import Badge from "@/src/components/atom/Badge";
import { TaskStatusType } from "@/src/store/apis/tasks/taskList/interface";
import {
  ContainerStatusType,
  TaskWeighingStatusType,
} from "@/src/store/apis/tasks/taskDetail/interface";
import styled from "styled-components";
import Icon from "@/src/components/atom/Icon";
import CheckCircleSvg from "@/src/assets/icons/icon-checkmark-circle-green-fill.svg";
import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle-fill.svg";
import DocumentEndnoteSvg from "@/src/assets/icons/icon-document-endnote-fill.svg";
import RadixTooltip from "@/src/components/atom/Tooltip/RadixTooltip";
import Typo from "@/src/components/atom/Typo";
import { TFunction } from "i18next";

export const renderLoadingStatusBadge = (param: {
  type: TaskStatusType | null;
  size?: "S" | "L";
  t: TFunction;
}) => {
  const { type, size = "S", t } = param;

  if (type === null) {
    return;
  }

  switch (type) {
    case "TASK_REQUEST":
      return (
        <Badge
          text={t("task:list.badge.loadingRequest")}
          color="systemPink2"
          badgeColor="systemPink6"
          badgeSize={size}
        />
      );

    case "TASK_SCHEDULED":
      return (
        <Badge
          text={t("task:list.badge.loadingScheduled")}
          color="systemGrape2"
          badgeColor="systemGrape6"
          badgeSize={size}
        />
      );

    case "WORKING":
      return (
        <Badge
          text={t("task:list.badge.working")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );

    case "PHOTO_SUBMISSION":
      return (
        <Badge
          text={t("task:list.badge.photoSubmitted")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={size}
        />
      );

    case "CONTAINER_COMPLETED":
      return (
        <Badge
          text={t("task:list.badge.fieldWorkCompleted")}
          color="systemOrange2"
          badgeColor="systemOrange6"
          badgeSize={size}
        />
      );

    case "TASK_COMPLETED":
      return (
        <Badge
          text={t("task:list.badge.loadingCompleted")}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );

    case "CANCEL_TASK_REQUEST":
      return (
        <Badge
          text={t("task:list.badge.cancelLoadingRequest")}
          color="gray7"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
  }
};

export const renderLoadingContainerStatusBadge = (param: {
  type: ContainerStatusType;
  size?: "S" | "L";
  t: TFunction;
}) => {
  const { type, size = "S", t } = param;

  switch (type) {
    case "TASK_SCHEDULED":
      return (
        <Badge
          text={t("task:list.badge.notStarted")}
          color="systemGrape2"
          badgeColor="systemGrape6"
          badgeSize={size}
        />
      );

    case "WORKING":
      return (
        <Badge
          text={t("task:list.badge.working")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );

    case "PHOTO_SUBMISSION":
      return (
        <Badge
          text={t("task:list.badge.photoSubmitted")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={size}
        />
      );
  }
};

/**
 * @description 계근정보 체크상테 여부에 따른 아이콘 렌더링
 * @returns 아이콘 (isTooltip을 이용하면 툴팁을 포함)
 */
export const renderContainerWeighCheckedStatus = (param: {
  type: TaskWeighingStatusType | null;
  size?: number;
  isTooltip?: boolean;
  t: TFunction;
}) => {
  const { type, size = 20, isTooltip, t } = param;

  if (type === null) {
    return;
  }

  let weighCheckedStatus: JSX.Element;
  let tooltipContent: JSX.Element;

  switch (type) {
    case "WEIGHING_CHECK_COMPLETED":
      weighCheckedStatus = (
        <TaskCheckedStatus>
          <StyledIcon iconSrc={CheckCircleSvg} iconSize={size} />
        </TaskCheckedStatus>
      );

      tooltipContent = (
        <TaskCheckedTooltipItem>
          <TaskCheckedStatus>
            <StyledIcon iconSrc={CheckCircleSvg} iconSize={16} />
          </TaskCheckedStatus>
          <Typo typoType="b10m" color="white">
            {t("task:detail.tooltip.weightUnCheckCompleted")}
          </Typo>
        </TaskCheckedTooltipItem>
      );
      break;

    case "WEIGHING_CHECK_NEEDED":
      weighCheckedStatus = (
        <TaskCheckedStatus>
          <StyledIcon iconSrc={ErrorCircleSvg} iconSize={size} />
        </TaskCheckedStatus>
      );

      tooltipContent = (
        <TaskCheckedTooltipItem>
          <TaskCheckedStatus>
            <StyledIcon iconSrc={ErrorCircleSvg} iconSize={16} />
          </TaskCheckedStatus>
          <Typo typoType="b10m" color="white">
            {t("task:detail.tooltip.weightCheckNeeded")}
          </Typo>
        </TaskCheckedTooltipItem>
      );
      break;
  }

  if (isTooltip) {
    return (
      <RadixTooltip
        contentProps={{
          side: "bottom",
        }}
        arrowProps={{ style: { opacity: 0.8 } }}
        arrowColor="gray1"
        content={
          <TaskCheckedTooltipContent>
            {tooltipContent}
          </TaskCheckedTooltipContent>
        }
      >
        {weighCheckedStatus}
      </RadixTooltip>
    );
  }

  return weighCheckedStatus;
};

export const renderIsWritedInAppStatus = (param: {
  type: boolean;
  size?: number;
  isTooltip?: boolean;
  t: TFunction;
}) => {
  const { type, size = 20, isTooltip, t } = param;

  let writedInAppStatusIcon: JSX.Element;

  switch (type) {
    case true:
      writedInAppStatusIcon = (
        <div>
          <StyledIcon iconSrc={DocumentEndnoteSvg} iconSize={size} />
        </div>
      );
      break;

    default:
      return;
  }

  if (isTooltip) {
    return (
      <RadixTooltip
        contentProps={{
          side: "bottom",
        }}
        arrowProps={{ style: { opacity: 0.8 } }}
        arrowColor="gray1"
        content={
          <TaskCheckedTooltipContent>
            <TaskCheckedTooltipItem>
              <div style={{ width: "16px", height: "16px" }}>
                <StyledIcon iconSrc={DocumentEndnoteSvg} iconSize={16} />
              </div>
              <Typo typoType="b10m" color="white">
                {t("task:detail.tooltip.isWritedInApp")}
              </Typo>
            </TaskCheckedTooltipItem>
          </TaskCheckedTooltipContent>
        }
      >
        {writedInAppStatusIcon}
      </RadixTooltip>
    );
  }

  return writedInAppStatusIcon;
};

const TaskCheckedStatus = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const TaskCheckedTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  border-radius: 4px;
`;

const TaskCheckedTooltipItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;

  white-space: pre-wrap;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;
