import Dialog from "@/src/components/atom/Dialog";
import colorSet from "@/src/styles/color";
import { styled } from "styled-components";
import ScaleSvg from "@/src/assets/icons/icon-scale-white.svg";
import Icon from "@/src/components/atom/Icon";
import CheckSvg from "@/src/assets/icons/icon-check-circle-fill.svg";
import { useTranslation } from "react-i18next";

interface ReferenceImageDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  imageList: string[];
  selectImage: string;
  onChangeSelect: (value: string) => void;
}

function ReferenceImageDialog({
  open,
  onOpenChange,
  imageList,
  selectImage,
  onChangeSelect,
}: ReferenceImageDialogProps) {
  const { t } = useTranslation();

  const handleSelectImageClick = () => {
    window.open(selectImage, "_blank");
  };

  const handleImageItemClick = (image: string) => {
    onChangeSelect(image);
  };

  return (
    <Dialog
      title={t("common:photoCollection")}
      width={640}
      open={open}
      onOpenChange={() => {
        onOpenChange(false);
        onChangeSelect("");
      }}
    >
      <ImageContainer>
        <SelectImageContainer onClick={handleSelectImageClick}>
          <SelectImageContainer />
          <Overlay />
          <ScaleIcon iconSrc={ScaleSvg} iconSize={48} />
          <SelectImage selectImage={selectImage} />
        </SelectImageContainer>

        <ImageList>
          {imageList.map((img, idx) => {
            const isSelect = img === selectImage;

            return (
              <ImageItem
                key={idx.toString()}
                onClick={() => handleImageItemClick(img)}
              >
                {isSelect && <ImageOverlay isSelect={isSelect} />}
                {isSelect && <CheckIcon iconSrc={CheckSvg} iconSize={32} />}
                <Image imgUrl={img} />
              </ImageItem>
            );
          })}
        </ImageList>
      </ImageContainer>
    </Dialog>
  );
}

export default ReferenceImageDialog;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ScaleIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.3s;
`;

const SelectImageContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;

  &:hover {
    ${ScaleIcon} {
      opacity: 1;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 395px;
  border-radius: 8px;
  background: none;
  transition: 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const SelectImage = styled.div<{ selectImage: string }>`
  width: 100%;
  height: 395px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray10};
  background: ${({ selectImage }) => selectImage && `url(${selectImage})`}
    no-repeat;
  background-position: center;
  background-size: contain;
`;

const ImageList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 112px);
  gap: 16px 8px;
`;

const ImageItem = styled.div`
  position: relative;
  cursor: pointer;
`;

const ImageOverlay = styled.div<{ isSelect: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(22, 109, 215, 0.7);
`;

const Image = styled.div<{ imgUrl: string }>`
  width: 112px;
  height: 112px;
  border-radius: 8px;
  background: ${({ imgUrl }) => imgUrl && `url(${imgUrl})`} no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${colorSet.gray10};
`;

const CheckIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
