import colorSet, { ColorType } from "@/src/styles/color";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { styled } from "styled-components";

interface DotProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  dotColor?: ColorType;
}

const Dot = ({ dotColor = "blue4", ...restProps }: DotProps) => {
  return <StyledDot $dotColor={dotColor} {...restProps} />;
};

export default Dot;

const StyledDot = styled.span<{ $dotColor: ColorType }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ $dotColor }) => colorSet[$dotColor]};
`;
