import { useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import PUBLIC_PATH from "@/src/routes/public/path";
import { setRedirectUrl } from "@/src/store/slice/auth";
import EnterAccountCode from "./components/steps/EnterAccountCode";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { useLazyGetCompanyInfoViaLinkCodeQuery } from "@/src/store/apis/link";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import useAlert from "@/src/hooks/useAlert";
import AlertDialog from "@/src/components/atom/AlertDialog";
import LinkCompany from "./components/steps/LinkCompany";
import LinkSuccess from "./components/steps/LinkSuccess";
import { useTranslation } from "react-i18next";

enum AlertDialogState {
  NULL,
  DUPLICATE,
}

const LinkToAccountPage = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [params] = useSearchParams();
  const alert = useAlert();
  const linkCode = params.get("code");
  const [getCompanyInfoViaLinkCode, { currentData, originalArgs }] =
    useLazyGetCompanyInfoViaLinkCodeQuery();
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [alertDialogContent, setAlertDialogContent] = useState<string>("");
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [existClientId, setExistClientId] = useState<number>(0);

  if (!user) {
    dispatch(setRedirectUrl(`${location.pathname}${location.search}`));
    return <Navigate to={PUBLIC_PATH.LOGIN} replace />;
  }

  if (
    user &&
    (user.exporterUserType === "MIDDLE_MANAGER" ||
      user.exporterUserType === "MANAGER")
  ) {
    const type = user.exporterUserMainFieldType;
    if (type === "BOTH" || type === "SELLER") {
      return <Navigate to={EXPORTER_PRIVATE_PATH.HOME} replace />;
    }
    return <Navigate to={IMPORTER_PRIVATE_PATH.HOME} replace />;
  }

  const handleMoveFirstStepToSecondStep = async (
    isNewClient: boolean,
    companyLinkCode: string,
    clientId?: number | undefined
  ) => {
    try {
      if (isNewClient) {
        await getCompanyInfoViaLinkCode({
          companyLinkCode,
        }).unwrap();
        setExistClientId(0);
        setStep(2);
      } else {
        if (clientId) {
          await getCompanyInfoViaLinkCode({
            companyLinkCode,
          }).unwrap();
          setExistClientId(clientId);
          setStep(2);
        }
      }
    } catch (e: any) {
      const isAlreadyRegistered = e.status === 409;
      if (isAlreadyRegistered) {
        setAlertDialogState(AlertDialogState.DUPLICATE);
        setAlertDialogContent(e.data.message);
      } else {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const renderContentByStep = () => {
    switch (step) {
      case 1:
        return (
          <EnterAccountCode
            onNextStep={handleMoveFirstStepToSecondStep}
            linkCode={linkCode ?? ""}
          />
        );
      case 2:
        return (
          <>
            {currentData?.row ? (
              <LinkCompany
                companyInfo={currentData.row}
                onPrevStep={() => setStep(1)}
                isNewClient={!existClientId}
                clientId={existClientId}
                linkCode={originalArgs?.companyLinkCode || ""}
                onNextStep={() => setStep(3)}
              />
            ) : null}
          </>
        );
      case 3:
        return <LinkSuccess />;
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;
    if (alertDialogState === AlertDialogState.DUPLICATE) {
      return (
        <AlertDialog
          open
          title={t(
            "linkToAccount:integrated.integrationCompletionCodeGuidance"
          )}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            setAlertDialogContent("");
          }}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            setAlertDialogContent("");
          }}
          cancelText={""}
          okText={t("linkToAccount:integrated.ok")}
        >
          {alertDialogContent || (
            <>
              {t(
                "linkToAccount:integrated.integrationCompletionCodeGuidanceDescription"
              )}
            </>
          )}
        </AlertDialog>
      );
    }
  };

  return (
    <SignUpLayout>
      {renderContentByStep()}
      {renderAlertDialog()}
    </SignUpLayout>
  );
};

export default LinkToAccountPage;
