import { Button } from "@/src/components/atom/Button";
import Checkbox from "@/src/components/atom/Checkbox";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Input from "@/src/components/atom/Input";
import Label from "@/src/components/atom/Label";
import Typo from "@/src/components/atom/Typo";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import useAlert from "@/src/hooks/useAlert";
import { useAppSelector } from "@/src/store";
import {
  useCreatePaymentMethodsMutation,
  useLazyGetPaymentMethodsQuery,
} from "@/src/store/apis/subscription";
import colorSet from "@/src/styles/color";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ReactComponent as DotGray7Svg } from "@/src/assets/icons/icon-dot-gray7.svg";
import {
  isValidBirthDay,
  isValidExpirationDate,
} from "../../utils/isValidExpirationDate";
import {
  CARD_NUMBER_CHECK_LENGTH,
  DEFAULT_CARD_NUMBER,
} from "../../../NormalPlanSignup/components/PaymentInformation";
import { useTranslation } from "react-i18next";
import {
  CARD_REGISTER_TYPE_OPTION_LIST,
  REGISTER_TYPE_OPTION_LIST,
} from "@/src/constant/optionList";

interface AddPaymentMethodsDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

export type RegisterType = "BUSINESS" | "PERSONAL";
export type CardType = "CORPORATE_CARD" | "EMPLOYEE_PERSONALIZED_CARD";

function AddPaymentMethodsDialog({
  open,
  onOpenChange,
}: AddPaymentMethodsDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const user = useAppSelector((state) => state.auth.user);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);

  const [isConfirm, setIsConfirm] = useState(false);
  const [cardNumber, setCardNumber] = useState(DEFAULT_CARD_NUMBER);
  const { handleSubmit, control, watch, getValues, setValue, clearErrors } =
    useForm({
      mode: "onBlur",
      defaultValues: {
        cardType: "CORPORATE_CARD" as CardType,
        password2: "",
        expiredDate: "",
        registerType: "BUSINESS" as RegisterType,
        userInfo: "",
      },
    });

  const isButtonDisabled =
    !isConfirm ||
    cardNumber.join("").length <= CARD_NUMBER_CHECK_LENGTH ||
    watch("password2").length !== 2 ||
    watch("expiredDate").length !== 5 ||
    (watch("registerType") === "PERSONAL" && watch("userInfo").length !== 6);

  // API
  const [getPayments] = useLazyGetPaymentMethodsQuery();
  const [createPaymentMethods, { isLoading: isPaymentLoading }] =
    useCreatePaymentMethodsMutation();

  const getPaymentsList = async () => {
    try {
      await getPayments({ page: 1, pageSize: 2 }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleSubmitClick = async () => {
    if (!user) {
      return;
    }

    const expiryDateYear = getValues("expiredDate").substring(3, 5);
    const expiryDateMonth = getValues("expiredDate").substring(0, 2);

    const params = {
      cardNumber: cardNumber.join(""),
      expiryDate: `${expiryDateYear}${expiryDateMonth}`,
      password2: getValues("password2"),
      exporterId: user.exporter.id,
      isRepresentative: false,
      // 기업일경우와 카드종류가 법인명 카드일경우 비즈니스 넘버
      // 개인일경우 생년월일
      userInfo:
        getValues("registerType") === "BUSINESS" &&
        getValues("cardType") === "CORPORATE_CARD"
          ? user.exporter.businessNumber
          : getValues("userInfo"),
    };

    try {
      const res = await createPaymentMethods(params).unwrap();

      if (res) {
        getPaymentsList();
        onOpenChange(false);
        alert.showAlert({
          type: "success",
          message: t("alert:saveWasSuccessful"),
        });
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleExpiredDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, "");

    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4);
    }
    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
    }

    setValue("expiredDate", inputValue);
  };

  const handleCardNumberInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    idx: number
  ) => {
    const isBackSpaceKeyDown = e.code === "Backspace";

    const nextInput = inputContainerRef.current?.childNodes[idx].nextSibling
      ?.firstChild as HTMLInputElement;
    const prevInput = inputContainerRef.current?.childNodes[idx].previousSibling
      ?.firstChild as HTMLInputElement;

    if (cardNumber[idx].length === 0 && prevInput && isBackSpaceKeyDown) {
      prevInput.focus();
    }

    if (cardNumber[idx].length === 4 && nextInput && !isBackSpaceKeyDown) {
      nextInput.focus();
    }
  };

  const renderRegisterTypeItem = () => {
    if (watch("registerType") === "BUSINESS") {
      return (
        <>
          <FormItem
            label={t("common:cardType")}
            type="radioGroup"
            name="cardType"
            control={control}
            rules={{ required: true }}
            direction="vertical"
            options={CARD_REGISTER_TYPE_OPTION_LIST.map((item) => {
              return { ...item, label: t(item.langKey) };
            })}
            inputProps={{
              onChange: (e) => {
                setValue("cardType", e.target.value);
                clearErrors("userInfo");
              },
            }}
          />
          {watch("cardType") === "EMPLOYEE_PERSONALIZED_CARD" && (
            <FormItem
              label={t("normalPlanSignup:userInfo")}
              type="text"
              name="userInfo"
              control={control}
              rules={{
                required: watch("cardType") === "EMPLOYEE_PERSONALIZED_CARD",
                minLength: 6,
                validate: (value) => {
                  return isValidBirthDay(value);
                },
              }}
              errorsMessage={{
                required: t("error:required"),
                minLength: t("error:enterDateYYMMDD"),
                validate: t("error:enterDateYYMMDD"),
              }}
              direction="vertical"
              inputProps={{
                placeholder: t("normalPlanSignup:userInfoPlaceholder"),
                maxLength: 6,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("userInfo", e.target.value.replace(/[^0-9]/g, ""));
                },
              }}
            />
          )}
          <SectionCardRow
            label={
              <Label required>{t("normalPlanSignup:businessNumber")}</Label>
            }
            value={<Input disabled value={user?.exporter.businessNumber} />}
            direction="vertical"
          />
        </>
      );
    }

    if (watch("registerType") === "PERSONAL") {
      return (
        <FormItem
          label={t("normalPlanSignup:userInfo")}
          type="text"
          name="userInfo"
          control={control}
          rules={{
            required: watch("registerType") === "PERSONAL",
            minLength: 6,
            validate: (value) => {
              return isValidBirthDay(value);
            },
          }}
          errorsMessage={{
            required: t("error:required"),
            minLength: t("error:enterDateYYMMDD"),
            validate: t("error:enterDateYYMMDD"),
          }}
          direction="vertical"
          inputProps={{
            placeholder: t("normalPlanSignup:userInfoPlaceholder"),
            maxLength: 6,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setValue("userInfo", e.target.value.replace(/[^0-9]/g, ""));
            },
          }}
        />
      );
    }
  };

  return (
    <Dialog
      title={t("subscriptionManagement:subscription.addPaymentMethodDialog")}
      open={open}
      onOpenChange={onOpenChange}
      width={496}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(false)}
          >
            {t("subscriptionManagement:subscription.button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(handleSubmitClick)}
            disabled={isPaymentLoading || isButtonDisabled}
            isLoading={isPaymentLoading}
          >
            {t("subscriptionManagement:subscription.button.save")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <StyledForm onSubmit={handleSubmit(handleSubmitClick)}>
        <FormItem
          label={t("normalPlanSignup:selectBusinessIndividual")}
          type="radioGroup"
          name="registerType"
          control={control}
          rules={{ required: true }}
          direction="vertical"
          options={REGISTER_TYPE_OPTION_LIST.map((item) => {
            return { ...item, label: t(item.langKey) };
          })}
          inputProps={{
            onChange: (e) => {
              setValue("registerType", e.target.value);
              setValue("cardType", "CORPORATE_CARD");
              setValue("userInfo", "");
            },
          }}
        />
        {renderRegisterTypeItem()}
        <Divider />

        <SectionCardRow
          direction="vertical"
          label={<Label required>{t("normalPlanSignup:cardNumber")}</Label>}
          value={
            <FlexRow gap={8} ref={inputContainerRef}>
              {cardNumber.map((_, idx) => {
                return (
                  <Input
                    key={idx.toString()}
                    type="text"
                    value={cardNumber[idx]}
                    placeholder={t("normalPlanSignup:zeroPlaceholder").replace(
                      "`",
                      ""
                    )}
                    maxLength={4}
                    allowClear={false}
                    onChange={(e) => {
                      const copyCardNumber = [...cardNumber];
                      const result = copyCardNumber.map((item, itemIdx) => {
                        if (itemIdx === idx) {
                          return e?.target?.value.replace(/[^0-9]/g, "");
                        }
                        return item;
                      });

                      setCardNumber(result);
                    }}
                    onKeyDown={(e) => {
                      handleCardNumberInputKeyDown(e, idx);
                    }}
                  />
                );
              })}
            </FlexRow>
          }
        />

        <FlexRow gap={8}>
          <StyledFormItem
            label={t("normalPlanSignup:expiredDate")}
            type="text"
            name="expiredDate"
            control={control as any}
            rules={{
              required: true,
              minLength: 5,
              validate: (value) => {
                return isValidExpirationDate(value.replace(/[^0-9]/g, ""));
              },
            }}
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:enterDateMMYY"),
              minLength: t("error:enterDateMMYY"),
            }}
            direction="vertical"
            inputProps={{
              placeholder: t("normalPlanSignup:expiredDatePlaceholder"),
              maxLength: 5,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                handleExpiredDateChange(e),
              onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "/") {
                  e.preventDefault();
                }
              },
            }}
          />
          <StyledSectionCardRow
            label={<Label>{t("normalPlanSignup:password")}</Label>}
            direction="vertical"
            value={
              <Grid>
                <StyledFormItem
                  type="text"
                  name="password2"
                  control={control as any}
                  rules={{ required: true }}
                  errorsMessage={{
                    required: t("error:required"),
                  }}
                  direction="vertical"
                  inputProps={{
                    placeholder: t("normalPlanSignup:passwordPlaceholder"),
                    maxLength: 2,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setValue(
                        "password2",
                        e.target.value.replace(/[^0-9]/g, "")
                      );
                    },
                  }}
                />
                <Dot />
                <Dot />
              </Grid>
            }
          />
        </FlexRow>

        <CheckboxContainer>
          <Checkbox
            checked={isConfirm}
            onChange={() => setIsConfirm((prev) => !prev)}
          />
          <Typo typoType="b7r">
            {t(
              "subscriptionManagement:subscription.providingMyPersonalInformation"
            )}
          </Typo>
        </CheckboxContainer>
      </StyledForm>
    </Dialog>
  );
}

export default AddPaymentMethodsDialog;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const CheckboxContainer = styled.label`
  display: flex;
  gap: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: relative;
    top: 4px;
    flex-shrink: 0;
  }
`;

const FlexColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? 4}px;
  width: 100%;
`;

const FlexRow = styled(FlexColumn)`
  flex-direction: row;
  align-items: center;
`;

const StyledFormItem = styled(FormItem)`
  flex: 1;
  align-self: baseline;
`;

const Dot = styled(DotGray7Svg)`
  width: 8px;
  height: 8px;

  * {
    fill: ${colorSet.gray2};
  }
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  flex: 1;
  align-self: baseline;
`;

const Grid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: auto 24px 24px;
  align-items: baseline;
  justify-items: center;
`;
