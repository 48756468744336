import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Button, IconButton } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import SectionCard from "@/src/components/molecule/SectionCard";
import ContainerCard from "./ContainerCard";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import DownloadSvg from "@/src/assets/icons/icon-download-indigo.svg";
import Icon from "@/src/components/atom/Icon";
import {
  useGetImporterShipmentDetailQuery,
  useGetImporterShipmentDetailDocumentAndMemoQuery,
  useCreateImportInternalDocumentMutation,
  useDeleteImporterShipmentInternalDocumentMutation,
} from "@/src/store/apis/shipments/shipmentDetail";
import { isNull, isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { downloadFile } from "@/src/utils/downloadFile";
import { useUploadBigFileMutation } from "@/src/store/apis/media";
import useAlert from "@/src/hooks/useAlert";
import DeleteSvg from "@/src/assets/icons/icon-delete.svg";
import Badge from "@/src/components/atom/Badge";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";

interface ShipmentInProcessingTabProps {
  id: number;
  blNo?: string;
  refetchList?: () => void;
}

const EMPTY_ARRAY: [] = [];

const ShipmentInProcessingTab = ({
  id,
  blNo,
  refetchList,
}: ShipmentInProcessingTabProps) => {
  const { t } = useTranslation();
  const isRefetchOnce = useRef<boolean>(false);

  const downloadButton = useRef<HTMLButtonElement[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const alert = useAlert();
  const [documentTab, setDocumentTab] = useState<"share" | "internal">("share");
  const {
    invoiceNo,
    seller,
    etd,
    eta,
    shippingLine,
    vessel,
    voyageNo,
    portOfLoading,
    placeOfDelivery,
  } = useGetImporterShipmentDetailQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        const isStable = !isUnstable;

        const isNew = !(currentData?.isRead ?? true);

        if (isNew && !isRefetchOnce.current) {
          refetchList?.();
          isRefetchOnce.current = true;
        }

        return {
          invoiceNo: isStable ? currentData.invoiceNo : "-",
          seller: isStable ? currentData.buyerName : "-",
          etd: isStable ? currentData.etdAt : "-",
          eta: isStable ? currentData.etaAt : "-",
          shippingLine: isStable ? currentData.shippingLine : "-",
          vessel: isStable ? currentData.vessel : "-",
          voyageNo: isStable ? currentData.voyageNo : "-",
          portOfLoading: isStable ? currentData.portOfLoading : "-",
          placeOfDelivery: isStable ? currentData.placeOfDelivery : "-",
        };
      },
    }
  );
  const {
    shareMedia,
    internalMedia,
    isCiPlSharedAt,
    isBlSharedAt,
    ciMediaUrl,
    plMediaUrl,
    blMediaUrl,
  } = useGetImporterShipmentDetailDocumentAndMemoQuery(
    {
      id,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        const isStable = !isUnstable;
        return {
          shareMedia: isStable
            ? isNull(currentData.row.externalSimpleDocumentMedias)
              ? EMPTY_ARRAY
              : currentData.row.externalSimpleDocumentMedias
            : EMPTY_ARRAY,
          internalMedia: isStable
            ? isNull(currentData.row.importerInternalSimpleDocumentMedias)
              ? EMPTY_ARRAY
              : currentData.row.importerInternalSimpleDocumentMedias
            : EMPTY_ARRAY,
          isCiPlSharedAt: isStable ? !!currentData.row.ciPlSharedAt : true,
          isBlSharedAt: isStable ? !!currentData.row.blSharedAt : true,
          ciMediaUrl: isStable ? currentData.row.ciMediaUrl : undefined,
          plMediaUrl: isStable ? currentData.row.plMediaUrl : undefined,
          blMediaUrl: isStable ? currentData.row.blMediaUrl : undefined,
        };
      },
    }
  );
  const [upload] = useUploadBigFileMutation();
  const [uploadInternalDoc] = useCreateImportInternalDocumentMutation();
  const [deleteInternalDoc, { isLoading: isRemoving }] =
    useDeleteImporterShipmentInternalDocumentMutation();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file)
      // eslint-disable-next-line no-throw-literal
      throw {
        data: {
          message: t("alert:fileUploadFail"),
        },
      };
    try {
      const res = await upload({
        files: [file],
        folder: "commons/",
      }).unwrap();
      if (res) {
        await uploadInternalDoc({ id, mediaId: res[0].id }).unwrap();
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleDeleteFile = async (id: number) => {
    try {
      await deleteInternalDoc({ id }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    isRefetchOnce.current = false;
  }, [id]);

  return (
    <Article>
      <FlexDiv fullWidth flexDirection="column">
        <Typo as="h3" typoType="h4" className="header">
          {t("common:invoiceNo")} {invoiceNo} - {t("common:blNo")} {blNo || "-"}
        </Typo>
        <SectionCardGrid>
          {[
            {
              gridColumn: 6,
              title: t("common:seller"),
              value: <BreakWord typoType="h4">{seller}</BreakWord>,
            },
            {
              gridColumn: 3,
              title: t("common:etd"),
              value: (
                <BreakWord typoType="h4">
                  {dayjs(etd).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
                </BreakWord>
              ),
            },
            {
              gridColumn: 3,
              title: t("common:eta"),
              value: (
                <BreakWord typoType="h4">
                  {dayjs(eta).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
                </BreakWord>
              ),
            },
            {
              isDivider: true,
            },
            {
              gridColumn: 3,
              title: t("common:shippingLine"),
              value: <BreakWord typoType="h4">{shippingLine}</BreakWord>,
            },
            {
              gridColumn: 3,
              title: t("common:vesselAndVoyageNo"),
              value: (
                <BreakWord typoType="h4">
                  {vessel} / {voyageNo}
                </BreakWord>
              ),
            },
            {
              gridColumn: 3,
              title: t("common:portOfLoading"),
              value: <BreakWord typoType="h4">{portOfLoading}</BreakWord>,
            },
            {
              gridColumn: 3,
              title: t("common:placeOfDelivery"),
              value: <BreakWord typoType="h4">{placeOfDelivery}</BreakWord>,
            },
          ].map(({ gridColumn, isDivider = false, title, value }) => {
            if (isDivider) {
              return (
                <StyledSectionCardRow
                  key={title}
                  data-columns={12}
                  data-divider
                />
              );
            }
            return (
              <StyledSectionCardRow
                key={title}
                data-columns={gridColumn}
                label={
                  <Typo color="gray6" typoType="b9m">
                    {title}
                  </Typo>
                }
                direction="vertical"
                value={value}
              />
            );
          })}
        </SectionCardGrid>
      </FlexDiv>

      <FlexDiv gap={16}>
        <StyledSectionCard
          cardTitle={t("common:shippingDocuments")}
          className="half"
        >
          <FlexDiv flexDirection="column" gap={16}>
            {[
              {
                title: t("common:abbreviationCi"),
                isShared: isCiPlSharedAt,
                mediaUrl: ciMediaUrl,
                fileName: "ci",
              },
              {
                title: t("common:abbreviationPl"),
                isShared: isCiPlSharedAt,
                mediaUrl: plMediaUrl,
                fileName: "pl",
              },
              {
                title: t("common:abbreviationBl"),
                isShared: isBlSharedAt,
                mediaUrl: blMediaUrl,
                fileName: "bl",
              },
            ].map(({ title, isShared, mediaUrl, fileName }) => {
              return (
                <ShippingDocumentItem
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FlexDiv gap={8} alignItems="center">
                    <Typo typoType="h3">{title}</Typo>
                    {isShared ? (
                      <Badge
                        badgeSize="S"
                        badgeColor="green6"
                        color="green1"
                        text={t("common:share")}
                      />
                    ) : (
                      <Badge
                        badgeSize="S"
                        badgeColor="gray10"
                        color="gray7"
                        text={t("common:notShare")}
                      />
                    )}
                  </FlexDiv>
                  <FlexDiv gap={4} alignItems="flex-start">
                    <StyledButton
                      buttonColor="blue"
                      buttonGrade="secondary"
                      buttonSize={24}
                      isIconOnly
                      disabled={!isShared}
                      onClick={() => {
                        if (mediaUrl) {
                          downloadFile(mediaUrl, fileName);
                        }
                      }}
                    >
                      <Icon iconSrc={DownloadSvg} />
                    </StyledButton>
                    <StyledButton
                      buttonColor="blue"
                      buttonGrade="secondary"
                      buttonSize={24}
                      isIconOnly
                      disabled={!isShared}
                      onClick={() => {
                        if (mediaUrl) {
                          window.open(mediaUrl, "_blank");
                        }
                      }}
                    >
                      <Icon iconSrc={PreviewSvg} />
                    </StyledButton>
                  </FlexDiv>
                </ShippingDocumentItem>
              );
            })}
          </FlexDiv>
        </StyledSectionCard>
        <StyledSectionCard
          cardTitle={t("common:documentsFiles")}
          className="half"
        >
          <Tabs role="tablist">
            <StyledTabItem
              tabValue={"share"}
              tabIndex={documentTab === "share" ? 0 : -1}
              onFocus={() => setDocumentTab("share")}
              onClick={() => setDocumentTab("share")}
              data-selected={documentTab === "share"}
            >
              <Typo typoType="b7m">
                {t("contract:importer.detail.label.sharedDocument")}
              </Typo>
            </StyledTabItem>
            <StyledTabItem
              tabValue={t("contract:importer.detail.label.internalDocument")}
              tabIndex={documentTab === "internal" ? 0 : -1}
              onFocus={() => setDocumentTab("internal")}
              onClick={() => setDocumentTab("internal")}
              data-selected={documentTab === "internal"}
            >
              <Typo typoType="b7m">
                {t("contract:importer.detail.label.internalDocument")}
              </Typo>
            </StyledTabItem>
          </Tabs>
          {documentTab === "share" ? (
            <FlexDiv flexDirection="column" gap={16}>
              <FlexDiv justifyContent="space-between" alignItems="center">
                <Typo typoType="b7r" color="gray5">
                  {shareMedia?.length || 0} {t("common:files")}
                </Typo>
                <Button
                  buttonSize={32}
                  buttonGrade="tertiary"
                  buttonColor="black"
                  onClick={() => {
                    downloadButton.current.forEach((button) => button.click());
                  }}
                >
                  {t("contract:importer.detail.button.downloadButton")}
                </Button>
              </FlexDiv>
              <DocumentFileSection flexDirection="column" gap={16}>
                {shareMedia?.map(({ originalFileName, mediaUrl }, idx) => {
                  return (
                    <DocumentRow gap={8} alignItems="center">
                      <Typo typoType="b7m" className="document-row-title">
                        {t("common:document")} {idx + 1}
                      </Typo>

                      <FlexDiv gap={8} style={{ width: "calc(100% - 218px)" }}>
                        <Typo
                          as="p"
                          typoType="b7r"
                          color="gray5"
                          className="ellipsis"
                        >
                          {originalFileName}
                        </Typo>
                      </FlexDiv>
                      <FlexDiv gap={4}>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() =>
                            downloadFile(mediaUrl ?? "", originalFileName ?? "")
                          }
                          ref={(node) => {
                            const isAlreadyInRef = downloadButton.current.some(
                              (button) => button.isSameNode(node)
                            );
                            if (node && !isAlreadyInRef) {
                              downloadButton.current = [
                                ...downloadButton.current,
                                node,
                              ];
                            }
                          }}
                        >
                          <Icon iconSrc={DownloadSvg} iconSize={16} />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            window.open(mediaUrl, "_blank");
                          }}
                        >
                          <Icon iconSrc={PreviewSvg} iconSize={16} />
                        </IconButton>
                      </FlexDiv>
                    </DocumentRow>
                  );
                })}
              </DocumentFileSection>
            </FlexDiv>
          ) : (
            <FlexDiv flexDirection="column" gap={16}>
              <FlexDiv justifyContent="space-between" alignItems="center">
                <Typo typoType="b7r" color="gray5">
                  {internalMedia?.length || 0} {t("common:files")}
                </Typo>

                <FlexDiv gap={8}>
                  <Button
                    buttonSize={32}
                    buttonGrade="tertiary"
                    buttonColor="black"
                    onClick={() => {
                      downloadButton.current.forEach((button) =>
                        button.click()
                      );
                    }}
                  >
                    {t("contract:importer.detail.button.downloadButton")}
                  </Button>

                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(event) => handleFileChange(event)}
                  />

                  <Button
                    buttonSize={32}
                    buttonGrade="tertiary"
                    buttonColor="black"
                    onClick={() => {
                      if (fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    {t("contract:importer.detail.button.addFilesButton")}
                  </Button>
                </FlexDiv>
              </FlexDiv>
              <DocumentFileSection flexDirection="column" gap={16}>
                {internalMedia?.map(
                  ({ originalFileName, mediaUrl, id }, idx) => {
                    return (
                      <DocumentRow gap={8} alignItems="center">
                        <Typo typoType="b7m" className="document-row-title">
                          {t("common:document")} {idx + 1}
                        </Typo>

                        <FlexDiv
                          gap={8}
                          style={{ width: "calc(100% - 150px - 16px - 82px)" }}
                        >
                          <Typo
                            as="p"
                            typoType="b7r"
                            color="gray5"
                            className="ellipsis"
                          >
                            {originalFileName}
                          </Typo>
                        </FlexDiv>
                        <FlexDiv gap={4}>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            onClick={() =>
                              downloadFile(
                                mediaUrl ?? "",
                                originalFileName ?? ""
                              )
                            }
                            ref={(node) => {
                              const isAlreadyInRef =
                                downloadButton.current.some((button) =>
                                  button.isSameNode(node)
                                );
                              if (node && !isAlreadyInRef) {
                                downloadButton.current = [
                                  ...downloadButton.current,
                                  node,
                                ];
                              }
                            }}
                          >
                            <Icon iconSrc={DownloadSvg} iconSize={16} />
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            onClick={() => {
                              window.open(mediaUrl, "_blank");
                            }}
                          >
                            <Icon iconSrc={PreviewSvg} iconSize={16} />
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="red"
                            buttonGrade="secondary"
                            disabled={isRemoving}
                            onClick={() => {
                              handleDeleteFile(id);
                            }}
                          >
                            <Icon iconSrc={DeleteSvg} iconSize={16} />
                          </IconButton>
                        </FlexDiv>
                      </DocumentRow>
                    );
                  }
                )}
              </DocumentFileSection>
            </FlexDiv>
          )}
        </StyledSectionCard>
      </FlexDiv>
      <StyledSectionCard
        cardTitle={t("contract:importer.detail.label.containerList")}
      >
        <ContainerCard id={id} />
      </StyledSectionCard>
    </Article>
  );
};

export default ShipmentInProcessingTab;

const Article = styled.article`
  width: calc(100% - 236px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  .header {
    padding: 16px;
  }
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  &[data-columns="3"] {
    grid-column: 3 span;
  }
  &[data-columns="4"] {
    grid-column: 4 span;
  }
  &[data-columns="6"] {
    grid-column: 6 span;
  }
  &[data-columns="12"] {
    grid-column: 12 span;
  }
  &[data-divider="true"] {
    border-top: 1px solid ${colorSet.gray9};
  }
`;

const BreakWord = styled(Typo)`
  word-break: break-word;
`;

const FlexDiv = styled.div<{
  gap?: number;
  fullWidth?: boolean;
  flexDirection?: CSSProperties["flexDirection"];
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
}>`
  display: flex;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `};

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `};
`;

const StyledSectionCard = styled(SectionCard)`
  flex-shrink: 0;

  &.half {
    width: calc(50% - 8px);
  }

  .white-space-pre-wrap {
    white-space: pre-wrap;
  }
`;

const ShippingDocumentItem = styled(FlexDiv)`
  border: 1px solid ${colorSet.gray9};
  padding: 16px;
  border-radius: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${colorSet.gray10};
  margin-bottom: 16px;
  width: 100%;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-shrink: 0;
  padding: 10px 0;
  border: none;
  cursor: pointer;
  background: none;

  &[data-selected="true"] {
    padding: 10px 13px 8px 13px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const DocumentRow = styled(FlexDiv)`
  .document-row-title {
    width: 150px;
    flex-shrink: 0;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const DocumentFileSection = styled(FlexDiv)`
  overflow: auto;
  height: 125px;
  position: relative;
`;
