import AlertDialog from "@/src/components/atom/AlertDialog";
import Typo from "@/src/components/atom/Typo";
import { useTranslation, Trans } from "react-i18next";

interface PlanChangeConfirmAlertDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onOk: () => void;
  currentPlanName: string;
  currentPaymentCycle: string;
  nextPlanName: string;
  nextPaymentCycle: string;
  isOkDisabled?: boolean;
  isOkLoading?: boolean;
}

function PlanChangeConfirmAlertDialog({
  open,
  onOpenChange,
  onOk,
  currentPlanName,
  currentPaymentCycle,
  nextPlanName,
  nextPaymentCycle,
  isOkDisabled,
  isOkLoading,
}: PlanChangeConfirmAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      title={t("subscriptionManagement:subscription.checkChangePlan")}
      open={open}
      onOpenChange={onOpenChange}
      cancelText={t("subscriptionManagement:subscription.button.cancel")}
      okText={t("subscriptionManagement:subscription.button.ok")}
      onOk={onOk}
      isOkDisabled={isOkDisabled}
      isOkLoading={isOkLoading}
    >
      <Trans
        i18nKey="subscriptionManagement:subscription.checkChangeMessage"
        components={{
          strong: <Typo typoType="h8" color="gray5" />,
          br: <br />,
        }}
        values={{
          currentPlan: `${currentPlanName} - ${currentPaymentCycle}`,
          nextPlan: `${nextPlanName} - ${nextPaymentCycle}`,
        }}
      />
    </AlertDialog>
  );
}

export default PlanChangeConfirmAlertDialog;
