import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";

export const itemBuyerColDef: ColDef[] = [
  {
    field: "no",
    lockPosition: "left",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    width: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{(params.node?.rowIndex || 0) + 1}</Typo>;
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "exporterItem",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.exporterItem || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "totalNetWeight",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.totalNetWeight || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "buyerTop1",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.buyerTop1 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "buyerTop2",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.buyerTop2 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "buyerTop3",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.buyerTop3 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "buyerTop4",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.buyerTop4 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "buyerTop5",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.buyerTop5 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "etc",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.etc || "-"}</Typo>;
    },
  },
];
