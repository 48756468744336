export const isUndefined = (value: any): value is undefined =>
  typeof value === "undefined";

export const isBoolean = (value: any): value is boolean =>
  typeof value === "boolean";

export const isNull = (value: any) => value === null;

export const isNullish = (value: any | null | undefined) =>
  value === null || value === undefined;

export const isBase64Data = (data: string) => {
  return data.startsWith("data:") && data.includes(";base64,");
};

export const isURL = (data: string) => {
  try {
    const url = new URL(data);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch {
    return false;
  }
};
