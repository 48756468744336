import styled from "styled-components";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import BasicCard, {
  BasicCardTitleContainer,
} from "@/src/components/atom/Cards/BasicCard";
import {
  useCorporationSignUpFormContext,
  useCorporationStepContext,
} from "./hooks";
import { Button } from "@/src/components/atom/Button";
import {
  useGetCommonCodeViaCodeNameQuery,
  useGetTimeZonesQuery,
} from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import { Controller, useForm } from "react-hook-form";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import {
  useLazyCheckBusinessNameQuery,
  useLazyCheckBusinessNumberQuery,
} from "@/src/store/apis/auth";
import Icon from "@/src/components/atom/Icon";
import InfoSvg from "@/src/assets/icons/icon-info.svg";
import CallOut from "@/src/components/molecule/CallOut";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChevronRightSvg } from "@/src/assets/icons/icon-chevron-right.svg";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import { ClientType } from "@/src/store/apis/auth/interface";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Label from "@/src/components/atom/Label";
import GridCardRadio from "@/src/components/molecule/GridCardRadio";
import Select from "@/src/components/atom/Select";
import { useEffect, useMemo } from "react";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";

export const COMPANY_TYPE_OPTION_LIST: {
  label: string;
  value: ClientType;
  gridSpan?: number;
}[] = [
  { label: "common:export", value: "SELLER" },
  { label: "common:import", value: "BUYER" },
  { label: "common:exportAndImport", value: "BOTH", gridSpan: 2 },
];

const CorporateSignUpStepOne = () => {
  const { t } = useTranslation();
  const { onStepNext } = useCorporationStepContext();
  const signUpForm = useCorporationSignUpFormContext();
  const navigate = useNavigate();

  const {
    control,
    setValue,
    formState: { errors },
    register,
    clearErrors,
    handleSubmit,
    getValues,
    watch,
    setFocus,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      companyType: signUpForm.companyType,
      countryCodeItemName: signUpForm.countryCodeItemName,
      businessNumber: signUpForm.businessNumber,
      companyName: signUpForm.companyName,
      fax: signUpForm.fax,
      faxPrefix: signUpForm.faxPrefix,
      telPrefix: signUpForm.telPrefix,
      tel: signUpForm.tel,
      apartment: signUpForm.streetAddress,
      city: signUpForm.locality,
      state: signUpForm.region,
      postal: signUpForm.postalCode,
      country: signUpForm.countryName,
      timezoneCity: signUpForm.city,
      timezoneGmtOffset: signUpForm.gmtOffset,
    },
  });

  const {
    isFetching: isFetchingCountryCodeList,
    data: countryCodeList,
    isError: isErrorCountryCodeList,
  } = useGetCommonCodeViaCodeNameQuery({
    codeName: "COUNTRY",
  });

  const countryCodeListOptions = useMemo(() => {
    if (isErrorCountryCodeList) return [];
    return (
      countryCodeList?.map((item) => {
        return {
          label: `${item.codeItemName}) ${item.codeItemNameEn}`,
          value: `${item.codeItemName}&${item.value1}`,
        };
      }) ?? []
    );
  }, [isErrorCountryCodeList, countryCodeList]);

  const {
    currentData: timeZoneList,
    isFetching: isFetchingTimeZoneList,
    isError: isErrorTimeZoneList,
  } = useGetTimeZonesQuery(
    {
      countryCode: watch("countryCodeItemName").replace(/&+.*/g, ""),
    },
    {
      skip: !watch("countryCodeItemName"),
    }
  );
  const [checkBusinessNumber] = useLazyCheckBusinessNumberQuery();
  const [checkBusinessName] = useLazyCheckBusinessNameQuery();

  const isNextButtonDisabled = !(
    !!watch("companyName") &&
    !!watch("countryCodeItemName") &&
    !!watch("telPrefix") &&
    !!watch("tel") &&
    !!watch("businessNumber") &&
    !!watch("country") &&
    !!watch("timezoneCity") &&
    !!watch("timezoneGmtOffset")
  );

  const goTo = (path: keyof typeof PUBLIC_PATH) => {
    navigate(PUBLIC_PATH[path]);
  };

  const handleStepPrev = () => {
    goTo("SIGNUP");
  };

  const handleStepNext = async () => {
    const {
      apartment,
      city,
      state,
      postal,
      country,
      timezoneCity,
      timezoneGmtOffset,
    } = getValues();

    signUpForm.onFormValueChange((prev) => {
      return {
        ...prev,
        ...getValues(),
        countryName: country,
        locality: city,
        postalCode: postal,
        streetAddress: apartment,
        region: state,
        city: timezoneCity,
        gmtOffset: timezoneGmtOffset,
      };
    });
    onStepNext();
  };

  useEffect(() => {
    if (timeZoneList && timeZoneList.length === 1) {
      setValue("timezoneCity", timeZoneList[0].zoneName);
      setValue("timezoneGmtOffset", timeZoneList[0].gmtOffset);
      clearErrors("timezoneCity");
    } else {
      // timeZoneList에 값이 2개 이상일 경우 focus 설정
      if (timeZoneList) {
        if (timeZoneList.length >= 2) {
          setFocus("timezoneCity");
        }
      }
    }
  }, [timeZoneList, setValue, clearErrors, setFocus]);

  return (
    <>
      <StyledCard
        title={
          <BasicCardTitleContainer>
            {t("signupCorp:steps.secondStep")}
          </BasicCardTitleContainer>
        }
      >
        <ContentContainer>
          <CallOut
            icon={<Icon iconSrc={InfoSvg} />}
            value={
              <CalloutContent>
                <Typo typoType="b9m" color="indigo">
                  {t("signupCorp:content.corporateSignUpCallout")}
                </Typo>
                <CalloutLink onClick={() => goTo("STAFF_SIGNUP")}>
                  <BlueText typoType="b9m" color="systemBlue2">
                    {t("signupCorp:content.corporateSignUpCalloutLink")}
                  </BlueText>
                  <IconContainer>
                    <ChevronRightIcon />
                  </IconContainer>
                </CalloutLink>
              </CalloutContent>
            }
          />

          <form onSubmit={handleSubmit(handleStepNext)}>
            <FormContent>
              <SectionCardRow
                direction="vertical"
                label={<Label required>{t("signupCorp:content.type")}</Label>}
                value={
                  <Controller
                    name="companyType"
                    control={control}
                    render={({ field }) => {
                      return (
                        <StyledGridCardRadioContainer>
                          {COMPANY_TYPE_OPTION_LIST.map(
                            ({ label, value, gridSpan }) => {
                              const isChecked =
                                watch("companyType")?.includes(value);

                              return (
                                <GridCardRadio.Radio
                                  {...field}
                                  label={
                                    <Typo
                                      typoType="h9"
                                      color={isChecked ? "blue4" : "gray6"}
                                    >
                                      {t(label)}
                                    </Typo>
                                  }
                                  value={value}
                                  gridSpan={gridSpan}
                                  checked={isChecked}
                                />
                              );
                            }
                          )}
                        </StyledGridCardRadioContainer>
                      );
                    }}
                  />
                }
              />

              <SectionCardRow
                label={
                  <Label required>
                    {t("signupCorp:content.countryAndCity")}
                  </Label>
                }
                direction="vertical"
                value={
                  <FlexCol>
                    <Flex>
                      <Controller
                        name="countryCodeItemName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <StyledSelect
                              {...field}
                              suffixIcon={
                                isFetchingCountryCodeList ? (
                                  <Loader />
                                ) : undefined
                              }
                              disabled={
                                isFetchingCountryCodeList ||
                                isErrorCountryCodeList
                              }
                              data-invalid={!!errors.countryCodeItemName}
                              showSearch
                              value={
                                countryCodeListOptions?.find(
                                  ({ value }) =>
                                    value === getValues("countryCodeItemName")
                                )?.value
                              }
                              onChange={(e) => {
                                const countryCodeItem = e.split("&");
                                const countryCodeItemValue1 =
                                  countryCodeItem[1];

                                setValue("countryCodeItemName", e);
                                setValue("telPrefix", countryCodeItemValue1);
                                setValue("tel", "");
                                setValue("faxPrefix", countryCodeItemValue1);
                                setValue("fax", "");
                              }}
                              placeholder={t(
                                "signupCorp:content.countryPlaceholder"
                              )}
                              options={countryCodeListOptions}
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentElement;
                              }}
                              filterOption={(input, option) =>
                                ((option?.label as string) ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase()) ||
                                ((option?.value as string) ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          );
                        }}
                      />
                      <Controller
                        name="timezoneCity"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <StyledSelect
                              {...field}
                              suffixIcon={
                                isFetchingTimeZoneList ? <Loader /> : undefined
                              }
                              disabled={
                                isFetchingTimeZoneList ||
                                isErrorTimeZoneList ||
                                !watch("countryCodeItemName")
                              }
                              data-invalid={!!errors.city}
                              showSearch
                              value={
                                timeZoneList?.find(
                                  ({ zoneName }) =>
                                    zoneName === getValues("timezoneCity")
                                )?.zoneName
                              }
                              onChange={(value) => {
                                if (isErrorTimeZoneList) return;

                                const gmtOffset =
                                  timeZoneList?.find(
                                    ({ zoneName }) => zoneName === value
                                  )?.gmtOffset ?? 0;

                                field.onChange(value);
                                setValue("timezoneGmtOffset", gmtOffset);
                              }}
                              placeholder={t(
                                "signupCorp:content.cityPlaceholder"
                              )}
                              options={
                                isErrorTimeZoneList
                                  ? []
                                  : timeZoneList?.map((item) => ({
                                      label: `${item.zoneName} (${t(
                                        "common:utc"
                                      )} ${item.gmtOffset > 0 ? "+" : ""}${
                                        item.gmtOffset
                                      })`,
                                      value: item.zoneName,
                                    })) ?? []
                              }
                              filterOption={(inputValue, option) => {
                                return (
                                  ((option?.label as string) ?? "")
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()) ||
                                  ((option?.value as string) ?? "")
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Flex>
                    {(errors.countryCodeItemName || errors.timezoneCity) && (
                      <InputError message={t("error:required")} />
                    )}
                  </FlexCol>
                }
              />

              <FormItem
                label={t("signupCorp:content.businessNumber")}
                type="text"
                name="businessNumber"
                control={control}
                rules={{
                  required: true,
                  validate: async (number) => {
                    const data = await checkBusinessNumber({
                      businessNumber: number,
                    });
                    return !data.isError;
                  },
                }}
                errorsMessage={{
                  required: t("error:required"),
                  validate: t("error:alreadyExisted"),
                }}
                direction="vertical"
                inputProps={{
                  placeholder: t("signupCorp:content.businessNumber"),
                }}
              />

              <FormItem
                label={t("signupCorp:content.companyName")}
                type="text"
                name="companyName"
                control={control}
                rules={{
                  required: true,
                  validate: async (companyName) => {
                    const data = await checkBusinessName({
                      companyName,
                    });
                    return !data.isError;
                  },
                }}
                errorsMessage={{
                  required: t("error:required"),
                  validate: t("error:alreadyExisted"),
                }}
                direction="vertical"
                inputProps={{
                  placeholder: t("signupCorp:content.companyName"),
                }}
                bottomAccessory={
                  <Flex>
                    <Icon iconSrc={InfoSvg} iconSize={16} />
                    <Typo typoType="b9r" color="gray6">
                      {t("signup:common.enterCompanyNameInEng")}
                    </Typo>
                  </Flex>
                }
              />

              <ContactFormItem
                label={t("signupCorp:content.contact")}
                prefixValue={watch("telPrefix")}
                prefixName="telPrefix"
                restContactName="tel"
                register={register}
                setValue={setValue}
                direction="vertical"
                errorMessage={t("error:required")}
              />
              <ContactFormItem
                label={t("signupCorp:content.fax")}
                prefixValue={watch("faxPrefix")}
                prefixName="faxPrefix"
                restContactName="fax"
                register={register}
                setValue={setValue}
                direction="vertical"
                isRequired={false}
              />

              <AddressFormItem
                register={register}
                setValue={setValue}
                direction="horizontal"
                isRequired={true}
                error={errors}
                clearErrors={clearErrors}
                label={t("signupCorp:content.address")}
              />

              <Flex>
                <Icon iconSrc={InfoSvg} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("signupCorp:content.addressEnterEnglish")}
                </Typo>
              </Flex>

              <ButtonContainer>
                <StyledButton
                  buttonGrade="tertiary"
                  buttonColor="black"
                  buttonSize={40}
                  onClick={handleStepPrev}
                >
                  <Typo typoType="btn3m">{t("common:back")}</Typo>
                </StyledButton>

                <StyledButton
                  type="submit"
                  buttonSize={40}
                  disabled={isNextButtonDisabled}
                >
                  <Typo typoType="btn3m" color="white">
                    {t("common:next")}
                  </Typo>
                </StyledButton>
              </ButtonContainer>
            </FormContent>
          </form>
        </ContentContainer>
      </StyledCard>
    </>
  );
};

export default CorporateSignUpStepOne;

const StyledCard = styled(BasicCard)`
  width: 800px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 160px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const StyledButton = styled(Button)``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  padding-top: 16px;

  ${StyledButton} {
    text-align: center;
    flex: 1;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FlexCol = styled(Flex)`
  flex-direction: column;
  align-items: stretch;
`;

const CalloutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CalloutLink = styled.div`
  display: flex;
  gap: 2px;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  cursor: pointer;
  word-break: break-all;
`;

const ChevronRightIcon = styled(ChevronRightSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.systemBlue2};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledGridCardRadioContainer = styled(GridCardRadio.Container)`
  grid-template-rows: minmax(60px, 60px);
  padding-bottom: 32px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledSelect = styled(Select)`
  width: calc((100% - 8px) / 2);
`;
