import { IconButton } from "@/src/components/atom/Button";
import { styled } from "styled-components";
import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import dayjs from "dayjs";
import { ReactComponent as DeleteSvg } from "@/src/assets/icons/icon-cancle-red.svg";
import { isNull, isUndefined } from "@/src/utils/is";
import { commonApi } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { authApi } from "@/src/store/apis/auth";
import Flex from "@/src/components/molecule/Flex";
import { useTranslation } from "react-i18next";

interface LoadContractInformationCardProps {
  id?: number;
  onLoadButtonClick: () => void;
  onDeleteButtonClick?: (id: number) => void;
  contractDetail?: ContractDetailViewDto;
  isEdit?: boolean;
  isLastItem: boolean;
}

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const LoadContractInformationCard = ({
  id,
  contractDetail,
  onDeleteButtonClick,
  isEdit,
  isLastItem,
}: LoadContractInformationCardProps) => {
  const { t } = useTranslation();
  const isEmptyCard = !id;
  const mainCategory = contractDetail?.mainCategory;

  const { mainCategoryList } =
    commonApi.endpoints.getCommonCodeViaCodeName.useQueryState(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            mainCategoryList: isStable ? currentData : mainCategoryEmptyArray,
          };
        },
      }
    );

  const { userMainCategory, exporterUserType } =
    authApi.endpoints.getSession.useQuery(undefined, {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          userMainCategory: isStable
            ? currentData.row.mainCategoryCodeItemNames
            : [],
          exporterUserType: currentData?.row.exporterUserType,
        };
      },
    });

  const mainCategoryItemName = mainCategoryList.find(
    (item) => item.codeItemNameEn === mainCategory
  )?.codeItemName;

  const isMainCategoryAuthorized =
    exporterUserType === "MANAGER"
      ? userMainCategory.includes(mainCategoryItemName as string)
      : true;

  const isDisabledDeleteButton =
    !isMainCategoryAuthorized || (isEdit && contractDetail?.isTaskLinked);

  const renderContent = () => {
    return (
      <StyledFlex flexDirection="column" gap={24} isLastItem={isLastItem}>
        <InfoCardContainer>
          <InfoBlueCard>
            {[
              {
                key: "scNumber",
                title: t("common:scNo"),
                content: contractDetail?.scNo,
              },
              {
                key: "buyer",
                title: t("common:buyer"),
                content: contractDetail?.buyerName,
              },
              {
                key: "item",
                title: t("common:item"),
                content: contractDetail?.exporterItem,
              },
              {
                key: "contact",
                title: t("common:contact"),
                content: contractDetail?.buyerEmployeeName,
              },
              {
                key: "paymentTerms",
                title: t("common:paymentTerms"),
                content: `${contractDetail?.paymentTerms} ${
                  contractDetail?.paymentTermsRemark ?? ""
                }`,
              },
            ].map((item) => {
              return (
                <StyledCardItem key={item.key}>
                  <CardItemTitle>{item.title}</CardItemTitle>
                  <CardItemContent
                    className={item.key === "scNumber" ? "wb-ba" : undefined}
                  >
                    {item.content || "-"}
                  </CardItemContent>
                </StyledCardItem>
              );
            })}
          </InfoBlueCard>
          {id && (
            <StyledIconButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              disabled={isDisabledDeleteButton}
            >
              <DeleteIcon
                color={isDisabledDeleteButton ? "red6" : "gray2"}
                onClick={() => onDeleteButtonClick?.(id)}
              />
            </StyledIconButton>
          )}
        </InfoCardContainer>

        <SubItemsContainer>
          <HalfContainer>
            {[
              {
                title: t("common:itemCode"),
                content: contractDetail?.exporterItemCode,
              },
              { title: t("common:poNo"), content: contractDetail?.poNo },
              {
                title: t("common:lcNo"),
                content: contractDetail?.lcNo ?? "-",
                isHidden: contractDetail?.paymentTerms !== "L/C",
              },
              {
                title: t("common:quantity"),
                content: `${Number(
                  contractDetail?.quantity
                ).toLocaleString()} ${contractDetail?.quantityUnit}`,
              },
              {
                title: t("common:unitPrice"),
                content: `${Number(
                  contractDetail?.unitPrice
                ).toLocaleString()} ${contractDetail?.unitPriceUnit}`,
              },
              {
                title: t("common:allocation"),
                content: contractDetail?.allocation,
                isHidden: !contractDetail?.allocation,
              },
            ].map((item, idx) => {
              const isHidden = !!item.isHidden;

              if (isHidden) return null;
              return (
                <SubItem key={idx}>
                  <SubItemTitle>{item.title}</SubItemTitle>
                  <SubItemContent>{item.content || "-"}</SubItemContent>
                </SubItem>
              );
            })}
          </HalfContainer>
          <HalfContainer>
            {[
              {
                title: t("common:placeOfDelivery"),
                content:
                  contractDetail?.placeOfDelivery ||
                  `${
                    contractDetail?.anyPortIn
                      ? `${t("common:anyPortIn")} ${contractDetail?.anyPortIn}`
                      : "-"
                  }`,
              },
              {
                title: t("common:shippingTerms"),
                content: `${contractDetail?.shippingTerms} ${
                  contractDetail?.shippingTermsRemark ?? ""
                }`,
              },

              {
                title: t("common:lastShipmentDate"),
                content: !isNull(contractDetail?.lastShipmentDateAt)
                  ? dayjs(contractDetail?.lastShipmentDateAt).format(
                      "YYYY-MM-DD"
                    )
                  : "-",
              },
              {
                title: t("common:freetime"),
                content: contractDetail?.freetime ?? "-",
              },
              {
                title: t("common:originLocation"),
                content: contractDetail?.origin ?? "-",
              },
            ].map((item) => {
              return (
                <SubItem key={item.title}>
                  <SubItemTitle>{item.title}</SubItemTitle>
                  <SubItemContent>{item.content || "-"}</SubItemContent>
                </SubItem>
              );
            })}
          </HalfContainer>
        </SubItemsContainer>
      </StyledFlex>
    );
  };

  return <>{!isEmptyCard && renderContent()}</>;
};

export default LoadContractInformationCard;

const StyledFlex = styled(Flex)<{ isLastItem: boolean }>`
  padding: 24px 0;
  border-bottom: ${({ isLastItem }) =>
    isLastItem ? `none` : `1px solid ${colorSet.gray9}`};

  &:first-of-type {
    padding-top: 0;
  }
`;

const InfoCardContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const InfoBlueCard = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: ${colorSet.gray11};
  width: 100%;
  padding: 16px 8px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray10};
`;

const StyledCardItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 20%;
`;

const CardItemTitle = styled.p`
  ${typo.b9m}
  color: ${colorSet.gray6};
`;

const CardItemContent = styled.span`
  ${typo.h4}
  color: ${colorSet.blue2};
  text-align: center;

  &.wb-ba {
    word-break: break-all;
  }
`;

const SubItemsContainer = styled.div`
  display: flex;
  gap: 32px;
  padding: 0 8px 24px 8px;
`;

const HalfContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:first-of-type {
    position: relative;
    &::after {
      content: "";
      width: 1px;
      position: absolute;
      right: -16px;
      height: 100%;
      background: ${colorSet.gray9};
    }
  }
`;

const SubItem = styled.div`
  display: flex;
  gap: 8px;
`;

const SubItemTitle = styled.p`
  width: 164px;
  flex-shrink: 0;
  color: ${colorSet.gray2};
  ${typo.b7m}
`;

const SubItemContent = styled.span`
  color: ${colorSet.gray5};
  ${typo.b7r}
`;

const StyledIconButton = styled(IconButton)`
  height: max-content;
`;

const DeleteIcon = styled(DeleteSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;
