import { useCallback } from "react";
import { styled } from "styled-components";
import {
  GoogleMap as GoogleMapComponent,
  GoogleMapProps,
  MarkerF,
  MarkerProps,
  useJsApiLoader,
} from "@react-google-maps/api";
import Typo from "../Typo";
import MapMarkerSvg from "@/src/assets/icons/icon-map-marker.svg";
import { useGetAddressByGeocdoeQuery } from "@/src/store/apis/common";
import { isUndefined } from "@/src/utils/is";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";
import { useTranslation } from "react-i18next";

interface Props extends GoogleMapProps {
  marker?: MarkerProps[];
  zoomLevel?: number;
  mediaCoordinateRange?: MediaCoordinateRangeType;
}

const GoogleMap = ({
  marker = [],
  mediaCoordinateRange,
  zoomLevel,
  ...mapProps
}: Props) => {
  const { i18n } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    language: "-",
    region: "-",
  });

  const { geoInfo } = useGetAddressByGeocdoeQuery(
    {
      latitude: String(mapProps.center?.lat),
      longitude: String(mapProps.center?.lng),
      language: i18n.language as "en" | "ko",
    },
    {
      skip: !mapProps.center?.lat || !mapProps.center?.lng,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          geoInfo: isUnstable ? undefined : currentData.row.formattedAddress,
        };
      },
    }
  );

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(mapProps.center);

      map.fitBounds(bounds);
    },
    [mapProps.center]
  );

  const renderGeoInfo = () => {
    if (mediaCoordinateRange === "COUNTRY") {
      return i18n.language === "en"
        ? geoInfo?.split(",")[1]
        : geoInfo?.split(" ")[0];
    }

    return geoInfo;
  };

  return (
    <GoogleMapWrapper>
      {isLoaded && (
        <GoogleMapComponent
          clickableIcons={false}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            disableDoubleClickZoom: false,
            streetViewControl: false,
            draggable: false,
            keyboardShortcuts: false,
            scrollwheel: false,
            zoomControl: false,
            rotateControl: false,
            panControl: false,
            scaleControl: false,
            maxZoom: zoomLevel || 13,
            minZoom: zoomLevel || 13,
            styles: [
              {
                stylers: [{ visibility: "off" }],
                featureType: "poi",
                elementType: "labels",
              },
            ],
          }}
          onLoad={onLoad}
          {...mapProps}
        >
          {mediaCoordinateRange === "ALL" &&
            marker.map((markerProps) => {
              return (
                <MarkerF
                  icon={{
                    url: MapMarkerSvg,
                  }}
                  animation={google.maps.Animation.DROP}
                  clickable={false}
                  {...markerProps}
                />
              );
            })}
        </GoogleMapComponent>
      )}

      {geoInfo && (
        <LocationBox>
          <Typo typoType="b10r" color="white">
            Location {renderGeoInfo()}
          </Typo>
        </LocationBox>
      )}
    </GoogleMapWrapper>
  );
};

export default GoogleMap;

const GoogleMapWrapper = styled.div`
  position: relative;
`;

const LocationBox = styled.div`
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  border-radius: 2px;
`;
