import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { useGetTermsQuery } from "@/src/store/apis/common";
import { TermsForAnonymousDto } from "@/src/store/apis/common/interface";
import dayjs from "dayjs";
import { useAppSelector } from "@/src/store";
import Typo from "@/src/components/atom/Typo";
import Select from "@/src/components/atom/Select";
import Loader from "@/src/components/atom/Loader";
import Icon from "@/src/components/atom/Icon";
import FolderBagSvg from "@/src/assets/icons/icon-fold-bag.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { utcNow } from "@/src/utils/utcDayjs";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import TabItem from "@/src/components/molecule/TabItem";
import { useTranslation } from "react-i18next";

const termsTabsData = {
  termsOfUse: "Terms of Use",
  privacyPolicy: "Privacy Policy",
};

const TermsPage = () => {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const [selectedTab, setSelectedTab] = useState<number>();
  const [selectedTermId, setSelectedTermId] = useState<number>();
  const isSelectTabQuery = !!searchParam.get("selectTab");
  const { isFetching, currentData, isSuccess, isError } = useGetTermsQuery(
    {
      service: "EXPORT_WEB",
      titleEn:
        termsTabsData[
          searchParam.get("selectTab") as keyof typeof termsTabsData
        ],
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const isValidTerms = useMemo(() => {
    return (
      currentData
        ?.filter(({ isActivated, isOptional }) => !isOptional && isActivated)
        .reduce<TermsForAnonymousDto[]>((acc, val) => {
          return [
            ...acc,
            {
              ...val,
              termsHistories: val.termsHistories
                .filter(
                  ({ releasedAt }) =>
                    dayjs.utc(releasedAt).isBefore(utcNow(), "day") ||
                    dayjs.utc(releasedAt).isSame(utcNow(), "day")
                )
                .sort((a, b) => b.releasedAt.localeCompare(a.releasedAt)),
            },
          ];
        }, []) || []
    );
  }, [currentData]);

  const selectedTerm = isValidTerms?.find(({ id }) => id === selectedTab);

  const handleLogoClick = () => {
    if (!user) {
      return;
    }

    if (user.exporter.companyType === "BUYER") {
      // 수입자
      return navigate(IMPORTER_PRIVATE_PATH.HOME);
    }

    // BOTH, 수출자
    if (
      user.exporter.companyType === "BOTH" ||
      user.exporter.companyType === "SELLER"
    ) {
      return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }
  };

  const handleTabChange = (termDetail: TermsForAnonymousDto) => {
    setSelectedTab(termDetail.id);
    setSelectedTermId(termDetail.currentTermsHistory.id);
  };

  useEffect(() => {
    if (!isFetching && isSuccess && isValidTerms.length) {
      setSelectedTab(isValidTerms[0].id);
      setSelectedTermId(isValidTerms[0].currentTermsHistory.id);
    }
  }, [isFetching, isSuccess, isValidTerms]);

  return (
    <>
      <SignUpLayout onLogoClick={handleLogoClick}>
        <StyledMain>
          <Title>{t("terms:label.legal")}</Title>

          {isFetching ? (
            <WaitingSection>
              <Loader size={50} />
            </WaitingSection>
          ) : isError ? (
            <PlaceholderSection>
              <Icon
                iconSrc={FolderBagSvg}
                iconSize={120}
                className="flex-shrink-0"
              />
              <Typo typoType="b7m" color="gray8">
                {t("terms:label.noData")}
              </Typo>
            </PlaceholderSection>
          ) : (
            <>
              {!isSelectTabQuery && (
                <Tabs role="tablist">
                  {isValidTerms.map((term) => {
                    return (
                      <StyledTab
                        itemListCount={isValidTerms.length}
                        tabValue={term.id}
                        tabIndex={term.id === selectedTab ? 0 : -1}
                        onClick={() => handleTabChange(term)}
                        onFocus={() => handleTabChange(term)}
                        data-selected={term.id === selectedTab}
                      >
                        {i18n.language === "en" ? term.titleEn : term.titleKo}
                      </StyledTab>
                    );
                  })}
                </Tabs>
              )}

              <Section>
                <SpaceBetween>
                  <Typo typoType="h4">
                    {i18n.language === "en"
                      ? selectedTerm?.titleEn
                      : selectedTerm?.titleKo}
                  </Typo>

                  <Select
                    style={{ width: 210 }}
                    value={selectedTermId}
                    onChange={(value) => setSelectedTermId(value)}
                    options={selectedTerm?.termsHistories.map(
                      ({ releasedAt, id }) => ({
                        label: dayjs.utc(releasedAt).format("YYYY-MM-DD"),
                        value: id,
                      })
                    )}
                  />
                </SpaceBetween>

                <ContentContainer>
                  <div
                    className="ck ck-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "en"
                          ? selectedTerm?.termsHistories.find(
                              ({ id }) => id === selectedTermId
                            )?.bodyEn || "-"
                          : selectedTerm?.termsHistories.find(
                              ({ id }) => id === selectedTermId
                            )?.bodyKo || "-",
                    }}
                  />
                </ContentContainer>
              </Section>
            </>
          )}
        </StyledMain>
      </SignUpLayout>
    </>
  );
};

export default TermsPage;

const Title = styled.p`
  color: ${colorSet.gray1};
  ${typo.d3};
  padding: 24px 0;
`;

const StyledMain = styled.main`
  width: 1250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const StyledTab = styled(TabItem)<{ itemListCount: number }>`
  text-align: center;
  padding: 8px 16px;
  border: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};
  color: ${colorSet.gray6};
  ${typo.b7m}
  cursor: pointer;
  width: ${({ itemListCount }) => `calc(${100 / itemListCount}%)`};

  &[data-selected="true"] {
    background: ${colorSet.indigo};
    color: ${colorSet.white};
  }

  &:first-of-type {
    border-radius: 8px 0px 0px 8px;
  }

  &:last-of-type {
    border-radius: 0px 8px 8px 0px;
  }
`;

const Section = styled.section`
  padding: 24px;
  width: 100%;
`;

const SpaceBetween = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${colorSet.gray8};
  margin-bottom: 16px;
`;

const ContentContainer = styled.div`
  padding: 16px;

  * {
    all: revert;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    line-height: normal;
  }
`;

const WaitingSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 300px);
  width: 100%;
`;

const PlaceholderSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 300px);
  width: 100%;

  padding: 40px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;
