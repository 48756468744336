import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Dialog from "@/components/atom/Dialog";
import { Button } from "@/components/atom/Button";
import DialogFooterContainer from "@/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/components/atom/Typo";
import SectionCardRow from "@/components/molecule/SectionCardRow";
import Flex from "@/components/molecule/Flex";
import CallOut from "../../molecule/CallOut";
import { useAppSelector } from "@/src/store";
import { GenerateBuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import Table, { renderNoRowsComponent } from "../../atom/Table";
import { ColDef } from "ag-grid-community";
import { clientIntegrationInviteCodeDialogColumn } from "./columns/columns";
import colorSet from "@/src/styles/color";
import { isNull } from "@/src/utils/is";

interface ClientIntegrationInviteCodeDialogProps {
  onClose: () => void;
  onSave: (user: GenerateBuyerEmployeeDto) => void;
  personList: (GenerateBuyerEmployeeDto & { uniqueId: string })[];
  isLoading?: boolean;
}

const ClientIntegrationInviteCodeDialog = ({
  onSave,
  onClose,
  personList,
  isLoading,
}: ClientIntegrationInviteCodeDialogProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const agGridRef = useRef<AgGridReact>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [columnDefs] = useState<ColDef[]>(
    clientIntegrationInviteCodeDialogColumn(setSelectedId)
  );

  useAgGridHeaderRefresh({
    gridRef: agGridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "table:contactName",
      },
      {
        columnKey: "email",
        langKey: "table:emailAddress",
      },
    ],
  });

  const userEmailList = personList.map((user) => {
    return {
      ...user,
      isChecked: selectedId === user.uniqueId,
    };
  });

  const handleSaveClick = () => {
    if (selectedId) {
      const selectedRow = personList.find(
        (person) => person.uniqueId === selectedId
      );

      if (selectedRow) {
        onSave(selectedRow);
      }
    }
  };

  return (
    <Dialog
      title={t("common:sendClientInviteCode")}
      open
      onOpenChange={onClose}
      width={640}
      footer={
        <DialogFooterContainer>
          <Button buttonGrade="tertiary" buttonColor="black" onClick={onClose}>
            {t("addMemoDialog:button.cancel")}
          </Button>
          <Button
            onClick={handleSaveClick}
            disabled={isLoading || isNull(selectedId)}
            isLoading={isLoading}
          >
            {t("common:sendInviteCode")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Flex flexDirection="column" gap={16}>
        <StyledTypo typoType="b6m" color="gray5">
          {t("common:clientIntegrationGuide")}
        </StyledTypo>
        <SectionCardRow
          label={t("common:inviteCode")}
          direction="vertical"
          value={
            <StyledCallOut backgroundColor="white">
              <Typo typoType="d5" color="blue4">
                {user?.exporter.companyLinkCode || "-"}
              </Typo>
            </StyledCallOut>
          }
        />
        <SectionCardRow
          label={t("common:selectEmailRecipients")}
          direction="vertical"
          value={
            <TableContainer>
              {personList.length >= 1 ? (
                <StyledTable
                  ref={agGridRef}
                  rowData={userEmailList}
                  columnDefs={columnDefs}
                  onGridReady={() => setIsReady(true)}
                  isPagination={false}
                  domLayout={"autoHeight"}
                  onRowClicked={(params) => {
                    setSelectedId(params.data.uniqueId);
                  }}
                />
              ) : (
                <StyledDiv>{renderNoRowsComponent()}</StyledDiv>
              )}
            </TableContainer>
          }
        />
      </Flex>
    </Dialog>
  );
};

export default ClientIntegrationInviteCodeDialog;

const StyledTypo = styled(Typo)`
  white-space: pre;
`;

const StyledCallOut = styled(CallOut)`
  justify-content: center;
`;

const TableContainer = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const StyledTable = styled(Table)`
  width: 100%;
  .ag-center-cols-clipper {
    min-height: 0px !important;
  }
`;

const StyledDiv = styled.div`
  padding: 20px 0;
`;
