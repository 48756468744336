import Badge from "@/src/components/atom/Badge";
import { ShipmentStatusType } from "@/src/store/apis/shipments/importerShipment/interface";
import { TFunction } from "i18next";

export const renderShipmentStatusBadge = (param: {
  t: TFunction;
  type: ShipmentStatusType | null;
  size?: "S" | "L";
}) => {
  const { t, type, size = "S" } = param;

  if (type === null) {
    return;
  }

  switch (type) {
    case "SHIPPING":
      return (
        <Badge
          text={t("shipment:importer.list.badge.shipping")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );
    case "BEFORE_SHIPPING":
      return (
        <Badge
          text={t("shipment:importer.list.badge.beforeShipment")}
          color="systemPink2"
          badgeColor="systemPink6"
          badgeSize={size}
        />
      );

    case "CONFIRMED_BOOKING":
      return (
        <Badge
          text={t("shipment:importer.list.badge.bookingConfirmed")}
          color="systemOrange2"
          badgeColor="systemOrange6"
          badgeSize={size}
        />
      );

    case "ARRIVED":
      return (
        <Badge
          text={t("shipment:importer.list.badge.arrived")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={size}
        />
      );

    case "CANCELED":
      return (
        <Badge
          text={t("shipment:importer.list.badge.bookingCancel")}
          color="red2"
          badgeColor="red8"
          badgeSize={size}
        />
      );
  }
};
