import React, { useState } from "react";
import styled from "styled-components";
import { Button, IconButton } from "@/src/components/atom/Button";
import useAlert from "@/src/hooks/useAlert";
import Icon from "@/src/components/atom/Icon";
import Dialog from "@/src/components/atom/Dialog";
import SingleImageUpload from "@/src/components/molecule/SingleImageUpload";
import CloseSvg from "@/src/assets/icons/icon-close-red.svg";
import PencelSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import { useAddSignMutation } from "@/src/store/apis/corporate/company";
import { useTranslation } from "react-i18next";
import { MediaDto } from "@/src/store/apis/media/interface";
import DialogFooterContainer from "../../atom/Dialog/DialogFooterContainer";

interface RegisterCompanySignDialogProps {
  onOpenChange: () => void;
  onSave?: (signatureMedia: MediaDto) => void;
}

const RegisterCompanySignDialog = ({
  onOpenChange,
  onSave,
}: RegisterCompanySignDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const [sign, setSign] = useState<{
    id: number;
    mediaUrl: string;
  }>();

  const [addSign, { isLoading: isAddingSign }] = useAddSignMutation();

  const handleRegister = async () => {
    if (!sign) return;
    try {
      const res = await addSign({ signatureMediaId: sign.id }).unwrap();
      onSave?.(res.signatureMedia);
      onOpenChange();

      alert.showAlert({
        type: "success",
        message: t("alert:saveWasSuccessful"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  return (
    <Dialog
      title={t("contract:exporter.detail.dialogTitle.registerSignature")}
      open
      onOpenChange={onOpenChange}
      footer={
        <DialogFooterContainer>
          <Button
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={onOpenChange}
          >
            {t("contract:exporter.detail.button.cancelButton")}
          </Button>
          <Button
            isLoading={isAddingSign}
            disabled={!sign}
            onClick={handleRegister}
          >
            {t("contract:exporter.detail.button.registerSaveButton")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Container>
        <SingleImageUpload
          onChange={(media) =>
            setSign({
              id: media.id,
              mediaUrl: media.mediaUrl,
            })
          }
          value={sign ? sign.mediaUrl : undefined}
          topAccessory={({ handleEdit, handleRemove }) => {
            return (
              <IconButtonContainer>
                <StyledIconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={24}
                  onClick={() => handleEdit()}
                >
                  <Icon iconSrc={PencelSvg} iconSize={16} />
                </StyledIconButton>
                <StyledIconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={24}
                  onClick={() => {
                    handleRemove();
                    setSign(undefined);
                  }}
                >
                  <Icon iconSrc={CloseSvg} iconSize={16} />
                </StyledIconButton>
              </IconButtonContainer>
            );
          }}
        />
      </Container>
    </Dialog>
  );
};

export default RegisterCompanySignDialog;

const IconButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px 4px 0 0;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  height: 205px;
`;
