import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import ShipSvg from "@/src/assets/icons/icon-ship-blue2.svg";
import Icon from "@/src/components/atom/Icon";
import { keyframes, styled } from "styled-components";
import colorSet from "@/src/styles/color";

export const shipmentRateColumns: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    field: "orderDateAt",
    cellStyle: { textAlign: "center" },
    width: 140,
    resizable: false,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {dayjs(params.data.orderDateAt).format("YYYY-MM-DD")}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "exporterItem",
    width: 200,
    minWidth: 100,
    flex: 1,
    resizable: true,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    width: 200,
    minWidth: 100,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "right" },
    field: "unitPrice",
    flex: 1,
    width: 120,
    minWidth: 60,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "right" },
    field: "totalQuantity",
    flex: 1,
    width: 120,
    minWidth: 60,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center", display: "flex", alignItems: "center" },
    field: "percentOfShipment",
    width: 280,
    flex: 1,
    minWidth: 280,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return <ShipmentSlider widthPercentage={params.data.percentOfShipment} />;
    },
  },
];

const ShipmentSlider = ({ widthPercentage }: { widthPercentage: number }) => {
  return (
    <Flex>
      <Icon iconSrc={ShipSvg} iconSize={16} />

      <Flex className="gap-16">
        <SliderTrack>
          <SliderThumb widthPercentage={widthPercentage} />
        </SliderTrack>

        <Typo typoType="b9m" color="blue4">
          {widthPercentage.toLocaleString()}%
        </Typo>
      </Flex>
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &.gap-16 {
    gap: 16px;
  }
`;

const SliderTrack = styled.div`
  width: 175px;
  height: 6px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  background: ${colorSet.gray9};
`;

const growUp = (percentage: number) => keyframes`
  0% {
    width: 0%;
  } 
  100% {
    width: ${percentage}%;
  }
`;

const SliderThumb = styled.div<{ widthPercentage: number }>`
  width: ${({ widthPercentage }) =>
    widthPercentage > 100 ? 100 : widthPercentage}%;
  position: absolute;
  animation: ${({ widthPercentage }) =>
      growUp(widthPercentage > 100 ? 100 : widthPercentage)}
    0.5s cubic-bezier(0, -0.01, 0.4, 1.5) forwards;
  top: 0;
  bottom: 0;
  background: ${colorSet.blue4};
`;
