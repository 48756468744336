import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import CallOut from "@/src/components/molecule/CallOut";
import Icon from "@/src/components/atom/Icon";
import InfoSvg from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import EmailLockSvg from "@/src/assets/icons/icon-email-lock.svg";
import PhoneLockSvg from "@/src/assets/icons/icon-phone-lock.svg";
import PhoneLockDisabledSvg from "@/src/assets/icons/icon-phone-lock-disabled.svg";
import colorSet from "@/src/styles/color";
import { Button } from "@/src/components/atom/Button";
import { useForm } from "react-hook-form";
import FormItem from "@/src/components/molecule/FormItem";
import useTimer from "@/src/hooks/useTimer";
import AlertDialog from "@/src/components/atom/AlertDialog";
import useAlert from "@/src/hooks/useAlert";
import {
  useEditSessionMutation,
  useGetMfaAuthTokenMutation,
  useMfaAuthSignInMutation,
  usePendingResetPasswordMutation,
} from "@/src/store/apis/auth";
import { isNull } from "@/src/utils/is";
import { setMfaUser, setTempUser, setUser } from "@/src/store/slice/auth";
import dayjs from "dayjs";
import Loader from "@/src/components/atom/Loader";
import useRedirectNavigate from "@/src/hooks/useRedirectNavigate";
import { utcNow } from "@/src/utils/utcDayjs";
import {
  useLazyGetCurrentSubscriptionQuery,
  useLazyGetEnterpriseInquiriesQuery,
  useLazyGetPlansQuery,
} from "@/src/store/apis/subscription";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { ExporterUserDto } from "@/src/store/apis/auth/interface";
import PUBLIC_PATH from "@/src/routes/public/path";
import { SUBSCRIPTION_EMPTY_LIST_ERROR_CODE } from "@/src/constant/error";
import TabItem from "@/src/components/molecule/TabItem";
import { useTranslation } from "react-i18next";

type TabType = "EMAIL" | "PHONE";

const INIT_TIMER_SECOND = 180;

enum AlertDialogState {
  NULL,
  PASSWORD_CHANGE_90DAYS_AFTER,
}

const TwoFactorAuthenticationPage = () => {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const mfaUser = useAppSelector((state) => state.auth.mfaUser);
  const tempUser = useAppSelector((state) => state.auth.tempUser);
  const navigate = useNavigate();
  const redirectOrNavigate = useRedirectNavigate();
  const tabRef = useRef<HTMLButtonElement>(null);

  const [selectTab, setSelectTab] = useState<TabType>("EMAIL");
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [isSendClick, setIsSendClick] = useState(false);
  const [initTimerSecond, setInitTimerSecond] = useState(INIT_TIMER_SECOND);
  const { min, second, restart } = useTimer({
    start: isSendClick,
    sec: initTimerSecond,
  });

  // API
  const [editMe, { isLoading: isEditUser }] = useEditSessionMutation();
  const [resetPassword] = usePendingResetPasswordMutation();
  const [getMfaAuthToken, { isLoading }] = useGetMfaAuthTokenMutation();
  const [mfaAuthSignIn, { isLoading: isSignInLoading }] =
    useMfaAuthSignInMutation();
  const [getCurrentSubscription] = useLazyGetCurrentSubscriptionQuery();

  const [getPlans] = useLazyGetPlansQuery();
  const [getEnterpriseInquiries] = useLazyGetEnterpriseInquiriesQuery();

  const tabList = [
    {
      type: "EMAIL" as TabType,
      iconSrc: EmailLockSvg,
      label: t("twoFactorAuthentication:emailTab"),
    },
    {
      type: "PHONE" as TabType,
      iconSrc: isNull(mfaUser?.maskedPersonalContact)
        ? PhoneLockDisabledSvg
        : PhoneLockSvg,
      label: t("twoFactorAuthentication:smsTab"),
      disabled: isNull(mfaUser?.maskedPersonalContact),
    },
  ];

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setFocus,
    clearErrors,
  } = useForm<{
    verifyString: string;
    authTokenId: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      verifyString: "",
      authTokenId: "",
    },
  });

  const redirectUserByType = async (res: ExporterUserDto) => {
    const companyType =
      res.exporterUserType === "CORPORATE_MANAGER"
        ? res.exporter.companyType
        : res.exporterUserMainFieldType;
    const isBuyerType = companyType === "BUYER";

    try {
      // 수입자
      if (isBuyerType) {
        return redirectOrNavigate(IMPORTER_PRIVATE_PATH.HOME);
      }

      // 기업 관리자
      if (res.exporterUserType === "CORPORATE_MANAGER") {
        const subscription = await getCurrentSubscription().unwrap();
        // 구독 내역이 있는 경우
        if (!!subscription) {
          return redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
        }
        // 수출자, BOTH
        return redirectOrNavigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING);
      } else {
        // 그 외 (중간관리자, 일반 매니저)
        redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
      }

      await getPlans({ planType: "ENTERPRISE" });
      await getEnterpriseInquiries({
        page: 1,
        pageSize: 1,
      });
    } catch (e: any) {
      // 구독 내역이 없는 경우, 수출자, BOTH
      if (e.data.errorCode === SUBSCRIPTION_EMPTY_LIST_ERROR_CODE) {
        return redirectOrNavigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING);
      }

      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  const handleSendClick = async () => {
    if (!mfaUser) {
      return;
    }

    try {
      const { authTokenId } = await getMfaAuthToken({
        contactType: selectTab,
        authToken: mfaUser?.authToken,
      }).unwrap();

      setIsSendClick(true);
      setValue("authTokenId", authTokenId);
      setValue("verifyString", "");
      setFocus("verifyString");

      // Timer
      restart();
    } catch (e: any) {
      alert.showAlert({ message: e.data.message, type: "error" });
    }
  };

  const handleConfirmClick = async () => {
    if (!mfaUser) {
      return;
    }

    const params = {
      authTokenId: getValues("authTokenId"),
      verifyString: getValues("verifyString"),
      authToken: mfaUser.authToken,
    };

    try {
      await mfaAuthSignIn(params).unwrap();
      const { row: user } = await editMe({
        language: i18n.language,
      }).unwrap();

      const newestChangedDate = dayjs
        .utc(user.account.passwordChangePendedAt)
        .isAfter(user.account.passwordChangedAt)
        ? user.account.passwordChangePendedAt
        : user.account.passwordChangedAt;

      const isSafePassword = dayjs
        .utc(newestChangedDate)
        .add(90, "day")
        .isAfter(utcNow());

      // 1. 임시 비밀번호 발급상태 (로그인 5회 싪패, 첫 로그인)
      // 2. 대시보드 이동
      // 3. 90일 이후 비밀번호 변경 Alert

      // 1. 임시 비밀번호 발급상태 (로그인 5회 싪패, 첫 로그인)
      if (user.account.isTemporaryPassword) {
        dispatch(setTempUser(user));
        return navigate(PUBLIC_PATH.CHANGE_PASSWORD);
      }

      // 2. 대시보드 이동
      // 2-1 수입자
      // 2-2 수출자
      // 2-3 BOTH
      // 2-4 기업관리자, 중간관리자, 일반매니저
      if (isSafePassword) {
        dispatch(setUser(user));
        redirectUserByType(user);
      } else {
        // 3. 90일 이후 비밀번호 변경 Alert
        setAlertDialogState(AlertDialogState.PASSWORD_CHANGE_90DAYS_AFTER);
        dispatch(setTempUser(user));
      }
    } catch (e: any) {
      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  const handlePasswordChangePending = async () => {
    if (!tempUser) return;
    try {
      await resetPassword().unwrap();

      dispatch(setUser(tempUser));
      redirectUserByType(tempUser);
      dispatch(setTempUser(null));
    } catch (e: any) {
      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  const renderTabContent = () => {
    const icon = selectTab === "EMAIL" ? EmailLockSvg : PhoneLockSvg;

    const buttonText = isSendClick
      ? t("twoFactorAuthentication:button.resendButton")
      : t("twoFactorAuthentication:button.sendVerificationCodeButton");

    const maskedText =
      selectTab === "EMAIL"
        ? mfaUser?.maskedEmail
        : mfaUser?.maskedPersonalContact;

    const contentDescription =
      selectTab === "EMAIL"
        ? t("twoFactorAuthentication:emailDescription")
        : t("twoFactorAuthentication:phoneDescription");

    return (
      <TabContent>
        <TabContentDescription>
          <Icon iconSrc={icon} iconSize={86} />
          <Typo typoType="h4">{maskedText}</Typo>
          <Typo typoType="b7m" as="p">
            {contentDescription}
          </Typo>
        </TabContentDescription>

        <Button
          buttonGrade="secondary"
          disabled={isLoading}
          onClick={handleSendClick}
        >
          {isLoading ? <Loader /> : buttonText}
        </Button>
      </TabContent>
    );
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.PASSWORD_CHANGE_90DAYS_AFTER) {
      return (
        <AlertDialog
          title={t("common:changingPasswordAlertTitle")}
          description={
            <AlertDialogDescription>
              {t("common:changingPasswordAlertDescription")}
            </AlertDialogDescription>
          }
          onOk={() => {
            navigate(PUBLIC_PATH.CHANGE_PASSWORD);
          }}
          onCancel={handlePasswordChangePending}
          cancelText={t("common:changingPasswordAlertCancelText")}
          okText={t("common:changingPasswordAlertOkText")}
          open
          onOpenChange={(open) => {
            if (!open) {
              setAlertDialogState(AlertDialogState.NULL);
            }
          }}
        />
      );
    }
  };

  useLayoutEffect(() => {
    if (!mfaUser) {
      return navigate(PUBLIC_PATH.LOGIN, { replace: true });
    }
  }, [mfaUser, navigate]);

  useEffect(() => {
    return () => {
      dispatch(setMfaUser(null));
    };
  }, [dispatch]);

  return (
    <SignUpLayout isLogoClick={false}>
      <StyledMain>
        <Typo typoType="d3" color="gray1" as="h1">
          {t("twoFactorAuthentication:title")}
        </Typo>

        <Card>
          <Typo typoType="h2" as="h2">
            {t("twoFactorAuthentication:cardTitle")}
          </Typo>

          <StyledSection>
            <CallOut
              borderColor="blue9"
              backgroundColor="blue10"
              icon={<Icon iconSrc={InfoSvg} />}
              value={
                <Typo typoType="b9r" color="indigo">
                  {t("twoFactorAuthentication:callout")}
                </Typo>
              }
            />

            {/* Tabs */}
            <Tabs role="tablist">
              {tabList.map(({ type, label, iconSrc, disabled }, idx) => {
                return (
                  <StyledTabItem
                    key={idx.toString()}
                    ref={type === selectTab ? tabRef : null}
                    tabIndex={type === selectTab ? 0 : -1}
                    data-selected={type === selectTab}
                    tabValue={type}
                    onClick={() => {
                      if (type !== selectTab) {
                        setSelectTab(type);
                        setIsSendClick(false);
                        clearErrors("verifyString");
                        setValue("verifyString", "");
                        setInitTimerSecond(INIT_TIMER_SECOND);
                      }
                    }}
                    disabled={disabled}
                    onFocusItem={() => {
                      if (type !== selectTab) {
                        setSelectTab(type);
                        setIsSendClick(false);
                        clearErrors("verifyString");
                        setValue("verifyString", "");
                        setInitTimerSecond(INIT_TIMER_SECOND);
                      }
                    }}
                  >
                    <div>
                      <Icon iconSrc={iconSrc} iconSize={56} />
                      <Typo typoType="b9m" color={disabled ? "gray7" : "gray2"}>
                        {label}
                      </Typo>
                    </div>
                  </StyledTabItem>
                );
              })}
            </Tabs>

            {/* Tabs Content*/}
            {renderTabContent()}

            <Form onSubmit={handleSubmit(handleConfirmClick)}>
              {isSendClick && (
                <StyledFormItem
                  label={
                    <Flex>
                      <CodeInputLabel>{t("common:code")}</CodeInputLabel>
                      <Typo typoType="b9m" color="red2">
                        {min}:{second} {t("common:sec")}
                      </Typo>
                    </Flex>
                  }
                  type="text"
                  name="verifyString"
                  control={control as any}
                  rules={{ required: true }}
                  errorsMessage={{
                    required: t("error:required"),
                  }}
                  direction="vertical"
                  inputProps={{
                    placeholder: t("common:enterCode"),
                  }}
                />
              )}
              {/* Button */}
              <ButtonContainer>
                <Button
                  buttonColor="black"
                  buttonGrade="tertiary"
                  onClick={() => navigate(PUBLIC_PATH.LOGIN)}
                >
                  {t("twoFactorAuthentication:button.backButton")}
                </Button>
                <Button
                  disabled={
                    watch("verifyString").length === 0 ||
                    (Number(min) === 0 && Number(second) === 0) ||
                    isEditUser ||
                    isSignInLoading
                  }
                  type="submit"
                  onClick={handleSubmit(handleConfirmClick)}
                  isLoading={isEditUser || isSignInLoading}
                >
                  {t("twoFactorAuthentication:button.confirmButton")}
                </Button>
              </ButtonContainer>
            </Form>
          </StyledSection>
        </Card>
      </StyledMain>

      {renderAlertDialog()}
    </SignUpLayout>
  );
};

export default TwoFactorAuthenticationPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  & > h1 {
    padding: 24px 0;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  padding: 40px 8px;
  gap: 32px;
  border: 1px;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.1);

  & > h2 {
    text-align: center;
  }
`;

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 460px;
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
`;

const StyledTabItem = styled(TabItem)`
  flex: 1;
  padding: 24px 8px;
  background: none;
  border: 1px solid ${colorSet.gray9};
  cursor: pointer;

  &[data-selected="true"] {
    border-color: ${colorSet.gray1};
  }

  &:disabled {
    cursor: not-allowed;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  border-radius: 1px;
  border: 1px solid ${colorSet.gray9};
  padding: 24px;
`;

const TabContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  padding: 8px 0;

  & > p {
    white-space: pre-wrap;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  button {
    width: 100%;
    text-align: center;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFormItem = styled(FormItem)`
  width: 100%;
`;

const CodeInputLabel = styled(Typo)`
  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const AlertDialogDescription = styled.p`
  white-space: pre-wrap;
`;
