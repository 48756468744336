import dayjs from "dayjs";
import Badge from "@/src/components/atom/Badge";
import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { changeEmailCharacters } from "@/src/utils/format";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import { getExporterUserType } from "../../Mypage";
import { CLIENT_TYPE_OPTION_LIST } from "@/src/constant/optionList";

export const columnStaffManagement: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 60,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "name",
    flex: 1,
    minWidth: 160,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.email
            ? changeEmailCharacters(params.data.email, 3)
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "exporterUserType",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.exporterUserType
            ? getExporterUserType(params.data.exporterUserType, params.data.t)
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "staffType",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      const staffTypeLangKey =
        params.data?.exporterUserMainFieldType &&
        CLIENT_TYPE_OPTION_LIST.find(
          (item) => item.value === params.data.exporterUserMainFieldType
        )?.langKey;

      return (
        <Typo typoType="b9r">
          {params.data.exporterUserMainFieldType
            ? params.data.t(staffTypeLangKey)
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "mainCategory",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.mainCategory ? params.data.mainCategory : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    minWidth: 160,
    flex: 1,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.personalContactPrefix && params.data.personalContact) {
        return (
          <Typo typoType="b9r">
            {`${params.data.personalContactPrefix} ${params.data.personalContact}`}
          </Typo>
        );
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "officeContact",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.officeContactPrefix && params.data.officeContact) {
        return (
          <Typo typoType="b9r">
            {`${params.data.officeContactPrefix} ${params.data.officeContact}`}
          </Typo>
        );
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.createdAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.createdAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )}
          </Typo>
        );
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "isActivated",
    width: 110,
    resizable: false,
    lockPosition: "right",
    pinned: "right",
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeColor="systemLime5"
            color="systemLime1"
            badgeSize="S"
          />
        );
      }
      return (
        <Badge text="NO" badgeColor="gray10" color="gray7" badgeSize="S" />
      );
    },
  },
];
