import { useEffect, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import { styled } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import Input from "@/src/components/atom/Input";
import Table from "@/src/components/atom/Table";
import Dialog from "@/src/components/atom/Dialog";
import { useLazyGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { LinkToAccountClientColumn } from "../columns/column";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface RegisteredAccountDialogProps {
  open: boolean;
  onClose: () => void;
  onNextStep: (selectedClientId: number) => void;
}

const RegisteredAccountDialog = ({
  open,
  onNextStep,
  onClose,
}: RegisteredAccountDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [buyerNameCode, setBuyerNameCode] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<number>(0);
  const ref = useRef<AgGridReact>(null);
  const [columnDefs] = useState<ColDef[]>(LinkToAccountClientColumn);
  const [getBuyers, { isError, currentData, isFetching }] =
    useLazyGetBuyersQuery();

  useAgGridHeaderRefresh({
    gridRef: ref.current,
    isReady,
    headerSet: [
      {
        columnKey: "client",
        langKey: "registeredLinkToAccount:table.client",
      },
      {
        columnKey: "clientType",
        langKey: "registeredLinkToAccount:table.clientType",
      },
      {
        columnKey: "clientCode",
        langKey: "registeredLinkToAccount:table.clientCode",
      },
      {
        columnKey: "country",
        langKey: "registeredLinkToAccount:table.country",
      },
      {
        columnKey: "categoryInCharge",
        langKey: "registeredLinkToAccount:table.categoryInCharge",
      },
    ],
  });

  const handleSelect = async () => {
    onNextStep(selectedClient);
  };

  useEffect(() => {
    (async () => {
      try {
        await getBuyers({
          page: 1,
          pageSize: 10,
          isActivated: true,
        }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBuyers]);

  return (
    <Dialog
      title={t("registeredLinkToAccount:title")}
      open={open}
      onOpenChange={() => setIsCancelAlertOpen(true)}
      width={1000}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => {
              setIsCancelAlertOpen(true);
            }}
          >
            {t("registeredLinkToAccount:cancelButton")}
          </Button>
          <Button disabled={!selectedClient} onClick={handleSelect}>
            {t("registeredLinkToAccount:selectButton")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Content>
        <InputContainer>
          <Typo typoType="b7m">{t("registeredLinkToAccount:subTitle")}</Typo>
          <ButtonContainer>
            <Input
              value={buyerNameCode}
              onChange={(e) => setBuyerNameCode(e.target.value)}
              placeholder={t("placeholder:enterClient")}
              onClear={() => setBuyerNameCode("")}
              onKeyDown={async (e) => {
                try {
                  const isEnterKey = e.code === "Enter";
                  if (isEnterKey) {
                    await getBuyers({
                      page: 1,
                      pageSize: pagination.pageSize,
                      isActivated: true,
                      buyerName: buyerNameCode || undefined,
                    }).unwrap();
                    setPagination(({ pageSize }) => ({ page: 1, pageSize }));
                  }
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;

                  if (e.data.statusCode !== 404) {
                    alert.showAlert({ type: "error", message });
                  }
                }
              }}
            />
            <StyledButton
              buttonColor="blue"
              buttonGrade="secondary"
              onClick={async () => {
                try {
                  await getBuyers({
                    page: 1,
                    pageSize: pagination.pageSize,
                    isActivated: true,
                    buyerName: buyerNameCode || undefined,
                  }).unwrap();
                  setPagination(({ pageSize }) => ({ page: 1, pageSize }));
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;

                  if (e.data.statusCode !== 404) {
                    alert.showAlert({ type: "error", message });
                  }
                }
              }}
            >
              {t("common:search")}
            </StyledButton>
          </ButtonContainer>
        </InputContainer>

        <Table
          ref={ref}
          onGridReady={() => setIsReady(true)}
          columnDefs={columnDefs}
          rowSelection={"single"}
          rowData={
            isError
              ? []
              : isFetching
              ? undefined
              : currentData?.data.map((client) => ({
                  ...client,
                  isChecked:
                    client.buyerListQueryResultDto.id === selectedClient,
                })) || []
          }
          totalPage={currentData?.count || 0}
          page={pagination.page}
          pageSize={pagination.pageSize}
          handlePaginationClick={async (page, pageSize) => {
            try {
              await getBuyers({
                page,
                pageSize,
                isActivated: true,
                buyerName: buyerNameCode || undefined,
              }).unwrap();
              setPagination({ page, pageSize });
            } catch (e: any) {
              const message = Array.isArray(e.data.message)
                ? e.data.message[0]
                : e.data.message;

              if (e.data.statusCode !== 404) {
                alert.showAlert({ type: "error", message });
              }
            }
          }}
          onRowSelected={(e) =>
            setSelectedClient(e.data.buyerListQueryResultDto.id)
          }
        />
      </Content>
      {isCancelAlertOpen && (
        <AlertDialog
          title={t("registeredLinkToAccount:alert.title")}
          open={isCancelAlertOpen}
          description={t("registeredLinkToAccount:alert.Description")}
          onOpenChange={(bool) => setIsCancelAlertOpen(bool)}
          onOk={onClose}
          onCancel={() => setIsCancelAlertOpen(false)}
          okText={t("registeredLinkToAccount:alert.ok")}
          cancelText={t("registeredLinkToAccount:alert.cancel")}
        />
      )}
    </Dialog>
  );
};

export default RegisteredAccountDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    border: 1px solid ${colorSet.gray9} !important;
    border-radius: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
`;
