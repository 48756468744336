import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import colorSet, { ColorType } from "@/src/styles/color";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Input from "@/src/components/atom/Input";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { Button, IconButton } from "@/src/components/atom/Button";
import typo from "@/src/styles/typography";
import SearchIcon from "@/src/assets/icons/icon-search.svg";
import DoubleLeftSvg from "@/src/assets/icons/icon-double-left.svg";
import DownloadSvg from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as ClockSvg } from "@/src/assets/icons/icon-clock-gray5.svg";
import { ReactComponent as ArrowDownSvg } from "@/src/assets/icons/icon-chevron-down-gray7.svg";
import { ReactComponent as MailSvg } from "@/src/assets/icons/icon-mail-blue.svg";
import ListIcon from "@/src/assets/icons/icon-menu.svg";
import GalleryIcon from "@/src/assets/icons/icon-gallery.svg";
import { ReactComponent as CornerArrowSvg } from "@/src/assets/icons/icon-corner-arrow.svg";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import Loader from "@/src/components/atom/Loader";
import { useGetContractListV2Query } from "@/src/store/apis/contracts/contractList";
import Flex from "@/src/components/molecule/Flex";
import { useGetExporterFileQuery } from "@/src/store/apis/file";
import PlaceHolderPNG from "@/src/assets/images/file-box-placeholder.png";
import Table from "@/src/components/atom/Table";
import { StyledScroll } from "@/src/styles/scroll";
import SectionCard from "@/src/components/molecule/SectionCard";
import { fileBoxAllColumns, fileBoxColumns } from "./columns/fileBoxColumns";
import { AgGridReact } from "ag-grid-react";
import { RowSelectedEvent } from "ag-grid-community";
import GalleryFileCard from "./components/GalleryFileCard";
import DropDown from "@/src/components/atom/DropDown";
import {
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import EmailDialog from "./components/dialog/EmailDialog";
import EmailHistoryDialog from "@/src/components/organism/EmailHistoryDialog";
import { useLazyGetEmailShareHistoriesQuery } from "@/src/store/apis/emailShareHistories";
import { isUndefined } from "@/src/utils/is";
import { useFileDownloadByMediaIdMutation } from "@/src/store/apis/containerImage";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";
import { Image } from "antd";
import { imageFileExtensionList } from "@/src/constant/fileExtension";

export type ExportFileBoxTabType =
  | "all"
  | "contract"
  | "booking"
  | "task"
  | "shipment";

const tabToFileClass = (tab: ExportFileBoxTabType) => {
  switch (tab) {
    case "all":
      return "ALL";
    case "contract":
      return "CONTRACT";
    case "booking":
      return "BOOKING";
    case "task":
      return "TASK";
    case "shipment":
      return "SHIPMENT";
  }
};

export const tabTitleI18nStrings = (tab: ExportFileBoxTabType) => {
  switch (tab) {
    case "all":
      return "common:all";
    case "contract":
      return "common:contract";
    case "booking":
      return "common:booking";
    case "task":
      return "common:loading";
    case "shipment":
      return "common:shipment";
  }
};

enum DialogState {
  NULL,
  SEND_EMAIL,
  SENT_HISTORY,
}

const ExporterFileBoxPage = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<ExportFileBoxTabType>("all");
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  const [categoryInputValue, setCategoryInputValue] = useState<string>("");
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [fileTab, setFileTab] = useState<"list" | "gallery">("list");
  const [isSideContentOpen, setIsSideContentOpen] = useState<boolean>(true);
  const [buyerCodeFilterValue, setBuyerCodeFilterValue] = useState<string>("");
  const [processingTab, setProcessingTab] = useState<string[]>([]);
  const [selectedContractId, setSelectedContractId] = useState<number>();
  const [selectedFileId, setSelectedFileId] = useState<number[]>([]);
  const [columnDefs] = useState(fileBoxColumns);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState("");

  const { currentData: buyerList, isFetching: isBuyerListFetching } =
    useGetBuyersQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const { currentData: contractList, isFetching: isContractListFetching } =
    useGetContractListV2Query({}, { refetchOnMountOrArgChange: true });
  const {
    currentData: fileList,
    isFetching,
    isError,
  } = useGetExporterFileQuery(
    {
      contractId: selectedContractId as number,
      fileClass: tabToFileClass(tab),
    },
    {
      skip: !selectedContractId,
      refetchOnMountOrArgChange: true,
    }
  );
  const [
    getContractSharedHistories,
    {
      count,
      emailHistoryList,
      isEmailShareHistoriesFetching,
      isEmailShareHistoriesError,
    },
  ] = useLazyGetEmailShareHistoriesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        count: !isUnstable ? currentData.count : 0,
        emailHistoryList: !isUnstable ? currentData.rows : undefined,
        isEmailShareHistoriesError: isError,
        isEmailShareHistoriesFetching: isFetching,
      };
    },
  });
  const [fileDownload, { isLoading: isFileDownloadLoading }] =
    useFileDownloadByMediaIdMutation();

  const fileContract = contractList?.rows.find(
    ({ id }) => id === selectedContractId
  );

  const filteredFileList = fileList?.rows.filter(
    ({ originalFileName, fileCategory }) => {
      return (
        originalFileName
          .toLowerCase()
          .includes(categoryInputValue.toLowerCase()) ||
        fileCategory.toLowerCase().includes(categoryInputValue.toLowerCase())
      );
    }
  );

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady: true,
    headerSet: [
      {
        columnKey: "type",
        langKey: "fileBox:exporter.table.type",
      },
      {
        columnKey: "sort",
        langKey: "fileBox:exporter.table.sort",
      },
      {
        columnKey: "fileCategory",
        langKey: "fileBox:exporter.table.fileCategory",
      },
      {
        columnKey: "originalFileName",
        langKey: "fileBox:exporter.table.originalFileName",
      },
      {
        columnKey: "createdAt",
        langKey: "fileBox:exporter.table.createdAt",
      },
    ].filter(({ columnKey }) => (tab === "all" ? true : columnKey !== "sort")),
  });

  // AgGrid Checkbox
  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const fileId = e.node.data.id;
      const keys = selectedNodesData.map((item) => item.data.id) as number[];

      if (selectedFileId.includes(fileId)) {
        setSelectedFileId((prev) => prev.filter((id) => id !== fileId));
      } else {
        const set: any = new Set([...selectedFileId, ...keys]);
        setSelectedFileId([...set]);
      }
    }
  };

  // AgGrid 페이지 네이션
  const handleRowDataUpdated = () => {
    const isLiveGridRef = !!gridRef.current;

    if (isLiveGridRef) {
      gridRef.current?.api.forEachNode((rowNode) => {
        if (selectedFileId.includes(rowNode.data.id)) {
          rowNode.setSelected(true);
        }
      });
    }
  };

  const handleFileDownload = async () => {
    try {
      if (selectedFileId.length === 0) {
        return alert.showAlert({
          type: "error",
          message: t("fileBox:exporter.alert.fileDownError"),
        });
      }
      await fileDownload({ mediaIdList: selectedFileId }).unwrap();
      alert.showAlert({
        type: "success",
        message: t("fileBox:exporter.alert.fileDownSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    if (isFetching && fileTab === "list" && gridRef.current) {
      gridRef.current?.api?.showLoadingOverlay();
    }
  }, [fileTab, isFetching]);

  const renderFilesByTab = () => {
    switch (fileTab) {
      case "list":
        return (
          <Table
            ref={gridRef}
            columnDefs={tab === "all" ? fileBoxAllColumns : columnDefs}
            rowData={isError ? [] : filteredFileList || []}
            height={900}
            rowSelection={"multiple"}
            rowMultiSelectWithClick={true}
            onRowSelected={handleSelectionChanged}
            onCellClicked={(e) => {
              const selectRow = e.node.data;
              const isOriginalFileNameNoColumn =
                e.column.getColDef().field === "originalFileName";

              if (
                isOriginalFileNameNoColumn &&
                imageFileExtensionList.includes(selectRow.fileExtension)
              ) {
                setPreviewImageUrl(selectRow.mediaUrl);
                setPreviewVisible(true);
              } else {
                setPreviewImageUrl("");
                setPreviewVisible(false);
              }
            }}
            onRowDataUpdated={handleRowDataUpdated}
            isPagination={false}
            totalPage={fileList?.count || 0}
          />
        );
      case "gallery":
        return (
          <FileGalleryList>
            {(filteredFileList || []).map(
              ({
                createdAt,
                originalFileName,
                mediaUrl,
                id,
                fileCategory,
                fileExtension,
              }) => {
                return (
                  <GalleryFileCard
                    category={fileCategory}
                    fileExtension={fileExtension}
                    fileSrc={mediaUrl}
                    fileName={originalFileName}
                    uploadedAt={createdAt}
                    onClick={() => window.open(mediaUrl, "_blank")}
                    isChecked={selectedFileId.includes(id)}
                    onCheckedChange={() => {
                      if (selectedFileId.includes(id)) {
                        return setSelectedFileId((prev) =>
                          prev.filter((fileId) => fileId !== id)
                        );
                      }
                      setSelectedFileId((prev) => [...prev, id]);
                    }}
                  />
                );
              }
            )}
          </FileGalleryList>
        );
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.SEND_EMAIL && selectedContractId) {
      const buyerContract = contractList?.rows.find(
        ({ id }) => id === selectedContractId
      );

      const buyer = buyerList?.data.find(
        ({ buyerNameCode }) => buyerNameCode === buyerContract?.buyerNameCode
      );

      return (
        <EmailDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          buyerName={buyer?.buyerName || ""}
          buyerId={buyer?.id || 0}
          fileList={
            fileList?.rows.filter(({ id }) => selectedFileId.includes(id)) || []
          }
          contractId={selectedContractId}
        />
      );
    }
    if (dialogState === DialogState.SENT_HISTORY && selectedContractId) {
      return (
        <EmailHistoryDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isFetching={isEmailShareHistoriesFetching}
          isError={isEmailShareHistoriesError}
          emailHistoryList={emailHistoryList}
          totalCount={count}
          fetch={getContractSharedHistories}
          emailShareDomain={"FILE_BOX_EXPORTER"}
          emailShareDomainId={selectedContractId}
        />
      );
    }
  };

  const renderEmailDropdown = () => {
    return (
      <DropDown
        dropdownContentProps={{ align: "end" }}
        dropdownTrigger={
          <StyledButton
            buttonSize={32}
            buttonColor="blue"
            buttonGrade="secondary"
          >
            <MailIcon size={16} color="indigo" />
            {t("fileBox:exporter.button.email")}
            <ArrowDownIcon />
          </StyledButton>
        }
      >
        <StyledSectionCardWithoutHeader>
          <EmailDropDownList>
            <EmailDropDownItem
              onSelect={() => setDialogState(DialogState.SEND_EMAIL)}
            >
              <MailIcon size={18} color="gray2" />
              {t("fileBox:exporter.button.sendEmailButton")}
            </EmailDropDownItem>

            <EmailDropDownItem
              onSelect={() => setDialogState(DialogState.SENT_HISTORY)}
            >
              <ClockIcon />
              {t("fileBox:exporter.button.sentHistoryButton")}
            </EmailDropDownItem>
          </EmailDropDownList>
        </StyledSectionCardWithoutHeader>
      </DropDown>
    );
  };

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:fileBox")]}
      sideAccessory={
        <SideContentSection>
          <FilterSection data-open={isSideContentOpen}>
            <SearchInputContainer>
              <Input
                value={buyerCodeFilterValue}
                onChange={({ target }) => setBuyerCodeFilterValue(target.value)}
                placeholder={t("fileBox:exporter.placeholder.enterBuyerCode")}
                onClear={() => setBuyerCodeFilterValue("")}
                suffix={<Icon iconSrc={SearchIcon} iconSize={16} />}
              />
            </SearchInputContainer>

            <SideTab>
              {isBuyerListFetching || isContractListFetching ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                <Accordion.Root type="multiple" value={processingTab}>
                  {(buyerList?.data || [])
                    .filter(({ buyerName }) =>
                      buyerName.toLowerCase().includes(buyerCodeFilterValue)
                    )
                    .map((buyer) => {
                      const matchedContractList = contractList?.rows.filter(
                        ({ buyerNameCode }) =>
                          buyer.buyerNameCode === buyerNameCode
                      );
                      return (
                        <Item
                          key={String(buyer.id)}
                          value={String(buyer.id)}
                          className="AccordionItem"
                        >
                          <Trigger
                            onClick={() =>
                              setProcessingTab((prev) =>
                                prev.includes(String(buyer.id))
                                  ? prev.filter(
                                      (buyerId) => buyerId !== String(buyer.id)
                                    )
                                  : [...prev, String(buyer.id)]
                              )
                            }
                          >
                            <Flex gap={2}>
                              <p>{buyer.buyerNameCode}</p>

                              <span>({matchedContractList?.length || 0})</span>
                            </Flex>

                            <ArrowDownIcon />
                          </Trigger>
                          <Content>
                            {matchedContractList?.map(({ scNo, id }) => {
                              return (
                                <StyledLocalButton
                                  data-selected={id === selectedContractId}
                                  onClick={() => {
                                    setSelectedContractId(id);
                                    setSelectedFileId([]);
                                  }}
                                >
                                  <CornerArrowIcon />
                                  <DomainNumber>
                                    {t("common:scNo")} {scNo}
                                  </DomainNumber>
                                </StyledLocalButton>
                              );
                            })}
                          </Content>
                        </Item>
                      );
                    })}
                </Accordion.Root>
              )}
            </SideTab>
          </FilterSection>

          <ToggleButtonContainer data-open={isSideContentOpen}>
            <ToggleButton
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              isIconOnly
              onClick={() => setIsSideContentOpen((prev) => !prev)}
            >
              <Icon
                className="double-left-icon"
                iconSrc={DoubleLeftSvg}
                iconSize={20}
              />
            </ToggleButton>
          </ToggleButtonContainer>
        </SideContentSection>
      }
    >
      {!fileContract ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          flex={1}
          flexDirection="column"
          gap={16}
        >
          <Figure>
            <img src={PlaceHolderPNG} alt="placeholder" />
          </Figure>
          <Flex flexDirection="column" alignItems="center">
            <Typo typoType="b4m">
              {t("fileBox:exporter.noContractSelected")}
            </Typo>
            <Typo typoType="b4m">
              {t("fileBox:exporter.pleaseSelectContract")}
            </Typo>
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection="column" gap={16}>
          <BuyerTypo>{fileContract.buyerNameCode}</BuyerTypo>
          <Tabs>
            {(
              [
                "all",
                "contract",
                "booking",
                "task",
                "shipment",
              ] as ExportFileBoxTabType[]
            ).map((key) => {
              return (
                <StyledTabItem
                  tabValue={key}
                  tabIndex={tab === key ? 0 : -1}
                  onFocus={() => {
                    setTab(key);
                  }}
                  onClick={() => {
                    setTab(key);
                  }}
                  data-selected={tab === key}
                  isSelect={tab === key}
                >
                  <Typo typoType="b5m" color={tab === key ? "white" : "gray6"}>
                    {t(tabTitleI18nStrings(key))}
                  </Typo>
                </StyledTabItem>
              );
            })}
          </Tabs>

          <Flex justifyContent="space-between">
            <InputContainer>
              <Input
                inputSize="small"
                value={categoryInputValue}
                onChange={({ target: { value } }) =>
                  setCategoryInputValue(value)
                }
                placeholder={t("fileBox:exporter.placeholder.fileCategory")}
                suffix={<Icon iconSrc={SearchIcon} />}
                onClear={() => setCategoryInputValue("")}
              />
            </InputContainer>

            <Flex gap={8}>
              {[
                {
                  iconSrc: DownloadSvg,
                  onClick: handleFileDownload,
                  type: "download",
                },
                {
                  iconSrc: MailIcon,
                  isEmail: true,
                },
                { iconSrc: ListIcon, onClick: () => setFileTab("list") },
                { iconSrc: GalleryIcon, onClick: () => setFileTab("gallery") },
              ].map(({ onClick, iconSrc, isEmail, type }) => {
                if (isEmail) {
                  return renderEmailDropdown();
                }
                return (
                  <StyledIconButton
                    buttonColor="black"
                    buttonGrade="tertiary"
                    buttonSize={32}
                    onClick={onClick}
                    isLoading={type === "download" && isFileDownloadLoading}
                  >
                    <Icon iconSrc={iconSrc as string} iconSize={16} />
                  </StyledIconButton>
                );
              })}
            </Flex>
          </Flex>

          <SectionCard cardTitle={t("fileBox:exporter.ecoyaExportFileList")}>
            {renderFilesByTab()}
          </SectionCard>
        </Flex>
      )}
      {renderDialogs()}

      {previewVisible && (
        <Image
          preview={{
            visible: previewVisible,
            src: previewImageUrl,
            onVisibleChange: (visible) => {
              setPreviewVisible(visible);
            },
          }}
        />
      )}
    </ExporterMainLayout>
  );
};

export default ExporterFileBoxPage;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

const SideContentSection = styled.section`
  display: flex;
`;

const FilterSection = styled.section`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colorSet.gray9};
  white-space: nowrap;
  transition: all 0.5s;

  &[data-open="true"] {
    width: 220px;
  }

  &[data-open="false"] {
    width: 0px;
    overflow: hidden;
    border: none;
    transform: translateX(-100%) translateZ(-100);
  }
`;

const ToggleButtonContainer = styled.div`
  padding: 12px 4px 12px 16px;

  &[data-open="false"] {
    .double-left-icon {
      transform: rotateY(180deg);
    }
  }
`;

const ToggleButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 6px;
  z-index: 1;
`;

const SearchInputContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const SideTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 8px;
  border-right: 1px solid ${colorSet.gray9};
  flex-shrink: 0;
  position: sticky;
  top: 0;
  overflow: auto;
  flex: 1;
`;

const StyledLocalButton = styled.button`
  cursor: pointer;
  ${typo.b8r};
  padding: 8px;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &[data-selected="true"] {
    background: ${colorSet.blue10};
    color: ${colorSet.blue4};
    ${typo.b9m};
    border: none;
  }

  &[data-selected="false"] {
    background: ${colorSet.white};
    color: ${colorSet.gray2};
    ${typo.b9m};
    border: none;
  }
`;

const slideDown = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: var(--radix-accordion-content-height);
    }
`;

const slideUp = keyframes`
    0% {
        height: var(--radix-accordion-content-height);
    }
    100% {
        height: 0;
    }
`;

const Trigger = styled(Accordion.Trigger)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 124px;
    ${typo.b8m}
  }

  span {
    ${typo.b8m}
  }

  .arrow {
    transition: transform 0.15s linear;
    flex-shrink: 0;
  }

  &[data-state="open"] {
    .arrow {
      transform: rotate(180deg);
    }
  }
`;

const Content = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state="open"] {
    animation: ${slideDown} 0.3s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.3s ease-in-out;
  }
`;

const Item = styled(Accordion.Item)`
  overflow: hidden;
`;

const CornerArrowIcon = styled(CornerArrowSvg)<{ isSelected?: boolean }>`
  flex-shrink: 0;

  path {
    ${({ isSelected }) =>
      isSelected &&
      css`
        stroke: ${colorSet.blue4};
      `};
  }
`;

const DomainNumber = styled.span`
  flex: 1;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BuyerTypo = styled.h1`
  ${typo.h1};
  padding-top: 16px;
`;

const Tabs = styled.div`
  display: flex;
`;

const StyledTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-right: 1px solid ${colorSet.gray9};
  cursor: pointer;
  background: ${({ isSelect }) =>
    isSelect ? colorSet.indigo : colorSet.gray11};
  border: 1px solid ${colorSet.gray9};

  &:last-child {
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const Figure = styled.figure`
  width: 178px;
`;

const FileGalleryList = styled.div`
  display: flex;
  max-height: calc(100vh - 385px);
  overflow: auto;
  gap: 16px;
  flex-wrap: wrap;
  ${StyledScroll}
`;

const InputContainer = styled.div`
  width: 240px;
`;

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
`;

const EmailDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;
const EmailDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }
`;

const ArrowDownIcon = styled(ArrowDownSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.indigo};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
`;

const ClockIcon = styled(ClockSvg)`
  width: 18px;
  height: 18px;

  path {
    fill: ${colorSet.gray2};
  }
`;

const MailIcon = styled(MailSvg)<{ size: number; color: ColorType }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
