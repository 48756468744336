import { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";
import styled, { css } from "styled-components";

interface FlexProps extends HTMLAttributes<HTMLDivElement> {
  isFullWidth?: boolean;
  gap?: CSSProperties["gap"];
  flexDirection?: CSSProperties["flexDirection"];
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
  flex?: CSSProperties["flex"];
  flexWrap?: CSSProperties["flexWrap"];
}

function Flex({
  isFullWidth,
  flexDirection,
  alignItems,
  justifyContent,
  flexWrap,
  gap,
  flex,
  ...rest
}: PropsWithChildren<FlexProps>) {
  return (
    <StyledFlex
      $isFullWidth={isFullWidth}
      $flexDirection={flexDirection}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $flexWrap={flexWrap}
      $gap={gap}
      $flex={flex}
      {...rest}
    />
  );
}

export default Flex;

const getFlexStyle = (props: FlexProps) => {
  const {
    isFullWidth,
    flexDirection,
    alignItems,
    justifyContent,
    gap,
    flex,
    flexWrap,
  } = props;

  return css`
    flex-direction: ${flexDirection};
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    gap: ${gap || 0}px;
    flex: ${flex};
    width: ${isFullWidth && "100%"};
    flex-wrap: ${flexWrap ? flexWrap : "nowrap"};
  `;
};

const StyledFlex = styled.div<{
  $isFullWidth?: boolean;
  $flexDirection?: CSSProperties["flexDirection"];
  $alignItems?: CSSProperties["alignItems"];
  $justifyContent?: CSSProperties["justifyContent"];
  $gap?: CSSProperties["gap"];
  $flex?: CSSProperties["flex"];
  $flexWrap?: CSSProperties["flexWrap"];
}>`
  display: flex;

  ${({
    $isFullWidth,
    $flexDirection,
    $alignItems,
    $justifyContent,
    $gap,
    $flex,
    $flexWrap,
  }) =>
    getFlexStyle({
      isFullWidth: $isFullWidth,
      flexDirection: $flexDirection,
      alignItems: $alignItems,
      justifyContent: $justifyContent,
      gap: $gap,
      flex: $flex,
      flexWrap: $flexWrap,
    })}
`;
