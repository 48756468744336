import React, { useEffect, useRef, useState } from "react";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import Flex from "@/src/components/molecule/Flex";
import styled from "styled-components";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import {
  useGetBookingShipmentContainerQuery,
  useLazyGetBookingShipmentContainerInformationQuery,
  useLazyGetBookingShipmentContainerIntegrationInfoQuery,
  useLazyGetBookingShipmentDocumentQuery,
} from "@/src/store/apis/shipments/importerShipment";
import { isUndefined } from "@/src/utils/is";
import Loader from "@/src/components/atom/Loader";
import SectionCard from "@/src/components/molecule/SectionCard";
import { Button, IconButton } from "@/src/components/atom/Button";
import Table from "@/src/components/atom/Table";
import { columnContainerList } from "../../../columns/columnContainerList";
import { AgGridReact } from "ag-grid-react";
import useAlert from "@/src/hooks/useAlert";
import { IRowNode, RowSelectedEvent } from "ag-grid-community";
import {
  BookingProgressContainerInfoDto,
  SimpleDocumentMediaDto,
} from "@/src/store/apis/shipments/importerShipment/interface";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import Typo from "@/src/components/atom/Typo";
import Icon from "@/src/components/atom/Icon";
import DeleteSvg from "@/src/assets/icons/icon-delete.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import DownloadBlackSvg from "@/src/assets/icons/icon-download-black.svg";
import DownloadSvg from "@/src/assets/icons/icon-download-indigo.svg";
import { downloadFile } from "@/src/utils/downloadFile";
import { useUploadBigFileMutation } from "@/src/store/apis/media";
import {
  useCreateImportInternalDocumentMutation,
  useDeleteImporterShipmentInternalDocumentMutation,
} from "@/src/store/apis/shipments/shipmentDetail";
import ConnectSaleDialog from "../dialog/ConnectSaleDialog";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { useDisconnectWarehouseWithSaleMutation } from "@/src/store/apis/sales";
import convertWeight, { WeightUnit } from "@/src/utils/convertWeight";
import EditConnectSaleDialog from "../dialog/EditConnectSaleDialog";
import Badge from "@/src/components/atom/Badge";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";

interface ContainerListCardProps {
  bookingId: number;
}

enum DialogState {
  NULL,
  CONNECT_CONTAINER_WITH_SALE,
  EDIT_CONTAINER_WITH_SALE,
}

enum AlertDialogState {
  NULL,
  DISCONNECT_CONTAINER_WITH_SALE,
}

const EMPTY_ARRAY: [] = [];

const ContainerListCard = ({ bookingId }: ContainerListCardProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact | null>(null);
  const downloadButton = useRef<HTMLButtonElement[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const shippingDocsDownloadButton = useRef<HTMLButtonElement[]>([]);
  const criteria = useRef<BookingProgressContainerInfoDto | null>(null);
  const [documentTab, setDocumentTab] = useState<"share" | "internal">("share");
  const [open, setOpen] = useState(true);
  const [selectedBl, setSelectedBl] = useState<number>(0); // ALL 일 시 0
  const [selectedContainer, setSelectedContainer] = useState<number[]>([]);
  const [selectedContainerList, setSelectedContainerList] = useState<
    BookingProgressContainerInfoDto[]
  >([]);
  const [selectedContainerTotalNetWeight, setSelectedContainerTotalNetWeight] =
    useState(0);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [col] = useState(columnContainerList);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isReady, setIsReady] = useState(false);

  const { blList, isBlFetching, isBlFetchingDone } =
    useGetBookingShipmentContainerQuery(
      { id: bookingId },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError, isSuccess }) => {
          const isUnstable = isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            blList: isStable ? currentData.rows : EMPTY_ARRAY,
            isBlFetching: isFetching,
            isBlFetchingDone: isSuccess,
          };
        },
      }
    );

  const [
    getContainerInfo,
    { containerList, isContainerListFetching, containerCount, originalArgs },
  ] = useLazyGetBookingShipmentContainerInformationQuery({
    selectFromResult: ({ currentData, isFetching, isError, originalArgs }) => {
      const isUnstable = isError || isUndefined(currentData);
      const isStable = !isUnstable;
      return {
        containerList: isStable ? currentData.rows : EMPTY_ARRAY,
        isContainerListFetching: isFetching,
        containerCount: isStable ? currentData.count : 0,
        originalArgs,
      };
    },
  });

  const [
    getContainerIntegrationInfo,
    { totalGrossWeight, totalNumberOfContainer, totalUnitQuantity },
  ] = useLazyGetBookingShipmentContainerIntegrationInfoQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isError || isUndefined(currentData);
      const isStable = !isUnstable;
      return {
        totalGrossWeight: isStable ? currentData.row.totalGrossWeight : "",
        totalNumberOfContainer: isStable
          ? currentData.row.totalNumberOfContainer
          : "",
        totalUnitQuantity: isStable ? currentData.row.totalUnitQuantity : "",
        isContainerIntegrationInfoFetching: isFetching,
      };
    },
  });

  const [getShippingDocuments, { shippingDocsList }] =
    useLazyGetBookingShipmentDocumentQuery({
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          shippingDocsList: isStable ? currentData.rows : EMPTY_ARRAY,
        };
      },
    });
  const [disconnectWarehouseWithSale] =
    useDisconnectWarehouseWithSaleMutation();
  const [upload] = useUploadBigFileMutation();
  const [uploadInternalDoc] = useCreateImportInternalDocumentMutation();
  const [deleteInternalDoc, { isLoading: isRemoving }] =
    useDeleteImporterShipmentInternalDocumentMutation();

  const shipmentIdList = blList.map(({ id }) => id).join(",");

  const shareMedia = shippingDocsList.reduce((acc, val) => {
    return [
      ...acc,
      ...(val.externalSimpleDocumentMedias
        ? val.externalSimpleDocumentMedias
        : []),
    ];
  }, [] as SimpleDocumentMediaDto[]);
  const internalMedia = shippingDocsList.reduce((acc, val) => {
    return [
      ...acc,
      ...(val.importerInternalSimpleDocumentMedias
        ? val.importerInternalSimpleDocumentMedias
        : []),
    ];
  }, [] as SimpleDocumentMediaDto[]);

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "warehouse",
        langKey: "shipment:importer.detail.table.warehouse",
      },
      {
        columnKey: "itemCode",
        langKey: "shipment:importer.detail.table.itemCode",
      },
    ],
  });

  const isRowSelectable = (node: IRowNode<BookingProgressContainerInfoDto>) => {
    /**
     * 1. 발주계약과 연동이 여부
     *  1-1. 발주계약과 연동이 되어있다면
     *      1-1-1. 현재 노드가 발주 계약 연동이 되어있지 않다면 false
     *      1-1-2. 현재 노드가 발주 계약 연동이 되어있다면
     *          1-1-2-1. 선택된 컨테이너의 연동된 발주 계약과 현재 노드의 발주 계약과 같으면 true
     *          1-1-2-2. 선택된 컨테이너의 연동된 발주 계약과 현재 노드의 발주 계약과 다르면 false
     *  1-2. 발주계약과 연동이 안되어있다면
     *      1-2-1. 현재 노드가 발주 계약 연동이 되어있다면 false
     *      1-2-2. 현재 노드가 발주 계약 연동이 되어있지 않다면
     *          1-2-2-1. 선택이 되어있는 컨테이너의 ItemCode와 현재 노드의 ItemCode가 같으면 true
     *          1-2-2-2. 선택이 되어있는 컨테이너의 ItemCode와 현재 노드의 ItemCode가 다르면 false
     */
    /**
     * 현재 노드의 ItemCode
     */
    const rowContainerItem = node.data?.contract.importerItemCode.itemCode;
    const isContainerConnectedWithSale =
      !!node.data?.warehousingContainer?.warehousing?.sales;
    const selectedContainerInfo = containerList.find(
      ({ id }) => criteria.current?.id === id
    );

    if (!!criteria.current) {
      /**
       * 발주계약 연동 여부
       */
      const isSelectedContainerConnectedWithSale =
        !!selectedContainerInfo?.warehousingContainer?.warehousing?.sales;
      /**
       * 선택이 되어있는 컨테이너의 ItemCode
       */
      const containerItem =
        selectedContainerInfo?.contract.importerItemCode.itemCode;
      if (isSelectedContainerConnectedWithSale) {
        // 1-1
        if (isContainerConnectedWithSale) {
          // 1-1-2
          return (
            node.data?.warehousingContainer?.warehousing?.sales?.salesNumber ===
            selectedContainerInfo?.warehousingContainer?.warehousing?.sales
              ?.salesNumber
          );
        }
        // 1-1-1
        return false;
      } else {
        // 1-2
        if (!isContainerConnectedWithSale) {
          // 1-2-2
          return rowContainerItem === containerItem;
        }
        // 1-2-1
        return false;
      }
    }
    /**
     * 만약 선택이 되어있지 않았다면
     * true
     */

    return true;
  };

  // AgGrid Checkbox
  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const accountKey = e.node.data.id;
      const keys = selectedNodesData.map((item) => item.data.id) as number[];
      let mtWeight: number;
      if (e.node.data.netWeight) {
        mtWeight = convertWeight({
          value: Number(e.node.data.netWeight),
          fromUnit: (
            e.node.data.weightUnit as string
          ).toLowerCase() as WeightUnit,
          toUnit: "mt",
          decimalPlaces: 2,
          includeUnit: false,
        }) as number;
      }
      if (selectedContainer.includes(accountKey)) {
        if (selectedContainer.length === 1) {
          criteria.current = null;
        }
        setSelectedContainer((prev) => prev.filter((id) => id !== accountKey));
        setSelectedContainerList((prev) =>
          prev.filter((container) => container.id !== accountKey)
        );
        if (e.node.data.netWeight) {
          setSelectedContainerTotalNetWeight((prev) => prev - mtWeight);
        }
      } else {
        if (criteria.current === null) {
          criteria.current = e.node.data;
        }
        const set: any = new Set([...selectedContainer, ...keys]);
        setSelectedContainer([...set]);
        setSelectedContainerList((prev) => [...prev, e.node.data]);
        if (e.node.data.netWeight) {
          setSelectedContainerTotalNetWeight((prev) => prev + mtWeight);
        }
      }
    }
  };

  // AgGrid 페이지 네이션
  const handleRowDataUpdated = () => {
    const isLiveGridRef = !!gridRef.current;
    if (isLiveGridRef) {
      gridRef.current?.api.forEachNode((rowNode) => {
        if (selectedContainer.includes(rowNode.data.id)) {
          rowNode.setSelected(true);
        }
      });
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file)
      // eslint-disable-next-line no-throw-literal
      throw {
        data: {
          message: t("alert:fileUploadFail"),
        },
      };
    try {
      const res = await upload({
        files: [file],
        folder: "commons/",
      }).unwrap();
      if (res) {
        await uploadInternalDoc({
          id: selectedBl,
          mediaId: res[0].id,
        }).unwrap();
        await getShippingDocuments({ id: bookingId, shipmentIdList });
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleDeleteFile = async (id: number) => {
    try {
      await deleteInternalDoc({ id }).unwrap();
      await getShippingDocuments({ id: bookingId, shipmentIdList });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    if (isBlFetchingDone) {
      (async () => {
        await getContainerInfo({ id: bookingId, shipmentIdList });
        await getContainerIntegrationInfo({ id: bookingId, shipmentIdList });
        await getShippingDocuments({ id: bookingId, shipmentIdList });
      })();
    }
  }, [
    blList,
    getContainerInfo,
    getContainerIntegrationInfo,
    bookingId,
    isBlFetchingDone,
    shipmentIdList,
    getShippingDocuments,
  ]);

  useEffect(() => {
    if (isContainerListFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isContainerListFetching]);

  const renderContainerListRightAccessory = () => {
    if (selectedContainer.length === 0) {
      /**
       * 선택한 컨테이너가 없을 때
       */
      return (
        <>
          {[
            {
              label: t(
                "shipment:importer.detail.button.connectionContractButton"
              ),
            },
            { label: t("shipment:importer.detail.button.editButton") },
            { label: t("shipment:importer.detail.button.disconnectButton") },
          ].map(({ label }) => {
            return (
              <Button
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={32}
                disabled
              >
                {label}
              </Button>
            );
          })}
        </>
      );
    } else {
      /**
       * 선택한 컨테이너가 있을 때
       */
      const container = selectedContainer[0];
      const hasSale = !!containerList.find(({ id }) => container === id)
        ?.warehousingContainer?.warehousing?.sales?.salesNumber;
      if (hasSale) {
        return (
          <>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              disabled
            >
              {t("shipment:importer.detail.button.connectionContractButton")}
            </Button>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() =>
                setDialogState(DialogState.EDIT_CONTAINER_WITH_SALE)
              }
            >
              {t("shipment:importer.detail.button.editButton")}
            </Button>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() =>
                setAlertDialogState(
                  AlertDialogState.DISCONNECT_CONTAINER_WITH_SALE
                )
              }
            >
              <Typo typoType="btn3m" color="red2">
                {t("shipment:importer.detail.button.disconnectButton")}
              </Typo>
            </Button>
          </>
        );
      } else {
        return (
          <>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() =>
                setDialogState(DialogState.CONNECT_CONTAINER_WITH_SALE)
              }
            >
              {t("shipment:importer.detail.button.connectionContractButton")}
            </Button>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              disabled
            >
              {t("shipment:importer.detail.button.editButton")}
            </Button>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              disabled
            >
              {t("shipment:importer.detail.button.disconnectButton")}
            </Button>
          </>
        );
      }
    }
  };

  const renderContainerList = () => {
    return (
      <SectionCard
        cardTitle={t("shipment:importer.detail.label.containerList")}
        rightAccessory={
          <Flex gap={8}>{renderContainerListRightAccessory()}</Flex>
        }
      >
        <ContainerInfo>
          {[
            {
              label: t("common:totalContainer"),
              value: `${totalNumberOfContainer} Containers`,
            },
            { label: t("common:totalGrossWeight"), value: totalGrossWeight },
            { label: t("common:totalUnitQty"), value: totalUnitQuantity },
          ].map(({ label, value }) => {
            return (
              <InfoItem key={label}>
                <Typo color="gray6" typoType="b9m">
                  {label}
                </Typo>
                <BreakWordTypo color="blue2" typoType="h4">
                  {value}
                </BreakWordTypo>
              </InfoItem>
            );
          })}
        </ContainerInfo>

        <Table
          ref={gridRef}
          rowData={containerList}
          columnDefs={col}
          page={page}
          pageSize={pageSize}
          totalPage={containerCount}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          onRowSelected={handleSelectionChanged}
          onRowDataUpdated={handleRowDataUpdated}
          handlePaginationClick={async (page, pageSize) => {
            try {
              await getContainerInfo({
                id: bookingId,
                shipmentIdList:
                  selectedBl === 0 ? shipmentIdList : selectedBl.toString(),
                page,
                pageSize,
              });
            } catch (e: any) {
              const message = Array.isArray(e.data.message)
                ? e.data.message[0]
                : e.data.message;
              if (e.data.statusCode !== 404) {
                alert.showAlert({ type: "error", message });
              }
            } finally {
              setPage(page);
              setPageSize(pageSize);
            }
          }}
          isRowSelectable={(node) => {
            return isRowSelectable(node);
          }}
          getRowClass={(params) => {
            return !isRowSelectable(params.node) ? "disabled-row" : "abled-row";
          }}
          onGridReady={() => setIsReady(true)}
        />
      </SectionCard>
    );
  };

  const renderShippingDocs = () => {
    return (
      <ShippingDocumentsStyledSectionCard
        cardTitle={t("shipment:importer.detail.label.shippingDocuments")}
        rightAccessory={
          selectedBl === 0 ? null : (
            <Button
              buttonColor="black"
              buttonSize={32}
              buttonGrade="tertiary"
              onClick={() =>
                shippingDocsDownloadButton.current.forEach((button) =>
                  button.click()
                )
              }
            >
              <Flex alignItems="center" justifyContent="center" gap={4}>
                <Icon iconSrc={DownloadBlackSvg} />
                {t("shipment:importer.detail.button.downloadButton")}
              </Flex>
            </Button>
          )
        }
      >
        {/* ALL 일 때 */}
        {selectedBl === 0 ? (
          <Content>
            <LabelContainer>
              <Typo typoType="b7r" color="gray5" className="blNo">
                {t("common:blNo")}
              </Typo>
              <Typo typoType="b7r" color="gray5" className="document">
                {t("shipment:importer.detail.label.document")}
              </Typo>
            </LabelContainer>

            <ScrollContainer>
              <Flex flexDirection="column" gap={16}>
                {shippingDocsList.map(
                  ({
                    blNo,
                    ciMediaUrl,
                    ciMediaOriginalFileName,
                    blMediaUrl,
                    blMediaOriginalFileName,
                    plMediaUrl,
                    plMediaOriginalFileName,
                  }) => {
                    return (
                      <Flex gap={16} alignItems="center">
                        <Typo typoType="b7m" color="gray2" className="blNo">
                          {blNo || "-"}
                        </Typo>
                        <DocumentContainer>
                          {[
                            { type: "C/I" },
                            { type: "P/L" },
                            { type: "B/L" },
                          ].map(({ type }, index) => {
                            const isLastIndex = index === 2;
                            const mediaUrl =
                              type === "C/I"
                                ? ciMediaUrl
                                : type === "P/L"
                                ? plMediaUrl
                                : blMediaUrl;
                            const originalFileName =
                              type === "C/I"
                                ? ciMediaOriginalFileName
                                : type === "P/L"
                                ? plMediaOriginalFileName
                                : blMediaOriginalFileName;
                            return (
                              <>
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  isFullWidth
                                  flexWrap="wrap"
                                  gap={16}
                                >
                                  <Typo typoType="h7">{type}</Typo>
                                  <Flex alignItems="center" gap={8}>
                                    <IconButton
                                      buttonSize={24}
                                      buttonColor="blue"
                                      buttonGrade="secondary"
                                      disabled={!mediaUrl}
                                      onClick={() => {
                                        if (mediaUrl) {
                                          downloadFile(
                                            mediaUrl,
                                            originalFileName ?? ""
                                          );
                                        }
                                      }}
                                    >
                                      <Icon
                                        iconSrc={DownloadSvg}
                                        iconSize={16}
                                      />
                                    </IconButton>
                                    <IconButton
                                      buttonSize={24}
                                      buttonColor="blue"
                                      buttonGrade="secondary"
                                      disabled={!mediaUrl}
                                    >
                                      <a
                                        href={mediaUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Flex
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <Icon
                                            iconSrc={PreviewSvg}
                                            iconSize={16}
                                          />
                                        </Flex>
                                      </a>
                                    </IconButton>
                                  </Flex>
                                </Flex>
                                {isLastIndex ? null : <Divider />}
                              </>
                            );
                          })}
                        </DocumentContainer>
                      </Flex>
                    );
                  }
                )}
              </Flex>
            </ScrollContainer>
          </Content>
        ) : (
          //  ALL 아닐때
          <Flex flexDirection="column" gap={24}>
            {[{ type: "C/I" }, { type: "P/L" }, { type: "B/L" }].map(
              ({ type }, idx) => {
                const mediaUrl =
                  type === "C/I"
                    ? shippingDocsList[0].ciMediaUrl
                    : type === "P/L"
                    ? shippingDocsList[0].plMediaUrl
                    : shippingDocsList[0].blMediaUrl;
                const originalFileName =
                  type === "C/I"
                    ? shippingDocsList[0].ciMediaOriginalFileName
                    : type === "P/L"
                    ? shippingDocsList[0].plMediaOriginalFileName
                    : shippingDocsList[0].blMediaOriginalFileName;
                const isShared =
                  type === "C/I" || type === "P/L"
                    ? !!shippingDocsList[0].ciPlSharedAt
                    : !!shippingDocsList[0].blSharedAt;

                return (
                  <DocumentContainer>
                    <Flex alignItems="center" gap={8}>
                      <Typo typoType="h3">{type}</Typo>
                      {isShared ? (
                        <Badge
                          badgeSize="S"
                          badgeColor="green6"
                          color="green1"
                          text={t("common:share")}
                        />
                      ) : (
                        <Badge
                          badgeSize="S"
                          badgeColor="gray10"
                          color="gray7"
                          text={t("common:notShare")}
                        />
                      )}
                    </Flex>

                    <Flex alignItems="center" gap={8}>
                      <IconButton
                        ref={(node) => {
                          if (node && mediaUrl) {
                            shippingDocsDownloadButton.current[idx] = node;
                          }
                        }}
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        disabled={!mediaUrl}
                        onClick={() => {
                          if (mediaUrl) {
                            downloadFile(mediaUrl, originalFileName ?? "");
                          }
                        }}
                      >
                        <Icon iconSrc={DownloadSvg} iconSize={16} />
                      </IconButton>
                      <IconButton
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        disabled={!mediaUrl}
                      >
                        <a href={mediaUrl} target="_blank" rel="noreferrer">
                          <Flex alignItems="center" justifyContent="center">
                            <Icon iconSrc={PreviewSvg} iconSize={16} />
                          </Flex>
                        </a>
                      </IconButton>
                    </Flex>
                  </DocumentContainer>
                );
              }
            )}
          </Flex>
        )}
      </ShippingDocumentsStyledSectionCard>
    );
  };

  const renderDocumentsFiles = () => {
    return (
      <DocumentStyledSectionCard
        cardTitle={t("shipment:importer.detail.label.documentsFiles")}
      >
        <Tabs role="tablist">
          <StyledTabItem
            tabValue={"share"}
            tabIndex={documentTab === "share" ? 0 : -1}
            onFocus={() => setDocumentTab("share")}
            onClick={() => setDocumentTab("share")}
            data-selected={documentTab === "share"}
          >
            <Typo typoType="b7m">
              {t("shipment:importer.detail.label.sharedDocuments")}
            </Typo>
          </StyledTabItem>
          <StyledTabItem
            tabValue={"internal"}
            tabIndex={documentTab === "internal" ? 0 : -1}
            onFocus={() => setDocumentTab("internal")}
            onClick={() => setDocumentTab("internal")}
            data-selected={documentTab === "internal"}
          >
            <Typo typoType="b7m">
              {t("shipment:importer.detail.label.internalDocuments")}
            </Typo>
          </StyledTabItem>
        </Tabs>
        {documentTab === "share" ? (
          <Flex flexDirection="column" gap={16}>
            <Flex justifyContent="space-between" alignItems="center">
              <Typo typoType="b7r" color="gray5">
                {shareMedia?.length || 0} {t("common:files")}
              </Typo>
              <Button
                buttonSize={32}
                buttonGrade="tertiary"
                buttonColor="black"
                onClick={() => {
                  downloadButton.current.forEach((button) => button.click());
                }}
              >
                <Flex alignItems="center" justifyContent="center" gap={4}>
                  <Icon iconSrc={DownloadBlackSvg} />
                  {t("shipment:importer.detail.button.downloadButton")}
                </Flex>
              </Button>
            </Flex>
            <DocumentFileSection flexDirection="column" gap={16}>
              {shareMedia?.map(({ originalFileName, mediaUrl }, idx) => {
                return (
                  <DocumentRow gap={8} alignItems="center">
                    <Typo typoType="b7m" className="document-row-title">
                      {t("common:document")} {idx + 1}
                    </Typo>

                    <Flex gap={8} style={{ width: "calc(100% - 218px)" }}>
                      <Typo
                        as="p"
                        typoType="b7r"
                        color="gray5"
                        className="ellipsis"
                      >
                        {originalFileName}
                      </Typo>
                    </Flex>
                    <Flex gap={4}>
                      <IconButton
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        onClick={() =>
                          downloadFile(mediaUrl ?? "", originalFileName ?? "")
                        }
                        ref={(node) => {
                          const isAlreadyInRef = downloadButton.current.some(
                            (button) => button.isSameNode(node)
                          );
                          if (node && !isAlreadyInRef) {
                            downloadButton.current = [
                              ...downloadButton.current,
                              node,
                            ];
                          }
                        }}
                      >
                        <Icon iconSrc={DownloadSvg} iconSize={16} />
                      </IconButton>
                      <IconButton
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        onClick={() => {
                          window.open(mediaUrl, "_blank");
                        }}
                      >
                        <Icon iconSrc={PreviewSvg} iconSize={16} />
                      </IconButton>
                    </Flex>
                  </DocumentRow>
                );
              })}
            </DocumentFileSection>
          </Flex>
        ) : (
          <Flex flexDirection="column" gap={16}>
            <Flex justifyContent="space-between" alignItems="center">
              <Typo typoType="b7r" color="gray5">
                {internalMedia?.length || 0} {t("common:files")}
              </Typo>

              <Flex gap={8}>
                <Button
                  buttonSize={32}
                  buttonGrade="tertiary"
                  buttonColor="black"
                  onClick={() => {
                    downloadButton.current.forEach((button) => button.click());
                  }}
                >
                  {t("shipment:importer.detail.button.downloadButton")}
                </Button>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event)}
                />
                {selectedBl === 0 ? null : (
                  <Button
                    buttonSize={32}
                    buttonGrade="tertiary"
                    buttonColor="black"
                    onClick={() => {
                      if (fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    {t("common:addFiles")}
                  </Button>
                )}
              </Flex>
            </Flex>
            <DocumentFileSection flexDirection="column" gap={16}>
              {internalMedia?.map(({ originalFileName, mediaUrl, id }, idx) => {
                return (
                  <DocumentRow gap={8} alignItems="center">
                    <Typo typoType="b7m" className="document-row-title">
                      {t("common:document")} {idx + 1}
                    </Typo>

                    <Flex
                      gap={8}
                      style={{
                        width: "calc(100% - 150px - 16px - 82px)",
                      }}
                    >
                      <Typo
                        as="p"
                        typoType="b7r"
                        color="gray5"
                        className="ellipsis"
                      >
                        {originalFileName}
                      </Typo>
                    </Flex>
                    <Flex gap={4}>
                      <IconButton
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        onClick={() =>
                          downloadFile(mediaUrl ?? "", originalFileName ?? "")
                        }
                        ref={(node) => {
                          const isAlreadyInRef = downloadButton.current.some(
                            (button) => button.isSameNode(node)
                          );
                          if (node && !isAlreadyInRef) {
                            downloadButton.current = [
                              ...downloadButton.current,
                              node,
                            ];
                          }
                        }}
                      >
                        <Icon iconSrc={DownloadSvg} iconSize={16} />
                      </IconButton>
                      <IconButton
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        onClick={() => {
                          window.open(mediaUrl, "_blank");
                        }}
                      >
                        <Icon iconSrc={PreviewSvg} iconSize={16} />
                      </IconButton>
                      <IconButton
                        buttonSize={24}
                        buttonColor="red"
                        buttonGrade="secondary"
                        disabled={isRemoving}
                        onClick={() => {
                          handleDeleteFile(id);
                        }}
                      >
                        <Icon iconSrc={DeleteSvg} iconSize={16} />
                      </IconButton>
                    </Flex>
                  </DocumentRow>
                );
              })}
            </DocumentFileSection>
          </Flex>
        )}
      </DocumentStyledSectionCard>
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) {
      return null;
    }
    if (dialogState === DialogState.CONNECT_CONTAINER_WITH_SALE) {
      return (
        <ConnectSaleDialog
          selectedContainer={selectedContainer}
          totalNetWeight={selectedContainerTotalNetWeight}
          onClose={() => setDialogState(DialogState.NULL)}
          refetchContainer={async () => {
            if (originalArgs) {
              await getContainerInfo(originalArgs);
              criteria.current = null;
              setSelectedContainer([]);
              setSelectedContainerList([]);
              setSelectedContainerTotalNetWeight(0);
            }
          }}
          itemCode={
            containerList.find(({ id }) => id === selectedContainer[0])
              ?.contract.importerItemCode.itemCode || ""
          }
          mainCategory={
            containerList.find(({ id }) => id === selectedContainer[0])
              ?.contract.importerItem.mainCategory.codeItemName || ""
          }
        />
      );
    }
    if (dialogState === DialogState.EDIT_CONTAINER_WITH_SALE) {
      return (
        <EditConnectSaleDialog
          selectedContainer={selectedContainerList}
          totalNetWeight={selectedContainerTotalNetWeight}
          onClose={() => setDialogState(DialogState.NULL)}
          refetchContainer={async () => {
            if (originalArgs) {
              await getContainerInfo(originalArgs);
              criteria.current = null;
              setSelectedContainer([]);
              setSelectedContainerList([]);
              setSelectedContainerTotalNetWeight(0);
            }
          }}
          itemCode={
            containerList.find(({ id }) => id === selectedContainer[0])
              ?.contract.importerItemCode.itemCode || ""
          }
          mainCategory={
            containerList.find(({ id }) => id === selectedContainer[0])
              ?.contract.importerItem.mainCategory.codeItemName || ""
          }
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) {
      return null;
    }

    if (alertDialogState === AlertDialogState.DISCONNECT_CONTAINER_WITH_SALE) {
      return (
        <AlertDialog
          open
          title={t("shipment:importer.detail.dialogTitle.disconnect")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onCancel={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={async () => {
            try {
              setAlertDialogState(AlertDialogState.NULL);
              await disconnectWarehouseWithSale({
                containerIdList: selectedContainer,
              }).unwrap();
              alert.showAlert({
                type: "success",
                message: t(
                  "shipment:importer.detail.alert.theContractHasBeenDisconnected"
                ),
              });
            } catch (e) {
              alert.showAlert({
                type: "success",
                message: t(
                  "shipment:importer.detail.alert.failedToDisconnectContract"
                ),
              });
            } finally {
              if (originalArgs) {
                await getContainerInfo(originalArgs);
                criteria.current = null;
                setSelectedContainer([]);
                setSelectedContainerList([]);
                setSelectedContainerTotalNetWeight(0);
              }
            }
          }}
          cancelText={t("shipment:importer.detail.button.cancelButton")}
          okText={t("shipment:importer.detail.button.okButton")}
        >
          {t("shipment:importer.detail.description.disconnectDescription")}
        </AlertDialog>
      );
    }
  };

  return (
    <SectionFoldableCard
      open={open}
      onOpenChange={setOpen}
      cardTitle={t("shipment:importer.detail.label.containerInformation")}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={open}
          onClick={() => setOpen((open) => !open)}
        />
      }
    >
      <Flex gap={16}>
        <BlList>
          <BlListItem
            data-selected={selectedBl === 0}
            onClick={async () => {
              setSelectedBl(0);
              await getContainerInfo({
                id: bookingId,
                shipmentIdList,
              });
              await getContainerIntegrationInfo({
                id: bookingId,
                shipmentIdList,
              });
              await getShippingDocuments({ id: bookingId, shipmentIdList });
            }}
          >
            {t("common:all")}
          </BlListItem>
          {isBlFetching ? (
            <BlLodingContainer alignItems="center" justifyContent="center">
              <Loader size={20} />
            </BlLodingContainer>
          ) : (
            <>
              {blList.map(({ id, blNo }) => {
                return (
                  <BlListItem
                    data-selected={selectedBl === id}
                    onClick={async () => {
                      setSelectedBl(id);
                      await getContainerInfo({
                        id: bookingId,
                        shipmentIdList: id.toString(),
                      });
                      await getContainerIntegrationInfo({
                        id: bookingId,
                        shipmentIdList: id.toString(),
                      });
                      await getShippingDocuments({
                        id: bookingId,
                        shipmentIdList: id.toString(),
                      });
                    }}
                  >
                    {blNo || "-"}
                  </BlListItem>
                );
              })}
            </>
          )}
        </BlList>

        <Flex flex={1} flexDirection="column" gap={16}>
          {renderContainerList()}

          <ShippingDocsAndDocsFilesContainer>
            {renderShippingDocs()}
            {renderDocumentsFiles()}
          </ShippingDocsAndDocsFilesContainer>
        </Flex>
      </Flex>
      {renderDialog()}
      {renderAlertDialog()}
    </SectionFoldableCard>
  );
};

export default ContainerListCard;

const BlList = styled.ul`
  width: 220px;
  flex-shrink: 0;
  padding: 0 8px;
  border-right: 1px solid ${colorSet.gray9};
`;

const BlListItem = styled.li`
  padding: 12px 8px;
  cursor: pointer;
  border-radius: 8px;

  ${typo.b9m}
  &[data-selected="true"] {
    color: ${colorSet.blue4};
    background-color: ${colorSet.blue10};
  }
`;

const BlLodingContainer = styled(Flex)`
  padding: 12px 0;
`;

const ContainerInfo = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const InfoItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;
  padding: 16px 8px;
  border-right: 1px solid ${colorSet.gray10};
  text-align: center;

  &:last-child {
    border-right: none;
  }
`;

const DocumentStyledSectionCard = styled(SectionCard)`
  width: 400px;
  flex-shrink: 0;
`;

const ShippingDocumentsStyledSectionCard = styled(SectionCard)`
  flex: 1;
`;

const Content = styled.div`
  position: relative;
`;

const LabelContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  gap: 16px;
  align-items: center;
  background: ${colorSet.white};

  .blNo {
    width: 126px;
    flex-shrink: 0;
  }

  .document {
    flex: 1;
  }
`;

const ScrollContainer = styled.div`
  overflow: auto;
  max-height: 238px;
  padding-top: 32px;

  .blNo {
    width: 126px;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .document {
    flex: 1;
  }
`;

const DocumentContainer = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const Divider = styled.div`
  border-left: 1px solid ${colorSet.gray9};
  height: 24px;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${colorSet.gray10};
  margin-bottom: 16px;
  width: 100%;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-shrink: 0;
  padding: 10px 0;
  border: none;
  cursor: pointer;
  background: none;

  &[data-selected="true"] {
    padding: 10px 13px 8px 13px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const DocumentRow = styled(Flex)`
  .document-row-title {
    width: 150px;
    flex-shrink: 0;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const DocumentFileSection = styled(Flex)`
  overflow: auto;
  height: 125px;
  position: relative;
`;

const ShippingDocsAndDocsFilesContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1600px) {
    flex-direction: column;

    ${DocumentStyledSectionCard} {
      width: 100%;
    }
  }
`;
