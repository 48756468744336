import { useEffect } from "react";
import { styled } from "styled-components";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "@/src/hooks/useAlert";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { useLazyGetNoticeQuery } from "@/src/store/apis/notice";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import Typo from "@/src/components/atom/Typo";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { useTranslation } from "react-i18next";

const ExporterNoticeDetailPage = () => {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [getNotice, noticeResult] = useLazyGetNoticeQuery();

  useEffect(() => {
    (async () => {
      try {
        await getNotice({ id: Number(id) }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ message, type: "error" });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:notice")]}
      pageTitle={t("sideNav:notice")}
    >
      <Article>
        <StyledSectionCardWithoutHeader>
          <Wrapper>
            <NoticeTitleContainer>
              <NoticeTitle>
                {i18n.language === "en"
                  ? noticeResult.currentData?.titleEn
                  : noticeResult.currentData?.titleKr}
              </NoticeTitle>
              <Typo typoType="b7r" color="gray6" className="flex-shrink-0">
                {dayjs(noticeResult.currentData?.createdAt).format(
                  "YYYY-MM-DD"
                )}
              </Typo>
            </NoticeTitleContainer>
            <NoticeContent
              className="ck ck-content"
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "en"
                    ? noticeResult.currentData?.bodyEn || "-"
                    : noticeResult.currentData?.bodyKr || "-",
              }}
            />
          </Wrapper>
        </StyledSectionCardWithoutHeader>

        <BottomFixedContainer>
          <FooterButtonSection>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
              {t("common:backToPrevious")}
            </Button>
          </FooterButtonSection>
        </BottomFixedContainer>
      </Article>
    </ExporterMainLayout>
  );
};

export default ExporterNoticeDetailPage;

const Article = styled.article`
  margin-bottom: 72px;
`;

const NoticeTitleContainer = styled.h2`
  padding: 16px;
  ${typo.h4}
  border-bottom: 1px solid ${colorSet.gray8};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const NoticeTitle = styled.p`
  word-break: break-all;
`;

const NoticeContent = styled.div`
  padding: 24px 40px;

  ${typo.b7r}
  * {
    all: revert;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    line-height: normal;
  }
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  min-height: 860px;
`;

const Wrapper = styled.div`
  padding: 16px;
`;
