import { PaymentCycleType } from "@/src/store/apis/subscription/interface";
import { TFunction } from "i18next";

export const convertPaymentTypeToString = (
  plan: PaymentCycleType,
  t: TFunction
) => {
  switch (plan) {
    case "MONTHLY":
      return t("normalPlanSignup:paymentTypeMonthly");

    case "ANNUALLY":
      return t("normalPlanSignup:paymentTypeAnnually");

    default:
      throw new Error("Unknown paymentType type");
  }
};
