import styled from "styled-components";
import colorSet from "@/src/styles/color";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import VerificationInputDiv from "@/src/components/molecule/VerificationInputDiv";
import typo from "@/src/styles/typography";
import MailSvg from "@/src/assets/icons/icon-mail.svg";
import { useEffect, useRef, useState } from "react";
import { ONLY_NUMBER } from "@/src/components/molecule/VerificationInputDiv/constant";
import { Button } from "@/src/components/atom/Button";
import {
  useStaffSignUpResourceContext,
  useStaffSignUpStepContext,
} from "./hooks";
import { useNavigate } from "react-router-dom";
import { useLazyGetExporterQuery } from "@/src/store/apis/auth";
import useAlert from "@/src/hooks/useAlert";
import BasicCard, {
  BasicCardTitleContainer,
} from "@/src/components/atom/Cards/BasicCard";
import CallOut from "@/src/components/molecule/CallOut";
import InfoSvg from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import { ReactComponent as ChevronRightSvg } from "@/src/assets/icons/icon-chevron-right.svg";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";

const StaffSignUpStepOne = () => {
  const { t } = useTranslation();
  const { onStepNext } = useStaffSignUpStepContext();
  const { onResourceChange, corporateCode } = useStaffSignUpResourceContext();
  const [getExporterCompanyInfo, exporterCompanyInfoResult] =
    useLazyGetExporterQuery();
  const navigate = useNavigate();
  const alert = useAlert();
  const [verification, setVerification] = useState(corporateCode);
  const ref = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    ref.current[0].focus();
  }, []);

  const goTo = (path: keyof typeof PUBLIC_PATH) => navigate(PUBLIC_PATH[path]);

  const handleNextClick = async () => {
    try {
      const [companyInfo] = await getExporterCompanyInfo({
        companyCode: verification.join(""),
      }).unwrap();

      onResourceChange("corporateCode", verification);
      onResourceChange("companyInfo", companyInfo);
      onResourceChange("personalContactPrefix", companyInfo.telPrefix);
      onResourceChange("officeContactPrefix", companyInfo.telPrefix);
      onResourceChange("companyType", companyInfo.companyType);
      onStepNext();
    } catch (e: any) {
      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  return (
    <StyledCard
      title={
        <BasicCardTitleContainer>
          {t("signupStaff:steps.firstStep")}
        </BasicCardTitleContainer>
      }
    >
      <Content>
        <CallOut
          style={{ marginBottom: "8px" }}
          icon={<Icon iconSrc={InfoSvg} />}
          value={
            <CalloutContent>
              <Typo typoType="b9m" color="indigo">
                {t("signupStaff:content.staffSignUpCallout")}
              </Typo>

              <CalloutLink onClick={() => goTo("CORP_SIGNUP")}>
                <BlueText typoType="b9m" color="systemBlue2">
                  {t("signupStaff:content.staffSignUpCalloutLink")}
                </BlueText>
                <IconContainer>
                  <ChevronRightIcon />
                </IconContainer>
              </CalloutLink>
            </CalloutContent>
          }
        />

        <Icon iconSrc={MailSvg} iconSize={80} />

        <Typo className="card-title" as="h3" typoType="d6">
          {t("signupStaff:content.enterCompanyCodeTitle")}
        </Typo>
        <Typo className="card-description" as="p" typoType="b7m">
          {t("signupStaff:content.enterCompanyCodeDescription")}
        </Typo>

        <VerificationDiv>
          <Typo typoType="b7m" color="gray2">
            {t("signupStaff:content.companyCode")}
            <strong> *</strong>
          </Typo>

          <VerificationInputDivContainer
            onKeyDown={(e) => {
              if (e.code === "Enter" && verification.every((item) => item)) {
                handleNextClick();
              }
            }}
          >
            {verification.map((_, idx) => {
              return (
                <VerificationInputDiv
                  key={idx.toString()}
                  ref={(node) => node && ref.current.push(node)}
                  char={verification[idx]}
                  allowKey={ONLY_NUMBER}
                  style={idx === 3 ? { marginRight: "20px" } : undefined}
                  onKeyDown={(e) => {
                    const key = e.key;
                    if (key === "ArrowLeft") {
                      if (idx - 1 !== -1) {
                        ref.current[idx - 1].focus();
                      }
                    }
                    if (key === "ArrowRight") {
                      if (idx + 1 !== verification.length) {
                        ref.current[idx + 1].focus();
                      }
                    }
                    if ((e.ctrlKey || e.metaKey) && e.key === "v") {
                      navigator.clipboard.readText().then((res) => {
                        if (/^\d+$/g.test(res)) {
                          const overridden = Array.from(
                            { length: 8 },
                            () => ""
                          );
                          const pasteText = res
                            .split("")
                            .slice(0, 8)
                            .concat(overridden)
                            .slice(0, 8);
                          setVerification(pasteText);
                        } else {
                          alert.showAlert({
                            message: t("error:onlyNumber"),
                            type: "error",
                          });
                        }
                      });
                    }
                  }}
                  onCharChange={(char) => {
                    if (!!verification[idx]) {
                      if (idx + 1 !== verification.length) {
                        ref.current[idx + 1].focus();
                      }
                      return;
                    }
                    const copied = verification.slice();
                    copied.splice(idx, 1, char ?? "");
                    setVerification(copied);
                    if (idx + 1 !== verification.length) {
                      ref.current[idx + 1].focus();
                    }
                  }}
                  onBackSpaceKeyDown={() => {
                    const copied = verification.slice();
                    copied.splice(idx, 1, "");
                    setVerification(copied);
                    if (idx - 1 !== -1) {
                      ref.current[idx - 1].focus();
                    }
                  }}
                />
              );
            })}
          </VerificationInputDivContainer>
        </VerificationDiv>

        <ButtonContainer>
          <StyledButton
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={40}
            onClick={() => goTo("SIGNUP")}
          >
            <Typo typoType="btn3m">{t("common:back")}</Typo>
          </StyledButton>

          <StyledButton
            disabled={!verification.every((item) => item)}
            onClick={handleNextClick}
            isLoading={exporterCompanyInfoResult.isFetching}
          >
            {t("common:next")}
          </StyledButton>
        </ButtonContainer>
      </Content>
    </StyledCard>
  );
};

export default StaffSignUpStepOne;

const StyledCard = styled(BasicCard)`
  width: 640px;
`;

const VerificationDiv = styled.div`
  padding-top: 40px;

  strong {
    color: ${colorSet.red2};
  }
`;

const VerificationInputDivContainer = styled.div`
  display: flex;
  gap: 4px;
  padding-top: 8px;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  text-align: center;
  ${typo.btn3m};
  width: 480px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  .card-title {
    padding-top: 40px;
  }

  .card-description {
    text-align: center;
    word-break: keep-all;
    white-space: pre-wrap;
    padding-top: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  gap: 8px;
  padding-top: 16px;

  ${StyledButton} {
    text-align: center;
    flex: 1;
  }
`;

const CalloutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CalloutLink = styled.div`
  display: flex;
  gap: 2px;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  cursor: pointer;
  word-break: break-all;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ChevronRightIcon = styled(ChevronRightSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.systemBlue2};
  }
`;
