import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { formatCreditCardMasking } from "../utils/formatCreditCardMasking";
import { PAYMENT_STATUS_STRINGS } from "../constant";
import { CELL_TYPE_UNDER_LINE_LINK_ACTION } from "@/src/utils/row-data-util";
import { getAmountOfPayment } from "../utils/getPayments";

export const columnPaymentList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    sortable: false,
    minWidth: 104,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.createdAt ? (
        <Typo typoType="b9r">
          {dayjs(params.data.createdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "refundDateAt",
    sortable: false,
    minWidth: 104,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.refund?.refundDateAt ? (
        <Typo typoType="b9r">
          {dayjs(params.data.refund?.refundDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "paymentDetail",
    sortable: false,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.paymentDetail ? (
        <Typo typoType="b9r">{params.data.paymentDetail}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "paymentStatus",
    sortable: false,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      const t = params.data.t;

      if (params.data.paymentStatus === "PAYMENT_FAIL") {
        return (
          <Typo typoType="b9r" color="red2">
            {t(PAYMENT_STATUS_STRINGS.cancel_success)}
          </Typo>
        );
      }

      return params.data.paymentStatus ? (
        <Typo typoType="b9r">
          {t(
            PAYMENT_STATUS_STRINGS[
              String(
                params.data.paymentStatus
              ).toLowerCase() as keyof typeof PAYMENT_STATUS_STRINGS
            ]
          )}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "paymentNo",
    sortable: false,
    minWidth: 140,
    cellRenderer: CELL_TYPE_UNDER_LINE_LINK_ACTION,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "cardNumber",
    sortable: false,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.paymentMethod?.cardNumber ? (
        <Typo typoType="b9r">
          {formatCreditCardMasking(params.data.paymentMethod?.cardNumber)}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "price",
    sortable: false,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      const price = getAmountOfPayment({
        exchangeRate: Number(params.data.exchangeRate),
        price: Number(params.data.price),
        currencyUnit: params.data.currencyUnit,
      });

      return params.data.price ? (
        <Typo typoType="b9r">
          {Number(price - price / 10).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          {params.data.currencyUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "tax",
    sortable: false,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      const price = getAmountOfPayment({
        exchangeRate: Number(params.data.exchangeRate),
        price: Number(params.data.price),
        currencyUnit: params.data.currencyUnit,
      });

      return price ? (
        <Typo typoType="b9r">
          {Number(price / 10).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          {params.data.currencyUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "amount",
    sortable: false,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      const price = getAmountOfPayment({
        exchangeRate: Number(params.data.exchangeRate),
        price: Number(params.data.price),
        currencyUnit: params.data.currencyUnit,
      });

      return (
        <Typo typoType="b9r">
          {price.toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          {params.data.currencyUnit}
        </Typo>
      );
    },
  },
];
