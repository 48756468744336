import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useForm } from "react-hook-form";
import { emailRegex } from "@/src/utils/regex";
import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import FormItem from "@/src/components/molecule/FormItem";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface SharedEmailBuyerEmployeeAddDialogProps {
  defaultCountryCode?: string;
  onSave: (values: {
    name: string;
    email: string;
    isActivated: string;
    personalContact?: string;
    officeContact?: string;
    buyerEmployeeRemark?: string;
    personalContactPrefix?: string;
    officeContactPrefix?: string;
  }) => void;
  onClose: () => void;
}

const DEFAULT_VALUE = {
  name: "",
  email: "",
  personalContact: "",
  officeContact: "",
  isActivated: "Y",
  buyerEmployeeRemark: "",
};

const SharedEmailBuyerEmployeeAddDialog = ({
  defaultCountryCode,
  onClose,
  onSave,
}: SharedEmailBuyerEmployeeAddDialogProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, register, setValue, getValues, setFocus } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onBlur",
      defaultValues: {
        ...DEFAULT_VALUE,
        personalContactPrefix: defaultCountryCode,
        officeContactPrefix: defaultCountryCode,
      },
    });
  const ref = useRef<HTMLButtonElement | null>(null);
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState<boolean>(false);

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  return (
    <Dialog
      title={t("clientManagement:exporter.buyerListAddPage.addContactPerson")}
      open
      onOpenChange={onClose}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => setIsCancelAlertOpen(true)}
          >
            {t("clientManagement:exporter.common.exit")}
          </Button>
          <Button onClick={() => ref.current?.click()}>
            {t("clientManagement:exporter.common.save")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Form onSubmit={handleSubmit(onSave)}>
        <FormItem
          type="text"
          direction="vertical"
          label={t("clientManagement:exporter.common.name")}
          control={control}
          name="name"
          rules={{ required: true }}
          inputProps={{
            placeholder: t("clientManagement:exporter.placeholder.name"),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />
        <FormItem
          type="text"
          direction="vertical"
          label={t("clientManagement:exporter.common.email")}
          control={control}
          name="email"
          rules={{ required: true, pattern: emailRegex }}
          inputProps={{
            placeholder: t("clientManagement:exporter.placeholder.email"),
          }}
          errorsMessage={{
            required: t("error:required"),
            pattern: t("error:login.emailTypeMissMatching"),
          }}
        />
        <ContactFormItem
          register={register}
          setValue={setValue}
          label={t("clientManagement:exporter.common.personalContact")}
          direction="vertical"
          prefixName="personalContactPrefix"
          restContactName="personalContact"
          prefixValue={getValues("personalContactPrefix") || undefined}
          isRequired={false}
        />
        <ContactFormItem
          register={register}
          setValue={setValue}
          label={t("clientManagement:exporter.common.officeContact")}
          direction="vertical"
          prefixName="officeContactPrefix"
          restContactName="officeContact"
          prefixValue={getValues("officeContactPrefix") || undefined}
          isRequired={false}
        />

        <FormItem
          type="textarea"
          name="buyerEmployeeRemark"
          direction="vertical"
          label={t("clientManagement:exporter.common.remark")}
          control={control}
          inputProps={{
            style: { width: "100%", height: "150px" },
            placeholder: t("clientManagement:exporter.placeholder.remark"),
            maxLength: 100,
          }}
        />

        {isCancelAlertOpen && (
          <CancelAlertDialog
            open={isCancelAlertOpen}
            onOpenChange={setIsCancelAlertOpen}
            onOk={() => {
              onClose();
              setIsCancelAlertOpen(false);
            }}
          />
        )}

        <Hidden type="submit" ref={ref} />
      </Form>
    </Dialog>
  );
};

export default SharedEmailBuyerEmployeeAddDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
