import AgGridRadio from "@/src/components/atom/Radio/AgGridRadio";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
};

export const columnLoadContract = (
  radioOnChange: (id: number | null) => void
): ColDef[] => [
  {
    headerName: "",
    field: "radioButton",
    width: 60,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <AgGridRadio
          checked={params.data.isChecked}
          onChange={() => radioOnChange(params.data.id)}
        />
      );
    },
    lockPosition: "left",
    pinned: "left",
    sortable: false,
    resizable: false,
  },
  {
    field: "buyerNameCode",
    width: 180,
    ...commonColumn,
  },
  {
    field: "createdAt",
    width: 180,
    cellRenderer: (params: ValueGetterParams) => {
      return <>{dayjs(params.data.createdAt).format("YYYY-MM-DD")}</>;
    },
    ...commonColumn,
  },
  {
    field: "scNo",
    width: 140,
    ...commonColumn,
  },
  {
    field: "itemCode",
    width: 110,
    ...commonColumn,
  },
  {
    field: "quantity",
    width: 110,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <>
          {Number(params.data.quantity).toLocaleString()}{" "}
          {params.data.quantityUnit}
        </>
      );
    },
    cellStyle: { textAlign: "right" },
  },
  {
    field: "unitPrice",
    width: 110,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <>
          {Number(params.data.unitPrice).toLocaleString()}{" "}
          {params.data.unitPriceUnit}
        </>
      );
    },
    cellStyle: { textAlign: "right" },
  },
  {
    field: "shippingTerms",
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <>
          {params.data.shippingTerms} {params.data.shippingTermsRemark ?? ""}
        </>
      );
    },
    width: 160,
    ...commonColumn,
  },
  {
    field: "placeOfDelivery",
    width: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <>
          {params.data.ValueGetterParamsPortIn ??
            params.data.placeOfDelivery ??
            "-"}
        </>
      );
    },
    ...commonColumn,
  },
  {
    field: "origin",
    width: 150,
    cellRenderer: (params: ValueGetterParams) => {
      return <>{params.data.origin ?? params.data.origin ?? "-"}</>;
    },
    ...commonColumn,
  },
  {
    field: "lastShipmentDateAt",
    cellRenderer: (params: ValueGetterParams) => {
      return <>{dayjs(params.data.createdAt).format("YYYY-MM-DD")}</>;
    },
    width: 180,
    ...commonColumn,
  },
];
