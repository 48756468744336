import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { CELL_TYPE_VIEW_ACTION } from "@/src/utils/row-data-util";
import getTableIndex from "@/src/utils/getTableIndex";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import { TFunction } from "i18next";

export const columnStoreList = (t: TFunction): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:importer.detail.bookingList.bookingNo"),
    cellStyle: { textAlign: "center" },
    field: "purchaseOrderNo",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.sales?.salesNumber ? (
        <TableBlueText typoType="b9r">
          {params.data.sales?.salesNumber}
        </TableBlueText>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:importer.detail.bookingList.portOfLoading"),
    cellStyle: { textAlign: "center" },
    field: "receiptDate",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.warehousedAt ? (
        <Typo typoType="b9r">
          {dayjs(params.data.warehousedAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:importer.detail.bookingList.portOfLoading"),
    cellStyle: { textAlign: "center" },
    field: "receiptQty",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.warehousedAmount ? (
        <Typo typoType="b9r">{params.data.warehousedAmount}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:importer.detail.bookingList.portOfLoading"),
    cellStyle: { textAlign: "center" },
    field: "warehouse",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.sales?.warehouse ? (
        <Typo typoType="b9r">
          {params.data.sales?.warehouse?.warehouseName}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:importer.detail.bookingList.portOfLoading"),
    cellStyle: { textAlign: "center" },
    field: "receiptCertificate",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.warehousingMedia ? (
        <Typo typoType="b9r">
          {params.data.warehousingMedia.originalFileName}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:importer.detail.bookingList.portOfLoading"),
    cellStyle: { textAlign: "center" },
    field: "numberOfContainers",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfContainer ? (
        <Typo typoType="b9r">
          {params.data.numberOfContainer} {params.data.t("common:ea")}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "containerDetails",
    lockPosition: "right",
    pinned: "right",
    resizable: false,
    width: 140,
    sortable: false,
    cellRenderer: CELL_TYPE_VIEW_ACTION,
  },
];

// 발주 상세 페이지 내 입고목록의 컨테이너
export const columnSalesContainerList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: false,
    cellStyle: { textAlign: "center" },
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "containerNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.containerNo) {
        return <Typo typoType="b9r">{params.data.containerNo}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "sealNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.sealNo) {
        return <Typo typoType="b9r">{params.data.sealNo}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "unitQuantity",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.unitQuantity) {
        return <Typo typoType="b9r">{params.data.unitQuantity}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "netWeight",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data?.netWeight) {
        return <Typo typoType="b9r">{params.data?.netWeight}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "unitPrice",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data?.unitPrice && params.data.unitPriceUnit) {
        return (
          <Typo typoType="b9r">
            {Number(params.data?.unitPrice).toLocaleString("ko-KR")}{" "}
            {params.data?.unitPriceUnit}
          </Typo>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "taskNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.taskNo) {
        return <Typo typoType="b9r">{params.data.taskNo}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "blNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.blNo) {
        return (
          <TableBlueText typoType="b9r" color="systemBlue2">
            {params.data.blNo}
          </TableBlueText>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "poNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.poNo) {
        return (
          <TableBlueText typoType="b9r" color="systemBlue2">
            {params.data.poNo}
          </TableBlueText>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
];
