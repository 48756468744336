import React, { ReactNode, useState } from "react";
import dayjs from "dayjs";
import styled, { keyframes } from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ReactComponent as ArrowDownSvg } from "@/src/assets/icons/icon-chevron-down.svg";
import ContainerIllustSvg from "@/src/assets/icons/illust-containers.svg";
import { isNull } from "@/src/utils/is";
import SaleContent from "./SaleContent";
import { SalesDto } from "@/src/store/apis/sales/interface";
import Flex from "@/src/components/molecule/Flex";
import DatePicker from "@/src/components/atom/DatePicker";
import FileComponent from "@/src/components/atom/File";
import {
  useConnectWarehouseWithSaleMutation,
  useLazyGetWarehousingWithSaleQuery,
} from "@/src/store/apis/sales";
import { MediaDto } from "@/src/store/apis/media/interface";
import Icon from "@/src/components/atom/Icon";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface ConnectSaleDialogProps {
  onClose: () => void;
  selectedContainer: number[];
  itemCode: string;
  mainCategory: string;
  totalNetWeight: number;
  refetchContainer: () => void;
}

interface AccordionItemProps {
  value: string;
  renderTrigger?: ReactNode;
  renderContent?: ReactNode;
  disabled: boolean;
}

type AccordionItemType = "sale" | "contract";

const ConnectSaleDialog = ({
  onClose,
  selectedContainer,
  itemCode,
  mainCategory,
  totalNetWeight,
  refetchContainer,
}: ConnectSaleDialogProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<AccordionItemType>("sale");
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<
    number | "all"
  >("all");
  const [salesPage, setSalesPage] = useState(1);
  const [selectedSale, setSelectedSale] = useState<SalesDto | null>(null);
  const [warehouseInfo, setWarehouseInfo] = useState<{
    at?: string;
    file?: MediaDto;
  }>({
    at: undefined,
    file: undefined,
  });

  const [getWarehousing] = useLazyGetWarehousingWithSaleQuery();
  const [connectWarehouseWithSale, { isLoading }] =
    useConnectWarehouseWithSaleMutation();

  const getDisabledState = (value: AccordionItemType) => {
    if (value === "contract") {
      if (isNull(selectedSale)) return true;
    }

    return false;
  };

  const renderAccordionTitle = (value: AccordionItemType) => {
    switch (value) {
      case "sale": {
        return (
          <AccordionTitleSection>
            <h3>
              {t("common:dynamicStep", { count: 1 })}
              {t("shipment:importer.detail.label.selectContractNo")}{" "}
              <strong>*</strong>
            </h3>

            <AccordionController>
              <ArrowIcon className="arrow" color="gray2" />
            </AccordionController>
          </AccordionTitleSection>
        );
      }
      case "contract": {
        return (
          <AccordionTitleSection data-disabled={getDisabledState("contract")}>
            <h3>
              {t("common:dynamicStep", { count: 2 })}{" "}
              {t("shipment:importer.detail.label.registerSalesInformation")}
            </h3>

            <AccordionController>
              <ArrowIcon
                className="arrow"
                color={getDisabledState("contract") ? "gray7" : "gray2"}
              />
            </AccordionController>
          </AccordionTitleSection>
        );
      }
    }
  };

  const renderAccordionContent = (value: AccordionItemType) => {
    switch (value) {
      case "sale": {
        return (
          <SaleContent
            selectedSale={selectedSale}
            selectedWarehouse={selectedWarehouseId}
            salesPage={salesPage}
            itemCode={itemCode}
            mainCategory={mainCategory}
            onPageChange={(page) => setSalesPage(page)}
            onWarehouseChange={(warehouse: number | "all") =>
              setSelectedWarehouseId(warehouse)
            }
            onSaleChange={(sale) => {
              if (sale?.id !== selectedSale?.id) {
                setSelectedSale(sale);
                setTab("contract");
                setWarehouseInfo({
                  at: undefined,
                  file: undefined,
                });
              }
            }}
          />
        );
      }
      case "contract": {
        if (!selectedSale) return null;
        return (
          <ContentContainer alignItems="center" gap={16}>
            <Flex alignItems="center" flex={1}>
              <Typo typoType="b7m" className="label">
                {t("shipment:importer.detail.label.receivingDate")}
              </Typo>

              <StyledDatePicker
                getPopupContainer={(triggerNode: any) => {
                  return triggerNode.parentElement;
                }}
                format={"YYYY-MM-DD"}
                value={warehouseInfo.at ? dayjs(warehouseInfo.at) : undefined}
                onChange={async (value) => {
                  const warehousedAt = dayjs(value)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .toISOString();
                  const response = await getWarehousing({
                    id: selectedSale.id,
                    warehousedAt,
                  });

                  setWarehouseInfo((prev) => ({
                    ...prev,
                    at: warehousedAt,
                    file: response.data?.rows[0].warehousingMedia || undefined,
                  }));
                }}
              />
            </Flex>
            <Divider />
            <Flex alignItems="center" flex={1}>
              <Typo typoType="b7m" className="label">
                {t("shipment:importer.detail.label.receiptCertificate")}
              </Typo>

              <FileComponent
                defaultFileList={
                  warehouseInfo.file
                    ? [
                        new File(
                          [""],
                          warehouseInfo.file?.originalFileName ?? ""
                        ),
                      ]
                    : []
                }
                onChange={(file) =>
                  setWarehouseInfo((prev) => ({ ...prev, file: file[0] }))
                }
                onRemove={() =>
                  setWarehouseInfo((prev) => ({ ...prev, file: undefined }))
                }
              />
            </Flex>
          </ContentContainer>
        );
      }
    }
  };

  return (
    <Dialog
      title={t("shipment:importer.detail.label.connectionContractButton")}
      open
      onOpenChange={onClose}
      width={1200}
      footer={
        <DialogFooterContainer>
          <Button
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={40}
            onClick={onClose}
          >
            {t("shipment:importer.detail.button.cancelButton")}
          </Button>
          <Button
            disabled={!selectedSale || isLoading}
            isLoading={isLoading}
            onClick={async () => {
              if (selectedSale) {
                await connectWarehouseWithSale({
                  salesId: selectedSale.id,
                  containerIdList: selectedContainer,
                  warehousedAt: warehouseInfo.at,
                  warehousingMediaId: warehouseInfo.file?.id,
                });
                refetchContainer();
                onClose();
              }
            }}
          >
            {t("shipment:importer.detail.button.saveButton")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Container>
        <TopContainer>
          <Flex gap={16} alignItems="center" flex={1}>
            <IconWrapper>
              <Icon iconSrc={ContainerIllustSvg} iconSize={65} />
            </IconWrapper>

            <Flex flexDirection="column" gap={8}>
              <Typo typoType="b9m" color="gray6">
                {t("common:totalContainer")}
              </Typo>

              <Typo typoType="h4" color="blue2">
                {selectedContainer.length} EA
              </Typo>
            </Flex>
          </Flex>

          <Flex
            gap={16}
            justifyContent="center"
            flexDirection="column"
            flex={1}
          >
            {[
              {
                label: t("common:contractNo"),
                value: selectedSale?.salesNumber || "-",
              },
              {
                label: t("shipment:importer.detail.label.warehouse"),
                value: selectedSale?.warehouse.warehouseName || "-",
              },
            ].map(({ label, value }) => {
              return (
                <Flex gap={8} alignItems="center">
                  <Typo className="label" typoType="b7m">
                    {label}
                  </Typo>

                  <Typo className="value" typoType="b7r" color="gray5">
                    {value}
                  </Typo>
                </Flex>
              );
            })}
          </Flex>

          <Flex
            gap={16}
            justifyContent="center"
            flexDirection="column"
            flex={1}
          >
            {[
              {
                label: t("common:totalNetWeight"),
                value: totalNetWeight.toLocaleString() + "MT",
              },
              {
                label: t("shipment:importer.detail.label.itemCode"),
                value: selectedSale?.exporterItemCode.itemCode || "-",
              },
            ].map(({ label, value }) => {
              return (
                <Flex gap={8} alignItems="center">
                  <Typo className="label" typoType="b7m">
                    {label}
                  </Typo>

                  <Typo className="value" typoType="b7r" color="gray5">
                    {value}
                  </Typo>
                </Flex>
              );
            })}
          </Flex>
        </TopContainer>
        <Accordion.Root
          type="single"
          defaultValue="sale"
          value={tab}
          onValueChange={(value: AccordionItemType) => setTab(value)}
        >
          {(["sale", "contract"] as AccordionItemType[]).map((value) => {
            return (
              <AccordionItem
                key={value}
                value={value}
                renderTrigger={renderAccordionTitle(value)}
                renderContent={renderAccordionContent(value)}
                disabled={getDisabledState(value)}
              />
            );
          })}
        </Accordion.Root>
      </Container>
    </Dialog>
  );
};

export default ConnectSaleDialog;

const AccordionItem = ({
  value,
  renderTrigger,
  renderContent,
  disabled,
}: AccordionItemProps) => {
  return (
    <Item className="AccordionItem" value={value} disabled={disabled}>
      <Trigger>{renderTrigger}</Trigger>
      <Content>{renderContent}</Content>
    </Item>
  );
};

const slideDown = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: var(--radix-accordion-content-height);
    }
`;

const slideUp = keyframes`
    0% {
        height: var(--radix-accordion-content-height);
    }
    100% {
        height: 0;
    }
`;

const Trigger = styled(Accordion.Trigger)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray11};
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow {
    transition: transform 0.3s linear;
  }

  &:focus {
    outline-offset: 1px;
    border: 2px solid ${colorSet.blue5};
    padding: 14px 14px 15px 14px;
  }

  &[data-state="open"] {
    .arrow {
      transform: rotate(-180deg);
    }
  }
`;

const Content = styled(Accordion.Content)`
  background: ${colorSet.gray11};
  overflow: hidden;
  padding: 24px 40px;

  &[data-state="open"] {
    animation: ${slideDown} 0.3s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.3s ease-in-out;
  }
`;

const Item = styled(Accordion.Item)`
  overflow: hidden;
`;

const Container = styled.div`
  box-shadow: 0px 4px 15px 0px #0000000d;
  padding: 8px;
  border-radius: 16px;
`;

const AccordionTitleSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    ${typo.h6};

    strong {
      color: ${colorSet.red2};
      ${typo.b7m};
    }
  }

  &[data-disabled="true"] {
    h3 {
      color: ${colorSet.gray7};
    }
  }
`;

const AccordionController = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ArrowIcon = styled(ArrowDownSvg)<{ color: ColorType }>`
  width: 24px;
  height: 24px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const ContentContainer = styled(Flex)`
  padding: 16px;
  background: ${colorSet.white};

  .label {
    width: 164px;
    flex-shrink: 0;
  }
`;

const Divider = styled.div`
  border-right: 1px solid ${colorSet.gray9};
  padding-top: 40px;
`;

const StyledDatePicker = styled(DatePicker)`
  flex: 1;
`;

const TopContainer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  .label {
    width: 164px;
    flex-shrink: 0;
  }

  .value {
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const IconWrapper = styled.div`
  padding: 12px;
  border: 1px solid ${colorSet.gray10};
  border-radius: 8px;
`;
