import React from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Font,
  View,
  Image,
} from "@react-pdf/renderer";
// @ts-ignore
import RobotoBoldItalic from "../../../../assets/fonts/Roboto-BoldItalic.ttf";
import stringToPdfPriceText from "../utils/stringToPdfPrieceText";
import colorSet from "@/src/styles/color";
import { PlData } from "./types";
import { PlItemContainer } from "@/src/store/apis/shipments/pl/interface";

interface PlContentProps {
  data: PlData;
}

const PlContent = ({ data }: PlContentProps) => {
  const isNetWeightHidden = data.plItems
    .reduce((acc, val): any => {
      return [...acc, ...val.plItemContainers];
    }, [] as PlItemContainer[])
    .some((item) => item.isNetWeightHidden === true);

  const isGrossWeightHidden = data.plItems
    .reduce((acc, val): any => {
      return [...acc, ...val.plItemContainers];
    }, [] as PlItemContainer[])
    .some((item) => item.isGrossWeightHidden === true);

  const isCbmHidden = data.plItems
    .reduce((acc, val): any => {
      return [...acc, ...val.plItemContainers];
    }, [] as PlItemContainer[])
    .some((item) => item.isCbmHidden === true);

  return (
    <Document>
      <Page style={styles.body} size="A4">
        {/* Title */}
        <View style={styles.titleContainer}>
          <View style={styles.companyLogo}>
            {!!data.componyLogoUrl === true ? (
              <Image
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
                src={{
                  uri: data.componyLogoUrl as string,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
            ) : (
              <View style={styles.companyLogoDefault} />
            )}
          </View>

          <Text style={styles.title}>PACKING LIST</Text>
          <Text style={styles.draft}>{data.shipmentDocumentType}</Text>
        </View>
        <View style={{ ...styles.flexRow, border: 1 }}>
          {/* Left */}
          <View style={{ ...styles.flexColumn, flex: 1 }}>
            {/* Shipper / Seller*/}
            <View
              style={{
                ...styles.flexItemColumn,
                borderBottom: 1,
              }}
            >
              <Text style={styles.itemTitle}>{data.shipperTitle}</Text>
              <Text style={styles.itemText}>{data.shipper}</Text>
            </View>

            {/* Consignee / Buyer */}
            <View style={styles.flexItemColumn}>
              <Text style={styles.itemTitle}>{data.consigneeTitle}</Text>
              <Text style={styles.itemText}>{data.consignee}</Text>
            </View>

            {/* Notify Party / Applicant */}
            {!data.isNotifyPartyHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderTop: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.notifyPartyTitle}</Text>
                <Text style={styles.itemText}>{data.notifyParty}</Text>
              </View>
            ) : null}
          </View>
          {/* Right  */}
          <View style={{ ...styles.flexColumn, borderLeft: 1, flex: 1 }}>
            {/* Invoice No. and Date */}
            <View
              style={{
                ...styles.flexItemColumn,
                borderBottom:
                  data.isPoNoHidden &&
                  data.isScNoHidden &&
                  data.isLcInfoHidden &&
                  data.isRemarkHidden
                    ? 0
                    : 1,
                flex: data.isRemarkHidden ? 1 : undefined,
              }}
            >
              <Text style={styles.itemTitle}>{data.invoicoNoAndDateTitle}</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.itemText}>{data.invoiceNo}</Text>
                <Text style={styles.itemText}>{data.invoiceDateAt}</Text>
              </View>
            </View>

            {/* PO No. SC No.*/}
            {data.isPoNoHidden && data.isScNoHidden ? null : (
              <View
                style={{
                  ...styles.flexRow,
                  borderBottom:
                    data.isLcInfoHidden && data.isRemarkHidden ? 0 : 1,
                  flex: data.isRemarkHidden ? 1 : undefined,
                }}
              >
                {!data.isPoNoHidden ? (
                  <View
                    style={{
                      ...styles.flexItemColumn,
                      borderRight: data.isScNoHidden ? 0 : 1,
                      flex: 1,
                    }}
                  >
                    <Text style={styles.itemTitle}>{data.poNoTitle}</Text>
                    {data.poNoList?.map((item, idx, origin) => {
                      const poNoText =
                        origin.length - 1 === idx ? item : `${item},`;

                      return (
                        <Text key={idx.toString()} style={styles.itemText}>
                          {poNoText}
                        </Text>
                      );
                    })}
                  </View>
                ) : null}

                {!data.isScNoHidden ? (
                  <View
                    style={{
                      ...styles.flexItemColumn,
                      flex: 1,
                    }}
                  >
                    <Text style={styles.itemTitle}>{data.scNoTitle}</Text>
                    {data.scNoList?.map((item, idx, origin) => {
                      const scNoText =
                        origin.length - 1 === idx ? item : `${item},`;

                      return (
                        <Text key={idx.toString()} style={styles.itemText}>
                          {scNoText}
                        </Text>
                      );
                    })}
                  </View>
                ) : null}
              </View>
            )}

            {/* L/C No. and Date*/}
            {!data.isLcInfoHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderBottom: data.isRemarkHidden ? 0 : 1,
                  flex: data.isRemarkHidden ? 1 : undefined,
                }}
              >
                <View>
                  <Text style={styles.itemTitle}>{data.lcNoAndDateTitle}</Text>

                  {data?.lcInfoList?.map((item, idx) => {
                    return (
                      <View
                        key={idx.toString()}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text style={styles.itemText}>{item.lcNo}</Text>
                        <Text style={styles.itemText}>{item.expireDateAt}</Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            ) : null}

            {/* Remark */}
            {!data.isRemarkHidden ? (
              <View style={styles.flexItemColumn}>
                <Text style={styles.itemTitle}>{data.remarkTitle}</Text>
                <Text style={styles.itemText}>{data.remark}</Text>
              </View>
            ) : null}
          </View>
        </View>

        {/* Port of Loading ~ Place of Delivery */}
        {data.isPortOfLoadingHidden &&
        data.isPortOfDischargeHidden &&
        data.isPlaceOfDeliveryHidden ? null : (
          <View style={{ ...styles.flexRow, border: 1, borderTop: 0 }}>
            {/* Port of Loading */}
            {!data.isPortOfLoadingHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight:
                    data.isPortOfDischargeHidden && data.isPlaceOfDeliveryHidden
                      ? 0
                      : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.portOfLoadingTitle}</Text>
                <Text style={styles.itemText}>{data.portOfLoading}</Text>
              </View>
            ) : null}

            {/* // Port of Discharge */}
            {!data.isPortOfDischargeHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  flex: 1,
                  borderRight: data.isPlaceOfDeliveryHidden ? 0 : 1,
                }}
              >
                <Text style={styles.itemTitle}>
                  {data.portOfDischargeTitle}
                </Text>
                <Text style={styles.itemText}>{data.portOfDischarge}</Text>
              </View>
            ) : null}

            {/* // Place of Delivery */}
            {!data.isPlaceOfDeliveryHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>
                  {data.placeOfDeliveryTitle}
                </Text>
                <Text style={styles.itemText}>{data.placeOfDelivery}</Text>
              </View>
            ) : null}
          </View>
        )}

        {/* Vessel ~ SHIPPING TERMS */}
        {data.isVesselAndVoyageNoHidden &&
        data.isEtdHidden &&
        data.isPaymentTermsHidden &&
        data.isShippingTermsHidden ? null : (
          <View style={{ ...styles.flexRow, border: 1, borderTop: 0 }}>
            {/* // Vessel and Voyage No. */}
            {!data.isVesselAndVoyageNoHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight:
                    data.isShippingTermsHidden &&
                    data.isPaymentTermsHidden &&
                    data.isEtdHidden
                      ? 0
                      : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>
                  {data.vesselAndVoyageNoTitle}
                </Text>
                <Text style={styles.itemText}>
                  {data.vessel} {data.voyageNo}
                </Text>
              </View>
            ) : null}

            {/* ETD */}
            {!data.isEtdHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight:
                    data.isShippingTermsHidden && data.isPaymentTermsHidden
                      ? 0
                      : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.etdTitle}</Text>
                <Text style={styles.itemText}>{data.etdAt}</Text>
              </View>
            ) : null}

            {/* Payment TERMS */}
            {!data.isPaymentTermsHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight: data.isShippingTermsHidden ? 0 : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.paymentTermsTitle}</Text>
                <Text style={styles.itemText}>{data.paymentTerms}</Text>
              </View>
            ) : null}

            {/* Shipping Terms */}
            {!data.isShippingTermsHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.shippingTermsTitle}</Text>
                <Text style={styles.itemText}>{data.shippingTerms}</Text>
              </View>
            ) : null}
          </View>
        )}

        {/* Item Table Header */}
        <View
          style={{
            ...styles.flexRow,
            alignItems: "center",
            height: "32px",
            border: 1,
            borderTop: 0,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
              borderRight: 1,
              width: "54px",
              padding: "6px",
            }}
          >
            <Text style={styles.itemTableTitle}>Shipping</Text>
            <Text style={styles.itemTableTitle}>Mark</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
              borderRight: 1,
              width: "84.5px",
              paddingTop: "6px",
              paddingBottom: "6px",
              paddingRight: "4px",
              paddingLeft: "4px",
            }}
          >
            <Text style={styles.itemTableTitle}>Container Number</Text>
            <Text style={styles.itemTableTitle}>/Seal Number</Text>
          </View>
          <Text
            style={{
              ...styles.itemTableTitle,
              borderRight: 1,
              height: "32px",
              paddingTop: "6px",
              paddingLeft: "6px",
              paddingRight: "6px",
              flex: 1,
            }}
          >
            Description of Goods
          </Text>
          <Text
            style={{
              ...styles.itemTableTitle,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
              padding: "6px",
              width: "50px",
              borderRight:
                isCbmHidden && isGrossWeightHidden && isNetWeightHidden ? 0 : 1,
            }}
          >
            Quantity
          </Text>

          {!isNetWeightHidden ? (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "32px",
                width: "60px",
                padding: "6px",
                borderRight: isCbmHidden && isGrossWeightHidden ? 0 : 1,
              }}
            >
              <Text style={styles.itemTableTitle}>Net Weight</Text>
              <Text style={styles.itemTableTitle}>
                ({data.commonWeightUnit})
              </Text>
            </View>
          ) : null}

          {!isGrossWeightHidden ? (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRight: isCbmHidden ? 0 : 1,
                height: "32px",
                width: "64px",
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
            >
              <Text style={styles.itemTableTitle}>Gross Weight</Text>
              <Text style={styles.itemTableTitle}>
                ({data.commonWeightUnit})
              </Text>
            </View>
          ) : null}

          {!isCbmHidden ? (
            <Text
              style={{
                ...styles.itemTableTitle,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "32px",
                paddingTop: "6px",
                paddingBottom: "6px",
                width: "45px",
              }}
            >
              CBM
            </Text>
          ) : null}
        </View>

        {/* container List */}
        {data.plItems.map((item, idx) => {
          return (
            <View
              key={idx.toString()}
              style={{
                ...styles.flexRow,
                alignItems: "flex-start",
                border: 1,
                borderBottom: 1,
                borderTop: 1,
                marginTop: "-1px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  height: "100%",
                  width: "54px",
                  paddingTop: "2px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
              >
                {!item.isShippingMarksHidden ? (
                  <Text
                    style={{
                      ...styles.itemText,
                      textAlign: "center",
                    }}
                  >
                    {item.shippingMarks}
                  </Text>
                ) : null}
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "83.5px",
                }}
              >
                {item.plItemContainers.map(
                  ({ containerNo: containerNumber, sealNo: sealNumber }) => {
                    const firstSeelNumber = sealNumber?.substring(0, 14);
                    const secondeSeelNumber = sealNumber?.substring(
                      14,
                      sealNumber.length
                    );

                    const isSealNumberOverLenght =
                      sealNumber && sealNumber?.length >= 14;

                    const seelNumberJoin = [
                      firstSeelNumber,
                      secondeSeelNumber,
                    ].join(" ");

                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "40px",
                          paddingTop: "6px",
                        }}
                      >
                        <Text
                          style={{
                            ...styles.itemText,
                            textAlign: "center",
                          }}
                        >
                          {containerNumber}
                        </Text>
                        <Text
                          style={{
                            ...styles.itemText,
                            textAlign: "center",
                          }}
                        >
                          /
                          {isSealNumberOverLenght ? seelNumberJoin : sealNumber}
                        </Text>
                      </View>
                    );
                  }
                )}
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  padding: "2px",
                  height: "100%",
                  flex: 1,
                }}
              >
                {!item.isItemHidden && (
                  <Text style={styles.itemText}>Item : {item.item}</Text>
                )}
                {!item.isHsCodeHidden && (
                  <Text style={{ ...styles.itemText, paddingBottom: "2px" }}>
                    HS Code : {item.hsCode}
                  </Text>
                )}
                {!item.isDescriptionOfGoodsHidden && (
                  <Text style={{ ...styles.itemText, paddingBottom: "2px" }}>
                    {item.descriptionOfGoods}
                  </Text>
                )}
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "50.5px",
                  height: "100%",
                }}
              >
                {item.plItemContainers.map(({ quantity, quantityUnit }) => {
                  return (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "40px",
                        paddingTop: "6px",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.itemText,
                          textAlign: "center",
                        }}
                      >
                        {stringToPdfPriceText({
                          value: quantity?.toLocaleString("ko-KR") || "",
                        })}
                      </Text>
                      <Text
                        style={{
                          ...styles.itemText,
                          textAlign: "center",
                          minHeight: "14px",
                        }}
                      >
                        {quantityUnit}
                      </Text>
                    </View>
                  );
                })}
              </View>

              {!isNetWeightHidden ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "60px",
                  }}
                >
                  {item.plItemContainers.map(({ netWeight }) => {
                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        <Text
                          style={{
                            ...styles.itemText,
                            textAlign: "center",
                            height: "40px",
                            paddingTop: "6px",
                          }}
                        >
                          {stringToPdfPriceText({
                            value: netWeight?.toString() || "",
                          })}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              ) : null}

              {!isGrossWeightHidden ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "64.5px",
                    height: "100%",
                  }}
                >
                  {item.plItemContainers.map(({ grossWeight }) => {
                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        <Text
                          style={{
                            ...styles.itemText,
                            textAlign: "center",
                            height: "40px",
                            paddingTop: "6px",
                          }}
                        >
                          {stringToPdfPriceText({
                            value: grossWeight?.toString() || "",
                            referenceIndex: 4,
                          })}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              ) : null}

              {!isCbmHidden ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "45px",
                    height: "100%",
                  }}
                >
                  {item.plItemContainers.map(({ cbm }) => {
                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        <Text
                          style={{
                            ...styles.itemText,
                            textAlign: "center",
                            height: "40px",
                            paddingTop: "6px",
                          }}
                        >
                          {stringToPdfPriceText({
                            value: cbm ? String(cbm) : "",
                          })}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              ) : null}
            </View>
          );
        })}

        {/* Total */}
        <View
          wrap={false}
          style={{
            ...styles.flexRow,
            alignItems: "flex-start",
            minHeight: "22px",
            backgroundColor: "#000000",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "54px",
              minHeight: "22px",
              paddingLeft: "6px",
            }}
          >
            <Text style={styles.itemTableTotalTitle}>TOTAL</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "6px",
              minHeight: "22px",
              flex: 1,
            }}
          >
            <View style={styles.itemTableTotalTitle}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "left",
                  }}
                >
                  <Text>
                    {data.numberOfContainerTypeA?.replace(/[,]/g, ",  ")}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "left",
                  }}
                >
                  <Text>Total {data.numberOfContainerTypeB}</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "22px",
              padding: "6px",
              width: "77px",
            }}
          >
            <Text style={styles.itemTableTotalTitle}>
              {stringToPdfPriceText({
                value: data.totalQuantity.toLocaleString("ko-KR"),
                referenceIndex: 4,
              })}{" "}
              {data.totalQuantityUnit.length === 0 ||
              data.totalQuantityUnit.length > 1
                ? "Packages"
                : data.totalQuantityUnit[0]}
            </Text>
          </View>

          {!data.totalIsNetWeightHidden && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "22px",
                padding: "6px",
                width: "59px",
              }}
            >
              <Text style={styles.itemTableTotalTitle}>
                {stringToPdfPriceText({
                  value: data.totalNetWeight.toLocaleString("ko-KR"),
                })}{" "}
                {data.totalNetWeightUnit}
              </Text>
            </View>
          )}

          {!data.totalIsGrossWeightHidden && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "22px",
                padding: "6px",
                width: "60px",
              }}
            >
              <Text style={styles.itemTableTotalTitle}>
                {stringToPdfPriceText({
                  value: data.totalGrossWeight.toLocaleString("ko-KR"),
                })}{" "}
                {data.totalGrossWeightUnit}
              </Text>
            </View>
          )}

          {!data.totalIsCbmHidden && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "22px",
                padding: "6px",
                width: "48px",
              }}
            >
              {data.totalCbm > 0 && (
                <Text style={{ ...styles.itemTableTotalTitle }}>
                  {stringToPdfPriceText({
                    value: data.totalCbm.toLocaleString("ko-KR"),
                  })}{" "}
                  CBM
                </Text>
              )}
            </View>
          )}
        </View>

        {/* signature*/}
        <View style={styles.bankAndsignature}>
          <View
            style={{
              display: "flex",
              width: "100%",
              flex: 1,
              height: "100%",
              marginTop: "auto",
              minHeight: "20px",
            }}
          />

          <View style={styles.bankContainer} wrap={false}>
            <View style={styles.signature}>
              <View style={styles.signatureImageContainer}>
                {!!data.signatureUrl ? (
                  <Image
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                    src={{
                      uri: data.signatureUrl,
                      method: "GET",
                      headers: { "Cache-Control": "no-cache" },
                      body: "",
                    }}
                  />
                ) : (
                  <View style={styles.signatureImageDefault} />
                )}
              </View>
            </View>
          </View>
          <View style={styles.signatureTextContainer}>
            <Text style={styles.signatureText}>Authorized Signature</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PlContent;

Font.register({
  family: "SpoqaHanSans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf",
      fontWeight: 700,
      fontStyle: "normal",
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoBoldItalic,
      fontWeight: 700,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },

  // Title
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "81px",
    marginBottom: 2,
  },
  title: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    width: "100%",
    textAlign: "center",
    alignSelf: "center",
    position: "absolute",
  },
  draft: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Roboto",
    fontStyle: "italic",
    marginLeft: "auto",
    marginTop: "auto",
  },
  companyLogo: {
    position: "absolute",
    width: "81px",
    height: "81px",
  },
  companyLogoDefault: {
    position: "absolute",
    width: "76px",
    height: "76px",
    backgroundColor: colorSet.white,
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
    minHeight: "auto",
  },
  flexItemColumn: {
    display: "flex",
    flexDirection: "column",
    minHeight: "auto",
    padding: "6px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },

  bankAndsignature: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    flex: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
  },
  bankContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "8px",
    minHeight: "88px",
  },
  bank: { display: "flex", flexDirection: "column", marginTop: "auto" },
  signature: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    width: "128px",
  },
  signatureImageDefault: {
    marginTop: "auto",
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: colorSet.white,
  },
  signatureImageContainer: {
    marginTop: "auto",
    width: "100%",
    maxHeight: 64,
  },
  signatureTextContainer: {
    display: "flex",
    width: "100%",
    minHeight: "32px",
  },

  // Text
  itemTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    marginBottom: "4px",
  },
  itemTableTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
  },
  itemTableTotalTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    color: "#ffffff",
  },
  itemText: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
  },
  signatureTitle: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
  },
  signatureText: {
    marginLeft: "auto",
    marginRight: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    width: "128px",
    borderTop: 1,
  },
});
