import styled from "styled-components";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import { InputHTMLAttributes } from "react";

interface AgGridRadioProps extends InputHTMLAttributes<HTMLInputElement> {}

const AgGridRadio = ({ checked, onChange, disabled }: AgGridRadioProps) => {
  return (
    <StyledRadio>
      <input
        type="radio"
        className="custom-ag-radio"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="custom-radio-mark" />
    </StyledRadio>
  );
};

export default AgGridRadio;

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
`;
