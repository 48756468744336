import { RegisterType } from "../pages/exporter/SubscriptionManagement/components/dialog/AddPaymentMethodsDialog";
import { SignupJoinPathType } from "../store/apis/auth/interface";

const LANGUAGE_LIST = [
  { label: "common:english.en", value: "en" },
  { label: "common:korean.ko", value: "ko" },
  { label: "common:japanese.ja", value: "ja" },
  { label: "common:chinese.zh-TW", value: "zh-TW" },
  { label: "common:chinese.zh-CN", value: "zh-CN" },
];

const SUBSCRIPTION_PATH_OPTION_LIST: {
  langKey: string;
  value: SignupJoinPathType;
}[] = [
  {
    langKey: "common:search",
    value: "SEARCHING",
  },
  {
    langKey: "common:introduction",
    value: "REFERRAL",
  },
  {
    langKey: "common:news",
    value: "NEWS_ARTICLE",
  },
  {
    langKey: "common:fair",
    value: "EXHIBITION",
  },
  {
    langKey: "common:etc",
    value: "ETC",
  },
];

const REGISTER_TYPE_OPTION_LIST: {
  langKey: string;
  value: RegisterType;
}[] = [
  {
    langKey: "subscriptionManagement:subscription.business",
    value: "BUSINESS",
  },
  {
    langKey: "subscriptionManagement:subscription.individual",
    value: "PERSONAL",
  },
];

const CARD_REGISTER_TYPE_OPTION_LIST = [
  {
    langKey: "subscriptionManagement:subscription.corporateCard",
    value: "CORPORATE_CARD",
  },
  {
    langKey: "subscriptionManagement:subscription.employeePersonalizedCard",
    value: "EMPLOYEE_PERSONALIZED_CARD",
  },
];

const CLIENT_TYPE_OPTION_LIST = [
  { langKey: "common:export", value: "SELLER" },
  { langKey: "common:import", value: "BUYER" },
  { langKey: "common:exportAndImport", value: "BOTH" },
];

export {
  LANGUAGE_LIST,
  SUBSCRIPTION_PATH_OPTION_LIST,
  CARD_REGISTER_TYPE_OPTION_LIST,
  REGISTER_TYPE_OPTION_LIST,
  CLIENT_TYPE_OPTION_LIST,
};
