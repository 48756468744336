import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import Dialog from "@/src/components/atom/Dialog";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useLazyGetContractListAssociatedWithBookingQuery } from "@/src/store/apis/contracts/contractList";
import { BookingContractListViewDto } from "@/src/store/apis/contracts/contractList/interface";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";
import { columnLoadContract } from "../../../columns/columnLoadContract";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface LoadContractDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect?: (detail?: BookingContractListViewDto) => void;
  defaultSelectedItemId?: number;
  bookingId: number;
}

const emptyArray: [] = [];

const LoadContractDialog = ({
  open,
  onClose,
  onSelect,
  defaultSelectedItemId,
  bookingId,
}: LoadContractDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);

  const [buyerCode, setBuyerCode] = useState<string>();
  const [selectedId, setSelectedId] = useState<number | null>(
    defaultSelectedItemId || null
  );
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [columnDefs] = useState<ColDef[]>(columnLoadContract(setSelectedId));

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "createdAt",
        langKey: "table:orderDateAt",
      },
      {
        columnKey: "scNo",
        langKey: "table:scNo",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "quantity",
        langKey: "table:qty",
      },
      {
        columnKey: "unitPrice",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "shippingTerms",
        langKey: "table:shippingTerms",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "origin",
        langKey: "table:originLocation",
      },
      {
        columnKey: "lastShipmentDateAt",
        langKey: "table:lastShipmentDate",
      },
    ],
  });

  const {
    currentData: buyersList,
    isError: isBuyerListError,
    isFetching: isBuyerListFetching,
  } = useGetBuyersQuery({});
  const [getContractList, { isFetching, count, list }] =
    useLazyGetContractListAssociatedWithBookingQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        return {
          isFetching,

          list:
            !isError && !isUndefined(currentData)
              ? currentData.rows
              : emptyArray,
          count: currentData?.count || 0,
        };
      },
    });

  const contractList = list.map((contract) => {
    return {
      ...contract,
      isChecked: selectedId === contract.id,
    };
  });

  const buyersListToOptions = isBuyerListError
    ? []
    : buyersList?.data.reduce<{ label: string; value: string }[]>(
        (acc, val) => {
          const resource = {
            label: val?.buyerNameCode || "",
            value:
              `${val.buyerNameCode}_${val.buyerListQueryResultDto.id}` || "",
          };
          return [...acc, resource];
        },
        [{ label: t("common:all"), value: "all" }]
      );

  const handleSelect = () => {
    onSelect?.(contractList.find(({ id }) => id === selectedId));
    onClose();
    alert.showAlert({
      type: "success",
      message: t("task:add.alert.selectContractSuccess"),
    });
  };

  const handleRowClicked = (params: RowClickedEvent) =>
    setSelectedId(params.data.id);

  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getContractList({
        id: bookingId,
        page: 1,
        pageSize: 10,
        contractStatus: "PROCESSING",
      });
    })();
  }, [bookingId, getContractList]);

  return (
    <Dialog
      title={t("task:add.dialog.selectContract")}
      open={open}
      onOpenChange={onClose}
      width={1000}
      footer={
        <DialogFooterContainer>
          <Button buttonGrade="tertiary" buttonColor="black" onClick={onClose}>
            {t("task:add.common.exit")}
          </Button>
          <Button disabled={!selectedId} onClick={() => handleSelect()}>
            {t("task:add.buttonLabel.select")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Content>
        <InputContainer>
          <Typo typoType="b7m">{t("common:buyerCode")}</Typo>
          <Select
            options={buyersListToOptions}
            showSearch
            defaultValue={"all"}
            suffixIcon={isBuyerListFetching ? <Loader /> : undefined}
            disabled={isBuyerListFetching}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentElement;
            }}
            onChange={async (value, option: any) => {
              setBuyerCode(value === "all" ? undefined : option.label);
              setPagination({ page: 1, pageSize: pagination.pageSize });
              await getContractList({
                id: bookingId,
                page: 1,
                buyerNameCode: value === "all" ? undefined : option.label,
                pageSize: pagination.pageSize,
              });
            }}
          />
        </InputContainer>
        <Table
          ref={gridRef}
          onGridReady={() => setIsReady(true)}
          columnDefs={columnDefs}
          rowData={contractList}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalPage={count}
          handlePaginationClick={async (page, pageSize) => {
            setPagination({ page, pageSize });
            await getContractList({
              page,
              pageSize,
              id: bookingId,
              buyerNameCode: buyerCode,
            });
          }}
          onRowClicked={handleRowClicked}
        />
      </Content>
    </Dialog>
  );
};

export default LoadContractDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    outline: 1px solid ${colorSet.gray9};
    border-radius: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
