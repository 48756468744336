import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ColDef, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import colorSet from "@/src/styles/color";
import Table from "@/src/components/atom/Table";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import TablePlaceholderSvg from "@/src/assets/icons/icon-table-placeholder.svg";
import { BookingRecombinationShipmentListViewDto } from "@/src/store/apis/tasks/taskDetail/interface";
import { combineSplitShipmentTableColumn } from "../../../../columns/columnTaskList";
import { useTranslation } from "react-i18next";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";

interface ShipmentContentProps {
  selectedShipmentId: number | null;
  shipmentList: BookingRecombinationShipmentListViewDto[];
  shipmentCount: number;
  isShipmentListFetching: boolean;
  isShipmentUninitialized: boolean;
  page: number;
  onShipmentChange: (id: number) => void;
  onPageChange?: (page: number) => void;
}

const ShipmentContent = ({
  selectedShipmentId,
  isShipmentListFetching,
  isShipmentUninitialized,
  shipmentList,
  shipmentCount,
  page,
  onShipmentChange,
  onPageChange,
}: ShipmentContentProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const [isReady, setIsReady] = useState(false);
  const [shipmentColumn] = useState<ColDef[]>([
    {
      headerName: "",
      field: "radioButton",
      width: 60,
      cellRenderer: (params: IRowNode) => <>{radioRenderer(params)}</>,
      lockPosition: "left",
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...combineSplitShipmentTableColumn,
  ]);

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "shipmentStatus",
        langKey: "table:status",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "bookingNo",
        langKey: "table:bookingNo",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "scNo",
        langKey: "table:scNo",
      },
      {
        columnKey: "etdAt",
        langKey: "table:etd",
      },
      {
        columnKey: "etaAt",
        langKey: "table:eta",
      },
      {
        columnKey: "totalNetWeight",
        langKey: "table:totalNetWeight",
      },
      {
        columnKey: "unitPriceUnit",
        langKey: "table:currency",
      },
      {
        columnKey: "docCutOffAt",
        langKey: "table:docCutOffVgmCutOff",
      },
      {
        columnKey: "portOfLoading",
        langKey: "table:portOfLoading",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
    ],
  });

  const radioRenderer = (params: IRowNode) => {
    const handleRadioChange = () => {
      onShipmentChange?.(params.data.id);
    };

    const checked = params.data.isChecked;

    return (
      <StyledRadio>
        <input
          type="radio"
          className="custom-ag-radio"
          checked={checked}
          onChange={handleRadioChange}
        />
        <span className="custom-radio-mark" />
      </StyledRadio>
    );
  };

  useEffect(() => {
    if (isShipmentListFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isShipmentListFetching]);

  return (
    <AccordionContentWrapper>
      <TableWrapper>
        {!isShipmentUninitialized &&
        !isShipmentListFetching &&
        shipmentCount === 0 ? (
          <PlaceholderContainer>
            <Icon iconSrc={TablePlaceholderSvg} iconSize={56} />
            <Typo typoType="b7m" color="gray8">
              {t("common:notLinkedShipment")}
            </Typo>
          </PlaceholderContainer>
        ) : (
          <Table
            ref={gridRef}
            columnDefs={shipmentColumn}
            onGridReady={() => setIsReady(true)}
            rowData={
              isShipmentListFetching
                ? undefined
                : shipmentList.map((list) => ({
                    ...list,
                    isChecked: selectedShipmentId === list.id,
                  }))
            }
            totalPage={shipmentCount}
            page={page}
            height={372}
            pageSize={10}
            handlePaginationClick={async (page) => {
              onPageChange?.(page);
            }}
            onRowClicked={(e) => {
              const rowData = e.data;
              onShipmentChange?.(rowData.id);
            }}
          />
        )}
      </TableWrapper>
    </AccordionContentWrapper>
  );
};

export default ShipmentContent;

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
`;

const AccordionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableWrapper = styled.div`
  background: ${colorSet.white};
  padding: 8px 8px 0;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;

  .ant-pagination-options {
    display: none;
  }
`;

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 135px 0;
`;
