import mapScopeCity from "../../../../assets/images/image-map-scope-city.png";
import mapScopeCountry from "../../../../assets/images/image-map-scope-country.png";
import mapScopeAll from "../../../../assets/images/image-map-scope-all.png";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";
import { TFunction } from "i18next";

export const getMaxContainerInfoViaLang = (
  t: TFunction,
  maxContainer: number
) => {
  return t("common:maxContainers", { maxContainer });
};

export const MEDIA_COORDINATE_RANGE_OPTION_LIST: {
  label: string;
  value: MediaCoordinateRangeType;
  mapSrc: string;
}[] = [
  {
    label: "add.label.mediaCoordinateRangeCity",
    value: "CITY",
    mapSrc: mapScopeCity,
  },
  {
    label: "add.label.mediaCoordinateRangeCountry",
    value: "COUNTRY",
    mapSrc: mapScopeCountry,
  },
  {
    label: "add.label.mediaCoordinateRangeAll",
    value: "ALL",
    mapSrc: mapScopeAll,
  },
];
