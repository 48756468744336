import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Dialog from "@/src/components/atom/Dialog";
import { Button } from "@/src/components/atom/Button";
import FormItem from "@/src/components/molecule/FormItem";
import { emailRegex } from "@/src/utils/regex";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

type ContactPersonEditDialogFormType = {
  name: string;
  email: string;
  personalContactPrefix: string;
  personalContact: string;
  isActivated: string;
  officeContactPrefix?: string;
  officeContact?: string;
};

export type ContactPersonEditFormType = Omit<
  ContactPersonEditDialogFormType,
  "isActivated"
> & {
  isActivated: boolean;
};

interface ContactPersonEditDialogProps {
  onSave: (values: ContactPersonEditFormType) => void;
  onClose: () => void;
  defaultContactPersonInfo: ContactPersonEditDialogFormType;
}

const ContactPersonEditDialog = ({
  defaultContactPersonInfo,
  onSave,
  onClose,
}: ContactPersonEditDialogProps) => {
  const { t } = useTranslation();
  const dialogSubmitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<
    Omit<ContactPersonEditDialogFormType, "isActivated"> & {
      isActivated: string;
    }
  >({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      name: defaultContactPersonInfo.name,
      email: defaultContactPersonInfo.email,
      personalContactPrefix: defaultContactPersonInfo.personalContactPrefix,
      personalContact: defaultContactPersonInfo.personalContact,
      officeContactPrefix: defaultContactPersonInfo.officeContactPrefix,
      officeContact: defaultContactPersonInfo.officeContact,
      isActivated: defaultContactPersonInfo.isActivated,
    },
  });

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const renderAlertDialog = () => {
    if (isCancelAlertOpen) {
      return (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={onClose}
        />
      );
    }
  };

  return (
    <>
      <Dialog
        title={t("factoryManagement:factoryListAddPage.editContactPerson")}
        onOpenChange={onClose}
        open
        destroyDialogWhenEscapePress={false}
        onEscapeKeyDown={() => {
          setIsCancelAlertOpen(true);
        }}
        footer={
          <DialogFooterContainer>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => setIsCancelAlertOpen(true)}
            >
              {t("factoryManagement:common.exit")}
            </Button>
            <Button onClick={() => dialogSubmitButtonRef.current?.click()}>
              {t("factoryManagement:common.save")}
            </Button>
          </DialogFooterContainer>
        }
      >
        <DialogForm
          onSubmit={handleSubmit((values) =>
            onSave({
              ...values,
              personalContact: values.personalContact.replace(/^0+/, ""), // 앞자리 0 제거
              officeContact: values.officeContact?.replace(/^0+/, ""), // 앞자리 0 제거
              isActivated: values.isActivated === "Y",
            })
          )}
        >
          <FormItem
            type="text"
            direction="vertical"
            label={t("factoryManagement:common.name")}
            control={control}
            name="name"
            rules={{ required: true }}
            inputProps={{
              placeholder: t("factoryManagement:placeholder.name"),
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            type="text"
            direction="vertical"
            label={t("factoryManagement:common.email")}
            control={control}
            name="email"
            rules={{ required: true, pattern: emailRegex }}
            inputProps={{
              placeholder: t("factoryManagement:placeholder.email"),
            }}
            errorsMessage={{
              required: t("error:required"),
              pattern: t("error:login.emailTypeMissMatching"),
            }}
          />
          <ContactFormItem
            register={register}
            setValue={setValue}
            label={t("factoryManagement:common.personalContact")}
            direction="vertical"
            prefixName="personalContactPrefix"
            restContactName="personalContact"
            prefixValue={watch("personalContactPrefix") || undefined}
            errors={errors}
            isRequired
          />
          <ContactFormItem
            register={register}
            setValue={setValue}
            label={t("factoryManagement:common.officeContact")}
            direction="vertical"
            prefixName="officeContactPrefix"
            restContactName="officeContact"
            prefixValue={watch("officeContactPrefix") || undefined}
            errors={errors}
            isRequired={false}
          />
          <FormItem
            type="radioGroup"
            direction="vertical"
            label={t("factoryManagement:common.use")}
            control={control}
            name="isActivated"
            rules={{ required: true }}
            options={[
              { label: "Y", value: "Y" },
              { label: "N", value: "N" },
            ]}
            errorsMessage={{
              required: t("error:required"),
              pattern: t("error:login.emailTypeMissMatching"),
            }}
          />
          {renderAlertDialog()}
          <Hidden type="submit" ref={dialogSubmitButtonRef} />
        </DialogForm>
      </Dialog>
    </>
  );
};

export default ContactPersonEditDialog;

const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
