import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import { ColDef, IRowNode, RowSelectedEvent } from "ag-grid-community";
import { styled } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import Table from "@/src/components/atom/Table";
import Dialog from "@/src/components/atom/Dialog";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import { Button } from "@/src/components/atom/Button";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import {
  useLazyGetContractQuery,
  useLazyGetOngoingContractsQuery,
} from "@/src/store/apis/contracts";
import colorSet from "@/src/styles/color";
import useAlert from "@/src/hooks/useAlert";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface LoadContractsDialogProps {
  open: boolean;
  onClose: () => void;
  onFetchContractViaId: (detail: ContractDetailViewDto) => void;
  excludeIdList: number[];
}

const LoadContractsMultipleSelectDialog = ({
  open,
  onClose,
  onFetchContractViaId,
  excludeIdList,
}: LoadContractsDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);

  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [buyerNameCode, setBuyerNameCode] = useState<string>();
  const [columnDefs] = useState<ColDef[]>([
    {
      width: 50,
      headerCheckboxSelection: true, // 헤더에 체크박스 추가 (전체 선택)
      checkboxSelection: (params) => !excludeIdList.includes(params.data.id),
      showDisabledCheckboxes: true,
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...column,
  ]);

  // API
  const {
    currentData: buyersList,
    isError: isBuyerListError,
    isFetching: isBuyerListFetching,
  } = useGetBuyersQuery({});
  const [getContracts, contractsResult] = useLazyGetOngoingContractsQuery();
  const [getContractDetail] = useLazyGetContractQuery();
  const buyersListToOptions = isBuyerListError
    ? []
    : buyersList?.data.reduce<{ label: string; value: string }[]>(
        (acc, val) => {
          const resource = {
            label: val?.buyerNameCode || "",
            value:
              `${val.buyerNameCode}_${val.buyerListQueryResultDto.id}` || "",
          };
          return [...acc, resource];
        },
        [{ label: t("common:all"), value: "all" }]
      );

  const isRowSelectable = (node: IRowNode) => {
    return !excludeIdList.includes(node.data.id);
  };

  // AgGrid Header
  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "createdAt",
        langKey: "table:orderDateAt",
      },
      {
        columnKey: "scNo",
        langKey: "table:scNo",
      },
      {
        columnKey: "item",
        langKey: "table:itemCode",
      },
      {
        columnKey: "qty",
        langKey: "table:qty",
      },
      {
        columnKey: "price",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "shippingTerms",
        langKey: "table:shippingTerms",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "location",
        langKey: "table:originLocation",
      },
    ],
  });

  // AgGrid Checkbox
  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const accountKey = e.node.data.id;

      const keys = selectedNodesData.map((item) => item.data.id) as number[];

      if (selectedItemIds.includes(accountKey)) {
        setSelectedItemIds((prev) => prev.filter((id) => id !== accountKey));
      } else {
        const set: any = new Set([...selectedItemIds, ...keys]);
        setSelectedItemIds([...set]);
      }
    }
  };

  const updateRowSelection = () => {
    if (gridRef.current) {
      gridRef.current.api.forEachNode((node) => {
        if (
          excludeIdList.includes(node.data.id) ||
          selectedItemIds.includes(node.data.id)
        ) {
          node.setSelected(true);
        }
      });
    }
  };

  const handleSubmitClick = async () => {
    if (selectedItemIds.length === 0) return;
    setIsLoading(true);

    try {
      // 선택된 각 ID에 대해 getContractDetail 호출
      const uniqueIds = selectedItemIds.filter(
        (item) => !excludeIdList.includes(item)
      );

      const details = await Promise.all(
        (uniqueIds as number[]).map(async (id) => {
          const { row } = await getContractDetail({ id }).unwrap();
          return row;
        })
      );

      // API로부터 받은 데이터를 각각 처리
      details.forEach((detail) => onFetchContractViaId(detail));
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleResetPagination = () => setPagination({ page: 1, pageSize: 10 });

  const handleBuyerCodeChange = async (value: any, option: any) => {
    setSelectedItemIds([]);
    handleResetPagination();

    try {
      await getContracts({
        page: 1,
        pageSize: 10,
        contractStatus: "PROCESSING",
        buyerNameCode: value === "all" ? undefined : option.label,
      }).unwrap();
      setBuyerNameCode(value === "all" ? undefined : option.label);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  useEffect(() => {
    if (contractsResult.isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [contractsResult.isFetching]);

  useEffect(() => {
    (async () => {
      await getContracts({
        page: 1,
        pageSize: 10,
        contractStatus: "PROCESSING",
      });
    })();
  }, [getContracts]);

  return (
    <>
      <Dialog
        title={t("booking:add.alert.loadContracts")}
        open={open}
        onOpenChange={onClose}
        width={1000}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={onClose}
            >
              {t("booking:add.common.exit")}
            </Button>
            <Button
              isLoading={isLoading}
              disabled={selectedItemIds.length === 0 || isLoading}
              onClick={handleSubmitClick}
            >
              {t("booking:add.common.select")}
            </Button>
          </DialogFooterContainer>
        }
      >
        <Content>
          <InputContainer>
            <Typo typoType="b7m">{t("booking:add.alert.buyerCode")}</Typo>
            <Select
              options={buyersListToOptions}
              showSearch
              defaultValue={"all"}
              suffixIcon={isBuyerListFetching ? <Loader /> : undefined}
              disabled={isBuyerListFetching}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentElement;
              }}
              onChange={(value, option: any) => {
                handleBuyerCodeChange(value, option);
              }}
            />
          </InputContainer>

          <Table
            ref={gridRef}
            rowData={
              contractsResult.isError
                ? []
                : contractsResult.currentData?.rows.map((val) => ({
                    id: val.id,
                    buyerNameCode: val.buyerNameCode,
                    createdAt: val.orderDateAt,
                    scNo: val.scNo,
                    item: val.itemCode,
                    qty: `${parseFloat(val.quantity).toLocaleString("ko-KR")} ${
                      val.quantityUnit
                    }`,
                    price: `${parseFloat(val.unitPrice).toLocaleString(
                      "ko-KR"
                    )} ${val.unitPriceUnit}`,
                    shippingTerms: val.shippingTerms,
                    placeOfDelivery: val.placeOfDelivery || "-",
                    location: val.origin || "-",
                  })) ?? []
            }
            totalPage={contractsResult.currentData?.count}
            page={pagination.page}
            pageSize={pagination.pageSize}
            handlePaginationClick={async (page, pageSize) => {
              setPagination({ page, pageSize });
              await getContracts({
                page,
                pageSize,
                contractStatus: "PROCESSING",
                buyerNameCode,
              });
              updateRowSelection();
            }}
            isRowSelectable={isRowSelectable}
            onGridReady={() => {
              updateRowSelection();
              setIsReady(true);
            }}
            columnDefs={columnDefs}
            rowSelection={"multiple"}
            rowMultiSelectWithClick
            suppressRowClickSelection={false}
            onRowSelected={handleSelectionChanged}
            getRowId={(row) => row.data.id}
          />
        </Content>
      </Dialog>
    </>
  );
};

export default LoadContractsMultipleSelectDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    border: 1px solid ${colorSet.gray9} !important;
    border-radius: 8px;
  }
`;

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
};

const column: ColDef[] = [
  {
    field: "buyerNameCode",
    width: 180,
    minWidth: 180,
    ...commonColumn,
  },
  {
    field: "createdAt",
    width: 180,
    minWidth: 180,
    cellRenderer: (params: any) => {
      return dayjs(params.data.createdAt).format("YYYY-MM-DD");
    },
    ...commonColumn,
  },
  {
    field: "scNo",
    width: 140,
    ...commonColumn,
  },
  {
    field: "item",
    width: 160,
    ...commonColumn,
  },
  {
    field: "qty",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    field: "price",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    field: "shippingTerms",
    width: 160,
    ...commonColumn,
  },
  {
    field: "placeOfDelivery",
    width: 160,
    cellRenderer: (params: any) => {
      if (!params.data.placeOfDelivery) {
        return "-";
      }
      return params.data.placeOfDelivery;
    },
    ...commonColumn,
  },
  {
    field: "location",
    width: 150,
    cellRenderer: (params: any) => {
      if (!params.data.location) {
        return "-";
      }
      return params.data.location;
    },
    ...commonColumn,
  },
];

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
