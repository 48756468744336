import styled from "styled-components";
import BasicCard, {
  BasicCardTitleContainer,
} from "@/src/components/atom/Cards/BasicCard";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import {
  useCorporationSignUpFormContext,
  useCorporationStepContext,
} from "./hooks";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import CheckSvg from "@/src/assets/icons/icon-check-blue.svg";
import UnCheckSvg from "@/src/assets/icons/icon-uncheck-gray.svg";
import typo from "@/src/styles/typography";
import Loader from "@/src/components/atom/Loader";
import { useTranslation } from "react-i18next";

const CorporateSignUpStepTwo = () => {
  const { t, i18n } = useTranslation();
  const { isFetching, data: industryCodeList } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "INDUSTRY",
    });
  const { onStepPrev, onStepNext } = useCorporationStepContext();
  const signUpForm = useCorporationSignUpFormContext();

  return (
    <StyledCard
      title={
        <BasicCardTitleContainer>
          {t("signupCorp:steps.thirdStep")}
        </BasicCardTitleContainer>
      }
    >
      <ContentDiv>
        <FieldDiv>
          <Typo typoType="b7m" color="gray2">
            {t("signupCorp:content.industrySelection")}
            <Typo color="blue4"> ({t("common:optional")})</Typo>
          </Typo>
          <IndustrySelectionContainer>
            {isFetching ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              industryCodeList?.map(
                ({ codeItemName, codeItemNameEn, codeItemNameKo }) => {
                  const isChecked =
                    signUpForm.industryCodeItemNames?.includes(codeItemName);

                  return (
                    <StyledCheckbox
                      data-checked={isChecked}
                      onClick={() => {
                        if (isChecked) {
                          return signUpForm.onFormValueChange((prev) => {
                            return {
                              ...prev,
                              industryCodeItemNames:
                                prev.industryCodeItemNames?.filter(
                                  (item) => item !== codeItemName
                                ),
                            };
                          });
                        }
                        return signUpForm.onFormValueChange((prev) => {
                          return {
                            ...prev,
                            industryCodeItemNames: [
                              ...(prev.industryCodeItemNames ?? []),
                              codeItemName,
                            ],
                          };
                        });
                      }}
                    >
                      {i18n.language === "en" ? codeItemNameEn : codeItemNameKo}
                      <Icon
                        iconSrc={isChecked ? CheckSvg : UnCheckSvg}
                        iconSize={24}
                      />
                    </StyledCheckbox>
                  );
                }
              )
            )}
          </IndustrySelectionContainer>
        </FieldDiv>
        <StyledTypo typoType="b9r" color="gray7">
          {t("signupCorp:content.industrySelectionDescription")}
        </StyledTypo>
        <ButtonContainer>
          <StyledButton
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={40}
            onClick={onStepPrev}
          >
            <Typo typoType="btn3m">{t("common:back")}</Typo>
          </StyledButton>

          <StyledButton buttonSize={40} onClick={onStepNext}>
            <Typo typoType="btn3m" color="white">
              {t("common:next")}
            </Typo>
          </StyledButton>
        </ButtonContainer>
      </ContentDiv>
    </StyledCard>
  );
};

export default CorporateSignUpStepTwo;

const StyledCard = styled(BasicCard)`
  width: 800px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 160px;
  gap: 24px;
`;

const FieldDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  strong {
    color: ${colorSet.red2};
  }
`;

const StyledCheckbox = styled.div`
  width: calc(50% - 4px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: ${colorSet.gray10};
  padding: 18px 16px;
  cursor: pointer;
  ${typo.h9}
  color: ${colorSet.gray6};

  &[data-checked="true"] {
    background: ${colorSet.blue10};
    box-shadow: 0 0 0 2px ${colorSet.blue4} inset;
    color: ${colorSet.blue4};
  }
`;

const IndustrySelectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledButton = styled(Button)``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  padding-top: 16px;

  ${StyledButton} {
    text-align: center;
    flex: 1;
  }
`;

const StyledTypo = styled(Typo)`
  padding-top: 8px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
`;
