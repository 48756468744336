import Typo from "../../atom/Typo";
import dayjs from "dayjs";
import { FontSizeType } from "@/src/styles/typography";
import { ColorType } from "@/src/styles/color";
import { useTranslation } from "react-i18next";

interface EtdCompareCountProps {
  /**
   * @description 초기 날짜
   */
  initDate: string;
  /**
   * @description 최신 날짜
   */
  currentDate: string;
  typoType?: FontSizeType;
  color?: ColorType;
}

function EtdCompareCount({
  initDate,
  currentDate,
  typoType = "h8",
  color,
}: EtdCompareCountProps) {
  const { t } = useTranslation();
  const renderEtdCompareCount = () => {
    const initEtdData = dayjs.utc(initDate);
    const etdData = dayjs.utc(currentDate);
    const etdCompareCount = etdData.diff(initEtdData, "day");

    if (etdCompareCount === 0) {
      return (
        <Typo typoType={typoType} color={color ?? "green1"}>
          ({t("common:onTime")})
        </Typo>
      );
    }

    if (etdCompareCount < 0) {
      return (
        <Typo typoType={typoType} color={color ?? "systemBlue2"}>
          {`(${etdCompareCount} ${t("common:days")})`}
        </Typo>
      );
    }

    if (etdCompareCount > 0) {
      return (
        <Typo typoType={typoType} color={color ?? "red2"}>
          {`(+${etdCompareCount} ${t("common:days")})`}
        </Typo>
      );
    }
  };

  return <>{renderEtdCompareCount()}</>;
}

export default EtdCompareCount;
