import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import SignatureManagement from "./components/card/SignatureManagement";
import BankDetails from "./components/card/BankDetails";
import TermsOfSales from "./components/card/TermsOfSales";
import { styled } from "styled-components";
import BasicInformation from "./components/card/BasicInformation";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { useTranslation } from "react-i18next";
import { useGetSessionQuery } from "@/src/store/apis/auth";

const ExporterCompanyInformationManagement = () => {
  const { t } = useTranslation();

  const { companyName } = useGetExportersQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError }) => {
      const isErrorAndUndefined = isError || currentData === undefined;

      return {
        companyName: !isErrorAndUndefined
          ? currentData.basicInformation.companyName
          : "-",
      };
    },
  });
  useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "COUNTRY",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <ExporterMainLayout
      breadcrumb={[
        t("sideNav:corporateManagement"),
        t("sideNav:companyInformationManagement"),
      ]}
      pageTitle={companyName}
    >
      <FlexRow>
        {/* Left Item*/}
        <FlexColumn>
          <BasicInformation />
          <TermsOfSales />
        </FlexColumn>

        {/* Right Item */}
        <FlexColumn>
          <SignatureManagement />
          <BankDetails />
        </FlexColumn>
      </FlexRow>
    </ExporterMainLayout>
  );
};

export default ExporterCompanyInformationManagement;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 16px;
`;
