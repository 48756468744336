import React from "react";
import dayjs from "dayjs";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { styled } from "styled-components";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { bookingDetailApi } from "@/src/store/apis/bookings/bookingDetail";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { isUndefined } from "@/src/utils/is";
import OpenSvg from "@/src/assets/icons/icon-open.svg";
import { IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Flex from "@/src/components/molecule/Flex";
import { useTranslation } from "react-i18next";

interface BookingInfoCardProps {
  id: number;
}

const BookingInfoCard = ({ id }: BookingInfoCardProps) => {
  const { t } = useTranslation();

  const {
    etdAt,
    etaAt,
    atdAt,
    ataAt,
    numberOfContainer,
    containerType,
    vessel,
    voyageNo,
    portOfLoading,
    placeOfDelivery,
    shippingLine,
    shippingLineUrl,
  } = bookingDetailApi.endpoints.getImporterBookingDetail.useQueryState(
    { id },
    {
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        const isStable = !isUnstable;
        return {
          currentData,
          etdAt: isStable
            ? currentData.etdAt
              ? dayjs(currentData.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : "-"
            : "",
          etaAt: isStable
            ? currentData.etaAt
              ? dayjs(currentData.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : "-"
            : "",
          atdAt: isStable
            ? currentData.atdAt
              ? dayjs(currentData.atdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : "-"
            : "",
          ataAt: isStable
            ? currentData.ataAt
              ? dayjs(currentData.ataAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : "-"
            : "",
          containerType: isStable ? currentData.containerType : "",
          numberOfContainer: isStable ? currentData.numberOfContainer : "",
          shippingLine: isStable ? currentData.shippingLine : "",
          shippingLineUrl: isStable ? currentData.shippingLineUrl : "",
          vessel: isStable ? currentData.vessel : "",
          voyageNo: isStable ? currentData.voyageNo : "",
          portOfLoading: isStable ? currentData.portOfLoading : "",
          placeOfDelivery: isStable ? currentData.placeOfDelivery : "",
        };
      },
    }
  );

  return (
    <SectionCardGrid>
      {[
        { label: t("common:etdAtd"), value: `${etdAt} / ${atdAt}` },
        { label: t("common:etaAta"), value: `${etaAt} / ${ataAt}` },
        {
          label: t("common:noOfContainer"),
          value: `${numberOfContainer}x${containerType}`,
        },
        {
          label: t("common:shippingLine"),
          value: (
            <Flex alignItems="center" gap={4}>
              {shippingLine}
              {shippingLineUrl && (
                <>
                  <IconButton
                    buttonSize={24}
                    buttonColor="black"
                    buttonGrade="tertiary"
                    onClick={() => {
                      window.open(shippingLineUrl, "_blank");
                    }}
                  >
                    <Icon iconSrc={OpenSvg} iconSize={20} />
                  </IconButton>
                </>
              )}
            </Flex>
          ),
        },
        {
          isGrayLine: true,
        },
        {
          label: t("common:vesselAndVoyageNo"),
          value: `${vessel} / ${voyageNo}`,
        },
        { label: t("common:portOfLoading"), value: portOfLoading },
        { label: t("common:placeOfDelivery"), value: placeOfDelivery },
      ].map(({ label, value, isGrayLine }) => {
        if (isGrayLine) {
          return <GrayLine />;
        }
        return (
          <>
            {
              <StyledSectionCardRow
                label={
                  <Typo color="gray6" typoType="b9m">
                    {label}
                  </Typo>
                }
                direction="vertical"
                value={<BreakWordTypo typoType="h4">{value}</BreakWordTypo>}
              />
            }
          </>
        );
      })}
    </SectionCardGrid>
  );
};

export default BookingInfoCard;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;

  p {
    max-width: 100%;
  }
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;
