import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import Icon from "@/src/components/atom/Icon";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import renderContractStatusBadge from "../utils/renderContractStatusBadge";
import renderSignatureStatusBadge from "../utils/renderContractSignatureBadge";
import getTableIndex from "@/src/utils/getTableIndex";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";

// 공통 스타일
const commonCellStyle = { textAlign: "center" };
const commonRightAlignedCellStyle = { textAlign: "right" };
const commonHeaderClass = "ag-right-aligned-cell";

// 공통 CellRenderer 함수
const formatDate = (date: string) => {
  return date ? (
    <Typo typoType="b9r">
      {dayjs(date).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
    </Typo>
  ) : (
    "-"
  );
};

export const columnContractList: ColDef[] = [
  {
    headerClass: commonHeaderClass,
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: false,
    cellStyle: commonCellStyle,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: commonHeaderClass,
    field: "isBookmarked",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isBookmarked === true) {
        return <Icon iconSrc={BookmarkSvg} iconSize={18} />;
      }

      return null;
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonCellStyle,
    field: "buyerNameCode",
    flex: 1,
    minWidth: 230,
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonCellStyle,
    field: "createdAt",
    flex: 1,
    minWidth: 174,
    cellRenderer: (params: ValueGetterParams) =>
      formatDate(params.data.createdAt),
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "contractStatus",
    flex: 1,
    minWidth: 100,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.contractStatus) {
        return renderContractStatusBadge({
          t: params.data.t,
          type: params.data.contractStatus,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "signatureStatus",
    flex: 1,
    minWidth: 100,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.contractStatus) {
        return renderSignatureStatusBadge({
          t: params.data.t,
          type: params.data.signatureStatus,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "shipmentStatus",
    flex: 1,
    width: 230,
    minWidth: 230,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.shipmentBefore} / {params.data.shipmentDuring} /{" "}
          {params.data.shipmentAfter}
        </Typo>
      );
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonCellStyle,
    field: "scNo",
    flex: 1,
    minWidth: 140,
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonCellStyle,
    field: "mainCategoryCodeItemName",
    flex: 1,
    minWidth: 230,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.mainCategory ? (
        <Typo typoType="b9r">{params.data.mainCategory}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonCellStyle,
    field: "itemCode",
    flex: 1,
    minWidth: 230,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonRightAlignedCellStyle,
    field: "quantity",
    flex: 1,
    minWidth: 164,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isQuantity = params.data.quantity && params.data.quantityUnit;

      return isQuantity ? (
        <Typo typoType="b9r">
          {Number(params.data.quantity).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          {params.data.quantityUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonRightAlignedCellStyle,
    field: "balanceOfContract",
    flex: 1,
    minWidth: 164,
    cellRenderer: (params: ValueGetterParams) => {
      return <Typo typoType="b9r">{params.data.balanceOfContractV2}</Typo>;
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonRightAlignedCellStyle,
    field: "unitPrice",
    flex: 1,
    minWidth: 164,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isPrice = params.data.unitPriceUnit && params.data.unitPrice;

      return isPrice ? (
        <Typo typoType="b9r">
          {params.data.unitPriceUnit}{" "}
          {Number(params.data.unitPrice).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonCellStyle,
    field: "shippingTerms",
    flex: 1,
    minWidth: 180,
  },
  {
    headerClass: commonHeaderClass,
    cellStyle: commonCellStyle,
    field: "lastShipmentDateAt",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) =>
      formatDate(params.data.lastShipmentDateAt),
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: commonCellStyle,
    field: "poNo",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return <Typo typoType="b9r">{params.data.poNo || "-"}</Typo>;
    },
  },
];
