import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import {
  useCreateFactoryEmployeesMutation,
  useGetFactoryQuery,
  useLazyGetFactoryEmployeesQuery,
} from "@/src/store/apis/client/factory";
import { factoryContactPersonListColumn } from "./columns/column";
import Badge from "@/src/components/atom/Badge";
import Table from "@/src/components/atom/Table";
import typo from "@/src/styles/typography";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { isNull, isUndefined } from "@/src/utils/is";
import useContentLoading from "@/src/hooks/useContentLoading";
import ContactPersonAddDialog, {
  TransformContactPersonAddModalFormType,
} from "./dialog/ContactPersonAddDialog";
import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import EditSvg from "@/src/assets/icons/icon-edit-white.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import { useTranslation } from "react-i18next";
import BottomFixedContainer from "@/components/molecule/BottomFixedContainer";

enum DialogState {
  NULL,
  ADD_CONTACT_PERSON,
  EDIT_CONTACT_PERSON,
}

const ExporterFactoryManagementDetailPage = () => {
  const { t } = useTranslation();
  const agGridRef = useRef<AgGridReact>(null);
  const pageParams = useParams<{ id: string }>();
  const alert = useAlert();
  const navigator = useNavigate();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [columnDefs] = useState<ColDef[]>(factoryContactPersonListColumn);

  const { data: factoryInfo, isFetching: isFactoryInfoFetching } =
    useGetFactoryQuery(
      {
        id: Number(pageParams.id),
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const [
    getFactoryEmployees,
    { factoryEmployees, factoryEmployeesCount, isEmployeesFetching },
  ] = useLazyGetFactoryEmployeesQuery({
    selectFromResult: ({ isFetching, isError, currentData }) => {
      const isUnstable = isError || isUndefined(currentData);

      return {
        factoryEmployees: isUnstable ? [] : currentData.rows,
        factoryEmployeesCount: isUnstable ? 0 : currentData.count,
        isEmployeesFetching: isFetching,
      };
    },
  });

  const { isMainCategoryFetching, mainCategory } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategory: !isErrorAndUndefined ? currentData ?? [] : [],
          };
        },
      }
    );
  const [createEmployee] = useCreateFactoryEmployeesMutation();

  useAgGridHeaderRefresh({
    gridRef: agGridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "factoryManagement:common.name",
      },
      {
        columnKey: "email",
        langKey: "factoryManagement:common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "factoryManagement:common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "factoryManagement:common.officeContact",
      },
      {
        columnKey: "isActivated",
        langKey: "factoryManagement:common.use",
      },
    ],
  });

  const basicInformationCardLeftSideItemList = [
    {
      label: t("factoryManagement:common.factoryName"),
      value: factoryInfo?.workplaceName ? factoryInfo.workplaceName : "-",
    },
    {
      label: t("factoryManagement:common.countryAndCity"),
      value: factoryInfo?.country
        ? `${factoryInfo.country} ${
            factoryInfo.city &&
            !isUndefined(factoryInfo.gmtOffset) &&
            !isNull(factoryInfo.gmtOffset)
              ? `/ ${factoryInfo.city} (UTC ${
                  factoryInfo.gmtOffset > 0 ? "+" : ""
                }${factoryInfo.gmtOffset})`
              : ""
          }`
        : "-",
    },
    {
      label: t("factoryManagement:common.categoryInCharge"),
      value:
        !isMainCategoryFetching && factoryInfo?.mainCategoryCodeItemNames
          ? factoryInfo.mainCategoryCodeItemNames
              .map(
                (main) =>
                  mainCategory.find(({ codeItemName }) => codeItemName === main)
                    ?.codeItemNameEn
              )
              .join(", ")
          : "-",
    },
    {
      label: t("factoryManagement:common.businessNumber"),
      value: factoryInfo?.businessNumber ?? "-",
    },
    {
      label: t("factoryManagement:common.businessRegistration"),
      value: factoryInfo?.businessRegistrationSimpleMedia?.originalFileName ? (
        <RowValueContainer>
          {factoryInfo?.businessRegistrationSimpleMedia.originalFileName}{" "}
          <StyledIconButton
            buttonGrade="secondary"
            buttonSize={24}
            buttonColor="blue"
            onClick={() => {
              if (factoryInfo.businessRegistrationSimpleMedia) {
                window.open(
                  factoryInfo.businessRegistrationSimpleMedia.mediaUrl
                );
              }
            }}
          >
            <Icon iconSrc={PreviewSvg} iconSize={16} />
          </StyledIconButton>
        </RowValueContainer>
      ) : (
        "-"
      ),
    },
  ];

  const basicInformationCardRightSideItemList = [
    {
      label: t("signupCorp:content.address"),
      value: factoryInfo?.address ? factoryInfo.address : "-",
    },
    {
      label: t("factoryManagement:common.contact"),
      value:
        factoryInfo?.workplaceQueryResultDto.tel &&
        factoryInfo?.workplaceQueryResultDto.telPrefix
          ? factoryInfo?.fullTel
          : "-",
    },
    {
      label: t("factoryManagement:common.fax"),
      value:
        factoryInfo?.workplaceQueryResultDto.fax &&
        factoryInfo?.workplaceQueryResultDto.faxPrefix
          ? factoryInfo?.fullFax
          : "-",
    },
    {
      label: t("factoryManagement:common.use"),
      value: factoryInfo?.isActivated ? (
        <Badge
          text={t("status:yes")}
          badgeSize="S"
          badgeColor="systemLime5"
          color="systemLime1"
        />
      ) : (
        <Badge
          text={t("status:no")}
          badgeSize="S"
          badgeColor="gray10"
          color="gray7"
        />
      ),
    },
  ];

  const fetchFactoryEmployees = async ({
    page,
    pageSize,
  }: {
    page?: number;
    pageSize?: number;
  }) => {
    try {
      await getFactoryEmployees({
        id: Number(pageParams.id),
        page,
        pageSize,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleCreateEmployee = async (
    values: TransformContactPersonAddModalFormType
  ) => {
    try {
      await createEmployee({
        id: Number(pageParams.id) ?? "",
        isActivated: values.isActivated,
        name: values.name,
        email: values.email,
        personalContactPrefix: values.personalContactPrefix,
        personalContact: values.personalContact,
        officeContactPrefix: values.officeContactPrefix
          ? values.officeContactPrefix
          : undefined,
        officeContact: values.officeContact ? values.officeContact : undefined,
      }).unwrap();
      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    fetchFactoryEmployees({
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agGridRef.current && isEmployeesFetching) {
      agGridRef.current.api.showLoadingOverlay();
    }
  }, [isEmployeesFetching]);

  useEffect(() => {
    if (isFactoryInfoFetching) {
      handleContentLoadingOn();
    } else {
      handleContentLoadingOff();
    }
  }, [handleContentLoadingOff, handleContentLoadingOn, isFactoryInfoFetching]);

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;
    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      return (
        <ContactPersonAddDialog
          defaultCountryCode={
            factoryInfo?.workplaceQueryResultDto.country.value1
          }
          onSave={handleCreateEmployee}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  return (
    <ExporterMainLayout
      breadcrumb={[
        t("sideNav:factoryManagement"),
        t("sideNav:factoryManagementDetail"),
      ]}
      customPageTitle={
        <PageTitleContainer>
          <h1>{factoryInfo?.workplaceName ?? ""}</h1>
          <StyledButton
            buttonSize={32}
            onClick={() =>
              navigator(
                `${EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_EDIT}/${pageParams.id}`
              )
            }
          >
            <Icon iconSrc={EditSvg} iconSize={16} />
            <Typo typoType="btn3m" color="white">
              {t("factoryManagement:common.edit")}
            </Typo>
          </StyledButton>
        </PageTitleContainer>
      }
    >
      <SectionContainer>
        <SectionCard cardTitle={t("factoryManagement:common.basicInformation")}>
          <StyledDivContainer>
            <HalfDiv>
              {basicInformationCardLeftSideItemList.map(({ label, value }) => {
                return (
                  <SectionCardRow
                    label={label}
                    direction="horizontal"
                    value={value}
                  />
                );
              })}
            </HalfDiv>
            <Divider />
            <HalfDiv>
              {basicInformationCardRightSideItemList.map(({ label, value }) => {
                return (
                  <SectionCardRow
                    label={label}
                    direction="horizontal"
                    value={value}
                  />
                );
              })}
            </HalfDiv>
          </StyledDivContainer>
        </SectionCard>

        <SectionCard
          cardTitle={t("factoryManagement:common.contactPersonList")}
          rightAccessory={
            <StyledButton
              buttonGrade="tertiary"
              buttonSize={32}
              buttonColor="black"
              onClick={() => setDialogState(DialogState.ADD_CONTACT_PERSON)}
            >
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo typoType="btn3m" color="gray2">
                {t("factoryManagement:factoryListAddPage.addContactPerson")}
              </Typo>
            </StyledButton>
          }
        >
          <Table
            ref={agGridRef}
            rowData={isEmployeesFetching ? undefined : factoryEmployees}
            columnDefs={columnDefs}
            isPaginationDataMaping
            totalPage={factoryEmployeesCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onGridReady={() => setIsReady(true)}
            handlePaginationClick={(page, pageSize) => {
              fetchFactoryEmployees({ page, pageSize });
              setPagination({ page, pageSize });
            }}
          />
        </SectionCard>

        <BottomFixedContainer>
          <BackButton
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => navigator(-1)}
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
          >
            <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
            {t("factoryManagement:common.backToPrevious")}
          </BackButton>
        </BottomFixedContainer>
      </SectionContainer>
      {renderDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterFactoryManagementDetailPage;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const StyledDivContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const HalfDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  padding: 0.5px;
  background-color: ${colorSet.gray9};
`;

const PageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    ${typo.h1}
    padding: 15px 0;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BackButton = styled(Button)`
  ${typo.btn3m}
  display: inline-flex;
  align-self: flex-start;
`;

const RowValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIconButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 4px;
`;
