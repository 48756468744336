import React, { useState } from "react";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import useAlert from "@/src/hooks/useAlert";
import {
  taskDetailApi,
  useSendTaskRequestMutation,
} from "@/src/store/apis/tasks/taskDetail";
import {
  SimpleTaskWorkplaceEmployee,
  TransmissionMethodType,
} from "@/src/store/apis/tasks/taskDetail/interface";
import colorSet from "@/src/styles/color";
import dayjs from "dayjs";
import { css, styled } from "styled-components";
import { useGetFactoryEmployeesQuery } from "@/src/store/apis/client/factory";
import Loader from "@/src/components/atom/Loader";
import { useParams } from "react-router-dom";
import { WorkplaceEmployeeDto } from "@/src/store/apis/client/factory/interface";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

const loadingWorkplaceEmployeesEmptyArray: SimpleTaskWorkplaceEmployee[] = [];
const workplaceEmptyArray: WorkplaceEmployeeDto[] = [];

function SendTaskRequestCard() {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();

  // API
  const [sendLoading, sendLoadingData] = useSendTaskRequestMutation();
  const { isError, workplaceId, address, simpleLoadingWorkplaceEmployees } =
    taskDetailApi.endpoints.getTaskDetail.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ isError, currentData, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;
          const mergedAddress = !isUnstable
            ? [
                currentData?.workplacePostalCode,
                currentData?.workplaceStreetAddress,
                currentData?.workplaceLocality,
                currentData?.workplaceRegion,
                currentData?.workplaceCountryName,
              ]
                .filter(Boolean)
                .join(", ")
            : "-";

          return {
            isError,
            workplaceId: currentData?.workplaceId,
            address: mergedAddress,
            simpleLoadingWorkplaceEmployees: !isUnstable
              ? currentData?.simpleTaskWorkplaceEmployees ??
                loadingWorkplaceEmployeesEmptyArray
              : loadingWorkplaceEmployeesEmptyArray,
          };
        },
      }
    );
  const { factoryList } = useGetFactoryEmployeesQuery(
    {
      id: Number(workplaceId),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        return {
          factoryList: !isUnstable
            ? currentData.rows ?? workplaceEmptyArray
            : workplaceEmptyArray,
        };
      },
    }
  );

  const [selectProfileId, setSelectProfileId] = useState<number>();

  const handleSendClick = async (id: number) => {
    try {
      await sendLoading({ id }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("task:detail.alert.sendLoadingAlert"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderTransmissionMethod = (type: TransmissionMethodType) => {
    switch (type) {
      case "ALL":
        return t("common:smsAndEmail.en");

      case "EMAIL":
        return t("common:email.en");

      case "SMS":
        return t("common:sms.en");
    }
  };

  return (
    <StyledSectionCard cardTitle={t("common:sendLoadingRequest")}>
      <FlexColumn>
        <SectionCardRow
          label={t("task:detail.cards.address")}
          value={address}
          direction="vertical"
        />

        {!isError ? (
          <FlexRow>
            {simpleLoadingWorkplaceEmployees.map(
              (
                {
                  email,
                  transmissionMethod,
                  lastSentAt,
                  personalContactPrefix,
                  personalContact,
                  taskWorkplaceEmployeeId,
                  workplaceEmployeeId,
                },
                idx
              ) => {
                return (
                  <Profile key={idx.toString()}>
                    <>
                      <Typo typoType="h4">
                        {taskWorkplaceEmployeeId
                          ? factoryList.find(
                              (item) => item.id === workplaceEmployeeId
                            )?.name
                          : "-"}
                      </Typo>

                      <ProfileRow>
                        <Label typoType="b7m" color="gray6">
                          {t("task:detail.cards.contact")}
                        </Label>

                        {personalContactPrefix && personalContact ? (
                          <Typo typoType="b7r">
                            {personalContactPrefix} {personalContact}
                          </Typo>
                        ) : (
                          "-"
                        )}
                      </ProfileRow>

                      <ProfileRow>
                        <Label typoType="b7m" color="gray6">
                          {t("task:detail.cards.email")}
                        </Label>

                        <StyledTypo typoType="b7r">{email ?? "-"}</StyledTypo>
                      </ProfileRow>

                      <ProfileRow>
                        <Label typoType="b7m" color="gray6">
                          {t("task:detail.cards.transmissionMethod")}
                        </Label>

                        {transmissionMethod ? (
                          <Typo typoType="b7r">
                            {renderTransmissionMethod(transmissionMethod)}
                          </Typo>
                        ) : (
                          "-"
                        )}
                      </ProfileRow>

                      <ProfileRow>
                        <Label typoType="b7m" color="gray6">
                          {t("task:detail.cards.sendDate")}
                        </Label>

                        {lastSentAt ? (
                          <Typo typoType="b7r">
                            {dayjs(lastSentAt).format(
                              DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
                            )}
                          </Typo>
                        ) : (
                          "-"
                        )}
                      </ProfileRow>
                    </>
                    <StyledButton
                      buttonColor="blue"
                      buttonGrade="secondary"
                      buttonSize={32}
                      onClick={() => {
                        handleSendClick(taskWorkplaceEmployeeId);
                        setSelectProfileId(taskWorkplaceEmployeeId);
                      }}
                      disabled={
                        sendLoadingData?.isLoading &&
                        taskWorkplaceEmployeeId === selectProfileId
                      }
                    >
                      {sendLoadingData?.isLoading &&
                      taskWorkplaceEmployeeId === selectProfileId ? (
                        <Loader />
                      ) : (
                        t("task:detail.button.sendButton")
                      )}
                    </StyledButton>
                  </Profile>
                );
              }
            )}
          </FlexRow>
        ) : (
          "-"
        )}
      </FlexColumn>
    </StyledSectionCard>
  );
}

export default SendTaskRequestCard;

const StyledSectionCard = styled(SectionCard)`
  grid-column: 7 span;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  ${() => css`
    container-type: inline-size;
  `}
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};
  max-width: 426px;
  flex: 1;

  ${() => css`
    @container (max-width: 682px) {
      min-width: 100%;
      max-width: 100%;
    }
  `}
`;

const ProfileRow = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  text-align: center;
  margin-top: auto;
`;

const Label = styled(Typo)`
  min-width: 106px;
  max-width: 106px;
`;

const StyledTypo = styled(Typo)`
  word-break: break-all;
`;
