import Badge from "@/src/components/atom/Badge";
import { SalesStatusType } from "@/src/store/apis/sales/interface";
import { t } from "i18next";

const renderSalesStatusBadge = (param: {
  type: SalesStatusType | null;
  size?: "S" | "L";
}) => {
  const { type, size = "S" } = param;

  switch (type) {
    case "PROCESSING":
      return (
        <Badge
          text={t("status:contract.processing")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );

    case "COMPLETE":
      return (
        <Badge
          text={t("status:contract.complete")}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
  }
};

export default renderSalesStatusBadge;
