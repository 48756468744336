import { useCallback, useState } from "react";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import { styled } from "styled-components";
import { FlexCenter } from "./BasicInformation";
import EditSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import { useAppSelector } from "@/src/store";
import { companyApi } from "@/src/store/apis/corporate/company";
import Loader from "@/src/components/atom/Loader";
import { useTranslation } from "react-i18next";
import TermsAddDialog from "@/src/components/organism/TermsAddDialog";
import TermsEditDialog from "@/src/components/organism/TermsEditDialog";
import TermsPreviewDialog from "@/src/components/organism/TermsPreviewDialog";

enum DialogState {
  NULL,
  ADD,
  EDIT,
  PREVIEW,
}

const TermsOfSales = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const [termsDialogState, setTermsDialogState] = useState<DialogState>(
    DialogState.NULL
  );

  const { title, salesContractTermsId, body, isTerms, isFetching } =
    companyApi.endpoints.getExporters.useQueryState(undefined, {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isErrorAndUndefined = isError || currentData === undefined;

        return {
          isFetching,
          isTerms:
            !!currentData?.termsOfSalesContract
              .salesContractTermsWithLatestHistory,
          title: !isErrorAndUndefined
            ? currentData?.termsOfSalesContract
                .salesContractTermsWithLatestHistory?.title ?? "-"
            : "-",
          salesContractTermsId: Number(
            currentData?.termsOfSalesContract
              .salesContractTermsWithLatestHistory?.id
          ),
          body: !isErrorAndUndefined
            ? currentData.termsOfSalesContract
                ?.salesContractTermsWithLatestHistory
                ?.latestSalesContractTermsHistory.body ?? "-"
            : "-",
        };
      },
    });

  const renderAddAndEditButton = useCallback(() => {
    if (isFetching) {
      return (
        <Button
          isLoading
          buttonGrade="tertiary"
          buttonColor="black"
          buttonSize={32}
        >
          <FlexCenter>
            <Loader />
          </FlexCenter>
        </Button>
      );
    }

    return (
      <Button
        buttonGrade="tertiary"
        buttonColor="black"
        buttonSize={32}
        onClick={() =>
          isTerms
            ? setTermsDialogState(DialogState.EDIT)
            : setTermsDialogState(DialogState.ADD)
        }
      >
        <FlexCenter>
          <Icon iconSrc={isTerms ? EditSvg : AddSvg} iconSize={16} />
          {isTerms
            ? t("companyManagement:importer.companyInfo.terms.editButton")
            : t("companyManagement:importer.companyInfo.terms.addButton")}
        </FlexCenter>
      </Button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTerms, isFetching]);

  const renderPreviewControl = useCallback(() => {
    if (isTerms === false) {
      return (
        <Typo typoType="b7r" color="gray7">
          {t("companyManagement:importer.companyInfo.terms.termsPlaceholder")}
        </Typo>
      );
    }

    if (isTerms === true) {
      return (
        <TermsPreviewContainer>
          <Typo typoType="b7r">{title}</Typo>
          <PreviewButton
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={32}
            onClick={() => setTermsDialogState(DialogState.PREVIEW)}
          >
            <Icon iconSrc={PreviewSvg} iconSize={16} />
          </PreviewButton>
        </TermsPreviewContainer>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTerms, title]);

  const renderTermsDialog = () => {
    if (termsDialogState === DialogState.NULL) {
      return;
    }

    if (termsDialogState === DialogState.ADD) {
      return (
        <TermsAddDialog
          title={t("common:registerOrderTerms")}
          open={termsDialogState === DialogState.ADD}
          onOpenChange={() => setTermsDialogState(DialogState.NULL)}
        />
      );
    }

    if (termsDialogState === DialogState.EDIT) {
      return (
        <TermsEditDialog
          title={t("common:editOrderTerms")}
          open={termsDialogState === DialogState.EDIT}
          onOpenChange={() => setTermsDialogState(DialogState.NULL)}
          termsData={{
            salesContractTermsId,
            title,
            body,
          }}
        />
      );
    }

    if (termsDialogState === DialogState.PREVIEW) {
      return (
        <TermsPreviewDialog
          title={t("common:orderTermsPreview")}
          open={termsDialogState === DialogState.PREVIEW}
          onOpenChange={() => setTermsDialogState(DialogState.NULL)}
          termsValueData={{
            title,
            body,
          }}
        />
      );
    }
  };

  return (
    <StyledSectionCard
      cardTitle={t("common:orderTerms")}
      rightAccessory={
        user?.exporterUserType !== "MANAGER" && renderAddAndEditButton()
      }
    >
      {renderPreviewControl()}
      {renderTermsDialog()}
    </StyledSectionCard>
  );
};

export default TermsOfSales;

const StyledSectionCard = styled(SectionCard)`
  height: 100%;
`;

const TermsPreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PreviewButton = styled(Button)`
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
