import Typo from "@/src/components/atom/Typo";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { SimpleContractDto } from "@/src/store/apis/bookings/bookingDetail/interface";
import colorSet from "@/src/styles/color";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useTranslation } from "react-i18next";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";

interface SimpleContractProps {
  data: SimpleContractDto;
  index: number;
}

function SimpleContract({ data, index }: SimpleContractProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCardOpen, setIsCardOpen] = useState(true);

  const {
    scNo,
    id,
    buyerId,
    buyerName,
    exporterItem,
    paymentTerms,
    paymentTermsRemark,
    buyerEmployeeName,
    poNo,
    lcNo,
    quantity,
    quantityUnit,
    unitPrice,
    unitPriceUnit,
    allocation,
    placeOfDelivery,
    shippingTerms,
    shippingTermsRemark,
    lastShipmentDateAt,
    freetime,
    origin,
    exporterItemCode,
  } = data;

  return (
    <SectionFoldableCard
      open={isCardOpen}
      onOpenChange={setIsCardOpen}
      cardTitle={`${t(
        "booking:detail.card.contractInformation"
      )} ${addZeroPrefix(index + 1)}`}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={isCardOpen}
          onClick={() => setIsCardOpen((prev) => !prev)}
        />
      }
    >
      <SimpleContractContainer>
        {/* Simple Contract Info */}
        <InformationContainer>
          <InformationItem>
            <Typo typoType="b9m" color="gray6">
              {t("common:scNo")}
            </Typo>
            <BlueText
              typoType="h4"
              color="systemBlue2"
              onClick={() =>
                navigate(`${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${id}`)
              }
            >
              {scNo}
            </BlueText>
          </InformationItem>

          <InformationItem>
            <Typo typoType="b9m" color="gray6">
              {t("common:buyer")}
            </Typo>
            <BlueText
              typoType="h4"
              color="systemBlue2"
              onClick={() =>
                navigate(
                  `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_DETAIL}/${buyerId}`
                )
              }
            >
              {buyerName}
            </BlueText>
          </InformationItem>

          <InformationItem>
            <Typo typoType="b9m" color="gray6">
              {t("common:item")}
            </Typo>
            <Typo typoType="h4" color="blue2">
              {exporterItem}
            </Typo>
          </InformationItem>

          <InformationItem>
            <Typo typoType="b9m" color="gray6">
              {t("common:contact")}
            </Typo>
            <Typo typoType="h4" color="blue2">
              {buyerEmployeeName}
            </Typo>
          </InformationItem>

          <InformationItem>
            <Typo typoType="b9m" color="gray6">
              {t("common:paymentTerms")}
            </Typo>
            <PaymentTermsContainer>
              <BreakWordTypo color="blue2" typoType="h4">
                {paymentTerms
                  ? `${paymentTerms} ${paymentTermsRemark ?? ""}`
                  : "-"}
              </BreakWordTypo>
            </PaymentTermsContainer>
          </InformationItem>
        </InformationContainer>

        {/* Rows */}
        <InformationDetail>
          <InformationDetailItem>
            <SectionCardRow
              label={t("common:itemCode")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {exporterItemCode ?? "-"}
                </BreakWordTypo>
              }
            />
            <SectionCardRow
              label={t("common:poNo")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {poNo ?? "-"}
                </BreakWordTypo>
              }
            />
            {paymentTerms === PAYMENT_TERMS_LC_TYPE && (
              <SectionCardRow
                label={t("common:lcNo")}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {lcNo ?? "-"}
                  </BreakWordTypo>
                }
              />
            )}
            <SectionCardRow
              label={t("common:quantity")}
              value={
                quantity && quantityUnit ? (
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {`${Number(quantity).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${quantityUnit}`}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
            <SectionCardRow
              label={t("common:unitPrice")}
              value={
                unitPrice && unitPriceUnit ? (
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {`${Number(unitPrice).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${unitPriceUnit}`}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />

            {allocation && (
              <SectionCardRow
                label={t("common:allocation")}
                value={allocation ?? "-"}
              />
            )}
          </InformationDetailItem>
          <GrayLine />
          <InformationDetailItem>
            <SectionCardRow
              label={t("common:placeOfDelivery")}
              value={placeOfDelivery ? placeOfDelivery : "-"}
            />
            <SectionCardRow
              label={t("common:shippingTerms")}
              value={
                shippingTerms ? (
                  <ShippingTerms>
                    <Typo typoType="b7r" color="gray5">
                      {shippingTerms}
                    </Typo>
                    {shippingTermsRemark && (
                      <BreakWordTypo typoType="b7r" color="gray5">
                        {shippingTermsRemark}
                      </BreakWordTypo>
                    )}
                  </ShippingTerms>
                ) : (
                  "-"
                )
              }
            />
            <SectionCardRow
              label={t("common:lastShipmentDate")}
              value={
                lastShipmentDateAt
                  ? dayjs(lastShipmentDateAt).format("YYYY-MM-DD")
                  : "-"
              }
            />
            <SectionCardRow
              label={t("common:freetime")}
              value={freetime ? freetime : "-"}
            />
            <SectionCardRow
              label={t("common:originLocation")}
              value={origin ? origin : "-"}
            />
          </InformationDetailItem>
        </InformationDetail>
      </SimpleContractContainer>
    </SectionFoldableCard>
  );
}

export default SimpleContract;

const SimpleContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InformationContainer = styled.ul`
  display: flex;
  border-radius: 8px;
  background: ${colorSet.gray11};
`;

const InformationItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  padding: 16px 8px;
  flex: 1;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;

const InformationDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 16px;
  padding: 0 8px;
`;

const InformationDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GrayLine = styled.div`
  width: 1px;
  border-right: 1px solid ${colorSet.gray9};
`;

const ShippingTerms = styled.div`
  display: flex;
  gap: 4px;
`;
