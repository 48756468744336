import React, { ReactNode, useState } from "react";
import styled, { keyframes } from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import Icon from "@/src/components/atom/Icon";
import { Button, IconButton } from "@/src/components/atom/Button";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ReactComponent as ResetSvg } from "@/src/assets/icons/icon-reset-circle.svg";
import { ReactComponent as ArrowDownSvg } from "@/src/assets/icons/icon-chevron-down.svg";
import { isNull, isUndefined } from "@/src/utils/is";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import {
  useCombineAndSplitBookingMutation,
  useLazyGetShipmentecombinationListQuery,
} from "@/src/store/apis/tasks/taskDetail";
import BookingContent from "./BookingContent";
import ContractContent from "./ContractContent";
import {
  BookingRecombinationBookingListViewDto,
  BookingRecombinationShipmentListViewDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import ShipmentContent from "./ShipmentContent";
import useAlert from "@/src/hooks/useAlert";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface CombineSplitDialogProps {
  onClose?: () => void;
  loadingId: number;
  selectedContainer: number[];
}

interface AccordionItemProps {
  value: string;
  renderTrigger?: ReactNode;
  renderContent?: ReactNode;
  disabled: boolean;
}

type AccordionItemType = "booking" | "contract" | "shipment";

const emptyArray: BookingRecombinationShipmentListViewDto[] = [];

const defaultPagination = {
  page: 1,
  pageSize: 10,
};

const CombineSplitDialog = ({
  onClose,
  loadingId,
  selectedContainer,
}: CombineSplitDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [tab, setTab] = useState<AccordionItemType>("booking");

  const [buyerCode, setBuyerCode] = useState<string>("all");
  const [selectedBooking, setSelectedBooking] =
    useState<BookingRecombinationBookingListViewDto | null>(null);
  const [bookingPagination, setBookingPagination] = useState(defaultPagination);

  const [contractId, setContractId] = useState<number | null>(null);
  const [contractPagination, setContractPagination] =
    useState(defaultPagination);

  const [shipmentId, setShipmentId] = useState<number | null>(null);
  const [shipmentPagination, setShipmentPagination] =
    useState(defaultPagination);

  const [combineAndSplitBooking] = useCombineAndSplitBookingMutation();

  const { isBuyerListFetching, buyers } = useGetBuyersQuery(
    {},
    {
      selectFromResult: ({ currentData, isFetching, isError }) => ({
        buyers: !isError && currentData?.data ? currentData.data : [],
        isBuyerListError: isError,
        isBuyerListFetching: isFetching,
      }),
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    getShipmentList,
    {
      shipmentList,
      shipmentCount,
      isShipmentListFetching,
      isShipmentUninitialized,
    },
  ] = useLazyGetShipmentecombinationListQuery({
    selectFromResult: ({
      currentData,
      isError,
      isFetching,
      isUninitialized,
    }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      return {
        shipmentCount: !isUnstable ? currentData.count : 0,
        shipmentList: !isUnstable ? currentData.rows : emptyArray,
        isShipmentListFetching: isFetching,
        isShipmentUninitialized: isUninitialized,
      };
    },
  });

  const getDisabledState = (value: AccordionItemType) => {
    if (value === "contract") {
      if (isNull(selectedBooking)) return true;
    }

    if (value === "shipment") {
      if (isNull(contractId)) return true;
    }

    return false;
  };

  const resetPagination = (domain: ("booking" | "contract" | "shipment")[]) => {
    if (domain.includes("booking")) {
      setBookingPagination(defaultPagination);
    }
    if (domain.includes("contract")) {
      setContractPagination(defaultPagination);
    }
    if (domain.includes("shipment")) {
      setShipmentPagination(defaultPagination);
    }
  };

  const handleCombineAndSplit = async () => {
    if (!selectedBooking || !contractId) return;
    try {
      await combineAndSplitBooking({
        taskId: loadingId,
        bookingId: selectedBooking.id,
        contractId,
        containerIdList: selectedContainer,
        shipmentId: shipmentId || undefined,
      }).unwrap();

      onClose?.();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderAccordionTitle = (value: AccordionItemType) => {
    switch (value) {
      case "booking": {
        const isNotReady = isNull(selectedBooking);
        return (
          <AccordionTitleSection>
            <h3>
              {t("common:step1SelectBooking")} <strong>*</strong>
            </h3>

            <AccordionController>
              <IconButton
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={() => {
                  setTab("booking");
                  setSelectedBooking(null);
                  setContractId(null);
                  setShipmentId(null);
                  resetPagination(["booking", "contract", "shipment"]);
                }}
              >
                <ResetIcon color={isNotReady ? "gray7" : "gray2"} />
              </IconButton>

              <ArrowIcon className="arrow" color="gray2" />
            </AccordionController>
          </AccordionTitleSection>
        );
      }
      case "contract": {
        const isNotReady = isNull(contractId);
        return (
          <AccordionTitleSection data-disabled={getDisabledState("contract")}>
            <h3>
              {t("common:step2SelectContract")} <strong>*</strong>
            </h3>

            <AccordionController>
              <IconButton
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                disabled={isNotReady}
                onClick={() => {
                  setTab("contract");
                  setContractId(null);
                  setShipmentId(null);
                  resetPagination(["contract", "shipment"]);
                }}
              >
                <ResetIcon color={isNotReady ? "gray7" : "gray2"} />
              </IconButton>

              <ArrowIcon
                className="arrow"
                color={getDisabledState("contract") ? "gray7" : "gray2"}
              />
            </AccordionController>
          </AccordionTitleSection>
        );
      }
      case "shipment": {
        const isNotReady = isNull(shipmentId);
        return (
          <AccordionTitleSection
            data-disabled={getDisabledState("shipment") || shipmentCount === 0}
          >
            <h3>{t("common:Step3SelectShipment")}</h3>

            <AccordionController>
              <IconButton
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                disabled={isNotReady}
                onClick={() => {
                  setTab("shipment");
                  setShipmentId(null);
                  resetPagination(["shipment"]);
                }}
              >
                <ResetIcon color={isNotReady ? "gray7" : "gray2"} />
              </IconButton>

              <ArrowIcon
                className="arrow"
                color={
                  getDisabledState("shipment") || shipmentCount === 0
                    ? "gray7"
                    : "gray2"
                }
              />
            </AccordionController>
          </AccordionTitleSection>
        );
      }
    }
  };

  const renderAccordionContent = (value: AccordionItemType) => {
    switch (value) {
      case "booking": {
        return (
          <BookingContent
            isBuyerFetching={isBuyerListFetching}
            selectedBookingId={selectedBooking?.id || null}
            selectedContainerCount={selectedContainer.length}
            buyerCode={buyerCode}
            buyers={buyers}
            loadingId={loadingId}
            page={bookingPagination.page}
            onPageChange={(page) =>
              setBookingPagination({ page, pageSize: 10 })
            }
            onBookingChange={(booking) => setSelectedBooking(booking)}
            onBuyerChange={(buyerCode) => setBuyerCode(buyerCode)}
            onTabChange={() => setTab("contract")}
            onPaginationReset={() =>
              resetPagination(["contract", "booking", "shipment"])
            }
            onReset={() => {
              setContractId(null);
              setShipmentId(null);
              resetPagination(["contract", "shipment"]);
            }}
          />
        );
      }
      case "contract": {
        if (!selectedBooking) return null;
        return (
          <ContractContent
            selectedBookingId={selectedBooking.id}
            selectedContractId={contractId}
            loadingId={loadingId}
            page={contractPagination.page}
            buyerNameCode={buyerCode !== "all" ? buyerCode : undefined}
            onPageChange={(page) =>
              setContractPagination({ page, pageSize: 10 })
            }
            onContractChange={(contractId) => setContractId(contractId)}
            onTabChange={async (id) => {
              try {
                if (id !== contractId) {
                  const shipment = await getShipmentList({
                    bookingId: selectedBooking.id,
                    contractId: id,
                    page: 1,
                  }).unwrap();
                  if (!!shipment?.count) {
                    setTab("shipment");
                  }
                }
              } catch (e: any) {
                const message = Array.isArray(e.data.message)
                  ? e.data.message[0]
                  : e.data.message;

                if (e.data.statusCode !== 404) {
                  alert.showAlert({ type: "error", message });
                }
              }
            }}
            onReset={() => {
              setShipmentId(null);
              resetPagination(["contract", "shipment"]);
            }}
          />
        );
      }
      case "shipment": {
        if (!selectedBooking || !contractId) return null;
        return (
          <ShipmentContent
            selectedShipmentId={shipmentId}
            page={shipmentPagination.page}
            shipmentList={shipmentList}
            shipmentCount={shipmentCount}
            isShipmentListFetching={isShipmentListFetching}
            isShipmentUninitialized={isShipmentUninitialized}
            onShipmentChange={(id) => setShipmentId(id)}
            onPageChange={async (page) => {
              await getShipmentList({
                bookingId: selectedBooking.id,
                contractId,
                page,
              });
              setShipmentPagination({ page, pageSize: 10 });
            }}
          />
        );
      }
    }
  };

  return (
    <Dialog
      title={t("task:detail.dialog.combineSplit")}
      open
      onOpenChange={() => onClose?.()}
      width={1200}
      footer={
        <DialogFooterContainer>
          <Button
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={40}
            onClick={() => onClose?.()}
          >
            {t("common:cancel")}
          </Button>
          <Button
            disabled={!selectedBooking || !contractId}
            onClick={handleCombineAndSplit}
          >
            {t("common:save")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Inner>
        <InfoDiv>
          <Icon iconSrc={InfoGray6Svg} iconSize={16} />
          <Typo typoType="b9r" color="gray6">
            {t("task:detail.dialog.containerSplitInfo")}
          </Typo>
        </InfoDiv>

        <Container>
          <Accordion.Root
            type="single"
            defaultValue="booking"
            value={tab}
            onValueChange={(value: AccordionItemType) => setTab(value)}
          >
            {(["booking", "contract", "shipment"] as AccordionItemType[]).map(
              (value) => {
                return (
                  <AccordionItem
                    key={value}
                    value={value}
                    renderTrigger={renderAccordionTitle(value)}
                    renderContent={renderAccordionContent(value)}
                    disabled={getDisabledState(value)}
                  />
                );
              }
            )}
          </Accordion.Root>
        </Container>
      </Inner>
    </Dialog>
  );
};

export default CombineSplitDialog;

const AccordionItem = ({
  value,
  renderTrigger,
  renderContent,
  disabled,
}: AccordionItemProps) => {
  return (
    <Item className="AccordionItem" value={value} disabled={disabled}>
      <Trigger>{renderTrigger}</Trigger>
      <Content>{renderContent}</Content>
    </Item>
  );
};

const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const slideDown = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: var(--radix-accordion-content-height);
    }
`;

const slideUp = keyframes`
    0% {
        height: var(--radix-accordion-content-height);
    }
    100% {
        height: 0;
    }
`;

const Trigger = styled(Accordion.Trigger)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray11};
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow {
    transition: transform 0.3s linear;
  }

  &:focus {
    outline-offset: 1px;
    border: 2px solid ${colorSet.blue5};
    padding: 14px 14px 15px 14px;
  }

  &[data-state="open"] {
    .arrow {
      transform: rotate(-180deg);
    }
  }
`;

const Content = styled(Accordion.Content)`
  background: ${colorSet.gray11};
  overflow: hidden;
  padding: 24px 40px;

  &[data-state="open"] {
    animation: ${slideDown} 0.3s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.3s ease-in-out;
  }
`;

const Item = styled(Accordion.Item)`
  overflow: hidden;
`;

const Inner = styled.div`
  min-height: 716px;
`;

const Container = styled.div`
  box-shadow: 0px 4px 15px 0px #0000000d;
  padding: 8px;
  border-radius: 16px;
`;

const AccordionTitleSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    ${typo.h6};

    strong {
      color: ${colorSet.red2};
      ${typo.b7m};
    }
  }

  &[data-disabled="true"] {
    h3 {
      color: ${colorSet.gray7};
    }
  }
`;

const AccordionController = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ResetIcon = styled(ResetSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const ArrowIcon = styled(ArrowDownSvg)<{ color: ColorType }>`
  width: 24px;
  height: 24px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;
