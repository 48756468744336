import Button from "@/src/components/atom/Button/BasicButton";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { MediaDto } from "@/src/store/apis/media/interface";
import { WarehousingDto } from "@/src/store/apis/sales/interface";
import colorSet from "@/src/styles/color";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface EditSalesDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (params: {
    warehousedAt?: string;
    warehousingMediaId?: number;
  }) => void;
  isLoading: boolean;
  selectWarehouse: WarehousingDto | null;
}

function EditSalesDialog({
  open,
  onOpenChange,
  onSave,
  isLoading = false,
  selectWarehouse,
}: EditSalesDialogProps) {
  const { t } = useTranslation();

  const { control, watch, setValue, getValues } = useForm<{
    warehousedAt?: Dayjs;
    warehousingMedia: MediaDto[];
  }>({
    mode: "onBlur",
    defaultValues: {
      warehousedAt: "",
      warehousingMedia: [],
    },
  });

  const handleSaveClick = () => {
    onOpenChange(false);
    onSave({
      warehousedAt:
        getValues("warehousedAt")?.endOf("day").toISOString() || undefined,
      warehousingMediaId: getValues("warehousingMedia")[0]
        ? getValues("warehousingMedia")[0].id
        : undefined,
    });
  };

  useEffect(() => {
    if (selectWarehouse) {
      selectWarehouse?.warehousedAt &&
        setValue("warehousedAt", dayjs(selectWarehouse?.warehousedAt));
      selectWarehouse?.warehousingMedia &&
        setValue("warehousingMedia", [selectWarehouse.warehousingMedia]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t("common:editReceiving")}
      open={open}
      onOpenChange={onOpenChange}
      width={496}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(false)}
          >
            <Typo typoType="btn3m">{t("common:cancel")}</Typo>
          </Button>

          <Button
            onClick={handleSaveClick}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <Typo color="white" typoType="btn3m">
              {t("common:save")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <Flex flexDirection="column" gap={16}>
        <StyledForm>
          <FormItem
            label={t("common:receivingDate")}
            type="date"
            name="warehousedAt"
            control={control}
            direction="vertical"
            inputProps={{
              getPopupContainer: (triggerNode: { parentElement: any }) => {
                return triggerNode.parentElement;
              },
            }}
            rules={{ required: true }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />

          <SectionCardRow
            label={t("common:receiptCertificate")}
            direction="vertical"
            value={
              <WarehousingMediaContainer>
                <FormItem
                  type="file"
                  name="warehousingMedia"
                  control={control}
                  direction="vertical"
                  inputProps={{
                    defaultFileList:
                      watch("warehousingMedia")?.length >= 1
                        ? [
                            new File(
                              [""],
                              watch("warehousingMedia")?.[0]
                                ?.originalFileName ?? ""
                            ),
                          ]
                        : undefined,
                    onRemove: () => setValue("warehousingMedia", []),
                    isPreview: true,
                  }}
                />
              </WarehousingMediaContainer>
            }
          />
        </StyledForm>
      </Flex>
    </Dialog>
  );
}

export default EditSalesDialog;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const WarehousingMediaContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};
`;
