import React, { CSSProperties, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import ContainerTaskResultCard from "./ContainerTaskResultCard";
import { useGetImporterTaskDetailQuery } from "@/src/store/apis/tasks/taskDetail";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

interface LoadingInProcessingTabProps {
  id: number;
  refetchList?: () => void;
}

const LoadingInProcessingTab = ({
  id,
  refetchList,
}: LoadingInProcessingTabProps) => {
  const { t } = useTranslation();
  const isRefetchOnce = useRef<boolean>(false);

  const {
    loadingId,
    loadingNo,
    bookingNo,
    itemCode,
    noOfContainer,
    totalNetWeight,
    totalUnitQuantity,
    estimatedWeight,
  } = useGetImporterTaskDetailQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;

        const isNew = !(currentData?.isRead ?? true);

        if (isNew && !isRefetchOnce.current) {
          refetchList?.();
          isRefetchOnce.current = true;
        }

        return {
          loadingId: isStable ? currentData.id : undefined,
          loadingNo: isStable ? currentData.taskNo : undefined,
          bookingNo: isStable ? currentData.bookingNo : "-",
          itemCode: isStable ? currentData.exporterItem : "-",
          noOfContainer: isStable
            ? `${currentData.numberOfTaskContainer}x${currentData.containerType}`
            : "-",
          totalNetWeight: isStable ? `${currentData.totalNetWeight}` : "-",
          totalUnitQuantity: isStable
            ? `${currentData.totalUnitQuantity}`
            : "-",

          estimatedWeight: isStable
            ? `${
                currentData.estimatedWeight
                  ? Number(currentData.estimatedWeight).toLocaleString()
                  : "-"
              } ${currentData.estimatedWeightUnit}`
            : "-",
        };
      },
    }
  );

  useEffect(() => {
    isRefetchOnce.current = false;
  }, [id]);

  return (
    <Article>
      <FlexDiv fullWidth flexDirection="column">
        <Typo as="h3" typoType="h4" className="header">
          {t("common:loadingNo")} {loadingNo}
        </Typo>
        <SectionCardGrid>
          {[
            {
              gridColumn: 4,
              title: t("common:bookingNo"),
              value: <BreakWord typoType="h4">{bookingNo}</BreakWord>,
            },
            {
              gridColumn: 4,
              title: t("contract:importer.detail.label.itemCode"),
              value: <BreakWord typoType="h4">{itemCode}</BreakWord>,
            },
            {
              gridColumn: 4,
              title: t("common:noOfContainer"),
              value: <BreakWord typoType="h4">{noOfContainer}</BreakWord>,
            },
            {
              isDivider: true,
            },
            {
              gridColumn: 4,
              title: t("common:totalNetWeight"),
              value: <BreakWord typoType="h4">{totalNetWeight}</BreakWord>,
            },

            {
              gridColumn: 4,
              title: t("common:totalUnitQty"),
              value: <BreakWord typoType="h4">{totalUnitQuantity}</BreakWord>,
            },
            {
              gridColumn: 4,
              title: t("common:estimatedWeight"),
              value: <BreakWord typoType="h4">{estimatedWeight}</BreakWord>,
            },
          ].map(({ gridColumn, isDivider = false, title, value }) => {
            if (isDivider) {
              return (
                <StyledSectionCardRow
                  key={title}
                  data-columns={12}
                  data-divider
                />
              );
            }
            return (
              <StyledSectionCardRow
                key={title}
                data-columns={gridColumn}
                label={
                  <Typo color="gray6" typoType="b9m">
                    {title}
                  </Typo>
                }
                direction="vertical"
                value={value}
              />
            );
          })}
        </SectionCardGrid>
      </FlexDiv>

      <ContainerTaskResultCard bookingNo={bookingNo} loadingId={loadingId} />
    </Article>
  );
};

export default LoadingInProcessingTab;

const Article = styled.article`
  width: calc(100% - 236px);
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    padding: 16px;
  }
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  &[data-columns="3"] {
    grid-column: 3 span;
  }

  &[data-columns="4"] {
    grid-column: 4 span;
  }

  &[data-columns="6"] {
    grid-column: 6 span;
  }

  &[data-columns="12"] {
    grid-column: 12 span;
  }

  &[data-divider="true"] {
    border-top: 1px solid ${colorSet.gray9};
  }
`;

const BreakWord = styled(Typo)`
  word-break: break-word;
`;

const FlexDiv = styled.div<{
  gap?: number;
  fullWidth?: boolean;
  flexDirection?: CSSProperties["flexDirection"];
}>`
  display: flex;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `};
`;
