import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import colorSet from "@/src/styles/color";
import BasicCard, {
  BasicCardTitleContainer,
} from "@/src/components/atom/Cards/BasicCard";
import IconInput from "@/src/components/atom/Input/IconInput";
import { Button } from "@/src/components/atom/Button";
import { emailRegex, passwordRegex } from "@/src/utils/regex";
import Icon from "@/src/components/atom/Icon";
import EyeOffSvg from "@/src/assets/icons/icon-eye-off.svg";
import EyeSvg from "@/src/assets/icons/icon-eye.svg";
import Input from "@/src/components/atom/Input";
import { SUBSCRIPTION_PATH_OPTION_LIST } from "@/src/constant/optionList";
import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle.svg";
import {
  useStaffSignUpButtonStatusContext,
  useStaffSignUpResourceContext,
  useStaffSignUpStepContext,
} from "./hooks";
import MultipleSelect from "@/src/components/atom/Select/MultipleSelect";
import Checkbox from "@/src/components/atom/Checkbox";
import Typo from "@/src/components/atom/Typo";
import styled from "styled-components";
import {
  useCheckSendEmailCodeMutation,
  useLazyCheckIdQuery,
  useSendTokenEmailMutation,
  useStaffSignUpMutation,
} from "@/src/store/apis/auth";
import useAlert from "@/src/hooks/useAlert";
import {
  useGetCommonCodeViaCodeNameQuery,
  useGetTermsQuery,
} from "@/src/store/apis/common";
import TextButton from "@/src/components/atom/Button/TextButton";
import Loader from "@/src/components/atom/Loader";
import Dialog from "@/src/components/atom/Dialog";
import typo from "@/src/styles/typography";
import Select from "@/src/components/atom/Select";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Label from "@/src/components/atom/Label";
import { InputError, InputSuccess } from "@/src/components/molecule/FormItem";
import useTimer from "@/src/hooks/useTimer";
import { COMPANY_TYPE_OPTION_LIST } from "../CorporateSignup/CorporateSignUpStepOne";
import { ClientType } from "@/src/store/apis/auth/interface";
import GridCardRadio from "@/src/components/molecule/GridCardRadio";
import TextArea from "@/src/components/atom/TextArea";
import SearchSelect from "@/src/components/atom/Select/SearchSelect";
import { isUndefined } from "@/src/utils/is";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { useTranslation } from "react-i18next";

const SEPARATOR = "&";
const SELECT_INLINE_STYLE = { width: "128px", flexShrink: 0 };

const prefixValueToString = (prefixValue?: PartialCommonCodeItemDto) => {
  return prefixValue
    ? [
        prefixValue.codeItemName,
        prefixValue.codeItemNameEn,
        prefixValue.codeItemNameKo,
        prefixValue.value1,
      ].join(SEPARATOR)
    : undefined;
};

const StaffSignUpStepTwo = () => {
  const { t, i18n } = useTranslation();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [rePasswordHidden, setRePasswordHidden] = useState(true);
  const [errorRequiredTermId, setErrorRequiredTermId] = useState<number[]>([]);
  const {
    companyInfo,
    name,
    aId,
    password,
    category,
    isMarketingEmailReceive,
    requiredTermsIdList,
    optionalTermsIdList,
    personalContactPrefix,
    personalContact,
    officeContactPrefix,
    officeContact,
    companyType,
    onResourceChange: handleResourceChange,
    authTokenId,
    verifyString,
    joinPath,
    joinPathDescription,
  } = useStaffSignUpResourceContext();
  const buttonStatus = useStaffSignUpButtonStatusContext();
  const { min, second, restart, reset, pauseOn } = useTimer({
    start: buttonStatus?.isCodeSendClick,
  });
  const [staffSignUp, staffSignUpData] = useStaffSignUpMutation();
  const [sendEmail, sendEmailResult] = useSendTokenEmailMutation();
  const [checkSendEmail] = useCheckSendEmailCodeMutation();
  const { onStepPrev, onStepNext } = useStaffSignUpStepContext();
  const [checkValidId] = useLazyCheckIdQuery();
  const { data: termsResponse = [], isFetching } = useGetTermsQuery({
    service: "EXPORT_WEB",
  });
  const { isCountryCodeFetching, countryCodeOptionList, countryCodeList } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "COUNTRY",
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => ({
          countryCodeOptionList: !isError
            ? (currentData ?? []).reduce<{ label: string; value: string }[]>(
                (acc, val) => {
                  return [
                    ...acc,
                    {
                      label: `${val.value1 ?? ""} (${val.codeItemName})`,
                      value: [
                        val.codeItemName,
                        val.codeItemNameEn,
                        val.codeItemNameKo,
                        val.value1,
                      ].join(SEPARATOR),
                    },
                  ];
                },
                []
              )
            : [],
          isCountryCodeFetching: isFetching,
          countryCodeList: currentData ?? [],
        }),

        refetchOnMountOrArgChange: true,
      }
    );

  const personalPrefixValue = countryCodeList.find(
    ({ codeItemName }) => codeItemName === companyInfo?.countryCodeItemName
  );
  const officePrefixValue = countryCodeList.find(
    ({ codeItemName }) => codeItemName === companyInfo?.countryCodeItemName
  );

  const {
    data: mainCategoryCodeList,
    isFetching: isMainCategoryCodeListFetching,
    isError: isMainCategoryCodeListError,
  } = useGetCommonCodeViaCodeNameQuery(
    { codeName: "MAIN_CATEGORY" },
    { refetchOnMountOrArgChange: true }
  );

  const alert = useAlert();
  const {
    control,
    handleSubmit,
    formState: { errors, defaultValues },
    watch,
    setValue,
    clearErrors,
    setFocus,
    setError,
    getValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      companyType,
      name,
      aId,
      password: password,
      rePassword: "",
      category,
      isMarketingEmailReceive,
      personalContactPrefix: personalPrefixValue
        ? [
            personalPrefixValue.codeItemName,
            personalPrefixValue.codeItemNameEn,
            personalPrefixValue.codeItemNameKo,
            personalPrefixValue.value1,
          ].join(SEPARATOR)
        : undefined,
      personalContact,
      officeContactPrefix: officePrefixValue
        ? [
            officePrefixValue.codeItemName,
            officePrefixValue.codeItemNameEn,
            officePrefixValue.codeItemNameKo,
            officePrefixValue.value1,
          ].join(SEPARATOR)
        : undefined,
      officeContact,
      verifyString,
      joinPath,
      joinPathDescription,
    },
  });

  const formFieldNameType = getValues();
  const isTimeOver = Number(min) === 0 && Number(second) === 0;

  const [viewingTerm, setViewingTerm] = useState<{
    titleEn: string;
    bodyEn: string;
    titleKo: string;
    bodyKo: string;
  } | null>(null);

  const mainCategoryCodeListToSelectOption = mainCategoryCodeList?.reduce<
    { label: string; value: string }[]
  >((acc, val) => {
    if (!companyInfo?.mainCategoryCodeItemNames.includes(val.codeItemName)) {
      return acc;
    }

    return [
      ...acc,
      {
        label: i18n.language === "en" ? val.codeItemNameEn : val.codeItemNameKo,
        value: [val.codeItemName, val.codeItemNameEn, val.codeItemNameKo].join(
          SEPARATOR
        ),
      },
    ];
  }, []);

  const categoryValue = watch("category").map((codeName) => {
    const categoryItem = mainCategoryCodeList?.find(
      ({ codeItemName }) => codeName === codeItemName
    );
    return [
      categoryItem?.codeItemName,
      categoryItem?.codeItemNameEn,
      categoryItem?.codeItemNameKo,
    ].join(SEPARATOR);
  });

  const requiredTermIdList = termsResponse
    .filter((term) => !term.isOptional)
    .map(({ currentTermsHistory }) => currentTermsHistory.id);

  const isRequiredTermAgreeAll = requiredTermIdList.every((requiredTermId) =>
    requiredTermsIdList.includes(requiredTermId)
  );

  const termsHistoryLength = termsResponse
    .filter((item) => !item.isOptional)
    .map((item) => item.currentTermsHistory.id).length;

  const isAgreeAll =
    termsResponse
      .map(({ currentTermsHistory }) => currentTermsHistory.id)
      .every((id) =>
        [...requiredTermsIdList, ...optionalTermsIdList].includes(id)
      ) && isMarketingEmailReceive;

  const isCreateButtonDisabled = !(
    !!watch("name") &&
    !!watch("aId") &&
    !!watch("password") &&
    !!watch("rePassword") &&
    !!watch("verifyString") &&
    watch("category").length >= 1 &&
    buttonStatus?.isCodeConfirm &&
    requiredTermsIdList.length === termsHistoryLength
  );

  const handleAgreeAll = () => {
    setErrorRequiredTermId([]);
    handleResourceChange("isMarketingEmailReceive", true);
    handleResourceChange(
      "requiredTermsIdList",
      termsResponse
        .filter((term) => !term.isOptional)
        .map((requiredTerm) => requiredTerm.currentTermsHistory.id)
    );
    handleResourceChange(
      "optionalTermsIdList",
      termsResponse
        .filter((term) => term.isOptional)
        .map((optionalTerm) => optionalTerm.currentTermsHistory.id)
    );
  };

  const handleResetAgree = () => {
    handleResourceChange("isMarketingEmailReceive", false);
    handleResourceChange("requiredTermsIdList", []);
    handleResourceChange("optionalTermsIdList", []);
  };

  const handleTermClick = (id: number, isOptional: boolean) => {
    setErrorRequiredTermId([]);
    if (!isOptional) {
      const isChecked = requiredTermsIdList.includes(id);
      return handleResourceChange(
        "requiredTermsIdList",
        isChecked
          ? requiredTermsIdList.filter((termId) => termId !== id)
          : [...requiredTermsIdList, id]
      );
    }

    const isChecked = optionalTermsIdList.includes(id);
    return handleResourceChange(
      "optionalTermsIdList",
      isChecked
        ? optionalTermsIdList.filter((termId) => termId !== id)
        : [...optionalTermsIdList, id]
    );
  };

  const handleCreateAccount = async () => {
    if (!isRequiredTermAgreeAll) {
      return;
    }

    const body = {
      authTokenId,
      verifyString: getValues("verifyString"),
      name: getValues("name"),
      aId: getValues("aId"),
      isMarketingEmailReceive,
      password: getValues("password"),
      exporterId: companyInfo?.id as number,
      mainCategoryCodeItemNames: getValues("category"),
      optionalTermsHistoryIdList: [],
      personalContactPrefix:
        getValues("personalContactPrefix")?.split(SEPARATOR)[3] || undefined,
      personalContact: getValues("personalContact") || undefined,
      officeContactPrefix:
        getValues("officeContactPrefix")?.split(SEPARATOR)[3] || undefined,
      officeContact: getValues("officeContact") || undefined,
      exporterUserMainFieldType: getValues("companyType") as ClientType,
      joinPath: getValues("joinPath") || undefined,
      joinPathDescription: getValues("joinPathDescription") || undefined,
    };

    try {
      staffSignUp(body).unwrap();
      onStepNext();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const handlePrev = () => {
    handleResourceChange("name", watch("name"));
    handleResourceChange("aId", watch("aId"));
    handleResourceChange("password", watch("password"));
    handleResourceChange("category", watch("category"));
    handleResourceChange("verifyString", watch("verifyString"));
    handleResourceChange("personalContact", watch("personalContact"));
    handleResourceChange(
      "personalContactPrefix",
      watch("personalContactPrefix")
    );
    handleResourceChange("officeContact", watch("officeContact"));
    handleResourceChange("officeContactPrefix", watch("officeContactPrefix"));

    handleResourceChange("isMarketingEmailReceive", isMarketingEmailReceive);
    handleResourceChange("joinPath", watch("joinPath"));
    handleResourceChange("joinPathDescription", watch("joinPathDescription"));
    onStepPrev();
  };

  const handleResendEmailClick = async () => {
    try {
      const { authTokenId } = await sendEmail({
        contact: getValues("aId"),
      }).unwrap();
      handleResourceChange("authTokenId", authTokenId);

      restart();
      setValue("verifyString", "");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const handleEmailChangeClick = () => {
    buttonStatus?.onButtonStatusChange(() => {
      return {
        isCodeConfirm: false,
        isEmailConfirm: false,
        isCodeSendClick: false,
      };
    });
    setValue("verifyString", "");
  };

  const handleEmailConfirmClick = async () => {
    try {
      const data = await checkValidId({ id: getValues("aId") });
      reset();
      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isEmailConfirm: !data.isError };
      });
      if (data.isError) {
        setError("aId", { type: "validate" });
      }
    } catch (e) {
      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isEmailConfirm: false };
      });
    }
  };

  const handleRequiredErrorsBlur = (
    type: keyof typeof formFieldNameType,
    value?: string
  ) => {
    if (value !== "") {
      clearErrors(type);
    } else {
      setError(type, { type: "required" });
    }
  };

  const handleCodeConfirmClick = async () => {
    const body = {
      verifyString: getValues("verifyString"),
      authTokenId,
    };

    try {
      await checkSendEmail(body).unwrap();
      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isCodeConfirm: true };
      });
      pauseOn();
    } catch (e) {
      setError("verifyString", { type: "validate" });

      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isCodeConfirm: false };
      });
    }
  };

  const handleSendEmailClick = async () => {
    try {
      const { authTokenId } = await sendEmail({
        contact: getValues("aId"),
      }).unwrap();
      handleResourceChange("authTokenId", authTokenId);

      setValue("verifyString", "");
      restart();
      buttonStatus?.onButtonStatusChange((prev) => {
        return {
          ...prev,
          isCodeSendClick: true,
        };
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  useEffect(() => {
    if (countryCodeList?.length > 0 && defaultValues) {
      if (
        isUndefined(defaultValues.officeContactPrefix) &&
        isUndefined(watch().officeContactPrefix)
      ) {
        const prefixValue = countryCodeList.find(
          ({ codeItemName }) =>
            codeItemName === companyInfo?.countryCodeItemName
        );

        if (prefixValue) {
          setValue("officeContactPrefix", prefixValueToString(prefixValue));
        }
      }

      if (
        isUndefined(defaultValues.personalContactPrefix) &&
        isUndefined(watch().personalContactPrefix)
      ) {
        const prefixValue = countryCodeList.find(
          ({ codeItemName }) =>
            codeItemName === companyInfo?.countryCodeItemName
        );

        if (prefixValue) {
          setValue("personalContactPrefix", prefixValueToString(prefixValue));
        }
      }
    }
  }, [
    watch,
    setValue,
    countryCodeList,
    companyInfo,
    officeContactPrefix,
    personalContactPrefix,
    defaultValues,
  ]);

  const renderDialog = () => {
    if (viewingTerm !== null) {
      return (
        <Dialog
          open
          onOpenChange={(openState) => {
            if (!openState) {
              setViewingTerm(null);
            }
          }}
          width={850}
          title={
            i18n.language === "en" ? viewingTerm.titleEn : viewingTerm.titleKo
          }
        >
          <div
            className="ck ck-content"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en"
                  ? viewingTerm?.bodyEn || "-"
                  : viewingTerm?.bodyKo || "-",
            }}
          />
        </Dialog>
      );
    }
  };

  const renderEmailConfirmButton = (disabled: boolean) => {
    if (buttonStatus?.isEmailConfirm) {
      return (
        <StyledButton
          onClick={handleEmailChangeClick}
          buttonColor="blue"
          buttonGrade="secondary"
        >
          {t("signupCorp:button.changeEmail")}
        </StyledButton>
      );
    }

    return (
      <StyledButton disabled={disabled} onClick={handleEmailConfirmClick}>
        {t("signupCorp:button.doubleCheck")}
      </StyledButton>
    );
  };

  const renderCodeSendButton = () => {
    const isCodeConfirmDisabled =
      buttonStatus?.isCodeConfirm || !!!watch("verifyString");

    if (buttonStatus?.isCodeSendClick) {
      return (
        <StyledButton
          disabled={isCodeConfirmDisabled || isTimeOver}
          onClick={handleCodeConfirmClick}
        >
          {t("signupCorp:button.verify")}
        </StyledButton>
      );
    }

    return (
      <StyledButton
        buttonGrade="secondary"
        disabled={
          !buttonStatus?.isEmailConfirm ||
          sendEmailResult.isLoading ||
          sendEmailResult.isError
        }
        isLoading={sendEmailResult.isLoading}
        onClick={handleSendEmailClick}
      >
        {t("signupCorp:button.certificationNo")}
      </StyledButton>
    );
  };

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  useEffect(() => {
    if (buttonStatus.isCodeConfirm) {
      pauseOn();
    }
  }, [buttonStatus.isCodeConfirm, pauseOn]);

  return (
    <>
      <StyledCard
        title={
          <BasicCardTitleContainer>
            {t("signupStaff:steps.secondStep")}
          </BasicCardTitleContainer>
        }
      >
        <ContentDiv>
          <p>
            <Typo typoType="h2" color="blue4">
              {companyInfo?.companyName ?? ""}
            </Typo>{" "}
            <Typo typoType="h2" color="gray2">
              {t("signupStaff:content.welcomeMessage")}
            </Typo>
          </p>
          <StyledForm onSubmit={handleSubmit(handleCreateAccount)}>
            <SectionCardRow
              direction="vertical"
              label={<Label required>{t("signupCorp:content.type")}</Label>}
              value={
                <Controller
                  name="companyType"
                  control={control}
                  render={({ field }) => {
                    return (
                      <StyledGridCardRadioContainer>
                        {COMPANY_TYPE_OPTION_LIST.map(
                          ({ label, value, gridSpan }) => {
                            const isChecked =
                              watch("companyType")?.includes(value);

                            if (companyType === "BOTH") {
                              return (
                                <GridCardRadio.Radio
                                  {...field}
                                  label={t(label)}
                                  value={value}
                                  gridSpan={gridSpan}
                                  checked={isChecked}
                                />
                              );
                            }

                            if (companyType === value) {
                              return (
                                <GridCardRadio.Radio
                                  {...field}
                                  label={label}
                                  value={value}
                                  gridSpan={gridSpan}
                                  checked={isChecked}
                                />
                              );
                            }

                            return "";
                          }
                        )}
                      </StyledGridCardRadioContainer>
                    );
                  }}
                />
              }
            />
            <FieldDiv>
              <label htmlFor="name">
                {t("signupCorp:content.name")} <strong>*</strong>
              </label>

              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    data-invalid={!!errors.name}
                    id={"name"}
                    placeholder={t("signupCorp:content.namePlaceholder")}
                    onClear={() => setValue("name", "")}
                  />
                )}
              />
              {errors.name?.type === "required" && (
                <FormErrorMessage>
                  <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:required")}
                </FormErrorMessage>
              )}
            </FieldDiv>

            <StyledSectionCardRow
              label={<Label required>{t("signupCorp:content.id")}</Label>}
              direction="vertical"
              value={
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: emailRegex,
                  }}
                  name="aId"
                  render={({ field }) => {
                    const isTestFailed = emailRegex.test(field.value as string);

                    return (
                      <FlexColumn>
                        <InputWithButtonContainer>
                          <Input
                            {...field}
                            placeholder={t("signupCorp:content.idPlaceholder")}
                            disabled={
                              buttonStatus?.isCodeConfirm ||
                              buttonStatus?.isEmailConfirm
                            }
                            onClear={() => {
                              setValue("aId", "");
                            }}
                            data-invalid={!!errors.aId}
                            onBlur={() => {
                              handleRequiredErrorsBlur(
                                "aId",
                                field.value as string
                              );
                            }}
                            onChange={(e) => {
                              setValue("aId", e.target.value);
                              clearErrors("aId");
                            }}
                          />
                          {renderEmailConfirmButton(!isTestFailed)}
                        </InputWithButtonContainer>

                        {errors.aId?.type === "required" && (
                          <InputError message={t("error:required")} />
                        )}
                        {errors.aId?.type === "pattern" && (
                          <InputError
                            message={t("error:login.emailTypeMissMatching")}
                          />
                        )}
                        {errors.aId?.type === "validate" && (
                          <InputError message={t("error:alreadyExistedId")} />
                        )}
                        {buttonStatus?.isEmailConfirm && (
                          <InputSuccess message={t("success:availableId")} />
                        )}
                      </FlexColumn>
                    );
                  }}
                />
              }
            />
            <StyledSectionCardRow
              label={
                <FormItemLabelContainer>
                  <Label required>{t("signupCorp:content.code")}</Label>
                  {buttonStatus?.isCodeSendClick && (
                    <Button
                      buttonSize={24}
                      buttonGrade="tertiary"
                      buttonColor="black"
                      disabled={
                        !buttonStatus?.isEmailConfirm ||
                        !buttonStatus?.isCodeSendClick ||
                        buttonStatus?.isCodeConfirm
                      }
                      onClick={handleResendEmailClick}
                    >
                      {t("signupCorp:button.reSend")}
                    </Button>
                  )}
                </FormItemLabelContainer>
              }
              direction="vertical"
              value={
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="verifyString"
                  render={({ field }) => {
                    return (
                      <FlexColumn>
                        <InputWithButtonContainer>
                          <Input
                            {...field}
                            onChange={(e) => {
                              setValue("verifyString", e.target.value);
                              clearErrors("verifyString");
                            }}
                            disabled={buttonStatus?.isCodeConfirm}
                            placeholder={t(
                              "signupCorp:content.codePlaceholder"
                            )}
                            onClear={() => setValue("verifyString", "")}
                            data-invalid={!!errors.verifyString}
                            onBlur={() => {
                              handleRequiredErrorsBlur(
                                "verifyString",
                                field.value
                              );
                            }}
                            suffix={
                              buttonStatus?.isCodeSendClick && (
                                <Typo
                                  typoType="b9m"
                                  color={
                                    buttonStatus?.isCodeConfirm ||
                                    !buttonStatus?.isCodeSendClick
                                      ? "gray7"
                                      : "red2"
                                  }
                                >
                                  {min}:{second} sec
                                </Typo>
                              )
                            }
                          />
                          {renderCodeSendButton()}
                        </InputWithButtonContainer>

                        {errors.verifyString?.type === "required" && (
                          <InputError message={t("error:required")} />
                        )}
                        {errors.verifyString?.type === "validate" && (
                          <InputError
                            message={t("error:sendCodeValidationError")}
                          />
                        )}
                        {buttonStatus?.isCodeConfirm && (
                          <InputSuccess message={t("success:codeConfirm")} />
                        )}
                      </FlexColumn>
                    );
                  }}
                />
              }
            />

            <FieldDiv>
              <label htmlFor="password">
                {t("signupCorp:content.password")} <strong>*</strong>
              </label>

              <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                  pattern: passwordRegex,
                }}
                render={({ field }) => {
                  return (
                    <IconInputContainer data-invalid={!!errors.password}>
                      <IconInput
                        {...field}
                        onClear={() => setValue("password", "")}
                        icon={
                          <Icon
                            onClick={() => setPasswordHidden((prev) => !prev)}
                            iconSrc={passwordHidden ? EyeOffSvg : EyeSvg}
                            iconSize={20}
                          />
                        }
                        type={passwordHidden ? "password" : "text"}
                        placeholder={t("changePassword:newPasswordPlaceholder")}
                      />
                    </IconInputContainer>
                  );
                }}
              />

              {errors.password?.type === "required" && (
                <FormErrorMessage>
                  <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:required")}
                </FormErrorMessage>
              )}
              {errors.password?.type === "pattern" && (
                <FormErrorMessage>
                  <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:passwordPatterError")}
                </FormErrorMessage>
              )}
            </FieldDiv>

            <FieldDiv>
              <label htmlFor="reEnterPassword">
                {t("signupCorp:content.reEnterPassword")} <strong>*</strong>
              </label>

              <Controller
                name="rePassword"
                control={control}
                rules={{
                  required: true,
                  validate: (value, formValues) =>
                    value === formValues.password,
                }}
                render={({ field }) => (
                  <IconInputContainer data-invalid={!!errors.rePassword}>
                    <IconInput
                      {...field}
                      onClear={() => setValue("rePassword", "")}
                      icon={
                        <Icon
                          iconSrc={rePasswordHidden ? EyeOffSvg : EyeSvg}
                          iconSize={20}
                          onClick={() => setRePasswordHidden((prev) => !prev)}
                        />
                      }
                      type={rePasswordHidden ? "password" : "text"}
                      placeholder={t("changePassword:rePasswordPlaceholder")}
                    />
                  </IconInputContainer>
                )}
              />
              {errors.rePassword?.type === "required" && (
                <FormErrorMessage>
                  <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:required")}
                </FormErrorMessage>
              )}
              {errors.rePassword?.type === "validate" && (
                <FormErrorMessage>
                  <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:passwordRecheckFail")}
                </FormErrorMessage>
              )}
            </FieldDiv>

            <FieldDiv>
              <LabelContainer>
                <label htmlFor="category">
                  {t("signupCorp:content.category")} <strong>*</strong>
                </label>

                <CheckboxLabel>
                  <Checkbox
                    id="all-select"
                    checked={
                      watch("category").length ===
                      mainCategoryCodeListToSelectOption?.length
                    }
                    onChange={() => {
                      watch("category").length ===
                      mainCategoryCodeListToSelectOption?.length
                        ? setValue("category", [])
                        : setValue(
                            "category",
                            mainCategoryCodeListToSelectOption?.map(
                              ({ value }) => value.split(SEPARATOR)[0]
                            ) ?? []
                          );
                      clearErrors("category");
                    }}
                  />
                  <AllSelectLabel htmlFor="all-select">
                    {t("signupCorp:content.allCategory")}
                  </AllSelectLabel>
                </CheckboxLabel>
              </LabelContainer>
              <Controller
                name="category"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <StyledCheckboxSelect
                    {...field}
                    data-invalid={!!errors.category}
                    placeholder={t(
                      "signupCorp:content.mainCategoryCodeItemNamesPlaceholder"
                    )}
                    suffixIcon={
                      isMainCategoryCodeListFetching ? <Loader /> : undefined
                    }
                    disabled={
                      isMainCategoryCodeListFetching ||
                      isMainCategoryCodeListError
                    }
                    value={categoryValue}
                    onChange={(selectedItems) => {
                      const realValueList = selectedItems.map(
                        (item: string) => item.split(SEPARATOR)[0]
                      );
                      setValue("category", realValueList);
                      clearErrors("category");
                    }}
                    onRemoveItem={(item) => {
                      const codeNameValue = item.split(SEPARATOR)[0];
                      setValue(
                        "category",
                        watch("category").filter(
                          (codeName) => codeName !== codeNameValue
                        )
                      );
                    }}
                    options={mainCategoryCodeListToSelectOption}
                  />
                )}
              />
              {errors.category?.type === "required" && (
                <FormErrorMessage>
                  <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:required")}
                </FormErrorMessage>
              )}
            </FieldDiv>

            <FieldDiv>
              <span>{t("signupStaff:content.personalContact")}</span>
              <ContactPrefixRowDiv>
                <Controller
                  name="personalContactPrefix"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        disabled={isCountryCodeFetching}
                        suffixIcon={
                          isCountryCodeFetching ? <Loader /> : undefined
                        }
                        showSearch
                        style={SELECT_INLINE_STYLE}
                        placeholder={t("placeholder:contactPrefix").replace(
                          "`",
                          ""
                        )}
                        options={countryCodeOptionList}
                        onChange={(value) => {
                          const [codeName] = (value as string).split(SEPARATOR);
                          const codeValue = countryCodeList.find(
                            ({ codeItemName }) => codeItemName === codeName
                          );
                          field.onChange(value);
                          handleResourceChange(
                            "personalContactPrefix",
                            codeValue?.value1 ?? ""
                          );
                        }}
                      />
                    );
                  }}
                />

                <Controller
                  name="personalContact"
                  control={control}
                  render={({ field }) => {
                    return (
                      <RestContactInput
                        {...field}
                        placeholder={t("signupCorp:content.contactPlaceholder")}
                        onBlur={(e) => {
                          field.onBlur();
                          const noZeroPrefix = e.target.value.replace(
                            /^0+/,
                            ""
                          );
                          setValue("personalContact", noZeroPrefix);
                          handleResourceChange("personalContact", noZeroPrefix);
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          const onlyNumber = value.replace(/[^0-9]/g, "");
                          field.onChange(onlyNumber);
                          handleResourceChange("personalContact", onlyNumber);
                        }}
                        onClear={() => {
                          setValue("personalContact", "");
                          handleResourceChange("personalContact", "");
                        }}
                      />
                    );
                  }}
                />
              </ContactPrefixRowDiv>
            </FieldDiv>

            <FieldDiv>
              <span>{t("signupStaff:content.officeContact")}</span>
              <ContactPrefixRowDiv>
                <Controller
                  name="officeContactPrefix"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        disabled={isCountryCodeFetching}
                        suffixIcon={
                          isCountryCodeFetching ? <Loader /> : undefined
                        }
                        showSearch
                        style={SELECT_INLINE_STYLE}
                        placeholder={t("placeholder:contactPrefix").replace(
                          "`",
                          ""
                        )}
                        options={countryCodeOptionList}
                        onChange={(value) => {
                          const [codeName] = (value as string).split(SEPARATOR);
                          const codeValue = countryCodeList.find(
                            ({ codeItemName }) => codeItemName === codeName
                          );
                          field.onChange(value);
                          handleResourceChange(
                            "officeContactPrefix",
                            codeValue?.value1 ?? ""
                          );
                        }}
                      />
                    );
                  }}
                />

                <Controller
                  name="officeContact"
                  control={control}
                  render={({ field }) => {
                    return (
                      <RestContactInput
                        {...field}
                        placeholder={t("signupCorp:content.contactPlaceholder")}
                        onBlur={(e) => {
                          field.onBlur();
                          const noZeroPrefix = e.target.value.replace(
                            /^0+/,
                            ""
                          );
                          setValue("officeContact", noZeroPrefix);
                          handleResourceChange("officeContact", noZeroPrefix);
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          const onlyNumber = value.replace(/[^0-9]/g, "");
                          field.onChange(onlyNumber);
                          handleResourceChange("officeContact", onlyNumber);
                        }}
                        onClear={() => {
                          setValue("officeContact", "");
                          handleResourceChange("officeContact", "");
                        }}
                      />
                    );
                  }}
                />
              </ContactPrefixRowDiv>
            </FieldDiv>

            <StyledSectionCardRow
              label={<Label>{t("signupCorp:content.subscriptionPath")}</Label>}
              direction="vertical"
              value={
                <FlexColumn>
                  <Controller
                    name="joinPath"
                    control={control}
                    render={({ field }) => (
                      <SearchSelect
                        {...field}
                        placeholder={t(
                          "signupCorp:content.subscriptionPathPlaceholder"
                        )}
                        options={SUBSCRIPTION_PATH_OPTION_LIST.map(
                          ({ langKey, value }) => {
                            return { label: t(langKey), value };
                          }
                        )}
                        allowClear={!!watch("joinPath")}
                      />
                    )}
                  />
                  {watch("joinPath") === "ETC" && (
                    <Controller
                      name="joinPathDescription"
                      control={control}
                      render={({ field }) => (
                        <StyledTextArea
                          {...field}
                          placeholder={t(
                            "common:joinPathDescriptionPlaceholder"
                          )}
                          maxLength={100}
                        />
                      )}
                    />
                  )}
                </FlexColumn>
              }
            />

            <FieldDiv>
              <StyledLabel>
                <Checkbox
                  checked={isAgreeAll}
                  onClick={() => {
                    if (isAgreeAll) {
                      handleResetAgree();
                    } else {
                      handleAgreeAll();
                    }
                  }}
                />
                <Typo typoType="b7r" color="gray1">
                  {t("signupCorp:content.agreeAll")}
                </Typo>
              </StyledLabel>

              <Divider />

              {isFetching ? (
                <LoadingContainer>
                  <Loader size={25} />
                </LoadingContainer>
              ) : (
                termsResponse.map(
                  ({ isOptional, titleKo, titleEn, currentTermsHistory }) => {
                    const isChecked = isOptional
                      ? optionalTermsIdList.includes(currentTermsHistory.id)
                      : requiredTermsIdList.includes(currentTermsHistory.id);
                    const isError =
                      !isOptional &&
                      errorRequiredTermId.includes(currentTermsHistory.id);

                    return (
                      <>
                        <TermRowContainer key={currentTermsHistory.id}>
                          <StyledLabel>
                            <Checkbox
                              checked={isChecked}
                              onClick={() => {
                                handleTermClick(
                                  currentTermsHistory.id,
                                  isOptional
                                );
                              }}
                            />
                            <Typo typoType="b7r" color="gray1">
                              {i18n.language === "en" ? titleEn : titleKo}{" "}
                              {isOptional ? (
                                <Typo typoType="b9r" color="gray6">
                                  ({t("common:optional")})
                                </Typo>
                              ) : (
                                <Typo typoType="b9r" color="blue4">
                                  ({t("common:required")})
                                </Typo>
                              )}
                            </Typo>
                          </StyledLabel>

                          <ViewTextButton
                            onClick={() => {
                              setViewingTerm({
                                bodyEn: currentTermsHistory.bodyEn,
                                bodyKo: currentTermsHistory.bodyKo,
                                titleEn,
                                titleKo,
                              });
                            }}
                          >
                            {t("common:view")}
                          </ViewTextButton>
                        </TermRowContainer>

                        {isError && (
                          <CheckboxFormErrorMessage>
                            <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                            {t("error:required")}
                          </CheckboxFormErrorMessage>
                        )}
                      </>
                    );
                  }
                )
              )}

              <ServiceTermRow>
                <Checkbox
                  checked={isMarketingEmailReceive}
                  onClick={() => {
                    handleResourceChange(
                      "isMarketingEmailReceive",
                      !isMarketingEmailReceive
                    );
                  }}
                />
                <Typo
                  typoType="b7r"
                  color="gray1"
                  className="receive-email-term"
                >
                  {t("signupCorp:content.receiveEmailTerm")}
                  <Typo typoType="b9r" color="gray6">
                    ({t("common:optional")})
                  </Typo>
                </Typo>
              </ServiceTermRow>
            </FieldDiv>

            <ButtonContainer>
              <StyledButton
                buttonGrade="tertiary"
                buttonColor="black"
                buttonSize={40}
                onClick={handlePrev}
              >
                <Typo typoType="btn3m" color="gray2">
                  {t("common:back")}
                </Typo>
              </StyledButton>
              <StyledButton
                type="submit"
                disabled={isCreateButtonDisabled || staffSignUpData.isLoading}
                isLoading={staffSignUpData.isLoading}
              >
                <Typo typoType="btn3m" color="white">
                  {t("signupStaff:content.createStaffAccount")}
                </Typo>
              </StyledButton>
            </ButtonContainer>
          </StyledForm>
        </ContentDiv>
      </StyledCard>
      {renderDialog()}
    </>
  );
};

export default StaffSignUpStepTwo;

const StyledCard = styled(BasicCard)`
  width: 800px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 160px;
  gap: 16px;
`;

const FieldDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  strong {
    color: ${colorSet.red2};
  }
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  flex: 1;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;
`;

const FormErrorMessage = styled.span`
  ${typo.b9r}
  color: ${colorSet.red2};
  padding-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const IconInputContainer = styled.div`
  &[data-invalid="true"] {
    div.icon-input-container {
      border: 1px solid ${colorSet.red2};
    }
  }
`;

const StyledCheckboxSelect = styled(MultipleSelect)`
  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
`;

const CheckboxFormErrorMessage = styled(FormErrorMessage)`
  padding-left: 28px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
`;

const ViewTextButton = styled(TextButton)`
  color: ${colorSet.gray6};
  text-decoration-color: ${colorSet.gray6};
  ${typo.b9r}
`;

const TermRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ServiceTermRow = styled(StyledLabel)`
  align-items: flex-start;

  .receive-email-term {
    word-break: keep-all;
    white-space: pre-wrap;
  }

  > input {
    flex-shrink: 0;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ContactPrefixRowDiv = styled.div`
  display: flex;
  gap: 8px;
`;

const RestContactInput = styled(Input)`
  flex: 1;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  p:nth-of-type(1) {
    max-width: 100%;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputWithButtonContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr minmax(auto, 114px);
`;

const FormItemLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledGridCardRadioContainer = styled(GridCardRadio.Container)`
  grid-template-rows: minmax(60px, 60px);
  padding-bottom: 32px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledTextArea = styled(TextArea)`
  height: 137px;
`;

const AllSelectLabel = styled.label`
  cursor: pointer;
  color: ${colorSet.gray6};
  ${typo.b9r}
`;
