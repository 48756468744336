import SectionCard from "@/src/components/molecule/SectionCard";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import { StyledScroll } from "@/src/styles/scroll";
import Typo from "@/src/components/atom/Typo";
import typo from "@/src/styles/typography";
import Icon from "@/src/components/atom/Icon";
import dayjs from "dayjs";
import { IconButton } from "@/src/components/atom/Button";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import styled from "styled-components";
import colorSet from "@/src/styles/color";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import OpenSvg from "@/src/assets/icons/icon-open.svg";
import { BookingContractShareInfoV2Dto } from "@/src/store/apis/bookings/bookingShare/interface";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import { useTranslation } from "react-i18next";

interface BookingItemProps {
  bookingData?: BookingContractShareInfoV2Dto["bookingDetailShareInfo"];
  isError?: boolean;
}

function BookingItem({ bookingData, isError }: BookingItemProps) {
  const { t } = useTranslation();

  const handleShippingLineClick = () => {
    const url = bookingData?.shippingLineUrl;

    window.open(url, "_blank");
  };

  const renderEtd = () => {
    if (!bookingData?.etdAt && !bookingData?.initialEtdAt) {
      return "-";
    }

    return (
      <EtdTextContainer>
        <Typo typoType="h4">
          {dayjs.utc(bookingData.etdAt).format("YYYY-MM-DD")}
        </Typo>
        <EtdCompareCount
          initDate={bookingData?.initialEtdAt || ""}
          currentDate={bookingData?.etdAt || ""}
        />
      </EtdTextContainer>
    );
  };

  const renderBookingNoneTitleCard = () => {
    return (
      <StyledSectionCardGrid>
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:etd")}
            </Typo>
          }
          direction="vertical"
          value={renderEtd()}
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:eta")}
            </Typo>
          }
          direction="vertical"
          value={
            bookingData?.etaAt ? (
              <BreakWordTypo typoType="h4">
                {dayjs.utc(bookingData.etaAt).format("YYYY-MM-DD")}
              </BreakWordTypo>
            ) : (
              "-"
            )
          }
        />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:shippingLine")}
            </Typo>
          }
          direction="vertical"
          value={
            bookingData?.shippingLine ? (
              <ShippingLine>
                <BlueText
                  color="systemBlue2"
                  typoType="h4"
                  onClick={handleShippingLineClick}
                >
                  {bookingData.shippingLine}
                </BlueText>
                <IconButton
                  buttonSize={24}
                  buttonColor="black"
                  buttonGrade="tertiary"
                  onClick={handleShippingLineClick}
                >
                  <Icon iconSrc={OpenSvg} iconSize={16} />
                </IconButton>
              </ShippingLine>
            ) : (
              "-"
            )
          }
        />

        <GrayLine />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:vesselAndVoyageNo")}
            </Typo>
          }
          direction="vertical"
          value={
            bookingData?.vessel ? (
              <BreakWordTypo typoType="h4">
                {bookingData.vessel} {bookingData.voyageNo}
              </BreakWordTypo>
            ) : (
              "-"
            )
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:portOfLoading")}
            </Typo>
          }
          direction="vertical"
          value={
            bookingData?.portOfLoading ? (
              <Typo typoType="h4">{bookingData.portOfLoading}</Typo>
            ) : (
              "-"
            )
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:placeOfDelivery")}
            </Typo>
          }
          direction="vertical"
          value={
            bookingData?.placeOfDelivery ? (
              <BreakWordTypo typoType="h4">
                {bookingData.placeOfDelivery}
              </BreakWordTypo>
            ) : (
              "-"
            )
          }
        />
      </StyledSectionCardGrid>
    );
  };

  const renderBookingInformationCard = () => {
    return (
      <SectionCard cardTitle={t("booking:detail.card.bookingInformation")}>
        <InformationRow>
          <Typo as="p" typoType="b9m" color="gray7">
            {t("common:location")}
          </Typo>
          <section>
            <SectionCardRow
              label={t("common:placeOfReceipt")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5" as="p">
                  {bookingData?.placeOfReceipt ?? "-"}
                </BreakWordTypo>
              }
            />
            <SectionCardRow
              label={t("common:portOfDischarge")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5" as="p">
                  {bookingData?.portOfDischarge ?? "-"}
                </BreakWordTypo>
              }
            />
          </section>
        </InformationRow>
      </SectionCard>
    );
  };

  const renderBookingRemardCard = () => {
    return (
      <SectionCard cardTitle={t("common:bookingRemark")}>
        <BookingRemarkReadOnlyText typoType="b7r">
          {bookingData?.bookingRemark ? bookingData?.bookingRemark : "-"}
        </BookingRemarkReadOnlyText>
      </SectionCard>
    );
  };

  const renderBookingContent = () => {
    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <>
        <PageTitle as="h1" color="gray1">
          {t("common:bookingNo")} {bookingData?.bookingNo ?? "-"}
        </PageTitle>

        <GridContainer>
          {/* ContractNoneTitleCard */}
          {renderBookingNoneTitleCard()}
          {/* Booking Information */}
          {renderBookingInformationCard()}
          {/* Booking Remark */}
          {renderBookingRemardCard()}
        </GridContainer>
      </>
    );
  };

  return renderBookingContent();
}

export default BookingItem;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 4 span;
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const EtdTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ShippingLine = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const StyledSectionCardGrid = styled(SectionCardGrid)`
  grid-column: 2 span;
`;

const BookingRemarkReadOnlyText = styled(ReadOnlyText)`
  height: 156px;
  overflow: auto;
  ${StyledScroll}
`;

const InformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
  word-break: break-all;
`;
