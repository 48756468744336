import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ColDef, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import Table from "@/src/components/atom/Table";
import useAlert from "@/src/hooks/useAlert";
import { useLazyGetContractRecombinationListQuery } from "@/src/store/apis/tasks/taskDetail";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import { combineSplitContractTableColumn } from "../../../../columns/columnTaskList";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";

interface ContractContentProps {
  selectedBookingId: number;
  selectedContractId: number | null;
  buyerNameCode?: string;
  loadingId: number;
  page: number;
  onPageChange: (page: number) => void;
  onContractChange?: (id: number | null) => void;
  onTabChange?: (id: number) => void;
  onReset: () => void;
}

const emptyArray: any[] = [];

const ContractContent = ({
  loadingId,
  selectedBookingId,
  selectedContractId,
  page,
  buyerNameCode,
  onPageChange,
  onContractChange,
  onTabChange,
  onReset,
}: ContractContentProps) => {
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);
  const [isReady, setIsReady] = useState(false);
  const [bookingColumn] = useState<ColDef[]>([
    {
      headerName: "",
      field: "radioButton",
      width: 60,
      cellRenderer: (params: IRowNode) => <>{radioRenderer(params)}</>,
      lockPosition: "left",
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...combineSplitContractTableColumn,
  ]);

  const [
    getContractList,
    { contractList, contractCount, isContractListFetching },
  ] = useLazyGetContractRecombinationListQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      return {
        contractCount: !isUnstable ? currentData.count : 0,
        contractList: !isUnstable ? currentData.rows : emptyArray,
        isContractListFetching: isFetching,
      };
    },
  });

  const radioRenderer = (params: IRowNode) => {
    const handleRadioChange = () => {
      onContractChange?.(params.data.id);
    };

    const checked = params.data.isChecked;

    return (
      <StyledRadio>
        <input
          type="radio"
          className="custom-ag-radio"
          checked={checked}
          onChange={handleRadioChange}
        />
        <span className="custom-radio-mark" />
      </StyledRadio>
    );
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "orderDateAt",
        langKey: "table:orderDateAt",
      },
      {
        columnKey: "scNo",
        langKey: "table:scNo",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "qty",
        langKey: "table:qty",
      },
      {
        columnKey: "unitprice",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "shippingTerms",
        langKey: "table:shippingTerms",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "origin",
        langKey: "table:originLocation",
      },
      {
        columnKey: "lastShipmentDateAt",
        langKey: "table:lastShipmentDate",
      },
    ],
  });

  useEffect(() => {
    (async () => {
      try {
        await getContractList({
          taskId: loadingId,
          buyerNameCode,
          bookingId: selectedBookingId,
          page: 1,
        }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerNameCode, getContractList, selectedBookingId, loadingId]);

  useEffect(() => {
    if (isContractListFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isContractListFetching]);

  return (
    <AccordionContentWrapper>
      <TableWrapper>
        <Table
          ref={gridRef}
          onGridReady={() => setIsReady(true)}
          columnDefs={bookingColumn}
          rowData={
            isContractListFetching
              ? undefined
              : contractList.map((list) => ({
                  ...list,
                  isChecked: selectedContractId === list.id,
                }))
          }
          totalPage={contractCount}
          page={page}
          height={372}
          pageSize={10}
          handlePaginationClick={async (page) => {
            onPageChange?.(page);
            await getContractList({
              taskId: loadingId,
              buyerNameCode,
              bookingId: selectedBookingId,
              page,
            });
          }}
          onRowClicked={(e) => {
            const rowData = e.data;

            onContractChange?.(rowData.id);
            if (selectedContractId !== rowData.id) {
              onReset();
            }
            onTabChange?.(rowData.id);
          }}
        />
      </TableWrapper>
    </AccordionContentWrapper>
  );
};

export default ContractContent;

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
`;

const AccordionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableWrapper = styled.div`
  background: ${colorSet.white};
  padding: 8px 8px 0;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;

  .ant-pagination-options {
    display: none;
  }
`;
