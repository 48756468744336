import AgGridRadio from "@/src/components/atom/Radio/AgGridRadio";
import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { SalesDto } from "@/src/store/apis/sales/interface";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
};

export const columnLoadSalesContract = (
  radioOnChange: (id: number | null) => void
): ColDef[] => [
  {
    headerName: "",
    field: "radioButton",
    width: 60,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <AgGridRadio
          checked={params.data.isChecked}
          onChange={() => radioOnChange(params.data.id)}
        />
      );
    },
    lockPosition: "left",
    pinned: "left",
    sortable: false,
    resizable: false,
  },
  {
    field: "orderDateAt",
    width: 120,
    minWidth: 120,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.orderDateAt
            ? dayjs(params.data.orderDateAt).format(
                DATE_FORMAT_STRINGS.YYYY_MM_DD
              )
            : "-"}
        </Typo>
      );
    },
  },
  {
    field: "salesNumber",
    width: 140,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.salesNumber ? params.data.salesNumber : "-"}
        </Typo>
      );
    },
  },
  {
    field: "exporterItemCode",
    width: 160,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.exporterItemCode?.itemCode
            ? params.data.exporterItemCode?.itemCode
            : "-"}
        </Typo>
      );
    },
  },
  {
    field: "salesFinalProducts",
    width: 110,
    flex: 1,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      const finalProducts = (
        params.data.salesFinalProducts as SalesDto["salesFinalProducts"]
      )
        .map((item) => item.finalProduct.name)
        .join(",");

      return (
        <Typo typoType="b9r">
          {params.data.salesFinalProducts ? finalProducts : "-"}
        </Typo>
      );
    },
  },
  {
    field: "warehouse",
    width: 110,
    flex: 1,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data?.warehouse?.warehouseName
            ? params.data?.warehouse?.warehouseName
            : "-"}
        </Typo>
      );
    },
  },
  {
    field: "warehouseEmployee",
    width: 130,
    flex: 1,
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data?.warehouseEmployee.name
            ? params.data?.warehouseEmployee.name
            : "-"}
        </Typo>
      );
    },
  },
];
