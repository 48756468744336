import { useEffect, useRef, useState } from "react";
import { ColDef, RowClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import dayjs from "dayjs";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import Filter from "@/src/components/molecule/Filter";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppSelector } from "@/src/store";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import ExcelSvg from "@/src/assets/icons/icon-excel.svg";
import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle-fill.svg";
import ResetSvg from "@/src/assets/icons/icon-reset-circle.svg";
import { columnShipmentList } from "./columns/columnShipmentList";
import {
  GetShipmentListFilterRequest,
  GetShipmentListRequest,
  ShipmentListViewDto,
} from "@/src/store/apis/shipments/shipmentList/interface";
import {
  useLazyGetShipmentCategoryFilterListQuery,
  useLazyGetShipmentsExcelListQuery,
} from "@/src/store/apis/shipments/shipmentList";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import useAlert from "@/src/hooks/useAlert";
import useAgGirdSetColumn from "@/src/hooks/useAgGridSetColumn";
import { useBlnoToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import { ShipmentSelectTabState } from "./detail";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import { isUndefined } from "@/src/utils/is";
import { gridColIdLocalStorage } from "@/src/utils/localStorageFixedColId";
import colorSet from "@/src/styles/color";
import { useGetFactoriesQuery } from "@/src/store/apis/client/factory";
import { WorkplaceListViewDto } from "@/src/store/apis/client/factory/interface";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";

const Init: GetShipmentListFilterRequest = {
  shipmentStatus: "all",
  mainCategoryCodeItemName: "all",
  itemCode: undefined,
  buyerNameCode: "all",
  blNo: undefined,
  etdAt: undefined,
  bookingNo: undefined,
  scNo: undefined,
  isCiOrPlRecheckNeeded: "all" as unknown as boolean,
};

const FAST_SEARCH_QUERY_KEY: string[] = [
  "shipmentStatus",
  "mainCategoryCodeItemName",
  "buyerNameCode",
  "etdAt",
  "isCiOrPlRecheckNeeded",
];

type TabType = "ALL" | "UPDATE";
const TAB_LIST: { label: TabType; value: string }[] = [
  { label: "ALL", value: "all" },
  { label: "UPDATE", value: "true" },
];

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];
const buyerEmptyObject: {
  data: BuyerListViewDto[];
  count: number;
} = { data: [], count: 0 };
const workplaceEmptyArray: WorkplaceListViewDto[] = [];

const ExporterShipmentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  const colTempRef = useRef<ColDef[]>(columnShipmentList);
  const user = useAppSelector((state) => state.auth.user);
  const subscription = useAppSelector((state) => state.subscription);

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    user?.exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  // API
  const [getExcel] = useLazyGetShipmentsExcelListQuery();
  const [blnoToSeaVantageUrl] = useBlnoToSeaVantageUrlMutation();
  const [
    getShipmentList,
    { isFetching, originalArgs, shipmentList, count, isError },
  ] = useLazyGetShipmentCategoryFilterListQuery({
    selectFromResult: ({ currentData, isError, isFetching, originalArgs }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      return {
        isFetching,
        originalArgs,
        shipmentList: !isUnstable ? currentData.rows : undefined,
        count: !isUnstable ? currentData.count : 0,
        isError,
      };
    },
  });
  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { mainCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isErrorAndUndefined = isError || currentData === undefined;

        return {
          mainCategoryCurrentData: !isErrorAndUndefined
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );
  const { buyerCurrentData } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isErrorAndUndefined = isError || currentData === undefined;

        return {
          buyerCurrentData: !isErrorAndUndefined
            ? currentData
            : buyerEmptyObject,
        };
      },
    }
  );

  const { factoriesCurrentData } = useGetFactoriesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          factoriesCurrentData: !isUnstable
            ? currentData.rows ?? workplaceEmptyArray
            : workplaceEmptyArray,
        };
      },
    }
  );

  const buyerOptionList = buyerCurrentData.data?.map((item) => {
    return { label: item.buyerNameCode, value: String(item.id) };
  });

  const factoryOptionList = factoriesCurrentData?.map((item) => {
    return { label: item.workplaceName, value: item.workplaceName };
  });

  const mainCategoryFilterList = mainCategoryCurrentData
    ?.filter((item) =>
      session.currentData?.row.exporter.mainCategoryCodeItemNames.includes(
        item.codeItemName
      )
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });
  const allOptionItem = {
    label: t("shipment:exporter.list.placeholder.all"),
    value: "all",
  };

  // State
  const [isReady, setIsReady] = useState<boolean>(false);
  const {
    func: { handleColumnMoved, handleColumnReset, setCol },
    state: { col },
  } = useAgGirdSetColumn({
    key: gridColIdLocalStorage.shipment,
    columns: columnShipmentList,
  });

  const renderTabItemTitle = (value: string) => {
    switch (value) {
      case "all":
        return `All`;

      case "true":
        return `Update`;
    }
  };

  const handleExcelDownload = async () => {
    try {
      if (originalArgs) {
        await getExcel(originalArgs).unwrap();
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const handleBlnoClick = async (selectCell: ShipmentListViewDto) => {
    try {
      const { cargoTrackUrl } = await blnoToSeaVantageUrl({
        blNo: selectCell.blNo,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "isBookmarked",
        langKey: "shipment:exporter.list.table.isBookmarked",
      },
      {
        columnKey: "shipmentStatus",
        langKey: "shipment:exporter.list.table.shipmentStatus",
      },
      {
        columnKey: "blNo",
        langKey: "shipment:exporter.list.table.blNo",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "shipment:exporter.list.table.buyerNameCode",
      },
      {
        columnKey: "bookingNo",
        langKey: "shipment:exporter.list.table.bookingNo",
      },
      {
        columnKey: "mainCategoryCodeItemName",
        langKey: "shipment:exporter.list.table.mainCategoryCodeItemName",
      },
      {
        columnKey: "itemCode",
        langKey: "shipment:exporter.list.table.itemCode",
      },
      {
        columnKey: "scNo",
        langKey: "shipment:exporter.list.table.scNo",
      },
      {
        columnKey: "etdAt",
        langKey: "shipment:exporter.list.table.etdAt",
      },
      {
        columnKey: "etaAt",
        langKey: "shipment:exporter.list.table.etaAt",
      },
      {
        columnKey: "docCutOffAt",
        langKey: "shipment:exporter.list.table.docCutOffAt",
      },
      {
        columnKey: "numberOfShipmentContainer",
        langKey: "shipment:exporter.list.table.numberOfShipmentContainer",
      },
      {
        columnKey: "totalNetWeight",
        langKey: "shipment:exporter.list.table.totalNetWeight",
      },
      {
        columnKey: "portOfLoading",
        langKey: "shipment:exporter.list.table.portOfLoading",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "shipment:exporter.list.table.placeOfDelivery",
      },
      {
        columnKey: "workplaceDescription",
        langKey: "shipment:exporter.list.table.workplaceDescription",
      },
      {
        columnKey: "earliestTaskDate",
        langKey: "shipment:exporter.list.table.earliestTaskDate",
      },
    ],
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<GetShipmentListRequest, GetShipmentListFilterRequest>({
    isReady,
    gridRef: gridRef.current,
    fetch: getShipmentList,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        shipmentStatus:
          data.shipmentStatus === "all" ? undefined : data?.shipmentStatus,
        scNo: data?.scNo || undefined,
        blNo: data?.blNo || undefined,
        bookingNo: data?.bookingNo || undefined,
        buyerNameCode:
          data.buyerNameCode === "all"
            ? undefined
            : buyerOptionList.find(({ value }) => value === data.buyerNameCode)
                ?.label,
        itemCode: data?.itemCode || undefined,
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        etdAt: undefined,
        etdAtFrom: data.etdAt?.[0]
          ? dayjs(data.etdAt?.[0]).startOf("day").toISOString()
          : undefined,
        etdAtTo: data.etdAt?.[1]
          ? dayjs(data.etdAt?.[1]).endOf("day").toISOString()
          : undefined,
        isCiOrPlRecheckNeeded:
          (data?.isCiOrPlRecheckNeeded as unknown as string) === "all"
            ? undefined
            : (data?.isCiOrPlRecheckNeeded as any) === "true",
        workplaceName:
          data?.workplaceName === "all" ? undefined : data?.workplaceName,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        shipmentStatus: urlObject.shipmentStatus ?? "all",
        scNo: urlObject.scNo,
        bookingNo: urlObject.bookingNo,
        blNo: urlObject.blNo,
        buyerNameCode:
          buyerOptionList.find(({ label }) => label === urlObject.buyerNameCode)
            ?.value ?? "all",
        itemCode: urlObject.itemCode,
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        etdAt:
          urlObject.etdAtFrom && urlObject.etdAtTo
            ? [dayjs(urlObject.etdAtFrom), dayjs(urlObject.etdAtTo)]
            : undefined,
        etdAtFrom: urlObject.etdAtFrom,
        etdAtTo: urlObject.etdAtTo,
        isCiOrPlRecheckNeeded: (urlObject.isCiOrPlRecheckNeeded
          ? urlObject.isCiOrPlRecheckNeeded === "true"
            ? "true"
            : "all"
          : "all") as unknown as boolean,
        workplaceName: urlObject.workplaceName ?? "all",
      };
    },
  });

  const handleTabClick = (tabItem: string) => {
    onFilterDataChange("isCiOrPlRecheckNeeded", tabItem);
    onForceSearch("isCiOrPlRecheckNeeded", tabItem);
  };

  const getRowClass = (params: RowClassParams) => {
    if (params.data.isCiOrPlRecheckNeeded) {
      return "isCiOrPlRecheckNeeded";
    }
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:shipment")]}
      pageTitle={t("sideNav:shipmentManagement")}
    >
      <FlexColumn>
        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "shipmentStatus",
              label: t("shipment:exporter.list.filter.shipmentStatus"),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.all"),
                allowClear: filterData.shipmentStatus !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("shipmentStatus", "all");
                    onForceSearch("shipmentStatus", "all");
                  } else {
                    onFilterDataChange("shipmentStatus", value);
                    onForceSearch("shipmentStatus", value);
                  }
                },
              },
            },
            {
              key: "blNo",
              label: t("shipment:exporter.list.filter.blNo"),
              type: "input",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.blNo"),
              },
            },
            {
              key: "buyerNameCode",
              label: t("shipment:exporter.list.filter.buyerNameCode"),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.all"),
                allowClear:
                  filterData.buyerNameCode === "all" ? undefined : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("buyerNameCode", "all");
                    onForceSearch("buyerNameCode", "all");
                  } else {
                    onFilterDataChange("buyerNameCode", value);
                    onForceSearch("buyerNameCode", value);
                  }
                },
              },
            },
            {
              key: "bookingNo",
              label: t("shipment:exporter.list.filter.bookingNo"),
              type: "input",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.bookingNo"),
              },
            },
            {
              key: "mainCategoryCodeItemName",
              label: t(
                "shipment:exporter.list.filter.mainCategoryCodeItemName"
              ),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.all"),
                allowClear: filterData.mainCategoryCodeItemName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("mainCategoryCodeItemName", "all");
                    onForceSearch("mainCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("mainCategoryCodeItemName", value);
                    onForceSearch("mainCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "itemCode",
              label: t("shipment:exporter.list.filter.itemCode"),
              type: "input",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.itemCode"),
              },
            },
            {
              key: "scNo",
              label: t("shipment:exporter.list.filter.scNo"),
              type: "input",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.scNo"),
              },
            },
            {
              key: "etdAt",
              label: t("shipment:exporter.list.filter.etd"),
              type: "dateRangePicker",
            },
            {
              key: "workplaceName",
              label: t("shipment:exporter.list.filter.workplaceName"),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:exporter.list.placeholder.all"),
                allowClear: filterData.workplaceName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("workplaceName", "all");
                    onForceSearch("workplaceName", "all");
                  } else {
                    onFilterDataChange("workplaceName", value);
                    onForceSearch("workplaceName", value);
                  }
                },
              },
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "shipmentStatus",
              option: [
                allOptionItem,
                {
                  label: t("shipment:exporter.list.badge.beforeRegistration"),
                  value: "BEFORE_REGISTRATION",
                },
                {
                  label: t("shipment:exporter.list.badge.ciPlWriting"),
                  value: "CI_PL_WRITING",
                },
                {
                  label: t("shipment:exporter.list.badge.temporaryRegister"),
                  value: "TEMPORARY_REGISTER",
                },
                {
                  label: t("shipment:exporter.list.badge.ciPlCompleted"),
                  value: "CI_PL_COMPLETED",
                },
                {
                  label: t("shipment:exporter.list.badge.blWriting"),
                  value: "BL_WRITING",
                },
                {
                  label: t("shipment:exporter.list.badge.completed"),
                  value: "COMPLETED",
                },
              ],
            },
            {
              key: "buyerNameCode",
              option: [allOptionItem, ...buyerOptionList],
            },
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
            {
              key: "workplaceName",
              option: [allOptionItem, ...factoryOptionList],
            },
          ]}
        />

        <div>
          <Typo color="gray4" typoType="b5m">
            {t("shipment:exporter.list.table.title")}
          </Typo>

          <TableTitleContainer>
            <Tabs role="tablist">
              {TAB_LIST.map(({ label, value }, idx) => {
                return (
                  <StyledTabItem
                    key={idx.toString()}
                    tabIndex={
                      value === String(filterData.isCiOrPlRecheckNeeded)
                        ? 0
                        : -1
                    }
                    tabValue={value}
                    onClick={() => {
                      value !== String(filterData.isCiOrPlRecheckNeeded) &&
                        handleTabClick(value);
                      setCol(colTempRef.current);
                    }}
                    data-selected={
                      value === String(filterData.isCiOrPlRecheckNeeded)
                    }
                    onFocusItem={(value) => {
                      value !== String(filterData.isCiOrPlRecheckNeeded) &&
                        handleTabClick(value);
                      setCol(colTempRef.current);
                    }}
                  >
                    <TapItemTypoContainer>
                      {label === "UPDATE" && <Icon iconSrc={ErrorCircleSvg} />}
                      <Typo
                        typoType="b7m"
                        color={
                          value === String(filterData.isCiOrPlRecheckNeeded)
                            ? "gray2"
                            : "gray6"
                        }
                      >
                        {renderTabItemTitle(value)}
                      </Typo>
                    </TapItemTypoContainer>
                  </StyledTabItem>
                );
              })}
            </Tabs>

            <ButtonContainer>
              <StyledButton
                buttonSize={40}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleExcelDownload}
              >
                <Icon iconSrc={ExcelSvg} iconSize={16} />
                {t("shipment:exporter.list.table.excel")}
              </StyledButton>
              <StyledButton
                buttonSize={40}
                onClick={() => navigate(EXPORTER_PRIVATE_PATH.SHIPMENT_ADD)}
                disabled={isCreateDisabled}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />
                {t("shipment:exporter.list.table.addButton")}
              </StyledButton>
            </ButtonContainer>
          </TableTitleContainer>

          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              getRowClass={getRowClass}
              rowData={isError ? [] : shipmentList}
              isPaginationDataMaping
              columnDefs={col}
              handlePaginationClick={(page, pageSize) =>
                onPaginationChange({ page, pageSize })
              }
              totalPage={count}
              onCellClicked={(e) => {
                const selectShipmentRow = e.node.data;
                const isBlNoColumn = e.column.getColDef().field === "blNo";

                if (isBlNoColumn) {
                  if (selectShipmentRow.blNo) {
                    handleBlnoClick(selectShipmentRow);
                  }
                } else {
                  return navigate(
                    `${EXPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${selectShipmentRow.id}/?selectTab=${ShipmentSelectTabState.BOOKING_CONTRACT}`
                  );
                }
              }}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onSortChange={(sortSource, isClickedHeader) =>
                !!isClickedHeader && onSortChange(sortSource as any)
              }
              onColumnMoved={(e) => {
                handleColumnMoved(e);

                const changedGrid = e.columnApi.getAllGridColumns();
                const newCol = changedGrid.map(
                  (col) =>
                    columnShipmentList.find(
                      ({ field }) => col["colId"] === field
                    ) ?? {}
                );
                colTempRef.current = newCol;
              }}
              onGridReady={() => setIsReady(true)}
              paginationRightAccessory={
                <StyledButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleColumnReset}
                  style={{ marginLeft: "8px" }}
                >
                  <Icon iconSrc={ResetSvg} iconSize={16} />
                  <Typo typoType="btn4m">{t("common:columnReset")}</Typo>
                </StyledButton>
              }
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>
    </ExporterMainLayout>
  );
};

export default ExporterShipmentPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  border-bottom: 1px solid ${colorSet.gray9};
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

const Tabs = styled.div`
  display: flex;
  gap: 24px;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px 0;
  cursor: pointer;
  background: none;
  border: none;

  &[data-selected="true"] {
    padding: 10px 0 8px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const TapItemTypoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
