import Dialog from "@/src/components/atom/Dialog";
import { GenerateSalesContractTermsDto } from "@/src/store/apis/corporate/company/interface";
import BasicTextArea from "@/src/components/atom/TextArea/BasicTextArea";
import Typo from "@/src/components/atom/Typo";
import styled from "styled-components";

interface TermsAddDialogProps {
  title: string;
  open: boolean;
  onOpenChange: (value: boolean) => void;
  termsValueData: GenerateSalesContractTermsDto;
}

const TermsPreviewDialog = ({
  title,
  open,
  onOpenChange,
  termsValueData,
}: TermsAddDialogProps) => {
  return (
    <Dialog title={title} open={open} width={850} onOpenChange={onOpenChange}>
      <Title typoType="h5">{termsValueData.title}</Title>
      <BasicTextArea defaultValue={termsValueData.body} isReadOnly={true} />
    </Dialog>
  );
};

export default TermsPreviewDialog;

const Title = styled(Typo)`
  display: inline-block;
  margin-bottom: 16px;
`;
