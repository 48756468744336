import { Button, IconButton } from "@/src/components/atom/Button";
import SectionCard from "@/src/components/molecule/SectionCard";
import React, { useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Typo from "@/src/components/atom/Typo";
import colorSet, { ColorType } from "@/src/styles/color";
import { downloadFile } from "@/src/utils/downloadFile";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import {
  InternalScFileInfo,
  SimpleFileMediaDto,
} from "@/src/store/apis/shipments/shipmentDetail/interface";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { ScData } from "@/src/components/template/pdfs/Sc/types";
import ScDownLoad from "@/src/components/template/pdfs/Sc/DownLoad";
import ScPreview from "@/src/components/template/pdfs/Sc/Preview";
import { useLazyGetContractDetailQuery } from "@/src/store/apis/contracts/contractDetail";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import dayjs from "dayjs";
import useAlert from "@/src/hooks/useAlert";
import { useTranslation } from "react-i18next";

const getScPdfData: (data: ContractDetailViewDto) => ScData = (
  contractData
) => {
  return {
    scNumber: contractData?.scNo || "",
    componyLogoUrl: contractData?.businessLogoSimpleMedia?.mediaUrl || "",
    sellerCoporation: contractData?.companyName || "",
    sellerContact: contractData?.tel || "",
    sellerContactPrefix: contractData?.telPrefix || "",
    sellerFax: contractData?.fax || "",
    sellerFaxPrefix: contractData?.faxPrefix || "",

    sellerCountryName: contractData?.countryName || "",
    sellerRegion: contractData?.region || "",
    sellerPostalCode: contractData?.postalCode || "",
    sellerLocality: contractData?.locality || "",
    sellerStreetAddress: contractData?.streetAddress || "",

    buyerCoporation: contractData?.buyerName || "",
    buyerContactPrefix: contractData?.buyerTelPrefix || "",
    buyerContact: contractData?.buyerTel || "",
    buyerFaxPrefix: contractData?.buyerFaxPrefix || "",
    buyerFax: contractData?.buyerFax || "",

    buyerCountryName: contractData?.buyerCountryName || "",
    buyerRegion: contractData?.buyerRegion || "",
    buyerPostalCode: contractData?.buyerPostalCode || "",
    buyerLocality: contractData?.buyerLocality || "",
    buyerStreetAddress: contractData?.buyerStreetAddress || "",

    orderDate: dayjs(contractData?.orderDateAt).format("YYYY-MM-DD"),
    paymentTerm: contractData?.paymentTerms || "",
    paymentTermsRemark: contractData?.paymentTermsRemark || "",
    originLocation: contractData?.origin || "",
    shippingTermRemark: contractData?.shippingTermsRemark || "",
    shippingTerm: contractData?.shippingTerms || "",
    lastShipmentDate: dayjs(contractData?.lastShipmentDateAt).format(
      "YYYY-MM-DD"
    ),

    quantityPrefix: contractData?.quantityUnit || "",
    quantity:
      Number(String(contractData?.quantity).replace(/[,]/g, "")).toLocaleString(
        "ko-KR",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ) || "",
    description: contractData?.exporterItem || "",
    hsCode: contractData?.hsCode || "",
    unitPricePrefix: contractData?.unitPriceUnit || "",
    unitPrice:
      Number(
        String(contractData?.unitPrice).replace(/[,]/g, "")
      ).toLocaleString("ko-KR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "",
    amount:
      (
        contractData?.quantity &&
        contractData?.unitPrice &&
        Number(
          parseFloat(
            String(contractData?.quantity * contractData?.unitPrice)
          ).toFixed(2)
        )
      )?.toLocaleString("ko-KR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "",
    remark: contractData?.contractRemark || "",
    isAddBankDetail: contractData?.isAddBankDetail || false,
    bankName: contractData?.bankName || "",
    bankTelPrefix: contractData?.bankTelPrefix || "",
    bankTel: contractData?.bankTel || "",
    bankFaxPrefix: contractData?.bankFaxPrefix || "",
    bankFax: contractData?.bankFax || "",
    bankStreetAddress: contractData?.bankStreetAddress || "",
    bankLocality: contractData?.bankLocality || "",
    bankRegion: contractData?.bankRegion || "",
    bankPostalCode: contractData?.bankPostalCode || "",
    bankCountryName: contractData?.bankCountryName || "",
    swiftCode: contractData?.swiftCode || "",
    accountNumber: contractData?.accountNumber || "",
    accountName: contractData?.accountName || "",
    sellerSignatureUrl: contractData?.signatureSimpleMedia?.mediaUrl || "",
    buyerSignatureUrl: "",
    isAddContractTerms: contractData?.isAddContractTerms,
    contractTermsTitle: contractData?.salesContractTerms?.title || "",
    contractTerms:
      contractData?.salesContractTerms?.latestSalesContractTermsHistory.body ||
      "",
  };
};

interface AllFileCardProps {
  internalScFile?: InternalScFileInfo[];
  scAttachmentFile?: SimpleFileMediaDto[];
  poFile?: SimpleFileMediaDto[];
  lcFile?: SimpleFileMediaDto[];
  bookingFile?: SimpleFileMediaDto[];
  isSuccess: boolean;
}

function AllFileCard({
  internalScFile,
  scAttachmentFile,
  poFile,
  lcFile,
  bookingFile,
  isSuccess,
}: AllFileCardProps) {
  const { t } = useTranslation();
  const [getContractDetail] = useLazyGetContractDetailQuery();
  const alert = useAlert();
  const [contractsDetail, setContractsDetail] =
    useState<ContractDetailViewDto[]>();
  const allButtonRef = useRef<HTMLButtonElement[]>([]);

  const isUndefinedFiles =
    !internalScFile && !scAttachmentFile && !poFile && !lcFile && !bookingFile;

  const fetchInternalScFile = async () => {
    try {
      const contractDetailList = await Promise.all(
        internalScFile?.map(({ contractId }) =>
          getContractDetail({ id: contractId }).unwrap()
        ) ?? []
      );

      setContractsDetail(contractDetailList);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    isSuccess && fetchInternalScFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <SectionCard
      cardTitle={t("shipment:exporter.detail.label.allFile")}
      rightAccessory={
        <StyledButton
          buttonGrade="tertiary"
          buttonColor="black"
          buttonSize={32}
          onClick={() => {
            allButtonRef.current.forEach((buttonNode) => buttonNode.click());
          }}
        >
          <DownloadIcon />
          {t("shipment:exporter.detail.button.downloadButton")}
        </StyledButton>
      }
    >
      {isUndefinedFiles ? (
        <NoDataContainer>{renderNoRowsComponent()}</NoDataContainer>
      ) : (
        <AllFileContainer>
          {contractsDetail?.map((data, idx) => {
            const scData = getScPdfData(data);

            return (
              <SectionCardRow
                label={`${t("common:scFile")} ${addZeroPrefix(idx + 1)}`}
                value={
                  <FileInfo>
                    <FileName typoType="b7r" color="gray5">
                      {scData.scNumber ?? ""}
                    </FileName>
                    <FileButtons>
                      <ScDownLoad
                        fileName={`${t("common:scNo")}${scData.scNumber}.pdf`}
                        data={scData}
                        trigger={
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            ref={(node) => {
                              if (node) {
                                const isAlreadyInRef =
                                  allButtonRef.current.some((buttonNode) =>
                                    buttonNode.isSameNode(node)
                                  );

                                if (!isAlreadyInRef) {
                                  allButtonRef.current =
                                    allButtonRef.current.concat(node);
                                }
                              }
                            }}
                          >
                            <DownloadIcon color="indigo" />
                          </IconButton>
                        }
                      />
                      <ScPreview
                        data={scData}
                        trigger={
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                          >
                            <PreviewIcon color="indigo" />
                          </IconButton>
                        }
                      />
                    </FileButtons>
                  </FileInfo>
                }
              />
            );
          })}

          {scAttachmentFile &&
            scAttachmentFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:scFile")} ${addZeroPrefix(idx + 1)} (${t(
                    "common:attachment"
                  )})`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node)
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? ""
                            );
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}

          {poFile &&
            poFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:poFile")} ${addZeroPrefix(idx + 1)}`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node)
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? ""
                            );
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}

          {lcFile &&
            lcFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:lcFile")} ${addZeroPrefix(idx + 1)}`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node)
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? ""
                            );
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}

          {bookingFile &&
            bookingFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:bookingFile")} ${addZeroPrefix(idx + 1)}`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? ""
                            );
                          }}
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node)
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}
        </AllFileContainer>
      )}
    </SectionCard>
  );
}

export default AllFileCard;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AllFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const FileName = styled(Typo)`
  word-break: break-all;
`;

const FileButtons = styled.div`
  display: flex;
  gap: 4px;

  a {
    line-height: 0px;
  }
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 216px;
`;
