import { CSSProperties, useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Button, TextButton } from "@/src/components/atom/Button";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import RadioGroup from "@/src/components/atom/RadioGroup";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import NumberInput from "@/src/components/atom/Input/NumberInput";
import Typo from "@/src/components/atom/Typo";
import CheckboxCountField from "@/src/components/molecule/CheckboxCountField";
import Checkbox from "@/src/components/atom/Checkbox";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import colorSet from "@/src/styles/color";
import { mediaQuery } from "@/src/styles/mediaQuery";
import typo from "@/src/styles/typography";
import InfoSvg from "@/src/assets/icons/icon-info.svg";
import Select from "@/src/components/atom/Select";
import NoDataBagSvg from "@/src/assets/icons/icon-no-data-bag.svg";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import LoadBookingDialog from "./components/add/dialog/LoadBookingDialog";
import {
  MediaCoordinateRangeType,
  TaskBookingListViewDto,
} from "@/src/store/apis/tasks/taskRegister/interface";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import LoadContractDialog from "./components/add/dialog/LoadContractDialog";
import { BookingContractListViewDto } from "@/src/store/apis/contracts/contractList/interface";
import {
  useGetFactoriesQuery,
  useLazyGetFactoryEmployeesQuery,
} from "@/src/store/apis/client/factory";
import Loader from "@/src/components/atom/Loader";
import AddNewContactPersonDialog from "./components/add/dialog/AddNewContactPersonDialog";
import { transformValueOrNull } from "@/src/utils/transform";
import { useLazyGetBookingListAssociatedWithTaskQuery } from "@/src/store/apis/tasks/taskRegister";
import { useLazyGetContractListAssociatedWithBookingQuery } from "@/src/store/apis/contracts/contractList";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { useLazyGetTaskDetailQuery } from "@/src/store/apis/tasks/taskDetail";
import { StyledScroll } from "@/src/styles/scroll";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import { useEditTaskMutation } from "@/src/store/apis/tasks/taskEdit";
import { ModifyTaskDto } from "@/src/store/apis/tasks/taskEdit/interface";
import {
  getMaxContainerInfoViaLang,
  MEDIA_COORDINATE_RANGE_OPTION_LIST,
} from "./constant/add";
import { NOT_FOUND_CODE } from "@/src/constant/error";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { isNull, isUndefined } from "@/src/utils/is";
import { editTaskPageDefaultValue } from "./utils/defaultValues";
import ClockSvg from "@/src/assets/icons/icon-clock-black.svg";
import { ErrorResponse } from "@/src/store/apis/type";
import UnauthorizedDescription from "@/src/components/molecule/UnauthorizedDescription";
import { useLazyGetBuyerQuery } from "@/src/store/apis/client/buyer";
import { useLazyGetItemCodeListQuery } from "@/src/store/apis/corporate/companyItemManagement";
import { ExporterItemCodeListViewV2Dto } from "@/src/store/apis/corporate/companyItemManagement/interface";
import { useTranslation } from "react-i18next";
import { Radio as AntdRadio } from "antd";
import Flex from "@/src/components/molecule/Flex";
import Label from "@/src/components/atom/Label";
import Radio from "@/src/components/atom/Radio";
import { useLazyGetContractDetailQuery } from "@/src/store/apis/contracts/contractDetail";

const cardContentContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "24px",
};

enum DialogState {
  NULL,
  BOOKING,
  CONTRACT,
  ADD_NEW_CONTACT_PERSON,
}

enum AlertDialogState {
  NULL,
  SAVED_DATA,
  BACK_TO_PREVIOUS,
}

const photoTypeMap = {
  containerBothDoorsClosedMediasCount: "bothDoorsClosed",
  containerCloseUpSealMediasCount: "closeUpSeal",
  containerEmptyMediasCount: "empty",
  containerExtraMediasCount: "extra",
  containerFullLoadedMediasCount: "fullLoaded",
  containerHalfLoadedMediasCount: "halfLoaded",
  containerOneDoorClosedMediasCount: "oneDoorClosed",
  containerQuarterLoadedMediasCount: "quarterLoaded",
  containerSampleBaleMediasCount: "sampleBale",
};

type PhotoTypeContainerKey =
  | "containerBothDoorsClosedMediasCount"
  | "containerCloseUpSealMediasCount"
  | "containerEmptyMediasCount"
  | "containerExtraMediasCount"
  | "containerFullLoadedMediasCount"
  | "containerHalfLoadedMediasCount"
  | "containerOneDoorClosedMediasCount"
  | "containerQuarterLoadedMediasCount"
  | "containerSampleBaleMediasCount";

const zeroOrPositiveNumber = (bool: boolean, value: number) => {
  return !bool ? 0 : value;
};

const emptyArray: [] = [];
const itemEmptyArray: ExporterItemCodeListViewV2Dto[] = [];

const TaskEditPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const alert = useAlert();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [isSameAsWorkingDay, setIsSameAsWorkingDay] = useState<boolean>(false);
  const [excludedManagerId, setExcludedManagerId] = useState<
    (string | undefined)[]
  >([]);
  const [bookingDetail, setBookingDetail] =
    useState<TaskBookingListViewDto | null>(null);
  const [contractDetail, setContractDetail] =
    useState<BookingContractListViewDto | null>(null);

  const { mainCategoryList, exporterUserType } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      const isManager = currentData?.row.exporterUserType === "MANAGER";

      return {
        mainCategoryList: isStable
          ? isManager
            ? currentData.row.mainCategoryCodeItemNames
            : currentData.row.exporter.mainCategoryCodeItemNames
          : emptyArray,
        exporterUserType: currentData?.row.exporterUserType,
      };
    },
  });
  const { factoryList, isFetching } = useGetFactoriesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        return {
          factoryList: !isUnstable ? currentData.rows : emptyArray,
          isFetching,
        };
      },
    }
  );
  const [
    getFactoryEmployees,
    { factoryEmployees, isFactoryEmployeesFetching },
  ] = useLazyGetFactoryEmployeesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;

      return {
        factoryEmployees: !isUnstable ? currentData.rows : emptyArray,
        isFactoryEmployeesFetching: isFetching,
      };
    },
  });
  const [editLoading] = useEditTaskMutation();
  const [getBookingList] = useLazyGetBookingListAssociatedWithTaskQuery();
  const [getContractList] = useLazyGetContractListAssociatedWithBookingQuery();
  const [
    getLoadingDetail,
    {
      workplaceId,
      isEditAuth,
      isLoadingFetchingError,
      simpleTaskMemos,
      isLoadingUnstable,
    },
  ] = useLazyGetTaskDetailQuery({
    selectFromResult: ({ currentData, isFetching, isError, error }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;
      const isStable = !isUnstable;
      const isNotFoundError =
        !!error && (error as ErrorResponse).data.statusCode === NOT_FOUND_CODE;

      return {
        isLoadingUnstable: isUnstable,
        workplaceId: isStable ? currentData.workplaceId : 0,
        isEditAuth: !isNotFoundError,
        isLoadingFetchingError: isError,
        isLoadingFetching: isFetching,
        simpleTaskMemos:
          isStable && !isNull(currentData.simpleTaskMemos)
            ? currentData.simpleTaskMemos
            : emptyArray,
      };
    },
  });
  const [getBuyerDetail] = useLazyGetBuyerQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;
      const isStable = !isUnstable;
      return {
        mainCategoryCodeItem: isStable
          ? currentData.mainCategoryCodeItemNames
          : emptyArray,
      };
    },
  });

  const [getItemCodes, { itemList, isItemCodeUnstable }] =
    useLazyGetItemCodeListQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isItemCodeUnstable: isUnstable,
          itemList: !isUnstable
            ? currentData?.rows ?? itemEmptyArray
            : itemEmptyArray,
        };
      },
    });

  const [
    getContractDetail,
    { contractDetailMainCategory, isContractDetailFetching },
  ] = useLazyGetContractDetailQuery({
    selectFromResult: ({ currentData, isFetching }) => {
      return {
        contractDetailMainCategory: currentData?.mainCategory,
        isContractDetailFetching: isFetching,
      };
    },
  });

  const mainCategoryItemName = itemList.find(
    (item) => item.itemCode === contractDetail?.itemCode
  )?.mainCategoryCodeItemName;

  // 매니저만 카테고리 비교
  const isMainCategoryAuthorized =
    exporterUserType === "MANAGER"
      ? mainCategoryList.includes(mainCategoryItemName as string)
      : true;
  const isMainCategoryUnauthorized =
    !isMainCategoryAuthorized || isLoadingFetchingError;

  const {
    control,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<{
    bookingId?: number;
    contractId?: number;
    workingDayAt?: string;
    transportDateAt?: string;
    transportRemark?: string;
    workEstimatedTime?: Dayjs;
    containerTaskRequestRemark?: string;
    sampleBale?: {
      count: number;
      isUse: boolean;
    };
    empty?: {
      count: number;
      isUse: boolean;
    };
    quarterLoaded?: {
      count: number;
      isUse: boolean;
    };
    halfLoaded?: {
      count: number;
      isUse: boolean;
    };
    fullLoaded?: {
      count: number;
      isUse: boolean;
    };
    oneDoorClosed?: {
      count: number;
      isUse: boolean;
    };
    bothDoorsClosed?: {
      count: number;
      isUse: boolean;
    };
    closeUpSeal?: {
      count: number;
      isUse: boolean;
    };
    extra?: {
      count: number;
      isUse: boolean;
      remark?: string;
    };
    workplaceId?: string;
    numberOfTaskContainer: number;
    memo: string;
    taskCode: string;
    taskWorkplaceEmployeeInfos: {
      transmissionMethod?: string;
      workplaceEmployeeId?: string;
    }[];
    mediaCoordinateRange: MediaCoordinateRangeType;
  }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: editTaskPageDefaultValue,
  });

  // 계약의 메인 카테고리에 따른 필터링
  const factoriesToOptionList = factoryList
    .filter(
      ({ isActivated, workplaceListQueryResultDto, mainCategory }) =>
        (isActivated &&
          mainCategory
            .split(", ")
            .some((category) => category === contractDetailMainCategory)) ||
        workplaceId.toString() === workplaceListQueryResultDto.id.toString()
    )
    .reduce<{ label: string; value: string }[]>((acc, val) => {
      const resource = {
        label: val.workplaceName,
        value: val.workplaceListQueryResultDto.id.toString(),
      };

      return [...acc, resource];
    }, []);

  const factoryEmployeesToOptionList = factoryEmployees
    .filter(
      ({ isActivated, id }) =>
        isActivated || excludedManagerId.includes(id.toString())
    )
    .reduce<{ label: string; value: string }[]>((acc, val) => {
      const resource = {
        label: `${val.name} / ${val.personalContactPrefix} ${val.personalContact} / ${val.email}`,
        value: val.id.toString(),
      };

      return [...acc, resource];
    }, []);

  const handleEditLoading = async () => {
    const {
      sampleBale,
      empty,
      quarterLoaded,
      halfLoaded,
      fullLoaded,
      oneDoorClosed,
      bothDoorsClosed,
      closeUpSeal,
      extra,
      workingDayAt,
      transportDateAt,
      transportRemark,
      containerTaskRequestRemark,
      memo,
      bookingId,
      contractId,
      numberOfTaskContainer,
      workplaceId,
      taskWorkplaceEmployeeInfos,
      workEstimatedTime,
      mediaCoordinateRange,
    } = getValues();

    const transformData = transformValueOrNull({
      targetObject: {
        workingDayAt,
        transportDateAt,
        transportRemark,
        containerTaskRequestRemark,
        memo,
        bookingId,
        contractId,
        numberOfTaskContainer,
        mediaCoordinateRange,
        extraMediaRemark: !extra?.isUse ? undefined : extra?.remark,
        workplaceId: Number(workplaceId),
        taskWorkplaceEmployeeInfos: taskWorkplaceEmployeeInfos
          .filter(({ workplaceEmployeeId }) => workplaceEmployeeId)
          .map((employee) => {
            return {
              ...employee,
              workplaceEmployeeId: Number(employee.workplaceEmployeeId),
            };
          }),
      },
    });

    try {
      handleContentLoadingOn();
      await editLoading({
        id: Number(params.id),
        containerSampleBaleMediasCount: zeroOrPositiveNumber(
          !!sampleBale?.isUse,
          Number(sampleBale?.count)
        ),
        containerEmptyMediasCount: zeroOrPositiveNumber(
          !!empty?.isUse,
          Number(empty?.count)
        ),
        containerQuarterLoadedMediasCount: zeroOrPositiveNumber(
          !!quarterLoaded?.isUse,
          Number(quarterLoaded?.count)
        ),
        containerHalfLoadedMediasCount: zeroOrPositiveNumber(
          !!halfLoaded?.isUse,
          Number(halfLoaded?.count)
        ),
        containerFullLoadedMediasCount: zeroOrPositiveNumber(
          !!fullLoaded?.isUse,
          Number(fullLoaded?.count)
        ),
        containerOneDoorClosedMediasCount: zeroOrPositiveNumber(
          !!oneDoorClosed?.isUse,
          Number(oneDoorClosed?.count)
        ),
        containerBothDoorsClosedMediasCount: zeroOrPositiveNumber(
          !!bothDoorsClosed?.isUse,
          Number(bothDoorsClosed?.count)
        ),
        containerCloseUpSealMediasCount: Number(closeUpSeal?.count),
        containerExtraMediasCount: zeroOrPositiveNumber(
          !!extra?.isUse,
          Number(extra?.count)
        ),
        workEstimatedMinute: !!workEstimatedTime
          ? dayjs(workEstimatedTime).hour() * 60 +
            dayjs(workEstimatedTime).minute()
          : null,
        ...(transformData as Omit<
          ModifyTaskDto,
          "containerCloseUpSealMediasCount"
        >),
      }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("task:add.alert.saveSuccess"),
      });
      navigate(`${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${params.id}`);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  // 부킹 정보
  const renderBookingSelection = () => {
    return (
      <HalfSectionCard
        cardTitle={
          <>
            <RequiredSectionCardHeader>
              {bookingDetail
                ? t("task:add.common.bookingInformation")
                : t("task:add.common.bookingSelection")}{" "}
              <strong>*</strong>
              {isSubmitted && !bookingDetail && (
                <div>
                  <InputError message={t("error:required")} />
                </div>
              )}
            </RequiredSectionCardHeader>
            {bookingDetail && (
              <ChooseAgainButton
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={32}
                onClick={() => setDialogState(DialogState.BOOKING)}
              >
                {t("task:add.buttonLabel.chooseAgain")}
              </ChooseAgainButton>
            )}
          </>
        }
      >
        {bookingDetail ? (
          <>
            <InformationBoardBox>
              <InformationItem>
                <Typo typoType="b9m" color="gray6">
                  {t("common:noOfContainer")}
                </Typo>
                <Typo typoType="h4" color="blue2">
                  {`${bookingDetail.numberOfContainer.toLocaleString(
                    "ko-KR"
                  )}x${bookingDetail.containerType}`}
                </Typo>
              </InformationItem>
              <InformationItem>
                <Typo typoType="b9m" color="gray6">
                  {t("task:add.common.remainingContainer")}
                </Typo>
                <Typo typoType="h4" color="blue2">
                  {`${bookingDetail.remainingNumberOfContainer.toLocaleString(
                    "ko-KR"
                  )} ${t("common:ea")}`}
                </Typo>
              </InformationItem>
            </InformationBoardBox>
            <BookingDetailContainer>
              {[
                {
                  label: t("common:bookingNo"),
                  value: (
                    <StyledTextButton
                      onClick={() =>
                        navigate(
                          `${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/${bookingDetail.id}`
                        )
                      }
                    >
                      {bookingDetail.bookingNo}
                    </StyledTextButton>
                  ),
                },
                {
                  label: "ETD",
                  value: (
                    <>
                      {dayjs(bookingDetail.etdAt).format("YYYY-MM-DD")}{" "}
                      <EtdCompareCount
                        typoType="b7m"
                        initDate={bookingDetail.initialEtdAt}
                        currentDate={bookingDetail.etdAt}
                      />
                    </>
                  ),
                },

                {
                  label: t("common:cargoCutOff"),
                  value: dayjs(bookingDetail.cargoCutOffAt).format(
                    dayjs(bookingDetail.cargoCutOffAt).get("minute") === 30
                      ? "YYYY-MM-DD"
                      : "YYYY-MM-DD HH:mm"
                  ),
                },
                {
                  label: <PreWrap>{t("common:docCutOffWordBreak")}</PreWrap>,
                  value: dayjs(bookingDetail.docCutOffAt).format(
                    dayjs(bookingDetail.docCutOffAt).get("minute") === 30
                      ? "YYYY-MM-DD"
                      : "YYYY-MM-DD HH:mm"
                  ),
                },
                {
                  label: t("common:estimatedWeight"),
                  value:
                    bookingDetail.estimatedWeight &&
                    bookingDetail.estimatedWeightUnit ? (
                      <>
                        {Number(bookingDetail.estimatedWeight)?.toLocaleString(
                          "ko-KR"
                        )}{" "}
                        {bookingDetail.estimatedWeightUnit}
                      </>
                    ) : (
                      "-"
                    ),
                },
                { label: t("common:cfsCyCode"), value: bookingDetail.cfs },
              ].map(({ label, value }) => {
                return <SectionCardRow label={label} value={value} />;
              })}
            </BookingDetailContainer>
          </>
        ) : (
          <SelectionCardContent>
            <Typo typoType="b7r" color="gray6">
              {t("task:add.placeholder.bookingSelection")}
            </Typo>
            <Button
              ref={(node) => (buttonRef.current = node)}
              buttonGrade="secondary"
              buttonSize={40}
              onClick={() => setDialogState(DialogState.BOOKING)}
              disabled={!isEditAuth}
            >
              {t("task:add.buttonLabel.bookingSelection")}
            </Button>
          </SelectionCardContent>
        )}
      </HalfSectionCard>
    );
  };

  // 계약 정보
  const renderContractSelection = () => {
    return (
      <HalfSectionCard
        cardContentContainerStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
        }}
        cardTitle={
          <>
            <RequiredSectionCardHeader>
              {contractDetail
                ? t("task:add.common.contractInformation")
                : t("task:add.common.contractSelection")}{" "}
              <strong>*</strong>
              {isSubmitted && !contractDetail && (
                <div>
                  <InputError message={t("error:required")} />
                </div>
              )}
            </RequiredSectionCardHeader>
            {contractDetail && (
              <ChooseAgainButton
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={32}
                onClick={() => setDialogState(DialogState.CONTRACT)}
              >
                {t("task:add.buttonLabel.chooseAgain")}
              </ChooseAgainButton>
            )}
          </>
        }
      >
        {contractDetail ? (
          <>
            <InformationBoardBox>
              <InformationItem>
                <Typo typoType="b9m" color="gray6">
                  {t("common:buyer")}
                </Typo>
                <Typo typoType="h4" color="blue2">
                  {contractDetail.buyerNameCode}
                </Typo>
              </InformationItem>
              <InformationItem>
                <Typo typoType="b9m" color="gray6">
                  {t("common:item")}
                </Typo>
                <Typo typoType="h4" color="blue2">
                  {contractDetail.item}
                </Typo>
              </InformationItem>
            </InformationBoardBox>
            <BookingDetailContainer>
              {[
                {
                  label: t("common:itemCode"),
                  value: contractDetail.itemCode,
                },
                {
                  label: t("common:scNo"),
                  value: (
                    <StyledTextButton
                      onClick={() =>
                        navigate(
                          `${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${contractDetail.id}`
                        )
                      }
                    >
                      {contractDetail.scNo}
                    </StyledTextButton>
                  ),
                },
                {
                  label: t("common:poNo"),
                  value: contractDetail.poNo ?? "-",
                },
                {
                  label: t("common:qty"),
                  value: `${Number(contractDetail.quantity).toLocaleString(
                    "ko-KR"
                  )} ${contractDetail.quantityUnit}`,
                },
                {
                  label: t("common:unitPrice"),
                  value: `${Number(contractDetail.unitPrice).toLocaleString(
                    "ko-KR"
                  )} ${contractDetail.unitPriceUnit}`,
                },
                {
                  label: t("common:shippingTerms"),
                  value: `${contractDetail.shippingTerms} ${
                    contractDetail.shippingTermsRemark ?? ""
                  }`,
                },
                {
                  label: t("common:placeOfDelivery"),
                  value:
                    contractDetail?.anyPortIn ??
                    contractDetail?.placeOfDelivery ??
                    "-",
                },
                {
                  label: t("common:allocation"),
                  value: contractDetail?.allocation ?? "-",
                  isHidden: !contractDetail?.allocation,
                },
                {
                  label: t("common:originLocation"),
                  value: contractDetail?.origin ?? "-",
                },
                {
                  label: t("common:lastShipmentDate"),
                  value: !isNull(contractDetail.lastShipmentDateAt)
                    ? dayjs(contractDetail.lastShipmentDateAt).format(
                        "YYYY-MM-DD"
                      )
                    : "-",
                },
              ].map(({ label, value, isHidden = false }) => {
                if (isHidden) return null;
                return <SectionCardRow label={label} value={value} />;
              })}
            </BookingDetailContainer>
          </>
        ) : (
          <SelectionCardContent>
            <Typo typoType="b7r" color="gray6">
              {t("task:add.placeholder.contractSelection")}
            </Typo>
            <Button
              buttonGrade="secondary"
              buttonSize={40}
              disabled={!bookingDetail}
              onClick={() => setDialogState(DialogState.CONTRACT)}
            >
              {t("task:add.buttonLabel.contractSelection")}
            </Button>
          </SelectionCardContent>
        )}
      </HalfSectionCard>
    );
  };

  // 작업 정보
  const renderLoadingInformation = () => {
    return (
      <SectionCard
        cardContentContainerStyle={cardContentContainerStyle}
        cardTitle={t("task:add.common.loadingInformation")}
      >
        <FormItemsContainer>
          <FormItemContainer>
            <StyledFormItem
              control={control as any}
              label={t("task:add.common.workingDay")}
              rules={{ required: true }}
              inputProps={{
                placeholder: "YYYY-MM-DD HH:mm",
                showTime: true,
                format: "YYYY-MM-DD HH:mm",
                minuteStep: 30,
                showNow: false,
                value: watch("workingDayAt")
                  ? dayjs(watch("workingDayAt"))
                  : undefined,
                changeOnBlur: true,
                onChange: (date: Dayjs | null) => {
                  if (!date) return;
                  const isThirtyOrZeroMin =
                    date.minute() === 0 || date.minute() === 30;

                  const format = `YYYY-MM-DD HH:mm:00`;
                  const dateValue = `YYYY-MM-DD HH:${
                    isThirtyOrZeroMin ? date.minute() : "00"
                  }:00`;

                  if (isSameAsWorkingDay) {
                    setValue(
                      "transportDateAt",
                      dayjs(date.format(dateValue), format).toISOString()
                    );
                  }

                  setValue(
                    "workingDayAt",
                    dayjs(date.format(dateValue), format).toISOString()
                  );
                },
              }}
              name="workingDayAt"
              type="date"
              errorsMessage={{
                required: t("error:required"),
              }}
            />
          </FormItemContainer>
          <FormItemContainer>
            <CustomField data-responsive>
              <FormKey>
                {t("common:noOfContainer")} <strong>*</strong>
              </FormKey>

              <CustomFieldContainer>
                <Controller
                  control={control}
                  name="numberOfTaskContainer"
                  rules={{ required: true, validate: (number) => number > 0 }}
                  render={({ field }) => {
                    return (
                      <HalfInputContainer>
                        <NumberInput
                          {...field}
                          disabled
                          data-invalid={!!errors.numberOfTaskContainer}
                        />
                        <Input
                          disabled
                          placeholder={bookingDetail?.containerType ?? ""}
                        />
                      </HalfInputContainer>
                    );
                  }}
                />

                {!!errors.numberOfTaskContainer && (
                  <InputError message={t("error:required")} />
                )}

                <WarningDescription>
                  <Icon iconSrc={InfoSvg} iconSize={16} />
                  <Typo typoType="b9r" color="gray6">
                    {getMaxContainerInfoViaLang(
                      t,
                      bookingDetail
                        ? bookingDetail.remainingNumberOfContainer > 30
                          ? 30
                          : bookingDetail.remainingNumberOfContainer
                        : 0
                    )}
                  </Typo>
                </WarningDescription>
              </CustomFieldContainer>
            </CustomField>
          </FormItemContainer>
          <FormItemContainer>
            <StyledFormItem
              type="timePicker"
              control={control as any}
              label={t("task:add.common.workEstimatedTime")}
              name="workEstimatedTime"
              inputProps={{
                value: watch("workEstimatedTime")
                  ? dayjs(watch("workEstimatedTime"), "HH:mm")
                  : undefined,
                placeholder: "HH:mm",
                format: "HH:mm",
                minuteStep: 30,
                showNow: false,
                changeOnBlur: true,
                suffixIcon: <TimePickerSuffixIcon url={ClockSvg} />,
                onSelect: (e: any) => {
                  const isZeroHour = dayjs(e).get("hour") === 0;

                  if (isZeroHour) {
                    return setValue(
                      "workEstimatedTime",
                      dayjs().set("hour", 0).set("minute", 30)
                    );
                  }
                },
                disabledTime: () => {
                  return {
                    disabledMinutes: (selectHour: number) => {
                      if (selectHour === 0) {
                        return [0];
                      }

                      return [];
                    },
                  };
                },
              }}
            />
          </FormItemContainer>

          <FormItemContainer>
            <StyledFormItem
              control={control as any}
              label={t("common:estimatedWeight")}
              name=""
              inputProps={{
                disabled: true,
                placeholder: `${
                  bookingDetail
                    ? `${
                        isNaN(
                          parseFloat(
                            Number(
                              (bookingDetail?.estimatedWeight ?? NaN) /
                                bookingDetail.numberOfContainer
                            ).toFixed(3)
                          )
                        )
                          ? "-"
                          : (
                              parseFloat(
                                Number(
                                  (bookingDetail?.estimatedWeight ?? 0) /
                                    bookingDetail.numberOfContainer
                                ).toFixed(3)
                              ) * watch("numberOfTaskContainer")
                            ).toLocaleString("ko-KR")
                      } ${bookingDetail?.estimatedWeightUnit ?? ""}`
                    : "-"
                }`,
              }}
              type="text"
            />
          </FormItemContainer>
          <FormItemContainer>
            <StyledFormItem
              control={control as any}
              label={t("task:add.common.loadingCode")}
              name="taskCode"
              inputProps={{
                disabled: true,
                placeholder: watch("taskCode") ?? "-",
                value: undefined,
              }}
              type="text"
            />
          </FormItemContainer>
        </FormItemsContainer>
      </SectionCard>
    );
  };

  // Photo Information
  const renderPhotoInformation = () => {
    return (
      <SectionCard cardTitle={t("task:add.label.photoInformation")}>
        <Flex flexDirection="column" gap={24}>
          <Controller
            name="mediaCoordinateRange"
            control={control}
            rules={{ required: true }}
            render={() => (
              <Flex flexDirection="column" gap={8}>
                <Label required>
                  {t("task:add.label.mediaCoordinateRange")}
                </Label>
                <StyledAntdRadioGroup
                  value={watch("mediaCoordinateRange")}
                  onChange={(e) =>
                    setValue("mediaCoordinateRange", e.target.value)
                  }
                >
                  <Flex gap={16}>
                    {MEDIA_COORDINATE_RANGE_OPTION_LIST.map(
                      ({ label, value, mapSrc }) => {
                        return (
                          <MapScopeContainer
                            key={value}
                            flexDirection="column"
                            gap={8}
                          >
                            <Radio value={value} id="mediaCoordinateRange">
                              {t(`task:${label}`)}
                            </Radio>
                            <MapScope
                              backgroundImage={mapSrc}
                              onClick={() =>
                                setValue("mediaCoordinateRange", value)
                              }
                            />
                          </MapScopeContainer>
                        );
                      }
                    )}
                  </Flex>
                </StyledAntdRadioGroup>

                <Flex alignItems="center" gap={6}>
                  <StyledIcon iconSrc={InfoGray6Svg} iconSize={16} />
                  <Typo typoType="b9r" color="gray6">
                    {t("task:add.notification.mediaCoordinateRange")}
                  </Typo>
                </Flex>
              </Flex>
            )}
          />

          <RequestPhotoTypeContainer>
            <Typo typoType="b7m" color="gray2">
              {t("task:add.common.requestPhotoType")}
            </Typo>

            <PhotoTypeCounterContent>
              <StyledCheckboxCountField
                key="sampleBale"
                label={t("task:add.photoType.sampleBale")}
                isChecked={watch("sampleBale.isUse")}
                count={watch("sampleBale.count")}
                onCountDown={() =>
                  setValue("sampleBale.count", watch("sampleBale.count") - 1)
                }
                onCountUp={() =>
                  setValue("sampleBale.count", watch("sampleBale.count") + 1)
                }
                onCheckChange={() =>
                  setValue("sampleBale.isUse", !watch("sampleBale.isUse"))
                }
              />
              <StyledCheckboxCountField
                key="empty"
                label={t("task:add.photoType.empty")}
                isChecked={watch("empty.isUse")}
                count={watch("empty.count")}
                onCountDown={() =>
                  setValue("empty.count", watch("empty.count") - 1)
                }
                onCountUp={() =>
                  setValue("empty.count", watch("empty.count") + 1)
                }
                onCheckChange={() =>
                  setValue("empty.isUse", !watch("empty.isUse"))
                }
              />
              <StyledCheckboxCountField
                key="quarterLoaded"
                label={t("task:add.photoType.quarterLoaded")}
                isChecked={watch("quarterLoaded.isUse")}
                count={watch("quarterLoaded.count")}
                onCountDown={() =>
                  setValue(
                    "quarterLoaded.count",
                    watch("quarterLoaded.count") - 1
                  )
                }
                onCountUp={() =>
                  setValue(
                    "quarterLoaded.count",
                    watch("quarterLoaded.count") + 1
                  )
                }
                onCheckChange={() =>
                  setValue("quarterLoaded.isUse", !watch("quarterLoaded.isUse"))
                }
              />
              <StyledCheckboxCountField
                key="halfLoaded"
                label={t("task:add.photoType.halfLoaded")}
                isChecked={watch("halfLoaded.isUse")}
                count={watch("halfLoaded.count")}
                onCountDown={() =>
                  setValue("halfLoaded.count", watch("halfLoaded.count") - 1)
                }
                onCountUp={() =>
                  setValue("halfLoaded.count", watch("halfLoaded.count") + 1)
                }
                onCheckChange={() =>
                  setValue("halfLoaded.isUse", !watch("halfLoaded.isUse"))
                }
              />
              <StyledCheckboxCountField
                key="fullLoaded"
                label={t("task:add.photoType.fullLoaded")}
                isChecked={watch("fullLoaded.isUse")}
                count={watch("fullLoaded.count")}
                onCountDown={() =>
                  setValue("fullLoaded.count", watch("fullLoaded.count") - 1)
                }
                onCountUp={() =>
                  setValue("fullLoaded.count", watch("fullLoaded.count") + 1)
                }
                onCheckChange={() =>
                  setValue("fullLoaded.isUse", !watch("fullLoaded.isUse"))
                }
              />
              <StyledCheckboxCountField
                key="oneDoorClosed"
                label={t("task:add.photoType.oneDoorClosed")}
                isChecked={watch("oneDoorClosed.isUse")}
                count={watch("oneDoorClosed.count")}
                onCountDown={() =>
                  setValue(
                    "oneDoorClosed.count",
                    watch("oneDoorClosed.count") - 1
                  )
                }
                onCountUp={() =>
                  setValue(
                    "oneDoorClosed.count",
                    watch("oneDoorClosed.count") + 1
                  )
                }
                onCheckChange={() =>
                  setValue("oneDoorClosed.isUse", !watch("oneDoorClosed.isUse"))
                }
              />
              <StyledCheckboxCountField
                key="bothDoorsClosed"
                label={t("task:add.photoType.bothDoorsClosed")}
                isChecked={watch("bothDoorsClosed.isUse")}
                count={watch("bothDoorsClosed.count")}
                onCountDown={() =>
                  setValue(
                    "bothDoorsClosed.count",
                    watch("bothDoorsClosed.count") - 1
                  )
                }
                onCountUp={() =>
                  setValue(
                    "bothDoorsClosed.count",
                    watch("bothDoorsClosed.count") + 1
                  )
                }
                onCheckChange={() =>
                  setValue(
                    "bothDoorsClosed.isUse",
                    !watch("bothDoorsClosed.isUse")
                  )
                }
              />
              <StyledCheckboxCountField
                key="closeUpSeal"
                isRequired
                label={
                  <>
                    {t("task:add.photoType.closeUpSeal")} <strong>*</strong>
                  </>
                }
                disabled
                isChecked
                count={watch("closeUpSeal.count")}
                onCountDown={() =>
                  setValue("closeUpSeal.count", watch("closeUpSeal.count") - 1)
                }
                onCountUp={() =>
                  setValue("closeUpSeal.count", watch("closeUpSeal.count") + 1)
                }
              />
              <StyledCheckboxCountField
                key="extra"
                label={t("task:add.photoType.extra")}
                isChecked={watch("extra.isUse")}
                count={watch("extra.count")}
                onCountDown={() =>
                  setValue("extra.count", watch("extra.count") - 1)
                }
                onCountUp={() =>
                  setValue("extra.count", watch("extra.count") + 1)
                }
                onCheckChange={() =>
                  setValue("extra.isUse", !watch("extra.isUse"))
                }
                bottomAccessory={
                  <Controller
                    control={control}
                    name="extra.remark"
                    render={({ field }) => (
                      <Input
                        {...field}
                        disabled={!watch("extra.isUse")}
                        onClear={() => setValue("extra.remark", "")}
                        placeholder={t("task:add.placeholder.extraType")}
                      />
                    )}
                  />
                }
              />
            </PhotoTypeCounterContent>
          </RequestPhotoTypeContainer>
        </Flex>
      </SectionCard>
    );
  };

  // 작업 요청 전송
  const renderSendLoadingRequest = () => {
    return (
      <SectionCard
        cardTitle={t("common:sendLoadingRequest")}
        cardContentContainerStyle={cardContentContainerStyle}
      >
        <CustomField>
          <FormKey>
            {t("task:add.common.factory")} <strong>*</strong>
          </FormKey>

          <CustomFieldContainer>
            <Controller
              control={control}
              name="workplaceId"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <HalfInputContainer>
                    <StyledSelect
                      {...field}
                      options={factoriesToOptionList}
                      disabled={
                        isFetching ||
                        !isEditAuth ||
                        isContractDetailFetching ||
                        isUndefined(watch("contractId"))
                      }
                      suffixIcon={
                        isFetching || isContractDetailFetching ? (
                          <Loader />
                        ) : undefined
                      }
                      placeholder={t("task:add.placeholder.factory")}
                      data-invalid={!!errors.workplaceId}
                      onChange={async (value) => {
                        try {
                          await getFactoryEmployees({ id: value });
                          setValue("workplaceId", value);
                          setValue(
                            "taskWorkplaceEmployeeInfos",
                            editTaskPageDefaultValue.taskWorkplaceEmployeeInfos
                          );
                        } catch (e: any) {
                          const message = Array.isArray(e.data.message)
                            ? e.data.message[0]
                            : e.data.message;
                          alert.showAlert({ type: "error", message });
                        }
                      }}
                    />
                    <Button
                      buttonColor="black"
                      buttonGrade="tertiary"
                      onClick={() =>
                        setDialogState(DialogState.ADD_NEW_CONTACT_PERSON)
                      }
                      disabled={!watch("workplaceId")}
                    >
                      {t("task:add.buttonLabel.addNewContactPerson")}
                    </Button>
                  </HalfInputContainer>
                );
              }}
            />

            {!!errors.workplaceId && (
              <InputError message={t("error:required")} />
            )}
          </CustomFieldContainer>
        </CustomField>

        <ManagersInfoContainer>
          <ManagerInfoContainer>
            <CustomField data-responsive>
              <FormKey>
                {t("task:add.common.manager")} 01 <strong>*</strong>
              </FormKey>
              <CustomFieldContainer>
                <Controller
                  control={control}
                  name="taskWorkplaceEmployeeInfos.0.workplaceEmployeeId"
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <StyledSelect
                        {...field}
                        options={factoryEmployeesToOptionList.filter(
                          ({ value }) => {
                            return (
                              value !==
                              watch("taskWorkplaceEmployeeInfos.1")
                                .workplaceEmployeeId
                            );
                          }
                        )}
                        suffixIcon={
                          isFactoryEmployeesFetching ? <Loader /> : undefined
                        }
                        data-invalid={
                          !!errors?.taskWorkplaceEmployeeInfos?.[0]
                            ?.workplaceEmployeeId
                        }
                        placeholder={t("task:add.placeholder.manager")}
                        disabled={isFactoryEmployeesFetching}
                        allowClear
                        onChange={(value) => {
                          if (value) {
                            field.onChange(value);
                          } else {
                            setValue(
                              "taskWorkplaceEmployeeInfos.0",
                              !!watch("taskWorkplaceEmployeeInfos.1")
                                .workplaceEmployeeId
                                ? {
                                    workplaceEmployeeId: watch(
                                      "taskWorkplaceEmployeeInfos.1"
                                    ).workplaceEmployeeId,
                                    transmissionMethod:
                                      watch("taskWorkplaceEmployeeInfos.1")
                                        .transmissionMethod ?? "ALL",
                                  }
                                : {
                                    workplaceEmployeeId: undefined,
                                    transmissionMethod: "ALL",
                                  }
                            );
                            setValue("taskWorkplaceEmployeeInfos.1", {
                              workplaceEmployeeId: undefined,
                              transmissionMethod: "ALL",
                            });
                          }
                        }}
                      />
                    );
                  }}
                />

                {!!errors?.taskWorkplaceEmployeeInfos?.[0]
                  ?.workplaceEmployeeId && (
                  <InputError message={t("error:required")} />
                )}
              </CustomFieldContainer>
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.name")}</SubFormKey>
              <Input
                disabled
                placeholder={
                  factoryEmployees.find(
                    ({ id }) =>
                      id.toString() ===
                      watch("taskWorkplaceEmployeeInfos")?.[0]
                        .workplaceEmployeeId
                  )?.name ?? "-"
                }
              />
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.contact")}</SubFormKey>
              <div style={{ width: "140px", flexShrink: 0 }}>
                <Input
                  disabled
                  placeholder={
                    watch("taskWorkplaceEmployeeInfos")?.[0].workplaceEmployeeId
                      ? `${
                          factoryEmployees.find(
                            ({ id }) =>
                              id.toString() ===
                              watch("taskWorkplaceEmployeeInfos")?.[0]
                                .workplaceEmployeeId
                          )?.personalContactPrefix
                        }`
                      : "-"
                  }
                />
              </div>
              <Input
                disabled
                placeholder={
                  watch("taskWorkplaceEmployeeInfos")?.[0].workplaceEmployeeId
                    ? `${
                        factoryEmployees.find(
                          ({ id }) =>
                            id.toString() ===
                            watch("taskWorkplaceEmployeeInfos")?.[0]
                              .workplaceEmployeeId
                        )?.personalContact
                      }`
                    : "-"
                }
              />
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.email")}</SubFormKey>
              <Input
                disabled
                placeholder={
                  factoryEmployees.find(
                    ({ id }) =>
                      id.toString() ===
                      watch("taskWorkplaceEmployeeInfos")?.[0]
                        .workplaceEmployeeId
                  )?.email ?? "-"
                }
              />
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.receive")}</SubFormKey>
              <Controller
                control={control}
                name="taskWorkplaceEmployeeInfos.0.transmissionMethod"
                render={({ field }) => {
                  return (
                    <StyledRadioGroup
                      {...field}
                      options={[
                        { label: t("common:all"), value: "ALL" },
                        { label: t("common:email.en"), value: "EMAIL" },
                        { label: t("common:sms.en"), value: "SMS" },
                      ]}
                    />
                  );
                }}
              />
            </CustomField>
          </ManagerInfoContainer>
          <ManagerInfoContainer>
            <CustomField data-responsive>
              <FormKey>{t("task:add.common.manager")} 02</FormKey>
              <CustomFieldContainer>
                <Controller
                  control={control}
                  name="taskWorkplaceEmployeeInfos.1.workplaceEmployeeId"
                  render={({ field }) => {
                    return (
                      <StyledSelect
                        {...field}
                        options={factoryEmployeesToOptionList.filter(
                          ({ value }) => {
                            return (
                              value !==
                              watch("taskWorkplaceEmployeeInfos.0")
                                .workplaceEmployeeId
                            );
                          }
                        )}
                        suffixIcon={
                          isFactoryEmployeesFetching ? <Loader /> : undefined
                        }
                        placeholder={t("task:add.placeholder.manager")}
                        disabled={isFactoryEmployeesFetching}
                        allowClear
                        onClear={() => {
                          setValue("taskWorkplaceEmployeeInfos.1", {
                            workplaceEmployeeId: undefined,
                            transmissionMethod: "ALL",
                          });
                        }}
                      />
                    );
                  }}
                />
              </CustomFieldContainer>
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.name")}</SubFormKey>
              <Input
                disabled
                placeholder={
                  factoryEmployees.find(
                    ({ id }) =>
                      id.toString() ===
                      watch("taskWorkplaceEmployeeInfos")?.[1]
                        .workplaceEmployeeId
                  )?.name ?? "-"
                }
              />
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.contact")}</SubFormKey>
              <div style={{ width: "140px", flexShrink: 0 }}>
                <Input
                  disabled
                  placeholder={
                    watch("taskWorkplaceEmployeeInfos")?.[1].workplaceEmployeeId
                      ? `${
                          factoryEmployees.find(
                            ({ id }) =>
                              id.toString() ===
                              watch("taskWorkplaceEmployeeInfos")?.[1]
                                .workplaceEmployeeId
                          )?.personalContactPrefix
                        }`
                      : "-"
                  }
                />
              </div>
              <Input
                disabled
                placeholder={
                  watch("taskWorkplaceEmployeeInfos")?.[1].workplaceEmployeeId
                    ? `${
                        factoryEmployees.find(
                          ({ id }) =>
                            id.toString() ===
                            watch("taskWorkplaceEmployeeInfos")?.[1]
                              .workplaceEmployeeId
                        )?.personalContact
                      }`
                    : "-"
                }
              />
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.email")}</SubFormKey>
              <Input
                disabled
                placeholder={
                  factoryEmployees.find(
                    ({ id }) =>
                      id.toString() ===
                      watch("taskWorkplaceEmployeeInfos")?.[1]
                        .workplaceEmployeeId
                  )?.email ?? "-"
                }
              />
            </CustomField>
            <CustomField>
              <SubFormKey>{t("task:add.common.receive")}</SubFormKey>
              <Controller
                control={control}
                name="taskWorkplaceEmployeeInfos.1.transmissionMethod"
                render={({ field }) => {
                  return (
                    <StyledRadioGroup
                      {...field}
                      options={[
                        { label: t("common:all"), value: "ALL" },
                        { label: t("common:email.en"), value: "EMAIL" },
                        { label: t("common:sms.en"), value: "SMS" },
                      ]}
                    />
                  );
                }}
              />
            </CustomField>
          </ManagerInfoContainer>
        </ManagersInfoContainer>
      </SectionCard>
    );
  };

  // 운송 정보
  const renderTransportInformation = () => {
    return (
      <HalfSectionCard
        cardTitle={t("task:add.common.transportInformation")}
        cardContentContainerStyle={cardContentContainerStyle}
      >
        <StyledFormItem
          label={t("task:add.common.transportDate")}
          type="date"
          name="transportDateAt"
          rules={{ required: true }}
          control={control as any}
          errorsMessage={{
            required: t("error:required"),
          }}
          inputProps={{
            showTime: true,
            showNow: false,
            changeOnBlur: true,
            placeholder: "YYYY-MM-DD HH:mm",
            format: "YYYY-MM-DD HH:mm",
            minuteStep: 10,
            disabled: isSameAsWorkingDay,
            value: watch("transportDateAt")
              ? dayjs(watch("transportDateAt"))
              : undefined,
            onChange: (date: Dayjs | null) => {
              if (!date) return null;
              const min = Math.floor(date.minute() / 10);
              const transformedMin = min * 10;

              setValue(
                "transportDateAt",
                dayjs(
                  date.format(`YYYY-MM-DD HH:${transformedMin}:00`),
                  `YYYY-MM-DD HH:mm:00`
                ).toISOString()
              );
            },
          }}
          bottomAccessory={
            <StyledLabel>
              <Checkbox
                checked={isSameAsWorkingDay}
                disabled={!watch("workingDayAt")}
                onChange={(val) => {
                  setIsSameAsWorkingDay((prev) => !prev);
                  const isChecked = val.target.checked;
                  if (isChecked) {
                    setValue("transportDateAt", watch("workingDayAt"));
                    clearErrors("transportDateAt");
                  }
                }}
              />
              <Typo typoType="b7r">
                {t("task:add.common.sameAsWorkingDay")}
              </Typo>
            </StyledLabel>
          }
        />
        <StyledFormItem
          label={t("task:add.common.transportRemark")}
          type="textarea"
          name="transportRemark"
          control={control as any}
          inputProps={{
            maxLength: 500,
            placeholder: t("task:add.placeholder.transportRemark"),
          }}
        />
      </HalfSectionCard>
    );
  };

  const renderContainerLoadingRequestRemark = () => {
    return (
      <HalfSectionCard cardTitle={t("task:add.common.loadingRequestRemark")}>
        <FormItem
          type="textarea"
          name="containerTaskRequestRemark"
          control={control}
          inputProps={{
            style: { width: "100%" },
            placeholder: t("task:add.placeholder.loadingRequestRemark"),
            maxLength: 1000,
          }}
          noLabel
        />
      </HalfSectionCard>
    );
  };

  const renderMemo = () => {
    return (
      <SectionCard
        cardTitle={t("task:add.common.memo")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <MemoHalfContainer>
          {simpleTaskMemos.length ? (
            <MemoContainer>
              <MemoContent>
                {simpleTaskMemos.map((item, idx) => {
                  return (
                    <MemoItem key={idx.toString()}>
                      <MemoLabelContainer>
                        <Typo color="gray3" typoType="h9">
                          {item.writerName}
                        </Typo>
                        <Typo color="gray7" typoType="b9r">
                          {`(${dayjs(item.wroteAt).format("YY-MM-DD")}, ${dayjs(
                            item.wroteAt
                          ).format("HH:mm")})`}
                        </Typo>
                      </MemoLabelContainer>

                      <ReadOnlyText typoType="b7r" color="gray5">
                        {item.memo}
                      </ReadOnlyText>
                    </MemoItem>
                  );
                })}
              </MemoContent>
            </MemoContainer>
          ) : (
            <MemoPlaceholder>
              <Icon iconSrc={NoDataBagSvg} iconSize={56} />
              <Typo as="p" typoType="b7m" color="gray8">
                {t("task:add.common.noData")}
              </Typo>
            </MemoPlaceholder>
          )}
        </MemoHalfContainer>
        <MemoHalfContainer>
          <MemoInputContainer>
            <WarningDescription style={{ color: colorSet.gray6 }}>
              <StyledIcon iconSrc={InfoGray6Svg} iconSize={16} />
              {t("task:add.memoInfo")}
            </WarningDescription>
            <FormItem
              type="textarea"
              name="memo"
              control={control}
              inputProps={{
                style: { width: "100%", height: "163px" },
                placeholder: t("task:add.placeholder.memo"),
                maxLength: 500,
              }}
              noLabel
            />
          </MemoInputContainer>
        </MemoHalfContainer>
      </SectionCard>
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;

    if (dialogState === DialogState.BOOKING)
      return (
        <LoadBookingDialog
          open
          onClose={() => setDialogState(DialogState.NULL)}
          onSelect={(detail) => {
            if (detail) {
              setValue("bookingId", detail.id);
              if (contractDetail) {
                setContractDetail(null);
              }
              setBookingDetail(detail);

              /** 기존 선택한 부킹과 새로 선택한 부킹이 다를 때만 */
              if (bookingDetail && bookingDetail.id !== detail.id) {
                const candidate = editTaskPageDefaultValue;
                /** 계약 초기화 */
                setContractDetail(null);
                setValue("contractId", candidate["contractId"]);
                /** 작업지와 작업 담당자 초기화 */
                setValue("workplaceId", candidate["workplaceId"]);
                setValue(
                  "taskWorkplaceEmployeeInfos",
                  candidate["taskWorkplaceEmployeeInfos"]
                );
              }
            }
          }}
          defaultSelectedItemId={bookingDetail?.id}
          maxContainer={watch().numberOfTaskContainer}
        />
      );
    if (dialogState === DialogState.CONTRACT) {
      if (!bookingDetail) return null;
      return (
        <LoadContractDialog
          open
          onClose={() => setDialogState(DialogState.NULL)}
          onSelect={async (detail) => {
            try {
              if (detail) {
                setValue("contractId", detail.id);
                setContractDetail(detail);

                await getBuyerDetail({
                  id: detail.buyerId,
                }).unwrap();
              }
            } catch (e) {
            } finally {
              /** 기존 선택한 계약과 새로 선택한 계약이 다를 때만 */
              if (detail && contractDetail && detail.id !== contractDetail.id) {
                const candidate = editTaskPageDefaultValue;
                /** 작업지와 작업 담당자 초기화 */
                setValue("workplaceId", candidate["workplaceId"]);
                setValue(
                  "taskWorkplaceEmployeeInfos",
                  candidate["taskWorkplaceEmployeeInfos"]
                );
              }
            }
          }}
          bookingId={bookingDetail.id}
          defaultSelectedItemId={contractDetail?.id}
        />
      );
    }

    if (dialogState === DialogState.ADD_NEW_CONTACT_PERSON) {
      return (
        <AddNewContactPersonDialog
          onClose={() => setDialogState(DialogState.NULL)}
          factoryId={Number(watch("workplaceId"))}
          factoryName={
            factoryList.find(
              ({ workplaceListQueryResultDto }) =>
                workplaceListQueryResultDto.id.toString() ===
                watch("workplaceId")
            )?.workplaceName ?? ""
          }
          defaultCountryCodeItemName={
            factoryList.find(
              ({ workplaceListQueryResultDto }) =>
                workplaceListQueryResultDto.id.toString() ===
                watch("workplaceId")
            )?.workplaceListQueryResultDto.countryCodeItemName ?? ""
          }
          defaultCountryCodePrefix={
            factoryList.find(
              ({ workplaceListQueryResultDto }) =>
                workplaceListQueryResultDto.id.toString() ===
                watch("workplaceId")
            )?.workplaceListQueryResultDto.telPrefix ?? ""
          }
        />
      );
    }
    return null;
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t("task:add.alertDialog.backToPrevious.title")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={() => navigate(-1)}
          okText={t("task:add.common.ok")}
          cancelText={t("task:add.common.exit")}
        >
          {t("task:add.alertDialog.backToPrevious.description")}
        </AlertDialog>
      );
    }
  };

  const renderUnauthorizedDescription = () => {
    if (isLoadingUnstable || isItemCodeUnstable) {
      return;
    }

    return (
      <NoDataContainer>
        <UnauthorizedDescription />
      </NoDataContainer>
    );
  };

  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        handleContentLoadingOn();
        const {
          contractId,
          bookingId,
          simpleTaskWorkplaceEmployees,
          workingDayAt,
          numberOfTaskContainer,
          taskCode,
          workplaceId,
          transportDateAt,
          transportRemark,
          containerTaskRequestRemark,
          extraMediaRemark,
          simpleTaskMemos,
          buyerId,
          mediaCoordinateRange,
          ...restResponse
        } = await getLoadingDetail({
          id: Number(params.id),
        }).unwrap();

        await getBuyerDetail({ id: buyerId });

        const bookingListResponse = await getBookingList({ bookingId });
        if (bookingListResponse.data) {
          setBookingDetail(
            bookingListResponse.data.rows.find(
              ({ id }) => id === bookingId
            ) as TaskBookingListViewDto
          );
        }
        const contractListResponse = await getContractList({ id: bookingId });
        if (contractListResponse.data) {
          setContractDetail(
            contractListResponse.data.rows.find(
              ({ id }) => id === contractId
            ) as BookingContractListViewDto
          );
        }

        await getFactoryEmployees({ id: workplaceId });

        const loadingWorkplaceEmployee = [
          ...simpleTaskWorkplaceEmployees,
          {
            transmissionMethod: "ALL",
            workplaceEmployeeId: undefined,
          },
          {
            transmissionMethod: "ALL",
            workplaceEmployeeId: undefined,
          },
        ]
          .map(({ transmissionMethod, workplaceEmployeeId }) => ({
            transmissionMethod,
            workplaceEmployeeId: workplaceEmployeeId?.toString() ?? undefined,
          }))
          .filter((_, idx) => idx < 2);

        const transformContainerCount = Object.entries(
          photoTypeMap
        ).reduce<any>((acc, [key, formKey]) => {
          const numberOfPhotoType = restResponse[key as PhotoTypeContainerKey];

          let resource: Record<
            string,
            {
              count: number;
              isUse: boolean;
              remark?: string | null;
            }
          > = {
            [formKey]: {
              count: numberOfPhotoType === 0 ? 1 : numberOfPhotoType,
              isUse: numberOfPhotoType !== 0,
            },
          };

          if (key === "containerExtraMediasCount") {
            resource = {
              [formKey]: {
                ...resource[formKey],
                remark: numberOfPhotoType === 0 ? "" : extraMediaRemark,
              },
            };
          }

          return { ...acc, ...resource };
        }, {});
        setExcludedManagerId(
          loadingWorkplaceEmployee.map(({ workplaceEmployeeId }) =>
            workplaceEmployeeId?.toString()
          )
        );
        reset({
          bookingId,
          contractId,
          workplaceId: workplaceId.toString(),
          workingDayAt,
          workEstimatedTime:
            isNull(restResponse.workEstimatedMinute) ||
            isUndefined(restResponse.workEstimatedMinute)
              ? undefined
              : dayjs()
                  .startOf("day")
                  .add(
                    Math.floor(restResponse.workEstimatedMinute / 60),
                    "hour"
                  )
                  .add(restResponse.workEstimatedMinute % 60, "minutes"),
          numberOfTaskContainer,
          taskCode,
          transportDateAt,
          taskWorkplaceEmployeeInfos: loadingWorkplaceEmployee,
          containerTaskRequestRemark,
          transportRemark,
          mediaCoordinateRange,
          ...(transformContainerCount as any),
        });
      } catch (e: any) {
        const error = e as ErrorResponse;
        const message = Array.isArray(error.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (error.status === 404) {
          return;
        } else {
          alert.showAlert({ type: "error", message });
        }
      } finally {
        handleContentLoadingOff();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await getItemCodes({ itemCode: undefined });
    })();
  }, [getItemCodes]);

  // 계약 상세조회
  useEffect(() => {
    if (!contractDetail) {
      return;
    }
    (async () => {
      try {
        await getContractDetail({ id: contractDetail.id });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    })();
  }, [alert, contractDetail, getContractDetail]);

  return (
    <ExporterMainLayout
      breadcrumb={
        !isLoadingFetchingError && isMainCategoryAuthorized
          ? [t("sideNav:loading"), t("sideNav:loadingEdit")]
          : []
      }
      pageTitle={
        !isLoadingFetchingError &&
        isMainCategoryAuthorized &&
        t("sideNav:loadingEdit")
      }
    >
      {isMainCategoryUnauthorized || isLoadingFetchingError ? (
        renderUnauthorizedDescription()
      ) : (
        <form onSubmit={handleSubmit(handleEditLoading)}>
          <FlexGapSection>
            <FlexHalfContainer>
              {renderBookingSelection()}
              {renderContractSelection()}
            </FlexHalfContainer>
            {renderLoadingInformation()}
            {renderPhotoInformation()}
            {renderSendLoadingRequest()}
            <FlexHalfContainer>
              {renderTransportInformation()}
              {renderContainerLoadingRequestRemark()}
            </FlexHalfContainer>
            {renderMemo()}
          </FlexGapSection>

          <BottomFixedContainer>
            <FooterButtonSection>
              <Button
                buttonGrade="tertiary"
                buttonColor="black"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() =>
                  setAlertDialogState(AlertDialogState.BACK_TO_PREVIOUS)
                }
              >
                <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
                {t("task:add.buttonLabel.backToPrevious")}
              </Button>

              <SaveButtonContainer>
                <Button
                  buttonGrade="primary"
                  style={{ width: 158 }}
                  type="submit"
                  disabled={!isEditAuth}
                >
                  {t("task:add.buttonLabel.save")}
                </Button>
              </SaveButtonContainer>
            </FooterButtonSection>
          </BottomFixedContainer>
        </form>
      )}

      {renderDialog()}
      {renderAlertDialog()}
    </ExporterMainLayout>
  );
};

export default TaskEditPage;

const FlexHalfContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const HalfSectionCard = styled(SectionCard)`
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
`;

const RequiredSectionCardHeader = styled.span`
  ${typo.h6};
  display: flex;
  align-items: center;
  gap: 4px;

  strong {
    color: ${colorSet.red2};
  }
`;

const FlexCenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexColumnCenterDirection = styled(FlexCenterDiv)`
  flex-direction: column;
`;

const SelectionCardContent = styled(FlexColumnCenterDirection)`
  gap: 16px;
  text-align: center;
`;

const FlexGapSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const FormItemContainer = styled.div`
  width: calc(50% - 12px);
`;

const FormItemsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

const RequestPhotoTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PhotoTypeCounterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  strong {
    color: ${colorSet.red2};
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledCheckboxCountField = styled(CheckboxCountField)`
  overflow: auto;
  align-self: flex-start;
`;

const CustomField = styled.div`
  display: flex;
  gap: 8px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    &[data-responsive="true"] {
      flex-direction: column;
    }
  }
`;

const FormKey = styled.p`
  ${typo.b7m};
  color: ${colorSet.gray2};
  width: 164px;
  flex-shrink: 0;

  strong {
    color: ${colorSet.red2};
  }
`;

const SubFormKey = styled(FormKey)`
  ${typo.b9r};
  color: ${colorSet.gray6};
`;

const HalfInputContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const CustomFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-width: 0;
`;

const WarningDescription = styled.p`
  ${typo.b9r};
  color: ${colorSet.gray4};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledSelect = styled(Select)`
  flex: 1;
`;

const ManagersInfoContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

const ManagerInfoContainer = styled.div`
  width: calc(50% - 12px);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  gap: 6px;
`;

const StyledFormItem = styled(FormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const MemoContainer = styled.div`
  padding: 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const MemoHalfContainer = styled.div`
  flex: 1;
`;

const MemoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const MemoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const BookingDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PreWrap = styled.span`
  white-space: pre-wrap;
`;

const StyledTextButton = styled(TextButton)`
  color: ${colorSet.blue4};
  ${typo.h7}
`;

const ChooseAgainButton = styled(Button)`
  margin-left: auto;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: flex-start;
  cursor: pointer;
`;

const InformationBoardBox = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid orange;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray10};
  background: ${colorSet.gray11};
  margin-bottom: 24px;
`;

const InformationItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  word-break: break-word;
  text-align: center;
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 188px;
  ${StyledScroll}
  width: 100%;
`;

const MemoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MemoLabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
`;

const TimePickerSuffixIcon = styled.span<{ url: string }>`
  display: inline-block;
  width: 20px;
  height: 20px;

  ${({ url }) => css`
    background: url(${url});
  `};
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAntdRadioGroup = styled(AntdRadio.Group)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MapScopeContainer = styled(Flex)`
  width: 100%;
  max-width: 512px;
`;

const MapScope = styled.div<{ backgroundImage: string }>`
  width: 100%;
  max-width: 512px;
  height: 200px;
  background: ${({ backgroundImage }) => `url(${backgroundImage})`} no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};
  cursor: pointer;
  position: relative;
  overflow: hidden;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    width: 100%;
    height: 200px;
  }
`;
