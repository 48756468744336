import React, { useEffect, useRef, useState } from "react";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import SectionCard from "@/src/components/molecule/SectionCard";
import styled from "styled-components";
import { Button } from "@/src/components/atom/Button";
import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-white.svg";
import colorSet from "@/src/styles/color";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import Icon from "@/src/components/atom/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CallOut from "@/src/components/molecule/CallOut";
import Typo from "@/src/components/atom/Typo";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { MediaDto } from "@/src/store/apis/media/interface";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Label from "@/src/components/atom/Label";
import { isUndefined } from "@/src/utils/is";
import AddBlueSvg from "@/src/assets/icons/icon-add-blue.svg";
import SelectOptionButton from "@/src/components/atom/Button/SelectOptionButton";
import { mediaQuery } from "@/src/styles/mediaQuery";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import {
  useCreateSingleItemCodeMutation,
  useGetItemCodeListQuery,
} from "@/src/store/apis/corporate/companyItemManagement";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { OptionType } from "./components/dialog/AddSalesItemDialog";
import Loader from "@/src/components/atom/Loader";
import Input from "@/src/components/atom/Input";
import AddItemDialog, {
  AddItemType,
} from "@/components/organism/AddItemDialog";
import useAlert from "@/src/hooks/useAlert";
import Select from "@/src/components/atom/Select";
import SearchSelect from "@/src/components/atom/Select/SearchSelect";
import Flex from "@/src/components/molecule/Flex";
import {
  useCreateFinalProductMutation,
  useEditSaleMutation,
  useGetFinalProductsQuery,
  useGetSaleQuery,
  useGetSalesWithWarehousingsQuery,
} from "@/src/store/apis/sales";
import {
  useCreateWarehouseEmployeesMutation,
  useGetWarehousesQuery,
  useLazyGetWarehouseEmployeesQuery,
} from "@/src/store/apis/warehouse";
import { FinalProductDto } from "@/src/store/apis/sales/interface";
import {
  WarehouseDto,
  WarehouseEmployeeDto,
} from "@/src/store/apis/warehouse/interface";
import dayjs, { Dayjs } from "dayjs";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import ContactPersonAddDialog, {
  TransformContactPersonAddModalFormType,
} from "../WarehouseManagement/dialog/ContactPersonAddDialog";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import { StyledScroll } from "@/src/styles/scroll";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/src/store";

enum DialogState {
  NULL,
  ADD_WAREHOUSE_PERSON,
  ADD_ITEM,
}

enum AlertDialogState {
  NULL,
  PREVIOUS,
  DUPLICATION_ERROR,
}

type GroupType = {
  label: string;
  options: OptionType[];
};

const finalProductEmptyArray: FinalProductDto[] = [];
const WarehouseEmptyArray: WarehouseDto[] = [];
const WarehouseEmployeesEmptyArray: WarehouseEmployeeDto[] = [];
const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const ImporterSalesEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const params = useParams();
  const user = useAppSelector((state) => state.auth.user);
  const hiddenButtonRef = useRef<HTMLButtonElement>(null);

  // API
  const { warehousingsList } = useGetSalesWithWarehousingsQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          warehousingsList: isStable ? currentData.rows : [],
        };
      },
    }
  );
  const [editSale, { isLoading: isEditSaleLoading }] = useEditSaleMutation();
  const [createFinalProduct, { isLoading: isCreateFinalProductLoading }] =
    useCreateFinalProductMutation();
  const { finalProducts, isFinalProductsFetching } = useGetFinalProductsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFinalProductsFetching: isFetching,
          finalProducts: isStable ? currentData.rows : finalProductEmptyArray,
        };
      },
    }
  );

  const [createEmployee] = useCreateWarehouseEmployeesMutation();
  const { warehouses, isWarehousesFetching } = useGetWarehousesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isWarehousesFetching: isFetching,
          warehouses: isStable ? currentData.rows : WarehouseEmptyArray,
        };
      },
    }
  );
  const [
    getWarehouseEmployees,
    { warehouseEmployees, isWarehouseEmployeesFetching },
  ] = useLazyGetWarehouseEmployeesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        isWarehouseEmployeesFetching: isFetching,
        warehouseEmployees: isStable
          ? currentData.rows
          : WarehouseEmployeesEmptyArray,
      };
    },
  });

  const {
    salesNumber,
    warehouseId,
    warehouseEmployeeId,
    finalProductIdList,
    salesMediaId,
    orderDateAt,
    exporterItemCodeId,
    exporterItemId,
    itemCode,
    itemName,
    quantity,
    quantityUnit,
    quantityUnitCodeItemName,
    unitPriceUnitCodeItemName,
    unitPrice,
    subCategoryCodeItemName,
    mainCategoryCodeItemName,
    salesMemos,
  } = useGetSaleQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          finalProductIdList: currentData?.salesFinalProducts,
          orderDateAt: isStable ? currentData.orderDateAt : "",
          salesNumber: isStable ? currentData.salesNumber : "-",
          warehouseId: isStable ? currentData.warehouse.id.toString() : "",
          warehouseEmployeeId: isStable
            ? currentData.warehouseEmployee?.id.toString()
            : "",
          salesMediaId: currentData?.salesMedia,
          exporterItemCodeId: currentData?.exporterItemCode?.id,
          itemCode: isStable ? currentData.exporterItemCode?.itemCode : "-",
          exporterItemId: isStable ? currentData.exporterItem?.id : "-",
          itemName: isStable ? currentData.exporterItem?.item : "-",
          quantity: isStable ? currentData.quantity : "-",
          quantityUnit: isStable ? currentData.quantityUnit : "-",
          quantityUnitCodeItemName: isStable
            ? currentData.quantityUnitCodeItemName
            : "-",
          unitPrice: isStable ? currentData.unitPrice : "-",
          unitPriceUnitCodeItemName: isStable
            ? currentData.unitPriceUnitCodeItemName
            : "-",
          subCategoryCodeItemName:
            currentData?.exporterItem?.subCategoryCodeItemName,
          mainCategoryCodeItemName:
            currentData?.exporterItem?.mainCategoryCodeItemName,
          salesMemos: isStable ? currentData.salesMemos : [],
        };
      },
    }
  );

  const [createSingleItemCode] = useCreateSingleItemCodeMutation();
  const { isItemFetching, itemList } = useGetItemCodeListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isItemFetching: isFetching,
          itemList: isStable ? currentData?.rows : [],
        };
      },
    }
  );
  const {
    currentData: measurementList,
    isFetching: isMeasurementListFetching,
  } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MEASUREMENT",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    currentData: currencyUnitList,
    isFetching: isCurrencyUnitListFetching,
  } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "CURRENCY",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { mainCategoryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          mainCategoryList: isStable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );
  const { subCategoryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "SUB_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          subCategoryList: isStable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );

  const subCategory =
    subCategoryList.find(
      (item) => item.codeItemName === subCategoryCodeItemName
    )?.codeItemNameEn || "";
  const mainCategory =
    mainCategoryList.find(
      (item) => item.codeItemName === mainCategoryCodeItemName
    )?.codeItemNameEn || "";

  const measurementListToOptionList = measurementList
    ? measurementList.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: val.codeItemNameEn,
          value: val.codeItemName,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const currencyUnitListToOptionList = currencyUnitList
    ? currencyUnitList.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: val.codeItemNameEn,
          value: val.codeItemName,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const itemMappingList = itemList
    .filter((item) =>
      user?.exporterUserType === "MANAGER"
        ? user?.mainCategoryCodeItemNames.includes(
            item.mainCategoryCodeItemName
          )
        : true
    )
    .reduce((acc: GroupType[], item) => {
      // 그룹 라벨 생성
      const groupLabel = `${item.mainCategory} > ${item.subCategory}`;

      // 그룹이 이미 존재하는지 확인
      let group = acc.find((g) => g.label === groupLabel);
      if (!group) {
        group = { label: groupLabel, options: [] };
        acc.push(group);
      }

      // 옵션 레이블 생성
      const isItemCodeSameAsItem = item.item === item.itemCode;
      const optionLabel = isItemCodeSameAsItem
        ? `${item.item}`
        : `${item.item}(${item.itemCode})`;

      // 옵션 추가
      group.options.push({
        label: optionLabel,
        value: `${item.id}`,
      });

      return acc;
    }, []);

  const finalProductOptionList = finalProducts
    ? finalProducts.map(({ name, id }) => {
        return { label: name, value: id.toString() };
      })
    : [];

  const warehousesOptionList = warehouses.map(({ warehouseName, id }) => {
    return { label: warehouseName, value: id.toString() };
  });
  const warehouseEmployeeList = warehouseEmployees.map(({ name, id }) => {
    return { label: name, value: id.toString() };
  });

  const isShipmentLink = warehousingsList.length >= 1;

  // State
  const [directInputOpen, setDirectInputOpen] = useState(false);
  const [dialogState, setDialogState] = useState(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState(
    AlertDialogState.NULL
  );

  const {
    watch,
    control,
    setValue,
    setFocus,
    getValues,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    salesNumber: string;
    orderDateAt?: Dayjs;
    salesMediaId?: MediaDto[] | null;
    warehouseId?: string;
    warehouseEmployeeId?: string;
    finalProductIdList: string[];
    finalProductDirectInput: string;
    isFinalProductDirectInput: boolean;
    memo: string;

    exporterItemId?: string;
    exporterItemCodeId?: string;
    itemName?: string;
    itemCode?: string;
    subCategory?: string;
    mainCategory?: string;
    quantity?: string;
    quantityUnit?: string;
    quantityUnitCodeItemName?: string;
    unitPrice?: string;
    unitPriceUnit?: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      salesNumber,
      orderDateAt: dayjs(orderDateAt),
      salesMediaId: salesMediaId ? [salesMediaId] : null,
      warehouseId,
      warehouseEmployeeId,
      finalProductIdList: finalProductIdList?.map((item) =>
        item.finalProduct.id.toString()
      ),
      finalProductDirectInput: "",
      isFinalProductDirectInput: false,
      memo: "",

      exporterItemId: exporterItemId?.toString(),
      exporterItemCodeId: exporterItemCodeId?.toString(),
      itemName,
      itemCode,
      subCategory: subCategory,
      mainCategory: mainCategory,
      quantity: Number(quantity).toLocaleString("ko-KR"),
      quantityUnit,
      quantityUnitCodeItemName,
      unitPrice: Number(unitPrice).toLocaleString("ko-KR"),
      unitPriceUnit: unitPriceUnitCodeItemName,
    },
  });

  const handleSaveClick = async () => {
    const saveParams = {
      id: Number(params.id),
      salesNumber: getValues("salesNumber"),
      orderDateAt: dayjs(
        dayjs(getValues("orderDateAt")).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
      ).toISOString(),
      memo: getValues("memo") || undefined,
      finalProductIdList: getValues("finalProductIdList").map(
        (finalProductId) => Number(finalProductId)
      ),
      warehouseId: Number(getValues("warehouseId")),
      warehouseEmployeeId: Number(getValues("warehouseEmployeeId")),
      salesMediaId: getValues("salesMediaId")?.[0].id || null,
      itemInformationDto: {
        quantity: Number(getValues("quantity")?.replace(/,/g, "")) || 0,
        quantityUnit: getValues("quantityUnit") || "",
        quantityUnitCodeItemName: getValues("quantityUnitCodeItemName") || "",
        unitPrice: Number(getValues("unitPrice")?.replace(/,/g, "")) || 0,
        unitPriceUnit: getValues("unitPriceUnit") || "",
        unitPriceUnitCodeItemName: getValues("unitPriceUnit") || "",
        exporterItemId: Number(getValues("exporterItemId")),
        exporterItemCodeId: Number(getValues("exporterItemCodeId")),
      },
    };

    try {
      const res = await editSale(saveParams).unwrap();

      if (res) {
        alert.showAlert({
          type: "success",
          message: t("alert:saveSuccess"),
        });
        navigate(`${IMPORTER_PRIVATE_PATH.SALES_DETAIL}/${res.row.id}`);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const getAmount = () => {
    if (watch("quantity") && watch("unitPrice") && watch("unitPriceUnit")) {
      return `${(
        Number(watch("quantity")?.replace(/,/g, "")) *
        Number(watch("unitPrice")?.replace(/,/g, ""))
      ).toLocaleString("ko-KR")} ${watch("unitPriceUnit")}`;
    } else {
      return `0 ${watch("unitPriceUnit")}`;
    }
  };

  const getAmountMethod = () => {
    const qtyUnitData =
      measurementList &&
      measurementList.find(
        (item) => item.codeItemName === watch("quantityUnit")
      );

    return `${watch("quantity") ?? "0"} ${
      qtyUnitData?.codeItemNameEn ?? ""
    } X ${watch("unitPrice") ?? "0"} ${watch("unitPriceUnit")}`;
  };

  const handleWarehouseClick = async (id: number) => {
    try {
      await getWarehouseEmployees({ id }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddItems = async ({
    item,
    exporterItemCodeList,
    subCategoryCodeItemName,
    mainCategoryCodeItemName,
  }: AddItemType) => {
    try {
      const { row } = await createSingleItemCode({
        item: item,
        itemCode: exporterItemCodeList[0].itemCode,
        subCategoryCodeItemName: subCategoryCodeItemName,
        mainCategoryCodeItemName: mainCategoryCodeItemName,
      }).unwrap();

      if (row) {
        setValue("exporterItemCodeId", `${row.id}`);
        setValue("mainCategory", row.mainCategory ?? "");
        setValue("subCategory", row.subCategory ?? "");
        setValue("itemName", row.item ?? "");
        setValue("itemCode", row.itemCode ?? "");

        alert.showAlert({
          type: "success",
          message: t("alert:saveSuccess"),
        });
        setDialogState(DialogState.NULL);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddFinalProduct = async () => {
    try {
      const res = await createFinalProduct({
        name: getValues("finalProductDirectInput"),
      }).unwrap();

      if (res) {
        const currentFinalIdList = watch("finalProductIdList");

        setValue("finalProductDirectInput", "");
        setValue("finalProductIdList", [
          ...currentFinalIdList,
          res.row.id.toString(),
        ]);
        setFocus("finalProductDirectInput");
        clearErrors("finalProductIdList");
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleCreateEmployee = async (
    values: TransformContactPersonAddModalFormType
  ) => {
    if (!getValues("warehouseId")) {
      return;
    }

    try {
      const res = await createEmployee({
        id: Number(getValues("warehouseId")),
        isActivated: values.isActivated,
        name: values.name,
        email: values.email,
        personalContactPrefix: values.personalContactPrefix,
        personalContact: values.personalContact,
        officeContactPrefix: values.officeContactPrefix
          ? values.officeContactPrefix
          : undefined,
        officeContact: values.officeContact ? values.officeContact : undefined,
      }).unwrap();

      setDialogState(DialogState.NULL);
      setValue("warehouseEmployeeId", res.row.id.toString());
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialog = () => {
    // 입고지 담당자 추가
    if (dialogState === DialogState.ADD_WAREHOUSE_PERSON) {
      return (
        <ContactPersonAddDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={handleCreateEmployee}
        />
      );
    }

    // 아이템 추가
    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddItemDialog
          open={dialogState === DialogState.ADD_ITEM}
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={handleAddItems}
          isSingleItemCode
        />
      );
    }
  };

  const renderAlertDialog = () => {
    // 이전 화면 이동
    if (alertDialogState === AlertDialogState.PREVIOUS) {
      return (
        <AlertDialog
          title={t("common:backToPrevious")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("common:cancel")}
          okText={t("common:ok")}
          onOk={() => navigate(-1)}
        >
          {t("common:alertDialog.backToPreviousDescription")}
        </AlertDialog>
      );
    }

    // 중복 발주 넘버가 있을경우
    if (alertDialogState === AlertDialogState.DUPLICATION_ERROR) {
      return (
        <AlertDialog
          title={t("sales:common.alertDialog.contractNoDuplicate.title")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          okText={t("common:ok")}
          cancelText=""
          onOk={() => setAlertDialogState(AlertDialogState.NULL)}
        >
          {t("sales:common.alertDialog.contractNoDuplicate.description")}
        </AlertDialog>
      );
    }
  };

  useEffect(() => {
    setFocus("salesNumber");
  }, [setFocus]);

  useEffect(() => {
    handleWarehouseClick(Number(warehouseId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:sales"), t("sideNav:editSales")]}
      pageTitle={t("sideNav:editSales")}
    >
      <Form onSubmit={handleSubmit(handleSaveClick)}>
        {/* Basic Information */}
        <SectionCard cardTitle={t("sales:common.basicInformation")}>
          <Flex gap={24}>
            <Flex gap={24} flexDirection="column" flex={1}>
              <StyledFormItem
                label={t("sales:add.loadContractsTable.salesNumber")}
                type="text"
                name="salesNumber"
                control={control as any}
                rules={{ required: true }}
                inputProps={{
                  placeholder: t("sales:common.contractNoPlaceholder"),
                }}
                errorsMessage={{
                  required: t("error:required"),
                }}
              />
              <CustomFormItemRow className="flex-0">
                <>
                  <Label required>{t("sales:common.warehouse")}</Label>
                  <Controller
                    name="warehouseId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Flex flexDirection="column" gap={8} flex={1}>
                          <StyledSelect
                            {...field}
                            value={field.value || undefined}
                            style={{ flex: 1, width: "100%" }}
                            placeholder={t(
                              "sales:common.warehouseSelectPlaceholder"
                            )}
                            filterOption={(input, option) =>
                              ((option?.label as string) ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onSelect={(warehouseId) => {
                              handleWarehouseClick(Number(warehouseId));
                              setValue("warehouseEmployeeId", undefined);
                              clearErrors("warehouseId");
                            }}
                            options={warehousesOptionList}
                            showSearch
                            suffixIcon={
                              isWarehousesFetching ? <Loader /> : undefined
                            }
                            disabled={isShipmentLink}
                          />

                          <CustomFormItemRow>
                            <StyledLabel required>
                              <Typo typoType="b9r" color="gray6">
                                {t("sales:common.warehouseContact")}
                              </Typo>
                            </StyledLabel>
                            <Flex flex={1} alignItems="baseline">
                              <Controller
                                name="warehouseEmployeeId"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <StyledSelect
                                      {...field}
                                      value={
                                        isWarehouseEmployeesFetching
                                          ? undefined
                                          : field.value || undefined
                                      }
                                      style={{ flex: 1, width: 1 }}
                                      placeholder={t(
                                        "sales:common.warehouseContactPlaceholder"
                                      )}
                                      filterOption={(input, option) =>
                                        ((option?.label as string) ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      disabled={
                                        isUndefined(watch("warehouseId")) ===
                                          true || isShipmentLink
                                      }
                                      options={warehouseEmployeeList}
                                      showSearch
                                      suffixIcon={
                                        isWarehouseEmployeesFetching ? (
                                          <Loader />
                                        ) : undefined
                                      }
                                      dropdownRender={(menu) => {
                                        return (
                                          <>
                                            <DirectInputSelectOptionButton
                                              onClick={() => {
                                                setDialogState(
                                                  DialogState.ADD_WAREHOUSE_PERSON
                                                );
                                              }}
                                            >
                                              {t("common:directInput")}
                                            </DirectInputSelectOptionButton>
                                            {(warehouseEmployeeList?.length ??
                                              0) > 0 && <div>{menu}</div>}
                                          </>
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Flex>
                          </CustomFormItemRow>
                        </Flex>
                      );
                    }}
                  />
                </>
              </CustomFormItemRow>
            </Flex>

            <Flex gap={24} flexDirection="column" flex={1}>
              <StyledSectionCardRow
                label={
                  <Label required>
                    <Typo typoType="b7m">{t("sales:common.finalProduct")}</Typo>
                  </Label>
                }
                value={
                  <Flex flexDirection="column" gap={8}>
                    <StyledFormItem
                      type="multipleSelect"
                      name="finalProductIdList"
                      control={control as any}
                      rules={{ required: true }}
                      inputProps={{
                        placeholder: t(
                          "sales:common.finalProductSelectPlaceholder"
                        ),
                        suffixIcon: isFinalProductsFetching ? (
                          <Loader />
                        ) : undefined,
                        onRemoveItem: (value) => {
                          const watchCategory = watch("finalProductIdList");
                          const watchCategoryFilter = watchCategory.filter(
                            (item) => item !== value
                          );
                          setValue("finalProductIdList", watchCategoryFilter);
                        },
                        open: directInputOpen,
                        onDropdownVisibleChange: (visible) =>
                          setDirectInputOpen(visible),
                        dropdownRender: (menu) => {
                          return (
                            <>
                              <DirectInputSelectOptionButton
                                onClick={() => {
                                  setDirectInputOpen(false);
                                  setValue("isFinalProductDirectInput", true);
                                  setFocus("isFinalProductDirectInput");
                                }}
                              >
                                {t("common:directInput")}
                              </DirectInputSelectOptionButton>
                              {(finalProductOptionList?.length ?? 0) > 0 && (
                                <div>{menu}</div>
                              )}
                            </>
                          );
                        },
                      }}
                      options={finalProductOptionList}
                      errorsMessage={{
                        required: t("error:required"),
                      }}
                    />
                    {watch("isFinalProductDirectInput") && (
                      <Flex gap={8} flex={1}>
                        <StyledFormItem
                          type="text"
                          name="finalProductDirectInput"
                          control={control as any}
                          className="direct-input"
                          inputProps={{
                            placeholder: t(
                              "sales:common.finalProductPlaceholder"
                            ),
                          }}
                          errorsMessage={{
                            required: t("error:required"),
                          }}
                        />
                        <StyledButton
                          buttonColor="black"
                          buttonGrade="tertiary"
                          onClick={handleAddFinalProduct}
                          isLoading={isCreateFinalProductLoading}
                          disabled={
                            watch("finalProductDirectInput").length === 0 ||
                            isCreateFinalProductLoading
                          }
                        >
                          <AddIcon
                            data-disabled={
                              watch("finalProductDirectInput").length === 0 ||
                              isCreateFinalProductLoading
                            }
                          />
                          {t("sales:common.button.addFinalProduct")}
                        </StyledButton>
                      </Flex>
                    )}
                  </Flex>
                }
              />

              <StyledFormItem
                label={t("common:orderDate")}
                type="date"
                name="orderDateAt"
                control={control as any}
                rules={{ required: true }}
                inputProps={{
                  placeholder: DATE_FORMAT_STRINGS.YYYY_MM_DD,
                  allowClear: true,
                }}
                errorsMessage={{
                  required: t("error:required"),
                }}
              />
              <StyledFormItem
                label={t("sales:common.file")}
                type="file"
                name="salesMediaId"
                control={control as any}
                inputProps={{
                  defaultFileList: watch("salesMediaId")?.length
                    ? [
                        new File(
                          [""],
                          watch("salesMediaId")?.[0]?.originalFileName ?? ""
                        ),
                      ]
                    : undefined,
                  onRemove: () => setValue("salesMediaId", undefined),
                }}
              />
            </Flex>
          </Flex>
        </SectionCard>

        {/* Item Information */}
        <SectionCard cardTitle={t("sales:common.itemInformation")}>
          <Flex gap={24}>
            <CustomFormItemRow>
              <>
                <Label>{t("sales:common.item")}</Label>
                <Controller
                  name="exporterItemCodeId"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Flex flexDirection="column" gap={8} flex={1}>
                        <Flex gap={8} flex={1}>
                          <StyledSelect
                            {...field}
                            value={field.value || undefined}
                            style={{ flex: 1, width: 1 }}
                            placeholder={t("placeholder:searchItemCode")}
                            filterOption={(input, option) =>
                              ((option?.label as string) ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={itemMappingList}
                            showSearch
                            suffixIcon={isItemFetching ? <Loader /> : undefined}
                            onChange={(exporterItemId) => {
                              const findItem = itemList.find(
                                ({ id }) => id === Number(exporterItemId)
                              );

                              if (findItem) {
                                setValue(
                                  "exporterItemId",
                                  `${findItem.exporterItemId}`
                                );
                                setValue("itemCode", findItem.itemCode);
                                setValue("itemName", findItem.item);
                                setValue("subCategory", findItem.subCategory);
                                setValue("mainCategory", findItem.mainCategory);
                              }
                            }}
                            disabled={isShipmentLink}
                          />
                          <StyledButton
                            buttonColor="black"
                            buttonGrade="tertiary"
                            onClick={() => setDialogState(DialogState.ADD_ITEM)}
                            disabled={isShipmentLink}
                          >
                            <AddIcon data-disabled={isShipmentLink} />
                            {t("sales:common.button.addItem")}
                          </StyledButton>
                        </Flex>

                        <CustomFormItemRow>
                          <StyledTypo typoType="b9r" color="gray6">
                            {t("sales:common.itemCode")}
                          </StyledTypo>
                          <StyledInput
                            disabled
                            value={watch("itemCode")}
                            placeholder={t("common:itemCode")}
                          />
                        </CustomFormItemRow>

                        <CustomFormItemRow>
                          <StyledTypo typoType="b9r" color="gray6">
                            {t("sales:common.item")}
                          </StyledTypo>
                          <StyledInput
                            disabled
                            value={watch("itemName")}
                            placeholder={t("common:item")}
                          />
                        </CustomFormItemRow>

                        <CustomFormItemRow>
                          <StyledTypo typoType="b9r" color="gray6">
                            {t("sales:common.subCategory")}
                          </StyledTypo>
                          <StyledInput
                            disabled
                            value={watch("subCategory")}
                            placeholder={t("common:subCategory")}
                          />
                        </CustomFormItemRow>

                        <CustomFormItemRow>
                          <StyledTypo typoType="b9r" color="gray6">
                            {t("sales:common.mainCategory")}
                          </StyledTypo>
                          <StyledInput
                            disabled
                            value={watch("mainCategory")}
                            placeholder={t("common:mainCategory")}
                          />
                        </CustomFormItemRow>
                      </Flex>
                    );
                  }}
                />
              </>
            </CustomFormItemRow>

            <Flex flexDirection="column" gap={8} flex={1}>
              <CustomFormItemRow className="flex-zero">
                <Label required>
                  <Typo typoType="b7m">{t("common:amount")}</Typo>
                </Label>
                <StyledInput
                  disabled
                  value={`${getAmount()} (${getAmountMethod()})`}
                />
              </CustomFormItemRow>

              <CustomFormItemRow className="flex-zero">
                <Label required>
                  <Typo typoType="b7m">{t("common:qty")}</Typo>
                </Label>
                <Flex flexDirection="column" gap={8} isFullWidth>
                  <Flex gap={8} flex={1}>
                    <Controller
                      name="quantityUnit"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <StyledSearchSelect
                          {...field}
                          options={measurementListToOptionList}
                          suffixIcon={
                            isMeasurementListFetching ? <Loader /> : undefined
                          }
                          onSelect={(quantityUnit) => {
                            const quantityUnitCodeItemName =
                              measurementListToOptionList.find(
                                (item) => item.value === quantityUnit
                              )?.label;

                            setValue("quantityUnit", quantityUnit);
                            setValue(
                              "quantityUnitCodeItemName",
                              quantityUnitCodeItemName
                            );
                          }}
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentElement;
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="quantity"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <StyledInput
                          {...field}
                          placeholder={t("placeholder:enterQty")}
                          data-invalid={!!errors.quantity}
                          onChange={(e) => {
                            setValue(
                              "quantity",
                              Number(
                                e.target.value.replace(/[^0-9]/g, "")
                              ).toLocaleString("ko-KR")
                            );
                          }}
                        />
                      )}
                    />
                  </Flex>

                  {errors.quantity && (
                    <InputError message={t("error:required")} />
                  )}
                </Flex>
              </CustomFormItemRow>

              <CustomFormItemRow className="flex-zero">
                <Label required>
                  <Typo typoType="b7m">{t("common:unitPrice")}</Typo>
                </Label>
                <Flex flexDirection="column" gap={8} isFullWidth>
                  <Flex gap={8} flex={1}>
                    <Controller
                      name="unitPriceUnit"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <StyledSearchSelect
                          {...field}
                          options={currencyUnitListToOptionList}
                          suffixIcon={
                            isCurrencyUnitListFetching ? <Loader /> : undefined
                          }
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentElement;
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="unitPrice"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <StyledInput
                          {...field}
                          data-invalid={!!errors.unitPrice}
                          placeholder={t("placeholder:unitPrice")}
                          onChange={(e) =>
                            setValue(
                              "unitPrice",
                              Number(
                                e.target.value.replace(/[^0-9]/g, "")
                              ).toLocaleString("ko-KR")
                            )
                          }
                        />
                      )}
                    />
                  </Flex>

                  {errors.unitPrice && (
                    <InputError message={t("error:required")} />
                  )}
                </Flex>
              </CustomFormItemRow>
            </Flex>
          </Flex>
        </SectionCard>

        {/* Memo */}
        <StyledSectionCard
          cardTitle={t("sales:common.memo")}
          className="bottom-margin"
        >
          <Flex gap={24}>
            <Flex flex={1}>
              <StyledCallOut backgroundColor="white">
                <MemoContent>
                  {salesMemos?.length >= 1 ? (
                    salesMemos.map(({ createdAt, exporterUser, memo }, idx) => {
                      return (
                        <Flex
                          flexDirection="column"
                          gap={8}
                          key={idx.toString()}
                        >
                          <Flex
                            alignItems="center"
                            gap={4}
                            flex={1}
                            isFullWidth
                          >
                            <Typo color="gray3" typoType="h9">
                              {exporterUser.name}
                            </Typo>
                            <Typo color="gray7" typoType="b9r">
                              {`(${dayjs(createdAt).format(
                                "YY-MM-DD"
                              )}, ${dayjs(createdAt).format("HH:mm")})`}
                            </Typo>
                          </Flex>

                          <ReadOnlyText typoType="b7r" color="gray6">
                            {memo}
                          </ReadOnlyText>
                        </Flex>
                      );
                    })
                  ) : (
                    <MemoNoDataContainer>
                      {renderNoRowsComponent()}
                    </MemoNoDataContainer>
                  )}
                </MemoContent>
              </StyledCallOut>
            </Flex>

            <MemoContainer gap={16} flexDirection="column" flex={1}>
              <Flex gap={6} alignItems="center">
                <StyledIcon iconSrc={InfoGray6Svg} />
                <Typo color="gray6" typoType="b9r">
                  {t("sales:common.memoInfo")}
                </Typo>
              </Flex>

              <FormItem
                type="textarea"
                name="memo"
                control={control as any}
                inputProps={{
                  style: { width: "100%", height: "228px" },
                  placeholder: t("placeholder:memo"),
                  maxLength: 500,
                }}
              />
            </MemoContainer>
          </Flex>
        </StyledSectionCard>

        <Hidden
          type="submit"
          ref={hiddenButtonRef}
          disabled={isEditSaleLoading}
        />
      </Form>

      {/* Bottom Fixed */}
      <BottomFixedContainer>
        <BottomButtonContainer>
          <StyledButton
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => setAlertDialogState(AlertDialogState.PREVIOUS)}
          >
            <Icon iconSrc={ChevronLeft} iconSize={16} />
            {t("sales:common.button.backToPreviousButton")}
          </StyledButton>

          <figure>
            <StyledButton
              className="save-button"
              isLoading={isEditSaleLoading}
              disabled={isEditSaleLoading}
              onClick={() => hiddenButtonRef.current?.click()}
            >
              {t("sales:common.button.save")}
            </StyledButton>
          </figure>
        </BottomButtonContainer>
      </BottomFixedContainer>

      {renderDialog()}
      {renderAlertDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterSalesEditPage;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AddIcon = styled(AddSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.gray2};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    display: flex;
    gap: 8px;

    .save-button {
      width: 158px;
      justify-content: center;
    }

    .temporary-save {
      width: 136px;
      justify-content: center;
    }
  }
`;

const StyledCallOut = styled(CallOut)`
  flex: 1;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  & > p {
    width: 100%;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column !important;
  }
`;

const StyledFormItem = styled(FormItem)`
  &.direct-input {
    flex: 1;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }
`;

const DirectInputSelectOptionButton = styled(SelectOptionButton)`
  color: ${colorSet.blue4};

  &:hover {
    & {
      color: ${colorSet.blue4};
    }
  }

  &::before {
    content: url(${AddBlueSvg});
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
    top: 4px;
  }
`;

const StyledLabel = styled(Label)`
  min-width: 0;
`;

const StyledSelect = styled(Select)`
  width: 100%;

  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }

  .ant-select-selection-placeholder {
    width: 1px;
  }
`;

const CustomFormItemRow = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;

  &.flex-0 {
    flex: 0;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }

  &.flex-zero {
    flex: 0;
  }
`;

const StyledTypo = styled(Typo)`
  min-width: 104px;

  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSearchSelect = styled(SearchSelect)`
  max-width: 108px;
`;

const StyledSectionCard = styled(SectionCard)`
  &.bottom-margin {
    margin-bottom: 72px;
  }
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 252px;
  width: 100%;
  ${StyledScroll}
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const MemoContainer = styled(Flex)`
  height: 100%;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
