import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/src/components/atom/Typo";
import useAlert from "@/src/hooks/useAlert";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import TextArea from "@/src/components/atom/TextArea";
import Icon from "@/src/components/atom/Icon";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { useCreateShipmentMemoMutation } from "@/src/store/apis/shipments/shipmentDetail";
import colorSet from "@/src/styles/color";
import { InputError } from "@/src/components/molecule/FormItem";
import { useTranslation } from "react-i18next";

interface MemoDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

function MemoDialog({ open, onOpenChange }: MemoDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);
  const [createMemo] = useCreateShipmentMemoMutation();
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    memo: string;
  }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      memo: "",
    },
  });

  const handleCreateMemo = async (memo: string) => {
    try {
      await createMemo({
        memo,
        id: Number(params.id),
      }).unwrap();
      onOpenChange(false);
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  useEffect(() => {
    setFocus("memo");
  }, [setFocus]);

  return (
    <Dialog
      title={t("shipment:exporter.detail.label.memo")}
      width={496}
      open={open}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
            ref={cancelButtonRef}
            onKeyUp={(e) => {
              if (e.shiftKey && e.code === "Tab") {
                setFocus("memo");
              }
            }}
          >
            <Typo typoType="btn3m">
              {t("shipment:exporter.detail.button.cancelButton")}
            </Typo>
          </Button>
          <Button
            onClick={() => {
              submitButtonRef.current?.click();
            }}
          >
            <Typo typoType="btn3m" color="white">
              {t("shipment:exporter.detail.button.saveButton")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onOpenChange(false)}
        />
      )}

      <Form onSubmit={handleSubmit((value) => handleCreateMemo(value.memo))}>
        <MemoDescription>
          <StyeldIcon iconSrc={InfoSvg} />
          <Typo typoType="b9r" color="gray6">
            {t("shipment:exporter.detail.description.memo")}
          </Typo>
        </MemoDescription>

        <Memo>
          <Controller
            control={control}
            name="memo"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <StyeldTextArea
                {...field}
                placeholder={t("placeholder:memo")}
                containerStyle={
                  errors.memo
                    ? {
                        outline: `1px solid ${colorSet.red2}`,
                      }
                    : undefined
                }
              />
            )}
          />
          {errors.memo && <InputError message={t("error:required")} />}
        </Memo>

        <Hidden
          type="submit"
          ref={submitButtonRef}
          onFocus={() => {
            cancelButtonRef.current?.focus();
          }}
        />
      </Form>
    </Dialog>
  );
}

export default MemoDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MemoDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyeldIcon = styled(Icon)`
  flex-shrink: 0;
`;

const Memo = styled.div`
  padding-bottom: 130px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyeldTextArea = styled(TextArea)`
  width: 100%;
  min-height: 218px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
