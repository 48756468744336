import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store";
import { useSignOutMutation } from "./store/apis/auth";
import useAlert from "./hooks/useAlert";
import { persistor } from ".";
import {
  setLoginExtensionTime,
  setIsAutoLogoutDialogOpen,
  resetLoginExtensionTime,
} from "./store/slice/auth";
import Dialog from "./components/atom/Dialog";
import { Button } from "./components/atom/Button";
import styled from "styled-components";
import Typo from "./components/atom/Typo";
import PUBLIC_PATH from "./routes/public/path";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "./components/atom/Dialog/DialogFooterContainer";

const RESET_MIN = 63;

const AutoLogout = () => {
  const { t } = useTranslation();
  const token = useAppSelector((state) => state.auth.token);
  const sec = useAppSelector((state) => state.auth.loginExtensionTime);
  const isLogoutDialogOpen = useAppSelector(
    (state) => state.auth.isAutoLogoutDialogOpen
  );
  const intervalId = useRef<NodeJS.Timer>();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const [signOut] = useSignOutMutation();
  const [logoutRestSec, setLogoutRestSec] = useState<number>(sec);

  const logout = useCallback(async () => {
    try {
      await signOut().unwrap;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    } finally {
      persistor.purge();
      window.location.replace(PUBLIC_PATH.LOGIN);
      dispatch(setIsAutoLogoutDialogOpen(false));
      dispatch(resetLoginExtensionTime());
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearIntervalId = () => {
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  };

  useEffect(() => {
    const reset = () => {
      if (logoutRestSec < 60 * 3) return;
      dispatch(setLoginExtensionTime(60 * RESET_MIN));
      setLogoutRestSec(60 * RESET_MIN);
      clearIntervalId();
      intervalId.current = setInterval(() => {
        setLogoutRestSec((prev) => prev - 1);
      }, 1000);
    };

    if (token) {
      window.addEventListener("mouseover", reset);
      window.addEventListener("scroll", reset);
      window.addEventListener("keydown", reset);
    }
    return () => {
      window.removeEventListener("mouseover", reset);
      window.removeEventListener("scroll", reset);
      window.removeEventListener("keydown", reset);
    };
  }, [dispatch, logoutRestSec, token]);

  useEffect(() => {
    if (!token) return;

    intervalId.current = setInterval(() => {
      setLogoutRestSec((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [token]);

  useEffect(() => {
    if (!token) return;

    dispatch(setLoginExtensionTime(logoutRestSec));
  }, [dispatch, logoutRestSec, token]);

  useEffect(() => {
    if (logoutRestSec === 60 * 3) {
      dispatch(setIsAutoLogoutDialogOpen(true));
    }

    if (logoutRestSec === 0 && token) {
      logout();
      alert.showAlert({
        message: t("alert:sessionTimeout"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, logout, logoutRestSec, token]);

  if (!token) return null;

  return (
    <>
      {isLogoutDialogOpen && (
        <Dialog
          isCloseButton={false}
          title={t("common:extensionLogin")}
          footer={
            <DialogFooterContainer>
              <Button
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={40}
                onClick={logout}
              >
                {t("common:logout")}
              </Button>
              <Button
                onClick={() => {
                  dispatch(setIsAutoLogoutDialogOpen(false));
                  dispatch(setLoginExtensionTime(60 * RESET_MIN));
                  setLogoutRestSec(60 * RESET_MIN);
                  clearIntervalId();
                  intervalId.current = setInterval(() => {
                    setLogoutRestSec((prev) => prev - 1);
                  }, 1000);
                }}
              >
                {t("common:extension")}
              </Button>
            </DialogFooterContainer>
          }
          open
          width={496}
          destroyDialogWhenEscapePress={false}
          onOpenChange={() => {}}
        >
          <ContentDiv>
            <Typo typoType="b7r" color="gray2">
              {t("common:autoLogoutPrivacyProtection")}
            </Typo>
            <Typo typoType="b7r" color="gray2">
              {t("common:autoLogoutWarning")}{" "}
              <Typo color="red2" typoType="h7">
                3
              </Typo>{" "}
              {t("common:autoLogoutAfterMinutes")}
            </Typo>
          </ContentDiv>
        </Dialog>
      )}
    </>
  );
};

export default AutoLogout;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
