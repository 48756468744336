import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@/src/components/atom/Dialog";
import { ConfigProvider, Image, ImageProps } from "antd";
import zIndex from "@/src/styles/zIndex";
import { ContainerMediaData } from "../ViewPhotoCollectionDialog";
import Flex from "../../molecule/Flex";
import dayjs from "dayjs";
import styled, { css } from "styled-components";
import colorSet from "@/src/styles/color";
import GoogleMap from "@/src/components/atom/GoogleMap";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";

interface LoadingPhotoPreviewDialogProps {
  open: boolean;
  onOpenChange: () => void;
  selectImage: ContainerMediaData | null;
  antdImageProps?: ImageProps;
  photoDetailRightAccessory?: ReactNode;
  mediaCoordinateRange?: MediaCoordinateRangeType;
  zoomLevel?: number;
}

export const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

function LoadingPhotoPreviewDialog({
  open,
  onOpenChange,
  selectImage,
  antdImageProps,
  photoDetailRightAccessory,
  mediaCoordinateRange,
  zoomLevel,
}: LoadingPhotoPreviewDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("task:detail.dialog.photoPreview")}
      open={open}
      width={640}
      onOpenChange={() => {
        onOpenChange();
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Image: {
              zIndexPopup: zIndex.antdImage,
              zIndexPopupBase: zIndex.antdImageBase,
            },
          },
        }}
      >
        <Image
          style={{ objectFit: "contain" }}
          src={selectImage?.mediaUrl}
          width={592}
          height={395}
          {...antdImageProps}
        />
      </ConfigProvider>

      <StyledSectionCardRow
        label={t("task:detail.label.photoDetail")}
        value={
          <Flex justifyContent="space-between">
            {dayjs(selectImage?.createAt).format("YYYY-MM-DD HH:mm")}
            {photoDetailRightAccessory && (
              <div>{photoDetailRightAccessory}</div>
            )}
          </Flex>
        }
      />

      {selectImage?.latitude && selectImage?.longitude && (
        <>
          <Divider gap={24} />
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{
              lat: Number(selectImage?.latitude),
              lng: Number(selectImage?.longitude),
            }}
            zoomLevel={zoomLevel}
            mediaCoordinateRange={mediaCoordinateRange}
            marker={[
              {
                position: {
                  lat: Number(selectImage.latitude),
                  lng: Number(selectImage.longitude),
                },
              },
            ]}
          />
        </>
      )}
    </Dialog>
  );
}

export default LoadingPhotoPreviewDialog;

const Divider = styled.div<{ gap?: number }>`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
  ${({ gap }) =>
    gap &&
    css`
      margin: ${gap}px 0;
    `};
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  padding-top: 24px;
  p {
    width: 100%;
  }
`;
