import { styled } from "styled-components";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import { ReactComponent as EditSvg } from "@/src/assets/icons/icon-edit-pencel.svg";
import colorSet from "@/src/styles/color";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import Badge from "@/src/components/atom/Badge";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { ExporterUserType } from "@/src/store/apis/auth/interface";
import { useState } from "react";
import WithdrawDialog from "./dialog/WithdrawDialog";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import Icon from "@/src/components/atom/Icon";
import { useGetStaffsQuery } from "@/src/store/apis/corporate/staffManagement";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { LANGUAGE_LIST } from "@/src/constant/optionList";

export const getExporterUserType = (type: ExporterUserType, t: TFunction) => {
  if (type === "CORPORATE_MANAGER") {
    return t("myPage:exporter.optionValue.corporateManager");
  }
  if (type === "MIDDLE_MANAGER") {
    return t("myPage:exporter.optionValue.middleManager");
  }
  if (type === "MANAGER") {
    return t("myPage:exporter.optionValue.manager");
  }
};

enum DialogState {
  NULL,
  WITHDRAW,
}

const ExporterMypage = () => {
  const { t } = useTranslation();
  const {
    name,
    email,
    companyName,
    personalContact,
    officeContact,
    category,
    isMarketingEmailReceive,
    exporterUserType,
    notification,
    isMfaOn,
    language,
  } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      const notificationStrings = !isUnstable
        ? [
            currentData.row.account.notificationEmailReceive
              ? t("common:email.en")
              : "",
            currentData.row.account.notificationSmsReceive
              ? t("common:sms.en")
              : "",
            currentData.row.account.notificationWebPushReceive
              ? t("common:webPush.en")
              : "",
          ]
            .filter(Boolean)
            .join(", ") || "-"
        : "-";

      return {
        name: !isUnstable ? currentData?.row.name : "-",
        email: !isUnstable ? currentData?.row.email : "-",
        companyName: !isUnstable ? currentData?.row.exporter.companyName : "-",
        personalContact:
          !isUnstable &&
          currentData?.row?.personalContact &&
          currentData?.row?.personalContactPrefix
            ? `${currentData?.row?.personalContactPrefix} ${currentData?.row?.personalContact}`
            : "-",
        officeContact:
          !isUnstable &&
          currentData?.row?.officeContact &&
          currentData?.row?.officeContactPrefix
            ? `${currentData?.row?.officeContactPrefix} ${currentData?.row?.officeContact}`
            : "-",
        category: !isUnstable
          ? currentData?.row.mainCategoryCodeItemNames.join(", ")
          : "-",
        isMarketingEmailReceive: !isUnstable
          ? currentData?.row.account.isMarketingEmailReceive
          : false,
        exporterUserType: !isUnstable
          ? getExporterUserType(currentData.row.exporterUserType, t)
          : "-",
        notification: notificationStrings,
        isMfaOn: !isUnstable ? currentData?.row.account.isMfaOn : isUnstable,
        language: currentData?.row.account.language,
      };
    },
  });

  // API
  useGetStaffsQuery(
    {
      isActivated: true,
      exporterUserType: "CORPORATE_MANAGER",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const navigate = useNavigate();

  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  const informationSet = [
    { label: t("myPage:exporter.label.name"), value: name },
    { label: t("myPage:exporter.label.id"), value: email },
    {
      label: t("myPage:exporter.label.affiliatedCompany"),
      value: companyName,
    },
    {
      label: t("myPage:exporter.label.authority"),
      value: exporterUserType,
    },
    { label: t("myPage:exporter.label.password"), value: "••••••••" },
    {
      label: t("myPage:exporter.label.contactPersonal"),
      value: personalContact,
    },
    {
      label: t("myPage:exporter.label.contactOffice"),
      value: officeContact,
    },
  ];

  const informationRightSet = [
    {
      label: t("myPage:exporter.label.assignedCategory"),
      value: category,
    },
    {
      label: t("myPage:exporter.label.notification"),
      value: notification,
    },
    {
      label: t("myPage:exporter.label.agreeToReceiveEmail"),
      value: isMarketingEmailReceive ? (
        <FlexCenter>
          <Badge
            badgeColor="systemLime5"
            color="systemLime1"
            badgeSize="S"
            text={t("myPage:exporter.optionValue.agree")}
          />
        </FlexCenter>
      ) : (
        <FlexCenter>
          <Badge
            badgeColor="gray10"
            color="gray7"
            badgeSize="S"
            text={t("myPage:exporter.optionValue.disagree")}
          />
        </FlexCenter>
      ),
    },
    {
      label: t("myPage:exporter.label.twoFactorAuthentication"),
      value: isMfaOn ? (
        <FlexCenter>
          <Badge
            badgeColor="systemLime5"
            color="systemLime1"
            badgeSize="S"
            text="ON"
          />
        </FlexCenter>
      ) : (
        <FlexCenter>
          <Badge badgeColor="gray10" color="gray7" badgeSize="S" text="OFF" />
        </FlexCenter>
      ),
    },
    {
      label: t("common:languageSetting"),
      value: t(
        LANGUAGE_LIST.find((item) => item.value === language)?.label || ""
      ),
    },
  ];

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) {
      return null;
    }

    if (dialogState === DialogState.WITHDRAW) {
      return (
        <WithdrawDialog
          open={dialogState === DialogState.WITHDRAW}
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:myPage")]}
      customPageTitle={
        <>
          <StyledHeader>
            {
              <Typo typoType="h1" as="h1" color="gray1">
                {t("sideNav:myPage")}
              </Typo>
            }

            <StyledButton
              buttonSize={32}
              onClick={() => navigate(EXPORTER_PRIVATE_PATH.MY_PAGE_EDIT)}
            >
              <EditIcon />
              {t("myPage:exporter.edit")}
            </StyledButton>
          </StyledHeader>
        </>
      }
    >
      <MypageLayout>
        <SectionCard cardTitle={t("myPage:exporter.label.information")}>
          <FlexBox>
            <HalfBox>
              {informationSet.map(({ label, value }) => {
                return <SectionCardRow label={label} value={value} />;
              })}
            </HalfBox>

            <GrayLine />

            <HalfBox>
              {informationRightSet.map(({ label, value }) => {
                return <SectionCardRow label={label} value={value} />;
              })}
            </HalfBox>
          </FlexBox>
        </SectionCard>

        <WithdrawButtonContainer>
          <WithdrawButton
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={32}
            onClick={() => setDialogState(DialogState.WITHDRAW)}
          >
            {t("myPage:exporter.button.withdraw")}
          </WithdrawButton>
        </WithdrawButtonContainer>
      </MypageLayout>

      {/* Bottom Fixed */}
      <BottomFixedContainer>
        <PreviousButton
          buttonColor="black"
          buttonGrade="tertiary"
          onClick={() => navigate(-1)}
        >
          <Icon iconSrc={ChevronLeft} iconSize={16} />
          {t("myPage:exporter.button.backToPreviousButton")}
        </PreviousButton>
      </BottomFixedContainer>

      {renderDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterMypage;

const MypageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const WithdrawButton = styled(StyledButton)`
  margin-left: auto;
`;
const PreviousButton = styled(StyledButton)`
  gap: 4px;
`;

const EditIcon = styled(EditSvg)`
  path {
    fill: ${colorSet.white};
  }
`;

const FlexBox = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

const HalfBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
  gap: 24px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const WithdrawButtonContainer = styled.div``;

const GrayLine = styled.div`
  border-right: 1px solid ${colorSet.gray9};
`;
