import {
  createContext,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from "react";
import ImportMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import Flex from "@/src/components/molecule/Flex";
import Typo from "@/src/components/atom/Typo";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import CurrencySelect from "./components/CurrencySelect";
import DocumentSvg from "@/src/assets/icons/icon-document-blue2.svg";
import BoxSvg from "@/src/assets/icons/icon-box-blue2.svg";
import ShipSvg from "@/src/assets/icons/icon-ship-blue2.svg";
import StatisticsCard from "./components/StatisticsCard";
import { StyledHorizontalScroll } from "@/src/styles/scroll";
import colorSet from "@/src/styles/color";
import ShipmentStatus from "./components/ShipmentStatus";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import TradingVolumeStatistics from "./components/TradingVolumeStatistics";
import ImportedVolumeTrend from "./components/ImportedVolumeTrend";
import PurchaseSalesStatistics from "./components/PurchaseSalesStatistics";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { isNull, isUndefined } from "@/src/utils/is";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import DashboardFilter from "./components/DashboardFilter";
import Select from "@/src/components/atom/Select";
import RangePicker from "@/src/components/atom/RangePicker";
import typo from "@/src/styles/typography";
import {
  useGetImporterDomainCurrentSituationQuery,
  useLazyGetCategoryStatisticQuery,
  useLazyGetImporterShipmentTotalStatisticQuery,
  useLazyGetPurchasesAndSalesStatisticQuery,
  useLazyGetSellerAndItemRankStatisticQuery,
  useLazyGetTodayImporterShipmentTotalStatisticQuery,
  useLazyGetYesterdayImporterShipmentTotalStatisticQuery,
} from "@/src/store/apis/dashboard/importer";
import useAlert from "@/src/hooks/useAlert";
import SearchSelect from "@/src/components/atom/Select/SearchSelect";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import Loader from "@/src/components/atom/Loader";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";
import { useAppDispatch, useAppSelector } from "@/src/store";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { closeLinkageRequestAlertDialog } from "@/src/store/slice/webUtil";

type TabType = "shipmentBoard" | "statistics";
const TAB_LIST: {
  id: TabType;
  langKey: string;
}[] = [
  {
    id: "shipmentBoard",
    langKey: "dashboard:importer.tabs.shipmentBoard",
  },
  {
    id: "statistics",
    langKey: "dashboard:importer.tabs.statistics",
  },
];

export type ShipmentBoardFilterType = {
  seller: string;
  mainCategoryCodeItemName: string;
};
export type StatisticsFilterType = {
  ataAt: [Dayjs | null, Dayjs | null] | null;
  mainCategoryCodeItemName: string;
};

const initialShipmentFilter = {
  seller: "all",
  mainCategoryCodeItemName: "all",
};
const initialStatisticsFilter = {
  ataAt: [dayjs().subtract(11, "month"), dayjs()] as
    | [Dayjs | null, Dayjs | null],
  mainCategoryCodeItemName: "all",
};

export const StatisticsFilterRootContext = createContext<
  StatisticsFilterType | undefined
>(undefined);

export type TradingVolumeStatisticsRootContextType = {
  selectSellerRankIndex: number;
  onSellerRankIndexChange: Dispatch<SetStateAction<number>>;
  selectItemRankIndex: number;
  onItemRankIndexChange: Dispatch<SetStateAction<number>>;
};
export const TradingVolumeStatisticsRootContext =
  createContext<TradingVolumeStatisticsRootContextType | null>(null);

const today = dayjs();
const startOfThisWeek = today.startOf("week");
const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];
const THIRTY_ONE = 31;

const ImporterDashboardPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const rangePickerRef = useRef<{
    blur: () => void;
    focus: () => void;
  }>(null);

  const isLinkageRequestAlertDialogOpen = useAppSelector(
    (state) => state.webUtil.isLinkageRequestAlertDialogOpen
  );

  // State
  const [tab, setTab] = useState<TabType>("shipmentBoard");
  const [shipmentBoardFilter, setShipmentBoardFilter] =
    useState<ShipmentBoardFilterType>(initialShipmentFilter);
  const [statisticsFilter, setStatisticsFilter] =
    useState<StatisticsFilterType>(initialStatisticsFilter);

  // weekly
  const [weekIndex, setWeekIndex] = useState(0);
  const [weeklyShipmentDate, setWeeklyShipmentDate] = useState([
    startOfThisWeek,
    startOfThisWeek.add(6, "day"),
  ]);
  const [selectWeeklyDay, setSelectWeeklyDay] = useState({
    value: startOfThisWeek,
    day: startOfThisWeek.format(DATE_FORMAT_STRINGS.dddd).toLocaleLowerCase(),
  });

  // statistics;
  const [selectSellerRankIndex, setSelectSellerRankIndex] = useState(0);
  const [selectItemRankIndex, setSelectItemRankIndex] = useState(0);
  const [selectCurrency, setSelectCurrency] = useState("USD");

  // API
  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { mainCategoryCurrentData, isMainCategoryFetching } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);

          return {
            isMainCategoryFetching: isFetching,
            mainCategoryCurrentData: !isUnstable
              ? currentData ?? mainCategoryEmptyArray
              : mainCategoryEmptyArray,
          };
        },
      }
    );
  const { buyers, isBuyersFetching } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isBuyersFetching: isFetching,
          buyers: isStable ? currentData?.data || [] : [],
        };
      },
    }
  );

  useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MEASUREMENT",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  // Shipment API
  const [getWeeklyShipmentStatistic] =
    useLazyGetImporterShipmentTotalStatisticQuery();
  const [getTodayShipmentStatistic] =
    useLazyGetTodayImporterShipmentTotalStatisticQuery();
  const [getYesterdayShipmentStatistic] =
    useLazyGetYesterdayImporterShipmentTotalStatisticQuery();
  // Statistic API
  const [getCategoryStatistic] = useLazyGetCategoryStatisticQuery();
  const [getSellerAndItemRankStatistic] =
    useLazyGetSellerAndItemRankStatisticQuery();
  const [getPurchasesAndSalesStatistic] =
    useLazyGetPurchasesAndSalesStatisticQuery();

  const {
    totalNumberOfContract,
    signNeeded,
    pending,
    notLinked,
    totalNumberOfShipment,
    blUpdate,
    blUpdatedShipmentIdAndBlNoList,
    totalNumberOfWarehousing,
    wareHouseAtNotPassed,
    needWareHouseAt,
  } = useGetImporterDomainCurrentSituationQuery(
    {
      blSharedAtFrom: dayjs().startOf("day").toISOString(),
      blSharedAtTo: dayjs().endOf("day").toISOString(),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          totalNumberOfContract: isStable
            ? currentData?.importerContractStatusInfoDto
                ?.totalNumberOfContract ?? 0
            : 0,
          signNeeded: isStable
            ? currentData?.importerContractStatusInfoDto?.signNeeded ?? 0
            : 0,
          pending: isStable
            ? currentData?.importerContractStatusInfoDto?.pending ?? 0
            : 0,
          notLinked: isStable
            ? currentData?.importerContractStatusInfoDto?.notLinked ?? 0
            : 0,
          totalNumberOfShipment: isStable
            ? currentData?.importerShipmentStatusInfoDto
                ?.totalNumberOfShipment ?? 0
            : 0,
          blUpdate: isStable
            ? currentData?.importerShipmentStatusInfoDto?.blUpdate ?? 0
            : 0,
          blUpdatedShipmentIdAndBlNoList: isStable
            ? currentData?.importerShipmentStatusInfoDto
                ?.blUpdatedShipmentIdAndBlNoList ?? []
            : [],
          totalNumberOfWarehousing: isStable
            ? currentData?.importerWarehousingStatusInfoDto
                ?.totalNumberOfWarehousing ?? 0
            : 0,
          wareHouseAtNotPassed: isStable
            ? currentData?.importerWarehousingStatusInfoDto
                ?.wareHouseAtNotPassed ?? 0
            : 0,
          needWareHouseAt: isStable
            ? currentData?.importerWarehousingStatusInfoDto?.needWareHouseAt ??
              0
            : 0,
        };
      },
    }
  );

  const allOptionItem = {
    label: t("dashboard:importer.placeholder.all"),
    value: "all",
  };

  const mainCategoryFilterList = mainCategoryCurrentData
    ?.filter((item) =>
      session.currentData?.row.exporter.mainCategoryCodeItemNames.includes(
        item.codeItemName
      )
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });

  // Submit
  const getTotalShipment = async () => {
    try {
      await getTodayShipmentStatistic({
        importerMainCategories:
          shipmentBoardFilter.mainCategoryCodeItemName !== "all"
            ? shipmentBoardFilter.mainCategoryCodeItemName
            : undefined,
        seller:
          shipmentBoardFilter.seller !== "all"
            ? shipmentBoardFilter.seller
            : undefined,
      }).unwrap();

      await getYesterdayShipmentStatistic({
        importerMainCategories:
          shipmentBoardFilter.mainCategoryCodeItemName !== "all"
            ? shipmentBoardFilter.mainCategoryCodeItemName
            : undefined,
        seller:
          shipmentBoardFilter.seller !== "all"
            ? shipmentBoardFilter.seller
            : undefined,
      }).unwrap();

      await getWeeklyShipmentStatistic({
        etaAtFrom: dayjs(weeklyShipmentDate[0])
          .subtract(1, "day")
          .startOf("day")
          .toISOString(),
        etaAtTo: dayjs(weeklyShipmentDate[1])
          .subtract(1, "day")
          .endOf("day")
          .toISOString(),
        importerMainCategories:
          shipmentBoardFilter.mainCategoryCodeItemName !== "all"
            ? shipmentBoardFilter.mainCategoryCodeItemName
            : undefined,
        seller:
          shipmentBoardFilter.seller !== "all"
            ? shipmentBoardFilter.seller
            : undefined,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const getSellerAndItemRanks = async () => {
    if (!statisticsFilter.ataAt) {
      return;
    }

    try {
      await getSellerAndItemRankStatistic({
        ataAtFrom: dayjs(statisticsFilter.ataAt[0])
          .startOf("day")
          .toISOString(),
        ataAtTo: dayjs(statisticsFilter.ataAt[1]).endOf("day").toISOString(),
        importerMainCategories:
          statisticsFilter.mainCategoryCodeItemName !== "all"
            ? statisticsFilter.mainCategoryCodeItemName
            : undefined,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const getCategoryStatisticData = async () => {
    if (!statisticsFilter.ataAt) {
      return;
    }

    try {
      await getCategoryStatistic({
        ataAtFrom: dayjs(statisticsFilter.ataAt[0])
          .startOf("day")
          .toISOString(),
        ataAtTo: dayjs(statisticsFilter.ataAt[1]).endOf("day").toISOString(),
        importerMainCategories:
          statisticsFilter.mainCategoryCodeItemName !== "all"
            ? statisticsFilter.mainCategoryCodeItemName
            : undefined,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const getPurchasesAndSalesStatisticData = async () => {
    if (!statisticsFilter.ataAt) {
      return;
    }

    try {
      await getPurchasesAndSalesStatistic({
        ataAtFrom: dayjs(statisticsFilter.ataAt[0])
          .startOf("day")
          .toISOString(),
        ataAtTo: dayjs(statisticsFilter.ataAt[1]).endOf("day").toISOString(),
        importerMainCategories:
          statisticsFilter.mainCategoryCodeItemName !== "all"
            ? statisticsFilter.mainCategoryCodeItemName
            : undefined,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  // Reset
  const resetWeeklyTotalShipment = async () => {
    try {
      await getWeeklyShipmentStatistic({
        etaAtFrom: dayjs(weeklyShipmentDate[0])
          .subtract(1, "day")
          .startOf("day")
          .toISOString(),
        etaAtTo: dayjs(weeklyShipmentDate[1])
          .subtract(1, "day")
          .endOf("day")
          .toISOString(),
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const resetTodayTotalShipment = async () => {
    try {
      await getTodayShipmentStatistic({}).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const resetYesterdayTotalShipment = async () => {
    try {
      await getYesterdayShipmentStatistic({}).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const resetSellerAndItemRankStatistic = async () => {
    try {
      await getSellerAndItemRankStatistic({
        ataAtFrom: dayjs(initialStatisticsFilter.ataAt[0])
          .startOf("day")
          .toISOString(),
        ataAtTo: dayjs(initialStatisticsFilter.ataAt[1])
          .endOf("day")
          .toISOString(),
      }).unwrap();

      setSelectSellerRankIndex(0);
      setSelectItemRankIndex(0);
      setStatisticsFilter(initialStatisticsFilter);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const resetCategoryStatistic = async () => {
    try {
      await getCategoryStatistic({
        ataAtFrom: dayjs(initialStatisticsFilter.ataAt[0])
          .startOf("day")
          .toISOString(),
        ataAtTo: dayjs(initialStatisticsFilter.ataAt[1])
          .endOf("day")
          .toISOString(),
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const resetPurchasesAndSalesStatistic = async () => {
    try {
      await getPurchasesAndSalesStatistic({
        ataAtFrom: dayjs(initialStatisticsFilter.ataAt[0])
          .startOf("day")
          .toISOString(),
        ataAtTo: dayjs(initialStatisticsFilter.ataAt[1])
          .endOf("day")
          .toISOString(),
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const getPreset = () => {
    return [
      {
        label: t("common:duration.oneWeek"),
        from: today.clone().subtract(1 + 7, "day"),
        to: today.clone().subtract(1, "day"),
      },
      {
        label: t("common:duration.oneMonth"),
        from: today.clone().subtract(1, "month"),
        to: today.clone().subtract(1, "day"),
      },
      {
        label: t("common:duration.oneYear"),
        from: today.clone().subtract(11, "month"),
        to: today.clone().subtract(1, "day"),
      },
    ];
  };

  const handlePresetClick = ({ from, to }: { from: Dayjs; to: Dayjs }) => {
    const value = [from, to] as [Dayjs | null, Dayjs | null];

    setStatisticsFilter((prev) => {
      return {
        ...prev,
        ataAt: value,
      };
    });
  };

  const handleFilterSearchClick = async () => {
    if (tab === "shipmentBoard") {
      try {
        getTotalShipment();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }

    if (tab === "statistics") {
      try {
        getSellerAndItemRanks();
        getCategoryStatisticData();
        getPurchasesAndSalesStatisticData();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const handleResetClick = async () => {
    if (tab === "shipmentBoard") {
      try {
        resetTodayTotalShipment();
        resetYesterdayTotalShipment();
        resetWeeklyTotalShipment();
        setShipmentBoardFilter(initialShipmentFilter);
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }

    if (tab === "statistics") {
      try {
        resetSellerAndItemRankStatistic();
        resetCategoryStatistic();
        resetPurchasesAndSalesStatistic();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const disabledDate = (current: Dayjs) => {
    if (!current) {
      return false;
    }

    if (isNull(statisticsFilter?.ataAt)) {
      return false;
    }

    return false;
  };

  // Render Function
  const renderStatisticsSection = () => {
    return (
      <>
        {[
          {
            cardTitle: t(
              "dashboard:importer.commonStatistics.processingContract"
            ),
            icon: DocumentSvg,
            numberOfStatistic: totalNumberOfContract,
            rankOfStatistic: [
              {
                label: t(
                  "dashboard:importer.commonStatistics.signatureRequired"
                ),
                value: signNeeded,
              },
              {
                label: t("dashboard:importer.commonStatistics.pending"),
                value: pending,
              },
              {
                label: t("dashboard:importer.commonStatistics.unlinked"),
                value: notLinked,
              },
            ],
            path: IMPORTER_PRIVATE_PATH.CONTRACT,
          },
          {
            cardTitle: t(
              "dashboard:importer.commonStatistics.workingShippingDocs"
            ),
            icon: ShipSvg,
            numberOfStatistic: totalNumberOfShipment,
            rankOfStatistic: [
              {
                label: t("dashboard:importer.commonStatistics.update"),
                value: blUpdate,
              },
            ],
            rightContent: blUpdatedShipmentIdAndBlNoList.length >= 1 && (
              <ShipmentUpdateList>
                {blUpdatedShipmentIdAndBlNoList.map(
                  ({ blNo, shipmentId }, idx) => {
                    return (
                      <li key={idx.toString()}>
                        {t("common:blNo")}
                        <BlueText
                          typoType="b7m"
                          color="systemBlue2"
                          onClick={() =>
                            console.log(`${blNo} ${shipmentId}클릭`)
                          }
                        >
                          {blNo}-
                        </BlueText>
                      </li>
                    );
                  }
                )}
              </ShipmentUpdateList>
            ),
            path: IMPORTER_PRIVATE_PATH.SHIPMENT,
          },
          {
            cardTitle: t("dashboard:importer.commonStatistics.working"),
            icon: BoxSvg,
            numberOfStatistic: totalNumberOfWarehousing,
            rankOfStatistic: [
              {
                label: t("dashboard:importer.commonStatistics.scheduledSales"),
                value: wareHouseAtNotPassed,
              },
              {
                label: t(
                  "dashboard:importer.commonStatistics.salesInformationRequired"
                ),
                value: needWareHouseAt,
              },
            ],
            path: IMPORTER_PRIVATE_PATH.SALES_MANAGEMENT,
          },
        ].map(
          ({
            cardTitle,
            icon,
            numberOfStatistic,
            rankOfStatistic,
            rightContent,
            path,
          }) => {
            return (
              <StatisticsCard
                cardTitle={cardTitle}
                icon={icon}
                numberOfStatistic={numberOfStatistic}
                rankOfStatistic={rankOfStatistic}
                rightContent={rightContent}
                onClick={() => navigate(path)}
              />
            );
          }
        )}
      </>
    );
  };

  const renderPresetHeader = () => {
    return (
      <Typo as="p" typoType="h6">
        {statisticsFilter.ataAt?.[0]?.format(DATE_FORMAT_STRINGS["YY.MM.DD"]) ||
          "-"}{" "}
        ~{" "}
        {statisticsFilter.ataAt?.[1]?.format(DATE_FORMAT_STRINGS["YY.MM.DD"]) ||
          "-"}
      </Typo>
    );
  };

  const renderTab = () => {
    return (
      <Tabs role="tablist">
        {TAB_LIST.map(({ id, langKey }) => {
          return (
            <StyledTabItem
              key={id}
              tabIndex={id === tab ? 0 : -1}
              isSelect={id === tab}
              tabValue={id}
              onClick={() => setTab(id)}
              onFocusItem={(value) => setTab(value as TabType)}
            >
              <Typo typoType="b5m" color={id === tab ? "white" : "gray6"}>
                {t(langKey)}
              </Typo>
            </StyledTabItem>
          );
        })}
      </Tabs>
    );
  };

  const renderTabContent = () => {
    // 선적요약
    if (tab === "shipmentBoard") {
      return (
        <Flex flexDirection="column" gap={16}>
          <DashboardFilter
            onReset={handleResetClick}
            onSubmit={handleFilterSearchClick}
          >
            <FilterItemContainer>
              <Flex alignItems="center" gap={8} flex={1}>
                <FilterLabel typoType="b10r" color="gray4">
                  {t("common:seller")}
                </FilterLabel>

                <StyledSearchSelect
                  value={shipmentBoardFilter.seller}
                  onChange={(value) => {
                    setShipmentBoardFilter((prev) => {
                      return { ...prev, seller: value };
                    });
                  }}
                  options={[
                    allOptionItem,
                    ...buyers.map((item) => {
                      return {
                        label: item.buyerName,
                        value: item.buyerName,
                      };
                    }),
                  ]}
                  suffixIcon={isBuyersFetching ? <Loader /> : undefined}
                />
              </Flex>

              <Flex alignItems="center" gap={8} flex={1}>
                <FilterLabel typoType="b10r" color="gray4">
                  {t("common:category")}
                </FilterLabel>
                <StyledSelect
                  value={shipmentBoardFilter.mainCategoryCodeItemName}
                  onChange={(value) => {
                    setShipmentBoardFilter((prev) => {
                      return { ...prev, mainCategoryCodeItemName: value };
                    });
                  }}
                  options={[allOptionItem, ...mainCategoryFilterList]}
                  suffixIcon={isMainCategoryFetching ? <Loader /> : undefined}
                />
              </Flex>
            </FilterItemContainer>
          </DashboardFilter>

          <ShipmentStatus
            filterData={shipmentBoardFilter}
            weekIndex={weekIndex}
            onWeekIndexChange={setWeekIndex}
            weeklyShipmentDate={weeklyShipmentDate}
            onWeeklyShipmentDateChange={setWeeklyShipmentDate}
            selectWeeklyDay={selectWeeklyDay}
            onSelectWeeklyDayChange={setSelectWeeklyDay}
          />
        </Flex>
      );
    }

    // 통계
    if (tab === "statistics") {
      return (
        <Flex flexDirection="column" gap={16}>
          <DashboardFilter
            onReset={handleResetClick}
            onSubmit={handleFilterSearchClick}
          >
            <FilterItemContainer>
              <Flex alignItems="center" gap={8} flex={1}>
                <FilterLabel typoType="b10r" color="gray4">
                  {t("dashboard:importer.filter.label.period")}
                </FilterLabel>
                <StyledRangePicker
                  ref={rangePickerRef}
                  value={statisticsFilter.ataAt}
                  onChange={(value) => {
                    const [startDate, endDate] = value as Dayjs[];
                    const duration = endDate.diff(startDate, "days");
                    const isMonthlyType = duration > THIRTY_ONE;

                    setStatisticsFilter((prev) => {
                      if (isMonthlyType) {
                        return {
                          ...prev,
                          ataAt: [endDate.subtract(11, "month"), endDate],
                        };
                      } else {
                        return { ...prev, ataAt: value };
                      }
                    });
                  }}
                  disabledDate={disabledDate}
                  presets={[]}
                  dropdownClassName="custom-range-picker-header"
                  renderExtraFooter={() => {
                    return (
                      <>
                        <PresetHeader>
                          <Typo as="p" typoType="h6">
                            {renderPresetHeader()}
                          </Typo>
                        </PresetHeader>

                        <PresetContent>
                          {getPreset().map(({ label, from, to }) => {
                            return (
                              <button
                                onClick={() => {
                                  handlePresetClick({ from, to });
                                  if (rangePickerRef.current) {
                                    rangePickerRef.current?.blur?.();
                                  }
                                }}
                              >
                                {label}
                              </button>
                            );
                          })}
                        </PresetContent>
                      </>
                    );
                  }}
                />
              </Flex>

              <Flex alignItems="center" gap={8} flex={1}>
                <FilterLabel typoType="b10r" color="gray4">
                  {t("dashboard:importer.filter.label.category")}
                </FilterLabel>
                <StyledSelect
                  value={statisticsFilter.mainCategoryCodeItemName}
                  onChange={(value) => {
                    setStatisticsFilter((prev) => {
                      return { ...prev, mainCategoryCodeItemName: value };
                    });
                  }}
                  options={[allOptionItem, ...mainCategoryFilterList]}
                  suffixIcon={isMainCategoryFetching ? <Loader /> : undefined}
                />
              </Flex>
            </FilterItemContainer>
          </DashboardFilter>

          <StatisticsFilterRootContext.Provider
            value={{
              ...statisticsFilter,
            }}
          >
            <TradingVolumeStatistics
              selectSellerRankIndex={selectSellerRankIndex}
              onItemRankIndexChange={setSelectItemRankIndex}
              selectItemRankIndex={selectItemRankIndex}
              onSellerRankIndexChange={setSelectSellerRankIndex}
            />
            <ImportedVolumeTrend />
            <PurchaseSalesStatistics
              selectCurrency={selectCurrency}
              onSelectCurrencyChange={setSelectCurrency}
            />
          </StatisticsFilterRootContext.Provider>
        </Flex>
      );
    }
  };

  const renderAlertDialog = () => {
    /**
     * 연동승인요청 모달
     * - 기업관리자에게만 노출
     */
    if (
      isLinkageRequestAlertDialogOpen &&
      user?.exporterUserType === "CORPORATE_MANAGER"
    ) {
      return (
        <AlertDialog
          open
          title={t("dashboard:exporter.linkageAlertDialog.title")}
          description={t("dashboard:exporter.linkageAlertDialog.description")}
          onOpenChange={() => dispatch(closeLinkageRequestAlertDialog())}
          onOk={() => {
            dispatch(closeLinkageRequestAlertDialog());
            navigate(IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT);
          }}
          onCancel={() => dispatch(closeLinkageRequestAlertDialog())}
          okText={t("common:ok")}
          cancelText={t("common:cancel")}
        />
      );
    }
  };

  return (
    <ImportMainLayout
      breadcrumb={[t("sideNav:home")]}
      useInnerScroll
      customPageTitle={
        <StyledHeader>
          <Flex alignItems="center" gap={8}>
            <Typo as="h1" typoType="h1" color="gray1">
              {t("common:today")}
            </Typo>

            <Typo typoType="b7m" color="gray6">
              {dayjs().format("YYYY.MM.DD ddd")}
            </Typo>
          </Flex>

          <CurrencySelect />
        </StyledHeader>
      }
    >
      <Flex flexDirection="column" gap={16}>
        <StatisticsSection>{renderStatisticsSection()}</StatisticsSection>

        {renderTab()}
        {renderTabContent()}
      </Flex>
      {renderAlertDialog()}
    </ImportMainLayout>
  );
};

export default ImporterDashboardPage;

const StyledHeader = styled.header`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatisticsSection = styled.section`
  display: flex;
  gap: 16px;
  overflow: auto;
  padding: 16px;
  margin: 0 -16px;

  ${StyledHorizontalScroll}
  &::-webkit-scrollbar-track {
    margin: 16px;
  }
`;

const ShipmentUpdateList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0 16px 16px;
  border-left: 1px solid ${colorSet.gray10};
  margin: 0 auto;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  cursor: pointer;
  word-break: break-word;
`;

const Tabs = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const StyledTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-right: 1px solid ${colorSet.gray9};
  cursor: pointer;
  background: ${({ isSelect }) =>
    isSelect ? colorSet.indigo : colorSet.gray11};
  border: 1px solid ${colorSet.gray9};

  &:last-child {
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const FilterLabel = styled(Typo)`
  min-width: 144px;
`;

const StyledRangePicker = styled(RangePicker)`
  width: 100%;
  padding: 8px 16px;
`;

const PresetHeader = styled.div`
  padding: 12px 16px;
  text-align: center;
`;

const PresetContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  button {
    all: unset;
    text-align: center;
    cursor: pointer;
    border: 0.5px solid ${colorSet.gray9};
    ${typo.b9m};
    padding: 8px 16px;

    &:hover {
      background: ${colorSet.gray9};
    }
  }
`;

const FilterItemContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledSearchSelect = styled(SearchSelect)`
  width: 100%;
`;
