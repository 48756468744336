import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { ColDef, RowSelectedEvent } from "ag-grid-community";
import Typo from "@/src/components/atom/Typo";
import Table from "@/src/components/atom/Table";
import Dialog from "@/src/components/atom/Dialog";
import { Button } from "@/src/components/atom/Button";
import RadioGroup from "@/src/components/atom/RadioGroup";
import AlertDialog from "@/src/components/atom/AlertDialog";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import { useAppSelector } from "@/src/store";
import { ClientType, ExporterUserType } from "@/src/store/apis/auth/interface";
import colorSet from "@/src/styles/color";
import {
  useEditBulkStaffTypeMutation,
  useLazyGetStaffsQuery,
} from "@/src/store/apis/corporate/staffManagement";
import { isUndefined } from "@/src/utils/is";
import useAlert from "@/src/hooks/useAlert";
import { columnPermissionList } from "../columns/columnPermissionChangeDialog";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { StaffManagementListRequest } from "@/src/store/apis/corporate/staffManagement/interface";
import { useTranslation } from "react-i18next";
import { CLIENT_TYPE_OPTION_LIST } from "@/src/constant/optionList";

interface PermissionChangeDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CANCEL,
  SUBMIT,
}

function PermissionChangeDialog({
  open,
  onOpenChange,
}: PermissionChangeDialogProps) {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const alert = useAlert();
  const gridRef = useRef<AgGridReact | null>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [columnDefs] = useState<ColDef[]>(columnPermissionList);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [clientType, setClientType] = useState<ClientType>("BOTH");
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  // API
  const [getStaffs, { staffList, isFetching, count, isError }] =
    useLazyGetStaffsQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;
        return {
          isFetching,
          count: isStable ? currentData.count : 0,
          staffList: isStable ? currentData.rows : undefined,
          isError,
        };
      },
    });
  const [editBulkStaffType] = useEditBulkStaffTypeMutation();

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "staffManagement:importer.table.name",
      },
      {
        columnKey: "email",
        langKey: "staffManagement:importer.table.email",
      },
      {
        columnKey: "exporterUserType",
        langKey: "staffManagement:importer.table.authority",
      },
      {
        columnKey: "staffType",
        langKey: "staffManagement:importer.table.staffType",
      },
      {
        columnKey: "mainCategory",
        langKey: "staffManagement:importer.table.categoryInCharge",
      },
      {
        columnKey: "personalContact",
        langKey: "staffManagement:importer.table.contactPersonal",
      },
      {
        columnKey: "officeContact",
        langKey: "staffManagement:importer.table.contactOffice",
      },
      {
        columnKey: "createdAt",
        langKey: "staffManagement:importer.table.joinDate",
      },
      {
        columnKey: "isActivated",
        langKey: "staffManagement:importer.table.use",
      },
    ],
  });

  const handleSubmitClick = async () => {
    try {
      await editBulkStaffType({
        exporterUserMainFieldType: clientType,
        exporterUserIdList: selectedKeys,
      }).unwrap();

      onOpenChange(false);
      alert.showAlert({
        type: "success",
        message: t("alert:editEmployeeTypeWasSuccessful"),
      });
    } catch (e: any) {
      alert.showAlert({
        type: "error",
        message: t("alert:editEmployeeTypeFailed"),
      });
    } finally {
      setAlertDialogState(AlertDialogState.NULL);
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelAlertDialog
          open={alertDialogState === AlertDialogState.CANCEL}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => onOpenChange(false)}
        />
      );
    }

    if (alertDialogState === AlertDialogState.SUBMIT) {
      return (
        <AlertDialog
          open={alertDialogState === AlertDialogState.SUBMIT}
          title={t("staffManagement:importer.employeeAlert.title")}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={handleSubmitClick}
          okText={t("staffManagement:importer.employeeAlert.okButton")}
          cancelText={t("staffManagement:importer.employeeAlert.cancelButton")}
        >
          {t("staffManagement:importer.employeeAlert.description")}
        </AlertDialog>
      );
    }
  };

  // Fetching
  const getStaffList = useCallback(
    async (listParams?: StaffManagementListRequest) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;
      const exporterUserType: ExporterUserType | undefined =
        user?.exporterUserType === "CORPORATE_MANAGER" ? undefined : "MANAGER";

      const defaultParams: {
        page: number;
        pageSize: number;
        exporterUserType?: ExporterUserType;
      } = {
        page,
        pageSize,
        exporterUserType,
      };

      try {
        await getStaffs({ ...listParams, ...defaultParams }).unwrap();
        setPagination(defaultParams);
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getStaffs,
      pagination.page,
      pagination.pageSize,
      user?.exporterUserType,
    ]
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    getStaffList({
      page,
      pageSize,
    });
  };

  // AgGrid Checkbox
  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const accountKey = e.node.data.exporterUserListQueryResultDto.id;

      const keys = selectedNodesData.map(
        (item) => item.data.exporterUserListQueryResultDto.id
      ) as number[];

      if (selectedKeys.includes(accountKey)) {
        setSelectedKeys((prev) => prev.filter((id) => id !== accountKey));
      } else {
        const set: any = new Set([...selectedKeys, ...keys]);
        setSelectedKeys([...set]);
      }
    }
  };

  // AgGrid 페이지 네이션
  const handleRowDataUpdated = () => {
    const isLiveGridRef = !!gridRef.current;

    if (isLiveGridRef) {
      gridRef.current?.api.forEachNode((rowNode) => {
        if (
          selectedKeys.includes(
            rowNode.data.exporterUserListQueryResultDto.account.id
          )
        ) {
          rowNode.setSelected(true);
        }
      });
    }
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getStaffList({
        page: 1,
        pageSize: 10,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t("staffManagement:importer.editEmployeeTypeDialog.title")}
      open={open}
      width={1000}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setAlertDialogState(AlertDialogState.CANCEL)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setAlertDialogState(AlertDialogState.CANCEL)}
            ref={cancelButtonRef}
          >
            <Typo typoType="btn3m">
              {t(
                "staffManagement:importer.editEmployeeTypeDialog.cancelButton"
              )}
            </Typo>
          </Button>

          <Button
            ref={submitButtonRef}
            onClick={() => setAlertDialogState(AlertDialogState.SUBMIT)}
            disabled={selectedKeys.length === 0}
          >
            <Typo color="white" typoType="btn3m">
              {t(
                "staffManagement:importer.editEmployeeTypeDialog.permissionChangeButton"
              )}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <FlexColumn>
        <Flex>
          <Typo typoType="b7m">
            {t("staffManagement:importer.editEmployeeTypeDialog.selectStaff")}{" "}
            <Typo typoType="b7m" color="red2">
              *
            </Typo>
          </Typo>
          <StyledTable
            ref={gridRef}
            rowData={isError ? [] : staffList}
            isPaginationDataMaping
            columnDefs={columnDefs}
            totalPage={count}
            rowSelection={"multiple"}
            rowMultiSelectWithClick={true}
            handlePaginationClick={handlePaginationClick}
            onRowDataUpdated={handleRowDataUpdated}
            onRowSelected={handleSelectionChanged}
            onSortChange={(sortValue, isClickedHeader) => {
              if (isClickedHeader) {
                getStaffList({
                  page: pagination.page,
                  pageSize: pagination.pageSize,
                  sort: sortValue?.sort,
                  order: sortValue?.order
                    ? sortValue.order.toUpperCase()
                    : undefined,
                });
              }
            }}
            pageSize={pagination.pageSize}
            page={pagination.page}
            onGridReady={() => {
              setIsReady(true);
            }}
            getRowId={(data) =>
              data.data.exporterUserListQueryResultDto.account.id
            }
          />
        </Flex>
        <Flex>
          <Typo typoType="b7m">
            {t(
              "staffManagement:importer.editEmployeeTypeDialog.selectChangePermissions"
            )}{" "}
            <Typo typoType="b7m" color="red2">
              *
            </Typo>
          </Typo>

          <RadioContainer>
            <RadioGroup
              value={clientType}
              onChange={(e) => {
                setClientType(e.target.value);
              }}
              name={"radio"}
              options={CLIENT_TYPE_OPTION_LIST.map((item) => {
                return { ...item, label: t(item.langKey) };
              })}
            />
          </RadioContainer>
        </Flex>
      </FlexColumn>
      {renderAlertDialog()}
    </Dialog>
  );
}

export default PermissionChangeDialog;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledTable = styled(Table)`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;
