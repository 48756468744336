import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { taskDetailApi } from "@/src/store/apis/tasks/taskDetail";
import { StyledScroll } from "@/src/styles/scroll";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

function TransportationCard() {
  const { t } = useTranslation();
  const params = useParams();

  const { transportDateAt, transportRemark } =
    taskDetailApi.endpoints.getTaskDetail.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;

          return {
            transportDateAt:
              !isUnstable && currentData.transportDateAt
                ? dayjs(currentData.transportDateAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
                  )
                : "-",
            transportRemark: !isUnstable
              ? currentData.transportRemark ?? "-"
              : "-",
          };
        },
      }
    );

  return (
    <StyledSectionCard cardTitle={t("common:transportationInformation")}>
      <SectionCardRow
        label={t("common:transportationDate")}
        direction="vertical"
        value={
          <Typo typoType="h3" color="blue2">
            {transportDateAt}
          </Typo>
        }
      />

      <StyledReadOnlyText typoType="b7r" color="gray5">
        {transportRemark}
      </StyledReadOnlyText>
    </StyledSectionCard>
  );
}

export default TransportationCard;

const StyledSectionCard = styled(SectionCard)`
  grid-column: span 5;
`;

const StyledReadOnlyText = styled(ReadOnlyText)`
  margin-top: 8px;
  height: 100px;
  overflow: auto;

  ${StyledScroll}
`;
