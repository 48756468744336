import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { ClientType } from "@/src/store/apis/auth/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import {
  useApproveLinkMutation,
  useRejectLinkMutation,
} from "@/src/store/apis/link";
import { isUndefined } from "@/src/utils/is";
import Typo from "@/src/components/atom/Typo";
import Table from "@/src/components/atom/Table";
import Input from "@/src/components/atom/Input";
import Dialog from "@/src/components/atom/Dialog";
import { Button } from "@/src/components/atom/Button";
import RadioGroup from "@/src/components/atom/RadioGroup";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { staffManagerColumn } from "./columns/column";
import colorSet from "@/src/styles/color";
import { InputError } from "@/src/components/molecule/FormItem";
import {
  buyerApi,
  useInvalidatesBuyerMutation,
} from "@/src/store/apis/client/buyer";
import useAlert from "@/src/hooks/useAlert";
import { BuyerEmployeeDtoForSharing } from "@/src/store/apis/link/interface";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";
import { CLIENT_TYPE_OPTION_LIST } from "@/src/constant/optionList";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface LinkApproveDialogProps {
  onClose: () => void;
  defaultClientCode: string;
  defaultClientType: ClientType;
  clientId: number;
}

enum AlertDialogState {
  NULL,
  REJECT,
}

const EMPTY_ARRAY: PartialCommonCodeItemDto[] = [];
const EMPTY_EMPLOYEE_ARRAY: BuyerEmployeeDtoForSharing[] = [];
const EMPTY_STRING_ARRAY: string[] = [];

const TABLE_INNER_CONTENT_HEIGHT = 253;

const LinkApproveDialog = ({
  onClose,
  defaultClientCode,
  defaultClientType,
  clientId,
}: LinkApproveDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<{ clientCode: string; clientType: ClientType }>({
    mode: "onBlur",
    defaultValues: {
      clientCode: defaultClientCode,
      clientType: defaultClientType,
    },
  });

  const staffManagerGridRef = useRef<AgGridReact>(null);
  const [isStaffManagerGridReady, setIsStaffManagerGridReady] =
    useState<boolean>(false);
  const [staffPagination, setStaffPagination] = useState(1);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [staffColumn] = useState(staffManagerColumn);
  const [invalidatesBuyer] = useInvalidatesBuyerMutation();
  const { mainCategory } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategory: !isUnstable ? currentData : EMPTY_ARRAY,
        };
      },
    }
  );
  const [approve, { isLoading: isApproving }] = useApproveLinkMutation();
  const [reject] = useRejectLinkMutation();

  const {
    buyerEmployeeDtoForSharingList,
    totalPage,
    client,
    contact,
    country,
    businessNumber,
    mainCategoryCode,
    address,
  } = buyerApi.endpoints.getBuyer.useQueryState(
    {
      id: clientId,
    },
    {
      selectFromResult: ({ data, isSuccess }) => {
        return {
          buyerEmployeeDtoForSharingList: isSuccess
            ? data.buyerQueryResultDto.companyLinkageHistory
                .buyerEmployeeDtoForSharingList
            : EMPTY_EMPLOYEE_ARRAY,
          totalPage: isSuccess
            ? data.buyerQueryResultDto.companyLinkageHistory
                .buyerEmployeeDtoForSharingList.length
            : 0,
          client: isSuccess
            ? data.buyerQueryResultDto.companyLinkageHistory.sender?.companyName
            : "-",
          clientType: isSuccess
            ? data.buyerQueryResultDto.companyLinkageHistory.sender?.companyType
            : undefined,
          contact: isSuccess
            ? `${
                data.buyerQueryResultDto.companyLinkageHistory.sender
                  ?.telPrefix || ""
              } ${
                data.buyerQueryResultDto.companyLinkageHistory.sender?.tel || ""
              }`
            : "-",
          country: isSuccess
            ? data.buyerQueryResultDto.companyLinkageHistory.sender?.country
            : "-",
          businessNumber: isSuccess
            ? data.buyerQueryResultDto.companyLinkageHistory.sender
                ?.businessNumber
            : "-",
          mainCategoryCode: isSuccess
            ? data.buyerQueryResultDto.companyLinkageHistory.sender
                ?.mainCategoryCodeItemNames || EMPTY_STRING_ARRAY
            : EMPTY_STRING_ARRAY,
          address: isSuccess
            ? [
                data.buyerQueryResultDto.companyLinkageHistory.sender
                  ?.postalCode,
                data.buyerQueryResultDto.companyLinkageHistory.sender
                  ?.streetAddress,
                data.buyerQueryResultDto.companyLinkageHistory.sender?.locality,
                data.buyerQueryResultDto.companyLinkageHistory.sender?.region,
                data.buyerQueryResultDto.companyLinkageHistory.sender
                  ?.countryName,
              ]
                .filter((addressPartial) => !!addressPartial)
                .join(", ")
            : "-",
        };
      },
    }
  );

  const mainCategoryCapitalize = mainCategoryCode
    .map(
      (itemCode) =>
        mainCategory.find(({ codeItemName }) => codeItemName === itemCode)
          ?.codeItemNameEn
    )
    .join(", ");

  const handleApprove = async () => {
    try {
      await approve({
        id: clientId,
        companyType: getValues("clientType"),
        buyerNameCode: getValues("clientCode"),
      }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("clientManagement:exporter.linkApprove.alert.success"),
      });

      invalidatesBuyer();
      onClose();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleReject = async () => {
    try {
      await reject({
        id: clientId,
      }).unwrap();

      setAlertDialogState(AlertDialogState.NULL);
      onClose();

      navigate(EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT, { replace: true });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderCorpInfo = () => {
    return (
      <InfoBox>
        <Box>
          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t("clientManagement:exporter.linkApprove.clientInfo.client")}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Typo typoType="b7r" color="gray5">
                {client}
              </Typo>
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>
              {t("clientManagement:exporter.linkApprove.clientInfo.clientCode")}
            </InfoLabel>
            <InfoValue>
              <Controller
                control={control}
                name="clientCode"
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  return (
                    <Input
                      data-invalid={!!errors.clientCode}
                      onClear={() => setValue("clientCode", "")}
                      {...field}
                    />
                  );
                }}
              />
              {errors.clientCode && (
                <StyledInputError message={t("error:required")} />
              )}
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t(
                  "clientManagement:exporter.linkApprove.clientInfo.clientType"
                )}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Controller
                control={control}
                name="clientType"
                render={({ field }) => {
                  return (
                    <StyledRadioGroup
                      size="large"
                      {...field}
                      typoType="b7r"
                      options={CLIENT_TYPE_OPTION_LIST.map((item) => {
                        return { ...item, label: t(item.langKey) };
                      })}
                    />
                  );
                }}
              />
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t("clientManagement:exporter.linkApprove.clientInfo.country")}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Typo typoType="b7r" color="gray5">
                {country}
              </Typo>
            </InfoValue>
          </InfoRow>
        </Box>
        <Divider />
        <Box>
          {[
            {
              label: t(
                "clientManagement:exporter.linkApprove.clientInfo.businessNumber"
              ),
              value: businessNumber,
            },
            {
              label: t(
                "clientManagement:exporter.linkApprove.clientInfo.mainCategory"
              ),
              value: mainCategoryCapitalize,
            },
            {
              label: t("clientManagement:exporter.linkApprove.clientInfo.tel"),
              value: contact,
            },
            {
              label: t(
                "clientManagement:exporter.linkApprove.clientInfo.address"
              ),
              value: address,
            },
          ].map(({ label, value }) => {
            return (
              <InfoRow>
                <InfoLabel>
                  <Typo typoType="b7m">{label}</Typo>
                </InfoLabel>
                <InfoValue>
                  <Typo typoType="b7r" color="gray5">
                    {value}
                  </Typo>
                </InfoValue>
              </InfoRow>
            );
          })}
        </Box>
      </InfoBox>
    );
  };

  const renderCorpStaffList = () => {
    return (
      <Article>
        <Typo typoType="b7m">
          {t("clientManagement:exporter.linkApprove.staffList.list")}
        </Typo>

        <TableWrapper>
          <Table
            ref={staffManagerGridRef}
            columnDefs={staffColumn}
            height={TABLE_INNER_CONTENT_HEIGHT}
            totalPage={totalPage}
            page={staffPagination}
            pageSize={5}
            handlePaginationClick={(page) => setStaffPagination(page)}
            rowData={buyerEmployeeDtoForSharingList.slice(
              staffPagination * 5 - 5,
              staffPagination * 5 - 1
            )}
            onGridReady={() => {
              setIsStaffManagerGridReady(true);
            }}
          />
        </TableWrapper>
      </Article>
    );
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.REJECT) {
      return (
        <AlertDialog
          open
          title={t(
            "clientManagement:exporter.linkApprove.alertDialog.reject.title"
          )}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={handleReject}
          cancelText={t("common:cancel")}
          okText={t("common:ok")}
        >
          {t(
            "clientManagement:exporter.linkApprove.alertDialog.reject.description"
          )}
        </AlertDialog>
      );
    }
  };

  useAgGridHeaderRefresh({
    gridRef: staffManagerGridRef.current,
    isReady: isStaffManagerGridReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "clientManagement:exporter.common.name",
      },
      {
        columnKey: "email",
        langKey: "clientManagement:exporter.common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:exporter.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:exporter.common.officeContact",
      },
    ],
  });

  return (
    <>
      <Dialog
        title={t("clientManagement:exporter.linkApprove.dialogTitle")}
        open
        onOpenChange={onClose}
        width={1000}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade={"tertiary"}
              buttonColor="black"
              onClick={() => setAlertDialogState(AlertDialogState.REJECT)}
            >
              {t("clientManagement:exporter.linkApprove.buttons.reject")}
            </Button>
            <Button
              disabled={!watch("clientCode") || !!errors.clientCode}
              onClick={handleApprove}
              isLoading={isApproving}
            >
              {t("clientManagement:exporter.linkApprove.buttons.ok")}
            </Button>
          </DialogFooterContainer>
        }
      >
        <TextContainer>
          <PreLineTypo typoType="h2">
            {t("clientManagement:exporter.linkApprove.title")}
          </PreLineTypo>
          <PreLineTypo typoType="b7m">
            {t("clientManagement:exporter.linkApprove.description")}
          </PreLineTypo>
        </TextContainer>
        <InnerContainer>
          {renderCorpInfo()}

          <RowDivider />

          {renderCorpStaffList()}
        </InnerContainer>

        {renderAlertDialog()}
      </Dialog>
    </>
  );
};

export default LinkApproveDialog;

const InfoBox = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
`;

const Box = styled.div`
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  width: 1px;
  background: ${colorSet.gray9};
`;

const RowDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;

const InfoRow = styled.div`
  display: flex;
`;

const InfoLabel = styled.div`
  width: 164px;
`;
const InfoValue = styled.div`
  flex: 1;
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
`;

const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledInputError = styled(InputError)`
  padding-top: 8px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  &.ant-radio-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TableWrapper = styled.div`
  .ant-pagination-options {
    display: none;
  }
`;

const PreLineTypo = styled(Typo)`
  white-space: pre-line;
  text-align: center;
`;
