import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { isUndefined } from "@/src/utils/is";
import Typo from "@/src/components/atom/Typo";
import Table from "@/src/components/atom/Table";
import Tooltip from "@/src/components/atom/Tooltip";
import SectionCard from "@/src/components/molecule/SectionCard";
import { columnBookingList } from "../../columns/columnBookingList";
import { columnShipmentList } from "../../columns/columnShipmentList";
import { columnLoadingList } from "../../columns/columnLoadingList";
import { useLazyGetBookingListQuery } from "@/src/store/apis/bookings/bookingList";
import { useLazyGetTaskListQuery } from "@/src/store/apis/tasks/taskList";
import { useLazyGetShipmentListQuery } from "@/src/store/apis/shipments/shipmentList";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import {
  contractDetailApi,
  useGetContractTaskSummaryInfoQuery,
} from "@/src/store/apis/contracts/contractDetail";
import LoadingSvg from "@/src/assets/icons/icon-total-task.svg";
import NotShareSvg from "@/src/assets/icons/illust-not-share.svg";
import ArrowRightSvg from "@/src/assets/icons/icon-arrow-right-bold.svg";
import LoadingContainerSvg from "@/src/assets/icons/icon-total-container.svg";
import { SignatureStatusType } from "@/src/store/apis/contracts/interface";
import Icon from "@/src/components/atom/Icon";
import useAlert from "@/src/hooks/useAlert";
import { useTranslation } from "react-i18next";

type ListType = "booking" | "loading" | "shipment";
const EMPTY_ARRAY: [] = [];

interface ProcessingTabProps {
  tab: ListType;
  onTabChange: (tab: ListType) => void;
  signatureStatus: SignatureStatusType;
  hasLinkedBefore: boolean;
}

const ProcessingTab = ({
  tab,
  onTabChange,
  signatureStatus,
  hasLinkedBefore,
}: ProcessingTabProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();

  const [paginationSet, setPaginationSet] = useState<
    Record<ListType, { page: number; pageSize: number }>
  >({
    booking: {
      page: 1,
      pageSize: 10,
    },
    loading: {
      page: 1,
      pageSize: 10,
    },
    shipment: {
      page: 1,
      pageSize: 10,
    },
  });

  const [getBookingList, { bookingCount, bookingList }] =
    useLazyGetBookingListQuery({
      selectFromResult: ({ currentData, isError }) => {
        return {
          bookingList: isError ? EMPTY_ARRAY : currentData?.rows,
          bookingCount: isError ? 0 : currentData?.count || 0,
        };
      },
    });
  const [getLoadingList, { loadingList, loadingCount }] =
    useLazyGetTaskListQuery({
      selectFromResult: ({ currentData, isError }) => {
        return {
          loadingList: isError ? EMPTY_ARRAY : currentData?.rows,
          loadingCount: isError ? 0 : currentData?.count || 0,
        };
      },
    });
  const [getShipmentList, { shipmentCount, shipmentList }] =
    useLazyGetShipmentListQuery({
      selectFromResult: ({ currentData, isError }) => {
        return {
          shipmentList: isError ? EMPTY_ARRAY : currentData?.rows,
          shipmentCount: isError ? 0 : currentData?.count || 0,
        };
      },
    });

  const {
    loadingRequestCount,
    loadingScheduledCount,
    workingCount,
    photoSubmittedCount,
    fieldWorkCompletedCount,
    loadingCompletedCount,
    totalLoadingCount,
    totalContainerCount,
  } = useGetContractTaskSummaryInfoQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      skip: tab !== "loading",
      selectFromResult: ({ isError, currentData }) => {
        const isErrorAndUndefined = isError || isUndefined(currentData);

        return {
          loadingRequestCount: !isErrorAndUndefined
            ? currentData.taskRequestCount
            : 0,
          loadingScheduledCount: !isErrorAndUndefined
            ? currentData.taskScheduledCount
            : 0,
          workingCount: !isErrorAndUndefined ? currentData.workingCount : 0,
          photoSubmittedCount: !isErrorAndUndefined
            ? currentData.photoSubmittedCount
            : 0,
          fieldWorkCompletedCount: !isErrorAndUndefined
            ? currentData.fieldWorkCompletedCount
            : 0,
          loadingCompletedCount: !isErrorAndUndefined
            ? currentData.taskCompletedCount
            : 0,
          totalLoadingCount: !isErrorAndUndefined
            ? currentData.totalTaskCount
            : 0,
          totalContainerCount: !isErrorAndUndefined
            ? currentData.totalContainerCount
            : 0,
        };
      },
    }
  );

  const { balanceOfContractV2, totalShippingWeightV2, percentOfShipment } =
    contractDetailApi.endpoints.getContractShipmentSummaryInfo.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            balanceOfContractV2: !isErrorAndUndefined
              ? currentData.balanceOfContractV2
              : "-",
            totalShippingWeightV2: !isErrorAndUndefined
              ? currentData.totalShippingWeightV2
              : "-",
            percentOfShipment:
              !isErrorAndUndefined && currentData.percentOfShipment
                ? Number(currentData.percentOfShipment)
                : 0,
          };
        },
      }
    );

  const statusList = [
    {
      label: t("contract:exporter.detail.loadingList.loadingRequest"),
      value: loadingRequestCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.loadingScheduled"),
      value: loadingScheduledCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.working"),
      value: workingCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.photoSubmitted"),
      value: photoSubmittedCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.fieldWorkCompleted"),
      value: fieldWorkCompletedCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.LoadingCompleted"),
      value: loadingCompletedCount,
    },
  ];

  const renderTable = () => {
    switch (tab) {
      case "booking":
        return (
          <Table
            rowData={bookingList}
            columnDefs={columnBookingList(t)}
            isPaginationDataMaping
            totalPage={bookingCount}
            page={paginationSet.booking.page}
            pageSize={paginationSet.booking.pageSize}
            handlePaginationClick={async (page, pageSize) => {
              try {
                await getBookingList({
                  contractId: Number(params.id),
                  page,
                  pageSize,
                }).unwrap();
                setPaginationSet((prev) => ({
                  ...prev,
                  booking: {
                    page,
                    pageSize,
                  },
                }));
              } catch (e: any) {
                const message = Array.isArray(e.data.message)
                  ? e.data.message[0]
                  : e.data.message;

                if (e.data.statusCode !== 404) {
                  alert.showAlert({ type: "error", message });
                }
              }
            }}
            onRowClicked={({ data }) => {
              navigate(`${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/${data.id}`);
            }}
          />
        );
      case "loading":
        return (
          <>
            <TaskTotalInfoContainer>
              <TaskTotalItem>
                <TaskTotalIllust imageUrl={LoadingSvg} />
                <TaskDescription>
                  <Typo color="gray6" typoType="b9m">
                    {t("contract:exporter.detail.loadingList.totalLoading")}
                  </Typo>
                  <Typo color="blue2" typoType="h4">
                    {totalLoadingCount} {t("common:cases")}
                  </Typo>
                </TaskDescription>
              </TaskTotalItem>

              <TaskTotalItem>
                <TaskTotalIllust imageUrl={LoadingContainerSvg} />
                <TaskDescription>
                  <Typo color="gray6" typoType="b9m">
                    {t("contract:exporter.detail.loadingList.totalContainer")}
                  </Typo>
                  <Typo color="blue2" typoType="h4">
                    {totalContainerCount} {t("common:ea")}
                  </Typo>
                </TaskDescription>
              </TaskTotalItem>
            </TaskTotalInfoContainer>

            {/* Status */}
            <TaskStatusContainer>
              {statusList.map(({ label, value }, idx) => {
                return (
                  <TaskStatus key={idx.toString()}>
                    <StatusLabel>
                      <Typo typoType="b9m" color="gray6">
                        {label}
                      </Typo>
                    </StatusLabel>
                    <StatusValue>
                      <Typo typoType="h4" color="blue2">
                        {value}
                      </Typo>
                    </StatusValue>
                  </TaskStatus>
                );
              })}
            </TaskStatusContainer>

            <Table
              rowData={loadingList}
              columnDefs={columnLoadingList(t)}
              isPaginationDataMaping
              totalPage={loadingCount}
              page={paginationSet.loading.page}
              pageSize={paginationSet.loading.pageSize}
              handlePaginationClick={async (page, pageSize) => {
                try {
                  await getLoadingList({
                    contractId: Number(params.id),
                    page,
                    pageSize,
                  }).unwrap();
                  setPaginationSet((prev) => ({
                    ...prev,
                    loading: {
                      page,
                      pageSize,
                    },
                  }));
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;

                  if (e.data.statusCode !== 404) {
                    alert.showAlert({ type: "error", message });
                  }
                }
              }}
              onRowClicked={({ data }) => {
                navigate(`${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${data.id}`);
              }}
            />
          </>
        );
      case "shipment":
        return (
          <>
            <ShipmentStatusContainer>
              {/* Status */}
              <ShipmentStatusTextGrid>
                <ShipmentStatusText>
                  <Typo typoType="b9m" color="gray6">
                    {t(
                      "contract:exporter.detail.shipmentList.balanceOfContract"
                    )}
                  </Typo>
                  <Typo typoType="h4" color="blue2">
                    {balanceOfContractV2}
                  </Typo>
                </ShipmentStatusText>

                <ShipmentStatusText>
                  <Typo typoType="b9m" color="gray6">
                    {t(
                      "contract:exporter.detail.shipmentList.totalShippingWeight"
                    )}
                  </Typo>
                  <Typo typoType="h4" color="blue2">
                    {totalShippingWeightV2}
                  </Typo>
                </ShipmentStatusText>
              </ShipmentStatusTextGrid>

              {/* Status Percentage*/}
              <StatusPercentageContainer>
                {/* Percentage Tooltip */}
                <StyledTooltip
                  tooltipColor="green2"
                  placement="leftTop"
                  $isInnerWindow
                  left={percentOfShipment >= 100 ? 100 : percentOfShipment}
                  triggerComponent={
                    <PercentageContainer>
                      <PercentageColor
                        percent={
                          percentOfShipment >= 100 ? 100 : percentOfShipment
                        }
                        $isInnerWindow
                      />
                      <PercentageOverlay />
                    </PercentageContainer>
                  }
                >
                  <Typo color="white" typoType="b10m">
                    {percentOfShipment}%
                  </Typo>
                </StyledTooltip>

                {/* 0 ~ 100% */}
                <TopPercentage>
                  <Typo typoType="b10m" color="gray7">
                    0%
                  </Typo>
                  <Typo typoType="b10m" color="gray7">
                    100%
                  </Typo>
                </TopPercentage>

                {/* 선적 비율 */}
                <PercentageTypo>
                  <Typo typoType="b9m" color="gray6">
                    {t("contract:exporter.detail.shipmentList.shipmentRate")}
                  </Typo>
                  <Typo typoType="h4" color="green1">
                    {percentOfShipment}%
                  </Typo>
                </PercentageTypo>
              </StatusPercentageContainer>
            </ShipmentStatusContainer>
            <Table
              rowData={shipmentList}
              columnDefs={columnShipmentList(t)}
              isPaginationDataMaping
              totalPage={shipmentCount}
              page={paginationSet.shipment.page}
              pageSize={paginationSet.shipment.pageSize}
              handlePaginationClick={async (page, pageSize) => {
                try {
                  await getShipmentList({
                    contractId: Number(params.id),
                    page,
                    pageSize,
                  }).unwrap();
                  setPaginationSet((prev) => ({
                    ...prev,
                    shipment: {
                      page,
                      pageSize,
                    },
                  }));
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;

                  if (e.data.statusCode !== 404) {
                    alert.showAlert({ type: "error", message });
                  }
                }
              }}
              onRowClicked={({ data }) => {
                navigate(`${EXPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${data.id}`);
              }}
            />
          </>
        );
    }
  };

  useEffect(() => {
    (async () => {
      await getBookingList({
        contractId: Number(params.id),
        page: paginationSet.booking.page,
        pageSize: paginationSet.booking.pageSize,
      });
      await getLoadingList({
        contractId: Number(params.id),
        page: paginationSet.loading.page,
        pageSize: paginationSet.loading.pageSize,
        isCancelTaskRequestExist: false,
      });
      await getShipmentList({
        contractId: Number(params.id),
        page: paginationSet.shipment.page,
        pageSize: paginationSet.shipment.pageSize,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBookingList, getLoadingList, getShipmentList, params.id]);

  return (
    <FlexRow flexGap={16}>
      <SideTab>
        <StyledLocalButton
          onClick={() => onTabChange("booking")}
          data-selected={tab === "booking"}
        >
          {t("common:booking")} ({bookingCount})
        </StyledLocalButton>
        <StyledLocalButton
          onClick={() => onTabChange("loading")}
          data-selected={tab === "loading"}
        >
          {t("common:loading")} ({loadingCount})
        </StyledLocalButton>
        <StyledLocalButton
          onClick={() => onTabChange("shipment")}
          data-selected={tab === "shipment"}
        >
          {t("common:shipment")} ({shipmentCount})
        </StyledLocalButton>
      </SideTab>

      {signatureStatus === "LINKED" || hasLinkedBefore ? (
        <StyledCard cardTitle={t("common:bookingList")}>
          {renderTable()}
        </StyledCard>
      ) : (
        <NotSharedContainer>
          <Icon iconSrc={NotShareSvg} iconSize={160} />

          <Typo typoType="b4m">
            {t("contract:exporter.detail.label.thisIsBeforeTheUpdate")}
          </Typo>
        </NotSharedContainer>
      )}
    </FlexRow>
  );
};

export default ProcessingTab;

const FlexRow = styled.div<{ flexGap?: number }>`
  display: flex;
  ${({ flexGap }) =>
    flexGap &&
    css`
      gap: ${flexGap}px;
    `};
`;

const SideTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 8px;
  border-right: 1px solid ${colorSet.gray9};
  flex-shrink: 0;
`;

const StyledLocalButton = styled.button`
  all: unset;
  cursor: pointer;
  padding: 12px 8px;
  ${typo.b8r};
  border-radius: 8px;

  &[data-selected="true"] {
    background: ${colorSet.blue10};
    color: ${colorSet.blue4};
  }

  &[data-selected="false"] {
  }
`;

const StyledCard = styled(SectionCard)`
  flex: 1;
`;

const TaskTotalInfoContainer = styled.ul`
  display: flex;
  padding: 0 12px;
  gap: 24px;
`;

const TaskTotalItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
`;

const TaskTotalIllust = styled.div<{ imageUrl: string }>`
  width: 85px;
  height: 85px;
  border: 1px solid ${colorSet.gray10};
  border-radius: 8px;
  background: ${({ imageUrl }) => imageUrl && `url(${imageUrl})`} no-repeat;
  background-position: center;
`;

const TaskDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TaskStatusContainer = styled.ul`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: ${colorSet.gray11};
  margin: 16px 0;
  padding: 16px 0;
`;

const TaskStatus = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  padding: 0 8px;
  flex: 1;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: url(${ArrowRightSvg}) no-repeat;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
`;
const StatusValue = styled.div`
  flex: 1;
`;

const ShipmentStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 32px;
`;

const ShipmentStatusTextGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 176px);
  align-items: start;
`;

const ShipmentStatusText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8px;
  padding: 16px 8px;

  &:first-child {
    border-right: 1px solid ${colorSet.gray10};
  }
`;

const StatusPercentageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const TopPercentage = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PercentageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 16px;
  margin: 24px 0 8px 0;
`;

const PercentageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: ${colorSet.gray9};
`;

const percentageAnimation = (number: number) => keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${Math.round(number)}%
  }
`;

const percentageTooltipAnimation = (number: number) => keyframes`
  0% {
    left: 0;
  }
  100% {
    left: ${Math.round(number)}%;
  }
`;

const PercentageColor = styled.div<{
  percent: number;
  $isInnerWindow: boolean;
}>`
  position: absolute;
  height: 100%;
  border-radius: 20px;
  background: ${colorSet.green2};
  z-index: 1;
  animation: ${({ percent, $isInnerWindow }) =>
    $isInnerWindow ? percentageAnimation(percent) : null};
  animation-duration: 500ms;
  animation-fill-mode: both;
`;

const PercentageTypo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTooltip = styled(Tooltip)<{
  left: number;
  $isInnerWindow: boolean;
}>`
  bottom: 30px;
  animation: ${({ left, $isInnerWindow }) =>
    $isInnerWindow ? percentageTooltipAnimation(left) : null};
  animation-duration: 500ms;
  animation-fill-mode: both;
`;

const NotSharedContainer = styled.div`
  height: 670px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
