import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { SalesDto } from "@/src/store/apis/sales/interface";
import getTableIndex from "@/src/utils/getTableIndex";
import {
  CELL_TYPE_EDIT_ACTION,
  CELL_TYPE_VIEW_ACTION,
} from "@/src/utils/row-data-util";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import renderSalesStatusBadge from "../utils/renderSalesStatusBadge";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import Icon from "@/src/components/atom/Icon";
import { IconButton } from "@/src/components/atom/Button";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import styled from "styled-components";
import Flex from "@/src/components/molecule/Flex";
import TableBlueText from "@/src/components/molecule/TableBlueText";

// 발주 추가 페이지 내 아이템
export const columnSalesItem: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 45,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "item",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.itemName ? params.data.itemName : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "itemCode",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.itemCode ? params.data.itemCode : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "subCategory",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.subCategory ? params.data.subCategory : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "mainCategory",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.mainCategory ? params.data.mainCategory : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "quantity",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.quantity && params.data.quantityUnit
            ? `${Number(params.data.quantity.replace(/,/g, "")).toLocaleString(
                "ko-KR"
              )} ${params.data.quantityUnit}`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "unitPrice",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.unitPrice && params.data.unitPriceUnit
            ? `${Number(params.data.unitPrice.replace(/,/g, "")).toLocaleString(
                "ko-KR"
              )} ${params.data.unitPriceUnit}`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "amount",
    headerName: "Amount",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.amount && params.data.amountUnit
            ? `${Number(params.data.amount.replace(/,/g, "")).toLocaleString(
                "ko-KR"
              )} ${params.data.amountUnit}`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "edit",
    lockPosition: "right",
    pinned: "right",
    resizable: false,
    width: 90,
    sortable: false,
    cellRenderer: CELL_TYPE_EDIT_ACTION,
  },
];

// 발주 목록 페이지
export const columnSalesList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: false,
    cellStyle: { textAlign: "center" },
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "isBookmarked",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.salesBookmarks.length >= 1) {
        return <Icon iconSrc={BookmarkSvg} iconSize={18} />;
      }
      return null;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "orderDateAt",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.orderDateAt
            ? dayjs(params.data.orderDateAt).format(
                DATE_FORMAT_STRINGS.YYYY_MM_DD
              )
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "salesNumber",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.salesNumber ? params.data.salesNumber : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "salesStatus",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.salesStatus
            ? renderSalesStatusBadge({
                type: params.data.salesStatus,
                size: "S",
              })
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "warehouse",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.warehouse.warehouseName
            ? params.data.warehouse.warehouseName
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "exporterItemCode",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.exporterItemCode
            ? params.data.exporterItemCode.itemCode
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "unitPrice",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.unitPrice && params.data.unitPriceUnit
            ? `${Number(params.data.unitPrice).toLocaleString("ko-KR")} ${
                params.data.unitPriceUnit
              }`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "quantity",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.quantity && params.data.quantityUnit
            ? `${Number(params.data.quantity).toLocaleString("ko-KR")} ${
                params.data.quantityUnit
              }`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "incomingQuantity",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.incomingQuantity
            ? `${Number(params.data.incomingQuantity).toLocaleString(
                "ko-KR"
              )} ${params.data.quantityUnit}`
            : `0 ${params.data.quantityUnit}`}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "orderBalance",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const quantity = `${Number(params.data.quantity).toLocaleString(
        "ko-KR"
      )} ${params.data.quantityUnit}`;

      return (
        <Typo typoType="b9r">
          {params.data.orderBalance !== 0
            ? `${Number(params.data.orderBalance).toLocaleString("ko-KR")} ${
                params.data.quantityUnit
              }`
            : `${quantity}`}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "salesFinalProducts",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const finalProducts = (
        params.data.salesFinalProducts as SalesDto["salesFinalProducts"]
      )
        .map((item) => item.finalProduct.name)
        .join(",");

      return (
        <Typo typoType="b9r">
          {params.data.salesFinalProducts ? finalProducts : "-"}
        </Typo>
      );
    },
  },
];

// 발주 상세 페이지 내 입고목록
export const columnSalesWarehouseList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: false,
    cellStyle: { textAlign: "center" },
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "warehousedAt",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.warehousedAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.warehousedAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )}
          </Typo>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "warehousedAmount",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.warehousedAmount ? params.data.warehousedAmount : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "warehousingMedia",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const handlePreviewClick = () => {
        params.data.warehousingMedia?.id &&
          window.open(params.data.warehousingMedia.mediaUrl, "_blank");
      };

      return params.data.warehousingMedia?.id ? (
        <StyledFlex justifyContent="center" alignItems="center" gap={8}>
          <Typo typoType="b9r">
            {params.data.warehousingMedia.originalFileName}
          </Typo>
          <IconButton
            buttonSize={24}
            buttonColor="blue"
            buttonGrade="secondary"
            onClick={handlePreviewClick}
          >
            <Icon iconSrc={PreviewSvg} iconSize={16} />
          </IconButton>
        </StyledFlex>
      ) : (
        <Typo typoType="b9r">-</Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "warehousingContainers",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.warehousingContainers?.length >= 1) {
        return (
          <Typo typoType="b9r">
            {params.data.warehousingContainers?.length}{" "}
            {params.data.t("common:ea")}
          </Typo>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },

  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "view",
    lockPosition: "right",
    pinned: "right",
    resizable: false,
    width: 140,
    sortable: false,
    cellRenderer: CELL_TYPE_VIEW_ACTION,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "edit",
    lockPosition: "right",
    pinned: "right",
    resizable: false,
    width: 90,
    sortable: false,
    cellRenderer: CELL_TYPE_EDIT_ACTION,
  },
];

// 발주 상세 페이지 내 입고목록의 컨테이너
export const columnSalesContainerList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: false,
    cellStyle: { textAlign: "center" },
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "containerNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.containerNo) {
        return <Typo typoType="b9r">{params.data.containerNo}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "sealNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.sealNo) {
        return <Typo typoType="b9r">{params.data.sealNo}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "unitQuantity",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.unitQuantity) {
        return <Typo typoType="b9r">{params.data.unitQuantity}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "netWeight",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data?.netWeight) {
        return <Typo typoType="b9r">{params.data?.netWeight}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "unitPrice",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data?.unitPrice && params.data.unitPriceUnit) {
        return (
          <Typo typoType="b9r">
            {Number(params.data?.unitPrice).toLocaleString("ko-KR")}{" "}
            {params.data?.unitPriceUnit}
          </Typo>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "taskNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.taskNo) {
        return <Typo typoType="b9r">{params.data.taskNo}</Typo>;
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "blNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.blNo) {
        return (
          <TableBlueText typoType="b9r" color="systemBlue2">
            {params.data.blNo}
          </TableBlueText>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "poNo",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.poNo) {
        return (
          <TableBlueText typoType="b9r" color="systemBlue2">
            {params.data.poNo}
          </TableBlueText>
        );
      }
      return <Typo typoType="b9r">-</Typo>;
    },
  },
];

const StyledFlex = styled(Flex)`
  height: 100%;
`;
