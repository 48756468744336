import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  BuyerEmployeeDto,
  BuyerListViewDto,
  BuyerViewDto,
  GenerateBuyerDto,
  GenerateBuyerEmployeeDto,
  GetBuyersRequestParams,
  ModifyBuyerDto,
  ModifyBuyerEmployeeDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["buyerEmployees", "buyer", "buyers"],
});

const buyerApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    createBuyer: build.mutation<Row<BuyerViewDto>, GenerateBuyerDto>({
      query: (body) => ({
        url: "/exporters/this/buyers",
        method: "POST",
        body,
      }),
    }),
    getBuyers: build.query<
      { data: BuyerListViewDto[]; count: number },
      GetBuyersRequestParams
    >({
      query: (params) => ({
        url: "/exporters/this/buyers",
        params,
      }),
      transformResponse: (res: Rows<BuyerListViewDto>) => ({
        data: res.rows,
        count: res.count,
      }),
    }),
    getBuyer: build.query<BuyerViewDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/buyers/${id}`,
      }),
      providesTags: ["buyer"],
      transformResponse: (res: Row<BuyerViewDto>) => res.row,
    }),
    editBuyer: build.mutation<
      Row<BuyerViewDto>,
      ModifyBuyerDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/exporters/this/buyers/${body.id}`,
          method: "PUT",
          body: restBody,
        };
      },
    }),
    createBuyerEmployees: build.mutation<
      Row<BuyerEmployeeDto>,
      GenerateBuyerEmployeeDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/exporters/this/buyers/${id}/buyer-employees`,
          method: "POST",
          body: restBody,
        };
      },
    }),
    getBuyerEmployees: build.query<
      Rows<BuyerEmployeeDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: (params) => {
        const { id, ...restParams } = params;
        return {
          url: `/exporters/this/buyers/${params.id}/buyer-employees`,
          params: restParams,
        };
      },
      providesTags: ["buyerEmployees"],
    }),
    editBuyerEmployees: build.mutation<
      Row<BuyerEmployeeDto>,
      ModifyBuyerEmployeeDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/exporters/this/buyer-employees/${body.id}`,
          method: "PUT",
          body: restBody,
        };
      },
    }),

    // 바이어가 계약에서 취급한 아이템 목록을 조회하는 API 입니다.
    getBuyerContractExporterItem: build.query<
      Rows<{ item: string }>,
      { id: number }
    >({
      query: ({ id }) => {
        return {
          url: `/exporters/this/buyers/${id}/contract-exporter-items`,
        };
      },
    }),

    // 바이어를 토큰없이 단일 조회하는 API 입니다 (임시회원가입 시 사용).
    getBuyersNoToken: build.query<BuyerViewDto, { id: number }>({
      query: ({ id }) => {
        return {
          url: `/buyers/${id}`,
        };
      },
      transformResponse: (res: Row<BuyerViewDto>) => res.row,
    }),

    // 바이어 코드(buyerNameCode) 존재 유무 확인 API (검색제외 시키고 싶은 바이어의 Id를 입력가능)
    checkClientNameCode: build.query<
      Row<{ message: string }>,
      { buyerNameCode: string; buyerId?: number }
    >({
      query: (params) => {
        return {
          url: "/exporters/this/buyer/buyer-name-code-check",
          params,
        };
      },
    }),
    deleteBuyerEmployee: build.mutation<void, { id: number }>({
      query: ({ id }) => {
        return {
          method: "DELETE",
          url: `/exporters/this/buyer-employees/${id}`,
        };
      },
      invalidatesTags: ["buyerEmployees"],
    }),

    invalidatesBuyer: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ["buyer", "buyerEmployees"],
    }),
  }),
});

export const {
  useGetBuyersQuery,
  useLazyGetBuyerQuery,
  useLazyGetBuyersQuery,
  useGetBuyerQuery,
  useGetBuyerEmployeesQuery,
  useLazyGetBuyerEmployeesQuery,
  useGetBuyerContractExporterItemQuery,
  useLazyGetBuyersNoTokenQuery,
  useLazyCheckClientNameCodeQuery,

  useCreateBuyerMutation,
  useCreateBuyerEmployeesMutation,
  useEditBuyerEmployeesMutation,
  useEditBuyerMutation,
  useInvalidatesBuyerMutation,
  useDeleteBuyerEmployeeMutation,
} = buyerApi;
export { buyerApi };
