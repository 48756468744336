import Typo from "@/src/components/atom/Typo";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";

export const columnShardLoadingTab: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "item",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.item ? (
        <Typo typoType="b9r">{params.data.item}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "numberOfTaskContainer",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfTaskContainer && params.data.containerType ? (
        <Typo typoType="b9r">
          {params.data.numberOfTaskContainer}x{params.data.containerType}
        </Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "totalGrossWeight",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalGrossWeight ? (
        <Typo typoType="b9r">{params.data.totalGrossWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "totalNetWeight",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalNetWeight ? (
        <Typo typoType="b9r">{params.data.totalNetWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "totalQuantity",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalQuantity ? (
        <Typo typoType="b9r">{params.data.totalQuantity}</Typo>
      ) : (
        "-"
      );
    },
  },
];
