import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAlert from "@/src/hooks/useAlert";
import colorSet from "@/src/styles/color";
import { createViewAction } from "@/src/utils/row-data-util";
import { columnShardContainerTab } from "../../columns/columnShardContainerTab";
import { useSearchParams } from "react-router-dom";
import {
  useLazyGetShipmentShareContainerIntegrationQuery,
  useLazyGetShipmentShareContainerListQuery,
} from "@/src/store/apis/shipments/shipmentShare";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import {
  useContainerIdToSeaVantageUrlMutation,
  useSharedShipmentKeyWithcontainerIdToSeaVantageUrlMutation,
} from "@/src/store/apis/seavantage";
import SharedContainerPhotosDialog from "../dialogs/SharedContainerPhotosDialog";
import { isUndefined } from "@/src/utils/is";
import {
  useLazyGetShipmentDetailContainersIntegrationQuery,
  useLazyGetShipmentDetailContainersQuery,
} from "@/src/store/apis/shipments/shipmentDetail";
import Loader from "@/src/components/atom/Loader";
import { useTranslation } from "react-i18next";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";

enum DialogState {
  NULL,
  ALL_CONTAINER_PHOTO_DIALOG,
}

interface ShipmentWithContainerProps {
  sharedShipmentId: number;
  shipmentShareKey: string;
}

function ShipmentWithContainer({
  shipmentShareKey,
  sharedShipmentId,
}: ShipmentWithContainerProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();

  const [columnDefs] = useState<ColDef[]>(columnShardContainerTab);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState(false);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [containerIdForDialog, setContainerIdFofDialog] = useState<number>();

  const isPreview = searchParams.get("preview") === "true";
  const previewShipmentId = Number(searchParams.get("shipmentId"));

  // API
  const [containerIdToSeaVantageUrl] =
    useSharedShipmentKeyWithcontainerIdToSeaVantageUrlMutation();
  const [
    getSharedShipmentDetailContainers,
    { isSharedFetching, count, sharedShipmentWithContainerList },
  ] = useLazyGetShipmentShareContainerListQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isSharedFetching: isFetching,
        isError,
        count: !isUnstable ? currentData.count : 0,
        sharedShipmentWithContainerList: !isUnstable
          ? currentData.rows
          : undefined,
      };
    },
  });
  const [
    getShipmentShareContainerIntegration,
    {
      numberOfContainerTypeB,
      totalGrossWeight,
      totalNetWeight,
      totalQuantity,
      isContainersIntegrationFetching,
    },
  ] = useLazyGetShipmentShareContainerIntegrationQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isContainersIntegrationFetching: isFetching,
        numberOfContainerTypeB: !isUnstable
          ? currentData.numberOfContainerTypeB ?? "-"
          : "-",
        totalGrossWeight: !isUnstable
          ? currentData.totalGrossWeight ?? "-"
          : "-",
        totalNetWeight: !isUnstable ? currentData.totalNetWeight ?? "-" : "-",
        totalQuantity: !isUnstable ? currentData.totalQuantity ?? "-" : "-",
      };
    },
  });

  // Preview API
  const [previewContainerIdToSeaVantageUrl] =
    useContainerIdToSeaVantageUrlMutation();
  const [
    getShipmentDetailContainersPreview,
    { isPreviewFetching, shipmentDetailContainersPreviewData, previewCount },
  ] = useLazyGetShipmentDetailContainersQuery({
    selectFromResult: ({ isError, isFetching, currentData }) => {
      const isErrorAndUndefined = isError || currentData === undefined;

      return {
        isPreviewFetching: isFetching,
        previewCount: !isErrorAndUndefined ? currentData.count : 0,
        shipmentDetailContainersPreviewData: !isErrorAndUndefined
          ? currentData.rows
          : undefined,
      };
    },
  });
  const [
    getShipmentShareContainerIntegrationPreview,
    {
      previewNumberOfContainerTypeB,
      previewTotalGrossWeight,
      previewTotalNetWeight,
      previewTotalQuantity,
      isPreviewContainersIntegrationFetching,
    },
  ] = useLazyGetShipmentDetailContainersIntegrationQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isPreviewContainersIntegrationFetching: isFetching,
        previewNumberOfContainerTypeB: !isUnstable
          ? currentData.numberOfContainerTypeB ?? "-"
          : "-",
        previewTotalGrossWeight: !isUnstable
          ? currentData.totalGrossWeight ?? "-"
          : "-",
        previewTotalNetWeight: !isUnstable
          ? currentData.totalNetWeight ?? "-"
          : "-",
        previewTotalQuantity: !isUnstable
          ? currentData.totalQuantity ?? "-"
          : "-",
      };
    },
  });

  const handleViewButtonClick = (data: any) => {
    setContainerIdFofDialog(data.id);
    setDialogState(DialogState.ALL_CONTAINER_PHOTO_DIALOG);
  };

  // Preview Integration;
  const getContainerIntegrationPreviewData = async () => {
    try {
      await getShipmentShareContainerIntegrationPreview({
        id: previewShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  // Preview Submit
  const handlePreviewSubmit = useCallback(
    async (param?: { page?: number; pageSize?: number }) => {
      const page = param?.page || pagination.page;
      const pageSize = param?.pageSize || pagination.pageSize;
      const containerParams = { page, pageSize, id: previewShipmentId };

      try {
        await getShipmentDetailContainersPreview(containerParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getShipmentDetailContainersPreview,
      pagination.page,
      pagination.pageSize,
      previewShipmentId,
    ]
  );

  // Integration;
  const getContainerIntegration = async () => {
    try {
      await getShipmentShareContainerIntegration({
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  // Submit
  const handleSubmit = useCallback(
    async (params?: { page?: number; pageSize?: number }) => {
      const page = params?.page || pagination.page;
      const pageSize = params?.pageSize || pagination.pageSize;
      const containerParams = {
        page,
        pageSize,
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      };

      try {
        await getSharedShipmentDetailContainers(containerParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getSharedShipmentDetailContainers,
      pagination.page,
      pagination.pageSize,
      sharedShipmentId,
      shipmentShareKey,
    ]
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    if (isPreview) {
      return handlePreviewSubmit({
        page,
        pageSize,
      });
    }

    handleSubmit({
      page,
      pageSize,
    });
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "no",
        langKey: "table:no",
      },
      {
        columnKey: "item",
        langKey: "table:item",
      },
      {
        columnKey: "containerNo",
        langKey: "table:containerNo",
      },
      {
        columnKey: "sealNo",
        langKey: "table:sealNo",
      },
      {
        columnKey: "unitQuantity",
        langKey: "table:unitQuantity",
      },
      {
        columnKey: "grossWeight",
        langKey: "table:grossWeight",
      },
      {
        columnKey: "netWeight",
        langKey: "table:netWeight",
      },
      {
        columnKey: "photo",
        langKey: "table:photo",
      },
    ],
  });

  // Loading
  useEffect(() => {
    if (isPreview) {
      if (isPreviewFetching && gridRef.current) {
        gridRef?.current?.api?.showLoadingOverlay();
      }
    }

    if (isSharedFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isSharedFetching, isPreview, isPreviewFetching]);

  useEffect(() => {
    if (isPreview) {
      handlePreviewSubmit();
      getContainerIntegrationPreviewData();
    } else {
      handleSubmit();
      getContainerIntegration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContainerNoClick = async (containerId: number) => {
    try {
      if (isPreview) {
        const { cargoTrackUrl } = await previewContainerIdToSeaVantageUrl({
          containerId,
        }).unwrap();

        return window.open(cargoTrackUrl, "_blank");
      }

      const { cargoTrackUrl } = await containerIdToSeaVantageUrl({
        containerId,
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) {
      return null;
    }

    // Preview
    if (isPreview && dialogState === DialogState.ALL_CONTAINER_PHOTO_DIALOG) {
      return (
        <SharedContainerPhotosDialog
          onClose={() => setDialogState(DialogState.NULL)}
          sharedShipmentId={sharedShipmentId}
          initialSelectedSingleContainer={containerIdForDialog}
        />
      );
    }

    // Shared
    if (dialogState === DialogState.ALL_CONTAINER_PHOTO_DIALOG) {
      return (
        <SharedContainerPhotosDialog
          onClose={() => setDialogState(DialogState.NULL)}
          shipmentShareKey={shipmentShareKey}
          sharedShipmentId={sharedShipmentId}
          initialSelectedSingleContainer={containerIdForDialog}
        />
      );
    }
  };

  const renderContainerTotalInfo = () => {
    const containerIntegration = {
      numberOfContainerTypeB: isPreview
        ? previewNumberOfContainerTypeB
        : numberOfContainerTypeB,
      totalGrossWeight: isPreview ? previewTotalGrossWeight : totalGrossWeight,
      totalNetWeight: isPreview ? previewTotalNetWeight : totalNetWeight,
      totalQuantity: isPreview ? previewTotalQuantity : totalQuantity,
      isFetching: isPreview
        ? isPreviewContainersIntegrationFetching
        : isContainersIntegrationFetching,
    };

    if (containerIntegration.isFetching) {
      return (
        <ContainerInfo>
          <Loader size={36} />
        </ContainerInfo>
      );
    } else {
      return (
        <ContainerInfo>
          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              {t("common:totalContainer")}
            </Typo>
            <BreakWordTypo color="blue2" typoType="h4">
              {containerIntegration.numberOfContainerTypeB}
            </BreakWordTypo>
          </InfoItem>

          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              {t("common:totalGrossWeight")}
            </Typo>
            <BreakWordTypo color="blue2" typoType="h4">
              {containerIntegration.totalGrossWeight}
            </BreakWordTypo>
          </InfoItem>

          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              {t("common:totalNetWeight")}
            </Typo>
            <BreakWordTypo color="blue2" typoType="h4">
              {containerIntegration.totalNetWeight}
            </BreakWordTypo>
          </InfoItem>

          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              {t("common:totalUnitQty")}
            </Typo>
            <BreakWordTypo color="blue2" typoType="h4">
              {containerIntegration.totalQuantity}
            </BreakWordTypo>
          </InfoItem>
        </ContainerInfo>
      );
    }
  };

  return (
    <SectionCard cardTitle={t("common:containerList")}>
      <TableContainer>
        {renderContainerTotalInfo()}

        <Table
          ref={gridRef}
          rowData={
            isPreview
              ? shipmentDetailContainersPreviewData
              : sharedShipmentWithContainerList
          }
          totalPage={isPreview ? previewCount : count}
          columnDefs={columnDefs}
          isPaginationDataMaping
          handlePaginationClick={handlePaginationClick}
          pageSize={pagination.pageSize}
          page={pagination.page}
          rowClass={"ag-row-none-cursor"}
          onCellClicked={(e) => {
            const selectShipmentRow = e.node.data;
            const containerNo = e.column.getColDef().field === "containerNo";

            if (containerNo && selectShipmentRow.containerNo) {
              handleContainerNoClick(selectShipmentRow.id);
            }
          }}
          components={{
            ...createViewAction(handleViewButtonClick),
          }}
          onGridReady={() => setIsReady(true)}
        />
      </TableContainer>

      {/* Dialog */}
      {renderDialog()}
    </SectionCard>
  );
}

export default ShipmentWithContainer;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ContainerInfo = styled.ul`
  display: flex;
  justify-content: center;
`;

const InfoItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;
  padding: 16px 8px;
  border-right: 1px solid ${colorSet.gray10};
  text-align: center;

  &:last-child {
    border-right: none;
  }
`;
