import { useState } from "react";
import styled, { css } from "styled-components";
import colorSet, { ColorType } from "@/src/styles/color";
import Dot from "@/src/components/atom/Dot";
import Typo from "@/src/components/atom/Typo";
import DoughnutChart from "@/src/components/atom/chart/DoughnutChart";
import TabItem from "@/src/components/molecule/TabItem";
import typo from "@/src/styles/typography";
import Select from "@/src/components/atom/Select";
import { Button } from "@/src/components/atom/Button";
import { TransactionVolumeChart } from "./BuyerStatistics";
import { Dayjs } from "dayjs";
import { isUndefined } from "@/src/utils/is";
import {
  useGetExporterItemTopRankStatisticInfoByBuyerQuery,
  useGetExporterItemTopRankStatisticInfoByItemCodeQuery,
  useGetMainCategoryTopRankStatisticInfoQuery,
} from "@/src/store/apis/dashboard";
import {
  ExporterItemTopRankByBuyerDto,
  ExporterItemTopRankByItemCodeDto,
} from "@/src/store/apis/dashboard/interface";
import { BAR_BLUE_VIOLET_COLOR_SET } from "../util/chart";
import ItemBuyerRank from "./dialog/ItemBuyerRank";
import ItemItemCodeRank from "./dialog/ItemItemCodeRank";
import { useTranslation } from "react-i18next";

interface CategoryStatisticsProps {
  tab: "buyer" | "itemCode";
  category: string;
  isCategoryFetching: boolean;
  categoryList: { label: string; value: string }[];
  period: [Dayjs, Dayjs];
  onTabChange: (tab: CategoryStatisticsProps["tab"]) => void;
  onCategoryChange: (value: string) => void;
}

const mainCategoryChartColor = [
  "chartViolet1",
  "chartViolet2",
  "chartViolet3",
  "chartViolet4",
  "chartViolet5",
];

const ALL = "ALL";
const emptyArray: any[] = [];
enum DialogState {
  NULL,
  ITEM_BUYER_RANK,
  ITEM_ITEM_CODE_RANK,
}

const CategoryStatistics = ({
  tab,
  category,
  categoryList,
  period,
  onTabChange,
  onCategoryChange,
}: CategoryStatisticsProps) => {
  const { t } = useTranslation("dashboard");
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const { topRankList, mainCategoryTopRankTotalWeight } =
    useGetMainCategoryTopRankStatisticInfoQuery(
      {
        etdAtFrom: period[0].startOf("day").toISOString(),
        etdAtTo: period[1].endOf("day").toISOString(),
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isFetching || isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            topRankList: isStable
              ? currentData.row.mainCategoryTopRankList
              : [],
            mainCategoryTopRankTotalWeight: isStable
              ? currentData.row.mainCategoryTopRankTotalWeight
              : "-",
          };
        },
      }
    );

  const { exporterItemTopRankListByBuyer } =
    useGetExporterItemTopRankStatisticInfoByBuyerQuery(
      {
        etdAtFrom: period[0].startOf("day").toISOString(),
        etdAtTo: period[1].endOf("day").toISOString(),
        mainCategoryCodeItemName: category === ALL ? undefined : category,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: tab === "itemCode",
        selectFromResult: ({ isFetching, isError, currentData }) => {
          const isUnstable = isFetching || isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            exporterItemTopRankListByBuyer: isStable
              ? currentData.row.exporterItemTopRankListByBuyer
              : (emptyArray as ExporterItemTopRankByBuyerDto[]),
          };
        },
      }
    );

  const { exporterItemTopRankListByItemCode } =
    useGetExporterItemTopRankStatisticInfoByItemCodeQuery(
      {
        etdAtFrom: period[0].startOf("day").toISOString(),
        etdAtTo: period[1].endOf("day").toISOString(),
        mainCategoryCodeItemName: category === ALL ? undefined : category,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: tab === "buyer",
        selectFromResult: ({ isFetching, isError, currentData }) => {
          const isUnstable = isFetching || isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            exporterItemTopRankListByItemCode: isStable
              ? currentData.row.exporterItemTopRankListByItemCode
              : (emptyArray as ExporterItemTopRankByItemCodeDto[]),
          };
        },
      }
    );

  const mainCategoryTopFiveData = topRankList.reduce<{
    labels: string[];
    data: number[];
  }>(
    (acc, val) => ({
      labels: [...acc.labels, val.mainCategoryCodeItemName],
      data: [...acc.data, Number(val.totalNetWeight)],
    }),
    {
      labels: [],
      data: [],
    }
  );

  const topFiveMainCategoryIndex: {
    chipColor: ColorType;
    label: string;
    value: string;
  }[] = topRankList.map(
    (
      {
        mainCategoryCodeItemName,
        totalNetWeightPercent,
        totalNetWeight,
        totalNetWeightUnit,
      },
      index
    ) => {
      return {
        chipColor: mainCategoryChartColor[index] as ColorType,
        label: mainCategoryCodeItemName,
        value: `${Number(
          totalNetWeight
        ).toLocaleString()} ${totalNetWeightUnit} (${totalNetWeightPercent}%)`,
      };
    }
  );

  const datasetByBuyer = exporterItemTopRankListByBuyer.reduce<
    {
      buyerId: number;
      data: (number | null)[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      label: string;
    }[]
  >((acc, val, idx) => {
    val.transactionInfoListByBuyer.forEach(
      ({ buyerId, buyerNameCode, netWeight }) => {
        const colorIndex = acc.filter(({ buyerId }) => buyerId !== 0).length;
        const item = acc.find((stack) => stack.buyerId === buyerId);
        if (item) {
          const data = item.data;
          const nullishData = data.map((item) => (!item ? null : item));
          nullishData[idx] = Number(netWeight);
          acc = acc.map((current) =>
            current.buyerId === buyerId
              ? {
                  ...current,
                  data: nullishData.map((item) => (!item ? null : item)),
                }
              : current
          );
        } else {
          const dataArray = [];
          dataArray[idx] = Number(netWeight);
          acc.push({
            buyerId,
            data: dataArray,
            backgroundColor:
              buyerId === 0
                ? colorSet.chartEtc
                : BAR_BLUE_VIOLET_COLOR_SET[colorIndex],
            borderColor: "white",
            borderWidth: 0.7,
            maxBarThickness: 64,
            label: buyerNameCode,
          });
        }
      }
    );

    return acc;
  }, []);

  const datasetByItemCode = exporterItemTopRankListByItemCode.reduce<
    {
      exporterItemCodeId: number;
      data: (number | null)[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      label: string;
    }[]
  >((acc, val, idx) => {
    val.transactionInfoListByItemCode.forEach(
      ({ exporterItemCodeId, exporterItemCode, netWeight }) => {
        const colorIndex = acc.filter(
          ({ exporterItemCodeId }) => exporterItemCodeId !== 0
        ).length;
        const item = acc.find(
          (stack) => stack.exporterItemCodeId === exporterItemCodeId
        );

        if (item) {
          const data = item.data;
          const nullishData = data.map((item) => (!item ? null : item));
          nullishData[idx] = Number(netWeight);
          acc = acc.map((current) =>
            current.exporterItemCodeId === exporterItemCodeId
              ? {
                  ...current,
                  data: nullishData.map((item) => (!item ? null : item)),
                }
              : current
          );
        } else {
          const dataArray = [];
          dataArray[idx] = Number(netWeight);
          acc.push({
            exporterItemCodeId,
            data: dataArray,
            backgroundColor:
              exporterItemCodeId === 0
                ? colorSet.chartEtc
                : BAR_BLUE_VIOLET_COLOR_SET[colorIndex],
            borderColor: "white",
            borderWidth: 0.7,
            maxBarThickness: 64,
            label: exporterItemCode,
          });
        }
      }
    );

    return acc;
  }, []);

  const handleChange = async ({ tab }: { tab: "buyer" | "itemCode" }) => {
    onTabChange(tab);
  };

  const dataset =
    tab === "buyer"
      ? datasetByBuyer.map((item) => {
          const { buyerId, ...rest } = item;
          return rest;
        })
      : datasetByItemCode.map((item) => {
          const { exporterItemCodeId, ...rest } = item;
          return rest;
        });

  const labels =
    tab === "buyer"
      ? exporterItemTopRankListByBuyer.map(({ exporterItem }) => exporterItem)
      : exporterItemTopRankListByItemCode.map(
          ({ exporterItem }) => exporterItem
        );

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) {
      return null;
    }

    if (dialogState === DialogState.ITEM_BUYER_RANK) {
      return (
        <ItemBuyerRank
          from={period[0]}
          to={period[1]}
          mainCategoryCodeItemName={category === ALL ? undefined : category}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.ITEM_ITEM_CODE_RANK) {
      return (
        <ItemItemCodeRank
          from={period[0]}
          to={period[1]}
          mainCategoryCodeItemName={category === ALL ? undefined : category}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  return (
    <Flex gap={16}>
      <Flex gap={16} direction="column">
        <div>
          <DoughnutChart
            data={{
              labels: mainCategoryTopFiveData.labels,
              datasets: [
                {
                  data: mainCategoryTopFiveData.data,
                  backgroundColor: [
                    colorSet.chartViolet1,
                    colorSet.chartViolet2,
                    colorSet.chartViolet3,
                    colorSet.chartViolet4,
                    colorSet.chartViolet5,
                  ],
                  borderRadius:
                    mainCategoryTopFiveData.data.length === 1 ? 0 : 4,
                  borderColor:
                    mainCategoryTopFiveData.data.length === 1
                      ? colorSet.chartViolet1
                      : undefined,
                },
              ],
            }}
            height={300}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
                title: {
                  display: true,
                  color: colorSet.gray5,
                  padding: {
                    top: 24,
                    bottom: 32,
                  },
                  font: {
                    size: 15,
                  },
                  text: t("dashboard:exporter.transactionVolumeTop5ByCategory"),
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>
        <p>
          <Typo typoType="h7">{t("common:total")}</Typo>{" "}
          <Typo typoType="h4">{mainCategoryTopRankTotalWeight}</Typo>
        </p>

        <StyledList>
          {topFiveMainCategoryIndex.map(({ chipColor, label, value }) => {
            return (
              <ListItem key={label}>
                <LeftListItem>
                  <Flex gap={4}>
                    <Dot dotColor={chipColor} />
                    <Typo typoType="b10r" color="gray6">
                      {label}
                    </Typo>
                  </Flex>
                </LeftListItem>

                <RightListItem>
                  <Typo typoType="b10r">{value}</Typo>
                </RightListItem>
              </ListItem>
            );
          })}
        </StyledList>
      </Flex>

      <FullWidthDiv>
        <SpaceBetween>
          <Tabs>
            <StyledTabItem
              tabValue={"buyer"}
              tabIndex={tab === "buyer" ? 0 : -1}
              onFocus={() => handleChange({ tab: "buyer" })}
              onClick={() => handleChange({ tab: "buyer" })}
              data-selected={tab === "buyer"}
            >
              {t("exporter.tabs.buyer")}
            </StyledTabItem>
            <StyledTabItem
              tabValue={"itemCode"}
              tabIndex={tab === "itemCode" ? 0 : -1}
              onFocus={() => handleChange({ tab: "itemCode" })}
              onClick={() => handleChange({ tab: "itemCode" })}
              data-selected={tab === "itemCode"}
            >
              {t("exporter.itemCode")}
            </StyledTabItem>
          </Tabs>

          <Flex gap={8}>
            <StyledLabel>
              <Typo typoType="b10r">{t("common:category")}</Typo>

              <StyledSelect
                size="middle"
                value={category}
                options={categoryList}
                onChange={(value) => onCategoryChange(value)}
              />
            </StyledLabel>
          </Flex>
        </SpaceBetween>

        <Flex gap={8} direction="column" style={{ width: "100%" }}>
          <ChartTitleDiv>
            <Typo color="gray5" typoType="h8">
              {t("exporter.top5ItemsByVolume")} (
              {tab === "buyer" ? t("common:buyer") : t("common:itemCode")}{" "}
              {t("exporter.basis")})
            </Typo>

            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() => {
                if (tab === "buyer") {
                  setDialogState(DialogState.ITEM_BUYER_RANK);
                } else {
                  setDialogState(DialogState.ITEM_ITEM_CODE_RANK);
                }
              }}
            >
              {t("exporter.moreDetails")}
            </Button>
          </ChartTitleDiv>

          <div style={{ width: "100%" }}>
            <TransactionVolumeChart
              datasets={dataset}
              labels={labels}
              displayY={false}
            />
          </div>
        </Flex>
      </FullWidthDiv>
      {renderDialog()}
    </Flex>
  );
};

export default CategoryStatistics;

const Flex = styled.div<{ gap: number; direction?: "column" | "row" }>`
  display: flex;
  align-items: center;
  ${({ direction }) =>
    direction
      ? css`
          flex-direction: ${direction};
        `
      : undefined};

  ${({ gap }) => css`
    gap: ${gap}px;
  `};
`;

const FullWidthDiv = styled.div`
  width: calc(100% - 316px);
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px 16px 8px;
`;

const StyledList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftListItem = styled.div`
  flex: 1;
`;
const RightListItem = styled.div`
  width: 124px;
  text-align: right;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${colorSet.gray10};
`;

const ChartTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 16px;
`;

const Tabs = styled.div`
  display: flex;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  width: 98px;
  cursor: pointer;
  background: ${colorSet.gray11};
  color: ${colorSet.gray7};
  border: 1px solid ${colorSet.gray9};
  ${typo.b9m};

  &[data-selected="true"] {
    background: ${colorSet.white};
    color: ${colorSet.blue4};
    border: 1px solid ${colorSet.blue4};
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0px;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;
