import React from "react";
import AlertDialog from "../../atom/AlertDialog";
import { useTranslation } from "react-i18next";

interface NoRegisteredSignatureAlertDialogProps {
  open: boolean;
  onOpenChange: () => void;
  onOk: () => void;
  onCancel: () => void;
}

const NoRegisteredSignatureAlertDialog = ({
  open,
  onOk,
  onOpenChange,
  onCancel,
}: NoRegisteredSignatureAlertDialogProps) => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}
      title={t(
        "contract:importer.detail.alertDialog.noRegisteredSignatureTitle"
      )}
      description={t(
        "contract:importer.detail.alertDialog.noRegisteredSignatureDescription"
      )}
      onOk={onOk}
      onCancel={onCancel}
      cancelText={t("common:cancel")}
      okText={t("common:ok")}
    />
  );
};

export default NoRegisteredSignatureAlertDialog;
