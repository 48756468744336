import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { persistor } from "../..";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import ERROR_CODE from "./errorCode";
import qs from "qs";
import PUBLIC_PATH from "@/src/routes/public/path";
import i18n from "@/src/i18n";

const QUERY_TIME_OUT = 60000;

export const unAuthLogic = () => {
  persistor.purge();
  window.history.replaceState("", "", PUBLIC_PATH.LOGIN);
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("accept-language", i18n.language);

    return headers;
  },
  timeout: QUERY_TIME_OUT,
  paramsSerializer: (params) => qs.stringify(params, { indices: false }),
});

const imageApiBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_IMAGE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("accept-language", i18n.language);

    return headers;
  },
  timeout: QUERY_TIME_OUT,
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result: QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta> =
    await baseQuery(args, api, extraOptions);

  if (result.error) {
    const errorData: any = result.error.data;

    ERROR_CODE.includes(errorData?.errorCode) && unAuthLogic();
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export const imageApiSlice = createApi({
  reducerPath: "imageApiSlice",
  baseQuery: imageApiBaseQuery,
  endpoints: () => ({}),
});

export const currencyApiSlice = createApi({
  reducerPath: "currencyApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://openexchangerates.org",
  }),
  endpoints: () => ({}),
});
