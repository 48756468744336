import { useState } from "react";
import styled from "styled-components";
import Typo from "@/src/components/atom/Typo";
import Icon from "@/src/components/atom/Icon";
import Dialog from "@/src/components/atom/Dialog";
import TextArea from "@/src/components/atom/TextArea";
import Button from "@/src/components/atom/Button/BasicButton";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Flex from "@/src/components/molecule/Flex";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { useTranslation } from "react-i18next";

interface AddMemoDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (memo: string) => void;
  isLoading: boolean;
}

function AddMemoDialog({
  open,
  onOpenChange,
  onSave,
  isLoading = false,
}: AddMemoDialogProps) {
  const { t } = useTranslation();

  const [memo, setMemo] = useState("");

  const handleSaveClick = () => {
    onSave(memo);
  };

  return (
    <Dialog
      title={t("addMemoDialog:addMemo")}
      open={open}
      onOpenChange={onOpenChange}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(false)}
          >
            <Typo typoType="btn3m">{t("addMemoDialog:button.cancel")}</Typo>
          </Button>

          <Button
            onClick={handleSaveClick}
            disabled={memo.length === 0 || isLoading}
            isLoading={isLoading}
          >
            <Typo color="white" typoType="btn3m">
              {t("addMemoDialog:button.save")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <Flex flexDirection="column" gap={16}>
        <Flex alignItems="center" gap={6}>
          <StyledIcon iconSrc={InfoSvg} />
          <Typo typoType="b9r" color="gray6">
            {t("addMemoDialog:memoInfo")}
          </Typo>
        </Flex>

        <StyledTextArea
          placeholder={t("placeholder:memo")}
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          maxLength={500}
        />
      </Flex>
    </Dialog>
  );
}

export default AddMemoDialog;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const StyledTextArea = styled(TextArea)`
  height: 218px;
`;
