import { Button, IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import ResetSvg from "@/src/assets/icons/icon-filter-reset.svg";
import { useTranslation } from "react-i18next";

interface DashboardFilterProps {
  onReset: () => void;
  onSubmit: () => void;
}

function DashboardFilter({
  onReset,
  onSubmit,
  children,
}: PropsWithChildren<DashboardFilterProps>) {
  const { t } = useTranslation();

  return (
    <StyledSectionCardWithoutHeader>
      <Flex flexDirection="column" gap={8}>
        <Typo typoType="b8m" color="gray3">
          {t("dashboard:importer.filter.label.searchFilter")}
        </Typo>
        {children}

        <ButtonContainer>
          <IconButton
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={onReset}
            buttonSize={32}
            style={{ padding: "6px" }}
          >
            <Icon iconSrc={ResetSvg} iconSize={20} />
          </IconButton>
          <Button
            type="submit"
            buttonColor="blue"
            buttonGrade="secondary"
            onClick={onSubmit}
            buttonSize={32}
          >
            {t("common:search")}
          </Button>
        </ButtonContainer>
      </Flex>
    </StyledSectionCardWithoutHeader>
  );
}

export default DashboardFilter;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  padding: 12px 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
