import React, { useRef, useState } from "react";
import Dialog from "@/components/atom/Dialog";
import CallOut from "@/components/molecule/CallOut";
import Typo from "@/components/atom/Typo";
import InfoSvg from "@/src/assets/icons/icon-info-gray6.svg";
import Icon from "@/components/atom/Icon";
import Flex from "@/components/molecule/Flex";
import Table from "@/components/atom/Table";
import { columnShareableTable } from "./constant/column";
import { Button } from "../../atom/Button";
import {
  useGetSharingContractViaBookingQuery,
  useGetSharingContractViaShipmentQuery,
  useGetSharingContractViaTaskQuery,
} from "@/src/store/apis/sharing";
import { isUndefined } from "@/src/utils/is";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "../../atom/Dialog/DialogFooterContainer";

const EMPTY_ARRAY: [] = [];

interface ShareableContractDialogProps {
  onClose: () => void;
  onShare: () => void;
  type: "booking" | "task" | "shipment";
  id: number;
}

const ShareableContractDialog = ({
  onClose,
  onShare,
  type,
  id,
}: ShareableContractDialogProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [colDefs] = useState(columnShareableTable);
  const { bookingList, isBookingFetching } =
    useGetSharingContractViaBookingQuery(
      {
        bookingId: id,
      },
      {
        skip: type !== "booking",
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            bookingList: isStable ? currentData.rows : EMPTY_ARRAY,
            isBookingFetching: isFetching,
          };
        },
      }
    );
  const { loadingList, isLoadingFetchings } = useGetSharingContractViaTaskQuery(
    {
      taskId: id,
    },
    {
      skip: type !== "task",
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          loadingList: isStable ? currentData.rows : EMPTY_ARRAY,
          isLoadingFetchings: isFetching,
        };
      },
    }
  );
  const { shipmentList, isShipmentFetching } =
    useGetSharingContractViaShipmentQuery(
      {
        shipmentId: id,
      },
      {
        skip: type !== "shipment",
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            shipmentList: isStable ? currentData.rows : EMPTY_ARRAY,
            isShipmentFetching: isFetching,
          };
        },
      }
    );

  const rowData =
    type === "booking"
      ? bookingList
      : type === "task"
      ? loadingList
      : shipmentList;

  const isFetching =
    isBookingFetching || isLoadingFetchings || isShipmentFetching;

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "status",
        langKey: "table:SignatureStatus",
      },
      {
        columnKey: "poNo",
        langKey: "table:poNo",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "qty",
        langKey: "table:qty",
      },
      {
        columnKey: "unitPrice",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "shippingTerms",
        langKey: "table:shippingTerms",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "origin",
        langKey: "table:originLocation",
      },
      {
        columnKey: "lastShipmentDateAt",
        langKey: "table:lastShipmentDate",
      },
    ],
  });

  return (
    <Dialog
      title={t("shareableContractDialog:title")}
      open
      onOpenChange={onClose}
      width={1000}
      footer={
        <DialogFooterContainer>
          <Button buttonColor="black" buttonGrade="tertiary" onClick={onClose}>
            {t("shareableContractDialog:button.cancel")}
          </Button>
          <Button onClick={onShare}>
            {t("shareableContractDialog:button.sharing")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Flex flexDirection="column" gap={16}>
        <CallOut
          icon={<Icon iconSrc={InfoSvg} />}
          value={
            <Typo typoType="b9r" color="gray6">
              {t("shareableContractDialog:description")}
            </Typo>
          }
        />

        <Typo typoType="h6" color="gray5">
          {t("shareableContractDialog:numberOfSharedContracts")}
          <Typo typoType="h6" color="blue4">
            {
              rowData.filter(
                ({ signatureStatus }) => signatureStatus === "LINKED"
              ).length
            }
          </Typo>
          <Typo typoType="b6m" color="gray7">
            /{rowData.length}
          </Typo>
        </Typo>

        <Table
          isPagination={false}
          ref={gridRef}
          rowData={isFetching ? undefined : rowData}
          columnDefs={colDefs}
          onGridReady={() => setIsReady(true)}
        />
      </Flex>
    </Dialog>
  );
};

export default ShareableContractDialog;
