import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import Table from "@/src/components/atom/Table";
import Filter from "@/src/components/molecule/Filter";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { useAppSelector } from "@/src/store";
import { columnContractList } from "./columns";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import ExcelSvg from "@/src/assets/icons/icon-excel.svg";
import ResetSvg from "@/src/assets/icons/icon-reset-circle.svg";
import DoubleLeftSvg from "@/src/assets/icons/icon-double-left.svg";
import SearchIcon from "@/src/assets/icons/icon-search.svg";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import {
  GetContractListFilterRequest,
  GetContractListRequest,
} from "@/src/store/apis/contracts/contractList/interface";
import {
  useLazyGetContractExcelListQuery,
  useLazyGetContractListV2Query,
} from "@/src/store/apis/contracts/contractList";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import useAlert from "@/src/hooks/useAlert";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import useAgGirdSetColumn from "@/src/hooks/useAgGridSetColumn";
import { gridColIdLocalStorage } from "@/src/utils/localStorageFixedColId";
import { isUndefined } from "@/src/utils/is";
import colorSet from "@/src/styles/color";
import Input from "@/src/components/atom/Input";
import Checkbox from "@/src/components/atom/Checkbox";
import typo from "@/src/styles/typography";
import Tag from "@/src/components/atom/Tag";
import { StyledScroll } from "@/src/styles/scroll";
import {
  ContractStatusType,
  SignatureStatusType,
} from "@/src/store/apis/contracts/interface";
import { useTranslation } from "react-i18next";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import ClientsEmptyAlertDialog from "@/src/components/molecule/ClientsEmptyAlertDialog";

const Init: Omit<GetContractListFilterRequest, "buyerIdList"> & {
  buyerIdList: number[];
} = {
  poNo: undefined,
  contractStatus: "all",
  signatureStatus: "all",
  buyerNameCode: "all",
  itemCode: undefined,
  mainCategoryCodeItemName: "all",
  createdAt: undefined,
  createdAtFrom: undefined,
  createdAtTo: undefined,
  scNo: undefined,
  lastShipmentDateAt: undefined,
  lastShipmentDateAtFrom: undefined,
  lastShipmentDateAtTo: undefined,
  buyerIdList: [],
};

const FAST_SEARCH_QUERY_KEY = [
  "contractStatus",
  "signatureStatus",
  "createdAt",
  "mainCategoryCodeItemName",
  "lastShipmentDateAt",
  "buyerNameCode",
];

enum AlertDialogState {
  NULL,
  CLIENT_EMPTY,
}

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];
const buyerEmptyObject: {
  data: BuyerListViewDto[];
  count: number;
} = { data: [], count: 0 };

const ExporterContractPage = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const alert = useAlert();

  const user = useAppSelector((state) => state.auth.user);
  const subscription = useAppSelector((state) => state.subscription);

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    user?.exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  // API
  const [getExcel] = useLazyGetContractExcelListQuery();
  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [
    getContractList,
    { count, isFetching, contractList, originalArgs, isError },
  ] = useLazyGetContractListV2Query({
    selectFromResult: ({ currentData, isFetching, isError, originalArgs }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        isFetching,
        originalArgs,
        count: !isUnstable ? currentData.count : 0,
        contractList: !isUnstable ? currentData.rows : undefined,
        isError,
      };
    },
  });
  const { mainCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategoryCurrentData: !isUnstable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );
  const { buyerCurrentData, isBuyerDataLoading } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isLoading }) => {
        const isUnstable = isError || isUndefined(currentData) || isLoading;

        return {
          isBuyerDataLoading: isLoading,
          buyerCurrentData: !isUnstable ? currentData : buyerEmptyObject,
        };
      },
    }
  );

  const buyerOptionList = buyerCurrentData.data?.map((item) => {
    return { label: item.buyerNameCode, value: String(item.id) };
  });
  const mainCategoryFilterList = mainCategoryCurrentData
    ?.filter((item) =>
      session.currentData?.row.exporter.mainCategoryCodeItemNames.includes(
        item.codeItemName
      )
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });
  const allOptionItem = {
    label: t("contract:exporter.list.placeholder.all"),
    value: "all",
  };

  // State
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isSideContentOpen, setIsSideContentOpen] = useState<boolean>(true);
  const [sellerFilterValue, setSellerFilterValue] = useState<string>("");
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );

  const {
    func: { handleColumnMoved, handleColumnReset },
    state: { col },
  } = useAgGirdSetColumn({
    key: gridColIdLocalStorage.contract,
    columns: columnContractList,
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<
    GetContractListRequest,
    Omit<GetContractListFilterRequest, "buyerIdList"> & {
      buyerIdList: number[];
    }
  >({
    isReady,
    gridRef: gridRef.current,
    fetch: getContractList,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        contractStatus:
          data.contractStatus === "all"
            ? undefined
            : (data?.contractStatus as ContractStatusType),
        signatureStatus:
          data.signatureStatus === "all"
            ? undefined
            : (data?.signatureStatus as SignatureStatusType),
        scNo: data?.scNo || undefined,
        poNo: data?.poNo || undefined,
        buyerNameCode:
          data.buyerNameCode === "all"
            ? undefined
            : buyerOptionList.find(({ value }) => value === data.buyerNameCode)
                ?.label,
        itemCode: data?.itemCode || undefined,
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        createdAt: undefined,
        createdAtFrom: data.createdAt?.[0]
          ? dayjs(data.createdAt?.[0]).startOf("day").toISOString()
          : undefined,
        createdAtTo: data.createdAt?.[1]
          ? dayjs(data.createdAt?.[1]).endOf("day").toISOString()
          : undefined,
        lastShipmentDateAt: undefined,
        lastShipmentDateAtFrom: data.lastShipmentDateAt?.[0]
          ? dayjs(data.lastShipmentDateAt?.[0]).startOf("day").toISOString()
          : undefined,
        lastShipmentDateAtTo: data.lastShipmentDateAt?.[1]
          ? dayjs(data.lastShipmentDateAt?.[1]).endOf("day").toISOString()
          : undefined,
        buyerIdList: !!data.buyerIdList?.length
          ? data.buyerIdList.join()
          : undefined,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        contractStatus: (urlObject.contractStatus ?? "all") as any,
        signatureStatus: (urlObject.signatureStatus ?? "all") as any,
        buyerNameCode:
          buyerOptionList.find(({ label }) => label === urlObject.buyerNameCode)
            ?.value ?? "all",
        itemCode: urlObject.itemCode,
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        createdAt:
          urlObject.createdAtFrom && urlObject.createdAtTo
            ? [dayjs(urlObject.createdAtFrom), dayjs(urlObject.createdAtTo)]
            : undefined,
        createdAtFrom: urlObject.createdAtFrom,
        createdAtTo: urlObject.createdAtTo,
        scNo: urlObject.scNo,
        poNo: urlObject.poNo,
        lastShipmentDateAt:
          urlObject.lastShipmentDateAtFrom && urlObject.lastShipmentDateAtTo
            ? [
                dayjs(urlObject.lastShipmentDateAtFrom),
                dayjs(urlObject.lastShipmentDateAtTo),
              ]
            : undefined,
        lastShipmentDateAtFrom: urlObject.lastShipmentDateAtFrom,
        lastShipmentDateAtTo: urlObject.lastShipmentDateAtTo,
        buyerIdList: urlObject.buyerIdList
          ? urlObject.buyerIdList.split(",").map((id) => Number(id))
          : [],
      };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "isBookmarked",
        langKey: "table:isBookmarked",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerAlias",
      },
      {
        columnKey: "createdAt",
        langKey: "table:contractRegistration",
      },
      {
        columnKey: "contractStatus",
        langKey: "table:status",
      },
      {
        columnKey: "shipmentStatus",
        langKey: "table:beforeShippingArrived",
      },
      {
        columnKey: "scNo",
        langKey: "table:scNo",
      },
      {
        columnKey: "mainCategoryCodeItemName",
        langKey: "table:mainCategory",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "quantity",
        langKey: "table:qty",
      },
      {
        columnKey: "balanceOfContract",
        langKey: "table:balanceOfContract",
      },
      {
        columnKey: "unitPrice",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "shippingTerms",
        langKey: "table:shippingTerms",
      },
      {
        columnKey: "lastShipmentDateAt",
        langKey: "table:lastShipmentDate",
      },
      {
        columnKey: "poNo",
        langKey: "table:poNo",
      },
    ],
  });

  const handleExcelDownload = async () => {
    try {
      if (originalArgs) {
        await getExcel(originalArgs).unwrap();
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const handleClientItemClick = (client: BuyerListViewDto) => {
    if (filterData.buyerIdList?.includes(client.buyerListQueryResultDto.id)) {
      onForceSearch(
        "buyerIdList",
        filterData.buyerIdList.filter(
          (clientId) => clientId !== client.buyerListQueryResultDto.id
        )
      );
    } else {
      onForceSearch("buyerIdList", [
        ...(filterData?.buyerIdList || []),
        client.buyerListQueryResultDto.id,
      ]);
    }
  };

  const handleCreateContractClick = () => {
    // 거래처 등록 0일 경우
    if (
      buyerCurrentData.data.every(({ isActivated }) => isActivated === false)
    ) {
      return setAlertDialogState(AlertDialogState.CLIENT_EMPTY);
    }

    return navigate(EXPORTER_PRIVATE_PATH.CONTRACT_ADD);
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CLIENT_EMPTY) {
      return (
        <ClientsEmptyAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          type={"SELLER"}
        />
      );
    }
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:contract")]}
      pageTitle={t("sideNav:contract")}
      sideAccessory={
        <SideContentSection>
          <FilterSection data-open={isSideContentOpen}>
            <SearchInputContainer>
              <Input
                value={sellerFilterValue}
                onChange={({ target }) => setSellerFilterValue(target.value)}
                placeholder={t(
                  "contract:exporter.list.placeholder.enterBuyerCode"
                )}
                onClear={() => setSellerFilterValue("")}
                suffix={<Icon iconSrc={SearchIcon} iconSize={16} />}
              />
            </SearchInputContainer>

            <SearchInputContainer>
              <Typo typoType="h6" color="gray5">
                {t("contract:exporter.list.label.buyer")}{" "}
              </Typo>
              <Typo typoType="h6" color="blue4">
                {filterData.buyerIdList?.length || 0}
              </Typo>
              <Typo typoType="h6" color="gray7">
                /{buyerCurrentData.count}
              </Typo>
            </SearchInputContainer>

            <AllSellerContainer>
              <Checkbox
                id="client-all"
                className="flex-shrink-0"
                checked={
                  filterData.buyerIdList?.length === buyerCurrentData.count &&
                  filterData.buyerIdList?.length !== 0
                }
                onChange={() => {
                  if (
                    filterData.buyerIdList?.length === buyerCurrentData.count &&
                    filterData.buyerIdList?.length !== 0
                  ) {
                    onForceSearch("buyerIdList", []);
                  } else {
                    onForceSearch(
                      "buyerIdList",
                      buyerCurrentData.data.map(
                        (client) => client.buyerListQueryResultDto.id
                      )
                    );
                  }
                }}
              />
              <SelectAll htmlFor="client-all">
                {t("contract:exporter.list.label.selectAll")}
              </SelectAll>
            </AllSellerContainer>

            <List>
              {buyerCurrentData.data
                .filter((c) =>
                  c.buyerName.toLowerCase().includes(sellerFilterValue)
                )
                .map((client) => {
                  return (
                    <ClientListItem key={client.id}>
                      <Checkbox
                        className="flex-shrink-0"
                        id={client.id.toString()}
                        checked={filterData.buyerIdList?.includes(
                          client.buyerListQueryResultDto.id
                        )}
                        onChange={() => {
                          handleClientItemClick(client);
                        }}
                      />
                      <ClientItemLabel htmlFor={client.id.toString()}>
                        {client.buyerNameCode}
                      </ClientItemLabel>
                    </ClientListItem>
                  );
                })}
            </List>
          </FilterSection>

          <ToggleButtonContainer data-open={isSideContentOpen}>
            <ToggleButton
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              isIconOnly
              onClick={() => setIsSideContentOpen((prev) => !prev)}
            >
              <Icon
                className="double-left-icon"
                iconSrc={DoubleLeftSvg}
                iconSize={20}
              />
            </ToggleButton>
          </ToggleButtonContainer>
        </SideContentSection>
      }
    >
      <FlexColumn>
        <SelectSellerCard>
          <Typo typoType="b8m" color="gray3">
            {t("contract:exporter.list.label.selectBuyer")}
          </Typo>
          <TagList>
            {filterData.buyerIdList?.length === 0 ||
            filterData.buyerIdList?.length === buyerCurrentData.count ? (
              <Tag label={t("common:all")} />
            ) : (
              <>
                {filterData.buyerIdList
                  ?.map((filterClientId) => {
                    return {
                      clientName:
                        buyerCurrentData.data.find(
                          (client) =>
                            client.buyerListQueryResultDto.id === filterClientId
                        )?.buyerName || "",
                      clientId: filterClientId,
                    };
                  })
                  .map((client) => {
                    return (
                      <Tag
                        key={client.clientId}
                        label={client.clientName}
                        onRemove={() => {
                          onForceSearch(
                            "buyerIdList",
                            filterData.buyerIdList?.filter(
                              (clientId) => clientId !== client.clientId
                            )
                          );
                        }}
                      />
                    );
                  })}
              </>
            )}
          </TagList>
        </SelectSellerCard>

        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "poNo",
              label: t("contract:exporter.list.filter.poNo"),
              type: "input",
              props: {
                placeholder: t("contract:exporter.list.placeholder.poNo"),
              },
            },
            {
              key: "createdAt",
              label: t("contract:exporter.list.filter.createdAt"),
              type: "dateRangePicker",
            },
            {
              key: "itemCode",
              label: t("contract:exporter.list.filter.itemCode"),
              type: "input",
              props: {
                placeholder: t("contract:exporter.list.placeholder.itemCode"),
              },
            },
            {
              key: "scNo",
              label: t("contract:exporter.list.filter.scNo"),
              type: "input",
              props: {
                placeholder: t("contract:exporter.list.placeholder.scNo"),
              },
            },
            {
              key: "contractStatus",
              label: t("contract:exporter.list.filter.contractStatus"),
              type: "searchSelect",
              props: {
                placeholder: t("contract:exporter.list.placeholder.all"),
                allowClear: filterData.contractStatus !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("contractStatus", "all");
                    onForceSearch("contractStatus", "all");
                  } else {
                    onFilterDataChange("contractStatus", value);
                    onForceSearch("contractStatus", value);
                  }
                },
              },
            },
            {
              key: "signatureStatus",
              label: t("contract:exporter.list.filter.signatureStatus"),
              type: "searchSelect",
              props: {
                placeholder: t("contract:exporter.list.placeholder.all"),
                allowClear: filterData.signatureStatus !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("signatureStatus", "all");
                    onForceSearch("signatureStatus", "all");
                  } else {
                    onFilterDataChange("signatureStatus", value);
                    onForceSearch("signatureStatus", value);
                  }
                },
              },
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "contractStatus",
              option: [
                allOptionItem,
                {
                  label: t("contract:exporter.list.badge.processing"),
                  value: "PROCESSING",
                },
                {
                  label: t("contract:exporter.list.badge.complete"),
                  value: "COMPLETE",
                },
                {
                  label: t("contract:exporter.list.badge.notLinked"),
                  value: "NOT_LINKED",
                },
              ],
            },
            {
              key: "signatureStatus",
              option: [
                allOptionItem,
                {
                  label: t("contract:exporter.list.signatureBadge.draft"),
                  value: "DRAFT",
                },
                {
                  label: t("contract:exporter.list.signatureBadge.sent"),
                  value: "SENT",
                },
                {
                  label: t("contract:exporter.list.signatureBadge.pending"),
                  value: "PENDING",
                },
                {
                  label: t("contract:exporter.list.signatureBadge.approved"),
                  value: "APPROVED",
                },
                {
                  label: t("contract:exporter.list.signatureBadge.requested"),
                  value: "REQUESTED",
                },
                {
                  label: t("contract:exporter.list.signatureBadge.linked"),
                  value: "LINKED",
                },
              ],
            },
            {
              key: "buyerNameCode",
              option: [allOptionItem, ...buyerOptionList],
            },
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
          ]}
        />

        <div>
          <TableTitleContainer>
            <Typo color="gray4" typoType="b5m">
              {t("contract:exporter.list.table.title")}
            </Typo>

            <ButtonContainer>
              <StyledButton
                buttonSize={40}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleExcelDownload}
              >
                <Icon iconSrc={ExcelSvg} iconSize={16} />
                {t("contract:exporter.list.table.excel")}
              </StyledButton>

              <StyledButton
                buttonSize={40}
                onClick={handleCreateContractClick}
                disabled={isCreateDisabled || isBuyerDataLoading}
                isLoading={isBuyerDataLoading}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />
                {t("contract:exporter.list.table.addButton")}
              </StyledButton>
            </ButtonContainer>
          </TableTitleContainer>
          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={isError ? [] : contractList}
              isPaginationDataMaping
              columnDefs={col}
              handlePaginationClick={(page, pageSize) => {
                onPaginationChange({ page, pageSize });
              }}
              totalPage={count}
              onRowClicked={({ data }) => {
                navigate(`${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${data.id}`);
              }}
              onColumnMoved={handleColumnMoved}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onSortChange={(sortSource, isClickedHeader) =>
                !!isClickedHeader && onSortChange(sortSource as any)
              }
              onGridReady={() => setIsReady(true)}
              paginationRightAccessory={
                <StyledButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleColumnReset}
                  style={{ marginLeft: "8px" }}
                >
                  <Icon iconSrc={ResetSvg} iconSize={16} />
                  <Typo typoType="btn4m">{t("common:columnReset")}</Typo>
                </StyledButton>
              }
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>
      {renderAlertDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterContractPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SideContentSection = styled.section`
  display: flex;
`;

const FilterSection = styled.section`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colorSet.gray9};
  white-space: nowrap;
  transition: all 0.5s;

  &[data-open="true"] {
    width: 220px;
  }

  &[data-open="false"] {
    width: 0px;
    overflow: hidden;
    border: none;
    transform: translateX(-100%) translateZ(-100);
  }
`;

const ToggleButtonContainer = styled.div`
  padding: 12px 0 12px 16px;

  &[data-open="false"] {
    .double-left-icon {
      transform: rotateY(180deg);
    }
  }
`;

const ToggleButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 6px;
  z-index: 1;
`;

const SearchInputContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const List = styled.ul`
  overflow: auto;
  flex: 1;
  ${StyledScroll};
`;

const ClientListItem = styled.li`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const ClientItemLabel = styled.label`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  ${typo.b7r}
`;

const SelectAll = styled(ClientItemLabel)`
  color: ${colorSet.gray4};
`;

const AllSellerContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};
  display: flex;
  align-items: center;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const SelectSellerCard = styled(SectionCardWithoutHeader)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 2px 4px 0px #0000000d;
`;
