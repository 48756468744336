export const DASHBOARD_DATE_STRINGS = {
  sunday: "common:sunday",
  monday: "common:monday",
  tuesday: "common:tuesday",
  wednesday: "common:wednesday",
  thursday: "common:thursday",
  friday: "common:friday",
  saturday: "common:saturday",
};

export const RANK = {
  1: "common:1st",
  2: "common:2nd",
  3: "common:3rd",
  4: "common:4th",
  5: "common:5th",
};
