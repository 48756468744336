import { ReactNode, useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { ReactImageGalleryItem } from "react-image-gallery";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import PhotoCollection, {
  PhotoCollectionProps,
} from "@/src/components/molecule/PhotoCollection";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Icon from "@/src/components/atom/Icon";
import { IconButton } from "@/src/components/atom/Button";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import {
  ContainerDetailViewDto,
  ContainerListViewDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import dayjs from "dayjs";
import { ConfigProvider, ImageProps } from "antd";
import { useTranslation } from "react-i18next";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";
import { Image } from "antd";
import zIndex from "@/src/styles/zIndex";
import Flex from "../../molecule/Flex";
import { blockContextMenuInAntdPreview } from "@/src/utils/blockContextMenuInAntdPreview";
import colorSet from "@/src/styles/color";
import GoogleMap from "../../atom/GoogleMap";
import { isUndefined } from "@/src/utils/is";

export interface ContainerMediaData {
  latitude?: string;
  longitude?: string;
  mediaUrl?: string;
  createAt?: string;
  originalFileName?: string;
  zoomLevel?: number;
}

export interface ViewPhotoCollectionDialogProps
  extends Pick<PhotoCollectionProps, "imgProps" | "thumbnailProps"> {
  items: ReactImageGalleryItem[];
  open: boolean;
  onClose: () => void;
  startIndex?: number;
  selectLoading?: ContainerListViewDto | ContainerDetailViewDto | null;
  mediaDataList: ContainerMediaData[];
  antdImageProps?: ImageProps;
  photoDetailRightAccessory?: ReactNode;
  onSelectImageChange?: (media: ContainerMediaData | null) => void;
  mediaCoordinateRange?: MediaCoordinateRangeType;
  isDownloadBlock?: boolean;
}

export const photoCollectionGoogleMapContainerStyle = {
  width: "100%",
  height: "198px",
  borderRadius: "8px",
};

const ViewPhotoCollectionDialog = ({
  items,
  open,
  onClose,
  startIndex,
  selectLoading,
  mediaDataList,
  imgProps,
  thumbnailProps,
  antdImageProps,
  photoDetailRightAccessory,
  onSelectImageChange,
  mediaCoordinateRange,
  isDownloadBlock,
}: ViewPhotoCollectionDialogProps) => {
  const { t } = useTranslation();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [selectThumbnailIndex, setSelectThumbnailIndex] = useState(startIndex);
  const [selectImage, setSelectImage] = useState<ContainerMediaData | null>(
    null
  );

  const handlePhotoClick = (imageUrl: string) => {
    const findImageInfo = mediaDataList.find(
      (item) => item.mediaUrl === imageUrl
    );

    if (!findImageInfo) {
      return;
    }

    setSelectImage(findImageInfo);
    onSelectImageChange?.(findImageInfo);
    setPreviewVisible(true);
  };

  const handleThumbnailClick = useCallback(() => {
    if (isUndefined(selectThumbnailIndex)) {
      return;
    }

    const findImageInfo = mediaDataList.find(
      (item) => item.mediaUrl === items[selectThumbnailIndex].original
    );

    if (!findImageInfo) {
      return;
    }

    setSelectImage(findImageInfo);
    onSelectImageChange?.(findImageInfo);
  }, [items, mediaDataList, onSelectImageChange, selectThumbnailIndex]);

  useEffect(() => {
    handleThumbnailClick();
  }, [handleThumbnailClick, selectThumbnailIndex]);

  return (
    <>
      <Dialog
        title={t("task:detail.dialog.viewPhotoCollection")}
        open={open}
        onOpenChange={() => onClose?.()}
        width={1150}
      >
        <DialogContentSection>
          <ImageGalleryContainer>
            <Flex flexDirection="column" gap={16}>
              <PhotoCollection
                items={items}
                startIndex={startIndex}
                onImageClick={handlePhotoClick}
                imgProps={imgProps}
                thumbnailProps={thumbnailProps}
                onSlideChange={setSelectThumbnailIndex}
              />

              {selectImage?.createAt && (
                <StyledSectionCardRow
                  label={t("common:loadingPhotoCreateDate")}
                  value={
                    <Flex justifyContent="space-between" alignItems="center">
                      {dayjs(selectImage.createAt).format("YYYY-MM-DD HH:mm")}
                      {photoDetailRightAccessory && (
                        <>{photoDetailRightAccessory}</>
                      )}
                    </Flex>
                  }
                />
              )}

              {selectImage && selectImage.latitude && selectImage.longitude && (
                <>
                  <Divider />
                  <GoogleMap
                    mapContainerStyle={photoCollectionGoogleMapContainerStyle}
                    center={{
                      lat: Number(selectImage.latitude),
                      lng: Number(selectImage.longitude),
                    }}
                    zoomLevel={selectImage.zoomLevel}
                    mediaCoordinateRange={mediaCoordinateRange}
                    marker={[
                      {
                        position: {
                          lat: Number(selectImage.latitude),
                          lng: Number(selectImage.longitude),
                        },
                      },
                    ]}
                  />
                </>
              )}
            </Flex>
          </ImageGalleryContainer>

          <TaskDetailSection>
            <div>
              <Typo
                typoType="b9m"
                color="gray7"
                as="p"
                className="detail-title"
              >
                {t("task:detail.dialog.loadingDetails")}
              </Typo>
              <DetailContainer>
                <SectionCardRow
                  label={t("common:containerNo")}
                  value={
                    <StyledRowTypo typoType="b7m" color="blue2">
                      {selectLoading?.containerNo ?? "-"}
                    </StyledRowTypo>
                  }
                />
                <SectionCardRow
                  label={t("common:sealNo")}
                  value={
                    <StyledRowTypo typoType="b7m" color="blue2">
                      {selectLoading?.sealNo ?? "-"}
                    </StyledRowTypo>
                  }
                />
              </DetailContainer>
            </div>
            <div>
              <Typo
                typoType="b9m"
                color="gray7"
                as="p"
                className="detail-title"
              >
                {t("task:detail.dialog.additionalInformation")}
              </Typo>

              <DetailContainer>
                <SectionCardRow
                  label={t("task:detail.cards.weighingTicket")}
                  value={
                    <FileInfoContainer>
                      <div>
                        <figure>
                          <Typo typoType="b7r" color="gray5">
                            {selectLoading?.weighingTicketMedias &&
                            selectLoading?.weighingTicketMedias.length >= 1
                              ? selectLoading?.weighingTicketMedias[0]
                                  ?.originalFileName
                              : "-"}
                          </Typo>
                        </figure>
                        {selectLoading?.weighingTicketMedias &&
                          selectLoading?.weighingTicketMedias.length >= 1 &&
                          selectLoading?.weighingTicketMedias[0]?.mediaUrl && (
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={false}
                            >
                              <a
                                href={
                                  selectLoading?.weighingTicketMedias[0]
                                    ?.mediaUrl
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewSvg} iconSize={16} />
                              </a>
                            </IconButton>
                          )}
                      </div>

                      {selectLoading?.weighingTicketMedias &&
                        selectLoading?.weighingTicketMedias.length >= 2 && (
                          <div>
                            <figure>
                              <Typo typoType="b7r" color="gray5">
                                {
                                  selectLoading?.weighingTicketMedias[1]
                                    ?.originalFileName
                                }
                              </Typo>
                            </figure>

                            {selectLoading?.weighingTicketMedias[1]
                              ?.mediaUrl && (
                              <IconButton
                                buttonSize={24}
                                buttonColor="blue"
                                buttonGrade="secondary"
                                disabled={false}
                              >
                                <a
                                  href={
                                    selectLoading?.weighingTicketMedias[1]
                                      ?.mediaUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon iconSrc={PreviewSvg} iconSize={16} />
                                </a>
                              </IconButton>
                            )}
                          </div>
                        )}
                    </FileInfoContainer>
                  }
                />
                <SectionCardRow
                  label={t("task:detail.cards.firstWeight")}
                  value={
                    selectLoading?.firstWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.firstWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label={t("task:detail.cards.secondWeight")}
                  value={
                    selectLoading?.secondWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.secondWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label={t("common:grossWeight")}
                  value={
                    selectLoading?.grossWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.grossWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label={t("common:netWeight")}
                  value={
                    selectLoading?.netWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.netWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label={t("common:unitQuantity")}
                  value={
                    !selectLoading?.unitQuantity &&
                    !selectLoading?.unitQuantityUnit ? (
                      "-"
                    ) : (
                      <>
                        {selectLoading?.unitQuantity
                          ? selectLoading.unitQuantity.toLocaleString("ko-KR")
                          : ""}
                        {selectLoading?.unitQuantityUnit ?? ""}
                      </>
                    )
                  }
                />
                <SectionCardRow
                  label={t("task:detail.cards.photoSubmittedDate")}
                  value={
                    selectLoading?.photoSubmittedDateAt
                      ? dayjs(selectLoading?.photoSubmittedDateAt).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-"
                  }
                />
              </DetailContainer>
            </div>
          </TaskDetailSection>
        </DialogContentSection>
      </Dialog>

      {previewVisible && selectImage && (
        <ConfigProvider
          theme={{
            token: {
              zIndexPopupBase: zIndex.antdImage,
            },
          }}
        >
          <Image
            preview={{
              getContainer: () => document.body,
              visible: previewVisible,
              src: selectImage.mediaUrl,
              onVisibleChange: (visible) => {
                setPreviewVisible(visible);
              },
              afterOpenChange: () => {
                isDownloadBlock && blockContextMenuInAntdPreview();
              },
            }}
            {...antdImageProps}
          />
        </ConfigProvider>
      )}
    </>
  );
};

export default ViewPhotoCollectionDialog;

const DialogContentSection = styled.section`
  display: flex;
  gap: 24px;
`;

const ImageGalleryContainer = styled.div`
  width: 718px;
  flex-shrink: 0;
`;

const TaskDetailSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .detail-title {
    padding-bottom: 8px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    display: flex;
    gap: 8px;

    figure {
      max-width: 155px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  a {
    line-height: 0px;
  }
`;

const StyledRowTypo = styled(Typo)`
  word-break: break-word;
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
  border: none;
  margin: 0;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  align-items: center;

  p {
    width: 100%;
  }
`;
