import React, { useEffect, useReducer, useRef, useState } from "react";
import { styled } from "styled-components";
import IconInput from "@/src/components/atom/Input/IconInput";
import { Controller, useForm } from "react-hook-form";
import Dialog from "@/src/components/atom/Dialog";
import Icon from "@/src/components/atom/Icon";
import EyeSvg from "@/src/assets/icons/icon-eye.svg";
import EyeOffSvg from "@/src/assets/icons/icon-eye-off.svg";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { Button } from "@/src/components/atom/Button";
import { InputError } from "@/src/components/molecule/FormItem";
import { useChangePasswordOnMyPageMutation } from "@/src/store/apis/auth";
import { passwordRegex } from "@/src/utils/regex";
import useAlert from "@/src/hooks/useAlert";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { changePasswordReducer } from "@/src/pages/private/ChangePassword";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface ChangePasswordDialogProps {
  onClose?: (open?: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CANCEL,
}

const ChangePasswordDialog = ({ onClose }: ChangePasswordDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [alertDialogState, setAlertDialogState] = useState(
    AlertDialogState.NULL
  );
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [changePassword, { isLoading }] = useChangePasswordOnMyPageMutation();
  const [passwordVisibleState, passwordTypeDispatch] = useReducer(
    changePasswordReducer,
    {
      currentPassword: "password",
      newPassword: "password",
      reNewPassword: "password",
    }
  );

  const {
    control,
    setValue,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<{
    currentPassword?: string;
    newPassword?: string;
    reCheckPassword?: string;
  }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      currentPassword: undefined,
      newPassword: undefined,
      reCheckPassword: undefined,
    },
  });

  const handleChangePassword = async (values: {
    currentPassword?: string;
    newPassword?: string;
    reCheckPassword?: string;
  }) => {
    if (
      !values.currentPassword ||
      !values.newPassword ||
      !values.reCheckPassword
    )
      return;
    try {
      await changePassword({
        password: values.newPassword,
        oldPassword: values.currentPassword,
      }).unwrap();

      onClose?.();
      alert.showAlert({
        type: "success",
        message: t("myPage:exporter.alert.passwordChangeSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message,
      });
    }
  };

  useEffect(() => {
    setFocus("currentPassword");
  }, [setFocus]);

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <AlertDialog
          title={t("myPage:exporter.cancelAlert.title")}
          description={t("myPage:exporter.cancelAlert.description")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onCancel={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("myPage:exporter.exit")}
          okText={t("myPage:exporter.ok")}
          onOk={() => onClose?.()}
        />
      );
    }
  };

  return (
    <>
      <Dialog
        open
        destroyDialogWhenEscapePress={false}
        onOpenChange={(open) => onClose?.(open)}
        title={t("myPage:exporter.changePasswordDialog.title")}
        onEscapeKeyDown={() => setAlertDialogState(AlertDialogState.CANCEL)}
        footer={
          <DialogFooterContainer>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => onClose?.()}
            >
              {t("myPage:exporter.changePasswordDialog.button.cancel")}
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => {
                submitButtonRef.current?.click();
              }}
            >
              {t("myPage:exporter.changePasswordDialog.button.resetPassword")}
            </Button>
          </DialogFooterContainer>
        }
      >
        <StyledForm onSubmit={handleSubmit(handleChangePassword)}>
          <Item data-invalid={!!errors.currentPassword}>
            <label>
              {t("myPage:exporter.changePasswordDialog.label.currentPassword")}{" "}
              <strong>*</strong>
            </label>
            <Controller
              name="currentPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <IconInput
                    {...field}
                    allowClear
                    type={passwordVisibleState.currentPassword}
                    placeholder={t(
                      "myPage:exporter.changePasswordDialog.placeholder.currentPassword"
                    )}
                    onClear={() => setValue("currentPassword", "")}
                    icon={
                      <Icon
                        iconSrc={
                          passwordVisibleState.currentPassword === "password"
                            ? EyeOffSvg
                            : EyeSvg
                        }
                        iconSize={20}
                        onClick={() =>
                          passwordTypeDispatch({ type: "currentPassword" })
                        }
                      />
                    }
                  />
                );
              }}
            />

            {errors.currentPassword?.type === "required" && (
              <InputError message={t("error:required")} />
            )}
          </Item>

          <Item data-invalid={!!errors.newPassword}>
            <label>
              {t("myPage:exporter.changePasswordDialog.label.newPassword")}{" "}
              <strong>*</strong>
            </label>
            <Controller
              name="newPassword"
              control={control}
              rules={{ required: true, pattern: passwordRegex }}
              render={({ field }) => {
                return (
                  <IconInput
                    {...field}
                    allowClear
                    type={passwordVisibleState.newPassword}
                    placeholder={t(
                      "myPage:exporter.changePasswordDialog.placeholder.newPassword"
                    )}
                    onClear={() => setValue("newPassword", "")}
                    icon={
                      <Icon
                        iconSrc={
                          passwordVisibleState.newPassword === "password"
                            ? EyeOffSvg
                            : EyeSvg
                        }
                        iconSize={20}
                        onClick={() =>
                          passwordTypeDispatch({ type: "newPassword" })
                        }
                      />
                    }
                  />
                );
              }}
            />

            {errors.newPassword?.type === "required" && (
              <InputError message={t("error:required")} />
            )}

            {errors.newPassword?.type === "pattern" && (
              <InputError message={t("error:passwordPatterError")} />
            )}
          </Item>

          <Item data-invalid={!!errors.reCheckPassword}>
            <label>
              {t("myPage:exporter.changePasswordDialog.label.reCheckPassword")}{" "}
              <strong>*</strong>
            </label>
            <Controller
              name="reCheckPassword"
              control={control}
              rules={{
                required: true,
                validate: (password, values) => password === values.newPassword,
              }}
              render={({ field }) => {
                return (
                  <IconInput
                    {...field}
                    allowClear
                    type={passwordVisibleState.reNewPassword}
                    placeholder={t(
                      "myPage:exporter.changePasswordDialog.placeholder.reCheckPassword"
                    )}
                    onClear={() => setValue("reCheckPassword", "")}
                    icon={
                      <Icon
                        iconSrc={
                          passwordVisibleState.reNewPassword === "password"
                            ? EyeOffSvg
                            : EyeSvg
                        }
                        iconSize={20}
                        onClick={() =>
                          passwordTypeDispatch({ type: "reNewPassword" })
                        }
                      />
                    }
                  />
                );
              }}
            />

            {errors.reCheckPassword?.type === "required" && (
              <InputError message={t("error:required")} />
            )}
            {errors.reCheckPassword?.type === "validate" && (
              <InputError message={t("error:passwordRecheckFail")} />
            )}
          </Item>
          <Hidden type="submit" ref={submitButtonRef} />

          {renderAlertDialog()}
        </StyledForm>
      </Dialog>
    </>
  );
};

export default ChangePasswordDialog;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: ${colorSet.gray2};
    ${typo.b7m};

    strong {
      color: ${colorSet.red2};
    }
  }

  &[data-invalid="true"] {
    .icon-input-container {
      border: 1px solid ${colorSet.red2};
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
