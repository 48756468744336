import React, { useEffect, useRef, useState } from "react";
import * as Select from "@radix-ui/react-select";
import styled, { keyframes } from "styled-components";
import colorSet from "@/src/styles/color";
import AmericaSvg from "@/src/assets/icons/icon-america.svg";
import EuropeSvg from "@/src/assets/icons/icon-europe.svg";
import ChinaSvg from "@/src/assets/icons/icon-china.svg";
import JapanSvg from "@/src/assets/icons/icon-japan.svg";
import EnglandSvg from "@/src/assets/icons/icon-united-kingdom.svg";
import CanadaSvg from "@/src/assets/icons/icon-canada.svg";
import AustraliaSvg from "@/src/assets/icons/icon-australia.svg";
import KoreaSvg from "@/src/assets/icons/icon-south-korea.svg";
import UpSvg from "@/src/assets/icons/icon-currency-up.svg";
import DownSvg from "@/src/assets/icons/icon-currency-down.svg";
import { CURRENCY_MAP } from "@/src/utils/currency";
import { useAppSelector } from "@/src/store";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Loader from "@/src/components/atom/Loader";
import { currencyApi } from "@/src/store/apis/currency";
import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";

const currencyStringList = [
  "USD",
  "EUR",
  "CNY",
  "JPY",
  "GBP",
  "CAD",
  "AUD",
  "KRW",
];

const CurrencySelect = () => {
  const country = useAppSelector((state) => state.auth.countryCode);
  const [currencySelectValue, setCurrencySelectValue] = useState("USD");
  const openRef = useRef(false);
  const [animationStart, setAnimationStart] = useState(false);

  const { isFetching, currentCurrency, isUninitialized } =
    currencyApi.endpoints.getCurrency.useQueryState(
      {
        base: CURRENCY_MAP[country as string],
      },
      {
        selectFromResult: ({ data, isUninitialized, isFetching }) => ({
          currentCurrency: data,
          isUninitialized,
          isFetching,
        }),
      }
    );

  const { oneDayBeforeCurrency } =
    currencyApi.endpoints.getOneDayBeforeCurrency.useQueryState(
      {
        base: CURRENCY_MAP[country as string],
      },
      {
        selectFromResult: ({ data, isUninitialized, isFetching }) => ({
          oneDayBeforeCurrency: data,
          isOneDayBeforeCurrencyUninitalized: isUninitialized,
          isOneDayBeforeCurrencyFetching: isFetching,
        }),
      }
    );

  const currencyList =
    isFetching || isUninitialized || !currentCurrency || !oneDayBeforeCurrency
      ? []
      : [
          {
            label: "USD",
            icon: AmericaSvg,
            value: (1 / currentCurrency.rates.USD).toFixed(2) || "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.USD ?? 1) -
              1 / currentCurrency.rates.USD,
          },
          {
            label: "EUR",
            icon: EuropeSvg,
            value: (1 / currentCurrency.rates.EUR).toFixed(2) ?? "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.EUR ?? 1) -
              1 / currentCurrency.rates.EUR,
          },
          {
            label: "CNY",
            icon: ChinaSvg,
            value: (1 / currentCurrency.rates.CNY).toFixed(2) ?? "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.CNY ?? 1) -
              1 / currentCurrency.rates.CNY,
          },
          {
            label: "JPY",
            icon: JapanSvg,
            value: (1 / currentCurrency.rates.JPY).toFixed(2) ?? "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.JPY ?? 1) -
              1 / currentCurrency.rates.JPY,
          },
          {
            label: "GBP",
            icon: EnglandSvg,
            value: (1 / currentCurrency.rates.GBP).toFixed(2) ?? "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.GBP ?? 1) -
              1 / currentCurrency.rates.GBP,
          },
          {
            label: "CAD",
            icon: CanadaSvg,
            value: (1 / currentCurrency.rates.CAD).toFixed(2) ?? "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.CAD ?? 1) -
              1 / currentCurrency.rates.CAD,
          },
          {
            label: "AUD",
            icon: AustraliaSvg,
            value: (1 / currentCurrency.rates.AUD).toFixed(2) ?? "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.AUD ?? 1) -
              1 / currentCurrency.rates.AUD,
          },
          {
            label: "KRW",
            icon: KoreaSvg,
            value: (1 / currentCurrency.rates.KRW).toFixed(2) ?? "-",
            lastDay:
              1 / (oneDayBeforeCurrency.rates.KRW ?? 1) -
              1 / currentCurrency.rates.KRW,
          },
        ];

  useEffect(() => {
    const id = setInterval(() => {
      if (!openRef.current) {
        const currencyIndex = currencyStringList.findIndex(
          (currency) => currency === currencySelectValue
        );

        const nextCurrency = currencyStringList[currencyIndex + 1] || "USD";

        setCurrencySelectValue(nextCurrency);
        setAnimationStart(true);
      }
    }, 5000);

    return () => {
      clearInterval(id);
    };
  }, [currencySelectValue]);

  return (
    <>
      <Select.Root
        onOpenChange={(open) => {
          openRef.current = open;
        }}
        value={currencySelectValue}
        onValueChange={setCurrencySelectValue}
      >
        <Trigger>
          <TriggerContent>
            {currencyList
              .filter(({ label }) => label === currencySelectValue)
              .map(({ label, value, icon, lastDay }) => {
                const statusOfDiff =
                  lastDay > 0 ? "up" : lastDay === 0 ? "same" : "down";

                return (
                  <SpaceBetween
                    className={`${
                      animationStart ? "animation-start" : undefined
                    }`}
                    onAnimationEnd={() => setAnimationStart(false)}
                  >
                    <Flex>
                      <Icon iconSrc={icon} iconSize={22} />
                      <Typo>{label}</Typo>
                    </Flex>

                    <Flex>
                      <Typo>{value}</Typo>

                      <Flex
                        className="gap-4"
                        style={{ width: "60px", justifyContent: "flex-end" }}
                      >
                        {statusOfDiff !== "same" && (
                          <Icon
                            iconSrc={statusOfDiff === "up" ? UpSvg : DownSvg}
                            iconSize={12}
                          />
                        )}
                        <Typo
                          color={
                            statusOfDiff === "up"
                              ? "red2"
                              : statusOfDiff === "same"
                              ? "gray7"
                              : "blue4"
                          }
                        >
                          {Math.abs(lastDay).toFixed(2)}
                        </Typo>
                      </Flex>
                    </Flex>
                  </SpaceBetween>
                );
              })}
          </TriggerContent>

          {isFetching || isUninitialized ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <StyledIcon iconSrc={ArrowDownSvg} />
          )}
        </Trigger>

        <Select.Portal>
          <Content position="popper" sideOffset={8}>
            <Select.Viewport>
              {currencyList.map(({ icon, value, label, lastDay }) => {
                const statusOfDiff =
                  lastDay > 0 ? "up" : lastDay === 0 ? "same" : "down";
                return (
                  <Item value={label}>
                    <Select.ItemText asChild>
                      <SpaceBetween>
                        <Flex>
                          <Icon iconSrc={icon} iconSize={22} />
                          <Typo>{label}</Typo>
                        </Flex>

                        <Flex>
                          <Typo>{value}</Typo>

                          <Flex
                            className="gap-4"
                            style={{
                              width: "65px",
                              justifyContent: "flex-end",
                            }}
                          >
                            {statusOfDiff !== "same" && (
                              <Icon
                                iconSrc={
                                  statusOfDiff === "up" ? UpSvg : DownSvg
                                }
                                iconSize={12}
                              />
                            )}
                            <Typo
                              color={
                                statusOfDiff === "up"
                                  ? "red2"
                                  : statusOfDiff === "same"
                                  ? "gray7"
                                  : "blue4"
                              }
                            >
                              {Math.abs(lastDay).toFixed(2)}
                            </Typo>
                          </Flex>
                        </Flex>
                      </SpaceBetween>
                    </Select.ItemText>
                  </Item>
                );
              })}
            </Select.Viewport>
          </Content>
        </Select.Portal>
      </Select.Root>
    </>
  );
};

export default CurrencySelect;

const rollingUp = keyframes`
    0% {
        transform: translateY(80%);
    }
    100% {
        transform: translateY(0%);
    }
`;

const TriggerContent = styled.div`
  width: 100%;
`;

const Trigger = styled(Select.Trigger)`
  width: 340px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colorSet.white};
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid ${colorSet.gray9};
  cursor: pointer;
  overflow: hidden;
  padding: 0 8px 0 0;
`;

const Content = styled(Select.Content)`
  background: ${colorSet.white};
  width: 340px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1),
    0px 0px 0px 0.5px rgba(160, 164, 171, 0.2);
`;

const Item = styled(Select.Item)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: ${colorSet.systemBlue6};
  }
`;

const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  &.animation-start {
    animation: ${rollingUp} 0.3s ease-in-out;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &.gap-4 {
    gap: 4px;
  }
`;

const LoaderWrapper = styled.div`
  padding: 8px 0;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;
