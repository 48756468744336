import AlertDialog from "@/src/components/atom/AlertDialog";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ClientsEmptyAlertDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  type: "SELLER" | "BUYER";
}

function ClientsEmptyAlertDialog({
  open,
  onOpenChange,
  type,
}: ClientsEmptyAlertDialogProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOkClick = () => {
    switch (type) {
      case "BUYER":
        return navigate(IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD);
      case "SELLER":
        return navigate(EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD);
    }
  };

  const clientsEmptyAlertDialogDescription = () => {
    switch (type) {
      case "BUYER":
        return t("common:mustRegisterClientBeforeOrder");
      case "SELLER":
        return t("common:mustRegisterClientBeforeContract");
    }
  };

  return (
    <AlertDialog
      open={open}
      title={t("common:clientsEmpty")}
      onOpenChange={onOpenChange}
      onOk={handleOkClick}
      okText={t("common:clientsRegistration")}
      cancelText={t("cancelAlertDialog:cancelButton")}
    >
      {clientsEmptyAlertDialogDescription()}
    </AlertDialog>
  );
}

export default ClientsEmptyAlertDialog;
