import { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { styled } from "styled-components";
import { DefaultOptionType } from "antd/es/select";
import { ColDef, IRowNode } from "ag-grid-community";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "@/src/store";
import { useLazyGetBuyersQuery } from "@/src/store/apis/client/buyer";
import Select from "@/src/components/atom/Select";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import Table from "@/src/components/atom/Table";
import {
  useCreateShipmentMutation,
  useGetContainerListInShipmentMutation,
  useLazyGetTasksInShipmentQuery,
} from "@/src/store/apis/shipments/shipmentRegister";
import useAlert from "@/src/hooks/useAlert";
import Loader from "@/src/components/atom/Loader";
import {
  columnOfContainer,
  getColumnShipmentTaskList,
  getSameConditionObject,
} from "./columns/columnList";
import {
  ShipmentContainerListViewDto,
  ShipmentTaskListViewDto,
} from "@/src/store/apis/shipments/shipmentRegister/interface";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { AgGridReact } from "ag-grid-react";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { createViewAction } from "@/src/utils/row-data-util";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import SingleContainerPhotoDialog from "./components/dialog/SingleContainerPhotoDialog";
import ImageMultipleSvg from "@/src/assets/icons/icon-image-multiple.svg";
import ContainerPhotosByTaskIdDialog from "./components/dialog/ContainerPhotosDialogByTaskId";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";
import { useGetSessionQuery } from "@/src/store/apis/auth";

const MUST_SAME_CONDITION = [
  "buyerId",
  "quantityUnitCodeItemName",
  "unitPriceUnitCodeItemName",
  "shippingLineCodeItemName",
  "vessel",
  "voyageNo",
  "portOfLoadingCodeItemName",
  "portOfDischargeCodeItemName",
  "placeOfDeliveryCodeItemName",
  "etdAt",
  "etaAt",
  "docCutOffDay",
];

enum DialogState {
  NULL,
  CONTAINER_PHOTO_DIALOG,
  ALL_CONTAINER_PHOTO_DIALOG,
}

const getIsSameCondition = (compareItem: any, selectedItem: any) => {
  const rowResource = Object.values(
    getSameConditionObject(compareItem, MUST_SAME_CONDITION)
  );
  const condition = Object.values(
    getSameConditionObject(selectedItem ?? {}, MUST_SAME_CONDITION)
  );

  let isAllSame = true;

  if (condition.length) {
    for (let i = 0; i <= rowResource.length; i++) {
      if (rowResource[i] !== condition[i]) {
        isAllSame = false;
        break;
      }
    }
  }

  return isAllSame;
};

const ExporterShipmentAddPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const subscription = useAppSelector((state) => state.subscription);
  const defaultBuyerId: number | undefined = searchParams.get("buyerId")
    ? Number(searchParams.get("buyerId"))
    : undefined;
  const defaultLoadingId: string | undefined =
    searchParams.get("loadingId") || undefined;

  // Ref
  const gridRef = useRef<AgGridReact | null>(null);
  const containerGridRef = useRef<AgGridReact | null>(null);
  const selectedRows =
    useRef<(ShipmentTaskListViewDto & { docCutOffDay: string })[]>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const selectedContainerList = useRef<ShipmentContainerListViewDto[]>([]);
  const selectableCondition = useRef<
    ShipmentTaskListViewDto & { docCutOffDay: string }
  >();

  // State
  const [containerIdForDialog, setContainerIdFofDialog] = useState<number>();
  const [selectedBuyerId, setSelectedBuyerId] = useState(defaultBuyerId);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [step, setStep] = useState<1 | 2>(1);
  const [getBuyers, { buyerData, isBuyerFetching, isBuyerError }] =
    useLazyGetBuyersQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        return {
          buyerData: currentData?.data,
          isBuyerFetching: isFetching,
          isBuyerError: isError,
        };
      },
    });
  const [containerColumnDef] = useState<ColDef[]>(columnOfContainer);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isFirstStepTableReady, setIsFirstStepTableReady] = useState(false);

  // API
  const [getLoadings, loadingsResult] = useLazyGetTasksInShipmentQuery();
  const [getContainer, containerResult] =
    useGetContainerListInShipmentMutation();
  const [createShipment] = useCreateShipmentMutation();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const {
    exporterUserType,
    mainCategoryCodeItemNames,
    exporterUserMainFieldType,
  } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData }) => {
      return {
        exporterUserType: currentData?.row.exporterUserType,
        exporterUserMainFieldType: currentData?.row.exporterUserMainFieldType,
        mainCategoryCodeItemNames: currentData?.row.mainCategoryCodeItemNames,
      };
    },
  });

  console.log("exporterUserType", exporterUserType);

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  const buyerOptionList = isBuyerError
    ? []
    : buyerData
        ?.filter((val) => {
          // userType이 MANAGER인 경우 필터링
          // MANAGER가 아닌 경우 필터링 없이 그대로 유지
          return exporterUserType === "MANAGER"
            ? val.mainCategoryCodeItemNames.some((category) =>
                mainCategoryCodeItemNames?.includes(category)
              )
            : true;
        })
        .map((buyer) => {
          return {
            value: buyer.id,
            label: buyer.buyerNameCode,
          };
        }) ?? [];

  const rowData = loadingsResult.isError
    ? []
    : loadingsResult?.currentData?.map((loading) => {
        return {
          ...loading,
          docCutOffDay: dayjs(loading.docCutOffAt).format("YYYY-MM-DD"),
          etaAt: dayjs(dayjs(loading.etaAt).format("YYYY-MM-DD"))
            .startOf("day")
            .toISOString(),
          etdAt: dayjs(dayjs(loading.etdAt).format("YYYY-MM-DD"))
            .startOf("day")
            .toISOString(),
        };
      }) ?? [];

  const handleSelectChange = async (
    value?: string,
    option?: DefaultOptionType
  ) => {
    if (value && option) {
      try {
        await getLoadings({ buyerNameCode: option.label as string }).unwrap();
        handleFirstDataRendered();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    }
  };

  const handleFirstDataRendered = () => {
    if (gridRef.current) {
      gridRef.current.api.forEachNode((node: IRowNode) => {
        if (selectedRows.current) {
          if (selectedRows.current.map(({ id }) => id).includes(node.data.id)) {
            node.setSelected(true);
          }
        }
      });
    }
  };

  const handleCreateShipment = async () => {
    try {
      handleContentLoadingOn();
      const { id } = await createShipment({
        containerIdList: selectedContainerList.current.map(({ id }) => id),
      }).unwrap();

      navigate(`${EXPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${id}`);
      alert.showAlert({
        message: t("alert:saveSuccess"),
        type: "success",
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    } finally {
      handleContentLoadingOff();
    }
  };

  const refreshColumnDef = () => {
    gridRef.current!.api.setColumnDefs(
      getColumnShipmentTaskList(selectableCondition.current) as ColDef[]
    );
  };

  const fetchContainerList = useCallback(async () => {
    if (!selectedRows.current) return;
    try {
      const result = await getContainer({
        taskIdList: selectedRows.current.map(({ id }) => id),
      }).unwrap();

      const selectable = result.filter(
        ({ isShipmentContainerLinked }) => !isShipmentContainerLinked
      );
      selectedContainerList.current = selectable;
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  }, [alert, getContainer]);

  const handleViewButtonClick = (data: any) => {
    setContainerIdFofDialog(data.id);
    setDialogState(DialogState.CONTAINER_PHOTO_DIALOG);
  };

  const handleViewAllButtonClick = () => {
    setDialogState(DialogState.ALL_CONTAINER_PHOTO_DIALOG);
  };

  useEffect(() => {
    if (step === 1) {
      if (buttonRef.current) {
        buttonRef.current.disabled = true;
      }
    }
    if (step === 2) {
      if (saveButtonRef.current) {
        saveButtonRef.current.disabled = true;
      }
    }
  }, [step]);

  useEffect(() => {
    if (step === 1) {
      (async () => {
        const { data: buyerList } = await getBuyers({ isActivated: true });
        if (selectedBuyerId) {
          const { data: loadingList } = await getLoadings({
            buyerNameCode:
              buyerList?.data?.find(({ id }) => id === selectedBuyerId)
                ?.buyerNameCode ?? "",
          });
          const isFromLoadingPage = defaultLoadingId && defaultBuyerId;
          if (isFromLoadingPage) {
            const finedLoading = loadingList?.find(
              ({ id }) => id === Number(defaultLoadingId)
            );
            const isSelectableLoading = !isUndefined(finedLoading);

            if (isSelectableLoading && finedLoading) {
              selectedRows.current = [
                {
                  ...finedLoading,
                  docCutOffDay: dayjs(finedLoading.docCutOffAt).format(
                    "YYYY-MM-DD"
                  ),
                  etaAt: dayjs(dayjs(finedLoading.etaAt).format("YYYY-MM-DD"))
                    .startOf("day")
                    .toISOString(),
                  etdAt: dayjs(dayjs(finedLoading.etdAt).format("YYYY-MM-DD"))
                    .startOf("day")
                    .toISOString(),
                },
              ];
              selectableCondition.current = {
                ...finedLoading,
                docCutOffDay: dayjs(finedLoading.docCutOffAt).format(
                  "YYYY-MM-DD"
                ),
                etaAt: dayjs(dayjs(finedLoading.etaAt).format("YYYY-MM-DD"))
                  .startOf("day")
                  .toISOString(),
                etdAt: dayjs(dayjs(finedLoading.etdAt).format("YYYY-MM-DD"))
                  .startOf("day")
                  .toISOString(),
              };
            }
          }
          handleFirstDataRendered();
        }
      })();
    }
    if (step === 2) {
      fetchContainerList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (containerGridRef.current && containerResult.isLoading) {
      containerGridRef.current.api.showLoadingOverlay();
    }
  }, [containerResult.isLoading]);

  useAgGridHeaderRefresh({
    gridRef: containerGridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "taskNo",
        langKey: "table:no",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "containerNo",
        langKey: "table:containerNo",
      },
      {
        columnKey: "sealNo",
        langKey: "table:sealNo",
      },
      {
        columnKey: "quantityUnit",
        langKey: "table:unitQty",
      },
      {
        columnKey: "grossWeight",
        langKey: "table:grossWeight",
      },
      {
        columnKey: "netWeight",
        langKey: "table:netWeight",
      },
      {
        columnKey: "photo",
        langKey: "table:photo",
      },
    ],
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady: isFirstStepTableReady,
    headerSet: [
      {
        columnKey: "taskNo",
        langKey: "table:loadingNo",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "etdAt",
        langKey: "table:etd",
      },
      {
        columnKey: "etaAt",
        langKey: "table:eta",
      },
      {
        columnKey: "docCutOffAt",
        langKey: "table:docCutOff",
      },
      {
        columnKey: "shippingLine",
        langKey: "table:shippingLine",
      },
      {
        columnKey: "vesselAndVoyageNo",
        langKey: "table:vesselAndVoyageNo",
      },
      {
        columnKey: "portOfLoading",
        langKey: "table:portOfLoading",
      },
      {
        columnKey: "portOfDischarge",
        langKey: "table:portOfDischarge",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "quantityUnit",
        langKey: "table:qty",
      },
      {
        columnKey: "unitPriceUnit",
        langKey: "table:currency",
      },
      {
        columnKey: "workplaceName",
        langKey: "table:factory",
      },
      {
        columnKey: "numberOfTaskContainerAndContainerType",
        langKey: "table:noOfContainer",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
    ],
  });

  const renderByStep = () => {
    if (step === 1) {
      return (
        <SectionCard
          cardTitle={t("shipment:exporter.add.label.loadingSelection")}
        >
          <FlexColumn>
            <BuyerCodeSection>
              <Typo typoType="b7m" color="gray2">
                {t("common:buyerCode")}
              </Typo>

              <Select
                options={buyerOptionList}
                value={selectedBuyerId}
                allowClear
                suffixIcon={isBuyerFetching ? <Loader /> : undefined}
                disabled={isBuyerFetching}
                filterOption={(input, option) =>
                  ((option?.label as string) ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                showSearch
                placeholder={t("shipment:exporter.add.placeholder.buyerCode")}
                onChange={(value, option) => {
                  handleSelectChange(value, option as DefaultOptionType);
                  setSelectedBuyerId(value);
                  selectedRows.current = undefined;
                  selectableCondition.current = undefined;
                  if (buttonRef.current) {
                    buttonRef.current.disabled = true;
                  }
                  refreshColumnDef();
                }}
              />
            </BuyerCodeSection>

            <InfoRow>
              <InfoIcon color="gray6" />
              <Typo typoType="b9r" color="gray6">
                {t("shipment:exporter.add.description.etd")}
              </Typo>
            </InfoRow>

            <InfoRow>
              <InfoIcon color="blue4" />
              <Typo typoType="b9r" color="blue4">
                {t("shipment:exporter.add.description.buyerCode")}
              </Typo>
            </InfoRow>

            <Table
              ref={gridRef}
              rowData={rowData}
              columnDefs={
                getColumnShipmentTaskList(
                  selectableCondition.current
                ) as ColDef[]
              }
              isPagination={false}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              onRowSelected={(event) => {
                const isSelectable = getIsSameCondition(
                  event.data,
                  selectableCondition.current ? selectableCondition.current : {}
                );

                if (!isSelectable) {
                  event.node.setSelected(false);
                  return;
                }

                const isNoneItemSelected =
                  event.api.getSelectedRows().length === 0;
                const isOneItemSelected =
                  event.api.getSelectedRows().length === 1;

                if (isNoneItemSelected) {
                  selectableCondition.current = undefined;

                  if (buttonRef.current) {
                    buttonRef.current.disabled = true;
                  }
                }

                if (!isNoneItemSelected) {
                  if (buttonRef.current) {
                    buttonRef.current.disabled = false;
                  }
                }

                if (isOneItemSelected) {
                  selectableCondition.current = event.api.getSelectedRows()[0];
                }

                selectedRows.current = event.api.getSelectedRows();
                refreshColumnDef();
              }}
              onSelectionChanged={(event) => {
                event.api.forEachNode((node) => {
                  const isSelectable = getIsSameCondition(
                    node.data,
                    selectableCondition.current
                      ? selectableCondition.current
                      : {}
                  );

                  node.selectable = isSelectable;
                });
              }}
              onGridReady={() => setIsFirstStepTableReady(true)}
            />
          </FlexColumn>
          <BottomFixedContainer>
            <FooterButtonSection>
              <Button
                buttonGrade="tertiary"
                buttonColor="black"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
                {t("shipment:exporter.add.button.backToPreviousButton")}
              </Button>

              <SaveButtonContainer>
                <Button
                  ref={buttonRef}
                  buttonGrade="primary"
                  style={{ width: 158 }}
                  onClick={() => {
                    selectedRows.current =
                      gridRef.current?.api.getSelectedRows();
                    setStep(2);
                  }}
                >
                  {t("shipment:exporter.add.button.nextStep")}
                </Button>
              </SaveButtonContainer>
            </FooterButtonSection>
          </BottomFixedContainer>
        </SectionCard>
      );
    }

    return (
      <>
        <FlexColumn>
          <SectionCardGrid>
            {[
              {
                label: t("common:buyer"),
                value: selectableCondition.current?.buyerNameCode ?? "-",
              },
              {
                label: t("common:etd"),
                value:
                  dayjs(selectableCondition.current?.etdAt).format(
                    "YYYY-MM-DD"
                  ) ?? "-",
              },
              {
                label: t("common:eta"),
                value:
                  dayjs(selectableCondition.current?.etaAt).format(
                    "YYYY-MM-DD"
                  ) ?? "-",
              },
              {
                label: t("common:docCutOff"),
                value:
                  dayjs(selectableCondition.current?.docCutOffAt).format(
                    "YYYY-MM-DD"
                  ) ?? "-",
              },
              { isGrayLine: true },
              {
                label: t("common:vesselAndVoyageNo"),
                value: `${selectableCondition.current?.vessel ?? "-"} / ${
                  selectableCondition.current?.voyageNo ?? "-"
                }`,
              },
              {
                label: t("common:portOfLoading"),
                value: selectableCondition.current?.portOfLoading ?? "-",
              },
              {
                label: t("common:placeOfDelivery"),
                value: selectableCondition.current?.placeOfDelivery ?? "-",
              },
              {
                label: t("common:shippingLine"),
                value: selectableCondition.current?.shippingLine ?? "-",
              },
            ].map(({ label, value, isGrayLine = false }) => {
              if (isGrayLine) {
                return <GrayLine />;
              }

              return (
                <StyledSectionCardRow
                  label={
                    <Typo color="gray6" typoType="b9m">
                      {label}
                    </Typo>
                  }
                  direction="vertical"
                  value={<Typo typoType="h4">{value}</Typo>}
                />
              );
            })}
          </SectionCardGrid>

          <SectionCard
            cardTitle={t("shipment:exporter.add.label.containerSelection")}
            rightAccessory={
              <StyledButton
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleViewAllButtonClick}
              >
                <Icon iconSrc={ImageMultipleSvg} />
                <Typo typoType="btn3m">
                  {t("shipment:exporter.add.button.viewAllButton")}
                </Typo>
              </StyledButton>
            }
          >
            <Table
              onRowDataUpdated={(event) => {
                if (
                  selectedContainerList.current &&
                  selectedContainerList.current.length &&
                  saveButtonRef.current
                ) {
                  saveButtonRef.current.disabled = false;
                }
                event.api.forEachNode((node: IRowNode) => {
                  if (selectedContainerList.current) {
                    if (
                      selectedContainerList.current
                        .map(({ id }) => id)
                        .includes(node.data.id)
                    ) {
                      node.setSelected(true);
                    }
                  }
                });
              }}
              ref={containerGridRef}
              rowData={
                containerResult.isError ? [] : containerResult?.data ?? []
              }
              columnDefs={containerColumnDef}
              onGridReady={() => setIsReady(true)}
              isPagination={false}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              isRowSelectable={(rowNode) => {
                return !rowNode.data.isShipmentContainerLinked;
              }}
              rowClassRules={{
                "ag-selectable-disable-row": (params) => {
                  return !params.node.selectable;
                },
              }}
              onRowSelected={(event) => {
                if (saveButtonRef.current) {
                  if (event.api.getSelectedRows().length) {
                    saveButtonRef.current.disabled = false;
                  } else {
                    saveButtonRef.current.disabled = true;
                  }
                  selectedContainerList.current = event.api.getSelectedRows();
                }
              }}
              components={{
                ...createViewAction(handleViewButtonClick),
              }}
            />
          </SectionCard>
          <BottomFixedContainer>
            <FooterButtonSection>
              <Button
                buttonGrade="tertiary"
                buttonColor="black"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() => {
                  setStep(1);
                }}
              >
                <Icon iconSrc={ChevronLeftSvg} iconSize={16} />

                {t("shipment:exporter.add.button.backToPreviousButton")}
              </Button>

              <SaveButtonContainer>
                <Button
                  ref={saveButtonRef}
                  buttonGrade="primary"
                  style={{ width: 158 }}
                  onClick={handleCreateShipment}
                  disabled={isCreateDisabled}
                >
                  {t("shipment:exporter.add.button.save")}
                </Button>
              </SaveButtonContainer>
            </FooterButtonSection>
          </BottomFixedContainer>
        </FlexColumn>
      </>
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;

    if (
      dialogState === DialogState.CONTAINER_PHOTO_DIALOG &&
      containerIdForDialog
    ) {
      return (
        <SingleContainerPhotoDialog
          containerId={containerIdForDialog}
          onClose={() => {
            setContainerIdFofDialog(undefined);
            setDialogState(DialogState.NULL);
          }}
        />
      );
    }

    if (dialogState === DialogState.ALL_CONTAINER_PHOTO_DIALOG) {
      return (
        <ContainerPhotosByTaskIdDialog
          onClose={() => setDialogState(DialogState.NULL)}
          loadingId={selectedRows.current?.map(({ id }) => id) ?? []}
        />
      );
    }
  };

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:shipment"), t("sideNav:shipmentRegistration")]}
      pageTitle={t("sideNav:shipmentRegistration")}
    >
      <FixedBottomPaddingDiv>{renderByStep()}</FixedBottomPaddingDiv>
      {renderDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterShipmentAddPage;

const FixedBottomPaddingDiv = styled.div`
  padding-bottom: 72px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BuyerCodeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;

  p {
    max-width: 100%;
  }
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
