import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { IMPORTER_MANAGEMENT_NAV_MENU } from "@/src/components/template/Layout/importer/menu";
import { EXPORTER_MANAGEMENT_NAV_MENU } from "@/src/components/template/Layout/exporter/menu";

export interface SelectNav {
  iconSrc: string;
  isOpen: boolean;
  labelKey: string;
  closeHeight: number;
  fullHeight: number;
  includePath: string[];
  children: {
    labelKey: string;
    destination: string;
  }[];
}

interface SideNavSliceInitialState {
  importerSelectTwoDepsNav: SelectNav[];
  exporterSelectTwoDepsNav: SelectNav[];
}

const initialState: SideNavSliceInitialState = {
  exporterSelectTwoDepsNav: EXPORTER_MANAGEMENT_NAV_MENU,
  importerSelectTwoDepsNav: IMPORTER_MANAGEMENT_NAV_MENU,
};

export const sideNavSlice = createSlice({
  name: "sideNav",
  initialState,
  reducers: {
    setImporterTwoDepsNav: (state, { payload }: PayloadAction<SelectNav[]>) => {
      state.importerSelectTwoDepsNav = payload;
    },
    setExporterTwoDepsNav: (state, { payload }: PayloadAction<SelectNav[]>) => {
      state.exporterSelectTwoDepsNav = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export default sideNavSlice.reducer;
export const { setImporterTwoDepsNav, setExporterTwoDepsNav } =
  sideNavSlice.actions;
