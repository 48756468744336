import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import { pdf } from "@react-pdf/renderer";
import { css, styled } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@/src/components/atom/Icon";
import SectionCard from "@/src/components/molecule/SectionCard";
import SingleImageUpload from "@/src/components/molecule/SingleImageUpload";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { Button, IconButton } from "@/src/components/atom/Button";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import RadioGroup from "@/src/components/atom/RadioGroup";
import Input from "@/src/components/atom/Input";
import TextArea from "@/src/components/atom/TextArea";
import Checkbox from "@/src/components/atom/Checkbox";
import DatePicker from "@/src/components/atom/DatePicker";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Radio from "@/src/components/atom/Radio";
import PencelSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import CloseSvg from "@/src/assets/icons/icon-close-red.svg";
import InfoSvg from "@/src/assets/icons/icon-info-gray6.svg";
import EyeSvg from "@/src/assets/icons/icon-eye-black.svg";
import EyeOffSvg from "@/src/assets/icons/icon-eye-off-red2.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import {
  useCreateFinalCiMutation,
  useCreateTemporaryCiMutation,
  useLazyGetCiInformationQuery,
  useLazyGetInitialCiInformationQuery,
} from "@/src/store/apis/shipments/ci";
import useAlert from "@/src/hooks/useAlert";
import {
  CiInfoDto,
  CiItemDto,
  GenerateCiDto,
  KeyOfCiInfoDto,
} from "@/src/store/apis/shipments/ci/interface";
import { ciDefaultValue } from "./constant/defaultValue";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { commonCiApiFieldKey } from "./constant/ciHelper";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import CiPreview from "@/src/components/template/pdfs/Ci/Preview";
import { renderDocumentStatusBadge } from "./utils/renderShipmentStatusBadge";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { ShipmentSelectTabState } from "./detail";
import CiContent from "@/src/components/template/pdfs/Ci/CiContent";
import { useUploadBigFileMutation } from "@/src/store/apis/media";
import { useTranslation } from "react-i18next";

enum AlertDialogState {
  NULL,
  BACK_TO_PREVIOUS,
  DOCUMENT_INIT,
}

const ExporterAddCiPage = () => {
  const { t } = useTranslation();
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const previewPdfResourceRef = useRef<CiInfoDto>(ciDefaultValue);
  const params = useParams();
  const alert = useAlert();
  const id = Number(params.id);
  const navigate = useNavigate();
  const { handleContentLoadingOn, handleContentLoadingOff } =
    useContentLoading();

  const [, force] = useState({});
  const [ciInfo, setCiInfo] = useState<CiInfoDto>(ciDefaultValue);
  const [selectedSignatureMediaId, setSelectedSignatureMediaId] =
    useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );

  const [getCiInfo, ciInfoResult] = useLazyGetCiInformationQuery();
  const [getIntialCiInfo, initialCiInfoResult] =
    useLazyGetInitialCiInformationQuery();
  const [createTempCi] = useCreateTemporaryCiMutation();
  const [createFinalCi] = useCreateFinalCiMutation();
  const [uploadFile] = useUploadBigFileMutation();

  const isAllGrossWeightHidden = ciInfo.ciItems.some(
    ({ isGrossWeightHidden }) => isGrossWeightHidden === true
  );

  const isAllNetWeightHidden = ciInfo.ciItems.some(
    ({ isNetWeightHidden }) => isNetWeightHidden === true
  );

  const handleChange = (key: KeyOfCiInfoDto, value: any) => {
    setCiInfo((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeWithName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCiInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleToggle = (key: KeyOfCiInfoDto) => {
    setCiInfo((prev) => ({ ...prev, [key]: !ciInfo[key] }));
  };

  const handleClear = (key: KeyOfCiInfoDto) => {
    setCiInfo((prev) => ({ ...prev, [key]: "" }));
  };

  const handleCiIntialize = () => {
    if (!initialCiInfoResult.isSuccess || initialCiInfoResult.isFetching) {
      setCiInfo(ciDefaultValue);
    } else {
      setCiInfo(initialCiInfoResult.currentData);
    }
  };

  const handleCreateTempCi = async () => {
    try {
      handleContentLoadingOn();
      previewPdfResourceRef.current = ciInfo;
      const blob = await pdf(
        <CiContent
          data={{
            ...previewPdfResourceRef.current,
            totalIsGrossWeightHidden:
              previewPdfResourceRef.current.ciItems.some(
                ({ isGrossWeightHidden }) => isGrossWeightHidden
              ),
            totalIsNetWeightHidden: previewPdfResourceRef.current.ciItems.some(
              ({ isNetWeightHidden }) => isNetWeightHidden
            ),
          }}
        />
      ).toBlob();
      const ciPdfFile = new File([blob], "ci.pdf", { type: "application/pdf" });
      const uploadedFile = await uploadFile({
        files: [ciPdfFile],
        folder: "commons/",
      });

      const commonBody = Object.fromEntries(
        Object.entries(ciInfo).filter(([key]) =>
          commonCiApiFieldKey.includes(key)
        )
      ) as Omit<GenerateCiDto, "ciItemInfos" | "simpleSignatureMedias">;

      await createTempCi({
        id,
        ...commonBody,
        ciItems: ciInfo.ciItems.map(
          ({
            item,
            isItemHidden,
            hsCode,
            isHsCodeHidden,
            descriptionOfGoods,
            isDescriptionOfGoodsHidden,
            isGrossWeightHidden,
            isNetWeightHidden,
            descriptionOfGoodsTitle,
            contractId,
          }) => ({
            item,
            isItemHidden,
            hsCode,
            isHsCodeHidden,
            descriptionOfGoods,
            isDescriptionOfGoodsHidden,
            isGrossWeightHidden,
            isNetWeightHidden,
            descriptionOfGoodsTitle,
            contractId,
          })
        ),
        simpleSignatureMedias: ciInfo.simpleSignatureMedias
          ?.filter((media) => media)
          ?.map(({ id }) => ({
            id,
            isDefault: selectedSignatureMediaId === id,
          })),
        businessLogoMediaId: ciInfo.businessLogoSimpleMedia?.id,
        ciTemporaryMediaId:
          "data" in uploadedFile ? uploadedFile.data[0].id : undefined,
      }).unwrap();

      navigate(
        `${EXPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${id}?selectTab=${ShipmentSelectTabState.DOCUMENT_MEMO}`
      );
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  const handleCreateFinalCi = async () => {
    try {
      handleContentLoadingOn();
      previewPdfResourceRef.current = ciInfo;
      const blob = await pdf(
        <CiContent
          data={{
            ...previewPdfResourceRef.current,
            totalIsGrossWeightHidden:
              previewPdfResourceRef.current.ciItems.some(
                ({ isGrossWeightHidden }) => isGrossWeightHidden
              ),
            totalIsNetWeightHidden: previewPdfResourceRef.current.ciItems.some(
              ({ isNetWeightHidden }) => isNetWeightHidden
            ),
          }}
        />
      ).toBlob();
      const ciPdfFile = new File([blob], "ci.pdf", { type: "application/pdf" });
      const uploadedFile = await uploadFile({
        files: [ciPdfFile],
        folder: "commons/",
      });

      const commonBody = Object.fromEntries(
        Object.entries(ciInfo).filter(([key]) =>
          commonCiApiFieldKey.includes(key)
        )
      ) as Omit<GenerateCiDto, "ciItemInfos" | "simpleSignatureMedias">;

      await createFinalCi({
        id,
        ...commonBody,
        ciItems: ciInfo.ciItems.map(
          ({
            item,
            isItemHidden,
            hsCode,
            isHsCodeHidden,
            descriptionOfGoods,
            isDescriptionOfGoodsHidden,
            isGrossWeightHidden,
            isNetWeightHidden,
            descriptionOfGoodsTitle,
            contractId,
          }) => ({
            item,
            isItemHidden,
            hsCode,
            isHsCodeHidden,
            descriptionOfGoods,
            isDescriptionOfGoodsHidden,
            isGrossWeightHidden,
            isNetWeightHidden,
            descriptionOfGoodsTitle,
            contractId,
          })
        ),
        simpleSignatureMedias: ciInfo.simpleSignatureMedias
          ?.filter((media) => media)
          ?.map(({ id }) => ({
            id,
            isDefault: selectedSignatureMediaId === id,
          })),
        businessLogoMediaId: ciInfo.businessLogoSimpleMedia?.id,
        ciMediaId: "data" in uploadedFile ? uploadedFile.data[0].id : undefined,
      }).unwrap();

      navigate(
        `${EXPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${id}?selectTab=${ShipmentSelectTabState.DOCUMENT_MEMO}`
      );
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getCiInfo({ id }).unwrap();
        setSelectedSignatureMediaId(
          response.simpleSignatureMedias?.find(({ isDefault }) => isDefault)?.id
        );
        setCiInfo(response);
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        alert.showAlert({ message, type: "error" });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await getIntialCiInfo({ id });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        alert.showAlert({ message, type: "error" });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ciInfoResult.isFetching) {
      return handleContentLoadingOn();
    }

    setTimeout(() => {
      handleContentLoadingOff();
    }, 500);
  }, [
    ciInfoResult.isFetching,
    handleContentLoadingOff,
    handleContentLoadingOn,
  ]);

  const renderCiInformation = () => {
    return (
      <>
        <RowSection>
          <HalfWidthContainer>
            <FlexColumnContainer>
              <SectionItemTitle>
                {t("shipment:exporter.document.label.businessLogo")}
              </SectionItemTitle>
              <BusinessLogoContainer>
                <SingleImageUpload
                  onChange={(media) => {
                    handleChange("businessLogoSimpleMedia", media);
                  }}
                  value={
                    ciInfo.businessLogoSimpleMedia
                      ? ciInfo.businessLogoSimpleMedia.mediaUrl
                      : undefined
                  }
                  topAccessory={({ handleEdit, handleRemove }) => {
                    return (
                      <IconButtonContainer>
                        <StyledIconButton
                          buttonColor="black"
                          buttonGrade="tertiary"
                          buttonSize={24}
                          onClick={() => handleEdit()}
                          type="button"
                        >
                          <Icon iconSrc={PencelSvg} iconSize={16} />
                        </StyledIconButton>
                        <StyledIconButton
                          buttonColor="black"
                          buttonGrade="tertiary"
                          buttonSize={24}
                          type="button"
                          onClick={() => {
                            handleChange("businessLogoSimpleMedia", undefined);
                            handleRemove();
                          }}
                        >
                          <Icon iconSrc={CloseSvg} iconSize={16} />
                        </StyledIconButton>
                      </IconButtonContainer>
                    );
                  }}
                />
              </BusinessLogoContainer>
              <FlexAlignCenter>
                <Icon
                  iconSrc={InfoSvg}
                  iconSize={16}
                  className="flex-shrink-0"
                />
                <Typo typoType="b9r" color="gray6">
                  {t("shipment:exporter.document.description.businessLogoFile")}
                </Typo>
              </FlexAlignCenter>
            </FlexColumnContainer>
          </HalfWidthContainer>
          <HalfWidthContainer>
            <FlexColumnContainer>
              <SectionItemTitle>
                {t("shipment:exporter.document.label.documentType")}
              </SectionItemTitle>

              <RadioGroup
                size="large"
                name="shipmentDocumentType"
                value={ciInfo.shipmentDocumentType}
                onChange={(e) => {
                  handleChange("shipmentDocumentType", e.target.value);
                }}
                typoType="b7r"
                options={[
                  { label: "Original", value: "ORIGINAL" },
                  { label: "Draft", value: "DRAFT" },
                  { label: "Copy", value: "COPY" },
                ]}
              />
            </FlexColumnContainer>
          </HalfWidthContainer>
        </RowSection>

        <Divider colGap={24} />

        <InfoBox>
          <ExampleButton
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={24}
            aria-hidden
          >
            <Icon iconSrc={EyeSvg} iconSize={16} />
          </ExampleButton>

          <Typo typoType="b9r" color="gray6">
            {t("shipment:exporter.document.description.hideIcon")}
          </Typo>
        </InfoBox>

        <RowSection>
          <HalfWidthColumnDirection className="gap-40">
            <FlexColumnContainer>
              <TitleCustomInput
                name="shipperTitle"
                value={ciInfo.shipperTitle || ""}
                onChange={handleChangeWithName}
                onClear={() => handleClear("shipperTitle")}
              />

              <StyledTextArea
                name="shipper"
                value={ciInfo.shipper || ""}
                onChange={handleChangeWithName}
              />
            </FlexColumnContainer>
            <FlexColumnContainer>
              <TitleCustomInput
                name="consigneeTitle"
                value={ciInfo.consigneeTitle || ""}
                onChange={handleChangeWithName}
                onClear={() => handleClear("consigneeTitle")}
              />

              <StyledTextArea
                name="consignee"
                value={ciInfo.consignee || ""}
                onChange={handleChangeWithName}
              />
            </FlexColumnContainer>
            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="notifyPartyTitle"
                  value={ciInfo.notifyPartyTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("notifyPartyTitle")}
                />

                <StyledTextArea
                  name="notifyParty"
                  value={ciInfo.notifyParty || ""}
                  onChange={handleChangeWithName}
                  disabled={ciInfo.isNotifyPartySameAsConsignee}
                />

                <StyledCheckboxLabel>
                  <Checkbox
                    checked={ciInfo.isNotifyPartySameAsConsignee}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleChange("notifyParty", ciInfo.consignee);
                      }
                      handleChange(
                        "isNotifyPartySameAsConsignee",
                        e.target.checked
                      );
                    }}
                  />
                  <Typo typoType="b7r">
                    {t(
                      "shipment:exporter.document.checkboxLabel.sameConsignee"
                    )}
                  </Typo>
                </StyledCheckboxLabel>
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isNotifyPartyHidden}
                onClick={() => handleToggle("isNotifyPartyHidden")}
              />
            </PossibleHiddenItem>
          </HalfWidthColumnDirection>

          <HalfWidthColumnDirection className="gap-40">
            <FlexColumnContainer>
              <TitleCustomInput
                name="invoicoNoAndDateTitle"
                value={ciInfo.invoicoNoAndDateTitle || ""}
                onChange={handleChangeWithName}
                onClear={() => handleClear("invoicoNoAndDateTitle")}
              />

              <FlexAlignCenter className="gap-8">
                <HalfInputWidthContainer>
                  <Input disabled value={ciInfo.invoiceNo || ""} />
                </HalfInputWidthContainer>
                <HalfInputWidthContainer>
                  <ConfigProvider
                    theme={{
                      components: {
                        DatePicker: {
                          fontSize: 16,
                          lineHeight: 1.5,
                          fontWeightStrong: 400,
                        },
                      },
                    }}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      allowClear
                      value={
                        ciInfo.invoiceDateAt
                          ? dayjs(ciInfo.invoiceDateAt)
                          : undefined
                      }
                      onChange={(date) => {
                        if (!date) {
                          return handleChange("invoiceDateAt", null);
                        }
                        handleChange("invoiceDateAt", date?.toISOString());
                      }}
                    />
                  </ConfigProvider>
                </HalfInputWidthContainer>
              </FlexAlignCenter>
            </FlexColumnContainer>
            <FlexColumnContainer>
              <FlexAlignStart>
                <PossibleHiddenItem>
                  <FlexColumnContainer>
                    <TitleCustomInput
                      name="poNoTitle"
                      value={ciInfo.poNoTitle || ""}
                      onChange={handleChangeWithName}
                      onClear={() => handleClear("poNoTitle")}
                    />
                    {ciInfo.poNoList?.map((value) => {
                      return <Input disabled value={value || ""} />;
                    })}
                  </FlexColumnContainer>

                  <HiddenButton
                    isHidden={ciInfo.isPoNoHidden}
                    onClick={() => handleToggle("isPoNoHidden")}
                  />
                </PossibleHiddenItem>

                <PossibleHiddenItem>
                  <FlexColumnContainer>
                    <TitleCustomInput
                      name="scNoTitle"
                      value={ciInfo.scNoTitle || ""}
                      onChange={handleChangeWithName}
                      onClear={() => handleClear("scNoTitle")}
                    />
                    {ciInfo.scNoList.map((value) => {
                      return <Input disabled value={value || ""} />;
                    })}
                  </FlexColumnContainer>
                  <HiddenButton
                    isHidden={ciInfo.isScNoHidden}
                    onClick={() => handleToggle("isScNoHidden")}
                  />
                </PossibleHiddenItem>
              </FlexAlignStart>
            </FlexColumnContainer>
            {!ciInfo.isLcInfoHidden && (
              <FlexColumnContainer>
                <TitleCustomInput
                  name="lcNoAndDateTitle"
                  value={ciInfo.lcNoAndDateTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("lcNoAndDateTitle")}
                />

                {ciInfo.lcInfoList &&
                  ciInfo.lcInfoList.map((_, idx) => {
                    return (
                      <FlexAlignStart key={idx.toString()}>
                        <HalfInputWidthContainer>
                          <Input
                            placeholder="Enter L/C No."
                            name={"lcInfoList"}
                            value={ciInfo.lcInfoList[idx].lcNo || ""}
                            onChange={(e) => {
                              handleChange(
                                "lcInfoList",
                                ciInfo.lcInfoList.map((lcData, lcIdx) => {
                                  if (lcIdx === idx) {
                                    return { ...lcData, lcNo: e.target.value };
                                  }
                                  return lcData;
                                })
                              );
                            }}
                            onClear={() =>
                              handleChange(
                                "lcInfoList",
                                ciInfo.lcInfoList.map((lcData, lcIdx) => {
                                  if (lcIdx === idx) {
                                    return { ...lcData, lcNo: "" };
                                  }
                                  return lcData;
                                })
                              )
                            }
                          />
                        </HalfInputWidthContainer>
                        <HalfInputWidthContainer>
                          <ConfigProvider
                            theme={{
                              components: {
                                DatePicker: {
                                  fontSize: 16,
                                  lineHeight: 1.5,
                                  fontWeightStrong: 400,
                                },
                              },
                            }}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              value={
                                ciInfo.lcInfoList[idx].expireDateAt
                                  ? dayjs(ciInfo.lcInfoList[idx].expireDateAt)
                                  : undefined
                              }
                              onChange={(date) => {
                                if (!date) {
                                  return handleChange(
                                    "lcInfoList",
                                    ciInfo.lcInfoList.map((lcData, lcIdx) => {
                                      if (lcIdx === idx) {
                                        return {
                                          ...lcData,
                                          expireDateAt: null,
                                        };
                                      }
                                      return lcData;
                                    })
                                  );
                                }

                                handleChange(
                                  "lcInfoList",
                                  ciInfo.lcInfoList.map((lcData, lcIdx) => {
                                    if (lcIdx === idx) {
                                      return {
                                        ...lcData,
                                        expireDateAt: date?.toISOString(),
                                      };
                                    }
                                    return lcData;
                                  })
                                );
                              }}
                            />
                          </ConfigProvider>
                        </HalfInputWidthContainer>
                      </FlexAlignStart>
                    );
                  })}
              </FlexColumnContainer>
            )}

            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="remarkTitle"
                  value={ciInfo.remarkTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("remarkTitle")}
                />

                <StyledTextArea
                  name="remark"
                  value={ciInfo.remark || ""}
                  maxLength={500}
                  onChange={handleChangeWithName}
                />
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isRemarkHidden}
                onClick={() => handleToggle("isRemarkHidden")}
              />
            </PossibleHiddenItem>
          </HalfWidthColumnDirection>
        </RowSection>

        <Divider colGap={48} />

        <RowSection>
          <HalfWidthColumnDirection className="gap-40">
            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="portOfLoadingTitle"
                  value={ciInfo.portOfLoadingTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("portOfLoadingTitle")}
                />

                <Input
                  name="portOfLoading"
                  value={ciInfo.portOfLoading || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("portOfLoading")}
                />
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isPortOfLoadingHidden}
                onClick={() => handleToggle("isPortOfLoadingHidden")}
              />
            </PossibleHiddenItem>

            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="portOfDischargeTitle"
                  value={ciInfo.portOfDischargeTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("portOfDischargeTitle")}
                />

                <Input
                  name="portOfDischarge"
                  value={ciInfo.portOfDischarge || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("portOfDischarge")}
                />
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isPortOfDischargeHidden}
                onClick={() => handleToggle("isPortOfDischargeHidden")}
              />
            </PossibleHiddenItem>
          </HalfWidthColumnDirection>

          <HalfWidthColumnDirection className="gap-40">
            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="placeOfDeliveryTitle"
                  value={ciInfo.placeOfDeliveryTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("placeOfDeliveryTitle")}
                />

                <Input
                  name="placeOfDelivery"
                  value={ciInfo.placeOfDelivery || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("placeOfDelivery")}
                />
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isPlaceOfDeliveryHidden}
                onClick={() => handleToggle("isPlaceOfDeliveryHidden")}
              />
            </PossibleHiddenItem>
          </HalfWidthColumnDirection>
        </RowSection>

        <Divider colGap={48} />

        <RowSection>
          <HalfWidthColumnDirection className="gap-40">
            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  value={ciInfo.vesselAndVoyageNoTitle || ""}
                  disabled
                />

                <FlexAlignCenter className="gap-8">
                  <HalfInputWidthContainer>
                    <Input
                      name="vessel"
                      value={ciInfo.vessel || ""}
                      onChange={handleChangeWithName}
                      onClear={() => handleClear("vessel")}
                    />
                  </HalfInputWidthContainer>
                  <HalfInputWidthContainer>
                    <Input
                      name="voyageNo"
                      value={ciInfo.voyageNo || ""}
                      onChange={handleChangeWithName}
                      onClear={() => handleClear("voyageNo")}
                    />
                  </HalfInputWidthContainer>
                </FlexAlignCenter>
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isVesselAndVoyageNoHidden}
                onClick={() => handleToggle("isVesselAndVoyageNoHidden")}
              />
            </PossibleHiddenItem>

            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="etdTitle"
                  value={ciInfo.etdTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("etdTitle")}
                />

                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        fontSize: 16,
                        lineHeight: 1.5,
                        fontWeightStrong: 400,
                      },
                    },
                  }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    allowClear
                    value={ciInfo.etdAt ? dayjs(ciInfo.etdAt) : undefined}
                    onChange={(date) => {
                      if (!date) {
                        return handleChange("etdAt", null);
                      }
                      handleChange("etdAt", date?.toISOString());
                    }}
                  />
                </ConfigProvider>
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isEtdHidden}
                onClick={() => handleToggle("isEtdHidden")}
              />
            </PossibleHiddenItem>
          </HalfWidthColumnDirection>

          <HalfWidthColumnDirection className="gap-40">
            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="paymentTermsTitle"
                  value={ciInfo.paymentTermsTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("paymentTermsTitle")}
                />

                <Input
                  name="paymentTerms"
                  value={ciInfo.paymentTerms || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("paymentTerms")}
                />
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isPaymentTermsHidden}
                onClick={() => handleToggle("isPaymentTermsHidden")}
              />
            </PossibleHiddenItem>
            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  name="shippingTermsTitle"
                  value={ciInfo.shippingTermsTitle || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("shippingTermsTitle")}
                />

                <Input
                  name="shippingTerms"
                  value={ciInfo.shippingTerms || ""}
                  onChange={handleChangeWithName}
                  onClear={() => handleClear("shippingTerms")}
                />
              </FlexColumnContainer>

              <HiddenButton
                isHidden={ciInfo.isShippingTermsHidden}
                onClick={() => handleToggle("isShippingTermsHidden")}
              />
            </PossibleHiddenItem>
          </HalfWidthColumnDirection>
        </RowSection>
      </>
    );
  };

  const renderItem = (item: CiItemDto) => {
    const handleItemChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setCiInfo((prev) => {
        const newCiItem = prev.ciItems.map((ciItem) =>
          ciItem.contractId === item.contractId
            ? { ...ciItem, [e.target.name]: e.target.value }
            : ciItem
        );

        return { ...prev, ciItems: newCiItem };
      });
    };

    const handleItemClear = (key: string) => {
      setCiInfo((prev) => {
        const newCiItem = prev.ciItems.map((ciItem) =>
          ciItem.contractId === item.contractId
            ? { ...ciItem, [key]: "" }
            : ciItem
        );

        return { ...prev, ciItems: newCiItem };
      });
    };

    return (
      <>
        <RowSection>
          <HalfWidthColumnDirection className="gap-40">
            <FlexColumnContainer>
              <FlexAlignCenter className="gap-24">
                <PossibleHiddenItem>
                  <FlexColumnContainer>
                    <TitleCustomInput value={item.itemTitle || ""} disabled />
                    <Input
                      name={"item"}
                      value={item.item || ""}
                      onChange={handleItemChange}
                      onClear={() => handleItemClear("item")}
                    />
                  </FlexColumnContainer>
                  <HiddenButton
                    isHidden={item.isItemHidden}
                    onClick={() => {
                      setCiInfo((prev) => {
                        const newCiItem = prev.ciItems.map((ciItem) =>
                          ciItem.contractId === item.contractId
                            ? { ...ciItem, isItemHidden: !ciItem.isItemHidden }
                            : ciItem
                        );

                        return { ...prev, ciItems: newCiItem };
                      });
                    }}
                  />
                </PossibleHiddenItem>

                <PossibleHiddenItem>
                  <FlexColumnContainer>
                    <TitleCustomInput value={item.hsCodeTitle || ""} disabled />

                    <Input
                      name="hsCode"
                      value={item.hsCode || ""}
                      onChange={handleItemChange}
                      onClear={() => handleItemClear("hsCode")}
                    />
                  </FlexColumnContainer>
                  <HiddenButton
                    isHidden={item.isHsCodeHidden}
                    onClick={() => {
                      setCiInfo((prev) => {
                        const newCiItem = prev.ciItems.map((ciItem) =>
                          ciItem.contractId === item.contractId
                            ? {
                                ...ciItem,
                                isHsCodeHidden: !ciItem.isHsCodeHidden,
                              }
                            : ciItem
                        );

                        return { ...prev, ciItems: newCiItem };
                      });
                    }}
                  />
                </PossibleHiddenItem>
              </FlexAlignCenter>
            </FlexColumnContainer>

            <FlexColumnContainer>
              <FlexAlignCenter className="gap-24">
                <PossibleHiddenItem>
                  <FlexColumnContainer>
                    <FlexAlignStart className="gap-8">
                      <FlexColumnContainer>
                        <TitleCustomInput
                          value={item.grossWeightTitle || ""}
                          disabled
                        />
                        <Input disabled value={item.grossWeight || ""} />
                        <Input disabled value={item.grossWeightUnit || ""} />
                      </FlexColumnContainer>
                      <HiddenButton
                        isHidden={isAllGrossWeightHidden}
                        onClick={() => {
                          setCiInfo((prev) => {
                            const newCiItem = prev.ciItems.map((ciItem) => ({
                              ...ciItem,
                              isGrossWeightHidden: !isAllGrossWeightHidden,
                            }));

                            return { ...prev, ciItems: newCiItem };
                          });
                        }}
                      />
                    </FlexAlignStart>
                  </FlexColumnContainer>
                </PossibleHiddenItem>

                <PossibleHiddenItem>
                  <FlexColumnContainer>
                    <FlexAlignStart className="gap-8">
                      <FlexColumnContainer>
                        <TitleCustomInput
                          value={item.netWeightTitle || ""}
                          disabled
                        />
                        <Input disabled value={item.netWeight || ""} />
                        <Input disabled value={item.netWeightUnit || ""} />
                      </FlexColumnContainer>
                      <HiddenButton
                        isHidden={isAllNetWeightHidden}
                        onClick={() => {
                          setCiInfo((prev) => {
                            const newCiItem = prev.ciItems.map((ciItem) => ({
                              ...ciItem,
                              isNetWeightHidden: !isAllNetWeightHidden,
                            }));

                            return { ...prev, ciItems: newCiItem };
                          });
                        }}
                      />
                    </FlexAlignStart>
                  </FlexColumnContainer>
                </PossibleHiddenItem>
              </FlexAlignCenter>
            </FlexColumnContainer>

            <PossibleHiddenItem>
              <FlexColumnContainer>
                <TitleCustomInput
                  disabled
                  value={item.descriptionOfGoodsTitle || ""}
                />

                <StyledTextArea
                  name="descriptionOfGoods"
                  value={item.descriptionOfGoods || ""}
                  maxLength={1000}
                  placeholder={t(
                    "shipment:exporter.document.placeholder.descriptionOfGoods"
                  )}
                  onChange={handleItemChange}
                />
              </FlexColumnContainer>

              <HiddenButton
                isHidden={item.isDescriptionOfGoodsHidden}
                onClick={() => {
                  setCiInfo((prev) => {
                    const newCiItem = prev.ciItems.map((ciItem) =>
                      ciItem.contractId === item.contractId
                        ? {
                            ...ciItem,
                            isDescriptionOfGoodsHidden:
                              !ciItem.isDescriptionOfGoodsHidden,
                          }
                        : ciItem
                    );

                    return { ...prev, ciItems: newCiItem };
                  });
                }}
              />
            </PossibleHiddenItem>
          </HalfWidthColumnDirection>
          <HalfWidthColumnDirection className="gap-40">
            <FlexColumnContainer>
              <FlexAlignEnd className="gap-6">
                <FlexColumnContainer>
                  <TitleCustomInput value={item.quantityTitle || ""} disabled />
                  <FlexAlignCenter className="gap-8">
                    <HalfInputWidthContainer>
                      <Input disabled value={item.quantity || ""} />
                    </HalfInputWidthContainer>
                    <HalfInputWidthContainer>
                      <Input disabled value={item.quantityUnit || ""} />
                    </HalfInputWidthContainer>
                  </FlexAlignCenter>
                </FlexColumnContainer>
              </FlexAlignEnd>
            </FlexColumnContainer>

            <FlexColumnContainer>
              <FlexAlignCenter className="gap-24">
                <FlexColumnContainer>
                  <TitleCustomInput
                    value={item.unitPriceTitle || ""}
                    disabled
                  />

                  <FlexAlignStart className="gap-8">
                    <FlexColumnContainer>
                      <Input disabled value={item.unitPrice || ""} />
                      <Input disabled value={item.unitPriceUnit || ""} />
                    </FlexColumnContainer>
                  </FlexAlignStart>
                </FlexColumnContainer>

                <FlexColumnContainer>
                  <TitleCustomInput value={item.amountTitle || ""} disabled />

                  <FlexAlignStart className="gap-8">
                    <FlexColumnContainer>
                      <Input disabled value={item.amount || ""} />
                      <Input disabled value={item.amountUnit || ""} />
                    </FlexColumnContainer>
                  </FlexAlignStart>
                </FlexColumnContainer>
              </FlexAlignCenter>
            </FlexColumnContainer>
          </HalfWidthColumnDirection>
        </RowSection>
      </>
    );
  };

  const renderTotal = () => {
    const isTotalGrossWeightHidden = ciInfo.ciItems.some(
      ({ isGrossWeightHidden }) => isGrossWeightHidden
    );
    const isTotalNetWeightHidden = ciInfo.ciItems.some(
      ({ isNetWeightHidden }) => isNetWeightHidden
    );

    return (
      <RowSection>
        <HalfWidthContainerWithDivider>
          <SectionCardRow
            label="No. of Container"
            value={[
              <Typo typoType="h7">{ciInfo.numberOfContainerTypeA}</Typo>,
              <Typo typoType="h4">{ciInfo.numberOfContainerTypeB}</Typo>,
            ]}
          />
        </HalfWidthContainerWithDivider>
        <ColDivider rowGap={1} />
        <HalfWidthContainerWithDivider>
          <FlexColumnContainer className="gap-16">
            <SectionCardRow
              label="Quantity"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.totalQuantity}
                </Typo>,
              ]}
            />
            {!isTotalGrossWeightHidden && (
              <SectionCardRow
                label="Gross Weight"
                value={[
                  <Typo typoType="b7r" color="gray5">
                    {ciInfo.totalGrossWeight}
                  </Typo>,
                ]}
              />
            )}
            {!isTotalNetWeightHidden && (
              <SectionCardRow
                label="Net Weight"
                value={[
                  <Typo typoType="b7r" color="gray5">
                    {ciInfo.totalNetWeight}
                  </Typo>,
                ]}
              />
            )}
            <SectionCardRow
              label="Amount"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.totalAmount}
                </Typo>,
              ]}
            />
          </FlexColumnContainer>
        </HalfWidthContainerWithDivider>
      </RowSection>
    );
  };

  const renderEtc = () => {
    return (
      <RowSection>
        <HalfWidthColumnDirection className="gap-40">
          <FlexColumnContainer className="gap-16">
            <FlexSpaceBetween>
              <Typo typoType="h4">Bank Detail</Typo>

              <HiddenButton
                isHidden={ciInfo.isBankDetailHidden}
                onClick={() => handleToggle("isBankDetailHidden")}
              />
            </FlexSpaceBetween>
            <SectionCardRow
              label="Bank Name"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.bankName ?? "-"}
                </Typo>,
              ]}
            />
            <SectionCardRow
              label="Bank Address"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.bankAddress ?? "-"}
                </Typo>,
              ]}
            />
            <SectionCardRow
              label="Bank Tel"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.bankTel ?? "-"}
                </Typo>,
              ]}
            />
            <SectionCardRow
              label="Bank Fax"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.bankFax ?? "-"}
                </Typo>,
              ]}
            />
            <SectionCardRow
              label="Swift Code"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.swiftCode ?? "-"}
                </Typo>,
              ]}
            />

            <SectionCardRow
              label="Account No."
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.accountNumber ?? "-"}
                </Typo>,
              ]}
            />

            <SectionCardRow
              label="Account Name"
              value={[
                <Typo typoType="b7r" color="gray5">
                  {ciInfo.accountName ?? "-"}
                </Typo>,
              ]}
            />
          </FlexColumnContainer>
        </HalfWidthColumnDirection>
        <ColDivider rowGap={1} />
        <HalfWidthColumnDirection className="gap-40">
          <FlexColumnContainer className="gap-16">
            <Typo typoType="h4">
              {t("shipment:exporter.document.label.selectSignature")}
            </Typo>

            <FlexAlignCenter>
              <SingleSignatureContainer>
                <SignatureDiv>
                  <SingleImageUpload
                    onChange={(media, type) => {
                      handleChange("simpleSignatureMedias", [
                        media,
                        ciInfo.simpleSignatureMedias?.[1],
                      ]);

                      if (
                        (!ciInfo.simpleSignatureMedias?.[1] &&
                          type === "create") ||
                        (type === "edit" &&
                          ciInfo.simpleSignatureMedias?.[0].id ===
                            selectedSignatureMediaId)
                      ) {
                        setSelectedSignatureMediaId(media.id);
                      }
                    }}
                    value={
                      ciInfo.simpleSignatureMedias?.[0]?.mediaUrl || undefined
                    }
                    topAccessory={({ handleEdit, handleRemove }) => {
                      return (
                        <IconButtonContainer>
                          <StyledIconButton
                            buttonColor="black"
                            buttonGrade="tertiary"
                            buttonSize={24}
                            onClick={handleEdit}
                            type="button"
                          >
                            <Icon iconSrc={PencelSvg} iconSize={16} />
                          </StyledIconButton>
                          <StyledIconButton
                            buttonColor="black"
                            buttonGrade="tertiary"
                            buttonSize={24}
                            type="button"
                            onClick={() => {
                              if (ciInfo.simpleSignatureMedias?.[1]) {
                                setSelectedSignatureMediaId(
                                  ciInfo.simpleSignatureMedias[1].id
                                );
                                handleChange("simpleSignatureMedias", [
                                  ciInfo.simpleSignatureMedias[1],
                                  null,
                                ]);
                              } else {
                                handleChange("simpleSignatureMedias", [
                                  null,
                                  null,
                                ]);
                                setSelectedSignatureMediaId(undefined);
                              }
                              handleRemove();
                            }}
                          >
                            <Icon iconSrc={CloseSvg} iconSize={16} />
                          </StyledIconButton>
                        </IconButtonContainer>
                      );
                    }}
                  />
                </SignatureDiv>

                <Radio
                  label={t(
                    "shipment:exporter.document.radioLabel.signatureOne"
                  )}
                  typoType="b7r"
                  checked={
                    ciInfo.simpleSignatureMedias?.[0] &&
                    ciInfo.simpleSignatureMedias[0].id ===
                      selectedSignatureMediaId
                  }
                  disabled={!ciInfo.simpleSignatureMedias?.[0]}
                  onChange={() => {
                    setSelectedSignatureMediaId(
                      ciInfo.simpleSignatureMedias?.[0].id
                    );
                  }}
                />
              </SingleSignatureContainer>

              <SingleSignatureContainer>
                <SignatureDiv>
                  <SingleImageUpload
                    onChange={(media, type) => {
                      if (!ciInfo.simpleSignatureMedias?.[0]) {
                        handleChange("simpleSignatureMedias", [media, null]);
                        setSelectedSignatureMediaId(media.id);
                      } else {
                        handleChange("simpleSignatureMedias", [
                          ciInfo.simpleSignatureMedias[0],
                          media,
                        ]);
                      }

                      if (
                        type === "edit" &&
                        ciInfo.simpleSignatureMedias?.[1].id ===
                          selectedSignatureMediaId
                      ) {
                        setSelectedSignatureMediaId(media.id);
                      }
                    }}
                    value={
                      ciInfo.simpleSignatureMedias?.[1]?.mediaUrl || undefined
                    }
                    topAccessory={({ handleEdit, handleRemove }) => {
                      return (
                        <IconButtonContainer>
                          <StyledIconButton
                            buttonColor="black"
                            buttonGrade="tertiary"
                            buttonSize={24}
                            onClick={() => handleEdit()}
                            type="button"
                          >
                            <Icon iconSrc={PencelSvg} iconSize={16} />
                          </StyledIconButton>
                          <StyledIconButton
                            buttonColor="black"
                            buttonGrade="tertiary"
                            buttonSize={24}
                            type="button"
                            onClick={() => {
                              handleChange("simpleSignatureMedias", [
                                ciInfo.simpleSignatureMedias?.[0],
                                null,
                              ]);
                              if (!!ciInfo.simpleSignatureMedias?.[0]) {
                                setSelectedSignatureMediaId(
                                  ciInfo.simpleSignatureMedias[0].id
                                );
                              }

                              handleRemove();
                            }}
                          >
                            <Icon iconSrc={CloseSvg} iconSize={16} />
                          </StyledIconButton>
                        </IconButtonContainer>
                      );
                    }}
                  />
                </SignatureDiv>

                <Radio
                  label={t(
                    "shipment:exporter.document.radioLabel.signatureTwo"
                  )}
                  typoType="b7r"
                  checked={
                    ciInfo.simpleSignatureMedias?.[1] &&
                    ciInfo.simpleSignatureMedias[1].id ===
                      selectedSignatureMediaId
                  }
                  disabled={!ciInfo.simpleSignatureMedias?.[1]}
                  onChange={() => {
                    setSelectedSignatureMediaId(
                      ciInfo.simpleSignatureMedias?.[1].id
                    );
                  }}
                />
              </SingleSignatureContainer>
            </FlexAlignCenter>
          </FlexColumnContainer>
        </HalfWidthColumnDirection>
      </RowSection>
    );
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) {
      return null;
    }

    if (alertDialogState === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t("shipment:exporter.alertDialog.backToPrevious.title")}
          description={t(
            "shipment:exporter.alertDialog.backToPrevious.description"
          )}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onCancel={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("common:cancel")}
          okText={t("common:ok")}
          onOk={() => navigate(-1)}
        />
      );
    }
    if (alertDialogState === AlertDialogState.DOCUMENT_INIT) {
      return (
        <AlertDialog
          open
          title={t("shipment:exporter.alertDialog.documentIntialization.title")}
          description={t(
            "shipment:exporter.alertDialog.documentIntialization.description"
          )}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onCancel={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("common:cancel")}
          okText={t("common:ok")}
          onOk={() => {
            handleCiIntialize();
            setAlertDialogState(AlertDialogState.NULL);
          }}
        />
      );
    }
  };

  return (
    <ExporterMainLayout
      breadcrumb={[
        "...",
        t("sideNav:shipmentDetail"),
        t("shipment:exporter.document.title.createCI"),
      ]}
      customPageTitle={
        <FlexSpaceBetween style={{ padding: "16px 0" }}>
          <FlexAlignCenter className="gap-8">
            <Typo as="h2" typoType="h1" color="gray1">
              {t("shipment:exporter.document.title.createCI")}
            </Typo>

            {renderDocumentStatusBadge({
              type:
                ciInfo.ciStatus === "CI_TEMPORARY"
                  ? "TEMPORARY_REGISTER"
                  : ciInfo.ciStatus === "CI_FINAL"
                  ? "FINAL"
                  : "BEFORE_REGISTRATION",
              t,
              size: "L",
            })}
          </FlexAlignCenter>

          <Button
            buttonSize={32}
            buttonGrade="secondary"
            buttonColor="blue"
            onClick={() => setAlertDialogState(AlertDialogState.DOCUMENT_INIT)}
          >
            {t("shipment:exporter.document.button.documentInitialization")}
          </Button>
        </FlexSpaceBetween>
      }
    >
      <InnerLayoutContainer>
        <SectionCard
          cardTitle={t("shipment:exporter.document.title.ciInformation")}
        >
          {renderCiInformation()}
        </SectionCard>
        {ciInfo.ciItems.map((item, index) => {
          return (
            <SectionCard cardTitle={`Item ${addZeroPrefix(index + 1)}`}>
              {renderItem(item)}
            </SectionCard>
          );
        })}
        <SectionCard cardTitle="Total">{renderTotal()}</SectionCard>
        <SectionCard cardTitle="Etc">{renderEtc()}</SectionCard>
        <BottomFixedContainer>
          <FlexSpaceBetween>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
              onClick={() =>
                setAlertDialogState(AlertDialogState.BACK_TO_PREVIOUS)
              }
            >
              <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
              {t("shipment:exporter.document.button.backToPrevious")}
            </Button>

            <SaveButtonContainer>
              <CiPreview
                data={{
                  ...previewPdfResourceRef.current,
                  etdAt: previewPdfResourceRef.current?.etdAt
                    ? dayjs(previewPdfResourceRef.current?.etdAt).format(
                        "YYYY-MM-DD"
                      )
                    : undefined,
                  lcInfoList: previewPdfResourceRef.current.lcInfoList
                    ? previewPdfResourceRef.current.lcInfoList.map(
                        ({ lcNo, expireDateAt }) => {
                          return {
                            lcNo,
                            expireDateAt:
                              dayjs(expireDateAt).format("YYYY-MM-DD"),
                          };
                        }
                      )
                    : [],
                  invoiceDateAt: previewPdfResourceRef.current?.invoiceDateAt
                    ? dayjs(
                        previewPdfResourceRef.current?.invoiceDateAt
                      ).format("YYYY-MM-DD")
                    : undefined,
                  commonWeightUnit:
                    previewPdfResourceRef.current.ciItems?.[0]?.netWeightUnit,
                  componyLogoUrl:
                    previewPdfResourceRef.current.businessLogoSimpleMedia
                      ?.mediaUrl,
                  signatureUrl:
                    previewPdfResourceRef.current.simpleSignatureMedias &&
                    previewPdfResourceRef.current.simpleSignatureMedias.filter(
                      Boolean
                    ).length
                      ? previewPdfResourceRef.current.simpleSignatureMedias?.find(
                          ({ id }) => selectedSignatureMediaId === id
                        )?.mediaUrl
                      : undefined,
                  totalIsGrossWeightHidden:
                    previewPdfResourceRef.current.ciItems.some(
                      ({ isGrossWeightHidden }) => isGrossWeightHidden
                    ),
                  totalIsNetWeightHidden:
                    previewPdfResourceRef.current.ciItems.some(
                      ({ isNetWeightHidden }) => isNetWeightHidden
                    ),
                }}
                trigger={<HiddenPreviewButton ref={previewButtonRef} />}
              />

              <Button
                buttonGrade="tertiary"
                buttonColor="black"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
                isLoading={isLoading}
                onClick={() => {
                  previewPdfResourceRef.current = ciInfo;
                  force({});
                  setIsLoading(true);
                  setTimeout(() => {
                    previewButtonRef.current?.click();
                    setIsLoading(false);
                  }, 1000);
                }}
              >
                <Icon iconSrc={PreviewSvg} iconSize={16} />{" "}
                {t("shipment:exporter.document.button.preview")}
              </Button>

              <Button
                buttonGrade="secondary"
                buttonColor="blue"
                onClick={handleCreateTempCi}
              >
                {t("shipment:exporter.document.button.temporaryRegister")}
              </Button>
              <Button
                buttonGrade="primary"
                style={{ width: 158 }}
                type="submit"
                onClick={handleCreateFinalCi}
              >
                {t("shipment:exporter.document.button.finalRegister")}
              </Button>
            </SaveButtonContainer>
          </FlexSpaceBetween>
        </BottomFixedContainer>
      </InnerLayoutContainer>
      {renderAlertDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterAddCiPage;

const InnerLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const InfoBox = styled.div`
  padding: 16px;
  background: ${colorSet.gray11};
  border-radius: 8px;
  margin-bottom: 24px;

  display: flex;
  gap: 8px;
  align-items: center;
`;

const ExampleButton = styled(IconButton)`
  transform: none;

  &:hover {
    transform: none;
  }
`;

const TitleCustomInput = styled(Input)`
  ${typo.h7}
`;

const RowSection = styled.section`
  display: flex;
  width: 100%;
  gap: 24px;
`;

const HalfWidthContainer = styled.div`
  width: calc(50% - 12px);
`;

const HalfInputWidthContainer = styled.div`
  width: calc(50% - 4px);
`;

const HalfWidthContainerWithDivider = styled.div`
  width: calc(50% - 12.5px);
`;

const HalfWidthColumnDirection = styled(HalfWidthContainer)`
  display: flex;
  flex-direction: column;

  &.gap-40 {
    gap: 40px;
  }
`;

const BusinessLogoContainer = styled.div`
  height: 142px;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px 4px 0 0;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &.gap-16 {
    gap: 16px;
  }
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }

  &.gap-8 {
    gap: 8px;
  }

  &.gap-24 {
    gap: 24px;
  }
`;

const FlexAlignStart = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const FlexAlignEnd = styled.div`
  display: flex;
  align-items: flex-end;

  &.gap-6 {
    gap: 6px;
  }
`;

const SectionItemTitle = styled.p`
  ${typo.h7};
  color: ${colorSet.gray2};
`;

const StyledTextArea = styled(TextArea)`
  height: 100px;
`;

const PossibleHiddenItem = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: flex-start;
`;

const StyledCheckboxLabel = styled.label`
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

const SingleSignatureContainer = styled.div`
  width: calc(50% - 4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const SignatureDiv = styled.div`
  height: 128px;
  width: 100%;
`;

const Divider = styled.div<{ colGap?: number }>`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
  ${({ colGap }) =>
    colGap
      ? css`
          margin: ${colGap}px 0;
        `
      : css`
          margin: 24px 0;
        `};
`;

const ColDivider = styled.div<{ rowGap?: number }>`
  width: 1px;
  background: ${colorSet.gray9};
  ${({ rowGap }) =>
    rowGap
      ? css`
          margin: 0 ${rowGap}px;
        `
      : css`
          margin: 0 24px;
        `};
`;

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const HiddenButton = ({
  isHidden,
  onClick,
}: {
  isHidden: boolean;
  onClick?: () => void;
}) => {
  return (
    <IconButton buttonColor="black" buttonGrade="tertiary" onClick={onClick}>
      <Icon iconSrc={isHidden ? EyeOffSvg : EyeSvg} iconSize={20} />
    </IconButton>
  );
};

const HiddenPreviewButton = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
