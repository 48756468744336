import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import Icon from "@/src/components/atom/Icon";
import { renderLoadingStatusBadge } from "../utils/renderTaskStatusBadge";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import getTableIndex from "@/src/utils/getTableIndex";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import styled, { css } from "styled-components";
import colorSet from "@/src/styles/color";
import { CopiedBadge } from "..";
import { isNull, isUndefined } from "@/src/utils/is";
import { renderShipmentStatusBadge } from "@/src/pages/exporter/Shipment/utils/renderShipmentStatusBadge";

export const columnTaskList: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "isBookmarked",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isBookmarked === true) {
        return <Icon iconSrc={BookmarkSvg} iconSize={18} />;
      }

      return null;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "taskStatus",
    flex: 1,
    minWidth: 192,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        return renderLoadingStatusBadge({
          type: params.data.taskStatus,
          t: params.data.t,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "taskNo",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.taskNo ? (
        <Typo typoType="b9r">
          {(params.data.isCopiedTask || params.data.isCombinedOrSplited) && (
            <CopiedBadge size="xs">C</CopiedBadge>
          )}
          {params.data.taskNo}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "scNo",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.scNo ? (
        <Typo typoType="b9r">{params.data.scNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "bookingNo",
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.bookingNo ? (
        <Typo typoType="b9r">{params.data.bookingNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "workingDayAt",
    flex: 1,
    minWidth: 150,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.workingDayAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.workingDayAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "workplaceName",
    flex: 1,
    minWidth: 240,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.workplaceName ? (
        <Typo typoType="b9r">{params.data.workplaceName}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "taskCode",
    flex: 1,
    minWidth: 130,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.taskCode ? (
        <Typo typoType="b9r">{params.data.taskCode}</Typo>
      ) : (
        "-"
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "mainCategoryCodeItemName",
    flex: 1,
    minWidth: 240,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.mainCategory ? (
        <Typo typoType="b9r">{params.data.mainCategory}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 240,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "numberOfTaskContainer",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfTaskContainer && params.data.containerType ? (
        <Typo typoType="b9r">
          {params.data.numberOfTaskContainer}x{params.data.containerType}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    minWidth: 240,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerNameCode ? (
        <Typo typoType="b9r">{params.data.buyerNameCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "docCutOffAt",
    flex: 1,
    minWidth: 220,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.docCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.docCutOffAt).format(
              dayjs(params.data.docCutOffAt).get("minute") === 30
                ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                : DATE_FORMAT_STRINGS.YYYY_MM_DD_hA
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "cargoCutOffAt",
    flex: 1,
    minWidth: 220,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.cargoCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.cargoCutOffAt).format(
              dayjs(params.data.cargoCutOffAt).get("minute") === 30
                ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                : DATE_FORMAT_STRINGS.YYYY_MM_DD_hA
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "etdAt",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etdAt) {
        return (
          <>
            <Typo typoType="b9r">
              {dayjs(params.data.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
            </Typo>{" "}
            <EtdCompareCount
              initDate={params.data.initialEtdAt || ""}
              currentDate={params.data.etdAt || ""}
              typoType="b9m"
            />
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "portOfLoading",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.portOfLoading ? (
        <Typo typoType="b9r">{params.data.portOfLoading}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 165,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.placeOfDelivery ? (
        <Typo typoType="b9r">{params.data.placeOfDelivery}</Typo>
      ) : (
        "-"
      );
    },
  },
];

const notSortableDef = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
};

export const combineSplitBookingTableColumn: ColDef[] = [
  {
    field: "bookingNo",
    width: 125,
    cellClass: () => {
      return "pointer-events-auto";
    },
    cellRenderer: (params: any) => {
      if (!params.data.bookingNo) {
        return "-";
      }
      return (
        <BookingTextButton
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {params.data.bookingNo}
        </BookingTextButton>
      );
    },
    ...notSortableDef,
  },
  {
    field: "etd",
    width: 170,
    cellClass: (params) => {
      return params.data.isOverThanRemainingContainer
        ? "pointer-events-none"
        : undefined;
    },
    cellRenderer: (params: any) => {
      return (
        <>
          <Typo
            typoType="b9r"
            color={params.data.isOverThanRemainingContainer ? "gray7" : "gray2"}
          >
            {dayjs(params.data.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
          </Typo>{" "}
          <EtdCompareCount
            initDate={params.data.initialEtdAt || ""}
            currentDate={params.data.etdAt || ""}
            typoType="b9m"
            color={
              params.data.isOverThanRemainingContainer ? "gray7" : undefined
            }
          />
        </>
      );
    },
    ...notSortableDef,
  },
  {
    field: "buyerNameCode",
    width: 120,
    cellClass: (params) => {
      return params.data.isOverThanRemainingContainer
        ? "pointer-events-none"
        : undefined;
    },
    ...notSortableDef,
  },
  {
    field: "itemCode",
    width: 110,
    cellClass: (params) => {
      return params.data.isOverThanRemainingContainer
        ? "pointer-events-none"
        : undefined;
    },
    ...notSortableDef,
  },
  {
    field: "numberOfContainer",
    width: 150,
    cellClass: (params) => {
      return params.data.isOverThanRemainingContainer
        ? "pointer-events-none"
        : undefined;
    },
    cellRenderer: (params: any) => {
      if (!params.data.numberOfContainer) {
        return "-";
      }
      return `${params.data.numberOfContainer} ${params.data.t("common:ea")}`;
    },
    ...notSortableDef,
  },
  {
    field: "remainingNumberOfContainer",
    cellClass: (params) => {
      return params.data.isOverThanRemainingContainer
        ? "pointer-events-none"
        : undefined;
    },
    cellRenderer: (params: any) => {
      if (
        isNull(params.data.remainingNumberOfContainer) ||
        isUndefined(params.data.remainingNumberOfContainer)
      ) {
        return "-";
      }
      return (
        <RemainingContainerText
          isRedTextColor={params.data.isOverThanRemainingContainer}
        >
          {params.data.remainingNumberOfContainer} {params.data.t("common:ea")}
        </RemainingContainerText>
      );
    },
    ...notSortableDef,
  },
  {
    field: "etaAt",
    cellClass: (params) => {
      return params.data.isOverThanRemainingContainer
        ? "pointer-events-none"
        : undefined;
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etaAt) {
        return (
          <Typo
            typoType="b9r"
            color={params.data.isOverThanRemainingContainer ? "gray7" : "gray2"}
          >
            {dayjs(params.data.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
          </Typo>
        );
      } else {
        return "-";
      }
    },
    ...notSortableDef,
  },
];

export const combineSplitContractTableColumn: ColDef[] = [
  {
    field: "orderDateAt",
    width: 125,
    cellRenderer: (params: any) => {
      if (!params.data.orderDateAt) {
        return "-";
      }
      return (
        <Typo typoType="b9r">
          {dayjs(params.data.orderDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )}
        </Typo>
      );
    },
    ...notSortableDef,
  },
  {
    field: "scNo",
    width: 140,
    ...notSortableDef,
  },
  {
    field: "itemCode",
    width: 110,
    ...notSortableDef,
  },
  {
    field: "qty",
    width: 110,
    cellRenderer: (params: any) => {
      if (!params.data.quantity || !params.data.quantityUnit) {
        return "-";
      }
      return (
        <Typo typoType="b9r">
          {Number(params.data.quantity).toLocaleString()}{" "}
          {params.data.quantityUnit}
        </Typo>
      );
    },
    ...notSortableDef,
  },
  {
    field: "unitprice",
    width: 150,
    cellRenderer: (params: any) => {
      if (!params.data.unitPrice || !params.data.unitPriceUnit) {
        return "-";
      }
      return (
        <Typo typoType="b9r">
          {Number(params.data.unitPrice).toLocaleString()}{" "}
          {params.data.unitPriceUnit}
        </Typo>
      );
    },
    ...notSortableDef,
  },
  {
    headerName: "Shipping Terms",
    field: "shippingTerms",
    cellRenderer: (params: any) => {
      if (!params.data.shippingTerms) {
        return "-";
      }
      return (
        <Typo typoType="b9r">
          {params.data.shippingTerms} {params.data.shippingTermsRemark || ""}
        </Typo>
      );
    },
    ...notSortableDef,
  },
  {
    field: "placeOfDelivery",
    cellRenderer: (params: any) => {
      if (!params.data.placeOfDelivery) {
        return "-";
      }
      return <Typo typoType="b9r">{params.data.placeOfDelivery}</Typo>;
    },
    ...notSortableDef,
  },
  {
    field: "origin",
    width: 150,
    cellRenderer: (params: any) => {
      if (!params.data.origin) {
        return "-";
      }
      return <Typo typoType="b9r">{params.data.origin}</Typo>;
    },
    ...notSortableDef,
  },
  {
    field: "lastShipmentDateAt",
    width: 150,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.lastShipmentDateAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.lastShipmentDateAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
    ...notSortableDef,
  },
];

export const combineSplitShipmentTableColumn: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    field: "shipmentStatus",
    flex: 1,
    minWidth: 143,
    sortable: false,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        return renderShipmentStatusBadge({
          t: params.data.t,
          type: params.data.shipmentStatus,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerNameCode ? (
        <Typo typoType="b9r">{params.data.buyerNameCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "bookingNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.bookingNo ? (
        <Typo typoType="b9r">{params.data.bookingNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "scNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.scNo ? (
        <Typo typoType="b9r">{params.data.scNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "etdAt",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etdAt) {
        return (
          <>
            <Typo typoType="b9r">
              {dayjs(params.data.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
            </Typo>{" "}
            <EtdCompareCount
              initDate={params.data.initialEtdAt || ""}
              currentDate={params.data.etdAt || ""}
              typoType="b9m"
            />
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "etaAt",
    flex: 1,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etaAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "totalNetWeight",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalNetWeight ? (
        <Typo typoType="b9r">{params.data.totalNetWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "unitPriceUnit",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitPriceUnit ? (
        <Typo typoType="b9r">{params.data.unitPriceUnit}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "docCutOffAt",
    flex: 1,
    minWidth: 220,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.docCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.docCutOffAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "portOfLoading",
    flex: 1,
    minWidth: 170,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.portOfLoading ? (
        <Typo typoType="b9r">{params.data.portOfLoading}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 170,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.placeOfDelivery ? (
        <Typo typoType="b9r">{params.data.placeOfDelivery}</Typo>
      ) : (
        "-"
      );
    },
  },
];

export const containerHistoryColumn: ColDef[] = [
  {
    field: "taskNo",
    width: 180,
    cellRenderer: "LinkTaskCellRenderer",
    ...notSortableDef,
  },
  {
    field: "historyDescription",
    flex: 1,
    ...notSortableDef,
  },
  {
    field: "createdAt",
    width: 160,
    cellRenderer: (params: any) => {
      if (!params.data.createdAt) {
        return "-";
      }
      return (
        <Typo typoType="b9r">
          {dayjs(params.data.createdAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
          )}
        </Typo>
      );
    },
    ...notSortableDef,
  },
];

const BookingTextButton = styled.p`
  text-decoration: underline;
  color: ${colorSet.systemBlue2};
  cursor: pointer;
`;

const RemainingContainerText = styled.span<{ isRedTextColor: boolean }>`
  ${({ isRedTextColor }) =>
    isRedTextColor
      ? css`
          color: ${colorSet.red2};
        `
      : css`
          color: ${colorSet.gray2};
        `};
`;
