import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import SectionCard from "@/src/components/molecule/SectionCard";
import { Button } from "@/src/components/atom/Button";
import { Controller, useForm } from "react-hook-form";
import { TemporarySignupRootContext } from ".";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import { useNavigate } from "react-router-dom";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import InfoSvg from "@/src/assets/icons/icon-info.svg";
import {
  useGetCommonCodeViaCodeNameQuery,
  useGetTimeZonesQuery,
} from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import {
  useLazyCheckBusinessNameQuery,
  useLazyCheckBusinessNumberQuery,
} from "@/src/store/apis/auth";
import { buyerApi } from "@/src/store/apis/client/buyer";
import { isUndefined } from "@/src/utils/is";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Label from "@/src/components/atom/Label";
import Select from "@/src/components/atom/Select";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";

interface TemporarySignupStepOneProps {
  buyerId: number;
}

const TemporarySignupStepOne = ({ buyerId }: TemporarySignupStepOneProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signUpForm = useContext(TemporarySignupRootContext);

  const {
    countryCodeItemName,
    businessNumber,
    companyName,
    tel,
    telPrefix,
    fax,
    faxPrefix,
    streetAddress,
    locality,
    region,
    postalCode,
    countryName,
  } = buyerApi.endpoints.getBuyersNoToken.useQueryState(
    {
      id: buyerId,
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          countryCodeItemName: isStable
            ? `${currentData?.buyerQueryResultDto.countryCodeItemName}&${
                currentData?.buyerQueryResultDto.country.value1 ?? ""
              }`
            : "",
          businessNumber: isStable ? currentData?.businessNumber : "",
          companyName: isStable
            ? currentData?.buyerQueryResultDto.buyerName
            : "",
          tel: isStable ? currentData?.buyerQueryResultDto.tel : "",
          telPrefix: isStable
            ? currentData?.buyerQueryResultDto.country.value1
            : "",
          fax: isStable ? currentData?.buyerQueryResultDto.fax || "" : "",
          faxPrefix: isStable
            ? currentData?.buyerQueryResultDto.country.value1
            : "",
          streetAddress: isStable
            ? currentData?.buyerQueryResultDto.buyerStreetAddress
            : "",
          locality: isStable
            ? currentData?.buyerQueryResultDto.buyerLocality
            : "",
          region: isStable ? currentData?.buyerQueryResultDto.buyerRegion : "",
          postalCode: isStable
            ? currentData?.buyerQueryResultDto.buyerPostalCode
            : "",
          countryName: isStable
            ? currentData?.buyerQueryResultDto.buyerCountryName
            : "",

          city: isStable ? currentData?.city : "",
          gmtOffset: isStable ? currentData?.gmtOffset : 0,
        };
      },
    }
  );

  const [checkBusinessNumber] = useLazyCheckBusinessNumberQuery();
  const [checkBusinessName] = useLazyCheckBusinessNameQuery();

  const {
    control,
    setFocus,
    setValue,
    formState: { errors, defaultValues },
    register,
    clearErrors,
    handleSubmit,
    getValues,
    watch,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      countryCodeItemName: signUpForm?.countryCodeItemName,
      businessNumber: signUpForm?.businessNumber,
      companyName: signUpForm?.companyName,
      tel: signUpForm?.tel,
      telPrefix: signUpForm?.telPrefix,
      fax: signUpForm?.fax,
      faxPrefix: signUpForm?.faxPrefix,

      apartment: signUpForm?.streetAddress,
      city: signUpForm?.locality,
      state: signUpForm?.region,
      postal: signUpForm?.postalCode,
      country: signUpForm?.countryName,
      timezoneCity: signUpForm?.city,
      timezoneGmtOffset: signUpForm?.gmtOffset,
    },
  });

  const {
    data: countryCategoryList,
    isFetching: isCountryCategoryCodeListFetching,
    isError: isCountryCategoryCodeListError,
  } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "COUNTRY",
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    currentData: timeZoneList,
    isFetching: isFetchingTimeZoneList,
    isError: isErrorTimeZoneList,
  } = useGetTimeZonesQuery(
    {
      countryCode: watch("countryCodeItemName")?.replace(/&+.*/g, ""),
    },
    {
      skip: !watch("countryCodeItemName"),
      refetchOnMountOrArgChange: true,
    }
  );

  const isNextButtonDisabled = !(
    !!watch("companyName") &&
    !!watch("countryCodeItemName") &&
    !!watch("telPrefix") &&
    !!watch("tel") &&
    !!watch("businessNumber") &&
    !!watch("country") &&
    !!watch("timezoneCity") &&
    !!watch("timezoneGmtOffset")
  );

  const handleSubmitClick = () => {
    signUpForm?.onFormValueChange({
      ...(signUpForm as any),
      ...getValues(),
      ...{
        streetAddress: getValues("apartment"),
        locality: getValues("city"),
        region: getValues("state"),
        postalCode: getValues("postal"),
        countryName: getValues("country"),
        city: getValues("timezoneCity"),
        gmtOffset: getValues("timezoneGmtOffset"),
      },
    });
    signUpForm?.setStep?.(2);
  };

  const handleCancelClick = () => {
    navigate(PUBLIC_PATH.ROOT);
  };

  useEffect(() => {
    setFocus("companyName");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUndefined(defaultValues?.countryCodeItemName)) {
      setValue("countryCodeItemName", countryCodeItemName);
    }
    if (isUndefined(defaultValues?.businessNumber)) {
      setValue("businessNumber", businessNumber);
    }
    if (isUndefined(defaultValues?.companyName)) {
      setValue("companyName", companyName);
    }
    if (isUndefined(defaultValues?.tel)) {
      setValue("tel", tel);
    }
    if (isUndefined(defaultValues?.telPrefix)) {
      setValue("telPrefix", telPrefix);
    }
    if (isUndefined(defaultValues?.fax)) {
      setValue("fax", fax);
    }
    if (isUndefined(defaultValues?.faxPrefix)) {
      setValue("faxPrefix", faxPrefix);
    }
    if (isUndefined(defaultValues?.apartment)) {
      setValue("apartment", streetAddress);
    }
    if (isUndefined(defaultValues?.city)) {
      setValue("city", locality);
    }
    if (isUndefined(defaultValues?.state)) {
      setValue("state", region);
    }
    if (isUndefined(defaultValues?.postal)) {
      setValue("postal", postalCode);
    }
    if (isUndefined(defaultValues?.country)) {
      setValue("country", countryName);
    }
  }, [
    businessNumber,
    companyName,
    countryCodeItemName,
    countryName,
    defaultValues?.apartment,
    defaultValues?.businessNumber,
    defaultValues?.city,
    defaultValues?.companyName,
    defaultValues?.country,
    defaultValues?.countryCodeItemName,
    defaultValues?.fax,
    defaultValues?.faxPrefix,
    defaultValues?.postal,
    defaultValues?.state,
    defaultValues?.tel,
    defaultValues?.telPrefix,
    fax,
    faxPrefix,
    locality,
    postalCode,
    region,
    setValue,
    streetAddress,
    tel,
    telPrefix,
  ]);

  useEffect(() => {
    if (timeZoneList && timeZoneList.length === 1) {
      setValue("timezoneCity", timeZoneList[0].zoneName);
      setValue("timezoneGmtOffset", timeZoneList[0].gmtOffset);
      clearErrors("timezoneCity");
    } else {
      // timeZoneList에 값이 2개 이상일 경우 focus 설정
      if (timeZoneList) {
        if (timeZoneList.length >= 2) {
          setFocus("timezoneCity");
        }
      }
    }
  }, [timeZoneList, setValue, clearErrors, setFocus]);

  return (
    <StyledSectionCard
      cardTitle={
        <Typo typoType="h4">{t("temporarySignupCorp:steps.firstStep")}</Typo>
      }
    >
      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <Content>
          <FormItem
            label={t("signupCorp:content.companyName")}
            type="text"
            name="companyName"
            control={control}
            rules={{
              required: true,
              validate: async (companyName) => {
                const data = await checkBusinessName({
                  companyName,
                });
                return !data.isError;
              },
            }}
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:alreadyExisted"),
            }}
            direction="vertical"
            inputProps={{
              placeholder: t("signupCorp:content.companyNamePlaceholder"),
            }}
            bottomAccessory={
              <Flex>
                <Icon iconSrc={InfoSvg} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("signup:common.enterCompanyNameInEng")}
                </Typo>
              </Flex>
            }
          />

          <SectionCardRow
            label={
              <Label required>{t("signupCorp:content.countryAndCity")}</Label>
            }
            direction="vertical"
            value={
              <FlexCol>
                <Flex>
                  <Controller
                    name="countryCodeItemName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <StyledSelect
                          {...field}
                          suffixIcon={
                            isCountryCategoryCodeListFetching ? (
                              <Loader />
                            ) : undefined
                          }
                          disabled={
                            isCountryCategoryCodeListFetching ||
                            isCountryCategoryCodeListError
                          }
                          data-invalid={!!errors.countryCodeItemName}
                          showSearch
                          onChange={(e) => {
                            const countryCodeItem = e.split("&");
                            const countryCodeItemValue1 = countryCodeItem[1];

                            setValue("countryCodeItemName", e);
                            setValue("telPrefix", countryCodeItemValue1);
                            setValue("tel", "");
                            setValue("faxPrefix", countryCodeItemValue1);
                            setValue("fax", "");
                          }}
                          placeholder={t(
                            "signupCorp:content.countryPlaceholder"
                          )}
                          options={
                            isCountryCategoryCodeListError
                              ? []
                              : countryCategoryList?.map((item) => {
                                  return {
                                    label: `${item.codeItemName}) ${item.codeItemNameEn}`,
                                    value: `${item.codeItemName}&${item.value1}`,
                                  };
                                }) ?? []
                          }
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentElement;
                          }}
                          filterOption={(input, option) =>
                            ((option?.label as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase()) ||
                            ((option?.value as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      );
                    }}
                  />
                  <Controller
                    name="timezoneCity"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <StyledSelect
                          {...field}
                          suffixIcon={
                            isFetchingTimeZoneList ? <Loader /> : undefined
                          }
                          disabled={
                            isFetchingTimeZoneList ||
                            isErrorTimeZoneList ||
                            !watch("countryCodeItemName")
                          }
                          data-invalid={!!errors.city}
                          showSearch
                          value={
                            timeZoneList?.find(
                              ({ zoneName }) =>
                                zoneName === getValues("timezoneCity")
                            )?.zoneName
                          }
                          onChange={(value) => {
                            if (isErrorTimeZoneList) return;

                            const gmtOffset =
                              timeZoneList?.find(
                                ({ zoneName }) => zoneName === value
                              )?.gmtOffset ?? 0;

                            field.onChange(value);
                            setValue("timezoneGmtOffset", gmtOffset);
                          }}
                          placeholder={t("signupCorp:content.cityPlaceholder")}
                          options={
                            isErrorTimeZoneList
                              ? []
                              : timeZoneList?.map((item) => ({
                                  label: `${item.zoneName} (${t(
                                    "common:utc"
                                  )} ${item.gmtOffset > 0 ? "+" : ""}${
                                    item.gmtOffset
                                  })`,
                                  value: item.zoneName,
                                })) ?? []
                          }
                          filterOption={(inputValue, option) => {
                            return (
                              ((option?.label as string) ?? "")
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                              ((option?.value as string) ?? "")
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Flex>
                {(errors.countryCodeItemName || errors.timezoneCity) && (
                  <InputError message={t("error:required")} />
                )}
              </FlexCol>
            }
          />

          <FormItem
            label={t("signupCorp:content.businessNumber")}
            type="text"
            name="businessNumber"
            control={control}
            rules={{
              required: true,
              validate: async (number) => {
                const data = await checkBusinessNumber({
                  businessNumber: number,
                });
                return !data.isError;
              },
            }}
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:alreadyExisted"),
            }}
            direction="vertical"
            inputProps={{
              placeholder: t("signupCorp:content.businessNumberPlaceholder"),
            }}
          />

          <ContactFormItem
            label={t("signupCorp:content.contact")}
            prefixValue={watch("telPrefix")}
            prefixName="telPrefix"
            restContactName="tel"
            register={register}
            setValue={setValue}
            direction="vertical"
          />
          <ContactFormItem
            label={t("signupCorp:content.fax")}
            prefixValue={watch("faxPrefix")}
            prefixName="faxPrefix"
            restContactName="fax"
            register={register}
            setValue={setValue}
            direction="vertical"
            isRequired={false}
          />

          <AddressFormContainer>
            <AddressFormItem
              register={register}
              setValue={setValue}
              direction="horizontal"
              isRequired={true}
              error={errors}
              clearErrors={clearErrors}
              label={t("signupCorp:content.address")}
            />
            <Flex>
              <Icon iconSrc={InfoSvg} iconSize={16} />
              <Typo typoType="b9r" color="gray6">
                {t("signupCorp:content.addressEnterEnglish")}
              </Typo>
            </Flex>
          </AddressFormContainer>
        </Content>

        <ButtonContainer>
          <Button
            type="button"
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={handleCancelClick}
          >
            {t("signupCorp:button.back")}
          </Button>
          <Button type="submit" disabled={isNextButtonDisabled}>
            {t("signupCorp:button.next")}
          </Button>
        </ButtonContainer>
      </Form>
    </StyledSectionCard>
  );
};

export default TemporarySignupStepOne;

const StyledSectionCard = styled(SectionCard)`
  width: 800px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 480px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  gap: 8px;

  button {
    flex: 1;
    text-align: center;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FlexCol = styled(Flex)`
  flex-direction: column;
  align-items: stretch;
`;

const AddressFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledSelect = styled(Select)`
  width: calc((100% - 8px) / 2);
`;
