import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Typo from "@/src/components/atom/Typo";
import { useAppSelector } from "@/src/store";
import colorSet from "@/src/styles/color";
import Step1Png from "@/src/assets/images/image-guide-step-1.png";
import Step2Png from "@/src/assets/images/image-guide-step-2.png";
import Step3Png from "@/src/assets/images/image-guide-step-3.png";
import Step4Png from "@/src/assets/images/image-guide-step-4.png";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import { Button } from "@/src/components/atom/Button";
import { useEditExporterUserAdditionalInfosMutation } from "@/src/store/apis/auth";
import useAlert from "@/src/hooks/useAlert";
import { ReactComponent as CloseSvg } from "@/src/assets/icons/icon-close-red.svg";
import Icon from "@/src/components/atom/Icon";
import UserSvg from "@/src/assets/icons/icon-my-page.svg";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useTranslation } from "react-i18next";

interface GuideContentProps {
  onGuideClose: () => void;
}

const GuideContent = ({ onGuideClose }: GuideContentProps) => {
  const { t, i18n } = useTranslation("dashboard");
  const user = useAppSelector((state) => state.auth.user?.name);
  const alert = useAlert();
  const [editExporterUserAdditionalInfo] =
    useEditExporterUserAdditionalInfosMutation();
  const navigate = useNavigate();

  const handleCloseGuide = async () => {
    try {
      await editExporterUserAdditionalInfo({ isInitialGuideNeeded: false });
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message ?? t("alert:unHandled"),
      });
    }
  };

  if (!user) return null;

  const renderStep = () => {
    return (
      <>
        {[
          {
            title: t("dashboard:exporter.guideContent.step1.title"),
            description: (
              <Typo typoType="b7r" className="white-space-pre-wrap">
                {`${t("exporter.guideContent.step1.description")}\n`}
                <Typo as="strong" color="blue1">
                  {t("dashboard:exporter.guideContent.step1.warning")}
                </Typo>
              </Typo>
            ),
            image: Step1Png,
            x: "center",
            y: "center",
            path: EXPORTER_PRIVATE_PATH.COMPANY_INFORMATION_MANAGEMENT,
          },
          {
            title: t("dashboard:exporter.guideContent.step2.title"),
            description: (
              <Typo typoType="b7r">
                {t("dashboard:exporter.guideContent.step2.description")}
              </Typo>
            ),
            image: Step2Png,
            x: "center",
            y: "end",
            path: EXPORTER_PRIVATE_PATH.ITEM_MANAGEMENT,
          },
          {
            title: t("dashboard:exporter.guideContent.step3.title"),
            description: (
              <Typo typoType="b7r">
                {t("dashboard:exporter.guideContent.step3.description")}
              </Typo>
            ),
            image: Step3Png,
            x: "end",
            y: "end",
            path: EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD,
          },
          {
            title: t("dashboard:exporter.guideContent.step4.title"),
            description: (
              <Typo typoType="b7r" className="white-space-pre-wrap">
                {t("dashboard:exporter.guideContent.step4.description")}
              </Typo>
            ),
            image: Step4Png,
            x: "end",
            y: "end",
            path: EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_ADD,
          },
        ].map(({ title, description, image, x, y, path }, idx) => {
          return (
            <StyledButton key={title} onClick={() => navigate(path)}>
              <Left>
                <div>
                  <Typo as="p" typoType="b7m" color="gray6">
                    {t("common:step")} {idx + 1}
                  </Typo>
                  <Typo typoType="d6" color="gray2" className="step-title">
                    {title}
                  </Typo>
                </div>

                {description}
              </Left>

              <StyledFigure>
                <img src={image} alt="step-1" className={`${x}-${y}`} />
              </StyledFigure>
            </StyledButton>
          );
        })}
      </>
    );
  };

  const renderTwoFactorAuthenticationContent = () => {
    if (i18n.language === "ko") {
      return (
        <TwoFactorAuthentication>
          <Typo typoType="h7">
            {t("dashboard:exporter.guideContent.twoFactorAuthentication.label")}
          </Typo>

          <div>
            <Icon iconSrc={UserSvg} iconSize={18} />
            <BlueText
              typoType="b7r"
              color="systemBlue2"
              onClick={() => navigate(EXPORTER_PRIVATE_PATH.MY_PAGE)}
            >
              {t("common:myPage")}
            </BlueText>
            <Typo typoType="b7r">
              {t(
                "dashboard:exporter.guideContent.twoFactorAuthentication.description"
              )}
            </Typo>
          </div>
        </TwoFactorAuthentication>
      );
    }

    return (
      <TwoFactorAuthentication>
        <Typo typoType="h7">
          {t("dashboard:exporter.guideContent.twoFactorAuthentication.label")}
        </Typo>

        <div>
          <Typo typoType="b7r">
            {t(
              "dashboard:exporter.guideContent.twoFactorAuthentication.description"
            )}
          </Typo>
          <Icon iconSrc={UserSvg} iconSize={18} />
          <BlueText
            typoType="b7r"
            color="systemBlue2"
            onClick={() => navigate(EXPORTER_PRIVATE_PATH.MY_PAGE)}
          >
            {t("common:myPage")}
          </BlueText>
        </div>
      </TwoFactorAuthentication>
    );
  };

  return (
    <Wrapper>
      <CloseButton onClick={onGuideClose}>
        <Close />
      </CloseButton>
      <Article>
        {renderTwoFactorAuthenticationContent()}

        <header>
          <Typo as="h1" typoType="d3" color="gray1">
            {`${t("common:welcome")} ${user}\n${t(
              "dashboard:exporter.guideContent.title"
            )}`}
          </Typo>

          <Typo as="p" color="gray1" typoType="b7r">
            {t("dashboard:exporter.guideContent.description1")}
            <Button style={{ pointerEvents: "none" }} aria-hidden tabIndex={0}>
              {t("dashboard:exporter.guideContent.doNotShowAgain")}
            </Button>
            {t("dashboard:exporter.guideContent.description2")}
          </Typo>
        </header>

        <StyledSection>{renderStep()}</StyledSection>

        <BottomFixedContainer>
          <Button
            style={{
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
              gap: "4px",
            }}
            onClick={handleCloseGuide}
          >
            {t("dashboard:exporter.guideContent.doNotShowAgain")}
          </Button>
        </BottomFixedContainer>
      </Article>
    </Wrapper>
  );
};

export default GuideContent;

const Article = styled.article`
  width: 944px;
  margin: 24px auto 96px;

  header {
    text-align: center;
    white-space: pre-wrap;

    h1 {
      padding: 40px 0;
    }

    p {
      white-space: pre-wrap;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  padding: 32px 24px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
  word-break: keep-all;

  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  }

  .white-space-pre-wrap {
    white-space: pre-wrap;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .step-title {
    padding-top: 2px;
  }

  flex: 1;
`;

const StyledFigure = styled.figure`
  width: 323px;
  flex-shrink: 0;
  height: 141px;
  overflow: hidden;

  background: ${colorSet.gray10};
  border-radius: 8px;
  border: 1px solid ${colorSet.gray10};

  position: relative;

  img {
    position: absolute;
    height: auto;

    &.center-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.center-end {
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, 0);
    }

    &.end-end {
      bottom: 0%;
      right: 0%;
    }
  }
`;

const StyledSection = styled.section`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 20px;
`;

const Close = styled(CloseSvg)`
  width: 32px;
  height: 32px;

  path {
    fill: #1a1d20;
  }
`;

const TwoFactorAuthentication = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid ${colorSet.gray9};

  & > div {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  cursor: pointer;
  word-break: break-all;
`;
