import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { PulseLoader } from "react-spinners";
import Table from "@/src/components/atom/Table";
import Filter from "@/src/components/molecule/Filter";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import ExcelSvg from "@/src/assets/icons/icon-excel.svg";
import ResetSvg from "@/src/assets/icons/icon-reset-circle.svg";
import DoubleLeftSvg from "@/src/assets/icons/icon-double-left.svg";
import ShipmentSvg from "@/src/assets/icons/icon-shipment-gray11.svg";
import SearchIcon from "@/src/assets/icons/icon-search.svg";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { useGetCommonCodeListQuery } from "@/src/store/apis/common";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import useAgGirdSetColumn from "@/src/hooks/useAgGridSetColumn";
import { gridColIdLocalStorage } from "@/src/utils/localStorageFixedColId";
import { isUndefined } from "@/src/utils/is";
import colorSet from "@/src/styles/color";
import Input from "@/src/components/atom/Input";
import Checkbox from "@/src/components/atom/Checkbox";
import typo from "@/src/styles/typography";
import Tag from "@/src/components/atom/Tag";
import { StyledScroll } from "@/src/styles/scroll";
import { columnShipmentList } from "./columns/columnShipmentList";
import { useGetWarehousesQuery } from "@/src/store/apis/warehouse";
import {
  GetImporterShipmentProgressRequest,
  ShipmentStatusType,
} from "@/src/store/apis/shipments/importerShipment/interface";
import {
  useGetImminentShipmentQuery,
  useLazyGetImporterBookingProgressExcelListQuery,
  useLazyGetImporterShipmentProgressExcelListQuery,
  useLazyGetShipmentByBlQuery,
  useLazyGetShipmentByBookingQuery,
} from "@/src/store/apis/shipments/importerShipment";
import SectionCard from "@/src/components/molecule/SectionCard";
import ImminentShipmentCard from "./components/list/card/ImminentShipmentCard";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";

const Init: Omit<
  GetImporterShipmentProgressRequest,
  "buyerIdList" | "shipmentStatusForImporter" | "warehouseId"
> & {
  buyerIdList: number[];
  shipmentStatusForImporter:
    | GetImporterShipmentProgressRequest["shipmentStatusForImporter"]
    | "all";
  warehouseId: GetImporterShipmentProgressRequest["warehouseId"] | "all";
} = {
  shipmentStatusForImporter: "all",
  bookingOrBlNo: undefined,
  warehouseId: "all",
  poNo: undefined,
  itemCode: undefined,
  mainCategoryCodeItemName: "all",
  placeOfDelivery: "all",
  etaAtTo: undefined,
  etaAtFrom: undefined,
  buyerIdList: [],
};

const EMPTY_ARRAY: [] = [];

const buyerEmptyObject: {
  data: BuyerListViewDto[];
  count: number;
} = { data: [], count: 0 };

type TabType = "BOOKING" | "B/L";

const TAB_LIST: { label: TabType; value: "booking" | "b/l" }[] = [
  { label: "BOOKING", value: "booking" },
  { label: "B/L", value: "b/l" },
];

const FAST_SEARCH_QUERY_KEY = [
  "shipmentStatusForImporter",
  "warehouseId",
  "eta",
  "mainCategoryCodeItemName",
  "placeOfDelivery",
];

const renderTabItemTitle = (value: string) => {
  switch (value) {
    case "booking":
      return "common:booking";
    case "b/l":
      return "common:abbreviationBl";
    default:
      return "";
  }
};

const ImporterShipmentPage = () => {
  const { t } = useTranslation();

  const alert = useAlert();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isSideContentOpen, setIsSideContentOpen] = useState<boolean>(true);
  const [sellerFilterValue, setSellerFilterValue] = useState<string>("");
  const [tab, setTab] = useState<"booking" | "b/l">("booking");
  const [getBookingProgressExcel] =
    useLazyGetImporterBookingProgressExcelListQuery();
  const [getShipmentProgressExcel] =
    useLazyGetImporterShipmentProgressExcelListQuery();

  const {
    func: { handleColumnMoved, handleColumnReset },
    state: { col },
  } = useAgGirdSetColumn({
    key: gridColIdLocalStorage.import_shipment,
    columns: columnShipmentList(t),
  });
  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [
    getShipmentListByBl,
    {
      count,
      isShipmentByBlFetching,
      shipmentByBlList,
      isShipmentByBlListError,
      blOriginalArgs,
    },
  ] = useLazyGetShipmentByBlQuery({
    selectFromResult: ({ currentData, isFetching, isError, originalArgs }) => {
      const isUnstable = isError || isUndefined(currentData);

      return {
        isShipmentByBlFetching: isFetching,
        count: !isUnstable ? currentData.count : 0,
        shipmentByBlList: !isUnstable ? currentData.rows : undefined,
        isShipmentByBlListError: isError,
        blOriginalArgs: originalArgs,
      };
    },
  });
  const [
    getShipmentListByBooking,
    {
      shipmentByBookingCount,
      isShipmentByBookingFetching,
      shipmentByBookingList,
      isShipmentByBookingError,
      bookingOriginalArgs,
    },
  ] = useLazyGetShipmentByBookingQuery({
    selectFromResult: ({ currentData, isFetching, isError, originalArgs }) => {
      const isUnstable = isError || isUndefined(currentData);

      return {
        isShipmentByBookingFetching: isFetching,
        shipmentByBookingCount: !isUnstable ? currentData.count : 0,
        shipmentByBookingList: !isUnstable ? currentData.rows : undefined,
        isShipmentByBookingError: isError,
        bookingOriginalArgs: originalArgs,
      };
    },
  });
  const {
    imminentShipmentList,
    isImminentShipmentListFetching,
    isImminentShipmentListError,
  } = useGetImminentShipmentQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ isError, currentData, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError;
      const isStable = !isUnstable;
      return {
        imminentShipmentList: isStable ? currentData.rows : EMPTY_ARRAY,
        isImminentShipmentListFetching: isFetching,
        isImminentShipmentListError: isError,
      };
    },
  });

  const { warehouses } = useGetWarehousesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isWarehousesFetching: isFetching,
          warehouses: isStable ? currentData.rows : EMPTY_ARRAY,
        };
      },
    }
  );
  const { buyerCurrentData } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isError || isUndefined(currentData);

        return {
          buyerCurrentData: !isUnstable ? currentData : buyerEmptyObject,
        };
      },
    }
  );
  const { commonCodeCurrentData } = useGetCommonCodeListQuery(
    {
      codeNameList: [
        {
          codeName: "MAIN_CATEGORY",
        },
        {
          codeName: "PLACE_OF_DELIVERY",
          sort: "codeItemNameEn",
          order: "ASC",
        },
      ],
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          commonCodeCurrentData: !isUnstable
            ? currentData ?? EMPTY_ARRAY
            : EMPTY_ARRAY,
        };
      },
    }
  );

  const [mainCategoryCurrentData = [], placeOfDeliveryList = []] =
    commonCodeCurrentData;
  const mainCategoryFilterList = mainCategoryCurrentData
    ?.filter((item) =>
      session.currentData?.row.exporter.mainCategoryCodeItemNames.includes(
        item.codeItemName
      )
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });
  const placeOfDelivery = placeOfDeliveryList.map(({ codeItemNameEn }) => ({
    label: codeItemNameEn,
    value: codeItemNameEn,
  }));
  const warehouseOptionList = warehouses
    .filter(({ isActivated }) => isActivated)
    .map(({ warehouseName, id }) => {
      return { label: warehouseName, value: id };
    });

  const allOptionItem = {
    label: t("shipment:importer.list.placeholder.all"),
    value: "all",
  };

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
      onMultipleItemChangeForceSearch,
      onMountSubmit,
      onResetPaginationAndQuery,
    },
  } = useFilterSearch<
    GetImporterShipmentProgressRequest,
    Omit<
      GetImporterShipmentProgressRequest,
      "buyerIdList" | "shipmentStatusForImporter" | "warehouseId"
    > & {
      buyerIdList: number[];
      shipmentStatusForImporter:
        | GetImporterShipmentProgressRequest["shipmentStatusForImporter"]
        | "all";
      warehouseId: GetImporterShipmentProgressRequest["warehouseId"] | "all";
    }
  >({
    skipMountSubmit: true,
    isReady,
    gridRef: gridRef.current,
    fetch: tab === "b/l" ? getShipmentListByBl : getShipmentListByBooking,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        shipmentStatusForImporter:
          data.shipmentStatusForImporter === "all"
            ? undefined
            : data.shipmentStatusForImporter || undefined,
        bookingOrBlNo: data?.bookingOrBlNo || undefined,
        warehouseId:
          data.warehouseId === "all"
            ? undefined
            : data?.warehouseId || undefined,
        poNo: data?.poNo || undefined,
        itemCode: data?.itemCode || undefined,
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        placeOfDelivery:
          data.placeOfDelivery === "all" ? undefined : data?.placeOfDelivery,
        etaAtTo: data?.etaAtTo
          ? dayjs(data.etaAtTo).startOf("day").toISOString()
          : undefined,
        etaAtFrom: data?.etaAtFrom
          ? dayjs(data.etaAtFrom).endOf("day").toISOString()
          : undefined,
        buyerIdList: !!data.buyerIdList?.length
          ? data.buyerIdList.join()
          : undefined,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        shipmentStatusForImporter:
          (urlObject.shipmentStatusForImporter as
            | ShipmentStatusType
            | "all"
            | undefined) ?? "all",
        bookingOrBlNo: urlObject.bookingOrBlNo,
        warehouseId: urlObject.warehouseId
          ? Number(urlObject.warehouseId)
          : "all",
        poNo: urlObject.poNo,
        itemCode: urlObject.itemCode,
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        placeOfDelivery: urlObject.placeOfDelivery ?? "all",
        etaAtTo: urlObject.etaAtTo,
        etaAtFrom: urlObject.etaAtFrom,
        buyerIdList: urlObject.buyerIdList
          ? urlObject.buyerIdList.split(",").map((id) => Number(id))
          : [],
      };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "no",
        langKey: "table:no",
      },
      {
        columnKey: "isBookmarked",
        langKey: "table:isBookmarked",
      },
      {
        columnKey: "bookingStatusForImporter",
        langKey: "table:status",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:sellerAlias",
      },
      {
        columnKey: "poNo",
        langKey: "table:poNo",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "bookingNo",
        langKey: "table:bookingNo",
      },
      {
        columnKey: "blNo",
        langKey: "table:blNo",
      },
      {
        columnKey: "numberOfContainer",
        langKey: "table:noOfContainer",
      },
      {
        columnKey: "totalNetWeight",
        langKey: "table:totalNetWeight",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "portOfLoading",
        langKey: "table:portOfLoading",
      },
      {
        columnKey: "unitPrice",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "etdAt",
        langKey: "table:etdAtd",
      },
      {
        columnKey: "etaAt",
        langKey: "table:etaAta",
      },
      {
        columnKey: "loadingPhoto",
        langKey: "table:loadingPhoto",
      },
      {
        columnKey: "ciPl",
        langKey: "table:ciPl",
      },
      {
        columnKey: "bl",
        langKey: "table:bl",
      },
      {
        columnKey: "mainCategoryCodeItemName",
        langKey: "table:mainCategory",
      },
    ],
  });

  const handleExcelDownload = async () => {
    try {
      const originalArgs = tab === "b/l" ? blOriginalArgs : bookingOriginalArgs;
      const getExcel =
        tab === "b/l" ? getShipmentProgressExcel : getBookingProgressExcel;

      if (originalArgs) {
        await getExcel(originalArgs).unwrap();
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const handleClientItemClick = (client: BuyerListViewDto) => {
    if (filterData.buyerIdList?.includes(client.buyerListQueryResultDto.id)) {
      onForceSearch(
        "buyerIdList",
        filterData.buyerIdList.filter(
          (clientId: any) => clientId !== client.buyerListQueryResultDto.id
        )
      );
    } else {
      onForceSearch("buyerIdList", [
        ...(filterData?.buyerIdList || []),
        client.buyerListQueryResultDto.id,
      ]);
    }
  };

  const renderImminentShipmentSectionCard = () => {
    if (isImminentShipmentListFetching) {
      return (
        <PlaceHolderContainer>
          <PulseLoader color={colorSet.blue4} />
        </PlaceHolderContainer>
      );
    }

    if (isImminentShipmentListError) {
      return (
        <PlaceHolderContainer>
          <Icon iconSrc={ShipmentSvg} iconSize={56} />
          <Typo typoType="b7m" color="gray8">
            {t("common:noShipment")}
          </Typo>
        </PlaceHolderContainer>
      );
    }

    return (
      <ImminentShipmentContainer>
        {[...imminentShipmentList]
          .sort(
            (a, b) => new Date(a.etaAt).getTime() - new Date(b.etaAt).getTime() // 임박 순서 sorting
          )
          .map((shipmentData) => {
            return (
              <StyledImminentCard
                key={shipmentData.id}
                shipmentData={shipmentData}
                onClick={() =>
                  navigate(
                    `${IMPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${shipmentData.bookingId}`
                  )
                }
              />
            );
          })}
      </ImminentShipmentContainer>
    );
  };

  useEffect(() => {
    if (tab === "b/l" || tab === "booking") {
      onMountSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (gridRef.current) {
      if (isShipmentByBlFetching || isShipmentByBookingFetching) {
        gridRef.current.api.showLoadingOverlay();
      }
    }
  }, [isShipmentByBlFetching, isShipmentByBookingFetching]);

  return (
    <ImporterMainLayout
      pageTitle={t("sideNav:importStatus")}
      breadcrumb={[t("sideNav:importStatus")]}
      sideAccessory={
        <SideContentSection>
          <FilterSection data-open={isSideContentOpen}>
            <SearchInputContainer>
              <Input
                value={sellerFilterValue}
                onChange={({ target }) => setSellerFilterValue(target.value)}
                placeholder={t(
                  "shipment:importer.list.placeholder.enterSellerCode"
                )}
                onClear={() => setSellerFilterValue("")}
                suffix={<Icon iconSrc={SearchIcon} iconSize={16} />}
              />
            </SearchInputContainer>

            <SearchInputContainer>
              <Typo typoType="h6" color="gray5">
                {t("shipment:importer.list.label.seller")}{" "}
              </Typo>
              <Typo typoType="h6" color="blue4">
                {filterData.buyerIdList?.length || 0}
              </Typo>
              <Typo typoType="h6" color="gray7">
                /{buyerCurrentData.count}
              </Typo>
            </SearchInputContainer>

            <AllSellerContainer>
              <Checkbox
                id="client-all"
                className="flex-shrink-0"
                checked={
                  filterData.buyerIdList?.length === buyerCurrentData.count &&
                  filterData.buyerIdList?.length !== 0
                }
                onChange={() => {
                  if (
                    filterData.buyerIdList?.length === buyerCurrentData.count &&
                    filterData.buyerIdList?.length !== 0
                  ) {
                    onForceSearch("buyerIdList", []);
                  } else {
                    onForceSearch(
                      "buyerIdList",
                      buyerCurrentData.data.map(
                        (client) => client.buyerListQueryResultDto.id
                      )
                    );
                  }
                }}
              />
              <SelectAll htmlFor="client-all">
                {t("shipment:importer.list.label.selectAll")}
              </SelectAll>
            </AllSellerContainer>

            <List>
              {buyerCurrentData?.data
                .filter((c) =>
                  c.buyerName.toLowerCase().includes(sellerFilterValue)
                )
                .map((client) => {
                  return (
                    <ClientListItem key={client.id}>
                      <Checkbox
                        className="flex-shrink-0"
                        id={client.id.toString()}
                        checked={filterData.buyerIdList?.includes(
                          client.buyerListQueryResultDto.id
                        )}
                        onChange={() => {
                          handleClientItemClick(client);
                        }}
                      />
                      <ClientItemLabel htmlFor={client.id.toString()}>
                        {client.buyerNameCode}
                      </ClientItemLabel>
                    </ClientListItem>
                  );
                })}
            </List>
          </FilterSection>

          <ToggleButtonContainer data-open={isSideContentOpen}>
            <ToggleButton
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              isIconOnly
              onClick={() => setIsSideContentOpen((prev) => !prev)}
            >
              <Icon
                className="double-left-icon"
                iconSrc={DoubleLeftSvg}
                iconSize={20}
              />
            </ToggleButton>
          </ToggleButtonContainer>
        </SideContentSection>
      }
    >
      <FlexColumn>
        <SelectSellerCard>
          <Typo typoType="b8m" color="gray3">
            {t("shipment:importer.list.label.selectSeller")}
          </Typo>
          <TagList>
            {filterData.buyerIdList?.length === 0 ||
            filterData.buyerIdList?.length === buyerCurrentData.count ? (
              <Tag label={t("common:all")} />
            ) : (
              <>
                {filterData.buyerIdList
                  ?.map((filterClientId: any) => {
                    return {
                      clientName:
                        buyerCurrentData.data.find(
                          (client) =>
                            client.buyerListQueryResultDto.id === filterClientId
                        )?.buyerName || "",
                      clientId: filterClientId,
                    };
                  })
                  .map((client: any) => {
                    return (
                      <Tag
                        label={client.clientName}
                        onRemove={() => {
                          onForceSearch(
                            "buyerIdList",
                            filterData.buyerIdList?.filter(
                              (clientId: any) => clientId !== client.clientId
                            )
                          );
                        }}
                      />
                    );
                  })}
              </>
            )}
          </TagList>
        </SelectSellerCard>

        <SectionCard
          cardTitle={t("shipment:importer.list.label.impendingShipmentDetails")}
        >
          {renderImminentShipmentSectionCard()}
        </SectionCard>

        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "shipmentStatusForImporter",
              label: t("shipment:importer.list.filter.shipmentStatus"),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:importer.list.placeholder.all"),
                allowClear:
                  filterData.shipmentStatusForImporter === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("shipmentStatusForImporter", "all");
                    onForceSearch("shipmentStatusForImporter", "all");
                  } else {
                    onFilterDataChange("shipmentStatusForImporter", value);
                    onForceSearch("shipmentStatusForImporter", value);
                  }
                },
              },
            },
            {
              key: "bookingBlNo",
              label: t("shipment:importer.list.filter.bookingBlNo"),
              type: "input",
              props: {
                placeholder: t("shipment:importer.list.placeholder.blNo"),
              },
            },
            {
              key: "warehouseId",
              label: t("shipment:importer.list.filter.warehouse"),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:importer.list.placeholder.all"),
                allowClear: filterData.warehouseId === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("warehouseId", "all");
                    onForceSearch("warehouseId", "all");
                  } else {
                    onFilterDataChange("warehouseId", value);
                    onForceSearch("warehouseId", value);
                  }
                },
              },
            },
            {
              key: "poNo",
              label: t("shipment:importer.list.filter.poNo"),
              type: "input",
              props: {
                placeholder: t("shipment:importer.list.placeholder.poNo"),
              },
            },
            {
              key: "itemCode",
              label: t("shipment:importer.list.filter.itemCode"),
              type: "input",
              props: {
                placeholder: t("shipment:importer.list.placeholder.itemCode"),
              },
            },
            {
              key: "mainCategoryCodeItemName",
              label: t("shipment:importer.list.filter.mainCategory"),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:importer.list.placeholder.all"),
                allowClear:
                  filterData.mainCategoryCodeItemName === "all" ? false : true,
              },
            },
            {
              key: "placeOfDelivery",
              label: t("shipment:importer.list.filter.placeOfDelivery"),
              type: "searchSelect",
              props: {
                placeholder: t("shipment:importer.list.placeholder.all"),
                allowClear: filterData.placeOfDelivery === "all" ? false : true,
              },
            },
            {
              key: "eta",
              label: t("shipment:importer.list.filter.eta"),
              type: "dateRangePicker",
              props: {
                value:
                  filterData.etaAtFrom || filterData.etaAtTo
                    ? [dayjs(filterData.etaAtFrom), dayjs(filterData.etaAtTo)]
                    : undefined,
                onChange: (value) => {
                  if (value) {
                    onMultipleItemChangeForceSearch([
                      { etaAtTo: value[0]?.toISOString() },
                      { etaAtFrom: value[1]?.toISOString() },
                    ]);
                  } else {
                    onMultipleItemChangeForceSearch([
                      { etaAtTo: undefined },
                      { etaAtFrom: undefined },
                    ]);
                  }
                },
              },
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "shipmentStatusForImporter",
              option: [
                allOptionItem,
                {
                  label: t("shipment:importer.list.badge.bookingConfirmed"),
                  value: "BOOKING_CONFIRMED",
                },
                {
                  label: t("shipment:importer.list.badge.beforeShipment"),
                  value: "BEFORE_SHIPMENT",
                },
                {
                  label: t("shipment:importer.list.badge.shipping"),
                  value: "SHIPPING",
                },
                {
                  label: t("shipment:importer.list.badge.arrived"),
                  value: "ARRIVED",
                },
                {
                  label: t("shipment:importer.list.badge.bookingCancel"),
                  value: "BOOKING_CANCEL",
                },
              ],
            },
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
            {
              key: "placeOfDelivery",
              option: [allOptionItem, ...placeOfDelivery],
            },
            {
              key: "warehouseId",
              option: [allOptionItem, ...warehouseOptionList],
            },
          ]}
        />

        <div>
          <Typo color="gray4" typoType="b5m">
            {t("shipment:importer.list.table.title")}
          </Typo>

          <TableTitleContainer>
            <Tabs role="tablist">
              {TAB_LIST.map(({ value }, idx) => {
                const isSelected = tab === value;
                return (
                  <StyledTabItem
                    key={idx.toString()}
                    tabIndex={isSelected ? 0 : -1}
                    tabValue={value}
                    onClick={() => {
                      onResetPaginationAndQuery();
                      setTab(value);
                    }}
                    data-selected={isSelected}
                    onFocusItem={(value) => {
                      onResetPaginationAndQuery();
                      setTab(value);
                    }}
                  >
                    <TapItemTypoContainer>
                      <Typo
                        typoType="b7m"
                        color={isSelected ? "gray2" : "gray6"}
                      >
                        {t(renderTabItemTitle(value))}
                      </Typo>
                    </TapItemTypoContainer>
                  </StyledTabItem>
                );
              })}
            </Tabs>

            <ButtonContainer>
              <StyledButton
                buttonSize={40}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleExcelDownload}
              >
                <Icon iconSrc={ExcelSvg} iconSize={16} />
                {t("shipment:importer.list.table.excel")}
              </StyledButton>
            </ButtonContainer>
          </TableTitleContainer>

          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={
                tab === "b/l"
                  ? isShipmentByBlListError
                    ? []
                    : shipmentByBlList
                  : isShipmentByBookingError
                  ? []
                  : shipmentByBookingList
              }
              isPaginationDataMaping
              columnDefs={col}
              handlePaginationClick={(page, pageSize) => {
                onPaginationChange({ page, pageSize });
              }}
              totalPage={tab === "b/l" ? count : shipmentByBookingCount}
              onRowClicked={({ data }) => {
                navigate(
                  `${IMPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${data.bookingId}`
                );
              }}
              onColumnMoved={handleColumnMoved}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onSortChange={(sortSource, isClickedHeader) =>
                !!isClickedHeader && onSortChange(sortSource as any)
              }
              onGridReady={() => setIsReady(true)}
              paginationRightAccessory={
                <StyledButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleColumnReset}
                  style={{ marginLeft: "8px" }}
                >
                  <Icon iconSrc={ResetSvg} iconSize={16} />
                  <Typo typoType="btn4m">{t("common:columnReset")}</Typo>
                </StyledButton>
              }
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>
    </ImporterMainLayout>
  );
};

export default ImporterShipmentPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  border-bottom: 1px solid ${colorSet.gray9};
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;
const SideContentSection = styled.section`
  display: flex;
  background: ${colorSet.white};
`;

const FilterSection = styled.section`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colorSet.gray9};
  white-space: nowrap;
  transition: all 0.5s;

  &[data-open="true"] {
    width: 220px;
  }

  &[data-open="false"] {
    width: 0px;
    overflow: hidden;
    border: none;
    transform: translateX(-100%) translateZ(-100);
  }
`;

const ToggleButtonContainer = styled.div`
  padding: 12px 4px 12px 16px;

  &[data-open="false"] {
    .double-left-icon {
      transform: rotateY(180deg);
    }
  }
`;

const ToggleButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 6px;
  z-index: 1;
`;

const SearchInputContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const List = styled.ul`
  overflow: auto;
  flex: 1;
  ${StyledScroll};
`;

const ClientListItem = styled.li`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const ClientItemLabel = styled.label`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  ${typo.b7r}
`;

const SelectAll = styled(ClientItemLabel)`
  color: ${colorSet.gray4};
`;

const AllSellerContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};
  display: flex;
  align-items: center;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const SelectSellerCard = styled(SectionCardWithoutHeader)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 2px 4px 0px #0000000d;
`;

const Tabs = styled.div`
  display: flex;
  gap: 24px;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  background: none;
  border: none;

  &[data-selected="true"] {
    padding: 10px 0 8px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;
const TapItemTypoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const PlaceHolderContainer = styled.div`
  width: 100%;
  height: 195px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImminentShipmentContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  gap: 16px;
  width: 100%;
`;

const StyledImminentCard = styled(ImminentShipmentCard)`
  flex-shrink: 0;

  &:hover {
    box-shadow: 0px 4px 15px 0px #00000033;
  }
`;
