import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import { ReactComponent as CloseSvg } from "@/src/assets/icons/icon-close-red.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import { Button } from "@/src/components/atom/Button";
import {
  subscriptionApi,
  useUpdateSubscriptionMutation,
} from "@/src/store/apis/subscription";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import AlertDialog from "@/src/components/atom/AlertDialog";
import useAlert from "@/src/hooks/useAlert";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { isNull, isUndefined } from "@/src/utils/is";
import ChangePlanInfoDialog from "./dialog/ChangePlanInfoDialog";
import { localstorageKeySet } from "@/src/constant/localstorage";
import { authApi } from "@/src/store/apis/auth";
import { FreeCodeDto } from "@/src/store/apis/subscription/interface";
import { useTranslation } from "react-i18next";

type CalloutStatusType =
  | "PAYMENT_DUE_IN_ONE_WEEK_MONTHLY_TYPE"
  | "PAYMENT_DUE_IN_ONE_WEEK_ANNUALLY_TYPE"
  | "FREE_PLAN"
  | "PAYMENT_FAIL"
  | "NONE_SUBSCRIBE"
  | "PLAN_CHANGE"
  | "PLAN_TERMINATION"
  | "RETRACT_FREE_CODE"
  | null;

enum DialogState {
  NULL,
  PLAN_TERMINATION,
  PLAN_CHANGE,
}

interface SubscriptionStatusCalloutProps {
  subscriptionIds: number[];
  onSubscriptionIdsChange: Dispatch<SetStateAction<number[]>>;
  freeCodeIds?: number[];
  onFreeCodeIdsChange?: Dispatch<SetStateAction<number[]>>;
  type?: CalloutStatusType;
}

const emptyFreeCodeArray: FreeCodeDto[] = [];

function SubscriptionStatusCallout({
  subscriptionIds,
  onSubscriptionIdsChange,
  freeCodeIds = [],
  onFreeCodeIdsChange,
  type,
}: SubscriptionStatusCalloutProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();

  const [calloutStatus, setCalloutStatus] = useState<CalloutStatusType>(null);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  // API
  const session = authApi.endpoints.getSession.useQueryState(undefined);
  const [updateSubscription] = useUpdateSubscriptionMutation();

  const { subscriptionId } =
    subscriptionApi.endpoints.getSubscriptions.useQueryState(
      { page: 1, pageSize: 10 },
      {
        selectFromResult: ({ currentData }) => {
          return {
            subscriptionId: currentData?.rows[0].id,
          };
        },
      }
    );

  const {
    subscriptionStatus,
    subscriptionEndAtTo,
    nextPlan,
    currentPlanName,
    paymentCycle,
    subscriptionType,
    retractFreeCodeReason,
    freeCodeList,
    isUnstable,
    isFetching,
  } = subscriptionApi.endpoints.getSubscription.useQueryState(
    { id: subscriptionId || 0 },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          subscriptionStatus: currentData?.subscriptionStatus,
          subscriptionType: currentData?.subscriptionType,
          subscriptionEndAtTo: currentData?.subscriptionEndAtTo,
          currentPlanName: currentData?.plan.name,
          nextPlan: currentData?.nextPlan,
          paymentCycle: currentData?.paymentCycle,
          retractFreeCodeReason: currentData?.retractFreeCodeReason || "",
          freeCodeList:
            isStable && currentData?.freeCodeList
              ? currentData?.freeCodeList
              : emptyFreeCodeArray,
          isUnstable,
          currentData,
          isFetching,
        };
      },
    }
  );

  const freeCodeCopyList = [...freeCodeList].reverse();
  const isManagerType =
    session.currentData?.row.exporterUserType === "MANAGER" ||
    session.currentData?.row.exporterUserType === "MIDDLE_MANAGER";

  const endDate = dayjs(
    dayjs(subscriptionEndAtTo).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
  );
  const duration = endDate.diff(dayjs(), "days");
  const isUnstableNextPlan = isNull(nextPlan);
  const isNextPlan = !isUnstableNextPlan;
  const isSubscriptionIds = subscriptionIds.includes(subscriptionId as number);
  const isFreeCodeIds = freeCodeIds.includes(freeCodeCopyList[0]?.id);

  const handleResubscribeButtonClick = () => {
    navigate(`${EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP}?isInquiry=true`);
  };

  const handleButtonClick = (buttonType: CalloutStatusType) => {
    if (buttonType === "PLAN_TERMINATION") {
      setDialogState(DialogState.PLAN_TERMINATION);
    }
    if (buttonType === "PLAN_CHANGE") {
      setDialogState(DialogState.PLAN_CHANGE);
    }
  };

  const handleCalloutCloseClick = () => {
    if (!subscriptionId) {
      return;
    }

    const saveSubscriptionIdsData = Array.from(
      new Set([...subscriptionIds, subscriptionId])
    );
    const saveFreeCodeIdsData = Array.from(
      new Set(
        freeCodeCopyList?.[0].id
          ? [...freeCodeIds, freeCodeCopyList?.[0].id]
          : [...freeCodeIds]
      )
    );

    window.localStorage.setItem(
      localstorageKeySet.SUBSCRIPTION_IDS,
      JSON.stringify(saveSubscriptionIdsData)
    );

    window.localStorage.setItem(
      localstorageKeySet.FREE_CODE_IDS,
      JSON.stringify(saveFreeCodeIdsData)
    );

    onSubscriptionIdsChange(saveSubscriptionIdsData);
    onFreeCodeIdsChange?.(saveFreeCodeIdsData);
    setCalloutStatus(null);
  };

  const handlePlanTerminationCancelClick = async () => {
    if (!subscriptionId) {
      return;
    }

    try {
      await updateSubscription({
        id: subscriptionId,
        subscriptionStatus: "PROGRESS",
      }).unwrap();

      setDialogState(DialogState.NULL);

      alert.showAlert({
        type: "success",
        message: t(
          "subscriptionManagement:subscription.alert.subscriptionCancellation"
        ),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message,
      });
    }
  };

  const renderAlertDialog = () => {
    if (dialogState === DialogState.PLAN_TERMINATION) {
      return (
        <AlertDialog
          title={t("subscriptionManagement:subscription.cancelPlanChange")}
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          okText={t("subscriptionManagement:subscription.button.ok")}
          cancelText={t("subscriptionManagement:subscription.button.cancel")}
          onOk={handlePlanTerminationCancelClick}
        >
          {t(
            "subscriptionManagement:subscription.areYouSureYouWantToCancelThePlanTermination"
          )}
        </AlertDialog>
      );
    }

    if (dialogState === DialogState.PLAN_CHANGE) {
      return (
        <ChangePlanInfoDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    return <></>;
  };

  const renderCallout = () => {
    const year = dayjs(subscriptionEndAtTo).get("year");
    const month = addZeroPrefix(dayjs(subscriptionEndAtTo).get("month") + 1);
    const date = addZeroPrefix(dayjs(subscriptionEndAtTo).get("date"));

    if (isFetching) {
      return null;
    }

    if (isUnstable) {
      return (
        <StyledCallOut borderColor="blue9" backgroundColor="blue10">
          <InfoIcon color="blue4" />
          <Flex alignItems="center" justifyContent="space-between">
            <Typo color="indigo" typoType="b9r">
              {t(
                "subscriptionManagement:subscription.thereIsNoCurrentSubscriptionPlan"
              )}
            </Typo>
            <Button
              buttonSize={32}
              onClick={handleResubscribeButtonClick}
              disabled={isManagerType}
            >
              {t("subscriptionManagement:subscription.button.planSubscription")}
            </Button>
          </Flex>
        </StyledCallOut>
      );
    }

    switch (calloutStatus) {
      // 월간 구독시
      case "PAYMENT_DUE_IN_ONE_WEEK_MONTHLY_TYPE":
        return (
          <StyledCallOut borderColor="blue9" backgroundColor="blue10">
            <InfoIcon color="blue4" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="indigo" typoType="b9r">
                {t("common:paymentScheduledCheck", { day: duration })}
              </Typo>
              <CloseIcon color="blue4" onClick={handleCalloutCloseClick} />
            </Flex>
          </StyledCallOut>
        );

      // 연간 구독시
      case "PAYMENT_DUE_IN_ONE_WEEK_ANNUALLY_TYPE":
        return (
          <StyledCallOut borderColor="blue9" backgroundColor="blue10">
            <InfoIcon color="blue4" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="indigo" typoType="b9r">
                {t("common:paymentIsScheduledInOneWeek", {
                  year: year.toString(),
                  month: month.toString(),
                  date: date.toString(),
                })}
              </Typo>
              <CloseIcon color="blue4" onClick={handleCalloutCloseClick} />
            </Flex>
          </StyledCallOut>
        );

      // 무료이용기간 1주일 이내 남음
      case "FREE_PLAN":
        return (
          <StyledCallOut borderColor="blue9" backgroundColor="blue10">
            <InfoIcon color="blue4" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="indigo" typoType="b9r">
                {t("common:freeServiceExpiration", {
                  day: duration,
                })}
              </Typo>
              <CloseIcon color="blue4" onClick={handleCalloutCloseClick} />
            </Flex>
          </StyledCallOut>
        );

      // 결제 실패
      case "PAYMENT_FAIL":
        return (
          <StyledCallOut borderColor="red7" backgroundColor="red8">
            <InfoIcon color="red2" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="red2" typoType="b9r">
                {t(
                  "subscriptionManagement:subscription.paymentFailedPleaseCheckYourPaymentInformation"
                )}
              </Typo>
              <Button
                buttonSize={32}
                disabled={isManagerType}
                onClick={handleResubscribeButtonClick}
              >
                {t(
                  "subscriptionManagement:subscription.button.planSubscription"
                )}
              </Button>
            </Flex>
          </StyledCallOut>
        );

      // 즉시해제 (구독이 없는 상태)
      case "NONE_SUBSCRIBE":
        return (
          <StyledCallOut borderColor="blue9" backgroundColor="blue10">
            <InfoIcon color="blue4" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="indigo" typoType="b9r">
                {t(
                  "subscriptionManagement:subscription.thereIsNoCurrentSubscriptionPlan"
                )}
              </Typo>
              <Button
                buttonSize={32}
                onClick={handleResubscribeButtonClick}
                disabled={isManagerType}
              >
                {t(
                  "subscriptionManagement:subscription.button.planSubscription"
                )}
              </Button>
            </Flex>
          </StyledCallOut>
        );

      // 플랜이 변경 되었을때
      case "PLAN_CHANGE":
        const nextPlanName = nextPlan?.name ?? "-";

        return (
          <StyledCallOut borderColor="blue9" backgroundColor="blue10">
            <InfoIcon color="blue4" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="indigo" typoType="b9r">
                {t("common:subscriptionToChangePlan", {
                  year: year.toString(),
                  month: month.toString(),
                  date: date.toString(),
                  nextPlanName,
                })}
              </Typo>
              <Button
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={() => handleButtonClick("PLAN_CHANGE")}
                disabled={isManagerType}
              >
                {t("subscriptionManagement:subscription.changePlanInformation")}
              </Button>
            </Flex>
          </StyledCallOut>
        );

      // 해지예정
      case "PLAN_TERMINATION":
        return (
          <StyledCallOut borderColor="blue9" backgroundColor="blue10">
            <InfoIcon color="blue4" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="indigo" typoType="b9r">
                {t("common:subscriptionCanceledCancel", {
                  year: year.toString(),
                  month: month.toString(),
                  date: date.toString(),
                  currentPlanName: currentPlanName as string,
                })}
              </Typo>
              <Button
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={() => handleButtonClick("PLAN_TERMINATION")}
                disabled={isManagerType}
              >
                {t(
                  "subscriptionManagement:subscription.button.cancelPlanChange"
                )}
              </Button>
            </Flex>
          </StyledCallOut>
        );

      // 무료코드 회수
      case "RETRACT_FREE_CODE":
        return (
          <StyledCallOut borderColor="red7" backgroundColor="red8">
            <InfoIcon color="red2" />
            <Flex alignItems="center" justifyContent="space-between">
              <Typo color="red2" typoType="b9r">
                {t(
                  "subscriptionManagement:subscription.freePeriodCodeUsageStoppedDueTo",
                  { retractFreeCodeReason }
                )}
              </Typo>
              <CloseIcon color="red2" onClick={handleCalloutCloseClick} />
            </Flex>
          </StyledCallOut>
        );

      case null:
      default:
        return <></>;
    }
  };

  const getCalloutStatus = useCallback(() => {
    // 무료기간 코드 회수
    if (type === "RETRACT_FREE_CODE" && !isFreeCodeIds) {
      return setCalloutStatus("RETRACT_FREE_CODE");
    }

    if (!type && subscriptionStatus === "PAYMENT_FAIL") {
      // 결제 실패
      return setCalloutStatus("PAYMENT_FAIL");
    }

    if (!type && subscriptionStatus === "CANCELED") {
      if (duration > 0) {
        // 해지예정
        return setCalloutStatus("PLAN_TERMINATION");
      }
      // 즉시해제
      return setCalloutStatus("NONE_SUBSCRIBE");
    }

    // 플랜 변경
    if (!type && isNextPlan) {
      return setCalloutStatus("PLAN_CHANGE");
    }

    if (
      !type &&
      subscriptionStatus === "PROGRESS" &&
      !isSubscriptionIds &&
      duration <= 7
    ) {
      // 무료기간 7일전
      if (subscriptionType === "FREE") {
        return setCalloutStatus("FREE_PLAN");
      }
      // 결제 7일전 (연간)
      if (paymentCycle === "ANNUALLY") {
        return setCalloutStatus("PAYMENT_DUE_IN_ONE_WEEK_ANNUALLY_TYPE");
      }
      // 결제 7일전 (월간)
      if (paymentCycle === "MONTHLY") {
        return setCalloutStatus("PAYMENT_DUE_IN_ONE_WEEK_MONTHLY_TYPE");
      }
    }

    return setCalloutStatus(null);
  }, [
    type,
    isFreeCodeIds,
    subscriptionStatus,
    isNextPlan,
    isSubscriptionIds,
    duration,
    subscriptionType,
    paymentCycle,
  ]);

  useEffect(() => {
    setTimeout(() => {
      subscriptionId && getCalloutStatus();
    }, 0);
  }, [getCalloutStatus, subscriptionId]);

  return (
    <>
      {renderCallout()}
      {renderAlertDialog()}
    </>
  );
}

export default SubscriptionStatusCallout;

const StyledCallOut = styled(CallOut)`
  align-items: center;
`;

const Flex = styled.div<{
  direction?: CSSProperties["flexDirection"];
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
  gap?: number;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap || 0}px;
  width: 100%;
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  * {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const CloseIcon = styled(CloseSvg)<{ color: ColorType }>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;

  * {
    fill: ${({ color }) => colorSet[color]};
  }
`;
