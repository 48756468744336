import AgGridRadio from "@/src/components/atom/Radio/AgGridRadio";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
  cellClass: (params: any) => {
    const isDisabled = params.data.isDisable;
    return isDisabled ? "disabled-cell" : undefined;
  },
};

export const columnLoadBooking = (
  radioOnChange: (selectedId: number | null) => void,
  maxContainer?: number
): ColDef[] => [
  {
    headerName: "",
    field: "radioButton",
    width: 60,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <AgGridRadio
          checked={params.data.isChecked}
          onChange={() => radioOnChange(params.data.id)}
          disabled={
            maxContainer
              ? maxContainer > params?.data?.pureRemainingCount
              : false
          }
        />
      );
    },
    lockPosition: "left",
    pinned: "left",
    sortable: false,
    resizable: false,
  },
  {
    field: "bookingNo",
    width: 140,
    ...commonColumn,
  },
  {
    field: "etdAt",
    width: 140,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <>
          {dayjs(params.data.etdAt).format("YYYY-MM-DD")}{" "}
          <EtdCompareCount
            typoType="b9m"
            initDate={params.data.initialEtdAt}
            currentDate={params.data.etdAt}
            color={params.data.isDisable ? "gray7" : undefined}
          />
        </>
      );
    },
    flex: 1,
    ...commonColumn,
  },
  {
    field: "buyerNameCode",
    width: 160,
    ...commonColumn,
  },
  {
    field: "item",
    width: 110,
    ...commonColumn,
  },
  {
    field: "numberOfContainer",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    field: "remainingNumberOfContainer",
    width: 160,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
];
