import { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import Filter from "@/src/components/molecule/Filter";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import { ColDef } from "ag-grid-community";
import Table from "@/src/components/atom/Table";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import {
  GetWarehousesRequestParams,
  WarehouseDto,
} from "@/src/store/apis/warehouse/interface";
import { useLazyGetWarehousesQuery } from "@/src/store/apis/warehouse";
import { isUndefined } from "@/src/utils/is";
import { warehouseListColumn } from "./columns/column";
import { useTranslation } from "react-i18next";

interface GetWarehousesFilterRequest {
  warehouseName?: string;
  countryCodeItemName?: string;
  tel?: string;
  isActivated?: string;
  mainCategories?: string[];
}

const defaultValue = {
  warehouseName: undefined,
  countryCodeItemName: "all",
  tel: undefined,
  isActivated: "all",
  mainCategories: ["all"],
};

const FAST_SEARCH_QUERY_KEY = [
  "countryCodeItemName",
  "isActivated",
  "mainCategories",
];

const emptyArray: WarehouseDto[] = [];

const ImporterWarehouseManagementPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(warehouseListColumn);

  // API
  const { isFetching, data: countryCodeList } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "COUNTRY",
    });
  const { isFetching: isFetchingMainCategoryList, data: mainCategoryList } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "MAIN_CATEGORY",
    });
  const [getWarehouses, { isWarehouseFetching, count, warehouseList }] =
    useLazyGetWarehousesQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isWarehouseFetching: isFetching,
          count: isStable ? currentData.count : 0,
          warehouseList: isStable ? currentData.rows : emptyArray,
        };
      },
    });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<GetWarehousesRequestParams, GetWarehousesFilterRequest>({
    isReady,
    gridRef: gridRef.current,
    fetch: getWarehouses,
    defaultFilterData: defaultValue,
    onBeforeSearch: (data) => {
      let mainCategories;
      if (data.mainCategories && data.mainCategories.length > 0) {
        if (data.mainCategories.includes("all")) {
          if (data.mainCategories.length === 1) {
            mainCategories = undefined;
          } else {
            mainCategories = data.mainCategories.filter((val) => val !== "all");
          }
        } else {
          mainCategories = data.mainCategories;
        }
      } else {
        mainCategories = undefined;
      }

      return {
        warehouseName: data.warehouseName || undefined,
        countryCodeItemName:
          countryCodeList?.find(
            ({ codeItemNameKo }) => codeItemNameKo === data.countryCodeItemName
          )?.codeItemName || undefined,
        tel: data.tel || undefined,
        isActivated:
          data.isActivated === "true"
            ? true
            : data.isActivated === "false"
            ? false
            : undefined,
        mainCategories,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        ...urlObject,
        countryCodeItemName:
          countryCodeList?.find(
            ({ codeItemName }) => codeItemName === urlObject.countryCodeItemName
          )?.codeItemNameKo || "all",
        isActivated: urlObject.isActivated || "all",
        mainCategories: urlObject.mainCategories?.split(",") ?? ["all"],
      };
    },
    transformUrlToParams: (urlSet) => {
      const { mainCategories, ...rest } = urlSet;

      return {
        ...rest,
        mainCategories: mainCategories?.split(",") ?? undefined,
      };
    },
  });

  const allOptionItem = {
    label: t("warehouseManagement:placeholder.all"),
    value: "all",
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "warehouseName",
        langKey: "warehouseManagement:common.warehouseName",
      },
      {
        columnKey: "tel",
        langKey: "warehouseManagement:common.contact",
      },
      {
        columnKey: "countryCodeItemName",
        langKey: "warehouseManagement:common.country",
      },
      { columnKey: "isActivated", langKey: "warehouseManagement:common.use" },
    ],
  });

  const countryCodeListToSelectOption = countryCodeList?.reduce<
    { label: string; value: string }[]
  >(
    (acc, val) => {
      return [
        ...acc,
        {
          label: `${val.codeItemName}_${val.codeItemNameEn}`,
          value: val.codeItemNameKo,
        },
      ];
    },
    [allOptionItem]
  );

  const mainCategoryListToSelectOption = useMemo(() => {
    return mainCategoryList?.map((value) => ({
      label:
        i18n.language === "ko" ? value.codeItemNameKo : value.codeItemNameEn,
      value: value.codeItemName,
    }));
  }, [i18n.language, mainCategoryList]);

  const goToDetail = (warehouseId: number) => {
    navigate(
      `${IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_DETAIL}/${warehouseId}`
    );
  };

  // Loading
  useEffect(() => {
    if (isWarehouseFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isWarehouseFetching]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:management"), t("sideNav:warehouseManagement")]}
      pageTitle={t("sideNav:warehouseManagement")}
    >
      <Filter
        filterKeyList={[
          {
            key: "warehouseName",
            label: t("warehouseManagement:common.warehouseName"),
            type: "input",
            props: {
              placeholder: t("warehouseManagement:placeholder.warehouseName"),
            },
          },
          {
            key: "countryCodeItemName",
            label: t("warehouseManagement:common.country"),
            type: "searchSelect",
            props: {
              placeholder: t("warehouseManagement:placeholder.all"),
              suffixIcon: isFetching ? <Loader /> : undefined,
              disabled: isFetching,
            },
          },
          {
            key: "tel",
            label: t("warehouseManagement:common.contact"),
            type: "input",
            props: {
              placeholder: t("warehouseManagement:placeholder.contact"),
            },
          },
          {
            key: "isActivated",
            label: t("warehouseManagement:common.use"),
            type: "searchSelect",
            props: {
              placeholder: t("warehouseManagement:placeholder.all"),
            },
          },
          {
            key: "mainCategories",
            label: t("warehouseManagement:common.mainCategory"),
            type: "comboBoxSelect",
            props: {
              placeholder: t("warehouseManagement:placeholder.all"),
              disabled: isFetchingMainCategoryList,
              suffixIcon: isFetching ? <Loader /> : undefined,
              searchInputPlaceholder: t("placeholder:searchMainCategories"),
            },
          },
        ]}
        filterData={{
          mainCategories: filterData.mainCategories,
          warehouseName: filterData.warehouseName,
          countryCodeItemName: filterData.countryCodeItemName,
          isActivated: filterData.isActivated,
          tel: filterData.tel,
        }}
        optionList={[
          {
            key: "countryCodeItemName",
            option: countryCodeListToSelectOption ?? [],
          },
          {
            key: "isActivated",
            option: [
              allOptionItem,
              { value: "true", label: "Y" },
              { value: "false", label: "N" },
            ],
          },
          {
            key: "mainCategories",
            option: [allOptionItem, ...(mainCategoryListToSelectOption ?? [])],
          },
        ]}
        onSubmit={onSearch}
        onReset={onResetFilter}
        onChange={(key, value) => {
          onFilterDataChange(key, value);

          if (FAST_SEARCH_QUERY_KEY.includes(key)) {
            onForceSearch(key, value);
          }
        }}
      />

      <MiddleSection>
        <Typo typoType="b5m" color="gray4">
          {t("warehouseManagement:warehouseList.warehouseList")}
        </Typo>

        <StyledButton
          onClick={() => {
            navigate(IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_ADD);
          }}
        >
          <Icon iconSrc={AddSvg} iconSize={16} />
          <Typo typoType="btn3m" color="white">
            {t("warehouseManagement:warehouseList.warehouseRegistration")}
          </Typo>
        </StyledButton>
      </MiddleSection>

      <SectionCardWithoutHeader>
        <Table
          ref={gridRef}
          rowData={warehouseList}
          columnDefs={columnDefs}
          onRowClicked={({ data }) => goToDetail(data.id)}
          isPaginationDataMaping
          totalPage={count}
          page={pagination.page}
          pageSize={pagination.pageSize}
          onSortChange={(sortSource, isClickedHeader) =>
            !!isClickedHeader && onSortChange(sortSource as any)
          }
          handlePaginationClick={(page, pageSize) =>
            onPaginationChange({ page, pageSize })
          }
          onGridReady={() => setIsReady(true)}
        />
      </SectionCardWithoutHeader>
    </ImporterMainLayout>
  );
};

export default ImporterWarehouseManagementPage;

const MiddleSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
