import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import Flex from "@/src/components/molecule/Flex";
import { useAppDispatch, useAppSelector } from "@/src/store";
import {
  ModifySubscriptionDto,
  PaymentCurrencyUnitType,
  PaymentCycleType,
  PaymentDto,
  PlanDto,
  PlanVersionDto,
  SubscriptionStatusType,
  SubscriptionType,
} from "@/src/store/apis/subscription/interface";
import { closeEnterprisePlanDialog } from "@/src/store/slice/subscription";
import styled from "styled-components";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import {
  subscriptionApi,
  useCreateSubscriptionsMutation,
  useGetCancelFeeQuery,
  useGetPaymentMethodsQuery,
  useGetSubscriptionQuery,
  useGetSubscriptionsQuery,
  useLazyGetCurrentSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from "@/src/store/apis/subscription";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import colorSet from "@/src/styles/color";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IconCheck from "@/src/assets/icons/icon-check-shape.svg";
import Icon from "@/src/components/atom/Icon";
import { useState } from "react";
import Checkbox from "@/src/components/atom/Checkbox";
import { getAmountOfPayment } from "../../../SubscriptionManagement/utils/getPayments";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { useForm } from "react-hook-form";
import useAlert from "@/src/hooks/useAlert";
import FormItem from "@/src/components/molecule/FormItem";
import { useTranslation } from "react-i18next";

const emptyPlanArray: PlanDto[] = [];
const emptyPayments: PaymentDto[] = [];

function EnterprisePlanChangeConfirm() {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const open = useAppSelector(
    (state) => state.subscription.isEnterpriseInquiries
  );

  const [isConfirm, setIsConfirm] = useState(false);

  // API
  const [getCurrentSubscription] = useLazyGetCurrentSubscriptionQuery();
  const [createSubscriptions, { isLoading: isCreateSubscriptionLoading }] =
    useCreateSubscriptionsMutation();
  const [updateSubscription, { isLoading: isUpdateSubscriptionLoading }] =
    useUpdateSubscriptionMutation();
  const { payments } = useGetPaymentMethodsQuery(
    {
      page: 1,
      pageSize: 2,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnStable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnStable;

        return {
          payments: isStable ? currentData.rows : [],
        };
      },
    }
  );
  const { plans } = subscriptionApi.endpoints.getPlans.useQueryState(
    {},
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          plans: isStable ? currentData.rows : emptyPlanArray,
        };
      },
    }
  );

  // 구독 목록 조회
  const { subscriptionId } = useGetSubscriptionsQuery(
    {
      page: 1,
      pageSize: 10,
    },
    {
      selectFromResult: ({ currentData }) => {
        return {
          subscriptionId: currentData?.rows?.[0].id,
        };
      },
    }
  );

  // 구독 단일 항목조회
  const { planName, subscriptionType, paymentList } = useGetSubscriptionQuery(
    {
      id: subscriptionId as number,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          subscriptionType: currentData?.subscriptionType,
          planName: isStable ? currentData?.plan.name : "-",
          paymentList: isStable ? currentData.paymentList : emptyPayments,
        };
      },
    }
  );

  const { usedDays } = useGetCancelFeeQuery(
    {
      id: subscriptionId as number,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFetching,
          usedDays: isStable ? currentData?.row.usedDays : 0,
        };
      },
    }
  );

  const isCardCancelType = usedDays < 180;
  const paymentMethod = payments.filter((item) => item.isRepresentative)[0];

  const enterprisePlan = plans.filter(({ planType, planVersionList }) => {
    const releaseAt = dayjs(
      dayjs(planVersionList?.[0].releasedAt).format(
        DATE_FORMAT_STRINGS.YYYY_MM_DD
      )
    );
    const duration = releaseAt.diff(dayjs(), "days");
    // 엔터프라이즈, 오늘날짜 기준 반영일자가 포함되는 날
    return planType === "ENTERPRISE" && duration >= 0;
  })[0];

  const planVersion = enterprisePlan.planVersionList?.[0] as PlanVersionDto;
  const {
    currencyUnit,
    monthlyFee,
    numberOfLoadingCode,
    numberOfMember,
    annualFee,
    paymentCycle,
  } = planVersion;

  const payment = paymentList?.find(
    (item) => item.paymentStatus === "PAYMENT_SUCCESS"
  );

  const price = Math.floor(
    getAmountOfPayment({
      exchangeRate: Number(payment?.exchangeRate),
      price: Number(payment?.price),
      currencyUnit: payment?.currencyUnit as PaymentCurrencyUnitType,
    })
  );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    refundAccountName: string;
    refundBankName: string;
    refundAccountNo: string;
    estimatedRefundDateAt?: string;
    refundBankAddress?: string;
    swiftCode?: string;
    memo?: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      refundAccountName: "",
      refundBankName: "",
      refundAccountNo: "",
      estimatedRefundDateAt: undefined,
      refundBankAddress: undefined,
      swiftCode: undefined,
      memo: undefined,
    },
  });

  const getConfirmButtonDisabled = () => {
    if (isCardCancelType) {
      return !isConfirm;
    }

    return !isConfirm && !!errors;
  };

  const goToEnterprisePlanInquiryPage = () => {
    return navigate(
      `${EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP}?isInquiry=true`
    );
  };

  const handleSubscribeChangeConfirmClick = async () => {
    if (!subscriptionId) {
      return;
    }

    /**
     * @description 구독 즉시 해지
     * - 카드취소 (180일 이전)
     * - 국내계좌 (180일 이후)
     * - 해외계좌 (180일 이후)
     */
    const immediateData = isCardCancelType
      ? {
          // 카드취소
          subscriptionEndAtTo: dayjs().toISOString(),
          isImmediateCancel: true,
          subscriptionStatus: "CANCELED",
        }
      : {
          // 계좌정보 (국내, 해외)
          subscriptionEndAtTo: dayjs().toISOString(),
          isImmediateCancel: true,
          subscriptionStatus: "CANCELED",
          refundAccountType:
            payment?.currencyUnit === "KRW" ? "KOREA" : "OTHER",
          refundAccountName: getValues("refundAccountName") || undefined,
          refundBankName: getValues("refundBankName") || undefined,
          refundAccountNo: getValues("refundAccountNo") || undefined,
          estimatedRefundDateAt:
            getValues("estimatedRefundDateAt") || undefined,
          refundBankAddress: getValues("refundBankAddress") || undefined,
          swiftCode: getValues("swiftCode") || undefined,
          memo: getValues("memo") || undefined,
        };

    try {
      // 즉시 해지
      const res = await updateSubscription({
        id: subscriptionId,
        ...(immediateData as ModifySubscriptionDto),
      }).unwrap();

      // 구독 등록
      if (res && enterprisePlan && user) {
        const { planVersionList, id: enterprisePlanId } = enterprisePlan;

        await createSubscriptions({
          subscriptionStatus: "PROGRESS" as SubscriptionStatusType,
          subscriptionType: "STANDARD" as SubscriptionType,
          paymentCycle: planVersionList?.[0].paymentCycle as PaymentCycleType,
          exporterId: user.exporter.id,
          planId: enterprisePlanId,
          planVersionId: planVersionList?.[0].id as number,
          paymentMethodId: paymentMethod.id,
        }).unwrap();

        await getCurrentSubscription();
        dispatch(closeEnterprisePlanDialog());

        alert.showAlert({
          type: "success",
          message: t("alert:saveSuccess"),
        });
      }
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message,
      });
    }
  };

  const renderCard = () => {
    if (!enterprisePlan) {
      return null;
    }

    const releaseAt = dayjs(planVersion.releasedAt).format(
      DATE_FORMAT_STRINGS.YYYY_MM_DD
    );

    const paymentPrice = paymentCycle === "MONTHLY" ? monthlyFee : annualFee;
    const paymentCycleText =
      paymentCycle === "MONTHLY" ? t("common:month") : t("common:year");

    return (
      <Card>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="column" gap={8}>
            <Flex alignItems="center" gap={4}>
              <Typo typoType="b7m">{enterprisePlan.name}</Typo>
            </Flex>

            <Typo typoType="d6">
              {paymentCycleText} {Number(paymentPrice)?.toLocaleString("ko-KR")}{" "}
              {currencyUnit}
            </Typo>
          </Flex>

          <StyledButton
            buttonColor="blue"
            buttonGrade="secondary"
            onClick={goToEnterprisePlanInquiryPage}
          >
            {t("common:contactUs")}
          </StyledButton>
        </Flex>
        <Divider />
        <ul className="gap-8">
          <li>
            <div>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">
                {t("common:activeAccounts")} {numberOfMember}
                {t("common:people")}
              </Typo>
            </div>
          </li>
          <li>
            <div>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">
                {t("common:loadingCode")} {numberOfLoadingCode}
                {t("common:timesTransmissionPossible")}
              </Typo>
            </div>
            <div>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">
                {t("common:planRegistrationExpiry")}: {releaseAt}
              </Typo>
            </div>
          </li>
        </ul>
      </Card>
    );
  };

  const renderCancelFee = () => {
    return (
      <CallOut>
        <Flex flexDirection="column" gap={16}>
          <SectionCardRow
            label={t("common:currentPlanInformation")}
            value={planName}
          />
          <SectionCardRow
            label={t("common:expectedRefundAmount")}
            value={
              subscriptionType === "FREE" ? (
                "-"
              ) : (
                <Flex flexDirection="column">
                  <Typo typoType="b7r" color="gray5">
                    {payment?.currencyUnit}{" "}
                    {price.toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typo>
                  <Typo typoType="b11r" color="gray5">
                    ({t("common:penalty10Percent")})
                  </Typo>
                </Flex>
              )
            }
          />
        </Flex>
      </CallOut>
    );
  };

  const renderRefundAccountType = () => {
    if (isCardCancelType) {
      return null;
    }

    const isKoreaType = payment?.currencyUnit === "KRW";
    const isOtherType = payment?.currencyUnit === "USD";

    // 국내계좌 환불
    if (isKoreaType) {
      return (
        <Flex flexDirection="column" gap={16}>
          <FormItem
            label={t("subscriptionManagement:subscription.accountName")}
            name="refundAccountName"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.accountNamePlaceholder"
              ),
            }}
            rules={{
              required: isKoreaType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <Flex gap={16}>
            <StyledFormItem
              label={t("subscriptionManagement:subscription.bankName")}
              name="refundBankName"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.bankNamePlaceholder"
                ),
              }}
              rules={{
                required: isKoreaType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountNo")}
              name="refundAccountNo"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNoPlaceholder"
                ),
              }}
              rules={{
                required: isKoreaType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
          </Flex>
        </Flex>
      );
    }

    // 해외계좌 환불
    if (isOtherType) {
      return (
        <Flex flexDirection="column" gap={16}>
          <Flex gap={16}>
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountName")}
              name="refundAccountName"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNamePlaceholder"
                ),
              }}
              rules={{
                required: isOtherType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountNo")}
              name="refundAccountNo"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNoPlaceholder"
                ),
              }}
              rules={{
                required: isOtherType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
          </Flex>
          <FormItem
            label={t("subscriptionManagement:subscription.bankName")}
            name="refundBankName"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.bankNamePlaceholder"
              ),
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label={t("common:bankAddress")}
            name="refundBankAddress"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t("common:placeholder.bankAddress"),
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label={t("common:swiftCode")}
            name="swiftCode"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t("common:placeholder.swiftCode"),
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label={t("common:memo")}
            name="memo"
            type="textarea"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t("common:placeholder.memo"),
              className: "resize-none",
              maxLength: 140,
            }}
          />
        </Flex>
      );
    }
  };

  return (
    <Dialog
      title={t("common:changePlan")}
      open={open}
      onOpenChange={() => dispatch(closeEnterprisePlanDialog())}
      width={800}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => {
              dispatch(closeEnterprisePlanDialog());
            }}
          >
            {t("common:cancel")}
          </Button>
          <Button
            disabled={
              getConfirmButtonDisabled() ||
              isUpdateSubscriptionLoading ||
              isCreateSubscriptionLoading
            }
            isLoading={
              isUpdateSubscriptionLoading || isCreateSubscriptionLoading
            }
            onClick={() => {
              isCardCancelType
                ? handleSubscribeChangeConfirmClick()
                : handleSubmit(handleSubscribeChangeConfirmClick);
            }}
          >
            {t("common:immediateCancelChange")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Title typoType="d6">{t("common:salesApprovalCompleted")}</Title>
      <Flex flexDirection="column" gap={16}>
        <CallOut borderColor="blue9" backgroundColor="blue10">
          <Flex alignItems="center" gap={8}>
            <InfoIcon />
            <Typo typoType="b9r" color="indigo">
              {t("common:enterpriseUpgradeRequirement")}
            </Typo>
          </Flex>
        </CallOut>
        {renderCard()}
        {renderCancelFee()}
        {renderRefundAccountType()}

        <CheckboxContainer>
          <Checkbox
            checked={isConfirm}
            onChange={() => setIsConfirm((prev) => !prev)}
          />
          <Typo typoType="b7r">
            {t("common:subscriptionCancelRestrictions")}
          </Typo>
        </CheckboxContainer>
      </Flex>
    </Dialog>
  );
}

export default EnterprisePlanChangeConfirm;

const Title = styled(Typo)`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const InfoIcon = styled(InfoSvg)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  flex: 1;

  & > ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        display: flex;
        gap: 4px;
      }
    }
  }

  & > .gap-16 {
    gap: 16px;
  }

  & > .gap-8 {
    gap: 8px;
  }
`;

const StyledButton = styled(Button)`
  text-align: center;
  height: 100%;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const CheckboxContainer = styled.label`
  display: flex;
  gap: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    margin-top: 3px;
    flex-shrink: 0;
  }

  span {
    white-space: pre-wrap;
  }
`;

const StyledFormItem = styled(FormItem)`
  flex: 1;
`;
