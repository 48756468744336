import React from "react";
import { useAppSelector } from "@/src/store";
import { ReactComponent as NotFoundSvg } from "@/src/assets/icons/icon-not-found.svg";
import { styled } from "styled-components";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";

const SLASH = "/";
const EXPORT = "export";
const IMPORT = "import";

function isSame<T>(x: T, y: T): boolean {
  return x === y;
}

const NotFoundPage = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const path = useLocation();

  const isExport =
    path.pathname.split(SLASH)[1]?.toLocaleLowerCase() === EXPORT ||
    path.pathname.split(SLASH)[1]?.toLocaleLowerCase() !== IMPORT;

  const isImport =
    path.pathname.split(SLASH)[1]?.toLocaleLowerCase() === IMPORT;

  const AuthInvalidNotFoundContent = () => {
    return (
      <FlexColumnCenter style={{ height: "100vh" }}>
        <NotFoundIcon />
        <NotFoundTitle>{t("common:notFound")}</NotFoundTitle>
        <Typo color="gray2" typoType="b4m">
          <Typo color="gray2" typoType="b4m">
            {t("common:notFoundDescription")}
          </Typo>
        </Typo>

        <Flex>
          <Button
            style={{ width: "158px" }}
            onClick={() => window.history.back()}
          >
            {t("common:backToPrevious")}
          </Button>
        </Flex>
      </FlexColumnCenter>
    );
  };

  const AuthValidNotFoundContent = ({ homePath }: { homePath: string }) => {
    const navigate = useNavigate();

    return (
      <>
        <NotFoundIcon />
        <NotFoundTitle>{t("common:notFound")}</NotFoundTitle>
        <Typo color="gray2" typoType="b4m">
          {t("common:notFoundDescription")}
        </Typo>

        <Flex>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={40}
            style={{ width: "158px" }}
            onClick={() => navigate(homePath)}
          >
            {t("common:backToTheHome")}
          </Button>
          <Button
            style={{ width: "158px" }}
            onClick={() => window.history.back()}
          >
            {t("common:backToPrevious")}
          </Button>
        </Flex>
      </>
    );
  };

  if (!user) {
    return <AuthInvalidNotFoundContent />;
  }

  const getClientType = () => {
    if (isSame(user.exporterUserType, "CORPORATE_MANAGER")) {
      return user.exporter.companyType;
    }
    return user.exporterUserMainFieldType;
  };

  /* 수입자 일 때, BOTH 일 때 */
  if (
    (isSame(getClientType(), "BUYER") || isSame(getClientType(), "BOTH")) &&
    isImport
  ) {
    return (
      <ImporterMainLayout>
        <FlexColumnCenter>
          <AuthValidNotFoundContent homePath={IMPORTER_PRIVATE_PATH.HOME} />
        </FlexColumnCenter>
      </ImporterMainLayout>
    );
  }

  /* 수출자 일 때, BOTH 일 때 */
  if (
    (isSame(getClientType(), "BOTH") || isSame(getClientType(), "SELLER")) &&
    isExport
  ) {
    const hasExportPlan = true;
    const hasPlanHistory = true;
    if (hasExportPlan || hasPlanHistory) {
      return (
        <ExporterMainLayout>
          <FlexColumnCenter>
            <AuthValidNotFoundContent homePath={PUBLIC_PATH.ROOT} />
          </FlexColumnCenter>
        </ExporterMainLayout>
      );
    }
  }

  return <AuthInvalidNotFoundContent />;
};

export default NotFoundPage;

const FlexColumnCenter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: pre-wrap;
  text-align: center;
`;

const NotFoundIcon = styled(NotFoundSvg)`
  width: 283px;
  height: 172px;
`;

const NotFoundTitle = styled.h1`
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  font-family: "Pretendard Bold";
  line-height: normal;
  color: ${colorSet.gray2};
`;

const Flex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 40px;
`;
