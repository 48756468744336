import React from "react";
import BarChart from "@/src/components/atom/chart/BarChart";
import { useGetExporterItemStatisticInfoQuery } from "@/src/store/apis/dashboard";
import { CompletedTaskItemTransactionInfoDto } from "@/src/store/apis/dashboard/interface";
import colorSet from "@/src/styles/color";
import dayjs, { Dayjs } from "dayjs";
import {
  BAR_COLOR_SET,
  removeDuplicate,
  sortStackedBarChartPlugin,
} from "../util/chart";
import { getMonthBetweenTwoDate } from "../util/calendar";
import styled from "styled-components";
import Typo from "@/src/components/atom/Typo";
import Select from "@/src/components/atom/Select";
import { ConfigProvider } from "antd";
import { useGetItemsQuery } from "@/src/store/apis/corporate/companyItem";
import Loader from "@/src/components/atom/Loader";
import Tag from "@/src/components/atom/Tag";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { useTranslation } from "react-i18next";

interface ItemVolumeStatisticProps {
  tab: "daily" | "monthly";
  period: [Dayjs, Dayjs];
  exporterItemId?: string[];
  category: string;
  onItemChange: (itemId?: string) => void;
  onRemoveItem: (value: string) => void;
  onCategoryChange: (category: string) => void;
}

const mainCategoryEmptyArray: any[] = [];
const emptyArray: any[] = [];

const ItemVolumeStatistic = ({
  tab,
  period,
  exporterItemId,
  category,
  onItemChange,
  onRemoveItem,
  onCategoryChange,
}: ItemVolumeStatisticProps) => {
  const { t } = useTranslation();
  const { currentData: statistics } = useGetExporterItemStatisticInfoQuery(
    {
      mainCategoryCodeItemName: category === "all" ? undefined : category,
      exporterItemIds: exporterItemId?.includes("all")
        ? undefined
        : exporterItemId?.join(","),
      etdAtFrom: period[0].startOf("day").toISOString(),
      etdAtTo: period[1].endOf("day").toISOString(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const { currentData, isFetching } = useGetItemsQuery(
    { mainCategoryCodeItemName: category === "all" ? undefined : category },
    { refetchOnMountOrArgChange: true }
  );
  /**
   * api
   */
  const { mainCategoryList } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined =
        isError || isFetching || currentData === undefined;

      const isManager = currentData?.row.exporterUserType === "MANAGER";

      return {
        mainCategoryList: !isErrorAndUndefined
          ? isManager
            ? currentData.row.mainCategoryCodeItemNames
            : currentData.row.exporter.mainCategoryCodeItemNames
          : mainCategoryEmptyArray,
      };
    },
  });
  const { isMainCategoryFetching, mainCategoryCurrentData } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategoryCurrentData: !isErrorAndUndefined
              ? currentData ?? emptyArray
              : emptyArray,
          };
        },
      }
    );

  const mainCategoryOptionList = mainCategoryCurrentData
    ?.filter((item) => mainCategoryList.includes(item.codeItemName))
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });

  const itemOptionList =
    currentData?.rows.map(({ id, item }) => ({
      label: item,
      value: id.toString(),
    })) || [];

  const labels =
    statistics?.completedTaskItemTransactionInfoList.map(({ etdAt }) =>
      dayjs(etdAt).format(DATE_FORMAT_STRINGS["YY.MM.DD"])
    ) ?? [];

  const removeDuplicated = removeDuplicate(labels);

  const loadingListByItemId =
    statistics?.completedTaskItemTransactionInfoList.reduce<any>((acc, val) => {
      if (!acc[val.exporterItemId]) {
        acc[val.exporterItemId] = [val];
      } else {
        acc[val.exporterItemId] = [...acc[val.exporterItemId], val];
      }

      return acc;
    }, {}) ?? {};

  const loadingListOfSumOfNetWeightByItem = Object.values<
    CompletedTaskItemTransactionInfoDto[]
  >(loadingListByItemId).map((item) => {
    return item.reduce<
      (Omit<CompletedTaskItemTransactionInfoDto, "netWeight"> & {
        netWeight: number;
      })[]
    >((acc, val) => {
      if (
        acc.findIndex(
          ({ etdAt }) =>
            etdAt === dayjs(val.etdAt).format(DATE_FORMAT_STRINGS["YY.MM.DD"])
        ) !== -1
      ) {
        const alreadyExistedItem = acc.find(
          ({ etdAt }) =>
            etdAt === dayjs(val.etdAt).format(DATE_FORMAT_STRINGS["YY.MM.DD"])
        );
        acc = [
          ...acc.filter(
            ({ etdAt }) =>
              etdAt !== dayjs(val.etdAt).format(DATE_FORMAT_STRINGS["YY.MM.DD"])
          ),
          {
            ...val,
            etdAt: dayjs(val.etdAt).format(DATE_FORMAT_STRINGS["YY.MM.DD"]),
            netWeight:
              Number(val.netWeight) + Number(alreadyExistedItem?.netWeight),
          },
        ];
      } else {
        acc = [
          ...acc,
          {
            ...val,
            etdAt: dayjs(val.etdAt).format(DATE_FORMAT_STRINGS["YY.MM.DD"]),
            netWeight: Number(val.netWeight),
          },
        ];
      }

      return acc;
    }, []);
  });

  const chartDatasets = loadingListOfSumOfNetWeightByItem
    .reduce<
      {
        label: string;
        data: number[];
        rank: number;
      }[]
    >((acc, val) => {
      const item = statistics?.exporterItemRankList.find(
        ({ exporterItemId }) => exporterItemId === val?.[0].exporterItemId
      );
      const label = item?.exporterItem || "";
      const rank = item?.rank || 0;

      const data = val.reduce<number[]>((before, after) => {
        const index =
          tab === "monthly"
            ? getMonthBetweenTwoDate({
                from: period[0],
                to: period[1],
              }).findIndex(
                (month) =>
                  month ===
                  dayjs(after.etdAt, DATE_FORMAT_STRINGS["YY.MM.DD"]).format(
                    "YY.MM"
                  )
              )
            : removeDuplicated.findIndex((day) => day === after.etdAt);

        before[index] = (before[index] || 0) + after.netWeight;

        return before;
      }, []);

      return [
        ...acc,
        {
          label,
          data,
          rank,
        },
      ];
    }, [])
    .sort((a, b) => a.rank - b.rank);

  const chartData = chartDatasets
    .filter(({ label }) => label)
    .map((data, idx) => ({
      label: data.label,
      data: data.data,
      backgroundColor: BAR_COLOR_SET[idx],
      categoryPercentage: 0.5,
      barPercentage: 0.9,
      maxBarThickness: 64,
    }));

  const renderDateByTab = () => {
    return (
      <Typo as="h4" typoType="h4" color="gray2">
        <>
          {period[0].format(DATE_FORMAT_STRINGS["YY.MM.DD"])}~
          {period[1].format(DATE_FORMAT_STRINGS["YY.MM.DD"])}
        </>
      </Typo>
    );
  };

  const getMinIndex = () => {
    const dataPoke = chartData.map(({ data }) => data);
    const dataLengthPoke = chartData.map(({ data }) => data.length);
    const maxLength = Math.max(...dataLengthPoke);
    const datasetLength = chartData.length;

    let result = [];

    for (let i = 0; i < maxLength; i++) {
      let arr = [];
      for (let j = 0; j < datasetLength; j++) {
        arr.push({
          value: dataPoke[j][i] || Infinity,
          dataIndex: i,
          datasetIndex: j,
        });
      }
      const minArray = arr.reduce((acc, cur) => {
        if (acc.value > cur.value) {
          return cur;
        }
        return acc;
      }, arr[0]);

      result.push(minArray);
    }

    return result;
  };

  const renderChartByTab = () => {
    return (
      <>
        <GridDiv gap={exporterItemId ? 1 : undefined}>
          {[
            {
              label: t("common:totalTradingVolume"),
              value: statistics?.totalWeight ?? "-",
              isHidden: false,
            },
            {
              label: `${t("common:top1")} ${
                statistics?.firstExporterItem ?? "-"
              }`,
              value: statistics?.firstExporterItemInfo ?? "-",
              isHidden: !!exporterItemId,
            },
            {
              label: `${t("common:top2")} ${
                statistics?.secondExporterItem ?? "-"
              }`,
              value: statistics?.secondExporterItemInfo ?? "-",
              isHidden: !!exporterItemId,
            },
            {
              label: `${t("common:top3")} ${
                statistics?.thirdExporterItem ?? "-"
              }`,
              value: statistics?.thirdExporterItemInfo ?? "-",
              isHidden: !!exporterItemId,
            },
          ].map(({ label, value, isHidden }) => {
            if (isHidden) return null;

            return (
              <FlexColumnCenter className="gap-8">
                <Typo typoType="b9m" color="gray6">
                  {label}
                </Typo>
                <Typo typoType="h4" color="blue2">
                  {value}
                </Typo>
              </FlexColumnCenter>
            );
          })}
        </GridDiv>
        <div>
          <BarChart
            type="bar"
            height={377}
            data={{
              datasets: chartData.map((item) => ({
                ...item,
                minIndex: getMinIndex(),
              })),
              labels:
                tab === "monthly"
                  ? getMonthBetweenTwoDate({ from: period[0], to: period[1] })
                  : removeDuplicate(labels),
            }}
            plugins={[sortStackedBarChartPlugin]}
            options={{
              plugins: {
                datalabels: {
                  font: {
                    size: 13,
                  },
                  color: colorSet.gray5,
                  formatter: (_, context) => {
                    const datasetArray: { number: number; index: number }[] =
                      [];

                    const contextDataIndex = context.dataIndex;
                    const contextDatasetIndex = context.datasetIndex;

                    context.chart.data.datasets.forEach((dataset) => {
                      if (dataset.data[context.dataIndex] !== undefined) {
                        datasetArray.push({
                          number: dataset.data[context.dataIndex] as number,
                          index: context.dataIndex,
                        });
                      }
                    });

                    const total = datasetArray.reduce(
                      (total, data) => total + data.number,
                      0
                    );

                    if (
                      !!((context.dataset as any).minIndex as []).find(
                        ({ dataIndex, datasetIndex }) =>
                          dataIndex === contextDataIndex &&
                          datasetIndex === contextDatasetIndex
                      )
                    ) {
                      return total === 0 ? "" : total.toLocaleString();
                    } else {
                      return "";
                    }
                  },
                  anchor: "end",
                  align: "end",
                },
                legend: {
                  position: "bottom",
                  labels: {
                    color: colorSet.gray6,
                    boxWidth: 8,
                    boxHeight: 8,
                    pointStyle: "circle",
                    usePointStyle: true,
                    font: {
                      size: 13,
                    },
                  },
                },
                tooltip: {
                  bodyColor: colorSet.gray8,
                  titleColor: colorSet.gray10,
                  bodyFont: {
                    size: 14,
                  },
                  xAlign: "left",
                  yAlign: "center",
                  callbacks: {
                    title: (chartItem) => chartItem[0].dataset.label,
                    label: (a) => {
                      return `${a.formattedValue} MT`;
                    },
                  },
                },
              },
              layout: {
                padding: {
                  top: 23,
                },
              },
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
          />
        </div>
      </>
    );
  };

  return (
    <Article>
      <StyledHeader>
        <Flex>{renderDateByTab()}</Flex>
        <Flex className="gap-8">
          <Flex className="gap-8">
            <StyledLabel>
              <Typo typoType="b10r" color="gray4">
                {t("common:category")}
              </Typo>
              <ConfigProvider
                theme={{
                  token: {
                    controlHeight: 34,
                    fontSize: 14,
                  },
                }}
              >
                <Select
                  size="middle"
                  options={[
                    { label: t("common:all"), value: "all" },
                    ...mainCategoryOptionList,
                  ]}
                  style={{ width: "240px", height: "34px" }}
                  value={category}
                  disabled={isMainCategoryFetching}
                  suffixIcon={isMainCategoryFetching ? <Loader /> : undefined}
                  onChange={(value) => {
                    onCategoryChange(value);
                  }}
                />
              </ConfigProvider>
            </StyledLabel>
          </Flex>
          <Flex className="gap-8">
            <StyledLabel>
              <Typo typoType="b10r" color="gray4">
                {t("common:item")}
              </Typo>
              <ConfigProvider
                theme={{
                  token: {
                    controlHeight: 34,
                    fontSize: 14,
                  },
                }}
              >
                <Select
                  mode="multiple"
                  size="middle"
                  options={[
                    { label: t("common:all"), value: "all" },
                    ...itemOptionList,
                  ]}
                  style={{ width: "240px", height: "34px" }}
                  value={exporterItemId || "all"}
                  disabled={isFetching}
                  suffixIcon={isFetching ? <Loader /> : undefined}
                  onSelect={onItemChange}
                  onDeselect={(value) => {
                    const isOnlyOneSelected = exporterItemId?.length === 1;
                    if (isOnlyOneSelected) return;
                    onItemChange(value);
                  }}
                  maxTagCount="responsive"
                  tagRender={({ value, label }) => {
                    return (
                      <Tag
                        style={{ margin: "4px" }}
                        label={label}
                        color={"blue4"}
                        tagColor={"blue10"}
                        onRemove={() => {
                          onRemoveItem(value);
                        }}
                      />
                    );
                  }}
                />
              </ConfigProvider>
            </StyledLabel>
          </Flex>
        </Flex>
      </StyledHeader>

      <FlexColumn>{renderChartByTab()}</FlexColumn>
    </Article>
  );
};

export default ItemVolumeStatistic;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .gap-8 {
    gap: 8px;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .gap-8 {
    gap: 8px;
  }
`;

const FlexColumnCenter = styled(FlexColumn)`
  align-items: center;
  text-align: center;
`;

const GridDiv = styled.div<{ gap?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ gap }) => (gap ? gap : 4)}, 1fr);
`;
