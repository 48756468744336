import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";

export const buyerItemColDef: ColDef[] = [
  {
    field: "no",
    lockPosition: "left",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    width: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{(params.node?.rowIndex || 0) + 1}</Typo>;
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.buyerNameCode || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "totalNetWeight",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.totalNetWeight || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "itemTop1",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.itemTop1 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "itemTop2",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.itemTop2 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "itemTop3",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.itemTop3 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "itemTop4",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.itemTop4 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "itemTop5",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.itemTop5 || "-"}</Typo>;
    },
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell",
    field: "etc",
    width: 135,
    sortable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{params.data.etc || "-"}</Typo>;
    },
  },
];
