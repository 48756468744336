import { styled } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import SignUpCard from "./components/SignUpCard";
import BagSvg from "@/src/assets/icons/illust-company.svg";
import PersonSvg from "@/src/assets/icons/illust-person.svg";
import InfoSvg from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import { useNavigate } from "react-router-dom";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import TextButton from "@/src/components/atom/Button/TextButton";
import { useEffect, useRef } from "react";
import CallOut from "@/src/components/molecule/CallOut";
import Icon from "@/src/components/atom/Icon";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const SignUpPage = () => {
  const { t } = useTranslation();
  const corpSignUpButtonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const goTo = (to: keyof typeof PUBLIC_PATH) => {
    navigate(PUBLIC_PATH[to]);
  };

  useEffect(() => {
    if (corpSignUpButtonRef.current) {
      corpSignUpButtonRef.current.focus();
    }
  }, []);

  const renderCalloutText = () => {
    return (
      <Trans
        i18nKey="common:signupCallout"
        components={{
          strong: <Typo typoType="h9" color="indigo" />,
          default: <Typo typoType="b9r" color="indigo" />,
        }}
      />
    );
  };

  return (
    <SignUpLayout>
      <StyledMain>
        <Typo as="h1" typoType="d3" className="sign-up-heading">
          {t("signup:common.signup")}
        </Typo>

        <CallOut
          width={800}
          icon={<Icon iconSrc={InfoSvg} />}
          value={renderCalloutText()}
        />

        <CardContainer>
          <StyledSignUpCard
            icon={BagSvg}
            ref={corpSignUpButtonRef}
            description={t("signup:common.signupForACorpDesc")}
            onClick={() => goTo("CORP_SIGNUP")}
            type="corporate"
            buttonText={t("signup:common.corporateSignupButton")}
          />

          <StyledSignUpCard
            icon={PersonSvg}
            description={t("signup:common.signupForAStaffDesc")}
            onClick={() => goTo("STAFF_SIGNUP")}
            type="staff"
            buttonText={t("signup:common.staffSignupButton")}
          />
        </CardContainer>

        <GoToLoginSection>
          <span>{t("signup:common.alreadyHaveAnAccount")}</span>
          <StyledTextButton onClick={() => goTo("LOGIN")}>
            {t("signup:common.backToLogin")}
          </StyledTextButton>
        </GoToLoginSection>
      </StyledMain>
    </SignUpLayout>
  );
};

export default SignUpPage;

const StyledSignUpCard = styled(SignUpCard)``;
const CardContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 800px;

  ${StyledSignUpCard} {
    flex: 1;
  }
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  h1.sign-up-heading {
    padding: 24px 0;
  }
`;

const GoToLoginSection = styled.p`
  ${typo.b9m}
  color: ${colorSet.gray1};
  padding: 16px 0;
  display: flex;
  align-items: center;
  gap: 14px;
`;

const StyledTextButton = styled(TextButton)`
  ${typo.b9m};
  color: ${colorSet.systemBlue2};
`;
