import Select from "@/src/components/atom/Select";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import Flex from "@/src/components/molecule/Flex";
import SectionCard from "@/src/components/molecule/SectionCard";
import colorSet from "@/src/styles/color";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { renderRibbonIcon } from "../utils/renderRibbonIcon";
import { StyledScroll } from "@/src/styles/scroll";
import { currencyStringList } from "./CurrencySelect";
import DoughnutChart from "@/src/components/atom/chart/DoughnutChart";
import Table, { renderNoRowsComponent } from "@/src/components/atom/Table";
import { AgGridReact } from "ag-grid-react";
import { ColDef, RowClassParams, ValueGetterParams } from "ag-grid-community";
import { useImporterStatisticsFilterContext } from "../hooks/hook";
import dayjs from "dayjs";
import { getDayNames, getLast12Months } from "../utils/getDateFormat";
import { useAppSelector } from "@/src/store";
import Icon from "@/src/components/atom/Icon";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { ChartData, ChartOptions } from "chart.js";
import { useGetPurchasesAndSalesStatisticQuery } from "@/src/store/apis/dashboard/importer";
import { isUndefined } from "@/src/utils/is";
import Loader from "@/src/components/atom/Loader";
import {
  getDailyChartDataSums,
  getMonthlyChartDataSums,
} from "../utils/getChartDataFormat";
import {
  getDailyPurchaseAndSales,
  getMonthlyPurchaseAndSales,
  PurchaseAndSalesRowData,
} from "../utils/getTableDataFormat";
import { currencyApi } from "@/src/store/apis/currency";
import { CURRENCY_MAP } from "@/src/utils/currency";
import MixedChart from "@/src/components/atom/chart/MixedChart";
import { DoughnutTooltipType } from "./TradingVolumeStatistics";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface PurchaseSalesStatisticsProps {
  selectCurrency: string;
  onSelectCurrencyChange: Dispatch<SetStateAction<string>>;
}

const THIRTY_ONE = 31;
const DEFAULT_COLUMN: Omit<ColDef, "headerName"> & {
  headerName: string;
} = {
  headerName: t("common:type"),
  headerClass: "ag-right-aligned-cell",
  field: "difference",
  cellStyle: { textAlign: "center" },
  lockPosition: "left",
  pinned: "left",
  width: 110,
  minWidth: 110,
  resizable: false,
  sortable: false,
};

const BAR_CHART_OPTION: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
  },
  scales: {
    x: {
      border: {
        color: colorSet.gray10,
      },
      grid: {
        color: colorSet.gray10,
      },
    },
    y: {
      border: {
        color: colorSet.gray10,
      },
      grid: {
        color: colorSet.gray10,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      position: "bottom",
      labels: {
        color: colorSet.gray6,
        boxWidth: 6,
        boxHeight: 6,
        pointStyle: "circle",
        usePointStyle: true,
        padding: 16,
        font: {
          size: 13,
        },
      },
    },
  },
};

function PurchaseSalesStatistics({
  selectCurrency,
  onSelectCurrencyChange,
}: PurchaseSalesStatisticsProps) {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const country = useAppSelector((state) => state.auth.countryCode);
  const { ataAt, mainCategoryCodeItemName } =
    useImporterStatisticsFilterContext();

  const startDate = dayjs(ataAt?.[0]);
  const endDate = dayjs(ataAt?.[1]);
  const duration = endDate.diff(startDate, "days");
  const isMonthlyType = duration > THIRTY_ONE;

  const getColumnDefs = useCallback((): ColDef[] => {
    if (isMonthlyType) {
      return getLast12Months(endDate).map(({ fullName, headerName }) => {
        return {
          headerName: headerName,
          headerClass: "ag-right-aligned-cell",
          field: fullName,
          cellStyle: { textAlign: "center" },
          width: 110,
          minWidth: 110,
          flex: 1,
          sortable: false,
          cellRenderer: (params: ValueGetterParams) => {
            return params.data[fullName] ?? "-";
          },
        };
      });
    } else {
      return getDayNames(startDate, endDate).map(({ fullName, headerName }) => {
        return {
          headerName,
          headerClass: "ag-right-aligned-cell",
          field: fullName,
          cellStyle: { textAlign: "center" },
          width: 110,
          minWidth: 110,
          flex: 1,
          sortable: false,
          cellRenderer: (params: ValueGetterParams) => {
            return params.data[fullName] ?? "-";
          },
        };
      });
    }
  }, [endDate, isMonthlyType, startDate]);

  // State
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>([
    { ...DEFAULT_COLUMN, headerName: t("common:type") },
    ...getColumnDefs(),
  ]);

  // API
  const {
    isFetching,
    totalPurchases,
    totalSales,
    purchaseAndSalesInfoList,
    itemTopPurchasesRankList,
    itemTopSalesRankList,
    isNoData,
  } = useGetPurchasesAndSalesStatisticQuery(
    {
      ataAtFrom: dayjs(ataAt?.[0]).startOf("day").toISOString(),
      ataAtTo: dayjs(ataAt?.[1]).endOf("day").toISOString(),
      importerMainCategories:
        mainCategoryCodeItemName !== "all"
          ? mainCategoryCodeItemName
          : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFetching,
          totalPurchases: isStable ? currentData.totalPurchases : 0,
          totalSales: isStable ? currentData.totalSales : 0,
          isNoData: isStable
            ? currentData.totalSales === 0 && currentData.totalPurchases === 0
            : true,
          purchaseAndSalesInfoList: isStable
            ? currentData.purchaseAndSalesInfoDtoList
            : [],
          itemTopPurchasesRankList: isStable
            ? currentData.itemTopPurchasesRankList
            : [],
          itemTopSalesRankList: isStable
            ? currentData.itemTopSalesRankList
            : [],
        };
      },
    }
  );

  const { isCurrencyFetching, currentCurrency, isUninitialized } =
    currencyApi.endpoints.getCurrency.useQueryState(
      {
        base: CURRENCY_MAP[country as string],
      },
      {
        selectFromResult: ({ data, isUninitialized, isFetching }) => ({
          currentCurrency: data,
          isUninitialized,
          isCurrencyFetching: isFetching,
        }),
      }
    );

  const selectCurrencyValue: number = currentCurrency
    ? currentCurrency?.rates[selectCurrency as keyof typeof country]
    : 0;

  const getRowData = () => {
    if (isMonthlyType) {
      return getMonthlyPurchaseAndSales({
        list: purchaseAndSalesInfoList,
        ataAtTo:
          dayjs(ataAt?.[1]).startOf("day").toISOString() ||
          dayjs().startOf("day").toISOString(),
      });
    } else {
      return getDailyPurchaseAndSales({
        list: purchaseAndSalesInfoList,
        ataAtFrom:
          dayjs(ataAt?.[0]).startOf("day").toISOString() ||
          dayjs().startOf("day").toISOString(),
        ataAtTo:
          dayjs(ataAt?.[1]).startOf("day").toISOString() ||
          dayjs().startOf("day").toISOString(),
      });
    }
  };

  const { difference, ...salesMargins }: any = getRowData().find(
    (item) => item.difference === "이익률"
  );

  const salesChartData = (): ChartData<"bar" | "line"> => {
    const labelsList = isMonthlyType
      ? getLast12Months(endDate).map((month) => {
          return month.headerName;
        })
      : getDayNames(startDate, endDate).map((day) => {
          return day.headerName;
        });

    const salesMarginsDataList = salesMargins
      ? (Object.entries(salesMargins).map(([_, value]) => {
          return Number((value as number).toFixed(2));
        }) as number[])
      : [];

    const salesDataList: number[] = isMonthlyType
      ? getMonthlyChartDataSums({
          list: purchaseAndSalesInfoList,
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "sales",
        }) || []
      : getDailyChartDataSums({
          list: purchaseAndSalesInfoList,
          startDate: ataAt?.[0] || dayjs(),
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "sales",
        }) || [];

    const purchasesDataList: number[] = isMonthlyType
      ? getMonthlyChartDataSums({
          list: purchaseAndSalesInfoList,
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "purchases",
        }) || []
      : getDailyChartDataSums({
          list: purchaseAndSalesInfoList,
          startDate: ataAt?.[0] || dayjs(),
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "purchases",
        }) || [];

    return {
      labels: labelsList,
      datasets: [
        {
          type: "line" as const,
          label: t("dashboard:importer.purchaseSalesRevenue.salesMargin"),
          borderColor: colorSet.chartBlue1,
          backgroundColor: colorSet.chartBlue1,
          borderWidth: 2,
          data: salesMarginsDataList,
        },
        {
          type: "bar" as const,
          label: t("dashboard:importer.purchaseSalesRevenue.purchaseAmount"),
          backgroundColor: colorSet.systemGrape4,
          borderColor: colorSet.systemGrape4,
          borderWidth: 2,
          maxBarThickness: 20,
          data: purchasesDataList.map((item) => {
            if (selectCurrency !== "USD") {
              return item * selectCurrencyValue;
            }
            return item;
          }),
        },
        {
          type: "bar" as const,
          label: t("dashboard:importer.purchaseSalesRevenue.salesAmount"),
          backgroundColor: colorSet.systemPink4,
          borderColor: colorSet.systemPink4,
          borderWidth: 2,
          maxBarThickness: 20,
          data: salesDataList.map((item) => {
            if (selectCurrency !== "USD") {
              return item * selectCurrencyValue;
            }
            return item;
          }),
        },
      ],
    };
  };

  const renderTotalStatistics = () => {
    if (!selectCurrencyValue || isCurrencyFetching || isUninitialized) {
      return;
    }

    const data = [
      {
        label: t("dashboard:importer.purchaseSalesRevenue.totalPurchaseAmount"),
        value:
          selectCurrency === "USD"
            ? totalPurchases.toLocaleString("ko-KR")
            : (totalPurchases * selectCurrencyValue).toLocaleString("ko-KR"),
      },
      { isDivider: true },
      {
        label: t("dashboard:importer.purchaseSalesRevenue.totalSalesAmount"),
        value:
          selectCurrency === "USD"
            ? totalSales.toLocaleString("ko-KR")
            : (totalSales * selectCurrencyValue).toLocaleString("ko-KR"),
      },
    ];

    return (
      <Flex gap={10} alignItems="center">
        {data.map(({ label, value, isDivider }) => {
          if (isDivider) {
            return <Divider height={88} />;
          }

          return (
            <TotalStatisticsContainer
              flexDirection="column"
              alignItems="center"
              gap={8}
              flex={1}
            >
              <Typo typoType="b9m" color="gray6">
                {label}
              </Typo>
              <Typo typoType="h4" color="blue2">
                {value} {selectCurrency}
              </Typo>
            </TotalStatisticsContainer>
          );
        })}
      </Flex>
    );
  };

  const renderRankingRow = ({
    index,
    label,
    value,
    percentage,
  }: {
    index: number;
    label: string;
    value: string;
    percentage: string;
  }) => {
    return (
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap={4}>
          {renderRibbonIcon(index)}
          <Typo typoType="b10r" color="gray6">
            {label}
          </Typo>
        </Flex>
        <Typo typoType="b9m">
          {value} {selectCurrency} ({percentage}%)
        </Typo>
      </Flex>
    );
  };

  const renderPurchaseAndSalesRanking = () => {
    const purchaseTopRankData = itemTopPurchasesRankList.reduce<{
      labels: string[];
      data: DoughnutTooltipType[];
    }>(
      (acc, val) => ({
        labels: [...acc.labels, val.item],
        data: [
          ...acc.data,
          {
            value:
              selectCurrency === "USD"
                ? Number(val.itemTotalPurchases)
                : Number(val.itemTotalPurchases) * selectCurrencyValue,
            percentage: val.itemTotalPurchasesPercent,
          },
        ],
      }),
      {
        labels: [],
        data: [],
      }
    );

    const purchaseDoughnut = {
      labels: purchaseTopRankData.labels,
      datasets: [
        {
          data: purchaseTopRankData.data,
          backgroundColor: [
            colorSet.systemLime2,
            colorSet.systemLime3,
            colorSet.systemLime4,
          ],
        },
      ],
    };

    const salesTopRankData = itemTopSalesRankList.reduce<{
      labels: string[];
      data: DoughnutTooltipType[];
    }>(
      (acc, val) => ({
        labels: [...acc.labels, val.item],
        data: [
          ...acc.data,
          {
            value:
              selectCurrency === "USD"
                ? Number(val.itemTotalSales)
                : Number(val.itemTotalSales) * selectCurrencyValue,
            percentage: val.itemTotalSalesPercent,
          },
        ],
      }),
      {
        labels: [],
        data: [],
      }
    );

    const salesDoughnut = {
      labels: salesTopRankData.labels,
      datasets: [
        {
          data: salesTopRankData.data,
          backgroundColor: [
            colorSet.yellow2,
            colorSet.yellow3,
            colorSet.yellow4,
          ],
        },
      ],
    };

    const purchaseRankingList =
      itemTopPurchasesRankList.map(
        ({ item, itemTotalPurchases, itemTotalPurchasesPercent }) => {
          return {
            label: item,
            value:
              selectCurrency === "USD"
                ? Number(itemTotalPurchases).toLocaleString("ko-KR")
                : Number(
                    Number(itemTotalPurchases) * selectCurrencyValue
                  ).toLocaleString("ko-KR"),
            percentage: itemTotalPurchasesPercent.toString(),
          };
        }
      ) || [];

    const salesRankingList =
      itemTopSalesRankList.map(
        ({ item, itemTotalSales, itemTotalSalesPercent }) => {
          return {
            label: item,
            value:
              selectCurrency === "USD"
                ? Number(itemTotalSales).toLocaleString("ko-KR")
                : Number(
                    Number(itemTotalSales) * selectCurrencyValue
                  ).toLocaleString("ko-KR"),
            percentage: itemTotalSalesPercent.toString(),
          };
        }
      ) || [];

    const isPurchaseNodata = itemTopPurchasesRankList.length === 0;
    const isSalesNodata = itemTopSalesRankList.length === 0;

    return (
      <StyledCallOut borderColor="gray10" backgroundColor="white">
        <Flex gap={16} isFullWidth>
          <Flex flex={1} gap={16} alignItems="center">
            {isPurchaseNodata ? (
              <Flex alignItems="center" justifyContent="center" flex={1}>
                {renderNoRowsComponent()}
              </Flex>
            ) : (
              <>
                <RankingChartContainer>
                  <DoughnutChart
                    data={purchaseDoughnut as any}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          display: false,
                        },
                        tooltip: {
                          bodyColor: colorSet.gray8,
                          titleColor: colorSet.gray10,
                          bodyFont: {
                            size: 14,
                          },
                          xAlign: "left",
                          yAlign: "center",
                          callbacks: {
                            title: (chartItem) => {
                              return chartItem[0].dataset.label;
                            },
                            label: (a) => {
                              const index = a.dataIndex;
                              const dataset = a.dataset.data[index] as any;
                              const { value, percentage } = dataset;

                              return `${Number(value).toLocaleString(
                                "ko-KR"
                              )} ${selectCurrency} (${percentage}%)`;
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </RankingChartContainer>
                <StyledFlex flexDirection="column" gap={16} isFullWidth>
                  <Typo typoType="h7">
                    {t(
                      "dashboard:importer.purchaseSalesRevenue.purchaseAmountTop3"
                    )}
                  </Typo>
                  <Flex flexDirection="column" gap={8}>
                    {purchaseRankingList.map((row, index) => {
                      return renderRankingRow({ ...row, index });
                    })}
                  </Flex>
                </StyledFlex>
              </>
            )}
          </Flex>

          <Divider height={116} />

          <Flex flex={1} gap={16} alignItems="center">
            {isSalesNodata ? (
              <Flex alignItems="center" justifyContent="center" flex={1}>
                {renderNoRowsComponent()}
              </Flex>
            ) : (
              <>
                <RankingChartContainer>
                  <DoughnutChart
                    data={salesDoughnut as any}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          display: false,
                        },
                        tooltip: {
                          bodyColor: colorSet.gray8,
                          titleColor: colorSet.gray10,
                          bodyFont: {
                            size: 14,
                          },
                          xAlign: "left",
                          yAlign: "center",
                          callbacks: {
                            title: (chartItem) => {
                              return chartItem[0].dataset.label;
                            },
                            label: (a) => {
                              const index = a.dataIndex;
                              const dataset = a.dataset.data[index] as any;
                              const { value, percentage } = dataset;

                              return `${Number(value).toLocaleString(
                                "ko-KR"
                              )} ${selectCurrency} (${percentage}%)`;
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </RankingChartContainer>
                <StyledFlex flexDirection="column" gap={16} isFullWidth>
                  <Typo typoType="h7">
                    {t(
                      "dashboard:importer.purchaseSalesRevenue.salesAmountTop3"
                    )}
                  </Typo>
                  <Flex flexDirection="column" gap={8}>
                    {salesRankingList.map((row, index) => {
                      return renderRankingRow({ ...row, index });
                    })}
                  </Flex>
                </StyledFlex>
              </>
            )}
          </Flex>
        </Flex>
      </StyledCallOut>
    );
  };

  const getRowStyle = (params: RowClassParams) => {
    const { node } = params;

    if (node.rowIndex === 2) {
      return { borderBottom: `1px solid ${colorSet.gray9}` };
    }
  };

  const renderContent = () => {
    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    return (
      <Flex flexDirection="column" gap={16}>
        <Currency>
          <Typo typoType="b10r" color="gray4">
            {t("dashboard:importer.purchaseSalesRevenue.monetaryUnit")}
          </Typo>
          <StyledSelect
            value={selectCurrency}
            onChange={onSelectCurrencyChange}
            options={currencyStringList?.map((item) => {
              return { label: item, value: item };
            })}
          />
        </Currency>
        {renderTotalStatistics()}

        {/* Chart, Table */}
        <Flex flexDirection="column" gap={16}>
          <PurchaseSalesChartContainer>
            <MixedChart data={salesChartData()} options={BAR_CHART_OPTION} />
          </PurchaseSalesChartContainer>

          <Flex flexDirection="column" gap={8}>
            <PurchaseSalesTableContainer>
              <Table
                ref={gridRef}
                rowData={
                  !isNoData
                    ? getRowData().map((item: PurchaseAndSalesRowData) => {
                        let newItem = {} as PurchaseAndSalesRowData;

                        for (let key in item) {
                          if (typeof item[key] === "number") {
                            // USD 제외
                            if (selectCurrency !== "USD") {
                              newItem[key] = Number(
                                (
                                  (item[key] as number) * selectCurrencyValue
                                ).toFixed(2)
                              ).toLocaleString("ko-KR");
                            } else {
                              // USD 만
                              newItem[key] = Number(
                                (item[key] as number).toFixed(2)
                              ).toLocaleString("ko-KR");
                            }
                          } else {
                            // 구분 column
                            newItem[key] = item[key];
                          }
                        }

                        return newItem;
                      })
                    : []
                }
                columnDefs={columnDefs}
                isPagination={false}
                height={234}
                onGridReady={() => setIsReady(true)}
                getRowStyle={getRowStyle}
              />
            </PurchaseSalesTableContainer>
            <Flex alignItems="center" justifyContent="flex-end" gap={4}>
              <StyledIcon iconSrc={InfoSvg} />
              <Typo typoType="b9r" color="gray5">
                {t(
                  "dashboard:importer.purchaseSalesRevenue.profitCalculationMethod"
                )}
              </Typo>
            </Flex>
          </Flex>
        </Flex>

        {/* Top 3 */}
        <Flex flexDirection="column" gap={8}>
          <SubTitle typoType="h8">
            {t(
              "dashboard:importer.purchaseSalesRevenue.purchaseSalesRevenueRank"
            )}
          </SubTitle>
          {renderPurchaseAndSalesRanking()}
        </Flex>
      </Flex>
    );
  };

  useEffect(() => {
    if (gridRef.current && isReady) {
      setTimeout(() => {
        gridRef.current?.api.setColumnDefs([
          { ...DEFAULT_COLUMN, headerName: t("common:type") },
          ...getColumnDefs(),
        ]);
      }, 200);
    }
  }, [endDate, getColumnDefs, isReady, t]);

  return (
    <SectionCard
      cardTitle={t(
        "dashboard:importer.purchaseSalesRevenue.purchaseSalesRevenueStatistics"
      )}
    >
      {renderContent()}
    </SectionCard>
  );
}

export default PurchaseSalesStatistics;

const TotalStatisticsContainer = styled(Flex)`
  margin: 16px 0;
`;

const Divider = styled.div<{ height: number }>`
  width: 1px;
  height: ${({ height }) => height}px;
  background: ${colorSet.gray9};
`;

const Currency = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledSelect = styled(Select)`
  width: 240px;
`;

const SubTitle = styled(Typo)`
  padding: 7px 8px;
`;

const StyledCallOut = styled(CallOut)`
  display: flex;
  padding: 24px;
`;

const RankingChartContainer = styled.div`
  width: 200px;
  /* width: 116px; */
  height: 116px;
  flex-shrink: 0;
`;

const PurchaseSalesChartContainer = styled.div`
  height: 399px;
  padding-bottom: 8px;
`;

const PurchaseSalesTableContainer = styled.div`
  height: 234px;
  overflow: auto;
  ${StyledScroll}
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 950px;
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;
