import colorSet, { ColorType } from "@/src/styles/color";
import { HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

interface CallOutProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode;
  value?: ReactNode;
  borderColor?: ColorType;
  backgroundColor?: ColorType;
  width?: number;
}

function CallOut({
  icon,
  value,
  borderColor = "gray9",
  backgroundColor = "gray11",
  width,
  children,
  ...props
}: PropsWithChildren<CallOutProps>) {
  const renderCallOutContent = () => {
    if (children) {
      return children;
    } else {
      return (
        <>
          <div className="callout-icon">{icon}</div>
          <div>{value}</div>
        </>
      );
    }
  };

  return (
    <StyledCallOut
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      width={width}
      {...props}
    >
      {renderCallOutContent()}
    </StyledCallOut>
  );
}

export default CallOut;

const StyledCallOut = styled.div<{
  $borderColor?: ColorType;
  $backgroundColor?: ColorType;
  width?: number;
}>`
  display: flex;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  width: ${({ width }) => (width ? `${width}px` : `100%`)};
  background: ${({ $backgroundColor }) =>
    $backgroundColor && colorSet[$backgroundColor]};
  border: ${({ $borderColor }) =>
    $borderColor && `1px solid ${colorSet[$borderColor]}`};

  .callout-icon {
    flex-shrink: 0;
  }
`;
