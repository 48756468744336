import Typo from "@/src/components/atom/Typo";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { ShipmentDetailContractBookingListViewDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import BookingInfoTooltip from "./BookingInfoTooltip";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useTranslation } from "react-i18next";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";

interface BookingContractCardItemProps {
  data: ShipmentDetailContractBookingListViewDto;
  index: number;
}

function BookingContractCardItem({
  data,
  index,
}: BookingContractCardItemProps) {
  const { t } = useTranslation();
  const { shipmentDetailBookingInfoList, shipmentDetailContractInfo } = data;
  const navigate = useNavigate();

  const [isCardOpen, setIsCardOpen] = useState(true);

  const renderContractContent = () => {
    return (
      <ContractContainer>
        <Title typoType="h4">
          {t("shipment:exporter.detail.label.contract")}
        </Title>
        <CardContent>
          <FlexColumn>
            <SectionCardRow
              label={t("common:scNo")}
              value={
                <BlueText
                  typoType="h7"
                  color="systemBlue2"
                  onClick={() => {
                    shipmentDetailContractInfo.scNo &&
                      shipmentDetailContractInfo.id &&
                      navigate(
                        `${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${shipmentDetailContractInfo.id}`
                      );
                  }}
                >
                  {shipmentDetailContractInfo.scNo ?? "-"}
                </BlueText>
              }
            />
            <SectionCardRow
              label={t("common:poNo")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {shipmentDetailContractInfo.poNo ?? "-"}
                </BreakWordTypo>
              }
            />
            <SectionCardRow
              label={t("common:item")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {shipmentDetailContractInfo.exporterItem ?? "-"}
                </BreakWordTypo>
              }
            />
            <SectionCardRow
              label={t("common:itemCode")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {shipmentDetailContractInfo.exporterItemCode ?? "-"}
                </BreakWordTypo>
              }
            />
          </FlexColumn>

          <GrayLine />

          <FlexColumn>
            <SectionCardRow
              label={t("common:unitPrice")}
              value={
                shipmentDetailContractInfo.unitPrice &&
                shipmentDetailContractInfo.unitPriceUnit ? (
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {`${Number(
                      shipmentDetailContractInfo.unitPrice
                    ).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${shipmentDetailContractInfo.unitPriceUnit}`}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
            <SectionCardRow
              label={t("common:paymentTerms")}
              value={
                shipmentDetailContractInfo.paymentTerms ? (
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {`${shipmentDetailContractInfo.paymentTerms} ${
                      shipmentDetailContractInfo.paymentTermsRemark
                        ? shipmentDetailContractInfo.paymentTermsRemark
                        : ""
                    }
                      `}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
            {shipmentDetailContractInfo.paymentTerms ===
              PAYMENT_TERMS_LC_TYPE && (
              <SectionCardRow
                label={t("common:lcNo")}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {shipmentDetailContractInfo.lcNo ?? "-"}
                  </BreakWordTypo>
                }
              />
            )}
            <SectionCardRow
              label={t("common:shippingTerms")}
              value={
                shipmentDetailContractInfo.shippingTerms ? (
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {`${shipmentDetailContractInfo.shippingTerms} ${
                      shipmentDetailContractInfo.shippingTermsRemark
                        ? shipmentDetailContractInfo.shippingTermsRemark
                        : ""
                    }
                      `}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
            <SectionCardRow
              label={t("common:freetime")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {shipmentDetailContractInfo.freetime ?? "-"}
                </BreakWordTypo>
              }
            />

            {shipmentDetailContractInfo.allocation && (
              <SectionCardRow
                label={t("common:allocation")}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {shipmentDetailContractInfo.allocation ?? "-"}
                  </BreakWordTypo>
                }
              />
            )}
          </FlexColumn>
        </CardContent>
      </ContractContainer>
    );
  };

  const renderBookingContent = () => {
    return (
      <BookingContainer>
        {shipmentDetailBookingInfoList?.map(
          (
            {
              bookingNo,
              id,
              numberOfContainer,
              containerType,
              portOfDischarge,
              placeOfReceipt,
            },
            idx
          ) => {
            return (
              <BookingContent key={idx.toString()}>
                <Title typoType="h4">
                  {t("shipment:exporter.detail.label.booking")}{" "}
                  {addZeroPrefix(idx + 1)}
                </Title>
                <CardContent>
                  <FlexColumn>
                    <SectionCardRow
                      label={t("common:bookingNo")}
                      value={
                        <BlueText
                          typoType="h7"
                          color="systemBlue2"
                          onClick={() =>
                            bookingNo &&
                            id &&
                            navigate(
                              `${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/${id}`
                            )
                          }
                        >
                          {bookingNo ?? "-"}
                        </BlueText>
                      }
                    />
                    <SectionCardRow
                      label={
                        <NoOfContainerLabel>
                          <Typo>{t("common:noOfContainer")}</Typo>

                          {/* Tooltip */}
                          <BookingInfoTooltip />
                        </NoOfContainerLabel>
                      }
                      value={
                        numberOfContainer && containerType
                          ? `${numberOfContainer}x${containerType}`
                          : "-"
                      }
                    />
                  </FlexColumn>

                  <GrayLine />

                  <FlexColumn>
                    <SectionCardRow
                      label={t("common:portOfDischarge")}
                      value={portOfDischarge ?? "-"}
                    />
                    <SectionCardRow
                      label={t("common:placeOfReceipt")}
                      value={placeOfReceipt ?? "-"}
                    />
                  </FlexColumn>
                </CardContent>
              </BookingContent>
            );
          }
        )}
      </BookingContainer>
    );
  };

  return (
    <SectionFoldableCard
      open={isCardOpen}
      onOpenChange={setIsCardOpen}
      cardTitle={`${t(
        "shipment:exporter.detail.label.contractWithBooking"
      )} ${addZeroPrefix(Number(index + 1))}`}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={isCardOpen}
          onClick={() => setIsCardOpen((prev) => !prev)}
        />
      }
    >
      <BookingContractCardContainer>
        {/* Contract */}
        {renderContractContent()}
        {/* Booking */}
        {renderBookingContent()}
      </BookingContractCardContainer>
    </SectionFoldableCard>
  );
}

export default BookingContractCardItem;

const BookingContractCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContractContainer = styled.section`
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const BookingContent = styled.div`
  display: flex;
  gap: 24px;
`;

const BookingContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
  padding: 24px;
  flex: 1;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GrayLine = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${colorSet.gray9};
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
  word-break: break-word;
`;

const Title = styled(Typo)`
  min-width: 120px;
`;

const NoOfContainerLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
