import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ExporterUserDto, MfaDto } from "../../apis/auth/interface";
import { authApi } from "../../apis/auth";
import { PURGE } from "redux-persist";

interface AuthSliceIntialState {
  user: ExporterUserDto | null;
  tempUser: ExporterUserDto | null;
  token: string | null;
  countryCode: string | null;
  loginExtensionTime: number;
  isAutoLogoutDialogOpen: boolean;
  mfaUser: MfaDto | null;
  redirectUrl: string | null;
}

const initialState: AuthSliceIntialState = {
  user: null,
  tempUser: null,
  token: null,
  countryCode: null,
  loginExtensionTime: 63 * 60,
  isAutoLogoutDialogOpen: false,
  mfaUser: null,
  redirectUrl: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<ExporterUserDto>) => {
      state.user = payload;
    },
    setTempUser: (
      state,
      { payload }: PayloadAction<ExporterUserDto | null>
    ) => {
      state.tempUser = payload;
    },
    setUserTemporaryPasswordCheck: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      if (state.user === null) {
        state.user = null;
      } else {
        state.user = {
          ...state.user,
          account: { ...state.user.account, isTemporaryPassword: payload },
        };
      }
    },
    setCountryCode: (state, { payload }: PayloadAction<string>) => {
      state.countryCode = payload;
    },
    setLoginExtensionTime: (state, { payload }: PayloadAction<number>) => {
      state.loginExtensionTime = payload;
    },
    setIsAutoLogoutDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isAutoLogoutDialogOpen = payload;
    },
    resetLoginExtensionTime: (state) => {
      state.loginExtensionTime = initialState.loginExtensionTime;
    },
    setMfaUser: (state, { payload }: PayloadAction<MfaDto | null>) => {
      state.mfaUser = payload;
    },
    setRedirectUrl: (state, { payload }: PayloadAction<string | null>) => {
      state.redirectUrl = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);

    builder.addMatcher(
      authApi.endpoints.signIn.matchFulfilled,
      (state, { payload }) => {
        if (payload.statusCode === 201) {
          const { token } = payload;
          state.token = token.split(" ")[1];
        }
      }
    );

    builder.addMatcher(
      authApi.endpoints.mfaAuthSignIn.matchFulfilled,
      (state, { payload }) => {
        const { token } = payload;
        state.token = token.split(" ")[1];
      }
    );

    builder.addMatcher(authApi.endpoints.signOut.matchFulfilled, (state) => {
      state.token = null;
      state.user = null;
      state.mfaUser = null;
    });

    builder.addMatcher(
      authApi.endpoints.getSession.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.row;
      }
    );
  },
});

export default authSlice.reducer;
export const {
  setUser,
  setTempUser,
  setUserTemporaryPasswordCheck,
  setCountryCode,
  setLoginExtensionTime,
  setIsAutoLogoutDialogOpen,
  resetLoginExtensionTime,
  setMfaUser,
  setRedirectUrl,
} = authSlice.actions;
